import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import DraggableFeatureCardList from 'components/app/controllers/DraggableFeatureCardList';
import TaskModalContext from 'components/app/controllers/TaskModalContext';
import BacklogProductHeader from 'components/app/controllers/views/BacklogProductHeader';
import { DragSource } from 'components/app/controllers/views/DraggableFeatureCard/DragAndDropContext';
import { css } from 'emotion';
import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { incompleteRequirementBacklogTasks, reduxStore } from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources';
import { useToast } from 'tools';
import { sort } from 'tools/libraries/helpers/sort';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title' | 'backlogTasksCount' | 'archived'>;
export interface RequirementTasksControllerProps extends React.HTMLProps<HTMLDivElement> {
  requirement: RequirementFragment;
  getRequirements: () => void;
}

function RequirementTasksController({ requirement, getRequirements }: RequirementTasksControllerProps): JSX.Element {
  const dragSource = useMemo((): DragSource => ({ type: 'backlog' }), []);
  const { orgID } = useParams<{
    orgID: Data.Id.OrganizationId;
  }>();
  const { openModal } = useContext(TaskModalContext);

  const incompleteRequirementBacklogTasksSlice = incompleteRequirementBacklogTasks(orgID, requirement.id);

  useFirestoreConnect(incompleteRequirementBacklogTasksSlice.query);

  const sortedAllTasks = createSelector(incompleteRequirementBacklogTasksSlice.selector, (allTasks) =>
    sort(allTasks ?? [], 'createdAt', true),
  );

  const tasks = useSelector(sortedAllTasks);

  const { addToast } = useToast();

  const moveTaskToBacklog = useCallback(
    (taskRef: string): void => {
      reduxStore
        .dispatch(updateTask({ id: taskRef, sprint: null }))
        .then(unwrapResult)
        .catch((error: Error) => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
    },
    [addToast],
  );

  const moveTaskToSprint = useCallback(
    (taskRef: string, sprintID: string): void => {
      reduxStore
        .dispatch(updateTask({ id: taskRef, sprint: sprintID }))
        .then(unwrapResult)
        .catch((error: Error) => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
    },
    [addToast],
  );

  const toggleModal = useCallback(
    (task) => {
      openModal(task, sortedAllTasks, requirement.title);
    },
    [openModal, requirement.title, sortedAllTasks],
  );

  return (
    <>
      <div
        className={css`
          padding: 1rem 0.5rem;
          background-color: #ffffff;
          box-shadow: 0 0.0625rem 0 0 #eaeef5;
        `}
      >
        <BacklogProductHeader getRequirements={getRequirements} orgID={orgID} requirement={requirement} />
      </div>
      <div
        className={css`
          height: 90%;
          overflow: auto;
        `}
      >
        <DraggableFeatureCardList
          className={css`
            padding: 1rem;
          `}
          dragSource={dragSource}
          onMoveToBacklog={moveTaskToBacklog}
          onMoveToSprint={moveTaskToSprint}
          selectSprint
          tasks={tasks}
          toggleModal={toggleModal}
        />
      </div>
    </>
  );
}

export default RequirementTasksController;
