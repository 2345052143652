import { createSelector, Selector, unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { EmptySectionHeader, SectionFooter, SectionHeader } from 'components/app/controllers/Selectors/common/Section';
import {
  OptionRenderProps,
  SectionRenderProps,
  Selector as SelectorComponent,
} from 'components/core/controllers/Selector';
import { PopupHandle } from 'components/core/controllers/Selector/views/Popup';
import { css } from 'emotion';
import React, { forwardRef, RefObject, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import {
  getOrgTeams,
  getUpcomingSprints,
  reduxStore,
  RootState,
  selectPreferredTeamId,
  selectProfile,
  selectTeam,
} from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

import { SprintHeader } from './Header';
import SelectButton from './SelectButton';
import SprintOptionView from './SprintOption';
import {
  BacklogOption,
  BacklogSection,
  SprintOption,
  SprintSelectorOption,
  SprintSelectorSection,
  SprintsSection,
} from './types';

const backlog: BacklogOption = {
  id: 'backlog',
  isActive: false,
  sprintName: strings.sprintDropdown.backlog,
};
const backlogSection: BacklogSection = {
  id: 'backlog',
  name: strings.sprintDropdown.backlog,
  options: [backlog],
};

type PopupPosition = 'left' | 'right';

const popupStyleMap = {
  left: css`
    left: 0;
    width: 18.75rem;
  `,
  right: css`
    right: 0;
    width: 18.75rem;
  `,
};

type TaskFragment = Pick<UI.UITask, 'id' | 'sprint'>;

export interface SprintSelectorProps {
  task: TaskFragment;
  popupRef?: RefObject<HTMLDivElement>;
  openDropdown?: boolean;
  position?: PopupPosition;
  dataCy?: string;
}

/**
 * SprintSelector allows to select single sprint
 *
 * user can choose from all organization sprints grouped by teams
 */
export const SprintSelector = forwardRef<PopupHandle, SprintSelectorProps>(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function SprintSelector({ task, position = 'left', dataCy }, ref) {
    const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();

    const preferredTeamId = useSelector(selectPreferredTeamId(orgID));
    const preferredTeam = useSelector(selectTeam(orgID, preferredTeamId));

    if (!isLoaded(preferredTeam) || !preferredTeam) {
      throw new Error(`Team ${orgID}/${preferredTeamId} document was not loaded at the time of selector access`);
    }

    const orgTeamsSlice = getOrgTeams(orgID);

    const userTeams = useSelector(
      createSelector(orgTeamsSlice.selector, selectProfile, (allTeams, profile) =>
        allTeams?.filter((team) => profile.teamIds[orgID].includes(team.id) && team.id !== preferredTeamId),
      ),
    );

    const otherOrgTeams = useSelector(
      createSelector(orgTeamsSlice.selector, selectProfile, (allTeams, profile) =>
        allTeams?.filter((team) => !profile.teamIds[orgID].includes(team.id)),
      ),
    );

    const upcomingSprintsSlice = getUpcomingSprints(orgID);
    useFirestoreConnect(upcomingSprintsSlice.query);

    const teamSprints = ({ id }: Pick<UI.UITeam, 'id'>) => ({ teamId }: Pick<UI.UISprint, 'teamId'>): boolean => {
      return teamId === id;
    };

    const mapToSprintOption = ({ currentSprintId }: Pick<UI.UITeam, 'currentSprintId'>) => (
      sprint: UI.UISprint,
    ): SprintOption => {
      return { ...sprint, isActive: currentSprintId === sprint.id };
    };

    const getUpcomingSprintsFromTeam = ({
      id,
      currentSprintId,
    }: Pick<UI.UITeam, 'id' | 'currentSprintId'>): Selector<RootState, SprintOption[] | undefined> =>
      createSelector(upcomingSprintsSlice.selector, (upcoming) =>
        upcoming?.filter(teamSprints({ id })).map(mapToSprintOption({ currentSprintId })),
      );

    const getUpcomingSprintsFromTeams = (
      teams: UI.UITeam[] | undefined,
    ): Selector<RootState, SprintsSection[] | undefined> =>
      createSelector(
        upcomingSprintsSlice.selector,
        (upcoming) =>
          upcoming &&
          teams?.map((team) => ({
            ...team,
            options: upcoming?.filter(teamSprints(team)).map(mapToSprintOption(team)),
          })),
      );

    const upcomingSprintsFromPreferredTeam = useSelector(getUpcomingSprintsFromTeam(preferredTeam));
    const upcomingSprintsFromUserTeams = useSelector(getUpcomingSprintsFromTeams(userTeams));
    const upcomingSprintsFromOtherOrgTeams = useSelector(getUpcomingSprintsFromTeams(otherOrgTeams));

    const sections: SprintSelectorSection[] = [
      backlogSection,
      {
        ...preferredTeam,
        name: `${preferredTeam.name} ${strings.sprintDropdown.preferred}`,
        options: upcomingSprintsFromPreferredTeam ?? [],
      },
      ...(upcomingSprintsFromUserTeams ?? []),
      ...(upcomingSprintsFromOtherOrgTeams ?? []),
    ];

    const selectedOption: SprintSelectorOption =
      sections
        .flatMap((section: SprintSelectorSection): SprintSelectorOption[] => section.options)
        .find((option) => option?.id === task.sprint) ?? backlog;

    const { addToast } = useToast();

    const handleSelectedSprint = useCallback(
      (option: SprintSelectorOption): void => {
        reduxStore
          .dispatch(
            updateTask({
              id: task.id,
              sprint: option.id === 'backlog' ? null : option.id,
            }),
          )
          .then(unwrapResult)
          .catch(() =>
            addToast({
              type: 'error',
              message: strings.task.failedToUpdateTask,
            }),
          );
      },
      [addToast, task],
    );

    return (
      <SelectorComponent
        ref={ref}
        closePopupOnSelection
        dataCy={dataCy}
        onSelectOption={handleSelectedSprint}
        renderHeader={SprintHeader}
        renderOption={({
          option: sprint,
          isActive: isActiveOption,
          isSelected: isSelectedOption,
        }: OptionRenderProps<SprintSelectorOption>): JSX.Element => (
          <SprintOptionView
            dataCy={dataCy}
            isActive={sprint.isActive}
            isActiveOption={isActiveOption}
            isSelectedOption={isSelectedOption}
            sprint={sprint}
          />
        )}
        renderSectionFooter={({ section }: SectionRenderProps<SprintSelectorSection>): JSX.Element | null =>
          section.options?.length > 0 ? <SectionFooter /> : null
        }
        renderSectionHeader={({ section }: SectionRenderProps<SprintSelectorSection>): JSX.Element | null => {
          if (section.options?.length === 0) return null;
          if (section.id === 'backlog') return <EmptySectionHeader />;
          return <SectionHeader>{section.name}</SectionHeader>;
        }}
        renderSelectButton={({ openPopup }): JSX.Element => (
          <SelectButton dataCy={dataCy} onClick={openPopup} selectedOption={selectedOption} />
        )}
        sections={sections}
        selection={selectedOption}
        style={{ popup: popupStyleMap[position] }}
      />
    );
  },
);
