import { unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { notUndefined } from '@taraai/utility';
import AvatarPicker from 'components/core/controllers/views/AvatarPicker';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getUsers, reduxStore } from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { firestore } from 'tools/libraries/firebaseValues';

type TaskFragment = Pick<UI.UITask, 'id' | 'collaborators'>;

export interface AddTaskCollaboratorsProps {
  task: TaskFragment;
  Ref?: React.RefObject<HTMLInputElement>;
  onError: () => void;
  dataCy?: string;
}

/** Manages UI for adding collaborators to a task */
export default function AddTaskCollaborators({ task, Ref, onError, dataCy }: AddTaskCollaboratorsProps): JSX.Element {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();

  // show only active users plus collaborators, or all users if there's no access level info available
  const users = useSelector(getUsers(orgID).selector)?.filter(
    (user) => user.accessLevels?.[orgID] !== 'deactivated' || task.collaborators.includes(user.id),
  );

  const collaborators = task.collaborators // TODO: add task.collaboratorDocuments
    .map((userID) => users?.find((user) => user.id === userID))
    .filter(notUndefined);

  /** Add a collaborator by ID */
  const addCollaborator = useCallback(
    (collaborator) => {
      reduxStore
        .dispatch(
          updateTask({
            id: task?.id,
            collaborators: firestore.FieldValue.arrayUnion(collaborator),
          }),
        )
        .then(unwrapResult)
        .catch(onError);
    },
    [onError, task],
  );

  /** Remove a collaborator by ID */
  const removeCollaborator = useCallback(
    (collaborator) => {
      reduxStore
        .dispatch(
          updateTask({
            id: task?.id,
            collaborators: firestore.FieldValue.arrayRemove(collaborator),
          }),
        )
        .then(unwrapResult)
        .catch(onError);
    },
    [onError, task],
  );

  return (
    <AvatarPicker
      Ref={Ref}
      avatarStyle={css`
        margin-right: 0.5rem;
      `}
      dataCy={dataCy}
      isCollaborators
      maxShown={4}
      onAddUser={addCollaborator}
      onRemoveUser={removeCollaborator}
      pickerStyle={css`
        width: 2rem;
        height: 2rem;
        padding: 0rem;
      `}
      position='bottom-left'
      size='medium'
      suggestions={users}
      users={collaborators}
    />
  );
}
