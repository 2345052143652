import { createSelector, Selector } from '@reduxjs/toolkit';
import Tara, { Data, Identifiable, Timestamp, UI } from '@taraai/types';
import { UISprint } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { populate, ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import { getUsers } from 'reduxStore/users';
import { isBefore } from 'tools/libraries/helpers';
import { sort } from 'tools/libraries/helpers/sort';

export interface AllQuery<T> {
  query: ReduxFirestoreQuerySetting[];
  selectors: { all: () => Selector<RootState, T[] | undefined> };
}
function inertQuery<T>(): AllQuery<T> {
  const stateFnc: Selector<RootState, unknown> = (state: RootState) => state;
  const query: ReduxFirestoreQuerySetting[] = [];
  return {
    query,
    selectors: {
      all: (): Selector<RootState, T[] | undefined> => createSelector(stateFnc, (): T[] | undefined => undefined),
    },
  };
}
export interface Revision {
  id?: string;
  property: keyof UI.UITask;
  current: string | number | undefined | null;
  previous: string | number | undefined | null;
  updatedAt: Timestamp;
  user: UI.UIUser | Tara.UserStanza | null;
  avatarURL: string | null | undefined;
  name: string;
  sprint: UISprint | undefined;
  wasSprintActive: boolean;
}

interface RevisionTask extends UI.UITask {
  sprintDocument?: UI.UISprint;
  updatedByDocument: UI.UIUser;
}
export function getTaskRevision(orgID: Data.Id.OrganizationId, taskID: Data.Id.TaskId): AllQuery<Revision> {
  return queryBuilder(orgID, taskID);
}

function queryBuilder(orgID: Data.Id.OrganizationId, taskID: Data.Id.TaskId): AllQuery<Revision> {
  if (![orgID, taskID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const queryTask: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/tasks/${taskID}/revisions`,
    storeAs: `revisions-${taskID}-${orgID}`,
  };
  const querySprint: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/sprints`,
    storeAs: `revisions-${orgID}-sprints`,
  };

  const query = [queryTask, querySprint];

  const allTasksRevisions = createSelector(
    [
      (state: RootState): Record<string, UI.UITask> | undefined => state.firestore.data[queryTask.storeAs as string],
      (state: RootState): Record<string, UI.UIUser> | undefined =>
        state.firestore.data[getUsers(orgID).query[0].storeAs as string],
      (state: RootState): Record<string, UI.UISprint> | undefined =>
        state.firestore.data[querySprint.storeAs as string],
    ],
    (tasks, users, sprints) => {
      if (!tasks || !users) return undefined;

      const revisionsTask = Object.values(
        populate({ data: { tasks, users, sprints } }, 'tasks', [
          {
            child: 'updatedBy',
            root: 'users',
            childAlias: 'updatedByDocument',
          },
          {
            child: 'sprint',
            root: 'sprints',
            childAlias: 'sprintDocument',
          },
        ]) as RevisionTask[],
      );

      const sortedRevisionsTask = sort(revisionsTask, 'updatedAt');

      return (
        sortedRevisionsTask &&
        sortedRevisionsTask.reduce((result: Revision[], ___: Identifiable, index: number) => {
          if (index === 0) {
            return result;
          }
          const obj1 = sortedRevisionsTask[index - 1];
          const obj2 = sortedRevisionsTask[index];
          result.push(...getObjectDiff(obj1, obj2));
          return result;
        }, [])
      );
    },
  );

  return {
    query,
    selectors: {
      all: (): Selector<RootState, Revision[] | undefined> => allTasksRevisions,
    },
  } as AllQuery<Revision>;
}

function getObjectDiff(obj1: RevisionTask, obj2: RevisionTask): Revision[] {
  const { updatedAt } = obj2;
  const avatarURL = obj2.updatedByDocument?.avatarURL;
  const name = obj2.updatedByDocument?.name;
  const user = obj2.updatedByDocument;
  const sprint = obj2.sprintDocument;
  const { id } = obj2;
  const requiredFields: (keyof RevisionTask)[] = ['effortLevel', 'status', 'sprint'];
  return requiredFields.reduce((result: Revision[], key: keyof RevisionTask): Revision[] => {
    const value1 = getValueFromRevisionTask(obj1, key);
    const value2 = getValueFromRevisionTask(obj2, key);
    if (value1 === value2) {
      return result;
    }
    const wasSprintActive = isSprintActive(obj2, obj1);
    result.push({
      id,
      property: key as keyof UI.UITask,
      current: value2,
      previous: value1,
      updatedAt,
      user,
      avatarURL,
      name,
      sprint,
      wasSprintActive,
    });
    return result;
  }, []);
}

function getValueFromRevisionTask(obj: RevisionTask, key: keyof RevisionTask): string | undefined {
  if (key === 'sprint') {
    return obj.sprintDocument?.sprintName;
  }
  return obj[key];
}

function isSprintActive(currentRevision: RevisionTask, previousRevision: RevisionTask): boolean {
  const { updatedAt } = currentRevision;
  const sprint = currentRevision.sprintDocument ?? previousRevision.sprintDocument;
  if (!sprint) {
    return false;
  }
  return isBefore(updatedAt, sprint.initialEndDate) && !isBefore(updatedAt, sprint.initialStartDate);
}
