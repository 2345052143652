import { styled } from '@taraai/design-system';
import Button from 'components/core/controllers/views/Button';
import Input from 'components/core/controllers/views/Input';
import Modal from 'components/core/controllers/views/Modal';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React, { useCallback, useState } from 'react';
import { workspaceTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export interface DeleteWorkspaceModalProps {
  isLoading: boolean;
  onSubmit: () => void;
  nameToDelete: string;
  onClose: () => void;
}

export function DeleteWithConfirmationModal({
  isLoading,
  onSubmit,
  nameToDelete,
  onClose,
}: DeleteWorkspaceModalProps): JSX.Element {
  const [confirmationInput, setConfirmationInput] = useState('');

  const confirmationInputMatches = confirmationInput === nameToDelete;

  const updateConfirmationInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationInput(event.target.value);
  }, []);

  const header = <Header>{strings.formatString(strings.deleteWithConfirmationModal.title, { nameToDelete })}</Header>;

  const footer = (
    <Footer>
      <CancelButton onCancel={onClose} />
      <DeleteButton disabled={!confirmationInputMatches} onSubmit={onSubmit} showSpinner={isLoading} />
    </Footer>
  );

  return (
    <DeleteModal footer={footer} header={header}>
      <Body>
        <BodyMessage>
          {strings.formatString(strings.deleteWithConfirmationModal.areYouSure, { nameToDelete })}
          <br />
          {strings.deleteWithConfirmationModal.thisCannotBeUndone}
        </BodyMessage>
        <ConfirmationInput nameToDelete={nameToDelete} onChange={updateConfirmationInput} value={confirmationInput} />
      </Body>
    </DeleteModal>
  );
}

type CancelButtonProps = {
  onCancel: () => void;
};

const CancelButton: React.FC<CancelButtonProps> = ({ onCancel }) => (
  <CancelButtonStyled
    color='ghost'
    data-cy={workspaceTestIDs.DELETE_MODAL_CANCEL_BUTTON}
    name='cancel'
    onClick={onCancel}
  >
    {strings.deleteWithConfirmationModal.cancel}
  </CancelButtonStyled>
);

type DeleteButtonProps = {
  showSpinner: boolean;
  disabled: boolean;
  onSubmit: () => void;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({ showSpinner, disabled, onSubmit }) => (
  <DeleteButtonStyled
    color='red2gray'
    data-cy={workspaceTestIDs.DELETE_MODAL_CONFIRM_BUTTON}
    disabled={showSpinner || disabled}
    name='delete'
    onClick={onSubmit}
  >
    {showSpinner ? (
      <FastSmallSpinner
        color={atomic.get(atomic.colors.white)}
        spinnerStyles={css`
          padding: 0;
        `}
      />
    ) : (
      strings.deleteWithConfirmationModal.delete
    )}
  </DeleteButtonStyled>
);

type ConfirmationInputProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  nameToDelete: string;
};

const ConfirmationInput: React.FC<ConfirmationInputProps> = ({ value, onChange, nameToDelete }) => (
  <InputStyled
    data-cy={workspaceTestIDs.DELETE_MODAL_CONFIRM_INPUT}
    onChange={onChange}
    placeholder={strings.formatString(strings.deleteWithConfirmationModal.placeholder, { nameToDelete })}
    style={{
      main: css`
        color: ${atomic.get(atomic.colors.dark)};
        font-size: 0.875rem;
        background-color: ${atomic.get(atomic.colors.white)};
        border-radius: 3px;
      `,
      container: css`
        height: 2.375rem;
      `,
      labelStyle: css`
        font-size: 0.875rem;
        color: ${atomic.get(atomic.colors.grey7)};
      `,
    }}
    value={value}
  />
);

const DeleteModal = styled(Modal, {
  width: '35rem',
  borderRadius: '0.4rem',
});

const Footer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: '0.75rem 0',
});

const CancelButtonStyled = styled(Button, {
  marginRight: '0.8125rem',
  padding: ' 0rem',
  minHeight: '2.375rem',
  lineHeight: '2.375rem',
});
const DeleteButtonStyled = styled(Button, {
  padding: ' 0rem',
  margin: '0 0 0 7px',
  minHeight: '2.375rem',
  lineHeight: '2.375rem',
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const InputStyled = styled(Input, {
  margin: '1rem 0',
});

const Header = styled(Text, {
  color: '$dark',
  fontSize: '1rem',
  fontWeight: 600,
  padding: '0.5rem 0',
});

const Body = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const BodyMessage = styled('div', {
  lineHeight: '1.5rem',
});
