import { Data } from '@taraai/types';
import { PositionedPortal } from 'components/core/controllers/PositionedPortal';
import { createEntity } from 'components/editor/entities';
import { isWithinSelection } from 'components/editor/plugins/utils';
import { RichEditorContext } from 'components/editor/RichEditorProvider';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { css } from 'emotion';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getUsers } from 'reduxStore';

import { UserDropdown } from './UserDropdown';
import { useUserTagForId } from './useUserTagForId';

// TODO: write tests for this function (when editorStateFromRaw utility is merged in)
const replaceWithMention = (selection: SelectionState, mentionText: string | undefined, userId: string) => (
  state: EditorState,
): EditorState => {
  if (!mentionText) {
    return state;
  }
  const content = state.getCurrentContent();
  const { contentWithEntity, key } = createEntity('mention', 'IMMUTABLE', { id: userId }, content);
  const offsetAfterMention = selection.getAnchorOffset() + mentionText.length;
  const selectionAfterMention = selection.merge({
    anchorOffset: offsetAfterMention,
    focusOffset: offsetAfterMention,
    isBackward: false,
  }) as SelectionState;
  const withMention = EditorState.push(
    state,
    Modifier.replaceText(contentWithEntity, selection, mentionText, undefined, key),
    'insert-characters',
  );
  return EditorState.forceSelection(withMention, selectionAfterMention);
};

/**
 * DraftMentionInserter
 * Draft Mention Inserter for parsing mentions from markdown and letting the
 * user insert mentions.
 *
 * TODO: move into /editor directory after old editor is removed
 */
export default function DraftMentionInserter({
  blockKey,
  children,
  decoratedText,
  end,
  start,
}: DraftDecoratorComponentProps): JSX.Element {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const { editorState, setEditorState } = useContext(RichEditorContext);
  const allUsers = useSelector(getUsers(orgID).selector);

  const allActiveUsers = allUsers?.filter(
    (user) => user.accessLevels?.[orgID] && user.accessLevels[orgID] !== 'deactivated',
  );

  const getUserTagForId = useUserTagForId(orgID);

  // find the start of mention in the decorated text
  // because sometimes decorated text will start with a leading space
  const mentionStartOffset = decoratedText.indexOf('@');
  const decoratedTextSelection = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: start + mentionStartOffset, // drop leading space if exists
    focusOffset: end,
  }) as SelectionState;
  const insertMention = useCallback(
    (mentionText: string | undefined, userId: string) => {
      setEditorState(replaceWithMention(decoratedTextSelection, mentionText, userId));
    },
    [decoratedTextSelection, setEditorState],
  );

  const entityHasSelection = isWithinSelection(editorState.getSelection(), decoratedTextSelection);
  const matchedMention = decoratedText?.match(/(?:^|\s)@(\S*)/);
  const userSearch = matchedMention ? matchedMention[1] : '';

  return (
    <span
      className={css`
        position: relative;
        & * {
          display: inline-block;
        }
      `}
    >
      {children}
      {entityHasSelection && (
        <div
          className={css`
            position: absolute;
            left: 0;
            top: 0;
          `}
          tabIndex={-1}
        >
          <PositionedPortal elementID='pp-dropdown'>
            <UserDropdown
              onUserSelect={(user): void => {
                const userTag = getUserTagForId(user.id);
                insertMention(userTag, user.id);
              }}
              searchQuery={userSearch}
              users={allActiveUsers ?? []}
            />
          </PositionedPortal>
        </div>
      )}
    </span>
  );
}
