import { PopperProps } from '@material-ui/core';
import { styled } from '@taraai/design-system';
import * as PopupComponents from 'components/editor/plugins/shared/Popup.styles';
import { PopupButton } from 'components/editor/plugins/shared/PopupButton';
import React, { forwardRef, useCallback, useRef } from 'react';
import { strings } from 'resources/i18n';

interface Props {
  anchorEl?: PopperProps['anchorEl'];
  url: string;
  onCopy: () => void;
  onRemove: () => void;
  open: boolean;
}

export const Popup = forwardRef<HTMLDivElement, Props>(function Popup({ anchorEl, url, onCopy, onRemove, open }, ref) {
  const saveOnClose = useRef(false);

  const handleCopy = useCallback((): void => {
    saveOnClose.current = false;
    onCopy();
  }, [onCopy]);

  const handleRemove = useCallback((): void => {
    saveOnClose.current = false;
    onRemove();
  }, [onRemove]);

  return (
    <PopupComponents.PopperWrapper ref={ref} anchorEl={anchorEl} open={open} placement='bottom'>
      <PopupComponents.Content>
        <Link href={url} rel='noreferrer noopener' target='_blank' title={url}>
          {url}
        </Link>
        <PopupButton iconName='linkcopy' onClick={handleCopy} title={strings.editor.links.copy} />
        <PopupButton iconName='linkremove' onClick={handleRemove} title={strings.editor.links.remove} />
      </PopupComponents.Content>
    </PopupComponents.PopperWrapper>
  );
});

const Link = styled('a', {
  'color': '$focus',
  'fontSize': '0.88rem',
  'fontWeight': '500',
  'fontStretch': 'normal',
  'fontStyle': 'normal',
  'lineHeight': '1.57',
  'letterSpacing': 'normal',
  'marginRight': '1rem',
  'whiteSpace': 'nowrap',
  'overflow': 'hidden',
  'textOverflow': 'ellipsis',
  'maxWidth': '10rem',
  'cursor': 'pointer',
  ':hover': {
    opacity: '0.7',
    textDecoration: 'underline',
  },
});
