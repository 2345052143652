import AuthLayout from 'components/app/layouts/AuthLayout';
import FirebaseLogin from 'components/core/controllers/FirebaseLogin';
import React from 'react';
import { strings } from 'resources/i18n';

export default function Login(): JSX.Element {
  return (
    <AuthLayout subHeader={strings.authLayout.subHeader} title={strings.authLayout.welcome}>
      <FirebaseLogin />
    </AuthLayout>
  );
}
