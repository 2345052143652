import { css } from 'emotion';
import React from 'react';

export type StickyContainerProps = {
  children: React.ReactNode;
  shadowVisible: boolean;
};

export default function StickyContainer({ children, shadowVisible }: StickyContainerProps): JSX.Element {
  return (
    <div
      className={css`
        width: 100%;
        flex-direction: column;
        background-color: #ffffff;
        box-shadow: ${shadowVisible ? 'none' : '0 1px 0 0 #eaeef5, 0 2px 20px 0 rgba(0, 0, 0, 0.08)'};
        position: sticky;
        top: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0rem;
      `}
    >
      {children}
    </div>
  );
}
