import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { UITask } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { OrderByOptions, ReduxFirestoreQuerySetting, WhereOptions } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import { getUsers } from 'reduxStore/users';
import {
  createIndividualSelector,
  createStandardSelector,
  IndividualQuery,
  inertIndividualQuery,
  inertQuery,
  PopulateTuples,
  Query,
} from 'reduxStore/utils/selectors';

export type TaskSelector = Selector<RootState, UI.UITask | undefined>;
export type TaskListSelector = Selector<RootState, UI.UITask[] | undefined>;

const taskParentFieldPath = '_relationships.parent';
const taskRequirementFieldPath = '_relationships.requirement';

type OrderBy = keyof Pick<UITask, 'title' | 'createdAt' | 'updatedAt' | 'status'>;
interface QueryOptions {
  orderBy: OrderBy;
}

/**
 * Private Query Builder
 */

function queryBuilder(
  orgID: Data.Id.OrganizationId | undefined,
  where: WhereOptions[],
  options?: { orderBy?: OrderBy },
): Query<UITask> {
  if (!isNonEmptyString(orgID)) {
    return inertQuery();
  }

  const ignoreOrder = where.some((clause) => clause[0] === '__name__');

  let orderBy: OrderByOptions[] | undefined;
  switch (options?.orderBy) {
    case 'title':
      orderBy = [['title', 'asc']];
      break;
    case 'updatedAt':
      orderBy = [['updatedAt', 'desc']];
      break;
    case 'createdAt':
      orderBy = [['createdAt', 'desc']];
      break;
    case 'status':
      orderBy = [['status', 'asc']];
      break;
    default:
      break;
  }
  if (ignoreOrder) {
    orderBy = undefined;
  }

  const queryTask: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/tasks`,
    where,
    orderBy,
  };

  const queryRequirements: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/requirements`,
    storeAs: 'requirements',
  };
  const query = [queryTask, queryRequirements];
  const documentTuples = createDocumentTuples(orgID);
  return {
    query,
    selector: createStandardSelector(queryTask, documentTuples),
  };
}

/**
 * Private function to create document tuples
 * @param orgID
 */
function createDocumentTuples(orgID: string): PopulateTuples {
  const queryUser: ReduxFirestoreQuerySetting = getUsers(orgID).query[0];

  const queryRequirements: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/requirements`,
    storeAs: 'requirements',
  };

  return [
    ['author', queryUser],
    ['assignee', queryUser],
    ['_relationships.requirement', queryRequirements],
  ];
}

/**
 * Public functions
 */

export function getTask(orgID: string, taskID: string): IndividualQuery<UITask> {
  if (!isNonEmptyString(taskID)) {
    return inertIndividualQuery();
  }

  const queryTask: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/tasks`,
    where: [['__name__', '==', taskID]],
  };

  const documentTuples = createDocumentTuples(orgID);

  return {
    query: [queryTask],
    selector: createIndividualSelector(taskID, queryTask, documentTuples),
  };
}

export function getTaskBySlug(orgID: string, slug: string): IndividualQuery<UITask> {
  if (!isNonEmptyString(slug)) {
    return inertIndividualQuery();
  }

  const queryTask: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/tasks`,
    where: [['slug', '==', slug]],
  };

  const documentTuples = createDocumentTuples(orgID);

  return {
    query: [queryTask],
    selector: createSelector(
      createStandardSelector<UITask>(queryTask, documentTuples),
      (singleTask) => singleTask?.[0],
    ),
  };
}

export function getTasks(orgID: Data.Id.OrganizationId | undefined, options?: QueryOptions): Query<UITask> {
  const where: WhereOptions[] = [
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export function incompleteAssignedTasks(orgID: string, userID: string): Query<UITask> {
  if (![orgID, userID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['status', '<', 2],
    ['assignee', '==', userID],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  // Due to a limitation of firebase any filter with inequality on a filed
  // must also order by that field. The filed being 'status' here.
  return queryBuilder(orgID, where, { orderBy: 'status' });
}

export function requirementTasks(orgID: string, requirementID: string, options?: QueryOptions): Query<UITask> {
  if (![orgID, requirementID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    [taskRequirementFieldPath, '==', requirementID],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export function incompleteRequirementBacklogTasks(orgID: string, requirementID: string): Query<UITask> {
  if (![orgID, requirementID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    [taskRequirementFieldPath, '==', requirementID],
    ['status', '<', 2],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, { orderBy: 'status' });
}

export function requirementBacklogTasks(orgID: string, requirementID: string, options?: QueryOptions): Query<UITask> {
  if (![orgID, requirementID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    [taskRequirementFieldPath, '==', requirementID],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export function getSprintTasks(orgID: string, sprintID: string, options?: QueryOptions): Query<UITask> {
  if (![orgID, sprintID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', '==', sprintID],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export function sprintTasksUnassigned(orgID: string, sprintID: string, options?: QueryOptions): Query<UITask> {
  if (![orgID, sprintID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', '==', sprintID],
    ['assignee', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export function currentSprintAssignedTasks(
  orgID: string,
  userID: string,
  sprintID: string | null,
  options?: QueryOptions,
): Query<UITask> {
  if (![orgID, userID, sprintID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', '==', sprintID],
    ['assignee', '==', userID],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export function currentSprintCollaboratingTasks(
  orgID: string,
  userID: string,
  sprintID: string | null,
  options?: QueryOptions,
): Query<UITask> {
  if (![orgID, userID, sprintID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', '==', sprintID],
    ['collaborators', 'array-contains', userID],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];

  return queryBuilder(orgID, where, options);
}

export function productTasks(orgID: string, productID: string, options?: QueryOptions): Query<UITask> {
  if (![orgID, productID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['_relationships.product', '==', productID],
    [taskParentFieldPath, '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export function productBacklogTasks(orgID: string, productID: string, options?: QueryOptions): Query<UITask> {
  if (![orgID, productID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['_relationships.product', '==', productID],
    [taskParentFieldPath, '==', null],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export function incompleteBacklogTasks(orgID: string): Query<UITask> {
  if (![orgID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    ['status', '<', 2],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];

  return queryBuilder(orgID, where, { orderBy: 'status' });
}

export function loneTasks(orgID: string, options?: QueryOptions): Query<UITask> {
  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    [taskRequirementFieldPath, '==', null],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export function taskSubTasks(orgID: string, parentID: string, options?: QueryOptions): Query<UITask> {
  if (![orgID, parentID].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', parentID],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}

export const tasksImportedFromService = (
  orgID: Data.Id.OrganizationId,
  service: Data.ExternalIssue.Any['service'],
): Query<UITask> => {
  if (![orgID, service].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    [taskParentFieldPath, '==', null],
    ['externalIssue.service', '==', service],
    ['status', '<', 2],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  // Due to a limitation of firebase any filter with inequality on a filed
  // must also order by that field. The filed being 'status' here.
  return queryBuilder(orgID, where, { orderBy: 'status' });
};

export function getAssignedTasksFromUserSprints(
  orgID: string,
  userID: string,
  sprintIDs: string[],
  options?: QueryOptions,
): Query<UITask> {
  if (![orgID, userID].every(isNonEmptyString)) {
    return inertQuery();
  }

  if (sprintIDs.length === 0) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', 'in', sprintIDs],
    ['assignee', '==', userID],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];

  return queryBuilder(orgID, where, options);
}

export function getCollaborativeTasksFromUserSprints(
  orgID: string,
  userID: string,
  sprintIDs: string[],
  options?: QueryOptions,
): Query<UITask> {
  if (![orgID, userID].every(isNonEmptyString)) {
    return inertQuery();
  }

  if (sprintIDs.length === 0) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['sprint', 'in', sprintIDs],
    ['collaborators', 'array-contains', userID],
    ['archived', '==', false],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options);
}
