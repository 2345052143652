/* eslint-disable no-secrets/no-secrets */
import { markCollection } from 'tools/utils/perfmarks';

export const timing = () => (next) => (action) => {
  if (action.type === '@@reduxFirestore/SET_LISTENER') {
    markCollection(action);
  } else if (action.type === '@@reduxFirestore/LISTENER_RESPONSE') {
    markCollection(action, true);
  }
  return next(action);
};
