"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
function doesImplementToDate(value) {
    return typeof value === 'object' && value !== null && typeof value.toDate === 'function';
}
exports.default = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    if (value instanceof Date) {
        return ts_data_json_1.ok(value);
    }
    if (typeof value === 'string') {
        var unixTimestamp = Date.parse(value);
        if (isNaN(unixTimestamp)) {
            return ts_data_json_1.err(dateError(value));
        }
        return ts_data_json_1.ok(new Date(unixTimestamp));
    }
    if (doesImplementToDate(value)) {
        return ts_data_json_1.ok(value.toDate());
    }
    return ts_data_json_1.err(dateError(value));
});
function dateError(value) {
    return "Failed to decode " + value + " as a Date";
}
