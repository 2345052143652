import { getEntityData } from 'components/editor/entities';
import { RichEditorContext } from 'components/editor/RichEditorProvider';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useClickOutside } from 'tools';

import { Anchor } from './Anchor';
import { Popup } from './Popup';

/**
 * Link
 * A component for the LINK entity in Draft
 */
export function Link({
  blockKey,
  children,
  contentState,
  end,
  entityKey,
  start,
}: DraftDecoratorComponentProps): JSX.Element {
  const { setDisableHandlers, setEditorState } = useContext(RichEditorContext);
  const [isOpen, setIsOpen] = useState(false);
  const anchor = useRef<HTMLAnchorElement | null>(null);
  const popup = useRef<HTMLDivElement | null>(null);
  const { url } = getEntityData('LINK', contentState, entityKey);

  const handleOpen = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setDisableHandlers(true);
      setIsOpen(true);
    },
    [setDisableHandlers],
  );

  const handleClose = useCallback((): void => {
    setIsOpen(false);
    setDisableHandlers(false);
  }, [setDisableHandlers]);

  const handleCopy = useCallback((): void => {
    navigator.clipboard.writeText(url);
    handleClose();
  }, [handleClose, url]);

  const handleRemove = useCallback((): void => {
    setEditorState(
      (editorState: EditorState): EditorState =>
        EditorState.push(
          editorState,
          Modifier.applyEntity(editorState.getCurrentContent(), getSelectionState(blockKey, start, end), null).set(
            'selectionAfter',
            getSelectionState(blockKey, end),
          ) as ContentState,
          'apply-entity',
        ),
    );
    handleClose();
  }, [blockKey, end, handleClose, setEditorState, start]);

  useClickOutside([anchor, popup], handleClose);

  return (
    <>
      <Anchor ref={anchor} href={url} onClick={handleOpen}>
        {children}
      </Anchor>
      <Popup
        ref={popup}
        anchorEl={anchor.current}
        onCopy={handleCopy}
        onRemove={handleRemove}
        open={isOpen}
        url={url}
      />
    </>
  );
}

function getSelectionState(blockKey: string, anchorOffset: number, focusOffset = anchorOffset): SelectionState {
  return SelectionState.createEmpty(blockKey).merge({
    anchorOffset,
    focusOffset,
    hasFocus: true,
  }) as SelectionState;
}
