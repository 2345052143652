import { Data } from '@taraai/types';
import { Selector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { RootState } from 'reduxStore/store';

/**
 * Organization document selector.
 *
 * This selector is guaranteed to return loaded organization document
 * because of the check performed in `OrgAndTeamIsLoaded` component.
 */
export const selectOrganization = (orgID: Data.Id.OrganizationId): Selector<RootState, Data.Organization> => (
  state: RootState,
): Data.Organization => {
  const orgDocument = state.firestore.ordered[`org/${orgID}`]?.[0] as Data.Organization | undefined;

  if (!isLoaded(orgDocument) || !orgDocument) {
    throw new Error(`Organization ${orgID} document was not loaded at the time of selector access`);
  }

  return orgDocument;
};

/**
 * Organization feature flag selector.
 *
 * This selector checks to see if the given feature flag can be found in an organization's document.
 */
export const hasFeature = (flag: Data.FeatureFlag, orgID: Data.Id.OrganizationId): Selector<RootState, boolean> =>
  compose((org) => Boolean(org?.featureFlags?.[flag] ?? false), selectOrganization(orgID));
