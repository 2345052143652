import { createAsyncThunk } from '@reduxjs/toolkit';
import Tara, { Attachment, Data, UI } from '@taraai/types';
import { getFileExtension } from '@taraai/utility';
import { ExtendedStorageInstance } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import { ExtraAPI, Firestore } from 'reduxStore/utils/types';
import { strings } from 'resources/i18n';
import { ONE_HUNDRED_MEGABYTES, TEN_MINUTES } from 'tools/libraries/helpers/constants';
import { v4 as uuidv4 } from 'uuid';

type AttachableType = 'task' | 'requirement';

type AddAttachmentPayload = {
  type: AttachableType;
  path: string;
  file: File | null;
};

type RemoveAttachmentPayload = {
  type: AttachableType;
  path: string;
  attachment: Attachment | null;
};

export const getPathForRequirement = (id: Tara.Data.Id.RequirementId, orgId: Tara.Data.Id.OrganizationId): string =>
  `orgs/${orgId}/requirements/${id}/`;

export const getPathForTask = (id: Tara.Data.Id.TaskId, orgId: Tara.Data.Id.OrganizationId): string =>
  `orgs/${orgId}/tasks/${id}/`;

export function uploadStorage(firebaseStorage: ExtendedStorageInstance, path: string, file: File): Promise<string> {
  const uniqueFileName = file.name.replace(/(\.[\w-]+)$/i, `-${uuidv4()}`);
  const rawExtension = getFileExtension(file.name);
  const filenameExtension = rawExtension && `.${rawExtension}`;

  const uploadRef = firebaseStorage.storage().ref(path + uniqueFileName + filenameExtension);
  const uploadTask = uploadRef.put(file);

  return new Promise((resolve, reject) => {
    const uploadTimeout = setTimeout(() => {
      uploadTask.cancel();
      return reject(new Error(strings.attachments.uploadTimedOut));
    }, TEN_MINUTES);

    uploadTask.on(
      'state_changed',
      // use snapshot for upload progress
      (snapshot) => snapshot,
      (err: Error) => {
        clearTimeout(uploadTimeout);
        return reject(err.message);
      },
      async () => {
        clearTimeout(uploadTimeout);
        const attachmentURL = await uploadRef.getDownloadURL();
        return resolve(attachmentURL);
      },
    );
  });
}

export const addAttachment = createAsyncThunk(
  'AddAttachment',
  async ({ type, path, file }: AddAttachmentPayload, { getState, extra }) => {
    const { getFirestore, getFirebase, getUserID } = extra as ExtraAPI;
    const state = getState() as RootState;
    const firestore = getFirestore();
    const firebaseStorage = getFirebase();
    const userID = getUserID(state);

    if (!file) throw strings.attachments.missingAttachment;

    if (file.size > ONE_HUNDRED_MEGABYTES)
      throw strings.formatString(strings.attachments.tooBigError, {
        attachmentSize: ONE_HUNDRED_MEGABYTES / 1000000,
      });

    return uploadStorage(firebaseStorage, path, file).then(async (attachmentURL) => {
      const attachments = firestore.FieldValue.arrayUnion({
        name: file.name,
        url: attachmentURL,
        thumbnail: null,
      });

      const payload = addOrRemoveAttachmentPayload(type, firestore, userID, attachments);

      await firestore.doc(path).update(payload);

      return attachmentURL;
    });
  },
);

export const removeAttachment = createAsyncThunk(
  'RemovingAttachment',
  async ({ type, path, attachment }: RemoveAttachmentPayload, { getState, extra }) => {
    const { getFirestore, getUserID } = extra as ExtraAPI;
    const state = getState() as RootState;
    const firestore = getFirestore();
    const userID = getUserID(state);

    if (!attachment) throw strings.attachments.missingAttachment;

    const attachments = firestore.FieldValue.arrayRemove(attachment);
    const payload = addOrRemoveAttachmentPayload(type, firestore, userID, attachments);

    return firestore
      .doc(path)
      .update(payload)
      .catch((error: Error) => {
        throw error.message;
      });
  },
);

function addOrRemoveAttachmentPayload(
  type: AttachableType,
  firestore: Firestore,
  updatedBy: Data.Id.UserId,
  attachments: FirebaseFirestore.FieldValue,
): UI.UITaskAttachmentChangeset | UI.UIRequirementAttachmentChangeset {
  const lastUpdateVia = 'tara';
  const updatedAt = firestore.Timestamp.now();

  switch (type) {
    case 'task':
      return { attachments, updatedBy, lastUpdateVia, updatedAt };
    case 'requirement':
      return { attachments, updatedAt };
  }
}
