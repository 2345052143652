import FloatingLogo from 'components/app/controllers/views/FloatingLogo';
import ModalContainer from 'components/core/controllers/views/ModalContainer';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

import { ExternalLink } from './views';

export interface EmailVerificationLayoutProps {
  onResendEmailClick: () => void;
  resendClicked?: boolean;
}

export default function EmailVerificationLayout({
  onResendEmailClick,
  resendClicked,
}: EmailVerificationLayoutProps): JSX.Element {
  return (
    <ModalContainer>
      <div
        className={css`
          overflow-y: auto;
          width: 100%;
          height: 100%;
          background-color: white;
          padding: 5.25rem 1.25rem 0rem;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <FloatingLogo
          className={css`
            margin: 0 auto;
            margin-bottom: 2rem;
          `}
        />
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1.5rem;
          `}
        >
          <Text
            className={css`
              font-size: 1.5rem;
              font-weight: 600;
              text-align: center;
              color: ${atomic.get(atomic.colors.primary)};
            `}
          >
            {strings.onboarding.emailVerification.weJustSent}
          </Text>
          <Text
            className={css`
              font-size: 1rem;
              margin-top: 1.2rem;
              padding: 0;
              text-align: center;
              font-weight: normal;
              color: ${atomic.get(atomic.colors.grey7)};
            `}
          >
            {strings.onboarding.emailVerification.verifyYourAccount}
            <br />
            {strings.onboarding.emailVerification.checkSpam}
          </Text>
          <div
            className={css`
              display: flex;
              align-items: center;
              margin: 2.5rem 0;
            `}
          >
            <ExternalLink
              href='https://mail.google.com/'
              icon='gmail'
              name={strings.onboarding.emailVerification.openGmail}
            >
              {strings.onboarding.emailVerification.openGmail}
            </ExternalLink>
            <ExternalLink
              href='https://outlook.live.com/'
              icon='outlook'
              name={strings.onboarding.emailVerification.openOutlook}
            >
              {strings.onboarding.emailVerification.openOutlook}
            </ExternalLink>
            <ExternalLink
              href='https://mail.yahoo.com/'
              icon='yahoo'
              name={strings.onboarding.emailVerification.openYahoo}
            >
              {strings.onboarding.emailVerification.openYahoo}
            </ExternalLink>
          </div>
          {!resendClicked ? (
            <Text
              className={css`
                font-size: 1rem;
                padding: 0;
                text-align: center;
                font-weight: normal;
                color: ${atomic.get(atomic.colors.grey6)};
              `}
            >
              {strings.onboarding.emailVerification.didNotReceive}

              <button
                className={css`
                  color: ${atomic.get(atomic.colors.grey6)};
                  font-size: 1rem;
                  outline: none;
                  border: none;
                  font-weight: normal;
                  text-decoration: underline;
                  &:hover {
                    color: ${atomic.get(atomic.colors.grey7)};
                  }
                `}
                color='ghost'
                name={strings.onboarding.emailVerification.resendEmail}
                onClick={onResendEmailClick}
                type='button'
              >
                {strings.onboarding.emailVerification.resendEmail}
              </button>
            </Text>
          ) : (
            <Text
              className={css`
                font-size: 1rem;
                padding: 0;
                text-align: center;
                font-weight: normal;
                color: ${atomic.get(atomic.colors.grey6)};
              `}
            >
              {strings.onboarding.emailVerification.sent}
            </Text>
          )}
        </div>
      </div>
    </ModalContainer>
  );
}
