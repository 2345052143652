import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/remote-config';
import 'firebase/storage';

import firebase from 'firebase/app';

export const { analytics, auth, database, firestore, functions, performance, remoteConfig, storage } = firebase;

export { firebase };
