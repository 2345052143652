import { unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import EffortStatus from 'components/app/controllers/EffortStatus';
import { LabelList } from 'components/app/controllers/views/LabelList';
import { SmartText } from 'components/app/controllers/views/SmartText';
import AvatarPicker from 'components/core/controllers/views/AvatarPicker';
import { Card } from 'components/core/controllers/views/Card';
import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import Text from 'components/core/controllers/views/Text';
import React, { ComponentProps, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { getUpcomingSprints, getUsers, reduxStore, useTaskHasMatch } from 'reduxStore';
import { createSprint } from 'reduxStore/sprints/actions/create';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { decodeChain } from 'reduxStore/utils/decoders';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';

export interface SprintFacade {
  title: string;
  value: string;
}

type TaskFragment = Pick<
  UI.UITask,
  'id' | 'title' | 'description' | 'assignee' | 'effortLevel' | 'status' | 'slug' | 'labels' | '_relationships'
>;

export interface Props {
  background?: ComponentProps<typeof Card>['background'];
  onClick: () => void;
  onMoveToBacklog: (taskId: string) => void;
  onMoveToSprint: (taskId: string, sprintId: string) => void;
  selectSprint: boolean;
  sprintColumn?: boolean;
  sprintProgressView?: boolean;
  sprintView: boolean;
  task: TaskFragment;
  title?: string;
  withBorder?: boolean;
}

/**
 * A short description for LegacyFeatureCard.
 * @param className - className for styles
 * @param task - The task object
 * @param sprintView - Show the options menu
 */
export default function LegacyFeatureCard({
  background,
  onClick,
  onMoveToBacklog,
  onMoveToSprint,
  selectSprint,
  sprintColumn,
  sprintProgressView,
  sprintView,
  task,
  title,
  withBorder,
}: Props): JSX.Element {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();

  // show only active users plus assignee, or all users if there's no access level info available
  const teamUsers = useSelector(getUsers(orgID).selector)?.filter(
    (user) => user.accessLevels?.[orgID] !== 'deactivated' || user.id === task.assignee,
  );

  const assignee =
    teamUsers && isNonEmptyString(task.assignee) ? teamUsers.find((user) => user.id === task.assignee) : undefined;

  const upcomingSprintsSlice = getUpcomingSprints(orgID, teamID);
  useFirestoreConnect(upcomingSprintsSlice.query);
  const upcomingOrgSprints = useSelector(upcomingSprintsSlice.selector);
  const sprintOptions = upcomingOrgSprints?.map(
    (sprint: UI.UISprint): SprintFacade => ({
      title: sprint.sprintName,
      value: sprint.id,
    }),
  );

  const { whenError, whenSuccess } = useToast();

  /**
   * Add an assignee to the task by ID
   */
  const addAssignee = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (assignee) => {
      decodeChain<UI.UITaskChangeset>({ id: task.id, assignee }, 'UITaskChangeset')
        .then((changeSet) => reduxStore.dispatch(updateTask(changeSet)))
        .then(unwrapResult)
        .catch(whenError(strings.task.failedToUpdateTask));
    },
    [task, whenError],
  );

  /**
   * Remove the current assignee
   */

  const removeAssignee = useCallback(() => {
    decodeChain<UI.UITaskChangeset>({ id: task.id, assignee: null }, 'UITaskChangeset')
      .then((data) => reduxStore.dispatch(updateTask(data)))
      .then(unwrapResult)
      .catch(whenError(strings.task.failedToUpdateTask));
  }, [task, whenError]);

  const moveToBacklogOption = {
    title: strings.sprints.featureCard.remove,
    onSelect(): void {
      onMoveToBacklog(task.id);
    },
  };

  const moveToSprintOptions =
    sprintOptions?.map((sprint: SprintFacade) => ({
      title: sprint.title,
      onSelect(): void {
        onMoveToSprint(task.id, sprint.value);
      },
    })) ?? [];

  const moveToNewSprintOption = {
    title: strings.sprints.featureCard.newSprint,
    onSelect: async (): Promise<void> => {
      reduxStore
        .dispatch(createSprint())
        .then(unwrapResult)
        .then(whenSuccess(formatI18n(strings.sprints.movedToNewSprintSuccess)))
        .then(({ id }) => id)
        .then((sprintId) => onMoveToSprint(task.id, sprintId))
        .catch(whenError(formatI18n(strings.sprints.movedToNewSprintError)));
    },
  };
  const optionsForBacklogView = [moveToNewSprintOption, ...moveToSprintOptions];
  const optionsForSprintView = [moveToBacklogOption, moveToNewSprintOption, ...moveToSprintOptions];
  const cardOptions = selectSprint ? optionsForBacklogView : optionsForSprintView;
  const hasMatch = useTaskHasMatch(task);

  return (
    <Card background={background} disabled={!hasMatch} withBorder={withBorder}>
      <Container onClick={onClick}>
        <EffortButton>
          <EffortStatus effortLevel={task.effortLevel} status={task.status} />
        </EffortButton>
        <TaskTitleText className={title} done={task.status === 2} h5>
          <SmartText text={task.title} />
        </TaskTitleText>
        <AssigneeSection sprintView={sprintView}>
          {!sprintView && (
            <Menu options={cardOptions}>
              <StyledIcon id='featureCardMenuIcon' name='meatballs' />
            </Menu>
          )}
          <StyledAvatarPicker
            maxAllowed={1}
            onAddUser={addAssignee}
            onRemoveUser={removeAssignee}
            position={sprintProgressView ? 'bottom' : 'bottom-left'}
            size='medium'
            sprintColumn={sprintColumn}
            sprintDetailsView
            suggestions={teamUsers}
            users={assignee && [assignee]}
          />
        </AssigneeSection>
        <LabelList description={task.description} title={task.title} />
      </Container>
    </Card>
  );
}

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '2.75rem 1fr auto',
  alignItems: 'center',
});

const EffortButton = styled('button', {
  outline: 0,
  display: 'flex',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  color: '$dark',
  margin: '0 0.75rem 0 0.75rem',
});

const TaskTitleText = styled(
  Text,
  {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    wordBreak: 'break-word',
  },
  { done: { true: { textDecoration: 'line-through', color: '$grey7' } } },
);

const AssigneeSection = styled(
  'div',
  {
    display: 'flex',
    position: 'relative',
    alignContent: 'center',
    fontSize: '0.875rem',
    color: '#303f4b',
    marginRight: '0.5rem',
  },
  {
    sprintView: {
      true: {
        justifyContent: 'center',
      },
      false: {
        justifyContent: 'flex-end',
      },
    },
  },
);

const StyledAvatarPicker = styled(AvatarPicker, {
  alignSelf: 'center',
});

const StyledIcon = styled(Icon, {
  visibility: 'hidden',
  color: '#949caf',
  width: '1rem',
  paddingTop: '1.25rem',
  alignContent: 'center',
  justifyContent: 'center',
});
