import { Data } from '@taraai/types';
import { getSelectedTeamId } from 'components/app/GlobalNav/helpers';
import { Tab } from 'components/core/controllers/views/Tabs';
import { getRouteName, linkTo } from 'components/Router/paths';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getOrgTeams, selectPreferredTeamId } from 'reduxStore';
import { strings } from 'resources';
import { globalNavTestIDs } from 'resources/cypress/testAttributesValues';

import { NavigationLayout } from './NavigationLayout';

export const Navigation: React.FC = () => {
  const history = useHistory();
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const preferredTeamID = useSelector(selectPreferredTeamId(orgID));
  const orgTeams = useSelector(getOrgTeams(orgID).selector) || [];

  // Team should be either the selected team (if present) or the preferred one
  const teamID = getSelectedTeamId(orgTeams) || preferredTeamID;

  const tabs = useMemo(
    (): Tab[] => [
      {
        label: strings.navigation.home,
        route: linkTo('home', { orgID, teamID }),
        icon: 'homeFull',
        dataCy: globalNavTestIDs.HOME,
      },
      {
        label: strings.navigation.define,
        route: linkTo('requirements', { orgID, teamID }),
        dataCy: globalNavTestIDs.DEFINE,
      },
      {
        label: strings.navigation.sprint,
        route: linkTo('sprints', { orgID, teamID }),
        dataCy: globalNavTestIDs.SPRINT,
      },
      {
        label: strings.navigation.progress,
        route: linkTo('sprintDetails', {
          orgID,
          teamID,
          sprintID: 'current',
        }),
        dataCy: globalNavTestIDs.PROGRESS,
      },
    ],
    [orgID, teamID],
  );

  const [selectedTab, setSelectedTab] = useState<Tab>();
  useEffect(
    () =>
      setSelectedTab(
        tabs.find(({ route, label }) => {
          const routeName = getRouteName();
          // select define option when on requirement builder page
          if (routeName === 'requirement' && label === strings.navigation.define) {
            return true;
          }
          return route === history.location.pathname;
        }),
      ),
    [history.location.pathname, tabs],
  );

  const handleTabChange = useCallback(
    (tab: Tab) => {
      history.push(tab.route);
    },
    [history],
  );

  return <NavigationLayout handleTabChange={handleTabChange} selectedTab={selectedTab} tabs={tabs} />;
};
