import { Data } from '@taraai/types';
import { LabelChip } from 'components/app/controllers/views/LabelChip';
import React, { ReactNode } from 'react';
import { defaultLabels } from 'reduxStore';

interface Props {
  children?: ReactNode;
  id: string;
}

/**
 * ReduxLabelChip
 * A connected redux component that takes in a label ID and renders a
 * `LabelChip`
 *
 */
export function ReduxLabelChip({ children, id }: Props): JSX.Element {
  const labels = defaultLabels as Record<string, Data.DefaultLabel>;
  const child = children ?? labels[id]?.title;
  const description = labels[id]?.description;
  return (
    <LabelChip backgroundColor={labels[id]?.color} description={description}>
      {child}
    </LabelChip>
  );
}
