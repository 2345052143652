import { AnyAction, Middleware } from 'redux';
import { actionTypes } from 'redux-firestore';
import { RootState } from 'reduxStore/store';

export type RFCollectionOperationAction = {
  type: string;
  payload: {
    data?: Record<string, Record<string, unknown>>;
  };
};

export type RFDocumentOperationAction = {
  type: string;
  meta: { doc: string };
  payload: {
    data?: Record<string, unknown>;
  };
};

const isCollectionOperationAction = (action: AnyAction): action is RFCollectionOperationAction => {
  return action.type === actionTypes.GET_SUCCESS || action.type === actionTypes.LISTENER_RESPONSE;
};

const isDocumentOperationAction = (action: AnyAction): action is RFDocumentOperationAction => {
  return action.type === actionTypes.DOCUMENT_ADDED || action.type === actionTypes.DOCUMENT_MODIFIED;
};

/**
 * This middleware inserts `id` field to every document in the `firestore.data[storeAs]`
 * collection in the store.
 */
export const dataIdMiddleware: Middleware<unknown, RootState> = () => (next) => (action: AnyAction) => {
  if (isCollectionOperationAction(action) && action.payload.data) {
    Object.entries(action.payload.data).forEach(([id, data]) => {
      data.id = id;
    });
  } else if (isDocumentOperationAction(action) && action.payload.data) {
    action.payload.data.id = action.meta.doc;
  }
  return next(action);
};
