import { css, cx } from 'emotion';
import React, { ReactNode } from 'react';
import { atomic } from 'resources';

type Props = {
  children?: ReactNode;
  className?: string;
};
export const AreaOverlay: React.FC<Props> = ({ children, className }: Props) => {
  return (
    <div
      className={cx(
        css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          display: flex;
          box-sizing: border-box;
          padding: 74px;
          justify-content: center;
          align-items: center;
          background-color: rgba(221, 240, 255, 0.9);
          border: 1px dashed ${atomic.get(atomic.colors.focus)};
          font-size: 20px;
          font-weight: 500;
          line-height: 1.4;
          text-align: center;
          color: ${atomic.get(atomic.colors.focus)};
        `,
        className,
      )}
    >
      {children}
    </div>
  );
};
