"use strict";
/* eslint-disable max-classes-per-file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.err = exports.ok = exports.Err = exports.Ok = void 0;
// eslint-disable-next-line id-length
var Ok = /** @class */ (function () {
    function Ok(value) {
        this.value = value;
    }
    Ok.prototype.map = function (callback) {
        return ok(callback(this.value));
    };
    Ok.prototype.isOk = function () {
        return true;
    };
    return Ok;
}());
exports.Ok = Ok;
var Err = /** @class */ (function () {
    function Err(errors) {
        this.errors = errors;
    }
    Err.prototype.map = function (callback) {
        return err(this.errors);
    };
    Err.prototype.isOk = function () {
        return false;
    };
    return Err;
}());
exports.Err = Err;
function ok(value) {
    return new Ok(value);
}
exports.ok = ok;
function err(errors) {
    return new Err(errors);
}
exports.err = err;
