import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';
import { IconName } from 'resources';
import { atomic } from 'resources/theme';

type ExternalLinkProp = React.HTMLProps<HTMLAnchorElement> & {
  icon?: IconName;
};

export default function ExternalLink({ children, icon, ...props }: ExternalLinkProp): JSX.Element {
  return (
    <a
      className={css`
        padding: 0.5rem 1.25rem;
        margin: 0 0.75rem;
        border-radius: 3px;
        border: solid 1px ${atomic.get(atomic.colors.grey4)};
        background-color: ${atomic.get(atomic.colors.white)};
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
      `}
      {...props}
    >
      {icon && (
        <Icon
          className={css`
            padding: 0;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
          `}
          name={icon}
        />
      )}
      <span
        className={css`
          font-size: 1rem;
          color: ${atomic.get(atomic.colors.dark)};
        `}
      >
        {children}
      </span>
    </a>
  );
}
