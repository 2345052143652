import ToastContext from 'components/app/controllers/Toast/ToastContext';
import Icon from 'components/core/controllers/views/Icon';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { css, cx } from 'emotion';
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { IconName } from 'resources';
import { atomic } from 'resources/theme';

interface Props {
  children: ReactNode;
  /** Color of the left stripe, defaults to `logo` */
  color?: 'logo' | 'success' | 'error' | 'loading' | 'upgrade';
  /** Icon to be used on the left, defaults to `logo` */
  icon?: IconName;
  /** Toast id */
  id: string;
  /** Show/hide toast based on this prop */
  open: boolean;
  /** Show spinner and hide close button for loading */
  showSpinner: boolean;
}

export const TOAST_ANIMATION_DURATION_MS = 500;

/**
 * The Toast, for now only usable for the update app toast.
 *
 */
export function Toast({
  children,
  showSpinner = false,
  color = 'logo',
  icon = 'logo',
  id,
  open = true,
}: Props): JSX.Element {
  const { removeToast } = useContext(ToastContext);
  const [isOpen, setOpen] = useState(open);
  const closeToast = useCallback(() => {
    setOpen(false);
    removeToast(id);
  }, [id, removeToast]);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <div
      className={cx(
        css`
          animation-name: fadeInDown;
          animation-duration: ${TOAST_ANIMATION_DURATION_MS}ms;
          margin-bottom: 1.25rem;
          visibility: visible;
        `,
        {
          [css`
            animation-name: fadeOutUp;
            animation-duration: ${TOAST_ANIMATION_DURATION_MS}ms;
            opacity: 0;
            transform: translateY(-1.25rem);
          `]: !isOpen,
        },
      )}
    >
      <dialog
        className={css`
            ${atomic.padding('0.625rem', { horizontal: '1rem' })}
            ${atomic.backgroundColor(atomic.colors.white)}
            ${atomic.border({ radius: '0.1875rem', leftRadius: '0rem' })}
            ${atomic.position('relative')}
            display: flex;
            align-items: center;
            box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.18);
            &:before {
              content: '';
              ${atomic.border({ radius: '0.1875rem' })}
              ${atomic.margin('0', { left: '-0.25rem' })}
              ${atomic.position('absolute', '0', { zIndex: -1 })}
              ${
                color === 'logo'
                  ? css`
                      background: linear-gradient(to bottom, #4760a9, #5ec0eb);
                    `
                  : css`
                      ${atomic.backgroundColor(atomic.colors[color])}
                    `
              };
          `}
        open
      >
        {showSpinner && <FastSmallSpinner color={atomic.get(atomic.colors.loading)} />}
        {icon && !showSpinner && (
          <Icon
            className={cx(
              css`
                padding: 0rem;
                padding-right: 1rem;
                height: 1.875rem;
                width: 1.875rem;
              `,
            )}
            name={icon}
          />
        )}
        <Text {...(color === 'logo' ? {} : { color: atomic.colors[color] })}>{children}</Text>
        {!showSpinner && (
          <Icon
            className={cx(
              css`
                padding: 0rem;
                padding-left: 1rem;
                height: 0.625rem;
                width: 0.625rem;
              `,
            )}
            color={atomic.colors.grey6}
            name='close'
            onClick={closeToast}
          />
        )}
      </dialog>
    </div>
  );
}
