import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types/src';
import { SprintColumnHeaderView } from 'components/app/controllers/views/SprintColumnHeaderView';
import { SprintColumnTasksView } from 'components/app/controllers/views/SprintColumnTasksView';
import { SprintFilterCard } from 'components/app/controllers/views/SprintFilterCard';
import React from 'react';
import { strings } from 'resources/i18n';

type TaskFragment = Pick<
  UI.UITask,
  'id' | 'title' | 'description' | 'assignee' | 'effortLevel' | 'status' | 'slug' | 'labels' | '_relationships'
>;
export type SprintColumnType = 'active' | 'complete' | 'upcoming';
type FilterByStatus = 0 | 1 | 2;
type SprintFragment = Pick<UI.UISprint, 'sprintName' | 'initialStartDate' | 'initialEndDate'>;

type SprintColumnViewProps = {
  tasks: TaskFragment[];
  type: SprintColumnType;
  statusFilter?: FilterByStatus;
  assigneeFilter?: string;
  overloadPoints: number;
  completedEffort: number;
  totalEffort: number;
  sprint: SprintFragment;
};

export const SprintColumnView: React.FC<SprintColumnViewProps> = ({
  tasks,
  type,
  overloadPoints,
  statusFilter,
  assigneeFilter,
  completedEffort,
  totalEffort,
  sprint,
}) => (
  <>
    <SprintContainer>
      <SprintColumnHeaderView completedEffort={completedEffort} sprint={sprint} totalEffort={totalEffort} type={type} />
      <Divider complete={type === 'complete'} />
      {tasks.length ? (
        <SprintColumnTasksView overloadPoints={overloadPoints} tasks={tasks} />
      ) : (
        <EmptySprintColumnText>{strings.sprints.sprintColumn.planASprintHelper}</EmptySprintColumnText>
      )}
    </SprintContainer>
    {!!(statusFilter ?? assigneeFilter) && tasks.length && (
      <SprintFilterCard assignee={assigneeFilter} statusFilter={statusFilter} />
    )}
  </>
);

const SprintContainer = styled('div', {
  border: '0.0625rem solid colors.$grey4',
  borderRadius: '0.25rem',
  display: 'flex',
  flexDirection: 'column',
  width: '280px',
  overflow: 'hidden',
  margin: '0 0.3125rem 0.3125rem 0.3125rem',
});

const Divider = styled(
  'div',
  {
    flex: 'none',
    height: '0.25rem',
    flexGrow: '0',
    margin: '0rem 0rem',
  },
  {
    complete: {
      true: { backgroundColor: '$successLight' },
      false: { backgroundColor: '$grey2' },
    },
  },
);

const EmptySprintColumnText = styled('div', {
  color: '$grey6',
  fontSize: '0.625rem',
  padding: '0.875rem 3.4375rem',
  textAlign: 'center',
});
