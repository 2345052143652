import { IconElementData, IconGroup as IconGroupCore } from 'components/core/controllers/IconGroup';
import React, { useCallback } from 'react';
import { workspaceTeamsTestIDs } from 'resources/cypress/testAttributesValues';

export interface MembersIconGroupProps {
  elements: IconElementData[];
  openPopup: (() => void) | null;
}

export default function IconGroup({ elements, openPopup }: MembersIconGroupProps): JSX.Element {
  const showPlusButton = !!openPopup;

  const onClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      openPopup?.();
    },
    [openPopup],
  );

  return (
    <IconGroupCore
      dataCyElement={workspaceTeamsTestIDs.ADD_MEMBER_BUTTON_ELEMENT}
      elements={elements}
      maxShown={5}
      onCountButtonClick={onClick}
      onElementClick={(_elementId: unknown, event: React.SyntheticEvent): void => onClick(event)}
      onPlusButtonClick={onClick}
      plusIconVariant='fullSize'
      showPlusButton={showPlusButton}
    />
  );
}
