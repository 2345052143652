import ToastProvider from 'components/app/controllers/Toast/ToastProvider';
import { OnboardingController } from 'components/app/Onboarding';
import EmailVerificationController from 'components/app/Onboarding/EmailVerificationController';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from 'reduxStore';

export default function Onboarding(): JSX.Element {
  const { emailVerified } = useSelector(selectAuth);

  if (!emailVerified) {
    return <EmailVerificationController />;
  }

  return (
    <ToastProvider>
      <OnboardingController />
    </ToastProvider>
  );
}
