import { createAsyncThunk } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources';

export const deleteTask = createAsyncThunk(
  'DeleteTask',
  async ({ id: taskID }: Pick<UI.UITask, 'id'>, { extra, getState }) => {
    const state = getState() as RootState;
    const { getOrgID, getFirestore, getUserID } = extra as ExtraAPI;
    const orgID = getOrgID();
    const userID = getUserID(state);
    const firestore = getFirestore();
    const path = `orgs/${orgID}/tasks/${taskID}`;

    if (!isNonEmptyString(taskID)) throw strings.tasks.missingCredentials;

    const { id, ...changes } = decode<UI.UITaskDeleteChangeset>(
      {
        id: taskID,
        deleted: true,
        updatedAt: firestore.Timestamp.now(),
        updatedBy: userID,
      },
      'UITaskDeleteChangeset',
    );

    await firestore.update(path, changes);

    return { id: taskID };
  },
);
