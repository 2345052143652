import { createSelector, Selector } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { isNonEmptyString, notUndefined } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import { getUsers } from 'reduxStore/users';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';

export interface TaskCommentsWithThreadsQueryResponse<T> {
  query: ReduxFirestoreQuerySetting[];
  selectors: { all: () => Selector<RootState, T[] | undefined> };
}

// TODO: delete/replace with getTaskCommentsWithThreads after implementing task comments threads on FE
export function getTaskComments(orgID: string, taskID: string): Query<UI.UIComment> {
  if (!isNonEmptyString(orgID) || !isNonEmptyString(taskID)) {
    return inertQuery();
  }

  const queryTaskComment: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/tasks/${taskID}/comments`,
    orderBy: ['createdAt', 'asc'],
    storeAs: `all-org-${orgID}-task-${taskID}-comments`,
  };

  const queryUser: ReduxFirestoreQuerySetting = getUsers(orgID).query[0];

  return {
    query: [queryTaskComment],
    selector: createStandardSelector(queryTaskComment, [['authorId', queryUser]]),
  };
}

export function getTaskCommentsWithThreads(
  orgID: string,
  taskID: string,
): TaskCommentsWithThreadsQueryResponse<UI.UIComment> {
  const taskComments = getTaskComments(orgID, taskID);

  const withThreads = createSelector([taskComments.selector], (comments: UI.UIComment[] | undefined) =>
    comments
      ?.map((comment) => {
        // filter out threads children
        if (comment.parentId) {
          return undefined;
        }
        return {
          ...comment,
          // set thread children unless they are deleted
          thread: comment.hasThread
            ? comments.filter(({ deletedAt, parentId }) => deletedAt === null && parentId === comment.id)
            : [],
        };
      })
      .filter(notUndefined)
      // filter out deleted parents unless they contain a thread
      .filter(({ deletedAt, thread }) => deletedAt === null || thread.length > 0),
  );

  return {
    ...taskComments,
    selectors: {
      ...taskComments.selector,
      all: (): Selector<RootState, UI.UIComment[] | undefined> => withThreads,
    },
  };
}
