import { Tooltip } from '@material-ui/core';
import InstallIntegrationForm from 'components/app/controllers/views/InstallIntegrationForm';
import IntegrationBoxView from 'components/app/controllers/views/IntegrationBoxView';
import Button from 'components/core/controllers/views/Button';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { IconName } from 'resources';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { ConnectAccountGenericData } from 'types/connect-account';
import { InstallIntegrationData } from 'types/install-integration';

export type IntegrationElement = {
  text: string;
  url: string;
};

export interface IntegrationsAppProps extends React.HTMLProps<HTMLDivElement> {
  installationData: InstallIntegrationData;
  icon: IconName;
  iconColor?: string;
  title: string;
  description: string;
  elements?: IntegrationElement[];
  elementsTitle?: string | undefined;
  onDisconnect: () => void;
  isLoading?: boolean;
  manageIntegrationBtn?: JSX.Element | null;
  connectBtnDataCy: string;
  withProfileConnect?: {
    service: 'github' | 'slack';
    title: string;
    description: string;
    connectData: ConnectAccountGenericData;
    label: string;
    icon?: IconName;
    connectedLabel: string;
  };
}

export default function IntegrationsApp({
  className,
  installationData,
  icon,
  iconColor = '',
  title,
  description,
  elements = [],
  elementsTitle,
  onDisconnect,
  isLoading = false,
  manageIntegrationBtn,
  connectBtnDataCy,
}: IntegrationsAppProps): JSX.Element | null {
  const withPermissionsTooltip = useCallback(
    (element: JSX.Element): JSX.Element =>
      installationData.userHasPermission ? (
        element
      ) : (
        <Tooltip
          arrow
          placement='top'
          title={
            <span
              className={css`
                font-size: 0.8rem;
              `}
            >
              {strings.integrations[installationData.service].connectBtnDisabledTooltip}
            </span>
          }
        >
          <span>{element}</span>
        </Tooltip>
      ),
    [installationData.service, installationData.userHasPermission],
  );

  const connectButton = withPermissionsTooltip(
    <InstallIntegrationForm dataCy={connectBtnDataCy} installationData={installationData} method='get'>
      <Button
        className={css`
          padding: 0.25rem 0.5rem;
          outline: none;
          pointer-events: ${installationData.userHasPermission ? 'auto' : 'none'};
        `}
        color='green'
        data-cy={connectBtnDataCy}
        disabled={!installationData.userHasPermission}
        id='installIntegrationFormChild'
      >
        {strings.integrations.connect}
      </Button>
    </InstallIntegrationForm>,
  );

  const disconnectButton = withPermissionsTooltip(
    <Button
      className={css`
        padding: 0.25rem 0.5rem;
        outline: none;
        pointer-events: ${installationData.userHasPermission ? 'auto' : 'none'};
      `}
      color='ghost'
      disabled={!installationData.userHasPermission}
      onClick={onDisconnect}
    >
      {strings.integrations.disconnect}
    </Button>,
  );

  const connectedStateBtns = (
    <>
      {manageIntegrationBtn}
      {disconnectButton}
    </>
  );

  const comingSoonButton = (
    <Button
      className={css`
        padding: 0.25rem 0.5rem;
        outline: none;
        cursor: initial;
      `}
      color='ghost'
    >
      {strings.integrations.comingSoon}
    </Button>
  );

  // eslint-disable-next-line no-nested-ternary
  const actionButton = !installationData.isFeatureEnabled
    ? comingSoonButton
    : installationData.id
    ? connectedStateBtns
    : connectButton;

  const elementsList =
    elements.length > 0 ? (
      <div
        className={css`
          border-radius: 0.1875rem;
          background-color: ${atomic.get(atomic.colors.grey1)};
        `}
      >
        <div
          className={css`
            height: 0.0625rem;
            background-color: ${atomic.get(atomic.colors.grey3)};
          `}
        />
        <div
          className={css`
            padding: 1.375rem 3.5rem;
            display: flex;
            flex-direction: column;
          `}
        >
          <p
            className={css`
              font-size: 0.75rem;
              font-weight: 500;
              text-transform: uppercase;
              padding-bottom: 0.75rem;
              color: ${atomic.get(atomic.colors.grey7)};
            `}
          >
            {elementsTitle}
          </p>
          {elements.map((element) => (
            <a
              key={element.text}
              className={css`
                font-size: 0.75rem;
                padding: 0.25rem 0;
                color: ${atomic.get(atomic.colors.secondary)};
              `}
              href={element.url}
              rel='noreferrer noopener'
              target='_blank'
            >
              {element.text}
            </a>
          ))}
        </div>
      </div>
    ) : null;
  return (
    <IntegrationBoxView
      actionButton={actionButton}
      className={className}
      description={description}
      icon={icon}
      iconColor={iconColor}
      isLoading={isLoading}
      title={title}
    >
      {elementsList}
    </IntegrationBoxView>
  );
}
