import styled from '@emotion/styled';
import Icon from 'components/core/controllers/views/Icon';
import Logo from 'components/core/controllers/views/Logo';
import React from 'react';

export const TeamSwitcher: React.FC = () => (
  <Wrapper>
    <Logo color='#eaeef5' name='' shape='circle' size='medium' />
    <TeamNamePlaceholder />
    <Switch name='switch' />
    <Meatballs name='meatballs' />
  </Wrapper>
);

const Wrapper = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  height: 3.75rem;
  padding: 0 1rem;
  box-shadow: 0 1px 0 0 #eaeef5;
`;

const TeamNamePlaceholder = styled.span`
  margin-left: 0.5rem;
  width: 5.625rem;
  height: 0.625rem;
  border-radius: 0.0625rem;
  background-color: #eaeef5;
`;

const Switch = styled((props) => <Icon {...props} />)`
  width: 0.5rem;
  height: 0.75rem;
  padding: 0;
  margin-left: 1rem;
`;

const Meatballs = styled((props) => <Icon {...props} />)`
  fill: #949caf;
  width: 0.875rem;
  height: 1.375rem;
  padding: 0;
  margin-left: 1rem;
  cursor: pointer;
`;
