import { css, cx } from 'emotion';
import React, { Fragment } from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export type AppListEntry = {
  key: string;
  installed: boolean;
  component: () => JSX.Element;
  profileComponent?: () => JSX.Element | null;
};

export interface IntegrationsAppsListProps extends React.HTMLProps<HTMLDivElement> {
  entries: AppListEntry[];
  children?: React.ReactChild | false;
}

export default function IntegrationsAppsList({
  className,
  entries,
  children,
  ...props
}: IntegrationsAppsListProps): JSX.Element {
  const notInstalledApps = entries.filter((entry) => !entry.installed);
  const installedApps = entries.filter((entry) => entry.installed);
  const renderApp = ({
    component: EntryComponent,
    profileComponent: ProfileComponent,
    key,
  }: AppListEntry): JSX.Element => (
    <Fragment key={key}>
      <EntryComponent />
      {ProfileComponent && <ProfileComponent />}
    </Fragment>
  );

  const notInstalledAppList =
    notInstalledApps.length > 0 ? (
      <div
        className={css`
          padding-bottom: 1.5rem;
        `}
      >
        {notInstalledApps.map(renderApp)}
      </div>
    ) : null;
  const installedAppsList =
    installedApps.length > 0 ? (
      <div>
        <p
          className={css`
            font-size: 0.875rem;
            color: ${atomic.get(atomic.colors.grey6)};
            text-transform: uppercase;
            padding-bottom: 1rem;
          `}
        >
          {strings.integrations.appsYouConnected}
        </p>
        {installedApps.map(renderApp)}
      </div>
    ) : null;
  return (
    <div className={cx(css``, className)} {...props}>
      {notInstalledAppList}
      {installedAppsList}
      {children}
    </div>
  );
}
