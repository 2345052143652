import Checkbox from 'components/core/controllers/views/Checkbox';
import Icon from 'components/core/controllers/views/Icon';
import { css, cx } from 'emotion';
import React, { useCallback, useRef, useState } from 'react';
import { strings } from 'resources/i18n';
import { useClickOutside } from 'tools/utils/hooks';

export interface Option {
  key: string | number;
  label: string;
  value: string | number;
  isActive: boolean;
}

export interface FilterMenuViewProps {
  isFiltering?: boolean;
  options: Option[];
  onReset?: () => void;
  onSubmit?: () => void;
  onChange?: (option: string | number, isActive: boolean) => void;
  onClose?: () => void;
}

/**
 * FilterMenuView renders a clickable icon along with a menu with a list of options
 * @param isFiltering - Whether or not a filter is currently active
 * @param options - An array of options
 * @param onReset - Callback function for clicking the reset button
 * @param onSubmit - Callback function for clicking the ok button
 * @param onChange - Callback function for when a checkbox is changed
 * @param onClose - Callback function for clicking the close button
 */
export default function FilterMenuView({
  isFiltering = false,
  options,
  onReset,
  onSubmit,
  onChange,
  onClose,
}: FilterMenuViewProps): JSX.Element {
  const [isShowingFilterMenu, setIsShowingFilterMenu] = useState(false);

  function handleFilterIconClick(
    event: React.MouseEvent<SVGElement, MouseEvent> | React.KeyboardEvent<SVGElement>,
  ): void {
    event.stopPropagation();
    setIsShowingFilterMenu(!isShowingFilterMenu);
  }

  const handleReset = useCallback(() => {
    setIsShowingFilterMenu(false);
    if (typeof onReset === 'function') onReset();
  }, [onReset]);

  const handleSubmit = useCallback(() => {
    setIsShowingFilterMenu(false);
    if (typeof onSubmit === 'function') onSubmit();
  }, [onSubmit]);

  const handleOnClose = useCallback(() => {
    setIsShowingFilterMenu(false);
    if (typeof onClose === 'function') onClose();
  }, [onClose]);

  // Closes the filter menu when clicking outside
  const filterMenuRef = useRef<HTMLDivElement>(null);
  useClickOutside(filterMenuRef, handleOnClose);

  return (
    <span
      className={cx(
        css`
          position: absolute;
          z-index: 2;
          text-align: left;
          text-transform: none;
        `,
      )}
    >
      <Icon
        className={css`
          cursor: pointer;
          width: 0.75rem;
          height: 0.75rem;
          padding: 0 0 0 0.5rem;
        `}
        name={isFiltering ? 'filterActive' : 'filterInactive'}
        onClick={handleFilterIconClick}
        onKeyDown={handleFilterIconClick}
      />
      {isShowingFilterMenu && (
        <div
          ref={filterMenuRef}
          className={css`
            background: #fff;
            width: 150px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            border-radius: 3px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
            border: solid 1px #c8d0df;
            overflow: auto;
          `}
        >
          <div
            className={css`
              display: flex;
              flex-direction: column;
              max-height: 200px;
              overflow: auto;
              padding: 12px;
            `}
          >
            {options.map((option) => (
              <div
                key={option.key}
                className={css`
                  margin-bottom: 6px;
                  :last-child {
                    margin-bottom: 0px;
                  }
                `}
              >
                <Checkbox
                  checked={option.isActive}
                  label={option.label}
                  name={option.value as string}
                  onChange={(): void => {
                    if (typeof onChange === 'function') onChange(option.value, option.isActive);
                  }}
                />
              </div>
            ))}
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              border-top: 1px solid #eaeef5;
              padding: 12px 9px;
            `}
          >
            <button
              className={css`
                height: 14px;
                width: 20px;
                font-size: 14px;
                height: 14px;
                border: none;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #949caf;
                background-color: transparent;
                cursor: pointer;
              `}
              onClick={handleReset}
              onKeyDown={handleReset}
              type='button'
            >
              {strings.menuFilter.reset}
            </button>
            <button
              className={css`
                height: 14px;
                width: 20px;
                font-size: 14px;
                height: 14px;
                border: none;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #1d98ff;
                background-color: transparent;
                cursor: pointer;
              `}
              onClick={handleSubmit}
              onKeyDown={handleSubmit}
              type='button'
            >
              {strings.menuFilter.ok}
            </button>
          </div>
        </div>
      )}
    </span>
  );
}
