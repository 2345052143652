import { styled } from '@taraai/design-system';
import CompletedSprints from 'components/app/controllers/CompletedSprints';
import NewSprint from 'components/app/controllers/NewSprint';
import UpcomingSprints from 'components/app/controllers/UpcomingSprints';
import SprintHeader from 'components/app/controllers/views/SprintHeader';
import SprintsBacklog from 'components/app/layouts/SprintsBacklog';
import TabBar from 'components/core/controllers/views/TabBar';
import React from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

export default function SprintsLayout(): JSX.Element {
  return (
    <DndProvider backend={Backend}>
      <Root>
        <BacklogWrapper>
          <StyledSprintsBacklog />
          <Container>
            <SprintHeader />
            <TabBar
              lineMarginX='1rem'
              tabbedContent={[
                {
                  label: 'Upcoming',
                  content: <UpcomingSprints />,
                  route: 'upcoming-sprints',
                },
                {
                  label: 'Completed',
                  content: <CompletedSprints />,
                  route: 'completed-sprints',
                },
              ]}
              tabsSeparatorBottomMargin='small'
            >
              <NewSprint />
            </TabBar>
          </Container>
        </BacklogWrapper>
      </Root>
    </DndProvider>
  );
}

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const BacklogWrapper = styled('div', {
  display: 'flex',
  flexGrow: 1,
  backgroundColor: '$grey1',
  overflow: 'hidden',
});

const StyledSprintsBacklog = styled(SprintsBacklog, {
  minWidth: '25.2rem',
  maxWidth: '25.2rem',
  zIndex: 1,
});

const Container = styled('div', {
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  overflowX: 'auto',
});
