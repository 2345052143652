import { unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import { Data, TaskPartial, UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import { keys } from '@taraai/utility/dist/objects';
import Icon from 'components/core/controllers/views/Icon';
import {
  createLabelsPlugin,
  createSingleLinePlugin,
  getWhitespacePlugin,
  plainTextPlugin,
} from 'components/editor/plugins';
import { composePlugins } from 'components/editor/plugins/utils';
import { RichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createLabel, createTask, defaultLabels, getCustomLabels, reduxStore, selectDefaultLabel } from 'reduxStore';
import { decode } from 'reduxStore/utils/decoders';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

type SprintFragment = Pick<UI.UISprint, 'id' | 'sprintName'>;
type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;

export interface CreateTaskInputProps extends React.HTMLProps<HTMLDivElement> {
  dataCy?: string;
  noTopBorder?: boolean;
  requirement?: RequirementFragment;
  sprint?: SprintFragment;
}

type TaskCreationLocation = 'RequirementBacklog' | 'SprintsColumn' | 'Backlog';
function getTaskCreationLocation(requirement: boolean, sprint: boolean): TaskCreationLocation {
  if (requirement) return 'RequirementBacklog';
  if (sprint) return 'SprintsColumn';
  return 'Backlog';
}

function getPlaceholder(requirement: RequirementFragment | undefined, sprint: SprintFragment | undefined): string {
  // formatString method returns an array of strings if formatted string still contains an object (eg React Component)
  // so cast the type, as in this case we are sure we return string
  if (requirement) return strings.formatString(strings.sprints.createTaskName, { name: requirement.title }) as string;
  if (sprint) return strings.formatString(strings.sprints.createSprintName, { name: sprint.sprintName }) as string;
  return strings.sprints.createTask;
}

export default function CreateTaskInput({
  requirement,
  sprint,
  dataCy,
  noTopBorder,
}: CreateTaskInputProps): JSX.Element {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const { addToast } = useToast();

  const customLabels = useSelector(getCustomLabels(orgID).selector);

  const editorRef = useRef<RichEditorHandle | null>(null);
  const allLabels = useRef() as MutableRefObject<UI.UILabel[]>;
  allLabels.current = [...keys(defaultLabels).map(selectDefaultLabel), ...(customLabels || [])];

  const [hasText, setHasText] = useState(false);

  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        plainTextPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => allLabels.current,
        }),
        createSingleLinePlugin({ returnHandled: true }),
      ),
    [],
  );

  const handleEnter = (title: string): void => {
    try {
      decode<TaskPartial>({ title }, 'TaskPartial');
    } catch {
      addToast({ type: 'error', message: strings.task.titleTooShort });
      return;
    }

    const labels = unique(parseLabelsFromPlainText(title));

    reduxStore
      .dispatch(createTask({ title, labels, requirement: requirement?.id ?? null, sprint: sprint?.id ?? null }))
      .then(unwrapResult)
      .then((slug) => {
        segment.track('TaskCreated', { orgID, slug, location: getTaskCreationLocation(!!requirement, !!sprint) });
        editorRef.current?.clear();
      })
      .catch(() => {
        addToast({ type: 'error', message: strings.task.failedToCreateTask });
      });
  };

  const placeholder = getPlaceholder(requirement, sprint);
  return (
    <Wrapper data-cy={dataCy} noTopBorder={noTopBorder}>
      <StyledIcon name='plus' />
      <StyledEditor>
        <RichEditorProvider
          initialValue=''
          onHasTextChange={setHasText}
          onSave={handleEnter}
          plugin={plugin}
          saveOnReturn
          singleLine
        >
          <RichEditor ref={editorRef} data-cy={dataCy} placeholder={placeholder} />
        </RichEditorProvider>
      </StyledEditor>
      {!hasText && <StyledIcon name='enter' />}
    </Wrapper>
  );
}

const Wrapper = styled(
  'div',
  {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'borderTop': 'solid 0.0625rem colors.$grey3',
    'borderBottom': 'solid 0.0625rem colors.$grey3',
    'padding': '0.1875rem 1rem 0.0625rem 1rem',
    ':hover': {
      backgroundColor: '$grey1',
    },
    '& > * + * ': {
      paddingLeft: '1rem',
    },
  },
  {
    noTopBorder: { true: { borderTop: 'none' } },
  },
);

const StyledIcon = styled(Icon, {
  padding: '0rem',
  paddingBottom: '0.125rem',
  color: '$dark',
});

const StyledEditor = styled('div', {
  'display': 'flex',
  'alignItems': 'center',
  'flex': '1 0 auto',
  'height': '2.5rem',
  '*': {
    flex: '1 0 auto',
  },

  '.DraftEditor-root': {
    color: '$dark',
    fontSize: '0.875rem',
  },

  '.public-DraftEditorPlaceholder-root': {
    color: '$dark',
    opacity: 0.7,
  },
});
