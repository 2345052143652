import { createAsyncThunk } from '@reduxjs/toolkit';
import Tara, { Data, UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { decode } from 'reduxStore/utils/decoders';
import { flattenNestedAttribute } from 'reduxStore/utils/firebaseHelpers';
import { ExtraAPI, Firestore } from 'reduxStore/utils/types';

interface UpdateTaskPayload extends UI.UITaskChangeset {
  orgId?: Data.Id.OrganizationId;
  meta?: {
    transaction?: Firestore['Transaction'];
  };
}

export const updateTask = createAsyncThunk(
  'UpdateTask',
  async ({ orgId: forceOrgId, meta, ...data }: UpdateTaskPayload, { getState, extra }) => {
    const state = getState() as RootState;
    const { getOrgID, getFirestore, getUserID } = extra as ExtraAPI;
    const orgID = forceOrgId ?? getOrgID();
    const userID = getUserID(state);
    const firestore = getFirestore();

    function buildChangeset(change: UI.UITaskChangeset): UI.UITaskStrictChangeset {
      const changeset: UI.UITaskStrictChangeset = {
        ...change,
        updatedAt: firestore.Timestamp.now(),
        updatedBy: userID,
        lastUpdateVia: 'tara',
      };

      return maybeAddStatusHistory(changeset);
    }

    function maybeAddStatusHistory(change: UI.UITaskStrictChangeset): UI.UITaskStrictChangeset {
      if (isStatus(change.status)) {
        const statusHistory: Tara.StatusHistory = {
          lastUpdatedBy: change.updatedBy,
          updatedAt: firestore.Timestamp.now(),
          status: change.status,
        };
        return {
          ...change,
          statusHistory: firestore.FieldValue.arrayUnion(statusHistory),
        };
      }
      return change;
    }

    const decodedChangesWithID = decode<UI.UITaskStrictChangeset>(buildChangeset(data), 'UITaskStrictChangeset');

    const flattenChangesWithID = flattenNestedAttribute(decodedChangesWithID, '_relationships');

    const { id: taskID, ...flattenChanges } = flattenChangesWithID;

    if (meta?.transaction) {
      meta.transaction.update(firestore.doc(`orgs/${orgID}/tasks/${taskID}`), flattenChanges);
    } else {
      await firestore.update(`orgs/${orgID}/tasks/${taskID}`, flattenChanges);
    }

    return decodedChangesWithID;
  },
);

function isStatus(value: unknown): value is Tara.Status {
  return value === 0 || value === 1 || value === 2;
}
