import { Tooltip } from '@material-ui/core';
import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import AriaMenuButton from 'react-aria-menubutton';

import { hover, menuItemStyle } from './menuStyles';
import { MenuOption } from './menuTypes';

export interface LeafMenuItemProps {
  option: MenuOption;

  isDisabled?: boolean;

  /**
   * shows left padding to show selected check mark on left of item
   */
  showSelection?: boolean;

  selectedItem?: string;
  /**
   * shows tooltip on the left for a disabled menu item
   */
  showTooltip?: boolean;
  /**
   * title for the tooltip
   */
  title?: string;
}
export default function LeafMenuItem({
  option,
  showSelection = false,
  isDisabled = false,
  selectedItem,
  showTooltip = false,
  title,
}: LeafMenuItemProps): JSX.Element {
  // FIXME: why are there two props to disable that can be set differently?
  const disabled = option.disabled || isDisabled;
  return showTooltip ? (
    <Tooltip
      arrow
      enterDelay={0}
      interactive
      placement='left-end'
      title={title as string}
      TransitionProps={{ timeout: 600 }}
    >
      <MenuItem key={option.title + disabled} data-cy={option.dataCy} disabled={disabled} value={option.title}>
        {showSelection ? <TickIcon name='tick' selectedItem={option.title === selectedItem} /> : null}
        {option.title}
      </MenuItem>
    </Tooltip>
  ) : (
    <MenuItem key={option.title + disabled} data-cy={option.dataCy} disabled={disabled} value={option.title}>
      {showSelection ? <TickIcon name='tick' selectedItem={option.title === selectedItem} /> : null}
      {option.title}
    </MenuItem>
  );
}
const TickIcon = styled(
  Icon,
  {
    padding: '0 0.625rem 0 0',
    height: '0.5rem',
    opacity: '0',
  },
  { selectedItem: { true: { opacity: '1' } } },
);
const MenuItem = styled(
  AriaMenuButton.MenuItem,
  {
    color: '#303f4b',
    ...menuItemStyle,
    ...hover,
  },
  {
    disabled: { true: { color: '#949caf', cursor: 'not-allowed' } },
  },
);
