import styled from '@emotion/styled';

export const OrgCircle = styled.div`
  border: solid 0.125rem #1d98ff;
  border-radius: 100%;
  box-shadow: 0 0 0 10000px rgba(48, 63, 75, 0.3);
  height: 4.875rem;
  left: 0.1875rem;
  opacity: 0;
  position: absolute;
  top: 6.375rem;
  width: 4.875rem;
  z-index: 2;
`;
