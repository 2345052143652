import { styled } from '@taraai/design-system';
import { Data } from '@taraai/types';
import Avatar from 'components/core/controllers/views/Avatar';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Profile } from 'reduxStore/utils';

type ProfileSummaryProps = {
  containerRef?: React.RefObject<HTMLDivElement>;
  profile: Profile;
  email: string | null;
};

/**
 * @FIXME fix text selection
 */
export function Popup({ profile, email, containerRef }: ProfileSummaryProps): JSX.Element {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();

  return (
    <PopupContainer ref={containerRef}>
      <PersonalDataContainer>
        <AvatarStyled size='2rem' user={profile} />
        <TextContainer>
          <Text color='dark'>{profile.name}</Text>
          <Text color='light'>{email}</Text>
        </TextContainer>
      </PersonalDataContainer>
      <OptionsContainer>
        <Option to={linkTo('profile', { orgID, teamID })}>Profile settings</Option>
        <Option to={linkTo('logout')}>Logout</Option>
      </OptionsContainer>
    </PopupContainer>
  );
}

const PopupContainer = styled('div', {
  position: 'absolute',
  right: '1.25rem',
  top: '2.5rem',
  boxShadow: '$popup',
  border: 'borderWidths.$1px solid colors.$grey5',
  borderRadius: '0.1875rem',
  width: '15.625rem',
  backgroundColor: '$white',
  cursor: 'default',
  pointerEvents: 'auto',
  outline: 'none',
});

const PersonalDataContainer = styled('div', {
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
});

const AvatarStyled = styled(Avatar, {
  marginLeft: '1rem',
});

const TextContainer = styled('div', {
  width: '11rem',
  marginLeft: '0.75rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const Text = styled(
  'div',
  {
    fontSize: '0.8125rem',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  {
    color: {
      dark: {
        color: '$dark',
      },
      light: {
        color: '$grey6',
      },
    },
  },
);

const OptionsContainer = styled('div', {
  border: 'borderWidths.$1px solid colors.$grey5',
  borderWidth: '0.0625rem 0 0 0',
  padding: '0.3125rem 0',
  display: 'flex',
  flexDirection: 'column',
});

const Option = styled(Link, {
  'textAlign': 'left',
  'display': 'flex',
  'alignItems': 'center',
  'height': '2rem',
  'paddingLeft': '1rem',
  'color': '$dark',
  'fontSize': '0.8125rem',
  'cursor': 'pointer',
  ':hover': {
    backgroundColor: '$grey1',
  },
});
