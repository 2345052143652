import { styled } from '@taraai/design-system';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';

type SelectButtonProps = { description: string; openPopup: () => void };

export const SelectButton = ({ description, openPopup }: SelectButtonProps): JSX.Element => (
  <FilterButton color='ghost' name={description} onClick={openPopup} size='compact'>
    <span>{description}</span>
    <ArrowIcon name='expdown' />
  </FilterButton>
);

const FilterButton = styled(Button, {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const ArrowIcon = styled(Icon, {
  padding: 0,
});
