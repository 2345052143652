import { Data } from '@taraai/types';
import CommitListItem from 'components/app/controllers/views/CommitListItem';
import GitHubFallback from 'components/app/controllers/views/GitHubFallback';
import ListView from 'components/core/layouts/ListView';
import { css, cx } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { sort } from 'tools/libraries/helpers/sort';
import { InstallGitHubData } from 'types/install-integration';

export interface LastCheckInProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Array of Tara Commits objects
   */
  commits?: Data.Commit[];
  organizationID: Data.Id.OrganizationId;
  installGitHubData: InstallGitHubData;
}

/**
 * A view of the Last Check in
 *
 *  - [Designs](https://zpl.io/VqkNWRY)
 */
export default function LastCheckIn({
  className,
  commits,
  organizationID,
  installGitHubData,
  ...props
}: LastCheckInProps): JSX.Element {
  const sortedCommits = sort(commits || [], 'createdAt', true);

  const shouldShowCommits = installGitHubData.id && commits && commits.length > 0;
  return (
    <div
      className={cx(
        css`
          padding: 1.25rem 1rem 1.25rem 0;
          flex-grow: 1;
          flex-shrink: 1;
        `,
        className,
      )}
    >
      <div
        className={css`
          height: 100%;
          position: relative;
          min-height: 15.625rem;
          max-height: 28.125rem;
          border-radius: 0.1875rem;
          box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.08);
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        `}
        {...props}
      >
        <h2
          className={css`
            font-size: 1rem;
            font-weight: 500;
            padding: 1.5rem 1rem 0.75rem;
          `}
        >
          {strings.lastCheckIn.title}
        </h2>
        {shouldShowCommits ? (
          <>
            <div
              className={css`
                border-bottom: 0.0625rem solid ${atomic.get(atomic.colors.grey3)};
                width: 100%;
              `}
            />
            <div
              className={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: ${atomic.get(atomic.colors.grey6)};
                font-size: 0.75rem;
                font-weight: 500;
                padding: 1rem 1rem 0.25rem;
              `}
            >
              <p>{strings.lastCheckIn.teamMember}</p>
              <p>{strings.lastCheckIn.lastCommitted}</p>
            </div>
            <ListView
              childrenClassName={css`
                padding: 0.25rem 0;
              `}
              className={css`
                padding: 0 1rem 0.75rem;
              `}
            >
              {sortedCommits.map((commit: Data.Commit) => (
                <CommitListItem key={commit.sha} commit={commit} />
              ))}
            </ListView>
          </>
        ) : (
          <GitHubFallback
            icon='commit'
            iconClassName={css`
              width: 4.25rem;
              height: 1.25rem;
            `}
            installGitHubData={installGitHubData}
            organizationID={organizationID}
            title={strings.lastCheckIn.empty.title}
          />
        )}
      </div>
    </div>
  );
}
