import { Data, UI } from '@taraai/types';
import Sprint from 'components/app/controllers/Sprint';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { atomic, strings } from 'resources';

type SprintFragment = Pick<
  UI.UISprint,
  'id' | 'sprintName' | 'initialStartDate' | 'initialEndDate' | 'completedAt' | 'computedOnCompletion' | 'isComplete'
>;

type Props = {
  sprints: SprintFragment[];
  additionalSprintsToRender: boolean;
  currentSprintId?: Data.Id.SprintId;
  isComplete?: boolean;
  forwardedRef: React.RefObject<HTMLDivElement>;
  handleOnScroll: () => void;
};

export default function SprintsRowView({
  sprints,
  currentSprintId,
  isComplete,
  forwardedRef,
  additionalSprintsToRender,
  handleOnScroll,
}: Props): JSX.Element {
  return (
    <div
      ref={forwardedRef}
      className={css`
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        overflow-x: auto;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
        & > div:first-child {
          margin-left: 1rem;
        }
      `}
      onScroll={handleOnScroll}
    >
      {sprints.map((sprint) => (
        <Sprint
          key={sprint.id}
          className={css`
            margin: 0rem 0.75rem;
          `}
          currentSprintId={currentSprintId}
          isActive={sprint.id === currentSprintId}
          isComplete={!!isComplete}
          isFirstSprint={sprints && sprints[0].id === sprint.id}
          sprint={sprint}
        />
      ))}
      {additionalSprintsToRender && (
        <div
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 100%;
            flex-direction: column;
            ${sprints[0] && ' min-width: 8rem;'}
          `}
        >
          <FastSmallSpinner
            color={atomic.get(atomic.colors.grey6)}
            size={1.5}
            spinnerStyles={css`
              margin-bottom: 0.3rem;
            `}
          />
          <Text
            className={css`
              text-align: center;
            `}
            color={atomic.get(atomic.colors.grey6)}
          >
            {strings.sprints.loadingSprints}
          </Text>
        </div>
      )}
      <div
        className={css`
          margin-left: calc(0.5rem - 0.0625rem);
          min-width: 0.0625rem;
        `}
      />
    </div>
  );
}
