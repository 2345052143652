import Icon from 'components/core/controllers/views/Icon';
import InputGroup from 'components/core/controllers/views/InputGroup/InputGroup';
import { css } from 'emotion';
import React from 'react';
import { workspaceTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { InputProps } from 'tools';

export function WorkspaceURLInput({ bottomLabel, error, onChange, value }: InputProps): JSX.Element {
  const showValidMessage = !error && bottomLabel.trim().length > 0;

  return (
    <div
      className={css`
        position: relative;
        width: 100%;
        margin: 0.75rem 0 1.75rem;
      `}
    >
      <InputGroup
        bottomLabel={bottomLabel}
        bottomLabelStyle={css`
          font-size: 0.875rem;
          color: ${error ? atomic.get(atomic.colors.error) : atomic.get(atomic.colors.success)};
          margin-left: ${showValidMessage ? '1.25rem' : '0'};
        `}
        className={inputGroupClassName}
        containerStyle={css`
          height: 3rem;
          margin: 0.75rem 0 0.25rem 0;
          background-color: transparent;

          ${atomic.transition({
            duration: '.2s',
          })} :focus {
            outline: 0;
          }
          &:focus-within {
            ${atomic.border({
              style: 'solid',
              width: atomic.theme.space.input.border.width.regular,
              color: atomic.theme.color.input.border.focus,
              radius: atomic.theme.space.input.border.radius.regular,
            })}
            ${atomic.transition()}
          }
        `}
        data-cy={workspaceTestIDs.URL_INPUT}
        label={strings.createWorkspace.labels.workspaceUrl}
        labelStyle={css`
          font-size: 1rem;
          line-height: 1;
          color: ${atomic.get(atomic.colors.dark)};
        `}
        onChange={onChange}
        placeholder={strings.createWorkspace.placeholders.workspaceUrl}
        prepend={strings.createWorkspace.labels.taraRootUrl}
        prependStyle={css`
          align-items: center;
          border-right: none;
          font-size: 0.875rem;
          line-height: normal;
          padding: 0.5rem 0 0.5rem 1rem;
        `}
        value={value}
      />
      <Icon
        className={css`
          display: ${showValidMessage ? 'block' : 'none'};
          position: absolute;
          bottom: 0.3rem;
          left: 0;
          padding: 0;
          width: 0.875rem;
          height: 0.625rem;
          color: ${atomic.get(atomic.colors.success)};
        `}
        name='tick'
      />
    </div>
  );
}

const inputGroupClassName = css`
  color: #1d98ff;
  font-size: 0.875rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
  outline: 0;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    padding-right: 1rem;
  }
`;
