import 'draft-js/dist/Draft.css';

import { noop } from '@taraai/utility';
import { DraftHandleValue, Editor as DraftJSEditor, EditorState, SelectionState } from 'draft-js';
import { cx } from 'emotion';
import React, { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { DispatchWithCallback } from 'tools';

import {
  blockStyleFn,
  createKeyCommandHandler,
  createReturnHandler,
  customStyleMap,
  DraftKeyHandler,
  getBlockRendererFn,
  hasUserInput,
  styleForKeyboardEvent,
} from './helpers';
import { CONTAINER_STYLE, Style, STYLES } from './styles';

export type DraftEditorWrapperProps = {
  className?: string;
  editorRef: RefObject<DraftJSEditor> | null;
  editorState: EditorState;
  setEditorState: DispatchWithCallback<EditorState>;
  setStyle: (style: Style) => void;
  focusEditor?: () => void;
  onBlur?: () => void;
  onChange?: (state: EditorState) => void;
  onFocus?: () => void;
  onDroppedFiles?: (selection: SelectionState, files: Blob[]) => Promise<void>;
  onPastedFiles?: (files: Blob[]) => Promise<void>;
  onReturn?: DraftKeyHandler;
  onShiftReturn?: DraftKeyHandler;
  placeholder?: string;
  readOnly?: boolean;
};

export function DraftEditorWrapper({
  className,
  editorRef,
  editorState,
  setEditorState,
  setStyle,
  focusEditor = noop,
  onBlur = noop,
  onChange = noop,
  onFocus = noop,
  onDroppedFiles,
  onPastedFiles,
  onReturn,
  onShiftReturn,
  placeholder,
  readOnly = false,
}: DraftEditorWrapperProps): JSX.Element | null {
  const containerRef = useRef<HTMLDivElement>(null);
  const handleContainerClick = useCallback(
    (event) => {
      if (containerRef.current) {
        // focus editor when click originated from within the editor container
        // and editor is not already focused
        const isClickWithinContainer = containerRef.current.contains(event.target);
        const editorHasFocus = editorState.getSelection().getHasFocus();
        if (isClickWithinContainer && !editorHasFocus) {
          focusEditor();
        }
      }
    },
    [editorState, focusEditor],
  );

  const handleDroppedFiles = useCallback(
    (selection: SelectionState, files: Blob[]): DraftHandleValue => {
      if (onDroppedFiles) {
        onDroppedFiles(selection, files);
        return STYLES.DRAFTHANDLEVALUE.HANDLED;
      }
      return STYLES.DRAFTHANDLEVALUE.NOTHANDLED;
    },
    [onDroppedFiles],
  );

  const handlePastedFiles = useCallback(
    (files: Blob[]): DraftHandleValue => {
      if (onPastedFiles) {
        onPastedFiles(files);
        return STYLES.DRAFTHANDLEVALUE.HANDLED;
      }
      return STYLES.DRAFTHANDLEVALUE.NOTHANDLED;
    },
    [onPastedFiles],
  );

  const keyBindingFn = useCallback(
    (event: React.KeyboardEvent): string | null => {
      const style = styleForKeyboardEvent(event);
      if (style) {
        setStyle(style);
      }
      return style;
    },
    [setStyle],
  );

  const editorStateRef = useRef<EditorState | null>(null);
  const getEditorState = useCallback((): EditorState | null => {
    return editorStateRef.current;
  }, []);

  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);

  const showPlaceholder = useMemo(() => !!placeholder && !hasUserInput(editorState), [editorState, placeholder]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      ref={containerRef}
      className={cx(CONTAINER_STYLE, className)}
      onClick={handleContainerClick}
      role='button'
      tabIndex={0}
    >
      <DraftJSEditor
        ref={editorRef}
        blockRendererFn={getBlockRendererFn({
          getEditorState,
          setEditorState,
        })}
        blockStyleFn={blockStyleFn}
        customStyleMap={customStyleMap}
        editorState={editorState}
        handleDroppedFiles={handleDroppedFiles}
        handleKeyCommand={createKeyCommandHandler({
          getEditorState,
          setEditorState,
        })}
        handlePastedFiles={handlePastedFiles}
        handleReturn={createReturnHandler({
          setEditorState,
          onReturn,
          onShiftReturn,
        })}
        keyBindingFn={keyBindingFn}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={showPlaceholder ? placeholder : undefined}
        readOnly={readOnly}
        spellCheck
      />
    </div>
  );
}
