import { unwrapResult } from '@reduxjs/toolkit';
import { actions, Step } from 'components/app/Onboarding/FlowWrapper';
import { useCallback } from 'react';
import { finishOnboarding, reduxStore } from 'reduxStore';

import { Form } from './Form';

export const MoveTasksStep: Step = ({ children, dispatch, withTasksAndSprints }) => {
  const onNext = useCallback(async () => {
    dispatch(actions.finishOnboarding());

    await withTasksAndSprints((orgId, taskIds, upcomingSprintIds) => {
      const nonEmptyTaskIds = taskIds.filter((id) => id);
      return reduxStore
        .dispatch(
          finishOnboarding({
            orgId,
            taskChangesets: [
              { id: nonEmptyTaskIds[0], sprint: upcomingSprintIds[0] },
              { id: nonEmptyTaskIds[1], sprint: upcomingSprintIds[0] },
              { id: nonEmptyTaskIds[2], sprint: upcomingSprintIds[1] },
            ].filter(({ id }) => id),
          }),
        )
        .then(unwrapResult);
    })();
  }, [dispatch, withTasksAndSprints]);

  return children({
    childProps: {},
    Form,
    onNext,
  });
};
