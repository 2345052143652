import { Data } from '@taraai/types';
import { linkTo, usePathParams } from 'components/Router/paths';
import React from 'react';
import { Redirect, useParams } from 'react-router';
import { getPendingIntegrationOrg } from 'tools/utils/pendingIntegrationOrg';

import { InstallableServiceWithRedirects, isInstallableServiceWithRedirects } from './interfaces';
import { SetupIntegration } from './SetupIntegration';
import { WorkspaceSelect } from './WorkspaceSelect';

function getIntegrationPayloadFromURL(): Record<string, string> {
  const paramsSearcher = new URLSearchParams(window.location.search);
  return Object.fromEntries(paramsSearcher.entries());
}

const isOrgIdRequired: Record<InstallableServiceWithRedirects, (urlPayload: Record<string, string>) => boolean> = {
  github: (urlPayload) => urlPayload.setup_action !== 'update',
  slack: () => true,
};

/**
 * Gets orgId from the cookie and renders SetupIntegration
 * If orgId isn't in cookies the user is presented with WorkspaceSelect
 */
export function SetupIntegrationController(): JSX.Element {
  const { service } = usePathParams('setupIntegration');

  const { teamID } = useParams<{
    teamID: Data.Id.TeamId;
  }>();

  if (!isInstallableServiceWithRedirects(service)) {
    return <Redirect to={linkTo('notFound')} />;
  }

  const orgId = getPendingIntegrationOrg();
  const urlPayload = getIntegrationPayloadFromURL();

  if (!orgId && isOrgIdRequired[service](urlPayload)) {
    return <WorkspaceSelect service={service} urlPayload={urlPayload} />;
  }

  return <SetupIntegration orgID={orgId} service={service} teamID={teamID} urlPayload={urlPayload} />;
}
