import styled from '@emotion/styled';
import TeamsController from 'components/app/controllers/TeamsController';
import WorkspaceController from 'components/app/controllers/WorkspaceController';
import IntegrationsLayout from 'components/app/layouts/IntegrationsLayout';
import UsersLayout from 'components/app/layouts/UsersLayout';
import TabBar from 'components/core/controllers/views/TabBar';
import Text from 'components/core/controllers/views/Text';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export default function MyWorkspaceLayout(): JSX.Element {
  return (
    <Layout>
      <Header>{strings.workspace.myWorkspace}</Header>
      <TabBar
        tabbedContent={[
          {
            label: strings.workspace.tabs.workspace,
            route: strings.workspace.tabs.workspace,
            content: (
              <ContentWrapper>
                <WorkspaceController />
              </ContentWrapper>
            ),
          },
          {
            label: strings.workspace.tabs.users,
            route: strings.workspace.tabs.users,
            content: (
              <ContentWrapper>
                <UsersLayout />
              </ContentWrapper>
            ),
          },
          {
            label: strings.workspace.tabs.teams,
            route: strings.workspace.tabs.teams,
            content: (
              <ContentWrapper>
                <TeamsController />
              </ContentWrapper>
            ),
          },
          {
            label: strings.workspace.tabs.integrations,
            route: strings.workspace.tabs.integrations,
            content: (
              <ContentWrapper>
                <IntegrationsLayout />
              </ContentWrapper>
            ),
          },
        ]}
        tabsSeparatorBottomMargin='none'
        tabsWrapperVariant='settings'
      />
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const Header = styled((props) => <Text {...props} />)`
  background-color: ${atomic.get(atomic.colors.grey1)};
  color: #405161;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 1.5rem 7rem 1.5rem 5rem;
`;

const ContentWrapper = styled.section`
  position: relative;
  padding: 2.5rem 7rem 0 5rem;
  height: 100%;
`;
