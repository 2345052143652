import { Data } from '@taraai/types';
import { getPath, linkTo } from 'components/Router/paths';
import React from 'react';
import { homePageTestIDs } from 'resources/cypress/testAttributesValues';

import { NavigationIcon } from './NavigationIcon';
import { NavigationItem } from './NavigationItem';
import { NavigationLink } from './NavigationLink';

type NavigationListElementsProps = {
  orgID?: Data.Id.OrganizationId;
  teamID?: Data.Id.TeamId;
};

export const NavigationListElements: React.FC<NavigationListElementsProps> = ({
  orgID,
  teamID,
}: NavigationListElementsProps) => {
  if (orgID && teamID) {
    return (
      <>
        <NavigationLink
          dataCy={homePageTestIDs.HOME_ICON}
          icon='home'
          title='Home'
          to={linkTo('home', { orgID, teamID })}
        />
        <NavigationLink icon='builder' title='Requirements' to={linkTo('requirements', { orgID, teamID })} />
        <NavigationLink icon='sprints' title='Sprint Plans' to={linkTo('sprints', { orgID, teamID })} />
        <NavigationLink
          icon='progress'
          matchOn={getPath('sprintDetails')}
          title='Sprint Reports'
          to={linkTo('sprintDetails', { orgID, teamID, sprintID: 'current' })}
        />
      </>
    );
  }

  return (
    <>
      <NavigationItem dataCy={homePageTestIDs.HOME_ICON} title='Home'>
        <NavigationIcon dataCy={homePageTestIDs.HOME_ICON} name='home' />
      </NavigationItem>

      <NavigationItem title='Requirements'>
        <NavigationIcon name='builder' />
      </NavigationItem>

      <NavigationItem active title='Sprint Plans'>
        <NavigationIcon name='sprints' />
      </NavigationItem>

      <NavigationItem title='Sprint Reports'>
        <NavigationIcon name='progress' />
      </NavigationItem>
    </>
  );
};
