/* eslint-disable @typescript-eslint/no-explicit-any */

import debounce from 'lodash.debounce';
import { useMemo, useRef } from 'react';

/**
 * Returns debounced version of input function.
 *
 * It allows for debouncing functions that depend on the state of the component.
 * If input function changes it will persist internal `debounce()` state while updating the function.
 */
export function useDebounced<Callback extends (...args: any[]) => any>(callback: Callback, wait: number): Callback {
  const latestCallback = useRef(callback);
  latestCallback.current = callback;

  const latestWait = useRef(wait);
  if (process.env.NODE_ENV !== 'production' && wait !== latestWait.current) {
    throw new Error('Modifying the wait time is not supported because it can lead to an unpredictable behavior');
  }

  return useMemo(() => debounce(((...args) => latestCallback.current(...args)) as Callback, latestWait.current), []);
}
