import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import DraggableFeatureCardList from 'components/app/controllers/DraggableFeatureCardList';
import SprintColumnHeader from 'components/app/controllers/SprintColumnHeader';
import SprintEffort from 'components/app/controllers/SprintEffort';
import TaskModalContext from 'components/app/controllers/TaskModalContext';
import { AreaDropZone } from 'components/app/controllers/views/AreaDropZone';
import CreateTaskInput from 'components/app/controllers/views/CreateTaskInput';
import {
  DnDContext,
  DragSource,
  DragStatus,
} from 'components/app/controllers/views/DraggableFeatureCard/DragAndDropContext';
import EmptySprintColumn from 'components/app/controllers/views/EmptySprintColumn';
import SortMenu from 'components/app/controllers/views/SortMenu';
import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import { MenuOption } from 'components/core/controllers/views/Menu/menuTypes';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css, cx } from 'emotion';
import { History } from 'history';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import { Link, useHistory, useParams } from 'react-router-dom';
import { deleteSprint, getSprintTasks, reduxStore, selectTeam } from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { atomic } from 'resources';
import { sprintColumnSortMenuItems, sprintsTabTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { CustomSorts, sort } from 'tools/libraries/helpers/sort';

type SprintFragment = Pick<
  UI.UISprint,
  'id' | 'sprintName' | 'initialStartDate' | 'initialEndDate' | 'completedAt' | 'computedOnCompletion' | 'isComplete'
>;

export interface SprintProps extends React.HTMLProps<HTMLDivElement> {
  sprint: SprintFragment;
  isActive: boolean;
  isComplete: boolean;
  currentSprintId: Data.Id.SprintId | undefined;
  isFirstSprint?: boolean;
}

const shouldDropZoneBeDisabled = (dragStatus: DragStatus, sprintId: Data.Id.SprintId): boolean =>
  dragStatus.isDragging && dragStatus.dragSource.type === 'sprint' && dragStatus.dragSource.sprintId === sprintId;

const getMenuOptions = (
  history: History,
  orgID: Data.Id.OrganizationId,
  teamID: Data.Id.TeamId,
  isActive: boolean,
  sprint: SprintFragment | undefined,
  isComplete: boolean,
  deleteSelectedSprint: () => void,
): MenuOption[] => {
  if (isActive || isComplete)
    return [
      {
        title: strings.sprints.featureCard.view,
        onSelect: (): void => {
          history.push(
            linkTo('sprintDetails', {
              orgID,
              teamID,
              sprintID: sprint?.id ?? '',
            }),
          );
        },
      },
    ];

  return [
    {
      title: strings.tasks.delete,
      onSelect: deleteSelectedSprint,
      dataCy: sprintsTabTestIDs.DELETE_SPRINT,
    },
  ];
};

/**
 *
 * We need to get the sprint ID from the sprint column
 * With the sprint ID we will do a filter and map through
 * All the high level tasks. When a high level task sprint matches
 * with our current sprint then we can render it in the sprint column
 *
 * FIXME: Because this component talks directly to the Store, it cannot be
 * tested in Storybook
 *
 */
export default function Sprint({
  sprint,
  isActive,
  isComplete,
  className,
  currentSprintId,
  isFirstSprint,
}: SprintProps): JSX.Element {
  const history = useHistory();
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();
  const sprintTasks = getSprintTasks(orgID, sprint?.id);
  useFirestoreConnect(sprintTasks.query);
  const team = useSelector(selectTeam(orgID, teamID));

  const [tasksLoading, setTasksLoading] = useState(true);
  const [isRemoving, setIsRemoving] = useState(false);
  const { openModal } = useContext(TaskModalContext);
  const { addToast } = useToast();
  const [sortFilter, setSortFilter] = useState<keyof CustomSorts | null>(null);
  const dragSource = useMemo((): DragSource => ({ type: 'sprint', sprintId: sprint.id }), [sprint.id]);
  const allTasksSelector = createSelector(
    [(): keyof CustomSorts | null => sortFilter, sprintTasks.selector],
    (filter, allTasks) => allTasks && sort(allTasks, filter ?? 'taskAssigneeNameStatus'),
  );
  const tasksData = useSelector(allTasksSelector);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tasks: UI.UITask[] = tasksData ?? [];

  useEffect(() => {
    if (tasksData !== undefined) setTasksLoading(false);
  }, [tasksData]);

  const moveTaskToBacklog = useCallback(
    (taskRef: string): void => {
      reduxStore
        .dispatch(updateTask({ id: taskRef, sprint: null }))
        .then(unwrapResult)
        .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
    },
    [addToast],
  );

  const moveTaskToSprint = useCallback(
    (taskRef: string, sprintID: string): void => {
      reduxStore
        .dispatch(updateTask({ id: taskRef, sprint: sprintID }))
        .then(unwrapResult)
        .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
    },
    [addToast],
  );

  const deleteSelectedSprint = useCallback(() => {
    setIsRemoving(true);
    reduxStore
      .dispatch(
        deleteSprint({
          id: sprint?.id,
        }),
      )
      .then(unwrapResult)
      .then((success) => {
        const message = strings
          .formatString(strings.sprints.deletedSuccess, {
            sprintName: success.sprintName,
            numberOfTasks: success.remainingTasksCount,
          })
          .toString();
        return addToast({
          message,
          timeoutMs: 5500,
          type: 'success',
        });
      })
      .catch((error: Error) => {
        setIsRemoving(false);
        const message = strings
          .formatString(strings.sprints.deletingFailure, {
            errorMessage: error.message,
          })
          .toString();
        addToast({ message, type: 'error' });
      });
  }, [addToast, sprint]);

  const toggleModal = useCallback(
    (task) => {
      openModal(task, allTasksSelector, sprint.sprintName);
    },
    [allTasksSelector, openModal, sprint.sprintName],
  );

  const sprintId = sprint?.id;

  const { globalDragStatus } = useContext(DnDContext);
  const dropZoneDisabled = shouldDropZoneBeDisabled(globalDragStatus, sprintId);

  const menuOptions = getMenuOptions(history, orgID, teamID, isActive, sprint, isComplete, deleteSelectedSprint);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortOptions = [
    {
      dataCy: sprintColumnSortMenuItems.NONE,
      title: strings.sprints.sortBy.none,
      onSelect: useCallback(() => {
        setSortFilter(null);
      }, []),
    },
    {
      dataCy: sprintColumnSortMenuItems.TASK_TITLE_AZ,
      title: strings.sprints.sortBy.az,
      onSelect: useCallback(() => {
        setSortFilter('taskTitleAscending');
      }, []),
    },
    {
      dataCy: sprintColumnSortMenuItems.TASK_TITLE_ZA,
      title: strings.sprints.sortBy.za,
      onSelect: useCallback(() => {
        setSortFilter('taskTitleDescending');
      }, []),
    },
    {
      dataCy: sprintColumnSortMenuItems.ASSIGNEE,
      title: strings.sprints.sortBy.assignee,
      onSelect: useCallback(() => {
        setSortFilter('taskAssigneeNameStatus');
      }, []),
    },
    {
      dataCy: sprintColumnSortMenuItems.TASK_STATUS,
      title: strings.sprints.sortBy.status,
      onSelect: useCallback(() => {
        setSortFilter('taskStatusByAssignee');
      }, []),
    },
    {
      dataCy: sprintColumnSortMenuItems.DATE_CREATED_OLDEST,
      title: strings.sprints.sortBy.oldest,
      onSelect: useCallback(() => {
        setSortFilter('createdAt');
      }, []),
    },
    {
      dataCy: sprintColumnSortMenuItems.DATE_CREATED_NEWEST,
      title: strings.sprints.sortBy.newest,
      onSelect: useCallback(() => {
        setSortFilter('createdAtDesc');
      }, []),
    },
  ];

  return useMemo(
    () => (
      <div
        className={cx(
          css`
            display: flex;
            flex-direction: column;
            min-height: 0;
            width: 25.875rem;
            position: relative;
          `,
          className,
        )}
      >
        {isRemoving && (
          <div
            className={css`
              display: flex;
              flex-direction: column;
              position: absolute;
              z-index: 10;
              background-color: black;
              opacity: 50%;
              border-radius: 0.375rem;
              min-width: 100%;
              min-height: 100%;
              flex-grow: 1;
              align-items: center;
              justify-content: center;
            `}
          >
            <FastSmallSpinner
              color={atomic.get(atomic.colors.white)}
              size={1.5}
              spinnerStyles={css`
                margin-bottom: 0.3rem;
              `}
            />
            <Text color={atomic.get(atomic.colors.white)}>{strings.sprints.deleting}</Text>
          </div>
        )}
        {tasksLoading && (
          <div
            className={css`
              display: flex;
              flex-direction: column;
              position: absolute;
              z-index: 10;
              min-width: 100%;
              min-height: 100%;
              flex-grow: 1;
              align-items: center;
              justify-content: center;
            `}
          >
            <FastSmallSpinner
              color={atomic.get(atomic.colors.grey6)}
              size={1.5}
              spinnerStyles={css`
                margin-bottom: 0.3rem;
              `}
            />
            <Text color={atomic.get(atomic.colors.grey6)}>{strings.sprints.loadingTasks}</Text>
          </div>
        )}
        <SprintColumnHeader
          currentSprintId={currentSprintId}
          isActive={isActive}
          isComplete={isComplete}
          isFirstSprint={isFirstSprint}
          sprint={sprint}
          tasks={tasks}
        />
        <AreaDropZone
          className={css`
            display: flex;
            flex-direction: column;
            border: 0.0625rem solid #eaeef5;
            border-radius: 0.375rem;
            width: 25.875rem;
            min-height: 0;
            flex-grow: 1;
            background-color: #ffffff;
          `}
          disable={dropZoneDisabled}
          label={
            strings.formatString(strings.sprints.dropZone.sprint, {
              sprint: sprint?.sprintName,
            }) as string
          }
          onItemDrop={(item): void => {
            moveTaskToSprint(item.id, sprintId);
          }}
          overlayClassName={css`
            border-radius: 0.375rem;
          `}
        >
          <div
            className={css`
              width: 25.75rem;
              min-height: 0.5rem;
              background-color: ${isActive ? '#389e0d' : 'white'};
              border-radius: 0.375rem 0.375rem 0rem 0rem;
            `}
          />
          <div
            className={css`
              padding: 0.5rem 1rem;
              display: flex;
              align-items: center;
              min-height: fit-content;
              position: relative;
              justify-content: space-between;
            `}
          >
            <div
              className={css`
                display: flex;
              `}
            >
              {isActive || isComplete ? (
                <Link
                  className={css`
                    :hover {
                      text-decoration: underline;
                    }
                  `}
                  data-cy={sprintsTabTestIDs.VIEW_SPRINT_DETAILS}
                  to={linkTo('sprintDetails', {
                    orgID,
                    teamID,
                    sprintID: sprint.id,
                  })}
                >
                  {sprint.sprintName}
                </Link>
              ) : (
                sprint.sprintName
              )}
            </div>
            <div
              className={css`
                display: flex;
                align-items: center;
              `}
            >
              <SprintEffort currentSprint={sprint} orgID={orgID} tasks={tasks} />
              <SortMenu isFiltering={sortFilter !== null} options={sortOptions} />
              {!team?.sprintSettings.autoSprints ? (
                <Menu options={menuOptions} position='bottomLeft'>
                  <Icon
                    className={css`
                      fill: #949caf;
                      width: 0.875rem;
                      height: 1.375rem;
                      padding: 0.3125rem 0.125rem 0rem 0.5rem;
                      cursor: pointer;
                    `}
                    data-cy={sprintsTabTestIDs.THREE_DOT_MENU_SPRINT}
                    name='meatballs'
                  />
                </Menu>
              ) : null}
            </div>
          </div>
          <hr
            className={css`
              border: 0;
              min-height: 0.0625rem;
              background: #eaeef5;
            `}
          />
          <div
            className={css`
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              min-height: 0;
            `}
          >
            <div
              className={css`
                display: flex;
                min-height: 0;
                flex-direction: column;
                flex-grow: 1;
                background-color: #f4f4fa;
                margin: 1rem;
                border: 0.0625rem solid #eaeef5;
                border-radius: 0.1875rem;
              `}
            >
              {!isComplete && (
                <CreateTaskInput dataCy={sprintsTabTestIDs.NEW_TASK_INPUT_SPRINT_COLUMN} noTopBorder sprint={sprint} />
              )}
              {!isComplete && !tasksLoading && isEmpty(tasks) && <EmptySprintColumn />}
              <DraggableFeatureCardList
                className={css`
                  overflow: auto;
                  padding: 0.5rem 0.5rem;
                  padding-bottom: 0rem;
                `}
                dragSource={dragSource}
                onMoveToBacklog={moveTaskToBacklog}
                onMoveToSprint={moveTaskToSprint}
                sprintColumn
                tasks={tasks}
                toggleModal={toggleModal}
              />
            </div>
          </div>
        </AreaDropZone>
      </div>
    ),
    [
      className,
      isRemoving,
      tasksLoading,
      currentSprintId,
      isActive,
      isComplete,
      isFirstSprint,
      sprint,
      tasks,
      dropZoneDisabled,
      orgID,
      teamID,
      sortFilter,
      sortOptions,
      menuOptions,
      dragSource,
      moveTaskToBacklog,
      moveTaskToSprint,
      toggleModal,
      sprintId,
      team,
    ],
  );
}
