import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources';
import { strings } from 'resources/i18n';

/**
 * SprintsBacklogHeader does…
 *
 */
export default function SprintsBacklogHeader(): JSX.Element {
  return (
    <Text
      className={css`
        font-size: 1rem;
        font-weight: 500;
      `}
      color={atomic.get(atomic.colors.grey7)}
    >
      {strings.sprints.backlogTitle}
    </Text>
  );
}
