import { Attachment } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React, { useMemo, useState } from 'react';

type Props = {
  attachment: Attachment;
  onRemove: (attachment: Attachment) => void;
};

export default function EditorAttachment({ attachment, onRemove }: Props): JSX.Element {
  const [isRemoving, setIsRemoving] = useState(false);
  return useMemo(
    () => (
      <a
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.3rem;
          background-color: #eef1f7;
          min-width: 1rem;
          min-height: 1rem;
          display: flex;
          margin: 0.2rem;
          width: fit-content;
        `}
        href={attachment.url}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Icon
          className={css`
            fill: #949caf;
            height: 0.8rem;
            width: 0.8rem;
          `}
          name='attachment'
        />
        {attachment.name}
        {isRemoving ? (
          <FastSmallSpinner />
        ) : (
          <Icon
            className={css`
              fill: #949caf;
              height: 0.7rem;
              width: 0.7rem;
            `}
            name='close'
            onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
              event.preventDefault();
              setIsRemoving(true);
              onRemove(attachment);
            }}
          />
        )}
      </a>
    ),
    [attachment, isRemoving, onRemove],
  );
}
