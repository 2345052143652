import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';

export const getIntegrationSecret = (
  orgId: Data.Id.OrganizationId,
  integrationId: Data.Id.IntegrationId | undefined,
): Query<UI.UIIntegrationSecret> => {
  if (![orgId, integrationId].every(isNonEmptyString)) {
    return inertQuery();
  }
  const integrationsQuery: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgId}/integrations/${integrationId}/secrets`,
    storeAs: `org-${orgId}-integrations-${integrationId}-secrets`,
  };

  return {
    query: [integrationsQuery],
    selector: createStandardSelector(integrationsQuery),
  };
};
