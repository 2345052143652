import { UI } from '@taraai/types';
import ReviewersList from 'components/app/controllers/views/ReviewersList';
import Avatar from 'components/core/controllers/views/Avatar';
import { css, cx } from 'emotion';
import React, { useRef } from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { formatIdWithRepositoryName, timeSince, toDate } from 'tools/libraries/helpers';
import { useClickOutside } from 'tools/utils/hooks';

export type PullRequestDetailsProps = React.HTMLProps<HTMLDivElement> & {
  pullRequest: UI.UIPullRequest;
  onClose: () => void;
};

const PullRequestDetails: React.FC<PullRequestDetailsProps> = ({
  className,
  pullRequest,
  onClose,
}: PullRequestDetailsProps) => {
  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, onClose);
  const createdAtString = timeSince(toDate(pullRequest.createdAt));
  return (
    <div
      ref={popupRef}
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          width: 18.75rem;
          border-radius: 3px;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
          border: solid 1px ${atomic.get(atomic.colors.grey5)};
          background-color: ${atomic.get(atomic.colors.white)};
          z-index: 100;
          padding-top: 1rem;
        `,
        className,
      )}
    >
      <a
        className={css`
          color: ${atomic.get(atomic.colors.secondary)};
          font-size: 0.75rem;
          padding: 0 0.75rem 0 0.75rem;
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        `}
        href={pullRequest.url}
        rel='noopener noreferrer'
        target='_blank'
      >
        {strings.formatString(strings.pullRequestListItem.issueID, {
          id: formatIdWithRepositoryName(pullRequest),
        })}
      </a>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0.5rem 0.75rem 0.75rem 0.75rem;
        `}
      >
        <a
          className={css`
            font-size: 0.875rem;
            color: ${atomic.get(atomic.colors.dark)};
            overflow: hidden;
            position: relative;
            line-height: 1em;
            max-height: 3em;
            margin-right: -1em;
            padding-right: 1em;
            text-decoration: none;
            text-align: left;
            :before {
              content: '...';
              position: absolute;
              right: 0;
              bottom: 0;
            }
            :after {
              content: '';
              position: absolute;
              right: 0;
              width: 1em;
              height: 1em;
              margin-top: 0.2em;
              background: ${atomic.get(atomic.colors.white)};
            }
            :hover {
              text-decoration: underline;
            }
            :hover::before {
              text-decoration: underline;
            }
          `}
          href={pullRequest.url}
          rel='noopener noreferrer'
          target='_blank'
          title={pullRequest.title}
        >
          {pullRequest.title}
        </a>
        <p
          className={css`
            font-size: 0.75rem;
            font-style: italic;
            text-align: right;
            color: ${atomic.get(atomic.colors.grey6)};
            padding-left: 1em;
            white-space: nowrap;
          `}
        >
          <span title={toDate(pullRequest.createdAt).toLocaleDateString()}>{createdAtString}</span>
        </p>
      </div>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          padding: 0.75rem 0.75rem 0.75rem 0.75rem;
          box-shadow: 0 -1px 0 0 ${atomic.get(atomic.colors.grey3)};
        `}
      >
        <div>
          <p
            className={css`
              font-size: 0.75rem;
              font-weight: 500;
              color: ${atomic.get(atomic.colors.grey6)};
              text-transform: uppercase;
            `}
          >
            {strings.pullRequestDetails.assignee}
          </p>
          <Avatar
            className={css`
              margin: 0.5rem 0;
              padding: 0;
              flex-shrink: 0;
            `}
            icon='github'
            size='32px'
            user={pullRequest.user}
          />
        </div>
        <ReviewersList
          className={css`
            margin-left: 3.125rem;
          `}
          reviewers={pullRequest.reviewers}
        />
      </div>
    </div>
  );
};

export default PullRequestDetails;
