import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { UIUser } from '@taraai/types/src/ui';
import { RootState } from 'reduxStore/store';
import { selectActiveUsers } from 'reduxStore/users/selectors';

const attachEmailToUser = (emails: Record<string, string>) => (user: UIUser): UIUser => ({
  ...user,
  email: emails[user?.id],
});

export const selectOrgUsersWithEmails = createSelector(
  (state: RootState): RootState => state,
  (none: unknown, orgId: Data.Id.OrganizationId): Data.Id.OrganizationId => orgId,
  (state: RootState, orgId: Data.Id.OrganizationId) => state.userEmails,
);

export const selectActiveUsersWithEmails = (orgID: Data.Id.OrganizationId): Selector<RootState, UIUser[] | undefined> =>
  createSelector(
    [selectActiveUsers(orgID), (state: RootState) => selectOrgUsersWithEmails(state, orgID)],
    (activeUsers, emails) => {
      if (!emails.orgEmails) {
        return activeUsers;
      }

      return activeUsers.map(attachEmailToUser(emails.orgEmails));
    },
  );
