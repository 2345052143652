import { keyframes } from '@emotion/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import RemovedTaskView from 'components/app/controllers/views/RemovedTaskView';
import TaskModal from 'components/app/controllers/views/TaskModal';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { getTaskBySlug, reduxStore, taskSubTasks } from 'reduxStore';
import { getTaskComments } from 'reduxStore/comments/queries';
import { getTaskGitData } from 'reduxStore/git-task-lifecycle/queries/task-git-data';
import { deleteTask } from 'reduxStore/tasks/actions';
import { getTaskRevision } from 'reduxStore/tasks/queries/taskRevisionQueries';
import { strings } from 'resources';
import { useToast } from 'tools';

export interface TaskControllerProps {
  slugID: string;
}

/**
 * TaskController
 * top level controller for high level task data
 */
export default function TaskController({ slugID }: TaskControllerProps): JSX.Element {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const taskBySlugSlice = getTaskBySlug(orgID, slugID);
  const task = useSelector(taskBySlugSlice.selector);
  const taskID = task?.id || '';
  const subTasksSlice = taskSubTasks(orgID, taskID);
  const gitDataSlice = getTaskGitData(orgID, slugID);
  const revisionsSlice = getTaskRevision(orgID, taskID);
  const commentsSlice = getTaskComments(orgID, taskID);

  useFirestoreConnect([
    ...taskBySlugSlice.query,
    ...revisionsSlice.query,
    ...subTasksSlice.query,
    ...gitDataSlice.query,
    ...commentsSlice.query,
  ]);

  const gitData = useSelector(gitDataSlice.selectors.all());
  const subtasks = useSelector(subTasksSlice.selector) || [];
  const revisions = useSelector(revisionsSlice.selectors.all()) || [];
  const taskComments = useSelector(commentsSlice.selector)?.filter(({ deletedAt }) => deletedAt === null);

  const { addToast } = useToast();

  const deleteCurrentTask = (): void => {
    if (subtasks.length) {
      subtasks.map((subtask) => {
        return reduxStore
          .dispatch(deleteTask({ id: subtask.id } as Pick<UI.UITask, 'id'>))
          .then(unwrapResult)
          .catch(() => {
            addToast({
              type: 'error',
              message: strings.task.failedToDeleteTask,
            });
          });
      });
    }
    reduxStore
      .dispatch(deleteTask({ id: task?.id } as Pick<UI.UITask, 'id'>))
      .then(unwrapResult)
      .then(() =>
        addToast({
          message: `${strings.formatString(strings.task.deleted, {
            slug: task?.slug ?? '',
          })}`,
          timeoutMs: 3500,
          type: 'success',
        }),
      )
      .catch(() => {
        addToast({ type: 'error', message: strings.task.failedToDeleteTask });
      });
  };

  const copyTaskID = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgID}/*/tasks/${slugID}`);
    setTaskCopiedText(true);
  };

  if (
    !isLoaded(task) ||
    !isLoaded(taskID) ||
    !isLoaded(subtasks) ||
    !isLoaded(revisions) ||
    !isLoaded(gitData) ||
    !isLoaded(taskComments)
  ) {
    return (
      <StandardSpinner
        className={css`
          animation: ${fadeIn} 2s ease-in-out;
        `}
        fillSpace
        size='medium'
      />
    );
  }

  if (task.deleted) {
    return <RemovedTaskView currentOrg={orgID} />;
  }

  // eslint-disable-next-line xss/no-mixed-html
  return (
    <DndProvider backend={HTML5Backend}>
      <TaskModal
        author={task.authorDocument}
        copyTaskID={copyTaskID}
        currentOrg={orgID}
        deleteCurrentTask={deleteCurrentTask}
        gitData={gitData}
        noModal
        revisions={revisions}
        setTaskCopiedText={setTaskCopiedText}
        task={task}
        taskComments={taskComments}
        taskCopiedText={taskCopiedText}
      />
    </DndProvider>
  );
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
