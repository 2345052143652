import { css, cx } from 'emotion';
import React from 'react';
import { TaraLogoIcon } from 'resources/assets';
import { strings } from 'resources/i18n';

/**
 * A Floating animation version for the Tara Logo Icon
 *
 */
export default function FloatingLogo({ className }: React.HTMLProps<HTMLDivElement>): JSX.Element {
  return (
    <div
      className={cx(
        css`
          width: 6.25rem;
          height: 6.5625rem;
        `,
        className,
      )}
    >
      <img
        alt={strings.logo.tara}
        className={css`
          @keyframes Floating {
            from {
              transform: translate(0, 0rem);
            }
            50% {
              transform: translate(0, 0.4688rem);
            }
            to {
              transform: translate(0, -0rem);
            }
          }
          width: 6.25rem;
          margin-bottom: 1.5625rem;
          display: block;
          -webkit-animation-name: Floating;
          -webkit-animation-duration: 3.5s;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-timing-function: ease-in-out;
        `}
        src={TaraLogoIcon}
      />
      <div
        className={css`
          @keyframes Scale {
            0% {
              width: 4.0625rem;
            }
            50% {
              width: 4.6875rem;
            }
            100% {
              width: 4.0625rem;
            }
          }
          margin: auto;
          background-color: #eaeef5;
          width: 5rem;
          height: 0.625rem;
          border-radius: 100%;
          display: block;
          -webkit-animation-name: Scale;
          -webkit-animation-duration: 3.5s;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-timing-function: ease-in-out;
        `}
      />
    </div>
  );
}
