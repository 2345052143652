import { NotificationBar } from 'components/core/controllers/views/NotificationBar';
import { css } from 'emotion';
import React from 'react';
import { IconName } from 'resources';
import { strings } from 'resources/i18n';

export interface ErrorImportNotificationBarProps {
  errorCopy: string;
  tryAgainLinkLabel?: string;
  onTryAgainRequest: () => void;
  onCloseRequest: () => void;
  iconName: IconName;
}

export function ErrorImportNotificationBarView({
  iconName,
  onCloseRequest,
  errorCopy,
  onTryAgainRequest,
  tryAgainLinkLabel = strings.importNotificationBar.defaultTryAgainCopy,
}: ErrorImportNotificationBarProps): JSX.Element {
  return (
    <NotificationBar bgColor='#be2320' iconName={iconName} onCloseRequest={onCloseRequest} showCloseBtn>
      <div>{errorCopy}</div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={css`
          margin-left: 0.2rem;
          text-decoration: underline;
        `}
        href='#'
        onClick={onTryAgainRequest}
      >
        {tryAgainLinkLabel}
      </a>
    </NotificationBar>
  );
}
