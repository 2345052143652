import styled from '@emotion/styled';
import { UI } from '@taraai/types';
import Logo from 'components/core/controllers/views/Logo';
import React from 'react';

export const LogoContainer = styled.div`
  position: relative;
  width: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`;

type WorkspaceSwitcherNavButtonProps = {
  selectedOrg: UI.UIOrganization;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
  dataCy?: string;
};

export function WorkspaceSwitcherNavButton({
  selectedOrg,
  onClick,
  children,
  dataCy,
}: WorkspaceSwitcherNavButtonProps): JSX.Element {
  return (
    <LogoContainer data-cy={dataCy} onClick={onClick}>
      <Logo
        color={selectedOrg.color}
        dataCy={dataCy}
        name={selectedOrg.name}
        shape='square'
        size='medium'
        url={selectedOrg.logoURL}
      />
      {children}
    </LogoContainer>
  );
}
