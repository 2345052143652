import { Selector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import SprintDetailsHeaderController from 'components/app/controllers/SprintDetailsHeaderController';
import SprintDetailsSummary from 'components/app/controllers/SprintDetailsSummary';
import SprintDetailsTimeline from 'components/app/controllers/SprintDetailsTimeline';
import SprintDetailsNoActiveSprints from 'components/app/controllers/views/SprintDetailsNoActiveSprints';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React from 'react';
import { RootState } from 'reduxStore/store';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;

export interface SprintDetailsLayoutProps {
  organizationID: Data.Id.OrganizationId;
  currentSprintId: string | undefined;
  selectedSprint: UI.UISprint | undefined;
  requirements: RequirementFragment[];
  today?: Date;
  nextSprintID: Data.Id.SprintId | null;
  previousSprintID: Data.Id.SprintId | null;
  isLoaded: boolean;
  tasksSelector: Selector<RootState, UI.UITask[] | undefined>;
}
/**
 * Layout for the Sprint Details page and takes in a current sprint to pass to child component controllers and views
 */

export default function SprintDetailsLayout({
  selectedSprint,
  requirements,
  organizationID,
  today = new Date(),
  nextSprintID,
  previousSprintID,
  currentSprintId,
  isLoaded,
  tasksSelector,
}: SprintDetailsLayoutProps): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
    >
      {isLoaded ? (
        <div
          className={css`
            width: 100%;
            height: 100%;
            overflow: auto;
            padding: 0rem 1.875rem 0rem 1.875rem;
          `}
        >
          <SprintDetailsHeaderController
            nextSprintID={nextSprintID}
            organizationID={organizationID}
            previousSprintID={previousSprintID}
            selectedSprint={selectedSprint}
          />
          {selectedSprint ? (
            <>
              <SprintDetailsTimeline
                currentDate={today}
                organizationID={organizationID}
                requirements={requirements}
                selectedSprint={selectedSprint}
                tasksSelector={tasksSelector}
              />
              {/* This flag is to prevent details summary from showing up
      on completed sprint details */}
              {selectedSprint?.isComplete ? null : (
                <SprintDetailsSummary
                  currentSprintId={currentSprintId}
                  organizationID={organizationID}
                  sprint={selectedSprint}
                />
              )}
            </>
          ) : (
            <SprintDetailsNoActiveSprints />
          )}
        </div>
      ) : (
        <StandardSpinner fillSpace size='medium' />
      )}
    </div>
  );
}
