const ORIGINAL_ROUTE_KEY = 'original_route';

export const setOriginalRouteCookie = (originalRoute: string): void => {
  localStorage.setItem(ORIGINAL_ROUTE_KEY, originalRoute);
};

export const getOriginalRouteCookie = (): string | null => {
  return localStorage.getItem(ORIGINAL_ROUTE_KEY);
};

export const removeOriginalRouteCookie = (): void => {
  localStorage.removeItem(ORIGINAL_ROUTE_KEY);
};
