import { isNonEmptyString } from '@taraai/utility';

export type ImageMetadata = {
  width: number;
  height: number;
};

export const getImageMetadata = async (url?: string): Promise<ImageMetadata | null> => {
  if (!isNonEmptyString(url)) {
    return null;
  }
  return new Promise<ImageMetadata | null>((resolve) => {
    const img = new Image();
    img.onerror = (): void => {
      resolve(null);
    };
    img.onabort = (): void => {
      resolve(null);
    };
    img.onload = (): void => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = url;
  });
};
