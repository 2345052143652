import { NotificationBar } from 'components/core/controllers/views/NotificationBar';
import { css } from 'emotion';
import React from 'react';
import { IconName } from 'resources';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export interface ImportNotificationBarViewProps {
  iconName: IconName;
  bgColor: string;
  importInProgressCopy: string;
  onCloseRequest: () => void;
  hasImportFinished: boolean;
  importFinishedCopy?: string | string[];
  importedTasksLinkLabel?: string;
  onViewImportedTasksRequest: () => void;
}

export const ImportNotificationBarView = ({
  onCloseRequest,
  iconName,
  bgColor,
  importInProgressCopy,
  hasImportFinished,
  importFinishedCopy = strings.importNotificationBar.defaultImportFinishedCopy,
  onViewImportedTasksRequest,
  importedTasksLinkLabel = strings.importNotificationBar.defaultImportedTasksLinkLabel,
}: ImportNotificationBarViewProps): JSX.Element | null => {
  if (hasImportFinished) {
    return (
      <NotificationBar
        bgColor={atomic.get(atomic.colors.success)}
        iconName={iconName}
        onCloseRequest={onCloseRequest}
        showCloseBtn
      >
        <div>{importFinishedCopy}</div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={css`
            margin-left: 0.2rem;
            text-decoration: underline;
          `}
          href='#'
          onClick={onViewImportedTasksRequest}
        >
          {importedTasksLinkLabel}
        </a>
      </NotificationBar>
    );
  }

  return (
    <NotificationBar bgColor={bgColor} iconName={iconName} isLoading>
      {importInProgressCopy}
    </NotificationBar>
  );
};
