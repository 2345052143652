/* eslint-disable */
import { createSelector, Selector } from '@reduxjs/toolkit';
import Tara from '@taraai/types';
import { populate, ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import shallowequal from 'shallowequal';

export type QuerySelector<T extends Tara.Identifiable> = Selector<RootState, T[] | undefined>;

export type IndividualSelector<T extends Tara.Identifiable> = Selector<RootState, T | undefined>;

export interface Query<T extends Tara.Identifiable> {
  query: ReduxFirestoreQuerySetting[];
  selector: QuerySelector<T>;
}

export interface IndividualQuery<T extends Tara.Identifiable> {
  query: ReduxFirestoreQuerySetting[];
  selector: IndividualSelector<T>;
}

interface ReduxFirestorePopulate {
  root: string;
  child: string;
  childAlias: string;
}
type ID = string;
type Populates =
  | {
      root: string;
      child: string;
      childAlias: string;
    }[]
  | undefined;

export type PopulateTuples = [string, ReduxFirestoreQuerySetting][];

const scrubForRRFLib = /\/|\./g;
// @fix: Current bug with Redux-firestore does NOT match generated alias with populations.
// forward slashes need to be removed from automatically generated alias on queries
export const generateAlias = (query: ReduxFirestoreQuerySetting | string): string =>
  typeof query === 'string'
    ? query
    : query?.storeAs ?? serialize(query as Record<string, unknown>).replace(scrubForRRFLib, '');

export function createStandardSelector<T extends Tara.Identifiable>(
  parent: ReduxFirestoreQuerySetting | string,
  maps?: PopulateTuples,
): QuerySelector<T> {
  const parentStoreAs = generateAlias(parent);

  if (typeof parent !== 'string' && !parent.storeAs) {
    parent.storeAs = parentStoreAs;
  }

  maps?.forEach(([_, query]) => {
    if (typeof query !== 'string' && !query.storeAs) {
      query.storeAs = generateAlias(query);
    }
  });

  const populates = createPopulates(maps);

  const populatedRaw = (state: RootState): Record<ID, T> => createPopulatedRaw(state, populates, parentStoreAs);

  const getOrdered = (state: RootState) => state?.firestore?.ordered[parentStoreAs] as T[] | undefined;

  const getPopulated = createMemoizeAliasesSelector(parent, populatedRaw, maps);

  return createSelector([getOrdered, getPopulated], (ordered, populated) => {
    // no data in ordered, data is still loading
    if (!ordered) {
      return undefined;
    }

    const arr: T[] = [];
    ordered.forEach((doc) => {
      const document = populated[doc.id];
      if (document) {
        arr.push(document);
      }
    });
    return arr;
  });
}

export function createIndividualSelector<T extends Tara.Identifiable>(
  id: string,
  parent: ReduxFirestoreQuerySetting | string,
  maps?: PopulateTuples,
): IndividualSelector<T> {
  type OptionalType = T | undefined;

  const parentStoreAs = generateAlias(parent);

  if (typeof parent !== 'string' && !parent.storeAs) {
    parent.storeAs = parentStoreAs;
  }

  const populates = createPopulates(maps);

  const populatedRaw = (state: RootState): Record<ID, T> => createPopulatedRaw(state, populates, parentStoreAs);

  const getPopulated = createMemoizeAliasesSelector(parent, populatedRaw, maps);

  return createSelector<RootState, Record<string, T>, OptionalType>(getPopulated, (populated) =>
    !populated?.[id] ? undefined : { ...populated[id], id },
  );
}

export function inertQuery<T extends Tara.Identifiable>(): Query<T> {
  return {
    query: [],
    selector: () => undefined,
  };
}

export function inertIndividualQuery<T extends Tara.Identifiable>(): IndividualQuery<T> {
  return {
    query: [],
    selector: () => undefined,
  };
}

function createPopulates(maps: PopulateTuples | undefined) {
  return maps?.map(([property, query]) => ({
    root: generateAlias(query),
    child: property,
    childAlias: `${property.replace(/(.+)\./, '')}Document`,
  }));
}

function createPopulatedRaw<T extends Tara.Identifiable>(
  state: RootState,
  populates: Populates,
  parentStoreAs: string,
): Record<ID, T> {
  if (!populates) return state?.firestore?.data[parentStoreAs];

  // @see https://github.com/prescottprue/react-redux-firebase/blob/fe7a4cef10003bc62a08eb915dc53b146055cce9/src/helpers.js#L292-L389
  performance?.mark && performance.mark('@rrf/populated');

  // Default populate is all or nothing. Instead compose all available models
  const merged = populates.reduce((parentData, child) => {
    const nextMerged = populate(
      {
        data: {
          [parentStoreAs]: parentData,
          [child.root]: state?.firestore.data[child.root] ?? {},
        },
      },
      parentStoreAs,
      [child],
      undefined,
    );
    return nextMerged ?? parentData;
  }, state?.firestore.data[parentStoreAs] ?? {});

  performance?.measure && performance.measure('@rrf/populated', '@rrf/populated');

  const childNotFound = merged === undefined;
  if (childNotFound) {
    return state?.firestore?.data[parentStoreAs];
  }

  return merged;
}

function createMemoizeAliasesSelector<T>(
  parent: ReduxFirestoreQuerySetting | string,
  populated_raw: (state: RootState) => Record<ID, T>,
  children?: Array<[string, ReduxFirestoreQuerySetting | string]>,
) {
  const parentStoreAs = generateAlias(parent);
  const childStoreAs =
    children?.map(([_, query]) => {
      if (typeof query === 'string') return query;
      if (typeof query.storeAs === 'string') return query.storeAs;
      return generateAlias(query);
    }) || [];

  const alias = [parentStoreAs, ...childStoreAs];
  // first selector is for rrf/populate call, rest are used for memoization
  const selectors = [
    (state: RootState) => state,
    ...alias.map((alias) => (state: RootState) => {
      return state?.firestore?.data[alias];
    }),
  ];

  // always ignore changes to state but use default memoization for alias
  // @see https://github.com/reduxjs/reselect#customize-equalitycheck-for-defaultmemoize
  const createSelectorIgnoringState = createSelectorCreator(defaultMemoize, (previous: any, current: any): boolean => {
    const isRootState = !!(previous?.firestore && previous?.firebase);
    if (isRootState) return true;
    return shallowequal(previous, current);
  });

  // return memoize populate call which will be ran less frequently
  return createSelectorIgnoringState(selectors, populated_raw);
}

// Redux-firestore internal query-object-to-string function
export function serialize(queryParams: any) {
  return Object.keys(queryParams)
    .filter((key) => queryParams[key] !== undefined)
    .map((key) => arrayToStr(key, queryParams[key] as string))
    .join('&');
}

function arrayToStr(key: string, value: string | string[]): unknown {
  if (typeof value === 'string' || value instanceof String || value instanceof Number) {
    return `${key}=${value}`;
  }
  const first: unknown = value[0];
  if (typeof first === 'string' || first instanceof String) {
    return `${key}=${value.join(':')}`;
  }
  if (value && typeof value.toString === 'function') {
    return `${key}=${value.toString()}`;
  }

  return value.map((val) => arrayToStr(key, val));
}
