import RequirementBuilderController from 'components/app/controllers/RequirementBuilderController';
import React from 'react';

/**
 * RequirementBuilder does…
 * Page that renders all components for a requirement
 */
export default function RequirementBuilder(): JSX.Element {
  return <RequirementBuilderController />;
}
