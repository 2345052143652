import DraftMention from 'components/core/controllers/DraftMention';
import { useEditor } from 'components/core/controllers/Editor';
import { mentionStrategy } from 'components/core/controllers/Editor/decorators';
import { DraftEditorWrapper } from 'components/core/controllers/Editor/DraftEditorWrapper';
import { convertRawMarkdownToEditorState } from 'components/core/controllers/Editor/helpers';
import { upgradeEditorState } from 'components/core/controllers/Editor/mention';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { CompositeDecorator, EditorState } from 'draft-js';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

interface CommentDraftContentProps {
  content: string;
}

export default function CommentDraftContent({ content }: CommentDraftContentProps): JSX.Element {
  const { orgID } = useParams<{ orgID: string }>();

  const { editorRef, editorState, setEditorState, getEditorState, setStyle } = useEditor();

  const editorDecorator = new CompositeDecorator([
    {
      strategy: mentionStrategy,
      component: DraftMention,
      props: {
        getEditorState,
      },
    },
  ]);

  const getUserMentionTag = useUserTagForId(orgID);

  useEffect(() => {
    const descritpionEditorState = convertRawMarkdownToEditorState(
      content,
      editorState,
      getEditorState,
      setEditorState,
    );
    const upgradedWithMentions = upgradeEditorState(descritpionEditorState, getUserMentionTag);
    const withDecorator = EditorState.set(upgradedWithMentions, {
      decorator: editorDecorator,
    });
    setEditorState(withDecorator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, setEditorState]);

  return (
    <DraftEditorWrapper
      editorRef={editorRef}
      editorState={editorState}
      readOnly
      setEditorState={setEditorState}
      setStyle={setStyle}
    />
  );
}
