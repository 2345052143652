import { UI } from '@taraai/types';
import { css, cx } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

type RequirementFragment = Pick<UI.UIRequirement, 'backlogTasksCount'>;
export interface TaskCountProps extends React.HTMLProps<HTMLDivElement> {
  requirement: RequirementFragment;
}

export default function TaskCount({ className, requirement, ...props }: TaskCountProps): JSX.Element {
  const taskCount = requirement.backlogTasksCount;
  return (
    <div
      className={cx(
        css`
          font-size: 0.875rem;
          font-weight: normal;
          color: #949caf;
          padding-top: 0.25rem;
        `,
        className,
      )}
      {...props}
    >
      {Number.isInteger(taskCount) &&
        strings.formatString(strings.sprints.taskCount, {
          number: taskCount,
        })}
    </div>
  );
}
