import { UI } from '@taraai/types';
import RequirementsView from 'components/app/controllers/views/RequirementsView';
import { OrgIDParam } from 'components/Router/Router';
import Fuse from 'fuse.js';
import React, { useCallback, useEffect, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { getArchivedRequirements, getOrgTeams, getRequirements } from 'reduxStore';
import {
  filterTaraRequirements,
  getRequirementAssignedTeamsOptions,
  getResults,
} from 'tools/libraries/helpers/tableFilter';

/**
 * RequirementsController
 * A controller to query all the data for the requirements page
 *
 */
export const RequirementsController: React.FC = () => {
  const { orgID } = useParams<OrgIDParam>();
  const [searchResults, setSearchResults] = useState<UI.UIRequirement[]>([]);
  const [filterResults, setFilterResults] = useState<UI.UIRequirement[]>([]);
  const [finalResults, setFinalResults] = useState<UI.UIRequirement[]>([]);

  const activeRequirementsSlice = getRequirements(orgID, null, {
    orderBy: 'title',
  });
  const archivedRequirementsSlice = getArchivedRequirements(orgID, {
    orderBy: 'title',
  });

  useFirestoreConnect([...activeRequirementsSlice.query, ...archivedRequirementsSlice.query]);

  const activeRequirements = useSelector(activeRequirementsSlice.selector);
  const archivedRequirements = useSelector(archivedRequirementsSlice.selector);

  const teams = useSelector(
    compose((data = []) => data.map(({ id, name }) => ({ id, name })), getOrgTeams(orgID).selector),
    deepEquals,
  );

  const defaultOptions = getRequirementAssignedTeamsOptions(teams);

  const clearResults = useCallback(() => {
    setSearchResults([]);
    setFilterResults([]);
    setFinalResults([]);
  }, []);

  useEffect(() => {
    const combinedResults = getResults(searchResults, filterResults);
    setFinalResults(combinedResults);
  }, [filterResults, searchResults]);

  const handleFilter = useCallback(
    (
      requirementsToFilter: UI.UIRequirement[],
      collectionToFilter: keyof Pick<UI.UIRequirement, 'assignedTeamIds'>,
      itemsToFilter: (string | number)[],
    ) => {
      if (itemsToFilter.length === 0) {
        setFilterResults([]);
        return;
      }

      const filtered = filterTaraRequirements(requirementsToFilter, collectionToFilter, itemsToFilter);
      setFilterResults(filtered);
    },
    [],
  );

  const handleSearch = useCallback(
    (data: UI.UIRequirement[], query: string) => {
      const fuse = new Fuse(data, fuseOptions);
      const fuseResults = fuse.search(query).map(({ item }) => item) as unknown;
      setSearchResults((fuseResults as unknown) as UI.UIRequirement[]);
    },
    [setSearchResults],
  );

  if (!isLoaded(activeRequirements) || !isLoaded(archivedRequirements)) return null;

  return (
    <RequirementsView
      activeRequirements={activeRequirements}
      archivedRequirements={archivedRequirements}
      clearResults={clearResults}
      filteredRequirements={finalResults}
      handleFilter={handleFilter}
      handleSearch={handleSearch}
      statusFilterOptions={defaultOptions}
      teams={teams}
    />
  );
};

/**
 * The options for fusejs.
 * @see https://fusejs.io/
 */
const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  includeScore: false,
  keys: ['title', 'authorDocument.name'],
};
