import { css, cx } from 'emotion';
import React from 'react';
import { FeatureCardDragItem, useDropFeatureCard } from 'tools/utils/hooks/dragAndDrop';

import { AreaOverlay } from './AreaOverlay';

type AreaDropZoneProps = {
  label?: string;
  onItemDrop: (item: FeatureCardDragItem) => void;
  disable?: boolean;
  overlayClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const AreaDropZone: React.FC<AreaDropZoneProps> = ({
  children,
  className,
  onItemDrop,
  overlayClassName,
  disable = false,
  label = 'Drop card here',
}: AreaDropZoneProps) => {
  const [{ isOver }, dropZoneRef] = useDropFeatureCard(onItemDrop, { disable });
  return (
    <div
      ref={dropZoneRef}
      className={cx(
        css`
          position: relative;
        `,
        className,
      )}
    >
      {isOver && <AreaOverlay className={overlayClassName}>{label}</AreaOverlay>}
      {children}
    </div>
  );
};
