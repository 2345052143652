import { createEntity, getEntityData } from 'components/editor/entities';
import { hasPunctuation, hasWhitespace, Part, reconcileEntities, takeWhile } from 'components/editor/plugins/utils';
import { ContentBlock, ContentState, EditorState, Modifier, SelectionState } from 'draft-js';

import { matchLink } from './linkifyIt';

interface UrlPart extends Part {
  url?: string;
}

/**
 * This method is the main engine of the link plugin. There are several things happening here.
 *
 * First the "parts" are computed, that is the text surrounding the selection on both sides.
 * If there are any standard links not separated from the text by whitespace, they are treated as
 * plain text and are present in the parts (since the change may expand or remove those links, for
 * example "[zombo.com]?" + "q" becomes "[zombo.com?q]", where [...] denote a link).
 *
 * Then the selection is replaced with `chars` since the initial content state is not needed.
 *
 * After that a "before" state is computed based on the parts. It's a representation of text and
 * entities joined together with `chars` after replacing the selection and it simulates the default
 * Draft.js behavior. This state is needed to decide if the plugin should handle the event. After
 * all, if there are no changes to be made by the plugin, it should return "not-handled" and pass
 * control to other plugins in the pipeline.
 *
 * The "after" state is computed from the "before" state in the following manner: `chars` may or may
 * not get forced entityKey based on the heuristic described in `getCharsPart`, and then all URL
 * matches are added with the text between them.
 *
 * ; afterwards the reconciliation happens and any changes are applied to the content state
 *
 * If there are any differences between "before" and "after", that means Draft.js default behavior
 * is not enough, and so the modified `content` is returned; otherwise the original content is
 * returned.
 */
export function replaceAndReconcile(chars: string, editorState: EditorState, selection: SelectionState): ContentState {
  let content = editorState.getCurrentContent();
  const inlineStyle = chars ? editorState.getCurrentInlineStyle() : undefined;
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const urlToEntityKey = new Map<string, string>();
  // Compute the parts
  const prefix = getNeighborParts(
    urlToEntityKey,
    content,
    content.getBlockForKey(startKey),
    selection.getStartOffset(),
    true,
  );
  const { startOffset } = prefix;
  const suffix = getNeighborParts(urlToEntityKey, content, content.getBlockForKey(endKey), selection.getEndOffset());
  // Replace the characters so that the content is in the `before` state (minus entities)
  content = Modifier.replaceText(content, selection, chars, inlineStyle);
  const midPart = getMidPart(chars, prefix.parts, suffix.parts);
  let before: UrlPart[];
  let after: UrlPart[];
  if (midPart?.after[0].entityKey) {
    ({ before, after } = midPart);
  } else {
    ({ content, before, after } = getBeforeAndAfterState(content, urlToEntityKey, [
      ...prefix.parts,
      ...(midPart?.before ?? []),
      ...suffix.parts,
    ]));
  }
  // Apply differences between `before` and `after` and return `true` if there were any
  const nextContent = reconcileEntities(content, startKey, startOffset, before, after);
  if (nextContent !== content) {
    // Only set the selection if there were changes
    return nextContent.set('selectionAfter', getSelectionFromStart(selection, chars.length)) as ContentState;
  }
  return editorState.getCurrentContent();
}

function getNeighborParts(
  urlToEntityKey: Map<string, string>,
  content: ContentState,
  block: ContentBlock,
  anchorOffset: number,
  isBackward = false,
): { parts: UrlPart[]; startOffset: number } {
  const parts: UrlPart[] = [];
  const { offset, text, entityKey } = takeWhile(block, anchorOffset, isBackward, {
    bailOnWhitespace: true,
    entityKey: null,
  });
  let startOffset = offset;
  if (text) {
    parts.push({ text, entityKey: null });
  }
  if (entityKey && content.getEntity(entityKey).getType() === 'LINK') {
    const linkStart = takeWhile(block, offset, true, { entityKey });
    const linkEnd = takeWhile(block, offset, false, { entityKey });
    const { url } = getEntityData('LINK', content, entityKey);
    if (isStandardLink(`${linkStart.text}${linkEnd.text}`, url)) {
      parts.push({
        text: isBackward ? linkStart.text : linkEnd.text,
        entityKey,
        url,
      });
      urlToEntityKey.set(url, entityKey);
      startOffset = linkStart.offset;
    } else {
      parts.push({ text: '', entityKey });
    }
  }
  if (isBackward) {
    parts.reverse();
  }
  return { parts, startOffset: isBackward ? startOffset : anchorOffset };
}

// The link is a standard link if it matches the output from linkify-it
function isStandardLink(text: string, url: string): boolean {
  const matches = matchLink(text);
  return matches?.length === 1 && matches[0].raw === text && matches[0].url === url;
}

function getMidPart(
  chars: string,
  prefixParts: UrlPart[],
  suffixParts: UrlPart[],
): { before: [UrlPart]; after: [UrlPart] } | undefined {
  if (!chars) {
    return;
  }
  const prefixPart = prefixParts.length === 0 ? undefined : prefixParts[prefixParts.length - 1];
  const suffixPart = suffixParts.length === 0 ? undefined : suffixParts[0];
  /**
   * `entityKey` is the key that Draft.js would assign if the link plugin didn't handle input;
   * it's non-null only in the middle of an entity, and not on its ends.
   * Beware, in older versions character was appended to the entity on the left which, if restored,
   * would break our logic.
   */
  const entityKey =
    prefixPart?.entityKey && prefixPart.entityKey === suffixPart?.entityKey ? prefixPart.entityKey : undefined;
  /**
   * `nextEntityKey` is the entity key we want to force after transformation.
   * This only happens when a character is appended to a custom link (but then it has to be a word
   * character) or when it is added in the middle of a custom link. The value will always be either
   * the `entityKey` of the preceding character or `undefined`.
   */
  const nextEntityKey =
    !prefixPart?.text && (entityKey || (!hasWhitespace(chars) && !hasPunctuation(chars)))
      ? prefixPart?.entityKey
      : undefined;
  return {
    before: [{ text: chars, entityKey: entityKey ?? null }],
    after: [{ text: chars, entityKey: nextEntityKey ?? null }],
  };
}

function getBeforeAndAfterState(
  content: ContentState,
  urlToEntityKey: Map<string, string>,
  before: UrlPart[],
): { content: ContentState; before: UrlPart[]; after: UrlPart[] } {
  let nextContent = content;
  const after: UrlPart[] = [];
  const text = before.map((part) => part.text).join('');
  const matches = matchLink(text);
  let lastIndex = 0;
  matches?.forEach((match) => {
    let entityKey = urlToEntityKey.get(match.url);
    if (!entityKey) {
      ({ contentWithEntity: nextContent, key: entityKey } = createEntity(
        'LINK',
        'MUTABLE',
        { url: match.url },
        nextContent,
      ));
    }
    after.push(
      { text: text.slice(lastIndex, match.index), entityKey: null },
      { text: match.raw, entityKey, url: match.url },
    );
    lastIndex = match.lastIndex;
  });
  after.push({ text: text.slice(lastIndex), entityKey: null });
  return { content: nextContent, before, after };
}

function getSelectionFromStart(selection: SelectionState, offset = 0, size = 0): SelectionState {
  const anchorKey = selection.getStartKey();
  const anchorOffset = selection.getStartOffset() + offset;
  return selection.merge({
    anchorKey,
    anchorOffset,
    focusKey: anchorKey,
    focusOffset: anchorOffset + size,
    isBackward: false,
  }) as SelectionState;
}
