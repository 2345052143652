import { styled } from '@taraai/design-system';
import React from 'react';
import { navigationDrawerButtons } from 'resources/cypress/testAttributesValues';

type TeamSwitcherButtonProps = {
  selectedTeamName: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
  interactive: boolean;
};

export function TeamSwitcherButton({
  selectedTeamName,
  onClick,
  children,
  interactive,
}: TeamSwitcherButtonProps): JSX.Element {
  return (
    <Container data-cy={navigationDrawerButtons.WORKSPACE_SWITCHER} interactive={interactive} onClick={onClick}>
      {selectedTeamName}
      <ArrowIndicator />
      {children}
    </Container>
  );
}

const Container = styled(
  'button',
  {
    background: 'none',
    border: 'none',
    outline: 'inherit',
    position: 'relative',
    height: '$full',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '$dark',
    lineHeight: 'inherit',
  },
  {
    interactive: {
      true: {
        pointerEvents: 'auto',
      },
      false: {
        pointerEvents: 'none',
      },
    },
  },
);

const ArrowIndicator = styled('div', {
  width: '0',
  height: '0',
  borderStyle: 'solid',
  borderWidth: '0.1875rem 0.1875rem 0 0.1875rem',
  borderColor: '$grey7 transparent transparent transparent',
  marginLeft: '0.3125rem',
  marginRight: '1.3rem',
  marginBottom: '0.1rem',
});
