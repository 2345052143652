import 'draft-js/dist/Draft.css';

import { styled } from '@taraai/design-system';
import { Editor, EditorState } from 'draft-js';
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';

import { RichEditorContext } from './RichEditorProvider';
import { blockStyleFn, containerStyle, hidePlaceholderStyle, styleMap } from './styles';
import { withSelection } from './utils';

interface Props {
  isTitle?: boolean;
  placeholder?: string;
}

const DefaultStylesWrapper = styled('div', containerStyle, {
  /** Used to hide placeholder text (eg. when editor is focused) */
  hidePlaceholder: {
    true: hidePlaceholderStyle,
  },
  isTitle: {
    true: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
  },
  singleLine: {
    true: {
      '.public-DraftStyleDefault-block': {
        whiteSpace: 'pre',
        overflowX: 'hidden',
      },
    },
  },
});

export interface RichEditorHandle {
  focus: () => void;
  blur: () => void;
  clear: (hasFocus?: boolean) => void;
}

export const RichEditor = forwardRef<RichEditorHandle, Props>(function RichEditor(
  { isTitle, placeholder },
  ref,
): JSX.Element {
  const editor = useRef<Editor>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { editorState, handlers, onChange, readOnly, setEditorState, singleLine } = useContext(RichEditorContext);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => editor.current?.focus(),
      blur: () => editor.current?.blur(),
      // TODO: move this to RichEditorProvider after the pipeline refactor
      clear: (hasFocus = false) =>
        setEditorState((currentEditorState) => {
          const emptyEditorState = EditorState.createEmpty(currentEditorState.getDecorator());
          return withSelection(emptyEditorState, {
            anchorKey: emptyEditorState.getCurrentContent().getFirstBlock().getKey(),
            anchorOffset: 0,
            hasFocus,
          });
        }),
    }),
    [setEditorState],
  );

  const isEditorFocused = editorState.getSelection().getHasFocus();

  return (
    <DefaultStylesWrapper ref={wrapperRef} hidePlaceholder={isEditorFocused} isTitle={isTitle} singleLine={singleLine}>
      <Editor
        ref={editor}
        blockStyleFn={blockStyleFn}
        customStyleMap={styleMap}
        editorState={editorState}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        {...handlers}
      />
    </DefaultStylesWrapper>
  );
});
