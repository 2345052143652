import { UI } from '@taraai/types';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { css, cx } from 'emotion';
import React, { useEffect, useMemo, useRef } from 'react';

export interface DropdownOptionProps extends React.HTMLProps<HTMLDivElement> {
  option: UI.UIUser | UI.UIRequirement;
  onOptionClick: (event: React.SyntheticEvent) => void;
  isSelected: boolean;
  highlighted: boolean;
  handleInputOnKeyDown: (event: React.SyntheticEvent) => void;
}

/**
 * DropdownOption
 * option in dropdown component
 *
 */
export default function DropdownOption({
  className,
  option,
  onOptionClick,
  isSelected,
  highlighted,
  handleInputOnKeyDown,
  ...props
}: DropdownOptionProps): JSX.Element {
  const optionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (highlighted && optionRef.current) {
      optionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [highlighted]);

  return useMemo(
    () => (
      <div
        {...props}
        key={option.id}
        ref={optionRef}
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem 1rem;
          background-color: ${highlighted && '#fbfbfd'};
          :hover {
            background-color: #fbfbfd;
            span:last-child {
              opacity: ${highlighted && 0.6};
            }
          }
        `}
        data-choice={option.id}
        onClick={onOptionClick}
        onKeyDown={handleInputOnKeyDown}
        role='button'
        tabIndex={0}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
          `}
          data-choice={option.id}
        >
          {(option as UI.UIUser).name ? (
            <Avatar dataChoice={(option as UI.UIUser).id} size='2.5rem' user={option as UI.UIUser} />
          ) : (
            <Avatar
              dataChoice={(option as UI.UIRequirement).id}
              requirement={option as UI.UIRequirement}
              size='2.5rem'
            />
          )}
          <Text
            className={cx(
              css`
                padding: 0 0.625rem;
                color: #303f4b;
                font-size: 0.875rem;
                font-weight: normal;
              `,
            )}
            data-choice={option.id}
          >
            {(option as UI.UIUser).name ?? (option as UI.UIRequirement).title}
          </Text>
        </div>
        <div>
          {isSelected && (
            <Icon
              className={css`
                color: #949caf;
                width: 0.875rem;
                height: 0.625rem;
                padding: 0rem;
                padding-top: 0.3125rem;
              `}
              name='tick'
            />
          )}
        </div>
      </div>
    ),
    [handleInputOnKeyDown, highlighted, isSelected, onOptionClick, option, props],
  );
}
