"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
// Same as JsonDecoder.nullable but treats empty string as a null value
function nullable(decoder) {
    return new ts_data_json_1.JsonDecoder.Decoder(function (value) {
        if (value === '' || value === null) {
            return ts_data_json_1.ok(null);
        }
        return decoder.decode(value);
    });
}
exports.default = nullable;
