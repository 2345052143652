import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { adjustSprintEndDate } from '@taraai/utility';
import dashify from 'dashify';
import { updateTeamMembership } from 'reduxStore/users';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI, Firestore } from 'reduxStore/utils/types';
import { strings } from 'resources/i18n';
import { guessTimezone } from 'tools/utils/timezones';

export type CreateTeam = Pick<UI.UITeam, 'name'> & {
  userIds: Data.Id.UserId[];
};

function createData(
  firestore: Firestore,
  { autoSprints, teamId, teamName }: { autoSprints: boolean; teamId: Data.Id.TeamId; teamName: string },
): { teamData: Data.Team; sprintData: Data.Sprint } {
  const sprintSettings: Data.SprintSettings = {
    autoSprints,
    durationWeeks: 1,
    endDay: Data.WeekDay.Monday,
    endHours: 0,
    endMinutes: 0,
    timezone: guessTimezone(),
    moveRemainingTasksTo: 'NextSprint',
  };

  const now = new Date();

  const teamData = decode<Data.Team>(
    {
      name: teamName,
      isPublic: true,
      currentSprintId: null,
      totalSprintsCount: 0,
      sprintSettings,
      effortUnit: 'days',
      updatedAt: firestore.Timestamp.fromDate(now),
      createdAt: firestore.Timestamp.fromDate(now),
      deletedAt: null,
    },
    'Team',
    CREATION_DEFAULTS,
  );

  const sprintData = decode<Data.Sprint>(
    {
      teamId,
      initialStartDate: firestore.Timestamp.fromDate(now),
      initialEndDate: firestore.Timestamp.fromDate(adjustSprintEndDate(now, sprintSettings, now)),
      createdAt: firestore.Timestamp.fromDate(now),
      updatedAt: firestore.Timestamp.fromDate(now),
      sprintName: strings.formatString(strings.sprints.sprintNumber, {
        number: 1,
      }) as string,
      sprintNumber: 1,
    },
    'Sprint',
    CREATION_DEFAULTS,
  );

  return { teamData, sprintData };
}

export const createTeam = createAsyncThunk('CreateTeam', async ({ name, userIds }: CreateTeam, { dispatch, extra }) => {
  const { getOrgID, getFirestore } = extra as ExtraAPI;
  const orgID = getOrgID();
  const firestore = getFirestore();

  // Derive team slug from its name
  const slug = dashify(name);

  const orgRef = firestore.collection('orgs').doc(orgID);
  const teamRef = orgRef.collection('teams').doc(slug);
  const sprintRef = orgRef.collection('sprints').doc();

  const validatedTeamData = await firestore.runTransaction(
    async (transaction): Promise<Data.Team> => {
      const orgData = (await transaction.get(orgRef)).data() as Data.Organization;
      if (!orgData) throw new Error("Couldn't get organization");

      const teamDoc = await transaction.get(teamRef);
      if (teamDoc.exists) {
        throw new Error(`${name} team name has already been taken`);
      }

      const { teamData, sprintData } = createData(firestore, {
        autoSprints: !!orgData.featureFlags.autoSprints,
        teamId: teamRef.id,
        teamName: name,
      });

      transaction.set(teamRef, teamData);
      transaction.set(sprintRef, sprintData);

      return teamData;
    },
  );

  await dispatch(
    updateTeamMembership({
      action: 'add',
      teamId: teamRef.id,
      userIds,
    }),
  );

  return { ...validatedTeamData, id: teamRef.id };
});
