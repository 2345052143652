import Icon from 'components/core/controllers/views/Icon';
import Input from 'components/core/controllers/views/Input';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { InputProps } from 'tools';

export interface OnboardingInputProps extends InputProps {
  label: string;
  placeholder: string;
  showAsterisk?: boolean;
}

export default function OnboardingInput({
  error,
  value,
  bottomLabel = '',
  showAsterisk = true,
  ...props
}: OnboardingInputProps): JSX.Element {
  return (
    <div
      className={css`
        position: relative;
        width: 100%;
        margin: 0.75rem 0 2.5rem;
      `}
    >
      <Input
        bottomLabel={bottomLabel}
        // XXX: don't pass error to input so red border is never shown
        // fixes initial/empty state look
        // error={error}
        value={value}
        {...props}
        style={{
          main: css`
            padding: 0.5rem 1rem;
            font-size: 0.875rem;
            color: ${atomic.get(atomic.colors.dark)};
          `,
          container: css`
            height: 3rem;
            margin: 0.75rem 0 0.25rem 0;
          `,
          labelStyle: css`
            margin-left: ${showAsterisk ? '0.5rem' : '0'};
            font-size: 1rem;
            line-height: 1;
            color: ${atomic.get(atomic.colors.dark)};
          `,
          bottomLabelStyle: css`
            font-size: 0.875rem;
            color: ${error ? atomic.get(atomic.colors.error) : atomic.get(atomic.colors.success)};
            margin-left: ${error || bottomLabel.length === 0 ? '0' : '1.25rem'};
          `,
        }}
      />
      {showAsterisk && (
        <p
          className={css`
            font-size: 1rem;
            line-height: 1;
            position: absolute;
            top: 0;
            left: 0;
            color: ${atomic.get(atomic.colors.error)};
          `}
        >
          {strings.onboarding.labels.asterisk}
        </p>
      )}
      <Icon
        className={css`
          display: ${error || bottomLabel.length === 0 ? 'none' : 'block'};
          position: absolute;
          bottom: 0.35rem;
          left: 0;
          padding: 0;
          width: 0.875rem;
          height: 0.625rem;
          color: ${atomic.get(atomic.colors.success)};
        `}
        name='tick'
      />
    </div>
  );
}
