import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { atomic } from 'resources';

import { Navbar } from './Navbar';
import { TeamSwitcher } from './TeamSwitcher';
import { Window } from './Window';

type Props = {
  className?: string;
  windowClassName?: string;
  orgName: string;
  outsideWindowContentChildren?: ReactNode;
};

export const PreviewWrapper: React.FC<Props> = ({
  children,
  className,
  windowClassName,
  orgName,
  outsideWindowContentChildren,
}) => (
  <Wrapper className={className}>
    <Window className={windowClassName} outsideWindowContentChildren={outsideWindowContentChildren}>
      <Navbar orgName={orgName} />
      <Main>
        <TeamSwitcher />
        <Content>{children}</Content>
      </Main>
    </Window>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  background-color: #f4f4f6;
  padding: 1rem 0 0 1rem;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(138deg, #58a6e7 0%, #775dfa 100%);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    align-items: center;
    padding: 0 0 0 3.5rem;
  }
`;

const Main = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 17rem;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;
