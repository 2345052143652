import styled from '@emotion/styled';

export const NavbarWrapper = styled.nav`
  width: 5.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 0.0625rem solid #eaeef5;
  z-index: 10;
`;

export const NavigationList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 1.5625rem;
`;
