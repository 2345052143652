import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { strings } from 'resources/i18n';

export interface TeamBacklogPopupProps {
  closePopup: () => void;
}

/**
 * TeamBacklogPopup
 * Show a pop-up letting users know about new team’s assignment
 * for requirement and its impact on backlog
 *
 */

export default function TeamBacklogPopup({ closePopup }: TeamBacklogPopupProps): JSX.Element {
  return (
    <Container>
      <Title>
        <InfoCircleIcon name='miniInfoCircle' />
        {strings.emptyTeamBacklogPopup.title}
        <Description>{strings.emptyTeamBacklogPopup.description}</Description>
      </Title>
      <Close>
        <CloseIcon name='close' onClick={closePopup} />
      </Close>
    </Container>
  );
}

const CloseIcon = styled(Icon, {
  'color': '$grey6',
  '--size': '0.75rem',
  'width': 'var(--size)',
  'height': 'var(--size)',
  'margin': ' 0 0 2.75rem 1.4375rem',
  'padding': '0.125rem',
  ':hover': { opacity: 0.8, cursor: 'pointer' },
});

const InfoCircleIcon = styled(Icon, {
  padding: '0rem',
  paddingRight: '0.5rem',
  verticalAlign: 'sub',
});

const Container = styled('div', {
  fontSize: '0.875rem',
  lineHeight: '1.43',
  color: ' #303f4b',
  fontWeight: '600',
  borderTop: '0.0625rem solid #eaeef5',
  backgroundColor: '#ffffff',
  padding: '1rem',
  paddingTop: '0.5rem',
  float: 'left',
  position: 'absolute',
  bottom: '0',
  width: '100%',
});

const Title = styled('div', {
  width: '80%',
  position: 'absolute',
});

const Description = styled('span', {
  color: '#303f4b',
  fontWeight: 'normal',
});

const Close = styled('div', {
  float: 'right',
});
