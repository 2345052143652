import { css, cx } from 'emotion';
import React, { useRef } from 'react';

import { Backlog } from './Backlog';
import { BacklogTasksOutline } from './BacklogTasksOutline';
import { OrgCircle } from './OrgCircle';
import { PreviewWrapper } from './PreviewWrapper';
import { SprintsRow } from './SprintsRow';
import { animations } from './styles';
import { PreviewProps } from './types';

export const Preview = ({ orgName, stepName, taskTitles, isOnboardingFlowFinished }: PreviewProps): JSX.Element => {
  // Only do the slide animation when rendering a step for the first time
  const initialStepName = useRef(stepName);
  const stepNameChanged = useRef(false);
  const isOneOfLastTwoSteps = stepName === 'moveTasks' || stepName === 'finish';

  if (!stepNameChanged.current && stepName !== initialStepName.current) {
    stepNameChanged.current = true;
  }
  return (
    <PreviewWrapper
      className={cx({
        [gradientBackground]: stepName === 'finish' && isOnboardingFlowFinished,
        [previewWrapperStyles]: isOneOfLastTwoSteps,
      })}
      orgName={orgName}
      outsideWindowContentChildren={
        <>
          <OrgCircle
            className={cx({
              [fadeIn]: stepName === 'start',
              [fadeOut]: stepName === 'createTasks',
            })}
          />
          <BacklogTasksOutline
            className={cx({
              [fadeIn]: stepName === 'createTasks',
              [fadeOut]: stepName === 'moveTasks',
            })}
          />
        </>
      }
      windowClassName={cx(slideIn, !stepNameChanged.current && delayAnimationsInside, {
        [windowStyles]: isOneOfLastTwoSteps,
      })}
    >
      <Backlog
        className={cx({
          [fadeOut]: stepName === 'start',
          [fadeIn]: stepName === 'createTasks',
        })}
        tasksWrapperClassName={cx({
          [fadeIn]: stepName === 'createTasks',
          [fadeOut]: stepName === 'finish',
        })}
        taskTitles={taskTitles}
        variant={stepName === 'start' ? 'blank' : 'oneRequirement'}
      />
      <SprintsRow
        sprintClassName={cx({
          [fadeOut]: stepName === 'start',
          [invisible]: stepName === 'createTasks' || stepName === 'moveTasks',
          [fadeIn]: stepName === 'finish',
        })}
        taskTitles={taskTitles}
        variant={stepName === 'finish' ? 'namedTasks' : 'blank'}
      />
    </PreviewWrapper>
  );
};

const slideIn = css`
  animation: ${animations.slideIn} 1s ease both;
`;

const delayAnimationsInside = css`
  * {
    animation-delay: 1s;
  }
`;

const fadeIn = css`
  animation: ${animations.fadeIn} 1s ease both;
`;

const fadeOut = css`
  animation: ${animations.fadeOut} 1s ease both;
`;

const invisible = css`
  opacity: 0;
`;

const previewWrapperStyles = css`
  && {
    padding-bottom: 1rem;
  }
`;

const gradientBackground = css`
  &&:before {
    animation: ${animations.fadeIn} 0.5s ease both;
  }
`;

const windowStyles = css`
  && {
    overflow: hidden;
    border-radius: 0.5rem 0 0 0.5rem;
  }
`;
