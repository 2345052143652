import { AddToastFn } from 'components/app/controllers/Toast/ToastContext';
import { createEntity } from 'components/editor/entities';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { strings } from 'resources';

import { getImageMetadata } from './utils';

const insertImage = (url: string, selection: SelectionState | null) => (state: EditorState): EditorState => {
  const currentSelection = selection ?? state.getSelection();
  const content = state.getCurrentContent();
  const { contentWithEntity, key } = createEntity('IMAGE', 'IMMUTABLE', { src: url, alt: 'image' }, content);

  const imageText = `![image](${url})`;

  return EditorState.push(
    state,
    Modifier.insertText(contentWithEntity, currentSelection, imageText, undefined, key),
    'insert-characters',
  );
};

export type InsertFileFn = (selection: SelectionState | null, files: File[]) => void;

export const createInsertFileFn = (
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  addToast: AddToastFn,
  uploadAttachment: (file: File) => Promise<string>,
): InsertFileFn => {
  return (selection: SelectionState | null, files: File[]): void => {
    files
      .reduce(async (previousPromise, nextFile): Promise<void> => {
        await previousPromise;
        const url = await uploadAttachment(nextFile as File).catch(() => undefined);
        const valid = getImageMetadata(url);
        if (!url || !valid) {
          throw Error('file type is not supported');
        }
        setEditorState(insertImage(url, selection));
      }, Promise.resolve())
      .catch(() => {
        addToast({
          message: strings.editor.filesNotUploaded,
          timeoutMs: 5500,
          type: 'error',
        });
      });
  };
};
