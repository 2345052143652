import DraftMention from 'components/core/controllers/DraftMention';
import DraftMentionInserter from 'components/core/controllers/DraftMentionInserter';
import DraftLink from 'components/core/controllers/views/DraftLink';
import { ContentBlock, ContentState, DraftHandleValue } from 'draft-js';
import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources';

import { mentionInserterStrategy, mentionStrategy } from './decorators';
import DraftImage from './DraftImage';

export type Style = string;

export const STYLES = Object.freeze({
  INLINE: {
    BOLD: { value: 'BOLD', icon: 'formatBold' },
    ITALIC: { value: 'ITALIC', icon: 'formatItalic' },
    UNDERLINE: { value: 'UNDERLINE', icon: 'formatUnderlined' },
    STRIKETHROUGH: {
      value: 'STRIKETHROUGH',
      icon: 'strikethrough',
      styles: {
        textDecoration: 'line-through',
      },
    },
    CODE: {
      value: 'CODE',
      icon: 'formatCode',
      styles: {
        strokeDashoffset: '0',
      },
      classNameStyles: `[style*="stroke-dashoffset: 0"] {
        padding: 2px;
        background-color: ${atomic.get(atomic.colors.grey1)};
        color: ${atomic.get(atomic.colors.greySlate)};
        font-family: SourceCodePro !important;
        border-radius: 3px;
        border: solid 1px ${atomic.get(atomic.colors.grey4)};
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
      }

      [style*="stroke-dashoffset: 0"] > span {
        font-family: SourceCodePro !important;
      }
      `,
    },
  },
  BLOCK: {
    UNSTYLED: { value: 'unstyled' },
    HEADERONE: { value: 'header-one', icon: 'looksOne' },
    HEADERTWO: { value: 'header-two', icon: 'looksTwo' },
    HEADERTHREE: { value: 'header-three', icon: 'looksThree' },
    HEADERFOUR: { value: 'header-four', icon: 'looksFour' },
    HEADERFIVE: { value: 'header-five', icon: 'looksFive' },
    HEADERSIX: { value: 'header-six', icon: 'looksSix' },
    UNORDERED: { value: 'unordered-list-item', icon: 'formatListBulleted' },
    ORDERED: { value: 'ordered-list-item', icon: 'formatListNumbered' },
    BLOCKQUOTE: {
      value: 'blockquote',
      icon: 'blockquote',
      className: 'blockQuoteStyle',
      classNameStyles: `
        .blockQuoteStyle {
          ${atomic.color(atomic.colors.grey6)};
          border-left: 4px solid ${atomic.get(atomic.colors.grey3)};
          padding: 4px 16px;
        }
      `,
    },
    CODE: {
      value: 'code-block',
      icon: 'formatCode',
      className: 'codeBlockStyle',
      classNameStyles: `.codeBlockStyle {
        margin: 6px;
        background-color: ${atomic.get(atomic.colors.grey1)};
        font-family: SourceCodePro !important;
        font-size: 14px;
        padding: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: ${atomic.get(atomic.colors.greySlate)};
        border-radius: 3px;
        border: solid 1px ${atomic.get(atomic.colors.grey4)};
      }

      .codeBlockStyle > * {
        font-family: SourceCodePro !important;
      }`,
    },
    ATOMIC: {
      value: 'atomic',
      renderer: (
        props: Record<string, unknown> = {},
      ): {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component: (...args: any[]) => JSX.Element;
        props: Record<string, unknown>;
        editable: boolean;
      } => ({
        component: DraftImage,
        editable: false,
        props,
      }),
    },
  },
  ENTITY: {
    LINK: {
      classNameStyles: '',
      decoratorStrategy(
        contentBlock: ContentBlock,
        callback: (start: number, end: number) => void,
        contentState: ContentState,
      ): void {
        return contentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
        }, callback);
      },
      decoratorComponent({
        contentState,
        children,
        entityKey,
      }: {
        contentState: ContentState;
        children: React.ReactNode;
        entityKey: string;
      }): JSX.Element {
        const { url } = contentState.getEntity(entityKey).getData();
        return (
          <DraftLink entityKey={entityKey} href={url}>
            {children}
          </DraftLink>
        );
      },
    },
    MENTIONINSERTER: {
      classNameStyles: '',
      decoratorStrategy: mentionInserterStrategy,
      decoratorComponent: DraftMentionInserter,
    },
    MENTION: {
      classNameStyles: '',
      decoratorStrategy: mentionStrategy,
      decoratorComponent: DraftMention,
    },
  },
  DRAFTHANDLEVALUE: {
    HANDLED: 'handled' as DraftHandleValue,
    NOTHANDLED: 'not-handled' as DraftHandleValue,
  },
});

export const TOOLBAR_STYLES_INLINE = [
  STYLES.INLINE.BOLD,
  STYLES.INLINE.ITALIC,
  STYLES.INLINE.UNDERLINE,
  STYLES.INLINE.STRIKETHROUGH,
  STYLES.INLINE.CODE,
];

export const TOOLBAR_STYLES_BLOCK = [
  STYLES.BLOCK.HEADERONE,
  STYLES.BLOCK.HEADERTWO,
  STYLES.BLOCK.ORDERED,
  STYLES.BLOCK.UNORDERED,
  STYLES.BLOCK.BLOCKQUOTE,
];

export const CONTAINER_STYLE = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  ul,
  ol {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  ol > li > div,
  ul > li > div {
    margin-left: 4px;
  }

  ol > li:before {
    left: -16px !important;
    text-align: left !important;
  }

  h1,
  h2 {
    margin-bottom: 16px;
  }

  .public-DraftEditor-content {
    padding: 0;
    min-height: 0;
  }

  .public-DraftEditor-content * {
    line-height: 1.5;
  }

  ${STYLES.BLOCK.CODE.classNameStyles};
  ${STYLES.BLOCK.BLOCKQUOTE.classNameStyles};
  ${STYLES.INLINE.CODE.classNameStyles};
  ${STYLES.ENTITY.LINK.classNameStyles};
`;
