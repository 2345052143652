import Tabs, { Tab } from 'components/core/controllers/views/Tabs';
import { TabsViewVariantProps } from 'components/core/controllers/views/Tabs/TabsView';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'tools';

export interface TabbedContent {
  label: string;
  content: JSX.Element;
  route: string;
  disabled?: boolean;
}

export interface TabBarProps extends TabsViewVariantProps {
  /**
   * tabbedContent and array of tab objects containing label, content, and route.
   *
   * ```javascript
   * tabbedContent = [
   *  {
   *    label: `tab name 1`,       // string of the label displayed on the tab
   *    content: <Component 1/>,   // component to be rendered
   *    route: `component-1`       // optional: a string of the optional route path
   *    disabled: true             // optional: Boolean if tab is to be disabled
   *  },
   *  {
   *    label: `tab name 2`,
   *    content: <Component 2/>,
   *  },
   * ]
   * ```
   */
  tabbedContent: TabbedContent[];
  /**
   * custom right left margin for the tab bar line
   */
  lineMarginX?: string;

  children?: JSX.Element | JSX.Element[] | string;

  queryParamName?: string;
}

/**
 * TabBar renders a bar view of tabs to display components and labels
 */
export default function TabBar({
  children,
  lineMarginX = '',
  queryParamName = 'tab',
  tabbedContent,
  ...rest
}: TabBarProps): JSX.Element {
  const history = useHistory();
  const tabQuery = useQuery().get(queryParamName);
  const initialSelectedTab = useRef(getSelectedTabFromQuery(tabbedContent, tabQuery));

  const selectedTab = getSelectedTabFromQuery(tabbedContent, tabQuery);

  const setSearchFromTab = useCallback(
    (tab?: Tab): void => {
      const searchParams = new URLSearchParams(history.location.search);
      const hadParam = searchParams.has(queryParamName);
      searchParams.delete(queryParamName);
      if (tab) {
        searchParams.set(queryParamName, tab.route);
      }
      if (hadParam) {
        history.push({ search: String(searchParams) });
      } else {
        history.replace({ search: String(searchParams) });
      }
    },
    [history, queryParamName],
  );

  useEffect(() => {
    setSearchFromTab(initialSelectedTab.current);
    return () => setSearchFromTab();
  }, [queryParamName, setSearchFromTab]);

  const handleTabChange = useCallback(
    (tab: Tab): void => {
      if (!tab.disabled) {
        setSearchFromTab(tab);
      }
    },
    [setSearchFromTab],
  );

  return (
    <>
      <Tabs
        {...rest}
        handleTabChange={handleTabChange}
        lineMarginX={lineMarginX}
        selectedTab={selectedTab}
        tabs={tabbedContent}
      >
        {children}
      </Tabs>
      {selectedTab.content}
    </>
  );
}

function getSelectedTabFromQuery(tabbedContent: TabbedContent[], tabQuery: string | null): TabbedContent {
  return (
    tabbedContent.find((tab) => !tab.disabled && tab.route === tabQuery) ??
    tabbedContent.find((tab) => !tab.disabled) ??
    tabbedContent[0]
  );
}
