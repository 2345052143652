"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fluid = void 0;
var core_1 = require("./core");
var baseEnabledStyles = {
    /**
     * Warning: 0% is not the same as 0px, so we need to take the maximum
     * value here to prevent Fluid from collapsing miserably inside VStack.
     */
    '--flex-basis': 'max(calc(var(--space) * (var(--flex-fr) - 1)), 0%)',
    'display': 'flex',
    '> *': {
        width: '$full',
    },
};
exports.Fluid = core_1.styled('div', {}, {
    enabled: {
        true: __assign(__assign({}, baseEnabledStyles), { minHeight: '100%', width: '100%' }),
        false: {
            '--flex-fr': '0 !important',
        },
        horizontal: __assign(__assign({}, baseEnabledStyles), { width: '100%' }),
        vertical: __assign(__assign({}, baseEnabledStyles), { minHeight: '100%' }),
    },
    fractions: {
        1: { '--flex-fr': 1 },
        2: { '--flex-fr': 2 },
        3: { '--flex-fr': 3 },
        4: { '--flex-fr': 4 },
        5: { '--flex-fr': 5 },
        6: { '--flex-fr': 6 },
    },
});
exports.Fluid.defaultProps = {
    enabled: true,
    fractions: 1,
};
exports.Fluid.displayName = 'Fluid';
