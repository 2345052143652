"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegerInRangeDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
function isIntegerInRange(value, min, max) {
    return typeof value === 'number' && Number.isInteger(value) && value >= min && value <= max;
}
function IntegerInRangeDecoder(min, max) {
    return new ts_data_json_1.JsonDecoder.Decoder(function (value) {
        if (isIntegerInRange(value, min, max)) {
            return ts_data_json_1.ok(value);
        }
        return ts_data_json_1.err("Failed to decode " + value + " as part of integer range [" + min + ", " + max + "]");
    });
}
exports.IntegerInRangeDecoder = IntegerInRangeDecoder;
