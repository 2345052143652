import styled from '@emotion/styled';
import { unwrapResult } from '@reduxjs/toolkit';
import { Attachment, Data, Markdown, UI } from '@taraai/types';
import RequirementTasksEditorController from 'components/app/controllers/RequirementTasksEditorController';
import { EditorProvider } from 'components/core/controllers/Editor';
import { getAllEntitiesData } from 'components/editor/entities';
import {
  composePlugins,
  createAttachmentPlugin,
  createMentionPlugin,
  linkPlugin,
  markdownPlugin,
} from 'components/editor/plugins';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import { ContentState } from 'draft-js';
import React, { useCallback, useMemo } from 'react';
import {
  addAttachment,
  getPathForRequirement,
  reduxStore,
  removeAttachment,
  updateRequirement,
  UpdateRequirementTeamAction,
} from 'reduxStore';
import { strings } from 'resources';
import { FirebaseInput, useToast } from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import errorHandler from 'tools/libraries/stackdriver';
import { useForceSave } from 'tools/reconciliation/useForceSave';

import EditorSection from './EditorSection';

type TeamFragment = Pick<UI.UITeam, 'id' | 'name'>;
export interface RequirementBuilderLayoutProps {
  requirement: UI.UIRequirement;
  orgID: Data.Id.OrganizationId;
  archiveRequirement: () => void;
  restoreArchivedRequirement: () => void;
  manageRequirementTeam: (action: UpdateRequirementTeamAction) => (team: TeamFragment) => void;
  titleProps: FirebaseInput<string>;
  assignedTeams: TeamFragment[];
}

const getKeyForReconciliation = (newDescription: string): string => newDescription;

/**
 * Component layout for the entire Requirement Builder page
 */
export default function RequirementBuilderLayout({
  requirement,
  orgID,
  ...props
}: RequirementBuilderLayoutProps): JSX.Element {
  const requirementId = requirement?.id;
  const requirementDescription: Markdown = requirement?.description ?? null;
  const { addToast, removeToast } = useToast();

  const getUserTagForId = useUserTagForId(orgID);

  const path = getPathForRequirement(requirement.id, orgID);

  const handleAttachmentUpload = useCallback(
    async (file: File): Promise<string> => {
      addToast({
        message: strings.attachments.uploading,
        type: 'loading',
        id: file.name,
      });
      return reduxStore
        .dispatch(addAttachment({ type: 'requirement', path, file }))
        .then(unwrapResult)
        .then((attachmentURL) => {
          removeToast(file.name);
          return attachmentURL;
        })
        .catch((error) => {
          errorHandler.report(error);
          removeToast(file.name);
          const message = formatI18n(strings.attachments.uploadError)({
            errorMessage: error.message,
          });
          addToast({ message, type: 'error' });
          throw error;
        });
    },
    [addToast, path, removeToast],
  );

  const handleAttachmentRemove = useCallback(
    (attachment: Attachment): void => {
      reduxStore
        .dispatch(removeAttachment({ type: 'requirement', attachment, path }))
        .then(unwrapResult)
        .catch((error: Error) => {
          const message = formatI18n(strings.attachments.uploadError)({
            errorMessage: error.message,
          });
          addToast({ message, type: 'error' });
        });
    },
    [addToast, path],
  );

  const plugin = useMemo(
    () =>
      composePlugins(
        markdownPlugin,
        createMentionPlugin(getUserTagForId),
        linkPlugin,
        createAttachmentPlugin(addToast, handleAttachmentUpload),
      ),
    [addToast, getUserTagForId, handleAttachmentUpload],
  );

  const uploadMarkdown = useCallback(
    async (newDescription: Markdown, content: ContentState) => {
      await reduxStore
        .dispatch(
          updateRequirement({
            description: newDescription,
            id: requirementId,
            mentionedUserIds: getAllEntitiesData('mention', content).map(({ id }) => id),
          }),
        )
        .then(unwrapResult)
        .catch((error: Error) => {
          addToast({ message: error.message, type: 'error' });
        });
    },
    [addToast, requirementId],
  );

  const { trySave, isConflict, forceSave } = useForceSave(
    requirementDescription,
    uploadMarkdown,
    getKeyForReconciliation,
  );

  return (
    <Container>
      <LayoutWrapper>
        <RichEditorProvider initialValue={requirementDescription} onSave={trySave} plugin={plugin}>
          <EditorProvider>
            <EditorSection
              forceSave={forceSave}
              isEditorConflict={isConflict}
              onAttachmentRemove={handleAttachmentRemove}
              onAttachmentUpload={handleAttachmentUpload}
              requirement={requirement}
              {...props}
            />
            <RequirementTasksWrapper>
              <RequirementTasksEditorController orgID={orgID} requirement={requirement} />
            </RequirementTasksWrapper>
          </EditorProvider>
        </RichEditorProvider>
      </LayoutWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const LayoutWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow-y: scroll;
  flex-direction: column;

  > * {
    flex: 0 0 auto;
  }
`;

const RequirementTasksWrapper = styled.div`
  width: 100%;
  max-width: 65.5rem;
  padding: 0 1.5rem;
  margin-bottom: 2.5rem;
`;
