import { UI } from '@taraai/types';
import DraggableFeatureCardList from 'components/app/controllers/DraggableFeatureCardList';
import { DragSource } from 'components/app/controllers/views/DraggableFeatureCard/DragAndDropContext';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React, { useMemo } from 'react';

interface ImportedTasksViewHeaderProps {
  onBackClick: () => void;
  label: string;
  description: string | React.ReactText[] | JSX.Element;
  icon: JSX.Element;
}

const ImportedTasksViewHeader = ({
  onBackClick,
  label,
  description,
  icon: ImageIcon,
}: ImportedTasksViewHeaderProps): JSX.Element => (
  <div
    className={css`
      padding: 0.9375rem 0.5rem;
      border: solid 0.0625rem transparent;
      border-top: 0.0625rem solid #eaeef5;
      border-bottom: 0.0625rem solid #eaeef5;
      display: flex;
      align-items: center;
      &:focus {
        outline: 0;
      }

      flex-direction: row-reverse;
    `}
    role='button'
    tabIndex={0}
  >
    <div
      className={css`
        display: flex;
        align-items: center;
        width: 90%;
      `}
    >
      <div
        className={css`
          margin-right: 0.5rem;
        `}
      >
        {ImageIcon}
      </div>
      <div>
        <div
          className={css`
            padding-top: 0.1875rem;
            color: #303f4b;
            font-size: 1rem;
            font-weight: 500;
          `}
        >
          {label}
        </div>
        <div
          className={css`
            font-size: 0.875rem;
            font-weight: normal;
            color: #949caf;
            padding-top: 0.25rem;
          `}
        >
          {description}
        </div>
      </div>
    </div>
    <Icon
      className={css`
        color: #949caf;
        transform: scaleX(-1);
        &:hover {
          cursor: pointer;
        }
      `}
      name='right'
      onClick={onBackClick}
    />
  </div>
);

interface ImportedTasksViewProps {
  onBackClick: () => void;
  label: string;
  description: string | React.ReactText[] | JSX.Element;
  icon: JSX.Element;
  tasks: UI.UITask[];
  toggleModal: (task: UI.UITask) => void;
  onMoveToSprint: (taskRef: string, sprintId: string) => void;
  onMoveToBacklog: (taskRef: string) => void;
}

const ImportedTasksView = ({
  onBackClick,
  label,
  description,
  icon,
  toggleModal,
  onMoveToBacklog,
  onMoveToSprint,
  tasks,
}: ImportedTasksViewProps): JSX.Element => {
  const dragSource = useMemo((): DragSource => ({ type: 'backlog' }), []);
  return (
    <>
      <ImportedTasksViewHeader description={description} icon={icon} label={label} onBackClick={onBackClick} />
      <div
        className={css`
          height: 90%;
          overflow: auto;
        `}
      >
        <DraggableFeatureCardList
          dragSource={dragSource}
          onMoveToBacklog={onMoveToBacklog}
          onMoveToSprint={onMoveToSprint}
          selectSprint
          tasks={tasks}
          toggleModal={toggleModal}
        />
      </div>
    </>
  );
};

export default ImportedTasksView;
