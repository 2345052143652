import { Fluid, HStack, styled } from '@taraai/design-system';
import { Data } from '@taraai/types';
import SprintColumnsController from 'components/app/controllers/SprintColumnsController';
import SprintsBacklog from 'components/app/layouts/SprintsBacklog';
import { TaskDetails } from 'components/app/TaskDetails/TaskDetails';
import { getPath } from 'components/Router/paths';
import React from 'react';
import { DndProvider } from 'react-dnd';
import DnDBackend from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Route, Switch, useParams } from 'react-router';
import { compose } from 'redux';
import { getSprint, selectTeam } from 'reduxStore';

/**
 * Sprint Plan page. This page is a part of Tara 3.0 redesign.
 */
export function SprintPage(): JSX.Element {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();

  const currentSprintId = useSelector(compose((data) => data?.currentSprintId, selectTeam(orgID, teamID))) ?? '';
  const currentSprintSlice = getSprint(orgID, currentSprintId);
  const currentSprint = useSelector(currentSprintSlice.selector);

  useFirestoreConnect(currentSprintSlice.query);

  return (
    // FIXME: react-dnd library should be replaced with react-beautiful-dnd
    // when the feature branch with dnd refactor is merged
    <DndProvider backend={DnDBackend}>
      <FullHeightHStack>
        <Fluid fractions={1}>
          <SprintsBacklog />
        </Fluid>
        <Fluid fractions={3}>{currentSprint && <SprintColumnsController currentSprint={currentSprint} />}</Fluid>
        <Fluid fractions={1}>
          <Switch>
            <Route component={TaskDetails} path={getPath('sprintsTask')} />
            <Route component={() => <>Details panel goes here</>} path={getPath('sprints')} />
          </Switch>
        </Fluid>
      </FullHeightHStack>
    </DndProvider>
  );
}

const FullHeightHStack = styled(HStack, {
  height: '100%',
  // For now we only focus on the design for 1440px wide screens
  // FIXME: remove this before the release
  minWidth: '1440px',
  maxWidth: '1440px',
});
