import { compose, unwrapResult } from '@reduxjs/toolkit';
import LeaveTeamModal from 'components/app/controllers/views/LeaveTeamModal';
import { usePathParams } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { reduxStore, selectAuth, selectTeam, selectUserTeams, updateTeamMembership } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

/**
 * LeaveTeamController
 * Temporary leave team modal confirmation until we have a proper confirmation component.
 */
export default function LeaveTeamController(): JSX.Element | null {
  const profileId = useSelector(selectAuth).uid;
  const { orgID, teamID } = usePathParams('leaveTeam');
  const team = useSelector(selectTeam(orgID, teamID));
  const { addToast } = useToast();
  const history = useHistory();
  const canLeave = useSelector(compose((teams) => teams.length > 1, selectUserTeams(orgID)));

  if (!isLoaded(team)) {
    return null;
  }

  const onSubmit = async (): Promise<void> => {
    await reduxStore
      .dispatch(
        updateTeamMembership({
          action: 'remove',
          teamId: team.id,
          userIds: [profileId],
        }),
      )
      .then(unwrapResult)
      .then(() => {
        addToast({
          type: 'success',
          message: strings.formatString(strings.teamDetails.leaveSuccess, {
            name: team.name,
          }) as string,
          timeoutMs: 3000,
        });
        return history.goBack();
      })
      .catch((error: Error) =>
        addToast({
          type: 'error',
          message: strings.formatString(strings.teamDetails.leaveError, {
            name: team.name,
          }) as string,
          timeoutMs: 3000,
        }),
      );
  };

  return <LeaveTeamModal canLeave={canLeave} onSubmit={onSubmit} teamName={team.name} />;
}
