import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createStandardSelector, generateAlias, inertQuery, Query } from 'reduxStore/utils/selectors';

export const getOrgTeams = (orgID: Data.Id.OrganizationId): Query<UI.UITeam> => {
  if (!isNonEmptyString(orgID)) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/teams`,
    where: [
      ['isPublic', '==', true],
      ['deletedAt', '==', null],
    ],
    orderBy: ['createdAt', 'asc'],
  };
  query.storeAs = generateAlias(query);

  return {
    query: [query],
    selector: createStandardSelector(query),
  };
};
