import Tara, { Data } from '@taraai/types';
import SprintPlanningDetails from 'components/app/controllers/views/SprintPlanningDetails';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

export interface SprintPlanningProps {
  currentSprint: Data.Sprint | undefined;
  estimatedEffort: number;
  totalEffort: number;
  allSprintTasks: Tara.Task[] | undefined;
  unassignedSprintTasks: Tara.Task[] | undefined;
}

/**
 * SprintPlanning
 * view for sprint planning that renders effort and task data
 *
 */
export default function SprintPlanning({
  currentSprint,
  estimatedEffort,
  totalEffort,
  allSprintTasks,
  unassignedSprintTasks,
}: SprintPlanningProps): JSX.Element {
  return (
    <div
      className={css`
        border: solid 0.0625rem #eef1f7;
        border-radius: 0.1875rem;
        padding: 1rem;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div>
          <div
            className={css`
              color: #303f4b;
              font-size: 1.125rem;
              font-weight: 600;
              padding-bottom: 0.375rem;
            `}
          >
            {currentSprint?.sprintName}
          </div>
          <div
            className={css`
              color: #949caf;
              font-size: 0.75rem;
              font-weight: normal;
            `}
          >
            {strings.sprints.sprintPlanning.planning}
          </div>
        </div>
        <SprintPlanningDetails
          allSprintTasks={allSprintTasks}
          estimatedEffort={estimatedEffort}
          totalEffort={totalEffort}
          unassignedSprintTasks={unassignedSprintTasks}
        />
      </div>
    </div>
  );
}
