import RevisionEntry from 'components/app/controllers/views/RevisionEntry';
import { css, cx } from 'emotion';
import React from 'react';
import { Revision } from 'reduxStore/tasks/queries/taskRevisionQueries';
import { strings } from 'resources/i18n';

export interface TaskRevisionProps extends React.HTMLProps<HTMLDivElement> {
  revisions: Revision[];
}

/**
 * TaskRevision
 * displays the revision activity for each task.
 * Any changes made to the effort level, status and sprint will be displayed in the activity
 *
 */

export default function TaskRevision({ className, revisions }: TaskRevisionProps): JSX.Element {
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          padding: 1.5625rem;
          border-top: solid 0.0625rem #dee3ec;
        `,
        className,
      )}
    >
      <div
        className={css`
          color: #67728b;
          font-size: 0.75rem;
          font-weight: 600;
          line-height: 1.17;
          padding-bottom: 1.25rem;
        `}
      >
        {strings.revisions.activity.toUpperCase()}
      </div>
      <div>
        {revisions
          .slice(0)
          .reverse()
          .map((revision, idx) => (
            <RevisionEntry key={revision.id ?? idx} revision={revision} />
          ))}
      </div>
      <div
        className={css`
          padding-top: 1.5rem;
          font-size: 0.875rem;
          color: #c8d0df;
          text-align: center;
        `}
      >
        {revisions.length < 1 ? strings.revisions.noActivity : strings.revisions.endOfRevisions}
      </div>
    </div>
  );
}
