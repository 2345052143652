import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';

export const getTaskPullRequestsSummaries = (
  orgId: Data.Id.OrganizationId,
  taskSlug: Data.Id.TaskSlug,
): Query<UI.UIPullRequestSummary> => {
  if (![orgId, taskSlug].every(isNonEmptyString)) {
    return inertQuery();
  }

  const taskPullRequestSummaryQuery: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgId}/tasks-pull-requests-summaries`,
    where: [['taskSlug', '==', taskSlug]],
    storeAs: `org-${orgId}-task-${taskSlug}-pull-requests-summaries`,
  };

  return {
    query: [taskPullRequestSummaryQuery],
    selector: createStandardSelector(taskPullRequestSummaryQuery),
  };
};
