import Tara, { UI } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import Input from 'components/core/controllers/views/Input';
import TableCell from 'components/core/controllers/views/TableCell';
import TableHeaderCell from 'components/core/controllers/views/TableHeaderCell';
import TableRow from 'components/core/controllers/views/TableRow';
import TableLayout, { TableLayoutProps } from 'components/core/layouts/TableLayout';
import { css, cx } from 'emotion';
import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { requirementDetailsTestIDs, tableTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export type TableDataEntry = Record<string, JSX.Element | Tara.User | UI.UITask | string> & {
  id: string;
};

export type HeaderData = Record<string, string | JSX.Element | undefined> & {
  align?: string;
  width?: string;
  name: string;
};

export type Headers = Record<string, HeaderData>;

export interface TableProps extends Omit<Omit<TableLayoutProps, 'data'>, 'headers'> {
  data?: TableDataEntry[];
  headers?: Headers;
  colWidth?: Record<string, string>;
  tableRowOnClick?: (data: TableDataEntry) => MouseEventHandler;
  topRow?: JSX.Element;
  bottomRow?: JSX.Element;
  dashboardTaskRow?: boolean;
  withSearch?: boolean;
  placeholderSearch?: string;
  handleSearch?: (query: string) => void;
}

/**
 * The table component
 * @param headers - The headers for each column on the table
 * @param data - The data of each row that will be put into the table body
 * @param colWidth - Custom widths for each column; for an example check `DashboardTasks.tsx`
 * @param topRow - Customization for the first row
 * @param bottomRow - Customization for the last row
 * @param dashboardTaskRow - Used for styling the table on the dashboard
 * @param withSearch - Used to display the search box
 * @param placeholderSearch - The placeholder text for the search input box
 * @param handleSearch - A callback function that performs the search, located in the controller
 */
export default function Table({
  headers,
  data: propData = [],
  tableRowOnClick,
  colWidth,
  topRow = <TableRow />,
  bottomRow = <TableRow />,
  dashboardTaskRow = false,
  withSearch = false,
  placeholderSearch = strings.table.search,
  handleSearch,
  ...props
}: TableProps): JSX.Element {
  const handleSearchOnChange = useCallback(
    (event: React.BaseSyntheticEvent): void => {
      handleSearch && handleSearch(event.target.value);
    },
    [handleSearch],
  );

  /**
   * Using new data state for sorting functionality in table
   */
  /** Looking for a better way to setup headers for a new table instances
   * Keeping this old code here for now incase people prefer using a fallback
   * from the data keys
   * Object.keys(data[0]).map((key) => headers[key] ?? sentenceCase(key))
   */
  return useMemo(
    () => (
      <>
        {withSearch && (
          <span
            className={cx(
              css`
                display: flex;
                flex-direction: row;
              `,
            )}
          >
            <Icon
              className={cx(
                css`
                  height: 100%;
                  padding: 0.5rem 0rem;
                  margin-top: auto;
                  margin-bottom: auto;
                `,
              )}
              name='search'
            />
            <Input
              data-cy={requirementDetailsTestIDs.SEARCH_TASK_INPUT}
              naked
              onChange={handleSearchOnChange}
              placeholder={placeholderSearch}
              style={{
                main: css`
                  padding-left: 0.25rem;
                  margin-top: auto;
                  margin-bottom: auto;
                `,
              }}
            />
          </span>
        )}
        <TableLayout
          header={
            !dashboardTaskRow && headers ? (
              <TableRow>
                {Object.keys(headers).map((header: string) => (
                  <TableHeaderCell
                    key={header}
                    className={css`
                      width: ${headers[header].width ?? 'auto'};
                      text-align: ${headers[header].align || 'left'};
                    `}
                    id={headers[header].name}
                  >
                    <span
                      className={css`
                        position: relative;
                      `}
                    >
                      {headers[header].name}
                      {headers[header].filterRenderer}
                    </span>
                  </TableHeaderCell>
                ))}
              </TableRow>
            ) : undefined
          }
          {...props}
        >
          {topRow}
          {propData.map((row) => {
            const validRows = Object.entries(row).filter(
              ([key]) => Object.keys(headers ?? {}).includes(key) && key !== 'id' && key !== 'ref',
            );
            return (
              <TableRow
                key={row.id}
                className={
                  dashboardTaskRow
                    ? cx(
                        css`
                          border-left: 0;
                        `,
                      )
                    : ''
                }
                data-cy={tableTestIDs.SINGLE_TABLE_ROW}
                onClick={tableRowOnClick?.(row)}
              >
                {validRows.map(([key, value]) => {
                  return (
                    <TableCell
                      key={key}
                      className={cx(
                        css`
                          width: ${colWidth ? colWidth[key] : 'auto'};
                        `,
                      )}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {bottomRow}
        </TableLayout>
      </>
    ),
    [
      withSearch,
      handleSearchOnChange,
      placeholderSearch,
      dashboardTaskRow,
      headers,
      props,
      topRow,
      propData,
      bottomRow,
      tableRowOnClick,
      colWidth,
    ],
  );
}
