/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable id-length */
export default {
  emoji: {
    // FIXME: (a11y) emoji should be a `<span role='image' alt='emoji name'>🥳</span>`
    confetti: '🎉',
    poop: '💩',
    robot: '🤖',
    noMouth: '😶',
    congratulations: 'Congratulations!',
    ohPoo: 'Oh Poo.',
  },
  notFound: '404 Not found',
  deactivated: {
    backToLogin: 'Back to Login',
    userDoesNotExist: 'User does not exist or has been deactivated.',
  },
  avatar: {
    alt: "{name}'s avatar",
  },
  avatarPicker: {
    plus: '+{count}',
    arrayJoiner: ', ',
  },
  labelList: {
    showMore: 'Show more',
  },
  hello: 'Hello World!',
  logo: {
    tara: 'Tara logo',
  },

  textarea: {
    placeholder: 'Enter text here',
  },

  placeholder: {
    text: 'Enter {field}',
    fields: {
      title: 'title',
    },
  },

  emptyRequirement: 'Empty Requirement',

  authLayout: {
    error: 'Error!',
    userDisabled: 'User Disabled!',
    userNotFound: "'User Not Found!",
    unknownError: 'Unknown Error',
    signIn: 'Sign In',
    welcome: 'Sign up or Login here',
    subHeader: 'Start using Tara for free',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    policyTerms: 'By signing up, I agree to the {0} and {1}',
    hero: {
      title: 'Finally, sprint management that ties into your code.',
      subtitle: 'Keep everyone informed on the release cycle.',
    },
  },
  drawer: {
    profileMenu: {
      profile: 'My Profile',
      integrations: 'Integrations',
      logout: 'Logout',
      workspace: 'My Workspace',
    },
    emptyStates: {
      tasks: 'This is where all added tasks will eventually land. Use {0} to organize your work better.',
      requirements:
        'Requirements can be anything your team needs to scope and build, such as specifications, user stories, designs, and more',
      repos: 'Connect Tara to {0}, {1}, or {2} to bring your entire development stack together.',
      imports: 'Bring your {0} or {1} boards into Tara.',
    },
  },
  navigation: {
    home: 'Home',
    define: 'Define',
    sprint: 'Sprint',
    progress: 'Progress',
  },
  dropdown: {
    category: {
      user: 'User',
      requirement: 'Requirement',
      sprint: 'Sprint',
      team: 'Team',
    },
    header: {
      addTeamMembers: 'Add team members',
      addRequirements: 'Add Requirement',
      addMembersToTeam: 'Add members to {teamName}',
    },
    placeholder: {
      searchUsers: 'Search users...',
      searchRequirements: 'Search requirements...',
    },
  },
  table: {
    search: 'Search',
    searchTaskPlaceholder: 'Search task by ID number (e.g. 5982), name, or assignee',
    searchRequirementPlaceholder: 'Search requirement by name or author',
    page: 'page',
    of: 'of',
    goToPage: '| Go to page:',
    show: 'Show {perPage}',
    firstPage: '<<',
    previousPage: '<',
    nextPage: '>',
    lastPage: '>>',
  },
  task: {
    metaName: 'Tara | {taskSlug}',
    titleTooShort: 'Title is too short (Less than 2 characters)',
    titleTooLong: 'Title Size Limit of 500 Characters Exceeded',
    failedToUpdateTask: 'Error: failed to update task. Please try again.',
    failedToRemoveTask: 'Error: failed to remove task. Please try again.',
    failedToCreateTask: 'Error: failed to create task. Please try again.',
    failedToDeleteTask: 'Error: failed to create task. Please try again.',
    subtasks: 'Subtasks',
    subtaskPlaceholder: 'Enter a subtask...',
    addASubTask: '+ Add a subtask',
    addATask: '+ Add a task here and press <ENTER> to submit',
    taskDesc: 'Task Desc',
    addADescription: 'Adding a description helps your team understand this task',
    status: 'Status',
    idColumnHeader: 'ID',
    sprintColumnHeader: 'Sprint',
    name: 'Name',
    effortEstimation: 'Effort Estimation',
    assignee: 'assignee',
    createdBy: 'Created by {name} • {date}',
    taskSlug: 'TASK-{slug}',
    in: ' in ',
    effort: 'Effort',
    collaborator: 'Collaborator',
    sprint: 'Sprint',
    requirement: 'Requirement',
    statusShortcut: 'Press "S" to edit status',
    effortShortcut: 'Press "E" to edit effort',
    assigneeShortcut: 'Press "A" to add assignee',
    collaboratorShortcut: 'Press "C" to add collaborator',
    sprintShortcut: 'Press "P" to select sprint',
    subtasksShortcut: 'Press "T" to add subtask',
    requirementShortcut: 'Press "R" to select requirement',
    days: 'days',
    addSubtask: 'Add subtask',
    taskActivityEnd: 'You’ve reached the end of the activity log.',
    copyLinkToTask: 'Copy link to task',
    createdByAuthor: 'Created by {author}',
    requirementTaskCount: '({currentTaskNumber}/{taskCount})',
    notHereAnymore: 'The task you are looking for is not here anymore',
    backToHome: 'Back to home',
    edited: 'Edited ',
    lastEditedDays: '• {days}d ago',
    lastEditedHours: '• {hours}h ago',
    lastEditedMinutes: '• {minutes}m ago',
    lastEditedSeconds: '• {seconds}s ago',
    copyTask: 'Copy task URL',
    copyURL: 'Copy URL',
    copied: 'Copied!',
    importedFrom: 'Imported from ',
    pressButton: 'Press "{button}" to {action}',
    actions: {
      editStatus: 'edit status',
      editEffort: 'edit effort',
      addAssignee: 'add assignee',
      addCollaborator: 'add collaborator',
      selectSprint: 'select sprint',
      addSubtask: 'add subtask',
      selectRequirement: 'select requirement',
    },
    deleted: 'Task-{slug} has been deleted',
    permissionsError: 'Permissions error.',
    taskTitleError: 'There was an error updating task title.',
    effortUpdated: 'Effort updated.',
    titleUpdated: 'Title updated.',
  },
  builder: {
    team: 'Team',
    addADescription: 'Add a description',
    totalEffortEstimation: 'Total Effort Estimation',
    description: 'Description',
    tasks: 'Tasks ({tasks})',
    conflict: {
      firstLine: 'Looks like someone else is editing this document right now. ',
      secondLine: 'You can {cta} your changes.',
      cta: 'force save',
    },
    profileMenu: {
      editName: 'Edit Name',
      archiveRequirement: 'Archive Requirement',
      restoreRequirement: 'Restore Requirement',
    },
    header: {
      updateStatus: 'Created by {author}, last updated {date}',
    },
  },
  board: {
    board: 'Board',
  },
  errorBoundry: {
    retry: 'Retry',
    somethingWentWrong: 'Something Went Wrong.',
    evenRobotsMakeMistakes: 'Even robots make mistakes.',
    pleaseTryAgain: 'Please try again',
  },
  onboarding: {
    tasksForm: {
      errorOnSkip: 'Error occurred during skip',
      createTaskFailed: 'Set up error: failed to create task.',
      header: "Now, let's create a few tasks in your first requirement.",
      taskPlaceholders: ['Design wireframes', 'Sign up form', 'Architecture'],
      tip: {
        firstLine: 'A requirement is used to group tasks.',
        secondLine: 'Use them for new features, projects, or milestones.',
      },
      next: 'Next',
      skip: 'Skip',
      lightbulb: {
        emoji: '💡',
        label: 'lightbulb',
      },
    },
    moveTasksForm: {
      header: 'Next, move your tasks from the backlog into sprints.',
      submit: 'Move tasks',
    },
    confirmationForm: {
      header: 'Great. You’ve got a sprint plan.',
      text: 'Add more tasks, invite your team, and start sprinting.',
      next: 'Go to sprint plan',
      partypopper: {
        emoji: '🎉',
        label: 'partypopper',
      },
    },
    emailVerification: {
      error: "Couldn't send the email. Please try again later.",
      didNotReceive: 'Did not receive the email? ',
      openGmail: 'Open Gmail',
      openOutlook: 'Open Outlook',
      openYahoo: 'Open Yahoo',
      resendEmail: 'Resend email.',
      sent: 'Sent!',
      verifyYourAccount: 'Verify your account to get started with Tara.',
      checkSpam: 'If you do not receive your email right away, please check spam.',
      weJustSent: '📩 Please check your email to verify',
    },
    welcomeToTara: "Hi, I'm Tara.",
    setupAccount: "Let's get started by naming your workspace.",
    labels: {
      asterisk: '*',
      name: 'Name',
      username: 'Username',
      role: 'Role',
      logout: 'Logout',
      organizationName: 'Organization Name',
      organizationUrl: 'Organization URL',
      taraRootUrl: 'www.app.tara.ai/',
    },
    loginFromDesktop: {
      header: 'Login from desktop to enjoy the full features of Tara.',
      submit: 'Continue to mobile site',
    },
    errors: {
      emptyName: 'Please enter a name!',
      emptyUsername: 'Please enter a username!',
      organizationNameTaken: 'Organization Name/URL is already taken!',
      usernameTaken: 'Username is already taken!',
      unableToCreateAccount: 'Unable to create account, please contact support',
      invalidUsername: 'Need a valid Username!',
      invalidName: 'Need a valid Name!',
      invalidOrganizationName: 'Need a valid Organization Name!',
      cannotDetermineOrganization: 'Sorry, we were not able to determine your organization.',
      invalidToken: 'Your invitation has expired or is invalid.',
      default: 'An error has occurred. Please try again.',
    },
    connectToGitHub: 'Connect to GitHub',
  },
  joinWorkspace: {
    invitationMessageWithInviterName: '{inviterName} invited you to join the {organizationName} Workspace',
    invitationMessageWithoutInviterName: 'You have been invited to join the {organizationName} Workspace',
    createAccount: 'Create an account to start collaborating on Tara.',
    joinTara: 'Join Tara',
    expiredInvitation: {
      message: 'Your invitation has expired.',
      contactInfo: 'Please contact your team administrator to resend the invite link.',
    },
    errors: {
      general: 'Joining workspace has failed.',
    },
    progress: {
      userAccountSetup: 'We are setting up your user account now, it will be ready for you soon…',
      joiningWorkspace: 'We are setting up workspace for you, it will be ready for you soon…',
    },
  },
  userAccountSetupProgress: {
    animatedTexts: [
      '💡A 5-day sprint once helped deploy a navy ship.',
      '📝 Use requirements to spec features, create user stories and more.',
      '🏉 Scrum began as a term in Rugby.',
      '💡Effort can be changed to days, hours or points.',
      "💡Sprints aren't only for engineering! Use sprints to prioritize for marketing, sales or any other teams and tasks.",
      '✅ Sprints can be weekly, or bi-weekly - just toggle in settings.',
      '🤖 I love power naps. They help me build up charge.',
      '😍 Keyboard shortcuts work throughout Tara. Ask your friendly task modal!',
      '🥤A tall glass of cold bubble tea helps sprints run faster.',
      '💡To organize your tasks, group them into a requirement.',
    ],
  },
  passwordReset: {
    reset: 'Set new password.',
    updatePassword: 'Update Password',
    emailAddress: 'Email address',
    newPassword: 'New password',
    passwordMustBeSixChar: 'Password must be longer than six characters',
    passwordSuccessfullyChanged: 'Password successfully changed.',
  },
  firebaseEmailActionHandlers: {
    verifying: 'Verifying…',
    emailVerified: 'Email Verified!',
    youCanLogIn: 'You can log into your account.',
    continueToLogin: 'Continue to Login',
    verificationExpired:
      'Your email verification link has expired. A new email verification has been sent.Please check your email.',
    emailAlreadyVerified: 'Your email has already been verified.',

    contactSupport: 'Contact Support at support@tara.ai',
  },
  dashboard: {
    createANewRequirement: 'Create a new Requirement',
    lastUpdated: 'Updated {time}',
    noRecentRequirements: 'You currently have no recent requirements',
    noActiveTasks: 'You currently have no assigned tasks',
    recentRequirements: 'Recently Updated Requirements',
    recentTasks: 'Recently Assigned to You',
    welcomeBack: 'Welcome, {name}!',
    viewAllRequirements: 'View all Requirements',
    currentSprintAssignedTasks: 'Current Sprint Assigned Tasks',
    currentSprintNumber: 'View Sprint {number}',
    learnMore: 'Learn More',
    startASprint: 'Start a Sprint',
    createTasks: 'Create Tasks',
    status: 'status',
    assignee: 'assignee',
    title: 'title',
    assignedTasksHeader: 'Tasks',
    assignedTaskSubHeader: '{number} task is assigned to you.',
    assignedTasksSubHeader: '{number} tasks are assigned to you.',
    collaboratorTaskSubHeader: '{number} task lists you as a collaborator.',
    collaboratorTasksSubHeader: '{number} tasks list you as a collaborator.',
    currentSprint: 'Current Sprint',
    noAssignedTasks: 'There are currently no tasks assigned to you.',
    noActiveSprints: 'There are currently no active sprints.',
    planYourNextSprint: ' Plan and start your next sprint here.',
    pullRequest: {
      title: 'Pull requests',
      pullRequestDescription: 'You are an assigned reviewer on the following pull requests.',
      reviewPR: 'Review PR',
      okay: 'Okay',
      learnMoreModal: {
        title: 'Manage and view all the pull requests you need to review',
        connectGithubTitle: '1. Connect with GitHub',
        connectGithubBody:
          'Link your GitHub profile and the GitHub repos you work on so that Tara can organize your work for you.',
        seePRs: '2. See the pending pull requests requiring your review',
        seePRsBody:
          'From the selected repos, Tara centralizes all the pending pull requests that need your review on your personal homepage.',
        reviewPRs: '3. Review pull requests and unblock your team',
        reviewPRsBody:
          'Access those pull requests on GitHub with one click. Check them off the list as each pull request gets closed.',
      },
      empty: {
        noPullRequestsTitle: "You don't have any open pull requests to review right now.",
        noCreatedByYouPRs: "You don't have any open pull requests now.",
        noPullRequestsDescription: 'Take 5 and celebrate a clean queue',
        githubProfileNotLinked: 'View all the pull requests that need your review.',
        githubNotInstalled: 'Import your team’s repository to track your pull requests.',
        enableFeature: 'To enable this feature, do the following: ',
      },
    },
    getStartedWithTara: 'Get started with Tara',
    importTasksFromTrello: 'Import boards from Trello',
    importFromTrelloBtnLabel: 'Import from Trello',
    skipImport: 'Skip Import',
    home: 'Home',
  },
  profile: {
    joinOrganization: 'Join Organization {name}',
    contractWork: 'Contract Work',
    profile: 'Profile',
    logout: 'Logout',
    connectProfile: 'Connect Profile',
    connectedProfile: 'Connected Profile',
    userIntegrations: 'User Integrations',
    integrateYourUserProfile:
      'Integrate your user profile with 3rd party apps to get custom insights and personalized notifications.',
    connectWithGitHubTitle: 'Step 2: Connect GitHub Profile',
    connectWithGitHubDescription:
      'This connects your GitHub user to your profile and gets you more visibility into assigned Pull Requests and Review statuses. (Note: your Step 1: GitHub App must be installed to make the most of the profile connection)',
    connectWithGitHub: 'Connect GitHub Profile',
    connectedWithGitHub: 'Connected GitHub Profile',
    connectWithSlackTitle: 'Step 2: Connect Slack Profile',
    connectWithSlackDescription:
      'Connect your Slack profile to get personalized notifications and get full task previews inside direct messages. (Note: your Slack app must be installed to make the most of the profile connection)',
    connectWithSlack: 'Connect Slack Profile',
    connectedWithSlack: 'Connected Slack Profile',
    role: 'Role',
    resetPassword: 'Reset password',
    resetEmailSent: "We've sent an email to {userEmail}. Click the link in the email to reset your password.",
    label: {
      name: 'Name',
      username: 'Username',
      emailAddress: 'Email Address',
      designer: 'Designer',
      developer: 'Developer',
      productManager: 'Product Manager',
      manager: 'Manager',
      architect: 'Architect',
      openToContractWork: 'Open to contract work',
    },
    teams: {
      title: 'Teams',
      description: 'Set a preferred team to....',
      nameColumnLabel: 'name',
      preferredTeamLabel: 'Preferred',
      setPreferredTeamButtonLabel: 'Set as preferred',
      setPreferredSuccess: 'Preferred team successfully updated',
      setPreferredError: "Couldn't set team as preferred.",
      missingDataError: 'Missing credentials',
    },
  },
  deleteWithConfirmationModal: {
    cancel: 'Cancel',
    delete: 'Delete',
    areYouSure: 'Are you sure you want to delete “{nameToDelete}”?',
    thisCannotBeUndone: 'This cannot be undone.',
    title: 'Delete {nameToDelete}',
    placeholder: 'To confirm type “{nameToDelete}” in this field',
  },
  workspace: {
    tabs: {
      workspace: 'Workspace',
      users: 'Users',
      teams: 'Teams',
      integrations: 'Integrations',
    },
    deleteWorkspace: {
      title: 'Delete workspace',
      areYouSure: 'Are you sure you want to delete {organizationName}? This cannot be undone.',
      confirmationInstructions: 'To confirm type "{organizationName}" in the field below.',
      error: "Couldn't delete workspace",
      cancel: 'Cancel',
      yes: 'Delete',
    },
    label: {
      name: 'Name',
      workspaceURL: 'Workspace URL',
      workspaceColor: 'Workspace Color',
    },
    leaveWorkspace: {
      areYouSure: 'Are you sure you want to leave {organizationName}?',
      cancel: 'Cancel',
      error: "Couldn't leave workspace.",
      title: 'Leave workspace',
      yes: 'Leave',
    },
    members: {
      many: '{membersCount} Members',
      one: '1 Member',
    },
    myWorkspace: 'My Workspace',
    colorPicker: {
      label: 'Workspace color',
      success: 'New workspace color has been saved',
      error: 'Unable to update workspace color. {errorMessage}',
    },
    effortToggler: {
      label: 'Effort estimate unit',
      description: 'This is the unit your team estimates each task by.',
      success: 'Effort unit has been saved to {effortUnit}',
      error: 'Unable to update effort estimate unit. {errorMessage}',
      units: {
        days: 'Days',
        hours: 'Hours',
        storyPoints: 'Story Points',
      },
    },
    name: {
      success: '{name} workspace name has been saved',
      couldntChange: "Couldn't change name",
      cantBeBlank: "Workspace name can't be blank",
    },
  },
  maintenance: {
    title: 'Tara is currently undergoing maintenance',
    description1: 'Please check back in 30 minutes. Thank you for your patience.',
    description2: 'Get the latest updates on our blog and follow us on Twitter.',
    blog: 'Read our blog',
    twitter: 'Twitter',
  },
  teams: {
    table: {
      name: 'Name',
      members: 'Members',
    },
    join: 'Join Team',
    joined: 'Joined',
    create: '+ Create Team',
    selector: {
      searchPlaceholder: 'Search teams',
      header: 'Add teams',
      plusButton: 'Plus button',
    },
  },
  keyStatistics: {
    title: 'Key Statistics',
    teamStats: 'Team Stats',
    yourStats: 'Your Stats',
    done: 'Done',
    effort: 'Effort',
    totalEffortCompleted: '{effortCompleted}/{effort}',
    totalTasksDone: '{number}%',
    emptyState: '-',
    emptyStateTooltip: "You have no assigned tasks for this sprint. We'll show your completion rates here when you do.",
    tooltip: 'You have completed {number}% of the tasks assigned to you',
    tooltipEffort: 'You have completed {effortCompleted} of the tasks assigned to you',
    progressDetails: 'Progress Details',
  },
  integrations: {
    title: 'Workspace Integrations',
    description: 'Connect to the tools you use everyday',
    appsYouConnected: 'Apps you connected',
    connect: 'Connect',
    disconnect: 'Disconnect',
    connected: 'Connected',
    comingSoon: 'Coming Soon',
    manageRepository: 'Manage Repositories',
    disconnectedProfileSuccess: 'Successfully disconnected {service} profile',
    disconnectedProfileError: 'An error occurred trying to disconnect {service} profile',
    github: {
      title: 'Step 1: GitHub App Install',
      description:
        'Installing the GitHub App on Tara Imports your open issues. Allows you to attach your Pull Requests, Commits or Branches to Tasks on Tara as well as get PR Blocked and Merged Status Updates.',
      repositoriesConnected: 'Repositories Connected',
      errors: {
        noRepositoryTitle: 'Please select one or more repositories.',
        noRepositoryDescription:
          'We noticed that you did not select a repository when connecting Tara with GitHub. Please select one or more repositories so that you can manage your open issues and pull requests in Tara.',
      },
      connectBtnDisabledTooltip:
        'Please contact your Tara Admin to connect to GitHub repo. You can find your Tara Admin on the User List.',
    },
    gitlab: {
      title: 'Connect with GitLab',
      description:
        'Link your merge requests from GitLab to tasks in Tara and receive actionable insights to how your team performed.',
      manage: 'Manage',
      disabled: 'Only a Tara admin can connect integration',
      connectBtnDisabledTooltip:
        'Please contact your Tara Admin to connect to Gitlab workspace. You can find your Tara Admin on the User List.',
      modal: {
        alt: 'Gitlab integration',
        title: 'Gitlab Integration',
        heading: 'To integrate Tara with GitLab, please do the following:',
        step1: '1. In your GitLab Projects page, Go to ',
        settings: 'Settings > Webhooks',
        step2: '2. Paste in Tara webhook URL',
        step3: '3. Paste in Secret Token',
        generateToken: 'Generate Secret Token',
        generatingToken: 'Generating Token',
        step4: '4. Select all event triggers and enable SSL cert',
        step5: '5. Click Add Webhook',
        done: 'Done',
        webhookUrl: 'https://us-central1-tara-cloud.cloudfunctions.net/webhooks/gitlab',
        copy: 'Copy',
        copied: 'Copied!',
      },
    },

    slack: {
      title: 'Step 1: Install Slack App',
      description: 'Integrate with Slack to have all your conversations revolving around products in one platform.',
      connectBtnDisabledTooltip:
        'Please contact your Tara Admin to connect to Slack workspace. You can find your Tara Admin on the User List.',
      connectAccountNotification: {
        slackAppInstalled: 'The Slack app has been installed to your workspace. ',
        clickToConnect: 'Click here to connect to your profile and finish setup',
      },
    },
    trello: {
      title: 'Trello',
      description:
        'Manage your boards from Trello in Tara. To import your data into Tara, you have to first export the data from your Trello account.',
      exportInstructionUrlLabel: 'Learn about exporting data from Trello.',
    },
    asana: {
      title: 'Asana',
      description:
        'Manage your tasks from Asana into Tara. To import your data into Tara, you have to first export the data from your Asana account.',
      exportInstructionUrlLabel: 'Learn about exporting data from Asana.',
    },
    importBtnLabel: 'Import',
    importSectionHeader: 'Import',
  },
  users: {
    activeUsers: {
      tabLabel: 'Active users',
      table: {
        name: 'Name',
        email: 'Email',
        role: 'Role',
      },
      roleSelector: {
        placeholder: 'Select Role',
        labels: {
          admin: 'Admin',
          deactivated: 'Deactivate',
          user: 'User',
        },
        toasts: {
          error: "Couldn't change role.",
          success: 'Changed role for {name} to {role}.',
          successDeactivated: '{name} deactivated.',
        },
      },
      toasts: {
        error: "Couldn't get users.",
      },
    },
    deactivatedUsers: {
      empty: 'All users are active',
      reactivate: 'Reactivate',
      tabLabel: 'Deactivated',
      table: {
        action: 'Action',
        name: 'Name',
      },
      toasts: {
        error: "Couldn't get users.",
        reactivate: {
          error: "Couldn't reactivate user.",
          success: '{name} reactivated.',
        },
      },
    },
    invitedUsers: {
      tabLabel: 'Invited',
      resendBtn: 'Resend',
      resendSuccessNotification: 'Invitation sent!',
    },
    invite: 'Invite Users',
    noInvitesTitle: "You haven't invited anyone yet",
    noInvitesBody: 'Invite your team members to collaborate with you on Tara!',
  },
  tasks: {
    assignSelf: 'Assign to Self',
    assignee: 'Assignee',
    collaborators: 'Collaborators',
    delete: 'Delete',
    tags: 'Tags',
    plusAddTask: '+ Add task',
    effort: 'Effort Estimation',
    sprint: 'Sprint',
    status: 'Status',
    sprintHeader: 'Sprint',
    effortEstimationError: 'Effort level must be between 1 and 99.',
    name: 'Name',
    tasks: 'Tasks',
    requirement: 'requirement',
    missingCredentials: 'Missing user credentials',
  },
  products: {
    table: {
      name: 'Name',
      author: 'Author',
      team: 'Team',
      lastUpdated: 'Last Updated',
    },
  },
  requirements: {
    tasksCompleted: 'Tasks ({completeTasks}/{tasks})',
    titleTooShort: 'Title is too short (Less than 2 characters)',
    titleTooLong: 'Title Size Limit of 500 Characters Exceeded',
    missingCredentials: 'Missing user credentials',
    page: 'requirements',
    title: 'Requirements',
    createdBy: 'Created by {name}',
    lastUpdatedAt: 'last updated {timeSince}',
    lastUpdatedBy: 'last updated by {name} {timeSince}',
    description:
      'Requirements can be anything your team needs to scope, build, and improve software like designs, specifications, user stories, and more.',
    createRequirement: 'Create a Requirement',
    emptyStateRequirementDetails: 'Group tasks into a requirement',
    enterRequirementName: 'Enter your requirement name...',
    cancel: 'Cancel',
    create: 'Create',
    createAndDefine: 'Create and Define',
    failedToCreateRequirement: 'Failed to create requirement due to the following error: ',
    emptyStateRequirements: 'No requirements found.',
    emptyStateRequirementsWithLabel: 'No {label} requirements found.',
    noTeamsAssigned: 'Requirements will only appear in teams that are assigned to them.',
    createOne: 'create one!',
    createFailure: 'Failed to create requirement. {errorMessage}',
    createTooShort: 'Invalid title. Title must be two or more characters.',
    createMissingPreferredTeam: 'Your preferred team could not be found',
    createSuccess: 'Successfully created a new requirement in team {teamName}',
    or: 'or',
    importCardLabels: {
      trello: 'Import boards from Trello',
      asana: 'Import tasks from Asana',
      github: 'Import issues from GitHub',
    },
    active: 'Active',
    archived: 'Archived',
    archivedSuccess: '{name} was Successfully Archived',
    archivedFailure: 'Failed to archive requirement. {errorMessage}',
    restoreArchivedSuccess: '{name} was Successfully Restored',
    restoreArchivedFailure: 'Failed to restore the archived requirement. {errorMessage}',
    failedToAssignTeam: 'Failed to assign team to requirement.',
    failedToRemoveTeam: 'Failed to remove team from requirement.',
    backToRequirements: 'Back to Requirements',
  },
  GanttChartHeader: {
    title: 'Task Timeline',
  },
  sprints: {
    error: {
      missingSprintId: 'Missing sprint ID',
      sprintNotFound: 'Sprint not found',
      teamNotFound: 'Team not found',
      cannotDeleteActiveSprint: 'Cannot delete an active sprint',
      updateFailure: 'Failed to update sprint. {errorMessage}',
    },
    loadingSprints: 'Loading sprints...',
    loadingTasks: 'Loading tasks...',
    missingOrg: 'Missing org credentials',
    missingUserCredentials: 'Missing user credentials',
    title: 'Sprints',
    backlogTitle: 'Backlog',
    emptyStateBacklog: 'No tasks found, ',
    createTask: 'Create Task in Tasks Backlog',
    createTaskName: 'Create task in {name}',
    createSprintNumber: 'Create task in Sprint {number}',
    createSprintName: 'Create task in {name}',
    backlogTasks: 'Tasks Backlog',
    taskCount: '{number} tasks left',
    backlogTaskCount: '{number} tasks',
    headerTaskCount: ' ({number})',
    createOne: 'create one!',
    create: 'Create Sprint',
    newSprint: 'Create a new Sprint',
    creatingSprint: 'Creating Sprint...',
    creating: 'Creating...',
    deleting: 'Deleting and moving tasks to backlog...',
    deletedSuccess: '{sprintName} deleted. {numberOfTasks} task(s) were moved to the backlog',
    deletingFailure: 'Failed to delete sprint. {errorMessage}',
    createSprintSuccess: 'Successfully created {sprintName}',
    createSprintFailure: 'Failed to create sprint. {errorMassage}',
    sprintNumber: 'Sprint {number}',
    upcoming: 'Upcoming',
    completed: 'Completed',
    completedAndMovedToast:
      '{completedSprint} has been completed and your remaining tasks have been moved to {tasksMovedTo}.',
    movedToNewSprintSuccess: 'Moved tasks to new sprint {sprintName}.',
    movedToNewSprintError: 'An error occurred when creating a new sprint, moved tasks to backlog. Error: {message}.',
    completedToast: '{completedSprint} has been completed.',
    repeatsSingular: 'Repeats every 1 week',
    repeatsPlural: 'Repeats every {durationWeeks} weeks',
    tasksCompleted: '{completedTasks}/{tasks} tasks',
    completedHeader: {
      prsClosed: 'PRs closed',
      prCommits: '{number} commits',
      effortCompleted: 'Effort Completed',
      values: '{completed}/{total} ({percent}%)',
      taskValues: '{done}/{total} tasks done',
      onlyCompletedTaskValuesPlural: '{done} tasks done',
      onlyCompletedTaskValuesSingular: '1 task done',
      header: 'Every complete sprint is a win, here is what your team accomplished in this one.',
      rocket: {
        emoji: '🚀',
        label: 'Completed! Take off!',
      },
    },
    dropZone: {
      backlog: 'Move to Backlog Tasks',
      backlogWithRequirement: "Move to task's requirement {requirement}",
      sprint: 'Move to {sprint}',
      emptyColumn: 'Plan a sprint by dragging tasks from backlog or create a task.',
      emptyColumnAlt: 'Empty column',
    },
    featureCard: {
      view: 'View Details',
      remove: 'Backlog',
      newSprint: 'New Sprint',
    },
    sprintTimeline: {
      tasks: 'Tasks ({count})',
      filterByRequirement: 'Filter by requirement',
      filterByAssignee: 'Filter by assignee',
      searchUsers: 'Search users...',
      searchRequirements: 'Search requirements...',
    },
    sprintColumn: {
      current: 'Current',
      startDate: 'Start Date',
      startSprint: 'Start Sprint',
      sprintDateSpace: ' - ',
      complete: 'Complete Sprint',
      viewDetails: 'View Details',
      effort: 'Effort',
      effortTooltip: 'Total effort',
      totalEffortCompleted: '{completedEffort}/{totalEffort}',
      overloadTooltip:
        'Your sprint has been overloaded. The recommended effort is {number} based on your past performance.',
      disabledTooltip: 'Complete your current sprint to start the next one.',
      zeroTasksDisabledTooltip: 'Sprint cannot be started because there are no tasks yet.',
      sprintOverloadPoints: 'Sprint overload: {overloadPoints} points',
      filteredByStatus: 'Filtered by "{status}"',
      filteredByAssignee: 'Filtered by "{assignee}"',
      twoFiltersApplied: '2 filters applied',
      planASprintHelper: 'Plan a sprint by dragging tasks from the "Work" drawer (left side)',
      sprintDateRange: '{startDate} - {endDate}',
      simpleLabels: ['To Do', 'Doing', 'Done'],
    },
    sprintDetails: {
      sprints: 'Sprints',
      sprintNumber: 'Sprint {number}',
      completeSprint: 'Complete',
      taskTimeline: 'Task Timeline',
      tasks: 'Tasks',
      summary: 'Summary',
      gitStatus: {
        commitCount: '{commitCount} commit',
        commitsCount: '{commitCount} commits',
        blocked: 'PR blocked',
        merged: 'PR merged',
        pending: 'PR opened',
      },
    },
    sortBy: {
      sort: 'Sort by...',
      none: 'Default',
      az: 'Task Title (Ascending)',
      za: 'Task Title (Descending)',
      assignee: 'Assignee',
      status: 'Task Status',
      oldest: 'Date Created (Oldest first)',
      newest: 'Date Created (Newest first)',
    },
    sprintPlanning: {
      planning: 'Planning',
      totalEffort: 'Total effort',
      recommendedEffort: 'Recommended effort',
      tasks: 'Tasks',
      tasksUnassigned: 'Tasks Unassigned',
      findOutMore: 'Find out more',
    },
    edit: {
      editSprint: 'Edit {name}',
      sprintName: 'Sprint Name',
      duration: 'Duration',
      startDate: 'Start Date',
      endDate: 'End Date',
      cancel: 'Cancel',
      save: 'Save',
      weeks: '{number} Weeks',
      youMightHave: 'You might have',
      overloadedYourSprint: ' overloaded your sprint.',
      takeALook: ' Take a look at your past sprint performance.',
      invalidFields:
        'Please check your input. Sprint name must be at least two characters and the sprint start date must not be blank.',
    },
    start: {
      startSprint: 'Start {name}',
      cancel: 'Cancel',
      start: 'Start',
      sprintName: 'Sprint Name',
    },
    complete: {
      completeSprint: 'Complete {name}',
      backlog: 'Backlog',
      newSprint: 'New Sprint',
      cancel: 'Cancel',
      complete: 'Complete',
      moveFeatures: 'Move remaining features to:',
      featuresComplete: '{completedTasksLength}/{allTasksLength} features were completed this sprint',
      sprintSummaryName: '{name} summary',
      sprintSummaryDetails: 'Congrats on completing your sprint, here is what your team accomplished in this one.',
      effortCompleted: 'Effort Completed',
      effortCompletedAmount: '{completedEffort}/{totalEffort}',
      effortCompletedPercent: '({effortCompletedPercent}%)',
      tasksDone: '{completedTasks}/{totalTasks} tasks done',
      pullRequestsClosed: 'PRs closed',
      pullRequestsClosedOverTotal: '{closedPullRequestsCount}/{allPullRequestsCount}',
      commits: '{number} commits',
      moveRemainingTasksTo: 'Move remaining {number} tasks to:',
      notificationLabel: 'Completing {sprintName}....',
    },
    insights: {
      pastSprintInsights: 'Past sprint insights',
      planNextSprint: 'Plan your next sprint with performance from your past sprints.',
      planNextSprintOverload:
        "You might be overloading this sprint based on your team's past performance. Take a moment and consider rebalancing tasks.",
      sprintNumber: 'Sprint {number}',
      planning: 'Planning',
      totalEffort: 'Total effort',
      recommendedEffort: 'Recommended effort',
      pastSprintCompletedEffort: 'Past sprint completed effort',
      average: 'Average',
      outOf: 'out of {number}',
      recommended: 'recommended',
      estimatedEffortInfo: 'We calculate this as a running average of the last 3 completed sprints.',
      emptyEstimatedEffortInfo:
        "We'll start displaying effort recommendations after you complete your first sprint. Then we'll use a 3 sprint running average to calculate them.",
      empty: '--',
    },
    view: 'View',
    noActiveSprints: {
      header: 'No active sprints',
      message: 'There are currently no active sprints.',
      startASprint: 'Start a sprint',
      toViewInsights: ' to view insights!',
    },
  },
  sprintsSettings: {
    cancel: 'Cancel',
    save: 'Save',
    title: 'Sprint Settings',
    nextSprintTitle: 'Next Sprint Number',
    newSprintNumber: 'New sprints created will be named {sprintNum1}, {sprintNum2}, {sprintNum3} ...',
    newSprintPrepend: 'Sprint',
    duration: 'Sprint Duration',
    updateError: 'There was an error saving sprint settings.',
    endTime: 'Sprint End Time',
    selectTimezone: 'Select Timezone',
    timezoneDescription: 'Select a Time and a Time Zone at which you Sprint ends.',
    autoPilot: 'Sprint Auto-pilot',
    autoPilotDescription: 'Select if you want sprints to automatically roll over on a certain time and day.',
    on: 'On',
    off: 'Off',
    durationDescription: 'Select a weekly time period at which your sprint rolls over.',
    endDay: 'Sprint End Day',
    endDayDescription: 'Select a day on which your sprint should end.',
    sat: 'Sa',
    sun: 'Su',
    mon: 'Mo',
    tue: 'Tu',
    wed: 'We',
    thu: 'Th',
    fri: 'Fr',
    bannerMessage: 'Your sprint will end on {endDate} and start on {startDate}.',
    nextSprint: 'Next Sprint',
    backlog: 'Backlog',
    remainingTasksDestination: 'Remaining tasks container',
    remainingTasksDestinationDescription: 'Select a place where your want your unfinished tasks to be moved.',
    cantDisableAutoPilot: "You can't disable auto-pilot once it's on.",
  },
  required: '*',
  taskStatus: {
    /**
     * The label according to the firebase task `status` value
     * 0: TO DO
     * 1: DOING
     * 2: DONE
     */
    statusLabels: ['🤔 TO DO', '👩🏽‍💻 DOING', '✅ DONE'],
  },
  inviteUserPopup: {
    title: 'Invite to workspace',
    defaultInviteMessageContent: 'I’m working on this project in {orgName} and would like you to collaborate with me!',
    copy: 'Copy',
    copied: 'Copied!',
    validTill: 'Invite valid till ',
    link: {
      header: 'Link',
      deactivate: 'Deactivate',
      expirationNote: 'Expiring in 3 days',
      submit: 'Create Invite Link',
    },
    email: {
      header: 'Email',
      placeholder: 'Type Email addresses...',
    },
    submit: {
      errorMessage: 'We occured some issues. ',
      contactSupport: ' Contact support',
      successMessage: 'Invitations sent!',
      buttonLabel: 'Send invite',
    },
  },
  lastCheckIn: {
    title: 'Last Check in',
    teamMember: 'Team member',
    lastCommitted: 'Last Committed',
    empty: {
      title: 'No commits made for the current sprint 🙀',
    },
  },
  pullRequests: {
    title: '{openPRs} Open Pull Requests',
    openPRs: '{openPRs} Open',
    branch: 'Branch',
    status: 'Status',
    oldest: 'Oldest',
    newest: 'Newest',
    pullRequest: 'Pull Request',
    openedSince: 'Open Since',
    profileConnectedToast: 'GitHub profile is connected',
    repoConnectedToast: 'GitHub repository is connected',
    empty: {
      title: 'Track all open pull requests raised by your team so that they will not become stale.',
      details: 'Open or lagging pull request can slow momentum or create technical debt.',
    },
    prStatus: {
      /**
       * The label according to the pr `status` value
       * Checks failed
       * Blocked
       * Conflicts
       * Merge ready
       * Merged/Closed
       * Pending status
       */
      // unknown: 'pending',
      // behind: 'conflicts',
      // dirty: 'conflicts',
      // unstable: 'checks-failed',
      // blocked: 'blocked',
      // has_hooks: 'merge-ready',
      // clean: 'merge-ready',
      statusLabels: {
        checksFailed: 'Checks failed',
        blocked: 'Blocked',
        conflicts: 'Conflicts',
        mergeReady: 'Merge Ready',
        merged: 'Merged',
        pending: 'Pending',
      },
    },
  },
  pullRequestListItem: {
    issueID: '[{id}]',
    linesAdded: '+{linesAdded}',
    linesRemoved: '-{linesRemoved}',
    createdAt: '{time} by {user}',
  },
  pullRequestDetails: {
    assignee: 'Assignee',
    pendingReview: 'Pending review',
  },
  backlog: {
    title: 'Backlog ({count})',
    description:
      "All your organization's work, past, present, and future, is right here. The tasks listed below are not currently attached to a sprint.",
    createFeature: 'Create Feature',
    emptyList: {
      header: "You haven't created or imported any tasks yet",
      sub: 'Start by importing your GitHub issues or creating an initiative, sprint, or task in Tara.',
    },
    importGithub: 'Import GitHub Issues',
    taraCreate: 'Create in Tara',
    status: ['To Do ({todoCount})', 'Doing ({doingCount})', 'Done ({doneCount})'],
    tableHeader: {
      status: 'Status',
      task: 'Task',
      effort: 'Effort',
      created: 'Created',
      assignee: 'Assignee',
    },
  },
  sprintDropdown: {
    select: 'Select Sprint',
    nextSprint: 'Sprint {sprintNumber}',
    createNew: 'Create New',
    active: ' (Active)',
    preferred: ' (Preferred)',
    backlog: 'Backlog',
  },
  requirementDropdown: {
    placeholder: 'Select Requirement',
    noSelection: 'None',
  },
  sprintSettings: {
    oneWeek: '1 week',
    twoWeeks: '2 weeks',
    fourWeeks: '4 weeks',
  },
  attachments: {
    title: 'Attachment',
    fileTooBig: 'Error: File size is larger than 1 GB.',
    link: 'link',
    uploading: 'Uploading File',
    uploadError: 'There was an error uploading attachment. {errorMessage}',
    tooBigError: 'Error. Attachment is too large. That maximum attachment size is {attachmentSize}mb.',
    removeError: 'There was an error removing attachment. {errorMessage}',
    uploadTimedOut: 'Error: Upload timed out.',
    permissionError: 'Permissions error.',
    missingAttachment: 'Error. Missing attachment.',
  },
  importModal: {
    trello: {
      title: 'Import your boards from Trello',
      description: 'To import your Trello data into Tara, you have to first export the data from your Trello account.',
      exportInstructionUrlLabel: 'Exporting data from Trello',
    },
    asana: {
      title: 'Import your tasks from Asana',
      description: 'To import your Asana data into Tara, you have to first export the data from your Asana account.',
      exportInstructionUrlLabel: 'Exporting data from Asana',
    },
    importTasksBtnLabel: 'Import tasks',
    uploadFileBtnLabel: 'Upload CSV',
  },
  importNotificationBar: {
    trello: {
      importInProgressCopy: 'Importing your boards from Trello....',
    },
    asana: {
      importInProgressCopy: 'Importing your tasks from Asana....',
    },
    defaultImportFinishedCopy: 'All your tasks have been imported!',
    defaultImportedTasksLinkLabel: 'View them here.',
    github: {
      importInProgressCopy: ' Importing your issues from GitHub....',
      issuesImportedCopy: '{issueCount} open GitHub issues have been imported!',
      errorCopy: 'There are some errors with importing your GitHub issues.',
    },
    defaultTryAgainCopy: 'Try again?',
  },
  importIndicatorInBacklog: {
    importingTasks: 'Importing {tasksProgress} tasks...',
    taskCount: '{taskCount} tasks',
    importedFromTrello: 'Imported from Trello',
    importedFromAsana: 'Imported from Asana',
    importedFromGithub: 'All Open Issues',
  },
  menuFilter: {
    reset: 'Reset',
    ok: 'OK',
  },
  profileImage: {
    uploadPhoto: 'Upload photo',
    changePhoto: 'Change photo',
    removePhoto: 'Remove photo',
    uploading: 'Uploading...',
    removing: 'Removing...',
    uploadError: 'There was an error uploading your profile photo. Error: ({errorMessage})',
    permissionError: 'Missing user permissions',
    imageTooLarge: 'Image too large. Profile photo must be 2mb or less.',
    uploadTimeout: 'Upload timed out.',
    fileIssue: 'File issue. Please try again.',
    successfullyUploaded: 'Successfully uploaded profile photo.',
    successfullyRemoved: 'Successfully removed profile photo.',
  },
  workspaceLogo: {
    uploadLogo: 'Upload logo',
    changeLogo: 'Change logo',
    removeLogo: 'Remove logo',
    uploading: 'Uploading...',
    removing: 'Removing...',
    changeError: 'There was an error changing your workspace logo. Error: ({errorMessage})',
    imageTooLarge: 'Image too large. Workspace logo must be 2mb or less.',
    uploadTimeout: 'Upload timed out.',
    successfullyUploaded: 'Successfully uploaded profile photo.',
    successfullyRemoved: 'Successfully removed profile photo.',
  },
  gitTaskLifecycle: {
    noGitData: "There's no git data for this task 🙈",
    noCommits: 'There are no commits.',
    noBranches: 'There are no branches.',
    noPullRequests: 'There are no pull requests.',
    lastCommit: 'last commit',
    branches: 'branches',
    pullRequests: 'pull requests',
    github: 'github',
    updatedCommit: 'Updated {date}',
    linkYourTask: 'Link your task to your code by including the Task ID ',
    taskSlug: '(TASK-{taskSlug}) ',
    inYourBranch: 'in your branch name, commit message or pull request.',
    branchExample: 'Branch example:',
    prExample: 'Pull Request Title Example:',
    commitExample: 'Commit example:',
    gitBranchTask: 'git branch TASK-{taskSlug}',
    gitCommitTask: "git commit -m 'TASK-{taskSlug} your message....'",
    prTaskTitle: 'TASK-{taskSlug} your PR Title here....',
    copy: 'Copy',
    copied: 'Copied!',
    include: 'Include ',
    taskSlugQuote: '"TASK-{taskSlug}" ',
    inLinkYourCode: 'in your branch name to link your code to this task.',
    inYourPR: 'in your PR title to link your code to this task.',
    inYourCommit: 'in your commit message to link code to this task.',
    git: 'GIT',
  },
  revisions: {
    activity: 'Activity',
    taraBot: 'Tara-bot ',
    name: '{name} ',
    effort: 'effort',
    backlog: 'Backlog',
    updatedTitle: 'updated the {property} to {current} from {previous}',
    sprintTile: 'moved this task to "{current}" from "{previous}"',
    updatedAt: '{updatedAt} • {time}',
    noActivity: "There's no activity for this task yet 🙈",
    sprintName: '{sprintName}',
    endOfRevisions: "You've reached the end of the activity log.",
  },
  gitHubFallback: {
    connect: 'Connect to GitHub',
    profileLinked: 'Your GitHub Profile is Linked',
    repoConnected: 'Your GitHub Repos are Connected',
    linkYourGithubProfile: '1. Link your GitHub Profile',
    connectYourGithubRepos: '2. Connect Your GitHub Repos',
  },
  editor: {
    requirementDescriptionPlaceholder: 'Start writing your epics and specs, or drag an image...',
    taskDescriptionPlaceholder: 'Add more details about this task...',
    a11y: {
      link: 'Link',
      attachment: 'Attachment',
    },
    dataOverwritten:
      'The data in this editor just got updated by somebody else. Your latest changes were copied to your clipboard.',
    filesNotUploaded: 'Files were not uploaded. Please try again.',
    links: {
      copy: 'Copy',
      edit: 'Edit',
      remove: 'Remove',
    },
    addLink: {
      popper: {
        apply: 'Apply',
        cancel: 'Cancel',
        link: 'Link',
        text: 'Text',
      },
    },
    mentions: {
      deactivatedUser: '{user} (deactivated)',
    },
    attachments: {
      copy: 'Copy',
      remove: 'Remove',
    },
  },
  getDomain: {
    emailError: 'Invalid getDomain email in payload',
  },
  error: {
    user: {
      noID: 'Error. User ID not found.',
      noData: 'Error. User data not found.',
      nameTooShort: 'Name is too short (Less than 2 characters)',
      missingCredentials: 'Missing user credentials',
    },
  },
  workspaceSwitcher: {
    switchWorkspace: 'Switch Workspace',
    createWorkspace: 'Create a workspace...',
    createNewWorkspace: 'Create a new workspace...',
    workspaceSettings: 'Workspace settings',
  },
  createWorkspace: {
    placeholders: {
      workspaceName: 'Ex. company name',
      workspaceUrl: 'workspace-name',
    },
    labels: {
      asterisk: '*',
      workspaceName: 'Workspace Name',
      workspaceUrl: 'Workspace URL',
      taraRootUrl: 'www.app.tara.ai/',
      next: 'Next',
      cancel: 'Cancel',
    },
    emptyWorkspaceName: "Workspace name can't be empty!",
    availableWorkspaceUrl: '{organizationId} is available',
    workspaceUrlTaken: 'Workspace URL is already taken!',
    emptyWorkspaceUrl: "Workspace URL can't be empty!",
    invalidWorkspaceUrl: 'Workspace URL can contain only lowercase letters, digits and dashes.',
  },
  setupIntegration: {
    github: {
      setup: 'Setting up Github integration...',
      selectWorkspace: {
        title: 'Please select a workspace for your GitHub Integration.',
      },
    },
    slack: {
      setup: 'Setting up Slack integration...',
      selectWorkspace: {
        title: 'Please select a workspace for your Slack Integration.',
      },
    },
    gitlab: {
      setup: 'Setting up Gitlab integration...',
      selectWorkspace: {
        title: 'Please select a workspace for your Gitlab Integration.',
      },
    },
    error: "Couldn't complete integration.",
  },
  comments: {
    couldntAdd: "Couldn't add the comment.",
    empty: 'There are no comments yet.',
    leaveComment: 'Leave a comment...',
    menu: {
      delete: 'Delete',
    },
    deleteModal: {
      areYouSure: 'Are you sure you want to delete the comment?',
      cancel: 'Cancel',
      couldntDelete: "Couldn't delete the comment.",
      commentDeleted: 'Comment deleted!',
      delete: 'Delete',
      title: 'Delete comment',
    },
  },
  teamSwitcher: {
    teamSettings: 'Team settings',
    switchTeams: 'Switch teams',
    searchTeams: 'Search teams...',
    createTeam: 'Create new team',
    myTeams: 'My teams',
    edit: 'Edit',
    delete: 'Delete',
    allTeams: 'All teams',
    otherTeams: 'Other teams',
    viewDetails: 'View team details',
    selectTeam: 'Select which team to view',
    typeTeamName: 'Type a team name',
    name: {
      success: '{name} workspace name has been saved',
      couldntChange: "Couldn't change name",
      cantBeBlank: "Team name can't be blank",
    },
  },
  teamDetails: {
    editError: 'Error editing team details',
    editNameSuccess: 'Team name was updated successfully',
    isTeamMember: 'You are a member of this team.',
    joinSuccess: 'You joined {name}',
    joinError: "Couldn't join {name}: {error}",
    leaveSuccess: 'You left {name}',
    deleteSuccess: 'You deleted {name} team',
    leaveError: "Couldn't leave {name} team",
    deleteError: {
      default: "Couldn't delete {name} team",
      noAlternativeTeams: 'Unable to delete team. Every member must belong to at least 1 team.',
      activeOrCompletedSprints: 'This team cannot be deleted as there are completed sprints or active sprints.',
    },
    leaveTitle: 'Leave team',
    leaveTeamPrompt: 'Are you sure you want to leave the team?',
    cancel: 'Cancel',
    delete: 'Delete',
    leaveTeamPromptWithName: 'Are you sure you want to leave the team {name}?',
    joinTeam: 'Join team',
    leaveTeam: 'Leave team',
    deleteTeam: 'Delete Team',
    nameLabel: 'Team name',
    unableToLeaveTeam: 'Unable to leave team. Every member must belong to at least 1 team.',
    preferredTooltip: 'Tara will automatically prefill and display your preferred team in requirements and sprints.',
  },
  save: 'Save',
  cancel: 'Cancel',
  createTeamModal: {
    createTeam: 'Create Team',
    teamName: 'Team name',
    yourTeamName: 'Your team name',
    alreadyExists: 'Team name already exists.',
    cancel: 'Cancel',
    create: 'Create',
    validationFailure: 'Invalid Team name. The name is taken or contains invalid characters.',
    createSuccess: '{team} has been created.',
    createFailure: 'Failed to create team',
    createTooShort: 'Team name is too short. The name must be two or more characters.',
  },
  memberManager: {
    name: 'Name',
    role: 'Role',
    count: 'Members ({count})',
    remove: 'Remove',
    invite: 'Invite',
    addMember: {
      success: 'User {username} was added to the team {teamName}',
      error: 'User {username} could not be added to the team {teamName}',
    },
    removeMember: {
      success: 'User {username} has been removed from team {teamName}',
    },
    addMembers: {
      success: 'Add team members',
      error: 'Error: failed to add member. Please try again.',
    },
  },
  joinTeam: {
    success: 'You were added to the team {teamName}',
  },
  confirmDialog: {
    title: 'Confirm',
    prompt: 'Are you sure?',
    cancel: 'Cancel',
    yes: 'Yes',
  },
  teamMemberSelector: {
    searchUser: 'Search user...',
    addMembers: 'Add members to {teamName}',
    invitedUser: '(Invited)',
    addMember: {
      error: 'User {username} could not be added to the team {teamName}',
    },
    removeMember: {
      error: 'User {username} could not be removed from the team {teamName}',
    },
  },
  emptyTeamBacklogPopup: {
    title: 'Team Backlog ',
    description: 'Tasks and requirements that you create and are assigned to your team will show up in this backlog.',
  },
  workDrawer: {
    createTask: 'Create task...',
    assignHelpText: '@ to assign',
    labelHelpText: '# to label',
    estimateHelpText: '+n to estimate',
  },
  searchBar: {
    placeholder: 'Search tasks',
  },
};
