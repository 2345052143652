/* eslint-disable no-nested-ternary */
import TableCell from 'components/core/controllers/views/TableCell';
import TableRow from 'components/core/controllers/views/TableRow';
import { css } from 'emotion';
import React from 'react';
import { homePageTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export interface TasksAssignedHeaderProps {
  taskCount: number;
  colCount: number;
  header?: boolean;
}

/**
 * TasksAssignedHeader is the header component for the sprint dashboard tables
 *
 */
export default function TasksAssignedHeader({ colCount, taskCount, header }: TasksAssignedHeaderProps): JSX.Element {
  return (
    <TableRow
      className={css`
        background-color: #fbfbfd;
        border: 0;
        border-top: solid 1px #dee3ec;
        :hover {
          background-color: #fbfbfd;
          cursor: auto;
        }
      `}
    >
      <TableCell
        className={css`
          text-align: left;
          align-items: center;
        `}
        columnSpan={colCount}
      >
        {header && (
          <div
            className={css`
              padding-bottom: 0.5rem;
              padding-right: 0.5rem;
              font-size: 1rem;
              font-weight: 600;
              color: #303f4b;
            `}
          >
            {strings.dashboard.assignedTasksHeader}
          </div>
        )}
        <div
          className={css`
            padding-top: ${!header && '0.75rem'};
            font-size: 0.8125rem;
            font-weight: 500;
            color: #949caf;
          `}
          data-cy={homePageTestIDs.TASK_AMOUNT_ASSIGNED}
        >
          {header
            ? taskCount === 1
              ? strings.formatString(strings.dashboard.assignedTaskSubHeader, {
                  number: taskCount,
                })
              : strings.formatString(strings.dashboard.assignedTasksSubHeader, {
                  number: taskCount,
                })
            : taskCount === 1
            ? strings.formatString(strings.dashboard.collaboratorTaskSubHeader, {
                number: taskCount,
              })
            : strings.formatString(strings.dashboard.collaboratorTasksSubHeader, {
                number: taskCount,
              })}
        </div>
      </TableCell>
    </TableRow>
  );
}
