import { unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import Tara, { Data, UI } from '@taraai/types';
import UserAccessLevelSelector from 'components/app/controllers/UserAccessLevelSelector';
import Table from 'components/core/controllers/Table';
import Avatar from 'components/core/controllers/views/Avatar';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import React, { useCallback, useState } from 'react';
import { reduxStore } from 'reduxStore';
import { grantPermission } from 'reduxStore/users';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

import EmptyState from './EmptyState';

export interface ActiveUsersLayoutProps {
  orgID: Data.Id.OrganizationId;
  users: UI.UIUser[];
  isAdmin?: boolean;
}

/**
 * ActiveUsers displays a list of organization users with access levels or an empty state component
 */
export default function ActiveUsersLayout({ orgID, users, isAdmin = false }: ActiveUsersLayoutProps): JSX.Element {
  const [deactivationInProgress, setDeactivationInProgress] = useState<{
    [userId: string]: boolean;
  }>({});

  const { addToast } = useToast();

  const onAccessLevelChange = useCallback(
    async (userId: string, accessLevel: Tara.Data.AccessLevel): Promise<void> => {
      setDeactivationInProgress((prevState) => ({
        ...prevState,
        [userId]: true,
      }));
      try {
        const user = users.find(({ id }) => id === userId);
        if (!user) {
          throw new Error();
        }
        await reduxStore
          .dispatch(
            grantPermission({
              accessLevel,
              userId,
            }),
          )
          .then(unwrapResult);
        addToast({
          message: strings.formatString(
            accessLevel === 'deactivated'
              ? strings.users.activeUsers.roleSelector.toasts.successDeactivated
              : strings.users.activeUsers.roleSelector.toasts.success,
            {
              name: user.name,
              role: strings.users.activeUsers.roleSelector.labels[accessLevel],
            },
          ) as string,
          timeoutMs: 3500,
          type: 'success',
        });
      } catch {
        addToast({
          message: strings.users.activeUsers.roleSelector.toasts.error,
          timeoutMs: 3500,
          type: 'error',
        });
      } finally {
        setDeactivationInProgress((prevState) => ({
          ...prevState,
          [userId]: false,
        }));
      }
    },
    [addToast, users],
  );

  const tableData = users.map((user) => {
    return {
      id: user.id,
      name: (
        <CenteredCell>
          <StyledAvatar key={user.id} size='medium' user={user} />
          {user.name}
        </CenteredCell>
      ),
      email: <CenteredCell>{isAdmin ? user.email : ''}</CenteredCell>,
      role:
        user.accessLevels?.[orgID] && !deactivationInProgress[user.id] ? (
          <UserAccessLevelSelector
            accessLevel={user.accessLevels[orgID]}
            onAccessLevelChange={onAccessLevelChange}
            orgID={orgID}
            user={user}
          />
        ) : (
          <FastSmallSpinner />
        ),
    };
  });

  return (
    <div>
      {tableData.length < 1 ? (
        <EmptyState />
      ) : (
        <Table
          data={tableData}
          headers={{
            name: { name: strings.users.activeUsers.table.name, width: '15%' },
            email: {
              name: isAdmin ? strings.users.activeUsers.table.email : '',
              width: '70%',
            },
            role: { name: strings.users.activeUsers.table.role, width: '15%' },
          }}
        />
      )}
    </div>
  );
}

const CenteredCell = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledAvatar = styled(Avatar, {
  marginRight: '0.7rem',
});
