/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */
import { Tooltip } from '@material-ui/core';
import { Data, UI } from '@taraai/types';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { linkTo, usePathParams } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectTeam } from 'reduxStore';
import { sprintsTabTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { formatDMMM } from 'tools';

type SprintFragment = Pick<UI.UISprint, 'id' | 'initialStartDate' | 'initialEndDate'>;

export interface SprintColumnHeaderProps {
  isActive: boolean;
  isComplete: boolean;
  tasks: UI.UITask[];
  sprint: SprintFragment;
  isFirstSprint?: boolean;
  currentSprintId: Data.Id.SprintId | undefined;
}

type onClickHandler = (event: React.SyntheticEvent) => void;

function renderEmptyDate(
  orgID: Data.Id.OrganizationId,
  teamID: Data.Id.TeamId,
  sprint: SprintFragment,
  editSprint: onClickHandler,
): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        width: 6.25rem;
        height: 1.6875rem;
      `}
    >
      <Icon
        className={css`
          vertical-align: middle;
          height: 1rem;
          width: 1rem;
          padding: 0.4375rem;
        `}
        name='calendar'
      />
      <a
        className={css`
          align-self: center;
          text-decoration: none;
        `}
        href={linkTo('sprintEdit', {
          orgID,
          teamID,
          sprintID: sprint.id,
        })}
        onClick={editSprint}
      >
        <Text
          className={css`
            font-size: 0.875rem;
            font-weight: normal;
            color: #c8d0df;
            align-self: center;
          `}
        >
          {strings.sprints.sprintColumn.startDate}
        </Text>
      </a>
    </div>
  );
}

function renderSprintDate(
  orgID: Data.Id.OrganizationId,
  teamID: Data.Id.TeamId,
  sprint: Pick<UI.UISprint, 'id' | 'initialStartDate' | 'initialEndDate'>,
  textColor: string,
  editSprint: onClickHandler,
): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <a
        className={css`
          align-self: center;
          text-decoration: none;
        `}
        href={linkTo('sprintEdit', {
          orgID,
          teamID,
          sprintID: sprint.id,
        })}
        onClick={editSprint}
      >
        <Text
          className={css`
            font-size: 0.875rem;
            font-weight: normal;
            color: ${textColor};
            align-self: center;
            :hover {
              color: ${textColor};
            }
          `}
          data-cy={sprintsTabTestIDs.SPRINT_DATES}
        >
          {formatDMMM(sprint?.initialStartDate)}
          {strings.sprints.sprintColumn.sprintDateSpace}
          {formatDMMM(sprint?.initialEndDate)}
        </Text>
      </a>
    </div>
  );
}

export default function SprintColumnHeader({
  isActive,
  isComplete,
  sprint,
  tasks,
  isFirstSprint,
  currentSprintId,
}: SprintColumnHeaderProps): JSX.Element {
  const history = useHistory();

  const { orgID, teamID } = usePathParams('sprints');
  const team = useSelector(selectTeam(orgID, teamID));

  const textColor = isActive ? atomic.get(atomic.theme.color.success) : '#949caf';

  function editSprint(event: React.SyntheticEvent): void {
    event.preventDefault();
    history.push(
      linkTo('sprintEdit', {
        orgID,
        teamID,
        sprintID: sprint.id,
      }),
    );
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 0.5rem;
        min-height: fit-content;
        background-color: ${atomic.get(atomic.theme.color.transparent)};
      `}
    >
      {sprint.initialStartDate && sprint.initialEndDate
        ? renderSprintDate(orgID, teamID, sprint, textColor, editSprint)
        : renderEmptyDate(orgID, teamID, sprint, editSprint)}
      {!team?.sprintSettings.autoSprints ? (
        <StartOrCompleteBtn
          currentSprintId={currentSprintId}
          isActive={isActive}
          isComplete={isComplete}
          isFirstSprint={isFirstSprint}
          sprint={sprint}
          tasks={tasks}
        />
      ) : null}
    </div>
  );
}

type StartOrCompleteBtnProps = {
  isActive: boolean;
  isComplete: boolean;
  tasks: UI.UITask[];
  sprint: SprintFragment;
  isFirstSprint?: boolean;
  currentSprintId: Data.Id.SprintId | undefined;
};

const StartOrCompleteBtn: React.FC<StartOrCompleteBtnProps> = ({
  sprint,
  currentSprintId,
  isActive,
  tasks,
  isComplete,
  isFirstSprint,
}) => {
  const history = useHistory();
  const { orgID, teamID } = usePathParams('sprints');

  function completeSprint(event: React.SyntheticEvent): void {
    event.preventDefault();
    history.push(
      linkTo('sprintComplete', {
        orgID,
        teamID,
        sprintID: sprint.id,
      }),
    );
  }

  function startSprint(event: React.MouseEvent): void {
    event.preventDefault();
    if (canStartSprint) {
      history.push(
        linkTo('sprintStart', {
          orgID,
          teamID,
          sprintID: sprint.id,
        }),
      );
    }
  }
  const completeSprintButton = sprint.initialEndDate && sprint.initialEndDate.toDate() <= new Date(Date.now());
  const canStartSprint = !currentSprintId && !isActive && sprint.initialStartDate && tasks.length > 0;

  return isActive ? (
    <Button
      className={css`
        @keyframes pulse {
          0% {
            transform: scale(0.95);
          }
          70% {
            transform: scale(1);
            box-shadow: 0 0 0 5px rgba(71, 167, 0, 0.2);
          }
          100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(71, 167, 0, 0.2);
          }
        }
        padding: 0rem 0.6rem;
        background-color: ${completeSprintButton ? atomic.get(atomic.colors.success) : atomic.get(atomic.colors.grey2)};
        margin: 0rem;
        max-height: 1.6875rem;
        min-height: 1.6875rem;
        min-width: fit-content;
        outline: 0;
        font-size: 0.875rem;
        font-weight: 500;
        color: ${completeSprintButton ? '#ffffff' : '#67728b'};
        -webkit-animation: ${completeSprintButton ? 'pulse 1.5s infinite' : 'none'};
        transition: all 0.3s;
        box-shadow: 0 0 0 0 rgba(#47a700, 0.5);
        :hover {
          background-color: ${completeSprintButton
            ? atomic.get(atomic.colors.success)
            : atomic.get(atomic.colors.grey2)};
          -webkit-animation: none;
        }
      `}
      data-cy={sprintsTabTestIDs.COMPLETE_SPRINT}
      onClick={completeSprint}
    >
      {strings.sprints.sprintColumn.complete}
    </Button>
  ) : !isComplete && !canStartSprint ? (
    <Tooltip
      arrow
      enterDelay={0}
      interactive
      placement='bottom'
      title={
        <div
          className={css`
            font-size: 0.875rem;
            width: 9.375rem;
            line-height: 1.57;
          `}
        >
          {!currentSprintId
            ? tasks.length < 1 && strings.sprints.sprintColumn.zeroTasksDisabledTooltip
            : strings.sprints.sprintColumn.disabledTooltip}
        </div>
      }
      TransitionProps={{ timeout: 600 }}
    >
      <div
        className={css`
          border-radius: 0.1875rem;
          background-color: ${canStartSprint ? atomic.get(atomic.colors.success) : atomic.get(atomic.colors.grey2)};
          padding: 0.3125rem;
          text-align: center;
          display: ${isComplete ? 'none' : 'block'};
          cursor: ${canStartSprint ? 'pointer' : 'not-allowed'};
        `}
      >
        <a
          className={css`
            color: ${canStartSprint ? '#ffffff' : '#c8d0df'};
            align-self: center;
            text-decoration: none;
            margin: 0rem;
            max-height: 2rem;
            min-height: 2rem;
            min-width: 0rem;
            outline: 0;
            font-size: 0.875rem;
            font-weight: 500;
            cursor: ${canStartSprint ? 'pointer' : 'not-allowed'};
          `}
          data-cy={sprintsTabTestIDs.START_SPRINT_BUTTON}
          href={linkTo('sprintStart', {
            orgID,
            teamID,
            sprintID: sprint.id,
          })}
          onClick={startSprint}
        >
          {strings.sprints.sprintColumn.startSprint}
        </a>
      </div>
    </Tooltip>
  ) : (
    <div
      className={css`
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
      `}
    >
      <div
        className={css`
          @keyframes pulse {
            0% {
              transform: scale(0.95);
            }
            70% {
              transform: scale(1);
              box-shadow: 0 0 0 5px rgba(71, 167, 0, 0.2);
            }
            100% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 rgba(71, 167, 0, 0.2);
            }
          }
          border-radius: 0.1875rem;
          background-color: ${canStartSprint ? atomic.get(atomic.colors.success) : atomic.get(atomic.colors.grey2)};
          padding: 0.3125rem;
          text-align: center;
          display: ${isComplete ? 'none' : 'block'};
          cursor: ${canStartSprint ? 'pointer' : 'not-allowed'};

          -webkit-animation: ${isFirstSprint ? 'pulse 1.5s infinite' : 'none'};
          transition: ${isFirstSprint && 'all 0.3s'};
          box-shadow: ${isFirstSprint && '0 0 0 0 rgba(#47a700, 0.5)'};

          :hover {
            -webkit-animation: none;
          }
        `}
      >
        <a
          className={css`
            color: ${canStartSprint ? '#ffffff' : '#c8d0df'};
            align-self: center;
            text-decoration: none;
            margin: 0rem;
            max-height: 2rem;
            min-height: 2rem;
            min-width: 0rem;
            outline: 0;
            font-size: 0.875rem;
            font-weight: 500;
            cursor: ${canStartSprint ? 'pointer' : 'not-allowed'};
          `}
          data-cy={sprintsTabTestIDs.START_SPRINT_BUTTON}
          href={linkTo('sprintStart', {
            orgID,
            teamID,
            sprintID: sprint.id,
          })}
          onClick={startSprint}
        >
          {strings.sprints.sprintColumn.startSprint}
        </a>
      </div>
    </div>
  );
};
