import { UI } from '@taraai/types';
import TaskRow from 'components/app/controllers/views/TaskRow';
import React from 'react';
import { taskModalTestIDs } from 'resources/cypress/testAttributesValues';

type TaskFragment = Omit<
  UI.UITask,
  'updatedBy' | 'deadline' | 'archived' | 'lastUpdatedVia' | 'deleted' | 'tags' | 'author' | 'statusHistory'
>;

export interface TaskRowsProps extends React.HTMLProps<HTMLDivElement> {
  task: TaskFragment;
}

/**
 * TaskRows
 * render each feature row in task modal
 *
 */
export default function TaskRows({ task }: TaskRowsProps): JSX.Element {
  return (
    <div>
      <TaskRow subtasks task={task} />
      <TaskRow data-cy={taskModalTestIDs.TASK_MODAL_ROW.TASK_MODAL_STATUS} status task={task} />
      <TaskRow data-cy={taskModalTestIDs.TASK_MODAL_ROW.EFFORT_INPUT} effort task={task} />
      <TaskRow assignee data-cy={taskModalTestIDs.TASK_MODAL_ROW.ASSIGNEE_DROPDOWN} task={task} />
      <TaskRow collaborators data-cy={taskModalTestIDs.TASK_MODAL_ROW.COLLABORATOR_DROPDOWN} task={task} />
      <TaskRow data-cy={taskModalTestIDs.TASK_MODAL_ROW.SPRINT_DROPDOWN} sprint task={task} />
      <TaskRow data-cy={taskModalTestIDs.TASK_MODAL_ROW.REQUIREMENT_DROPDOWN} requirement task={task} />
    </div>
  );
}
