import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export const leaveOrganization = createAsyncThunk('LeaveOrganization', async (_actionPayload: undefined, { extra }) => {
  const { getFirebase, getOrgID } = extra as ExtraAPI;
  const organizationId = getOrgID();

  const payload: Functions.LeaveOrganization.Payload = {
    organizationId,
  };

  const response = await getFirebase().functions().httpsCallable('leaveOrganization')(payload);

  const result = decode<Functions.LeaveOrganization.Response>(response?.data, 'LeaveOrganizationResponse');

  if (result.status === 'error') {
    throw new Error(result.errors[0]?.error);
  }
});
