import { Data } from '@taraai/types';
import { DeleteTeamModal } from 'components/app/GlobalNav/TeamSwitcher/DeleteTeamModal';
import { getRouteName } from 'components/Router/paths';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';

export default function DeleteTeam(): JSX.Element {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();
  const routeName = getRouteName();
  const isOnNestedDeleteRoute = routeName === 'deleteTeam';

  const history = useHistory();

  const onClose = useCallback(() => {
    isOnNestedDeleteRoute ? history.go(-2) : history.goBack();
  }, [history, isOnNestedDeleteRoute]);

  return <DeleteTeamModal onClose={onClose} orgID={orgID} teamID={teamID} />;
}
