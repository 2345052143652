import { DnDStatusProvider } from 'components/app/controllers/views/DraggableFeatureCard/DragAndDropContext';
import SprintsLayout from 'components/app/layouts/SprintsLayout';
import React from 'react';
import { markPage, Marks } from 'tools/utils/perfmarks';

/**
 * Old legacy Sprints page component. Tara 3.0 replaces this with new design.
 *
 * @deprecated
 */
export default function LegacySprintsPage(): JSX.Element {
  markPage(Marks.PageSprint);
  return (
    <DnDStatusProvider>
      <SprintsLayout />
    </DnDStatusProvider>
  );
}
