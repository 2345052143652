import Icon from 'components/core/controllers/views/Icon';
import Input from 'components/core/controllers/views/Input';
import { css, cx } from 'emotion';
import React from 'react';
import { dropDownTestIDs } from 'resources/cypress/testAttributesValues';

export interface DropdownHeaderProps extends React.HTMLProps<HTMLDivElement> {
  inputRef: React.RefObject<HTMLInputElement>;
  onSearchChange: (event: React.SyntheticEvent) => void;
  handleInputOnKeyDown: (event: KeyboardEvent) => void;
  typeaheadInput: string;
  headerTitle: string | string[];
  headerPlaceholder: string;
  closeEvent: (event: MouseEvent | React.BaseSyntheticEvent) => void;
}

/**
 * DropdownHeader
 * optional header for dropdown component
 *
 */
export default function DropdownHeader({
  className,
  inputRef,
  onSearchChange,
  handleInputOnKeyDown,
  typeaheadInput,
  headerTitle,
  headerPlaceholder,
  closeEvent,
  ...props
}: DropdownHeaderProps): JSX.Element {
  return (
    <div
      className={css`
        padding: 1rem;
        box-shadow: 0 0.0625rem 0 0 #dee3ec;
        :hover {
          cursor: initial;
        }
        :focus {
          outline: none;
        }
      `}
      onClick={(event): void => event.stopPropagation()}
      onKeyDown={(event): void => event.stopPropagation()}
      role='button'
      tabIndex={0}
      {...props}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.6875rem;
        `}
      >
        <span
          className={css`
            font-size: 0.875rem;
            color: #303f4b;
            font-weight: normal;
          `}
        >
          {headerTitle}
        </span>
        <Icon
          className={css`
            padding: 0rem;
            width: 0.5625rem;
            height: 0.5625rem;
          `}
          data-cy={dropDownTestIDs.CLOSE_POPUP_BUTTON}
          name='exit'
          onClick={(event): void => {
            event.preventDefault();
            closeEvent(event);
          }}
        />
      </div>
      <Input
        Ref={inputRef}
        className={cx(
          css`
            border: solid 0.0625rem #dee3ec;
          `,
          className,
        )}
        data-cy={dropDownTestIDs.TEXT_INPUT}
        onChange={onSearchChange}
        onKeyDown={handleInputOnKeyDown}
        placeholder={headerPlaceholder}
        style={{
          main: css`
            padding-left: 0.5rem;
            margin-top: auto;
            margin-bottom: auto;
          `,
        }}
        type='text'
        value={typeaheadInput}
      />
    </div>
  );
}
