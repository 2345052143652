import { Data, UI } from '@taraai/types';
import { noop } from '@taraai/utility';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css, cx } from 'emotion';
import React, { ReactNode } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { atomic } from 'resources';

type Props = {
  organizationID: Data.Id.OrganizationId;
  nextSprintID: Data.Id.SprintId | null;
  previousSprintID: Data.Id.SprintId | null;
  selectedSprint?: UI.UISprint;
  children: ReactNode;
};

export const SprintDetailsNavigation: React.FC<Props> = ({
  organizationID,
  nextSprintID,
  previousSprintID,
  selectedSprint,
  children,
}: Props) => {
  const { teamID } = useParams<{ teamID: Data.Id.TeamId }>();

  const history = useHistory();

  const hideButtonClass = css`
    cursor: default;
    opacity: 0;
  `;

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        align-items: baseline;
      `}
    >
      <Button
        className={cx(
          css`
            margin-right: 1rem;
          `,
          // used to hide button, but preserve space
          !previousSprintID && hideButtonClass,
        )}
        color='wireframe'
        disabled={!previousSprintID}
        name='left'
        onClick={
          previousSprintID
            ? (): void =>
                history.push(
                  linkTo('sprintDetails', {
                    orgID: organizationID,
                    teamID,
                    sprintID: previousSprintID,
                  }),
                )
            : noop
        }
        size='compact'
        type='button'
      >
        <Icon
          className={css`
            width: 12px;
            height: 12px;
            padding: 0;
            color: #67728b;
          `}
          name='left'
        />
      </Button>
      <Text
        className={css`
          opacity: 0.7;
          font-weight: 500;
          ${atomic.color(atomic.colors.dark)};
        `}
        h3
      >
        {children}
      </Text>
      <Button
        className={cx(
          css`
            margin-left: 1rem;
          `,
          // used to hide button, but preserve space
          !nextSprintID && !selectedSprint?.isComplete && hideButtonClass,
        )}
        color='wireframe'
        disabled={!nextSprintID}
        name='right'
        onClick={
          nextSprintID
            ? (): void =>
                history.push(
                  linkTo('sprintDetails', {
                    orgID: organizationID,
                    teamID,
                    sprintID: nextSprintID,
                  }),
                )
            : (): void =>
                history.push(
                  linkTo('sprintDetails', {
                    orgID: organizationID,
                    teamID,
                    sprintID: 'current',
                  }),
                )
        }
        size='compact'
        type='button'
      >
        <Icon
          className={css`
            width: 12px;
            height: 12px;
            padding: 0;
            color: #67728b;
          `}
          name='right'
        />
      </Button>
    </div>
  );
};
