import { addPrefix } from '@taraai/utility';
import camelcase from 'camelcase';
import { css } from 'emotion';
import { isNonEmptyArray } from 'tools/libraries/helpers';
import MultiString from 'tools/libraries/helpers/MultiString';

import colors from './atomic/colors';
import fonts from './atomic/fonts';
import theme, { Theme } from './atomic/theme';

export interface ObjectArr {
  [key: number]: number[];
}

export interface NestedStyle {
  [key: string]: string | number | NestedStyle | NestedStyle[] | ObjectArr;
}

interface LeftRight {
  left: number;
  right: number;
}
type Spaces = string | boolean | LeftRight;

export interface Atomic {
  [key: string]: unknown;
  avatar: NestedStyle;
  color: (color: string | number | MultiString) => string;
  colors: typeof colors;

  animations: { [key: string]: string };
  fonts: typeof fonts;
  get: (key: string | number | MultiString | NestedStyle, fallback?: string) => string;
  getStyle: (style: string) => string;
  getValue: (key: string, element?: Element | null) => string;
  important: (important: boolean) => string;
  input: NestedStyle;

  style: { [key: string]: () => string };
  button: NestedStyle;
  responsive: {
    query: (spaces: { [key: string]: Spaces }, def?: boolean) => string;
    spaces: { [key: string]: Spaces };
    breakpoints: Record<string, number>[];
    columns: number;
    defaultSmartColumns: Record<string, unknown>;
    mapSpaceNames: (cb: (space: string, index: number, array: string[]) => string) => string;
    buildQuery: (params?: { sizes: number[][] }) => string;
  };

  theme: Theme;
  set: (key: string, value: string, element?: Element | null) => string;
  spaces: NestedStyle;

  width: (
    width?: string,
    config?: {
      min?: string | number;
      max?: string | number;
      important?: boolean;
    },
  ) => string;

  height: (
    width?: string,
    config?: {
      min?: string | number;
      max?: string | number;
      important?: boolean;
    },
  ) => string;

  transition: (options?: {
    delay?: string;
    duration?: string | string[];
    property?: string | string[];
    timing?: string | string[];
    important?: boolean;
  }) => string;

  padding: (
    space: null | string | number,
    config: {
      zIndex?: string | number;
      vertical?: string | null;
      horizontal?: string | null;
      top?: string | number | null;
      bottom?: string | number | null;
      left?: string | number | null;
      right?: string | number | null;
      important?: boolean;
    },
  ) => string;

  backgroundColor: (key?: string | MultiString, config?: { important?: boolean; fallback?: string }) => string;

  border: (config: {
    color?: string;
    verticalColor?: string;
    horizontalColor?: string;
    topColor?: string;
    bottomColor?: string;
    leftColor?: string;
    rightColor?: string;
    width?: string;
    verticalWidth?: string;
    horizontalWidth?: string;
    topWidth?: string;
    bottomWidth?: string;
    leftWidth?: string;
    rightWidth?: string;
    style?: string;
    verticalStyle?: string;
    horizontalStyle?: string;
    topStyle?: string;
    bottomStyle?: string;
    leftStyle?: string;
    rightStyle?: string;
    radius?: string;
    topRadius?: string;
    rightRadius?: string;
    bottomRadius?: string;
    leftRadius?: string;
    topLeftRadius?: string;
    topRightRadius?: string;
    bottomLeftRadius?: string;
    bottomRightRadius?: string;
    clip?: boolean;
    important?: boolean;
  }) => string;

  position: (
    position?: string,
    all?: string,
    config?:
      | {
          zIndex?: string | number;
          vertical?: string;
          horizontal?: string;
          top?: string;
          bottom?: string;
          left?: string;
          right?: string;
          important?: boolean;
        }
      | undefined,
  ) => string;

  margin: (
    space: string,
    config: {
      vertical?: string;
      horizontal?: string;
      top?: string;
      bottom?: string;
      left?: string;
      right?: string;
      important?: boolean;
    },
  ) => string;

  hidden: (config?: { opacity?: number; block?: boolean; important?: boolean; clickable?: boolean }) => string;

  dims: (
    width?: string,
    height?: string,
    config?: {
      minWidth?: string;
      maxWidth?: string;
      minHeight?: string;
      maxHeight?: string;
      important?: boolean;
    },
  ) => string;

  fontSize: (fontSize?: string) => string;

  fontWeight: (fontWeight?: string) => string;

  textDecoration: (textDecoration?: string, ...remaining: string[]) => string;

  gridItem: (
    value?: number,
    config?: {
      rows?: number;
      space?: number;
      spaces?: {
        [key: string]: string | boolean;
      };
      columns?: {
        [key: string]: string | boolean | { left: number; right: number };
      };
      width?: true | string;
      end?: string | number;
      start?: string;
      height?: boolean;
      leftSpace?: number;
      rightSpace?: number;
      rowEnd?: string | number;
      rowStart?: string | number;
    },
  ) => string;

  gridContainer: (
    cols: number,
    config: {
      space?: number;
      spaces?: {
        [key: string]: Spaces;
      };
      columns?: { [key: string]: string | boolean };
      gap?: string;
      rowGap?: string;
      height?: boolean;
      leftSpace?: number;
      rightSpace?: number;
      fillHeight?: boolean;
      minColumnWidth?: number;
    },
  ) => string;
}

export const atomic: Atomic = {
  getValue(key: string, element = document.querySelector(':root')): string {
    return String(window.getComputedStyle(element as Element).getPropertyValue(addPrefix(key, '--'))).trim();
  },
  get(
    key: string | number | MultiString | NestedStyle,
    fallback: string | number | MultiString | NestedStyle = key,
  ): string {
    return String(key).startsWith('--atomic-')
      ? `var(${addPrefix(String(key), '--')}${fallback === null ? '' : `, ${fallback}`})`
      : String(fallback);
  },
  set(key: string, value: string, element = document.querySelector(':root')): string {
    (element as HTMLElement).style.setProperty(addPrefix(key, '--'), value);
    return this.getValue(key, element);
  },
  getStyle(style: string): string {
    return css`
      ${style.trim()}
    `;
  },
  colors,
  fonts,
  input: {
    padding: '--atomic-input-padding',
    icon: {
      padding: '--atomic-input-icon-padding',
    },
    border: {
      width: '--atomic-input-border-width',
      radius: '--atomic-input-border-radius',
    },
  },
  spaces: {
    general: {
      p100: '--atomic-spaces-p100',
    },
    borders: {
      none: '--atomic-spaces-borders-none',
      thin: '--atomic-spaces-borders-thin',
      regular: '--atomic-spaces-borders-regular',
    },
    box: {
      height: {
        regular: '--atomic-spaces-box-height-regular',
      },
    },
    circle: {
      size: {
        tiny: '--atomic-spaces-circle-size-tiny',
        small: '--atomic-spaces-circle-size-small',
        medium: '--atomic-spaces-circle-size-medium',
        large: '--atomic-spaces-circle-size-large',
      },
      padding: {
        tiny: '--atomic-spaces-circle-padding-tiny',
        small: '--atomic-spaces-circle-padding-small',
        medium: '--atomic-spaces-circle-padding-medium',
        large: '--atomic-spaces-circle-padding-large',
      },
    },
  },
  avatar: {
    text: {
      tiny: '--atomic-avatar-text-tiny',
      small: '--atomic-avatar-text-small',
      medium: '--atomic-avatar-text-medium',
      large: '--atomic-avatar-text-large',
    },
  },
  animations: {
    spin: '--atomic-animations-spin',
  },
  button: {
    font: {
      size: {
        tiny: '--atomic-theme-font-button-size-tiny',
        small: '--atomic-theme-font-button-size-small',
        medium: '--atomic-theme-font-button-size-medium',
        large: '--atomic-theme-font-button-size-large',
      },
    },
  },
  theme,
  responsive: {
    columns: 12,
    breakpoints: [
      {
        nano: 0,
        tiny: 250,
        small: 600,
        medium: 900,
        large: 1200,
        huge: 1500,
        massive: 1800,
        gigantic: 2100,
      },
    ],
    defaultSmartColumns: {
      0: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      1: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      2: [2, 2, 2, 2, 2, 2, 2, 2, 2],
      3: [12, 12, 12, 6, 5, 3, 3, 2, 2],
      4: [4, 4, 4, 4, 4, 4, 4, 4, 4],
      5: [5, 5, 5, 5, 5, 5, 5, 5, 5],
      6: [6, 6, 6, 6, 6, 6, 6, 6, 6],
      7: [7, 7, 7, 7, 7, 7, 7, 7, 7],
      8: [8, 8, 8, 8, 8, 8, 8, 8, 8],
      9: [9, 9, 9, 9, 9, 9, 9, 9, 9],
      10: [10, 10, 10, 10, 10, 10, 10, 10, 10],
      11: [11, 11, 11, 11, 11, 11, 11, 11, 11],
      12: [12, 12, 12, 12, 12, 12, 12, 12, 12],
    },
    get spaces(): { [key: string]: string } {
      const acc: { [key: string]: string } = {};
      (this.breakpoints as { [key: string]: number }[]).forEach((names) => {
        const upKeyword = 'Up';
        const downKeyword = 'Down';
        const arr = Object.entries(names);
        arr.forEach((value, index) => {
          if (index === 0) {
            acc[`${arr[index][0]}${upKeyword}`] = `(min-width: ${arr[index][1]}px)`;
            acc[`${arr[index][0]}${downKeyword}`] = `(max-width: ${arr[index][1] - 1}px)`;
          } else if (index === arr.length) {
            acc[`${arr[index - 1][0]}${upKeyword}`] = `(min-width: ${arr[index - 1][1]}px)`;
            acc[`${arr[index - 1][0]}${downKeyword}`] = `(max-width: ${arr[index - 1][1] - 1}px)`;
          } else {
            acc[camelcase(`${arr[index - 1][0]}_${arr[index][0]}`)] = `(min-width: ${
              arr[index - 1][1]
            }px) and (max-width: ${arr[index][1] - 1}px)`;
            acc[`${arr[index][0]}${upKeyword}`] = `(min-width: ${arr[index][1]}px)`;
            acc[`${arr[index][0]}${downKeyword}`] = `(max-width: ${arr[index][1] - 1}px)`;
          }
        });
      });
      return acc;
    },
    mapSpaceNames(cb: (space: string, index: number, array: string[]) => string): string {
      return Object.keys(atomic.responsive.spaces)
        .map((space, ...rest) => ({
          name: space,
          data: cb(space, ...rest),
        }))
        .filter((space) => space.data !== null && space.data !== undefined && space.data.trim().length !== 0)
        .map(
          (space) => `${atomic.responsive.query({ [space.name]: true })} {
            ${space.data.trim()}
          }`,
        )
        .join('\n');
    },
    query(spaces: { [key: string]: Spaces } = {}, def = false): string {
      return `@media all and ${Object.entries(atomic.responsive.spaces)
        .reduce((acc, space) => {
          const ret1 = def === true ? acc : acc.concat([space[1] as never]);
          const ret2 = def === true ? acc.concat([space[1] as never]) : acc;
          return spaces[space[0]] === !def ? ret1 : ret2;
        }, [])
        .join(', ')}`;
    },
    buildQuery(
      { sizes = [[0, Infinity]] }: { sizes: number[][] } = {
        sizes: [[0, Infinity]],
      },
    ): string {
      // TODO: Handle flat sizes array
      const checkInSizes = ((size) => (sizeA: number, sizeB: number): boolean =>
        size.some((value: number[]) => sizeA >= value[0] && sizeB <= value[1]))(sizes);
      const arr: [string, number][] = Object.entries(atomic.responsive.breakpoints[0]);
      const acc: { [key: string]: boolean } = {};
      arr.forEach((value, index) => {
        if (index === 0) acc[`${arr[index][0]}`] = checkInSizes(0, arr[index][1]);
        else if (index === arr.length) acc[`${arr[index - 1][0]}`] = checkInSizes(arr[index - 1][1], Infinity);
        else acc[camelcase(`${arr[index - 1][0]}_${arr[index][0]}`)] = checkInSizes(arr[index - 1][1], arr[index][1]);
      });
      return atomic.responsive.query(acc);
    },
  },
  important(important = false): string {
    return important === true ? ' !important' : '';
  },
  hidden({
    opacity = 0,
    block = false,
    important = false,
    clickable = false,
  }: {
    opacity?: number;
    block?: boolean;
    important?: boolean;
    clickable?: boolean;
  } = {}): string {
    return `
    ${
      block === true
        ? `${clickable === true ? `opacity: ${atomic.get(opacity)}` : 'visibility: hidden'}`
        : 'display: none'
    }${atomic.important(important)};
  `;
  },
  position(
    position = 'unset',
    all = 'unset',
    {
      zIndex = 'unset',
      vertical = all,
      horizontal = all,
      top = vertical,
      bottom = vertical,
      left = horizontal,
      right = horizontal,
      important = false,
    }: {
      zIndex?: string | number;
      vertical?: string;
      horizontal?: string;
      top?: string;
      bottom?: string;
      left?: string;
      right?: string;
      important?: boolean;
    } = {},
  ): string {
    return `
    position: ${atomic.get(position)}${atomic.important(important)};
    top: ${atomic.get(top)}${atomic.important(important)};
    right: ${atomic.get(right)}${atomic.important(important)};
    bottom: ${atomic.get(bottom)}${atomic.important(important)};
    left: ${atomic.get(left)}${atomic.important(important)};
    z-index: ${atomic.get(zIndex)}${atomic.important(important)};
  `;
  },
  width(
    width = '100%',
    {
      min = 'unset',
      max = 'unset',
      important = false,
    }: {
      min?: string | number;
      max?: string | number;
      important?: boolean;
    } = {},
  ): string {
    return `
    width: ${atomic.get(width)}${atomic.important(important)};
    ${min === 'unset' ? '' : `min-width: ${atomic.get(min)}${atomic.important(important)};`}
    ${max === 'unset' ? '' : `max-width: ${atomic.get(max)}${atomic.important(important)};`}
  `;
  },
  height(
    height = '100%',
    {
      min = 'unset',
      max = 'unset',
      important = false,
    }: {
      min?: string | number;
      max?: string | number;
      important?: boolean;
    } = {},
  ): string {
    return `
    height: ${atomic.get(height)}${atomic.important(important)};
    ${min === 'unset' ? '' : `min-height: ${atomic.get(min)}${atomic.important(important)};`}
    ${max === 'unset' ? '' : `max-height: ${atomic.get(max)}${atomic.important(important)};`}
  `;
  },
  dims(
    width = '100%',
    height = '100%',
    {
      minWidth = 'unset',
      maxWidth = 'unset',
      minHeight = 'unset',
      maxHeight = 'unset',
      important = false,
    }: {
      minWidth?: string;
      maxWidth?: string;
      minHeight?: string;
      maxHeight?: string;
      important?: boolean;
    } = {},
  ): string {
    return `
    ${atomic.width(width, { important, min: minWidth, max: maxWidth })}
    ${atomic.height(height, { important, min: minHeight, max: maxHeight })}
  `;
  },
  padding(
    space = null,
    {
      vertical = space,
      horizontal = space,
      top = vertical,
      bottom = vertical,
      left = horizontal,
      right = horizontal,
      important = false,
    } = {},
  ): string {
    return space === null
      ? ''
      : `padding-top: ${atomic.get(top as string)}${atomic.important(important)};
         padding-right: ${atomic.get(right as string)}${atomic.important(important)};
         padding-bottom: ${atomic.get(bottom as string)}${atomic.important(important)};
         padding-left: ${atomic.get(left as string)}${atomic.important(important)};`;
  },
  margin(
    space = 'unset',
    {
      vertical = space,
      horizontal = space,
      top = vertical,
      bottom = vertical,
      left = horizontal,
      right = horizontal,
      important = false,
    }: {
      vertical?: string;
      horizontal?: string;
      top?: string;
      bottom?: string;
      left?: string;
      right?: string;
      important?: boolean;
    } = {},
  ): string {
    return space === null
      ? ''
      : `margin: ${atomic.get(top)} ${atomic.get(right)} ${atomic.get(bottom)} ${atomic.get(left)}${atomic.important(
          important,
        )};`;
  },
  border({
    color = atomic.colors.transparent as string,
    verticalColor = color,
    horizontalColor = color,
    topColor = verticalColor,
    bottomColor = verticalColor,
    leftColor = horizontalColor,
    rightColor = horizontalColor,
    width = '0rem',
    verticalWidth = width,
    horizontalWidth = width,
    topWidth = verticalWidth,
    bottomWidth = verticalWidth,
    leftWidth = horizontalWidth,
    rightWidth = horizontalWidth,
    style = 'solid',
    verticalStyle = style,
    horizontalStyle = style,
    topStyle = verticalStyle,
    bottomStyle = verticalStyle,
    leftStyle = horizontalStyle,
    rightStyle = horizontalStyle,
    radius = 'unset',
    topRadius = radius,
    rightRadius = radius,
    bottomRadius = radius,
    leftRadius = radius,
    topLeftRadius = leftRadius || topRadius,
    topRightRadius = rightRadius || topRadius,
    bottomLeftRadius = leftRadius || bottomRadius,
    bottomRightRadius = rightRadius || bottomRadius,
    clip = false,
    important = false,
  }) {
    return `
    ${
      radius !== 'unset'
        ? `
          border-top-left-radius: ${atomic.get(topLeftRadius)}${atomic.important(important)};
          border-top-right-radius: ${atomic.get(topRightRadius)}${atomic.important(important)};
          border-bottom-left-radius: ${atomic.get(bottomLeftRadius)}${atomic.important(important)};
          border-bottom-right-radius: ${atomic.get(bottomRightRadius)}${atomic.important(important)};
          ${clip === false ? '' : `overflow: ${atomic.get(clip === true ? 'hidden' : clip)}`};
          `
        : ''
    }
    border-top: ${atomic.get(topWidth)} ${atomic.get(topStyle)} ${atomic.get(topColor)}${atomic.important(important)};
    border-right: ${atomic.get(rightWidth)} ${atomic.get(rightStyle)} ${atomic.get(rightColor)}${atomic.important(
      important,
    )};
    border-bottom: ${atomic.get(bottomWidth)} ${atomic.get(bottomStyle)} ${atomic.get(bottomColor)}${atomic.important(
      important,
    )};
    border-left: ${atomic.get(leftWidth)} ${atomic.get(leftStyle)} ${atomic.get(leftColor)}${atomic.important(
      important,
    )};
  `;
  },
  transition({
    delay = '0s',
    duration = '0.5s',
    property = 'all',
    timing = 'ease',
    important = false,
  }: {
    delay?: string;
    duration?: string | string[];
    property?: string | string[];
    timing?: string | string[];
    important?: boolean;
  } = {}): string {
    return `
  ${
    property !== null
      ? `transition-property: ${(isNonEmptyArray(property) ? (property as string[]) : [property]).map((value) =>
          atomic.get(value as string),
        )}${atomic.important(important)};`
      : ''
  }
  ${
    duration !== null
      ? `transition-duration: ${(isNonEmptyArray(duration) ? (duration as string[]) : [duration]).map((value) =>
          atomic.get(value as string),
        )}${atomic.important(important)};`
      : ''
  }
  ${
    timing !== null
      ? `transition-timing-function: ${(isNonEmptyArray(timing) ? (timing as string[]) : [timing]).map((value) =>
          atomic.get(value as string),
        )}${atomic.important(important)};`
      : ''
  }
  ${
    delay !== null
      ? `transition-delay: ${(isNonEmptyArray(delay) ? ((delay as unknown) as string[]) : [delay]).map((value) =>
          atomic.get(value),
        )}${atomic.important(important)};`
      : ''
  }
  `;
  },
  color(key: string | number | MultiString = 'inherit', { important = false, fallback = undefined } = {}): string {
    return `color: ${atomic.get(key, fallback)}${atomic.important(important)};`;
  },
  backgroundColor(
    key = atomic.colors.transparent as string,
    { important = false, fallback = undefined }: { important?: boolean; fallback?: string } = {},
  ): string {
    return `background-color: ${atomic.get(key, fallback)}${atomic.important(important)};`;
  },
  fontSize(fontSize = 'inherit'): string {
    return `
    font-size: ${atomic.get(fontSize)};
  `;
  },
  fontWeight(fontWeight = 'inherit'): string {
    return `font-weight: ${atomic.get(fontWeight)};`;
  },
  textDecoration(textDecoration = 'inherit', ...remaining: string[]): string {
    return `
    text-decoration: ${atomic.get(textDecoration)} ${remaining.map((rule) => atomic.get(rule)).join(' ')};
  `;
  }, // eslint-disable-next-line sonarjs/cognitive-complexity
  gridItem(
    value = 1,
    {
      rows = 1,
      space = 0,
      spaces = {},
      columns = {},
      width = true,
      end = 'unset',
      start = 'auto',
      height = false,
      leftSpace = space,
      rightSpace = space,
      rowEnd: rEnd = 'unset',
      rowStart: rStart = 'auto',
    }: {
      rows?: number;
      space?: number;
      spaces?: {
        [key: string]: Spaces;
      };
      columns?: {
        [key: string]: string | boolean | { left: number; right: number };
      };
      width?: true | string;
      end?: string | number;
      start?: string;
      height?: boolean;
      leftSpace?: number;
      rightSpace?: number;
      rowEnd?: string | number;
      rowStart?: string | number;
    } = {},
  ): string {
    return `
    ${atomic.width(width === true ? '100%' : width, { min: 0 })}
    ${height !== false ? atomic.height(height === true ? '100%' : height, { min: 0 }) : ''}
    grid-row: ${rEnd === 'unset' ? `${rStart} / span ${rows}` : `span ${rows} / ${rEnd}`};
    ${
      value === null
        ? `grid-column: ${start + leftSpace} / ${(end as number) - rightSpace};`
        : `grid-column: ${
            end === 'unset'
              ? `${start} / span ${value + leftSpace + rightSpace}`
              : `span ${value + leftSpace + rightSpace} / ${end}`
          };`
    }
    ${atomic.responsive.mapSpaceNames((name: string) => {
      if ((columns[name] === false ? value : columns[name]) === 0) return 'display: none;';
      if (columns[name] === undefined && spaces[name] === undefined) return '';
      const finalLeftSpace =
        spaces[name] === undefined || (spaces[name] as LeftRight).left === undefined
          ? leftSpace ?? 0
          : (spaces[name] as LeftRight).left ?? 0;
      const finalRightSpace =
        spaces[name] === undefined || (spaces[name] as LeftRight).right === undefined
          ? rightSpace || 0
          : (spaces[name] as LeftRight).right ?? 0;
      const totalSpace = finalLeftSpace + finalRightSpace;
      const totalCols = `calc(${columns[name] ?? value} + ${totalSpace})`;
      return value === null
        ? `grid-column: ${start + finalLeftSpace} / ${(end as number) - finalRightSpace};`
        : `
          grid-column-start: ${end === 'unset' ? start : `span ${totalCols}`};
          grid-column-end: ${end === 'unset' ? `span ${totalCols}` : end};
        `;
    })}
  `;
  }, // eslint-disable-next-line sonarjs/cognitive-complexity
  gridContainer(
    cols: number,
    {
      space = 0,
      spaces = {},
      columns = {},
      gap = '0.75rem',
      rowGap = gap,
      height = false,
      leftSpace = space,
      rightSpace = space,
      fillHeight = false,
      minColumnWidth = 0,
    }: {
      space?: number;
      spaces?: {
        [key: string]: Spaces;
      };
      columns?: { [key: string]: string | boolean };
      gap?: string;
      rowGap?: string;
      height?: boolean | string;
      leftSpace?: number;
      rightSpace?: number;
      fillHeight?: boolean;
      minColumnWidth?: number;
    } = {},
  ): string {
    return `
    ${atomic.width('100%')}
    ${
      fillHeight === true || height !== false
        ? atomic.height(fillHeight === true || height === true ? '100%' : (height as string))
        : ''
    }
    display: grid;
    grid-row-gap: ${gap};
    grid-column-gap: ${rowGap};
    ${fillHeight === true ? '' : 'grid-auto-rows: minmax(min-content, max-content);'}
    grid-template-columns: repeat(${cols + leftSpace + rightSpace}, minmax(${minColumnWidth}, 1fr));
    ${atomic.responsive.mapSpaceNames((name: string) => {
      if ((columns[name] === undefined || columns[name] === false ? cols : columns[name]) === 0)
        return 'display: none;';
      if (columns[name] === undefined && spaces[name] === undefined) return '';
      const finalLeftSpace =
        spaces[name] === undefined || (spaces[name] as { left: number; right: number }).left === undefined
          ? leftSpace || 0
          : (spaces[name] as { left: number; right: number }).left || 0;
      const finalRightSpace =
        spaces[name] === undefined || (spaces[name] as { left: number; right: number }).right === undefined
          ? rightSpace || 0
          : (spaces[name] as { left: number; right: number }).right || 0;
      const totalSpace = finalLeftSpace + finalRightSpace;
      const totalCols = `calc(${columns[name] || cols} + ${totalSpace})`;
      return `grid-template-columns: repeat(${totalCols}, minmax(${minColumnWidth}, 1fr));`;
    })}
  `;
  },
  get style(): { [key: string]: () => string } {
    const styleObj: { [key: string]: () => string } = {};
    Object.keys(atomic).forEach((key) => {
      styleObj[key] = (...args): string => atomic.getStyle((atomic[key] as () => string)(...args));
    });
    return styleObj;
  },
};
