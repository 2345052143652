/* eslint-disable sonarjs/cognitive-complexity */
import { isNonEmptyString } from '@taraai/utility';
import AuthLayout from 'components/app/layouts/AuthLayout';
import Button from 'components/core/controllers/views/Button';
import PasswordReset from 'components/pages/PasswordReset';
import { css } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { TaraLogoIconWhite } from 'resources/assets';
import { strings } from 'resources/i18n';
import { auth } from 'tools/libraries/firebaseValues';

export default function FirebaseEmailActionHandler() {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('oobCode');
  const mode = searchParams.get('mode');
  const passwordResetSuccess = 'password-reset-success';
  const sendEmailVerification = useCallback(() => {
    const user = auth().currentUser;
    if (user && user.sendEmailVerification) {
      user.sendEmailVerification();
    }
  }, []);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [status, setStatus] = useState('verifying');
  const [didSetPassword, setDidSetPassword] = useState(false);
  const [errorCode, setErrorCode] = useState();

  const shouldSetPassword = useCallback(() => {
    return !didSetPassword && isNonEmptyString(email) && isNonEmptyString(password);
  }, [didSetPassword, email, password]);

  useEffect(() => {
    if (shouldSetPassword()) {
      // user has valid token + email and has given us a new password
      auth()
        .confirmPasswordReset(code, password)
        .then(() => {
          setStatus(passwordResetSuccess);
          return setDidSetPassword(true);
        })
        .catch((error) => {
          setStatus('error');
          setErrorCode(error.code);
        });
    }
  }, [code, password, shouldSetPassword]);

  useEffect(() => {
    if (!shouldSetPassword() && status !== passwordResetSuccess) {
      if (mode === 'resetPassword') {
        auth()
          .verifyPasswordResetCode(code)
          .then((accountEmail) => {
            setEmail(accountEmail);
            return setStatus('reset');
          })
          .catch((error) => {
            setErrorCode(error.code);
            return setStatus('error');
          });
      } else if (status !== 'verified') {
        auth()
          .applyActionCode(code)
          .then(() => setStatus('verified'))
          .catch((error) => {
            setErrorCode(error.code);
            return setStatus('error');
          });
      }
    }
  }, [code, mode, shouldSetPassword, status]);

  const continueToLoginButton = (
    <a href='/login'>
      <Button
        className={css`
          height: 3.5rem;
          display: flex;
          margin: 0;
          padding: 1rem;
          font-size: 1.125rem;
          vertical-align: middle;
          font-weight: var(--atomic-font-weight-medium);
        `}
        name={strings.firebaseEmailActionHandlers.continueToLogin}
      >
        <img
          alt={strings.logo.tara}
          className={css`
            width: 1.375rem;
            height: 1.375rem;
            text-align: left;
            margin-right: 1.25rem;
            vertical-align: middle;
          `}
          src={TaraLogoIconWhite}
        />
        {strings.firebaseEmailActionHandlers.continueToLogin}
      </Button>
    </a>
  );
  switch (status) {
    case 'verifying':
      return <AuthLayout title={strings.firebaseEmailActionHandlers.verifying} />;
    case 'verified':
      return (
        <AuthLayout title={strings.firebaseEmailActionHandlers.emailVerified}>
          <h4
            className={css`
              font-weight: var(--tara-font-weight-regular);
              text-align: left;
              margin-bottom: 3.125rem;
            `}
          >
            {strings.firebaseEmailActionHandlers.youCanLogIn}
          </h4>
          {continueToLoginButton}
        </AuthLayout>
      );
    case 'reset':
      return (
        <PasswordReset
          code={code}
          email={email}
          newPasswordWasGiven={setPassword}
          passwordSuccessfullyChanged={didSetPassword}
        />
      );
    case 'password-reset-success':
      return (
        <AuthLayout title={strings.firebaseEmailActionHandlers.passwordReset}>
          <h4
            className={css`
              font-weight: var(--tara-font-weight-regular);
              text-align: left;
              margin-bottom: 3.125rem;
            `}
          >
            {strings.firebaseEmailActionHandlers.youCanLogIn}
          </h4>
          {continueToLoginButton}
        </AuthLayout>
      );
    default:
      switch (errorCode) {
        case 'auth/expired-action-code':
          sendEmailVerification();
          return (
            <AuthLayout title={strings.authLayout.error}>
              <h4>{strings.firebaseEmailActionHandlers.verificationExpired}</h4>
              {continueToLoginButton}
            </AuthLayout>
          );
        case 'auth/invalid-action-code':
          return (
            <AuthLayout title={strings.authLayout.error}>
              <h4
                className={css`
                  font-weight: var(--tara-font-weight-regular);
                  text-align: left;
                  margin-bottom: 3.125rem;
                `}
              >
                {strings.firebaseEmailActionHandlers.invalidCode}
              </h4>
              {continueToLoginButton}
            </AuthLayout>
          );
        case 'auth/user-disabled':
          return (
            <AuthLayout title={strings.authLayout.userDisabled}>
              <h4
                className={css`
                  font-weight: var(--tara-font-weight-regular);
                  text-align: left;
                  margin-bottom: 3.125rem;
                `}
              >
                {strings.firebaseEmailActionHandlers.contactSupport}
              </h4>
              {continueToLoginButton}
            </AuthLayout>
          );
        case 'auth/user-not-found':
          return (
            <AuthLayout title={strings.authLayout.userNotFound}>
              <h4
                className={css`
                  font-weight: var(--tara-font-weight-regular);
                  text-align: left;
                  margin-bottom: 3.125rem;
                `}
              >
                {strings.firebaseEmailActionHandlers.contactSupport}
              </h4>
              {continueToLoginButton}
            </AuthLayout>
          );
        default:
          return (
            <AuthLayout title={strings.authLayout.unknownError}>
              <h4
                className={css`
                  font-weight: var(--tara-font-weight-regular);
                  text-align: left;
                  margin-bottom: 3.125rem;
                `}
              >
                {strings.firebaseEmailActionHandlers.contactSupport}
              </h4>
              {continueToLoginButton}
            </AuthLayout>
          );
      }
  }
}
