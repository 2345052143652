import { Card } from 'components/core/controllers/views/Card';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

/**
 * GanttChartHeader does…
 *
 */
export default function GanttChartHeader(): JSX.Element {
  return (
    <Card
      className={css`
        padding: 1.5rem 0.75rem 0.75rem 0.75rem;
        border-bottom: 0.0625rem solid #eaeef5;
      `}
      withBorder={false}
    >
      <Text
        className={css`
          font-size: 1rem;
          font-weight: 500;
          color: #303f4b;
        `}
      >
        {strings.GanttChartHeader.title}
      </Text>
    </Card>
  );
}
