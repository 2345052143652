import styled from '@emotion/styled';
import React, { ComponentProps } from 'react';

export const Task = styled(({ className, placeholder, value }: ComponentProps<'input'>) => (
  <input className={className} placeholder={placeholder} readOnly value={value ?? ''} />
))`
  width: 100%;
  height: 2.5rem;
  border-radius: 0.1875rem;
  border: solid 0.0625rem #dee3ec;
  background-color: #ffffff;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.5rem;
  padding: 0 0.8rem;

  & + & {
    margin-top: 0.5rem;
  }

  ::placeholder {
    color: #c2c8cc;
  }
`;
