import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

interface CreateTaskComment {
  description: Data.Comment['description'];
  taskID: Data.Id.TaskId;
  mentionedUserIds: Data.Comment['mentionedUserIds'];
}

export const createTaskComment = createAsyncThunk(
  'CreateTaskComment',
  async ({ description, taskID, mentionedUserIds }: CreateTaskComment, { extra, getState }) => {
    const { getOrgID, getUserID, getFirestore } = extra as ExtraAPI;
    const state = getState() as RootState;
    const userID = getUserID(state);
    const orgID = getOrgID();

    const firestore = getFirestore();
    const comment = decode<Data.Comment>(
      {
        authorId: userID,
        createdAt: firestore.Timestamp.now(),
        deletedAt: null,
        description,
        mentionedUserIds,
      },
      'Comment',
      CREATION_DEFAULTS,
    );

    return firestore.add<Data.Comment>(`orgs/${orgID}/tasks/${taskID}/comments/`, comment).then((docRef) => {
      const { id } = docRef;
      return { ...comment, id } as UI.UIComment;
    });
  },
);
