/* eslint-disable no-nested-ternary */
import { Tooltip } from '@material-ui/core';
import Icon from 'components/core/controllers/views/Icon';
import { css, cx } from 'emotion';
import React, { useState } from 'react';
import { strings } from 'resources/i18n';

export interface GitSnippetProps extends React.HTMLProps<HTMLDivElement> {
  taskSlug: string;
  branch?: boolean;
  pullRequest?: boolean;
  dataCy?: string;
}

/**
 * GitSnippet
 * component for git snippets
 *
 */
export default function GitSnippet({
  className,
  taskSlug,
  branch,
  pullRequest,
  dataCy,
  ...props
}: GitSnippetProps): JSX.Element {
  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const branchText = strings.formatString(strings.gitTaskLifecycle.gitBranchTask, {
    taskSlug,
  });
  const commitText = strings.formatString(strings.gitTaskLifecycle.gitCommitTask, {
    taskSlug,
  });
  const prText = strings.formatString(strings.gitTaskLifecycle.prTaskTitle, {
    taskSlug,
  });

  const copyGit = (): void => {
    if (branch) {
      navigator.clipboard.writeText(branchText.toString());
    } else if (pullRequest) {
      navigator.clipboard.writeText(prText.toString());
    } else {
      navigator.clipboard.writeText(commitText.toString());
    }
    setTaskCopiedText(true);
  };
  return (
    <div
      className={cx(
        css`
          background-color: #fbfbfd;
          border: solid 0.0625rem #dee3ec;
          border-radius: 0.1875rem;
          padding: 0.5rem;
          margin-top: 0.75rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `,
        className,
      )}
    >
      <span
        className={css`
          color: #67728b;
          font-size: 0.75rem;
          font-weight: 500;
          font-family: SourceCodePro;
        `}
      >
        {branch ? branchText : pullRequest ? prText : commitText}
      </span>
      <div data-cy={dataCy} onMouseEnter={(): void => setTaskCopiedText(false)}>
        <Tooltip
          arrow
          data-cy={dataCy}
          enterDelay={0}
          interactive
          placement='top'
          title={
            <div
              className={css`
                color: #ffffff;
                font-size: 0.875rem;
                font-weight: normal;
                padding: 0.1875rem;
                line-height: 1.125rem;
                max-width: 15.625rem;
              `}
            >
              {taskCopiedText ? strings.gitTaskLifecycle.copied : strings.gitTaskLifecycle.copy}
            </div>
          }
          TransitionProps={{ timeout: 600 }}
        >
          <div data-cy={dataCy}>
            <Icon
              className={css`
                padding: 0rem;
                display: flex;
                cursor: pointer;
              `}
              dataCy={dataCy}
              name='copyGit'
              onClick={copyGit}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
