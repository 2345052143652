import { UI } from '@taraai/types';

export const tasksCompleted = (tasks: UI.UITask[]): UI.UITask[] =>
  tasks.filter((task: UI.UITask): boolean => task.status === 2);

export const getTotalEffortFromTasks = (tasks: UI.UITask[]): number =>
  tasks.reduce((effortTotal: number, task: UI.UITask) => effortTotal + Number(task.effortLevel) || 1, 0);

export const getCompletedEffortFromTasks = (tasks: UI.UITask[]): number =>
  tasksCompleted(tasks).reduce((tasksDone: number, task: UI.UITask) => tasksDone + Number(task.effortLevel) || 1, 0);

export const getCompletedPercentage = (completed: number, total: number): number =>
  Math.round((completed / total) * 100);
