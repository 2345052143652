import { Identifiable } from '@taraai/types';
import { SectionType } from 'components/core/controllers/Selector';
import Fuse from 'fuse.js';

export type FilterFn<Section extends SectionType<Option>, Option extends Identifiable> = (
  sections: Section[],
  pattern: string,
) => Section[];

const defaultOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
};

function getFilterFn<Section extends SectionType<Option>, Option extends Identifiable>(
  additionalOptions: Fuse.IFuseOptions<Option>,
) {
  return (sections: Section[], pattern: string): Section[] => {
    return sections.map((section) => {
      const fuse = new Fuse(section.options, {
        ...defaultOptions,
        ...additionalOptions,
      });
      const options = fuse.search(pattern).map(({ item }) => item);
      return { ...section, options };
    });
  };
}

export function getFilterByNameFn<Section extends SectionType<Option>, Option extends Identifiable>(): FilterFn<
  Section,
  Option
> {
  return getFilterFn<Section, Option>({ keys: ['name'] });
}

export function getFilterByTitleFn<Section extends SectionType<Option>, Option extends Identifiable>(): FilterFn<
  Section,
  Option
> {
  return getFilterFn<Section, Option>({ keys: ['title'] });
}
