import { noop } from '@taraai/utility';
import { Style } from 'components/core/controllers/Editor/styles';
import { Editor as DraftJSEditor, EditorState, SelectionState } from 'draft-js';
import React, { RefObject } from 'react';
import { DispatchWithCallback } from 'tools';

export type UploadAttachmentFnType = (file: File) => Promise<string>;

export type OnChangeFunc = (editorState: EditorState, callback?: (newEditorState: EditorState) => void) => void;

export type OnChangeFnType = React.MutableRefObject<OnChangeFunc | null>;

type DraftDecoratorType = Parameters<typeof EditorState.createEmpty>[0];

export type EditorContextType = {
  editorRef: RefObject<DraftJSEditor> | null;
  editorState: EditorState;
  getEditorState: () => EditorState;
  setEditorState: DispatchWithCallback<EditorState>;
  setStyle: (style: Style) => void;
  focusEditor: () => void;
  uploadAndInjectFiles?: (exactSelectionState: SelectionState | null, files: Blob[]) => Promise<void>;
  uploadAndInjectPastedFiles?: (files: Blob[]) => Promise<void>;
  setUploadAttachmentFn: React.Dispatch<React.SetStateAction<UploadAttachmentFnType | null>>;
  onChangeFn: OnChangeFnType | null;
  setOnChangeFn: (onChangeFunc: OnChangeFunc) => void;
  readOnlyMode: boolean;
  setReadOnlyMode: (mode: boolean) => void;
  setDecorator: (decorator: DraftDecoratorType) => void;
  addLinkPopperOpen: boolean;
  setAddLinkPopperOpen: (open: boolean) => void;
};

const EditorContext = React.createContext<EditorContextType>({
  editorRef: null,
  editorState: EditorState.createEmpty(),
  focusEditor: noop,
  getEditorState: () => EditorState.createEmpty(),
  setEditorState: noop,
  setStyle: noop,
  onChangeFn: null,
  setUploadAttachmentFn: noop,
  setOnChangeFn: noop,
  readOnlyMode: false,
  setReadOnlyMode: noop,
  setDecorator: noop,
  addLinkPopperOpen: false,
  setAddLinkPopperOpen: noop,
});

export default EditorContext;
