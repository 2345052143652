"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = void 0;
var react_1 = __importStar(require("react"));
var core_1 = require("./core");
function Accordion(_a) {
    var children = _a.children, header = _a.header, _b = _a.initialUnfolded, initialUnfolded = _b === void 0 ? false : _b;
    var _c = react_1.useState(initialUnfolded), unfolded = _c[0], setUnfolded = _c[1];
    var arrow = unfolded ? arrows.unfolded : arrows.folded;
    var toggle = react_1.useCallback(function () {
        setUnfolded(function (prevUnfolded) { return !prevUnfolded; });
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        header({ arrow: arrow, toggle: toggle, unfolded: unfolded }),
        react_1.default.createElement("div", { hidden: !unfolded }, children)));
}
exports.Accordion = Accordion;
var arrows = {
    folded: (react_1.default.createElement("svg", { fill: core_1.tokens.colors.$grey7, height: '16', width: '16', xmlns: 'http://www.w3.org/2000/svg' },
        react_1.default.createElement("path", { d: 'M10.5 8L7.9 5.4a.3.3 0 00-.4.2v4.8c0 .2.3.3.4.2L10.5 8z' }))),
    unfolded: (react_1.default.createElement("svg", { fill: core_1.tokens.colors.$grey7, height: '16', width: '16', xmlns: 'http://www.w3.org/2000/svg' },
        react_1.default.createElement("path", { d: 'M8 10.5l2.6-2.6c.1-.1 0-.4-.2-.4H5.6c-.2 0-.3.3-.2.4L8 10.5z' }))),
};
