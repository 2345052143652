import { Data } from '@taraai/types';
import { UISprint } from '@taraai/types/dist/ui';
import SprintsRow from 'components/app/controllers/SprintsRow';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { getCompletedSprints } from 'reduxStore';
import { sort } from 'tools/libraries/helpers/sort';

/**
 * controls running query for completed sprint and passing completed sprints from the store to the SprintsRow
 *
 */
export default function CompletedSprints(): JSX.Element {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();

  const completedSlice = getCompletedSprints(orgID, teamID, {
    orderBy: 'sprintNumber',
  });
  useFirestoreConnect(completedSlice.query);

  const completedSprints = useSelector(
    compose(
      (arr: UISprint[]) =>
        arr.map((data) => ({
          id: data.id,
          sprintName: data.sprintName,
          description: data.description,
          initialStartDate: data.initialStartDate,
          initialEndDate: data.initialEndDate,
          completedAt: data.completedAt,
          computedOnCompletion: data.computedOnCompletion,
          isComplete: data.isComplete,
        })),
      (arr) => sort(arr || [], 'createdAt', true),
      completedSlice.selector,
    ),
    deepEquals,
  );

  return <SprintsRow isComplete sprints={completedSprints} />;
}
