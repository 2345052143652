"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = exports.defaultTextColor = void 0;
var core_1 = require("./core");
exports.defaultTextColor = '$grey7';
exports.Text = core_1.styled('span', {
    fontFamily: '$basic',
    fontWeight: '$regular',
}, {
    color: core_1.mapTokens('colors', function (value) { return ({ color: value }); }),
    italic: { true: { fontStyle: 'italic' } },
    size: core_1.mapTokens('fontSizes', function (value, name) { return ({ fontSize: value, lineHeight: name }); }),
    uppercase: { true: { textTransform: 'uppercase' } },
    weight: { medium: { fontWeight: '$medium' } },
}, { required: ['size'] });
exports.Text.defaultProps = {
    color: exports.defaultTextColor,
};
