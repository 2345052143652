import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';
import { compose, createSelector } from '@reduxjs/toolkit';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { linkTo, usePathParams } from 'components/Router/paths';
import React, { useRef } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import {
  getOrg,
  getRequirements,
  getTasks,
  getUpcomingSprints,
  selectActiveOrgIds,
  selectPreferredTeamId,
} from 'reduxStore';
import { atomic } from 'resources';
import { useLoadedOrgID } from 'tools/utils/hooks/useLoadedOrgId';

import { FlowWrapper, State, Step } from './FlowWrapper';
import { ConfirmationStep, CreateTasksStep, WorkspaceStep } from './Steps';
import { MoveTasksStep } from './Steps/MoveTasksStep';
import { StepName } from './types';

const defaultSteps: [StepName, Step][] = [
  ['start', WorkspaceStep],
  ['createTasks', CreateTasksStep],
  ['moveTasks', MoveTasksStep],
  ['finish', ConfirmationStep],
];

interface Props {
  steps?: [StepName, Step][];
}

/**
 * Onboarding v2
 */
export const OnboardingController = ({ steps = defaultSteps }: Props): JSX.Element => {
  const { small } = atomic.responsive.breakpoints[0];
  const isDesktop = useMediaQuery(`(min-width: ${small}px)`);

  const { orgID } = usePathParams('onboarding');
  const newOnboardingStarted = useRef(!orgID);
  const loadedOrgId = useLoadedOrgID();

  const orgSlice = getOrg(loadedOrgId);
  const requirementsSlice = getRequirements(loadedOrgId);
  const tasksSlice = getTasks(loadedOrgId);
  const upcomingSprintsSlice = getUpcomingSprints(loadedOrgId);

  useFirestoreConnect([
    ...orgSlice.query,
    ...requirementsSlice.query,
    ...tasksSlice.query,
    ...upcomingSprintsSlice.query,
  ]);

  const initialState = useSelector(
    createSelector(
      [orgSlice.selector, tasksSlice.selector, (): boolean => newOnboardingStarted.current],
      (org, tasks, isInitial) => {
        if (isInitial) {
          return { isOnboarded: false, orgName: '', taskTitles: [] };
        }
        if (!org || !tasks) {
          return;
        }
        return {
          isOnboarded: org.onboardingStatus === 'finished',
          orgName: org.name,
          taskTitles: tasks.map((task) => task.title),
        };
      },
    ),
    deepEquals,
  );
  const firstRequirementId = useSelector(compose((requirements) => requirements?.[0].id, requirementsSlice.selector));
  const taskTitleToId = useSelector(
    compose((tasks) => tasks?.reduce((map, task) => ({ ...map, [task.title]: task.id }), {}), tasksSlice.selector),
    deepEquals,
  );
  const upcomingSprintIds = useSelector(
    compose((sprints) => sprints?.map((sprint) => sprint.id), upcomingSprintsSlice.selector),
    deepEquals,
  );
  const isOnboardingFlowFinished = useSelector(
    compose((org) => org?.onboardingStatus === 'finished', orgSlice.selector),
  );

  const hasActiveOrgs = useSelector(
    compose(
      (data: string[]) => !!data.length,
      (data) => data.filter((slug) => slug !== orgID),
      selectActiveOrgIds,
    ),
  );

  const preferredTeamId = useSelector(selectPreferredTeamId(loadedOrgId));
  const history = useHistory();
  const onFinish = (): void => {
    if (!loadedOrgId || !preferredTeamId) {
      throw new Error('This is unexpected');
    }

    const sprintPage = linkTo('sprints', {
      orgID: loadedOrgId,
      teamID: preferredTeamId,
    });
    const mobileNotePage = linkTo('onboardingMobileNote', {
      orgID: loadedOrgId,
    });

    history.push(isDesktop ? sprintPage : mobileNotePage);
  };

  if (!isLoaded(initialState)) {
    return (
      <Center data-testid='loading'>
        <FastSmallSpinner />
      </Center>
    );
  }

  return (
    <PageContainer>
      <FlowWrapper
        firstRequirementId={firstRequirementId}
        hasActiveOrgs={hasActiveOrgs}
        initialState={initialState}
        initialStep={getInitialStep(initialState, orgID)}
        isOnboardingFlowFinished={isOnboardingFlowFinished}
        loadedOrgId={loadedOrgId}
        onFinish={onFinish}
        preferredTeamId={preferredTeamId}
        steps={steps}
        taskTitleToId={taskTitleToId}
        upcomingSprintIds={upcomingSprintIds}
      />
    </PageContainer>
  );
};

function getInitialStep({ isOnboarded, taskTitles }: State, orgID: string | undefined): StepName {
  if (isOnboarded) return 'finish';
  if (taskTitles.length > 0) return 'moveTasks';
  if (orgID) return 'createTasks';
  return 'start';
}

const PageContainer = styled.div`
  height: 100%;
`;

const Center = styled.div`
  font-size: 100px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
`;
