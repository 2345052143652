/* eslint-disable security/detect-unsafe-regex */
export type Validator = (value: string) => ValidationResult;
export type ValidationResult = { valid: true } | { valid: false; message: string };

function validateEmail(email: string): boolean {
  if (email.length === 0) return false;
  const regex = /(^$|^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\d-AZa-z-]+\.)+[A-Za-z]{2,}))$)/;
  return regex.test(String(email).toLowerCase());
}

export const emailValidator: Validator = (value: string) =>
  validateEmail(value) ? { valid: true } : { valid: false, message: 'Must be a valid email address' };
