import Fuse from 'fuse.js';

type FuseSubstringMatch = {
  matched: boolean;
  value: string;
  start: number;
  end: number;
};

const createSubstringMatch = (
  matched: boolean,
  source: string | undefined,
  start: number,
  end: number,
): FuseSubstringMatch => ({
  matched,
  value: source?.substring(start, end) ?? '',
  start,
  end,
});

type ReduceAccumulator = {
  currentIdx: number;
  substrings: FuseSubstringMatch[];
};

const indicesToSubstringsReducer = (source: string | undefined) => (
  { currentIdx, substrings }: ReduceAccumulator,
  [matchStart, matchEnd]: [number, number],
): ReduceAccumulator => {
  const nonMatched = createSubstringMatch(false, source, currentIdx, matchStart);
  const matched = createSubstringMatch(true, source, matchStart, matchEnd + 1);
  return {
    currentIdx: matchEnd + 1,
    substrings: [...substrings, nonMatched, matched],
  };
};

export function fuseMatchToSubstrings({ value, indices }: Fuse.FuseResultMatch): FuseSubstringMatch[] {
  const { substrings, currentIdx: lastMatchedIdx } = indices.reduce<ReduceAccumulator>(
    indicesToSubstringsReducer(value),
    {
      currentIdx: 0,
      substrings: [],
    },
  );
  const withLast = [
    ...substrings,
    {
      matched: false,
      value: value?.substring(lastMatchedIdx) ?? '',
      start: lastMatchedIdx,
      end: value?.length,
    } as FuseSubstringMatch,
  ];

  return withLast.filter(({ start, end }) => start !== end);
}
