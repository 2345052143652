import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { keys } from '@taraai/utility';
import Table, { Headers, TableDataEntry, TableProps } from 'components/core/controllers/Table';
import TableCell from 'components/core/controllers/views/TableCell';
import TableRow from 'components/core/controllers/views/TableRow';
import {
  composePlugins,
  createLabelsPlugin,
  createSingleLinePlugin,
  getWhitespacePlugin,
  plainTextPlugin,
} from 'components/editor/plugins';
import { RichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import { css, cx } from 'emotion';
import React, { MutableRefObject, useMemo, useRef } from 'react';
import { createLabel, reduxStore } from 'reduxStore';
import { defaultLabels, selectDefaultLabel } from 'reduxStore/labels/selectors';
import { requirementDetailsTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

/**
 * @param addNewTask addNewTask prop is being used by the requirement builder page
 * @param colWidth is used to specify individual column widths without a table header
 */
export interface TaskListProps extends TableProps {
  addFeatureRef?: React.MutableRefObject<RichEditorHandle | null>;
  onTaskSave?: (title: string) => void;
  tasks: TableDataEntry[];
  completeTaskCount?: string;
  toggleModal: (task: UI.UITask) => void;
  columns: Headers;
  addNewTask?: boolean;
  topRow?: JSX.Element;
  bottomRow?: JSX.Element;
  colWidth: Record<string, string>;
  dashboardTaskRow?: boolean;
  withSearch?: boolean;
  handleSearch?: (query: string) => void;
  customLabels?: UI.UICustomLabel[];
}

/**
 * TaskList renders a table view of tasks passed to the component
 * @param tasks - A list of tasks to display
 * @param addFeatureRef - The ref of the input used to add a new task
 * @param dashboardTaskRow - Used for styling the table on the dashboard
 * @param addNewTask - Used to render the new task input row as well as the new task button
 * @param handleEnter - Callback function for onKeyDown event
 * @param completeTaskCount - The number of completed tasks
 * @param tasksCompleted - Used to render the tasks completed count
 * @param toggleModal - Callback function for opening the modal for a task
 * @param columns - The headers for the table component
 * @param topRow - Customization for the first row
 * @param bottomRow - Customization for the last row
 * @param colWidth - Custom widths for each column; for an example check `DashboardTasks.tsx`
 * @param withSearch - Used to render the search box in the table component
 * @param handleSearch - A callback function that performs the search, located in the controller
 */
export default function TaskList({
  tasks,
  addFeatureRef,
  dashboardTaskRow,
  addNewTask,
  onTaskSave,
  completeTaskCount,
  toggleModal,
  columns,
  topRow,
  bottomRow,
  colWidth,
  withSearch = false,
  handleSearch,
  customLabels,
  ...props
}: TaskListProps): JSX.Element {
  const allLabels = useRef() as MutableRefObject<UI.UILabel[]>;
  allLabels.current = [...keys(defaultLabels).map(selectDefaultLabel), ...(customLabels ?? [])];
  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        plainTextPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => allLabels.current,
        }),
        createSingleLinePlugin({ returnHandled: true }),
      ),
    [],
  );
  return (
    <div>
      <div
        className={cx(css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `)}
      >
        {completeTaskCount && (
          <div
            className={cx(css`
              color: #191919;
              font-size: 1.5rem;
              font-weight: 600;
              padding-bottom: 0.75rem;
            `)}
            data-cy={requirementDetailsTestIDs.TASK_AMOUNT_HEADER}
          >
            {tasks.length
              ? strings.formatString(strings.requirements.tasksCompleted, {
                  completeTasks: completeTaskCount,
                  tasks: tasks.length,
                })
              : strings.formatString(strings.tasks.tasks)}
          </div>
        )}
      </div>
      <Table
        bottomRow={bottomRow}
        colWidth={colWidth}
        dashboardTaskRow={dashboardTaskRow}
        data={tasks}
        handleSearch={handleSearch}
        headers={columns}
        placeholderSearch={strings.table.searchTaskPlaceholder}
        tableRowOnClick={(row) => () => toggleModal(row.task as UI.UITask)}
        topRow={
          addNewTask ? (
            <TableRow>
              <TableCell
                className={cx(css`
                  &:focus-within {
                    background-color: #ffffff;
                    border: 0.0625rem solid #1d98ff;
                  }
                `)}
                columnSpan={keys(columns).length}
              >
                <StyledEditor>
                  <RichEditorProvider initialValue='' onSave={onTaskSave} plugin={plugin} saveOnReturn singleLine>
                    <RichEditor
                      ref={addFeatureRef}
                      data-cy={requirementDetailsTestIDs.ADD_TASK_INPUT}
                      placeholder={strings.task.addATask}
                    />
                  </RichEditorProvider>
                </StyledEditor>
              </TableCell>
            </TableRow>
          ) : (
            topRow
          )
        }
        withSearch={withSearch}
        {...props}
      />
    </div>
  );
}

const StyledEditor = styled('div', {
  'padding': '0.375rem 0rem',
  'height': 'unset',
  'color': '$grey6',
  '&:focus-within': {
    fontWeight: 500,
    color: '$dark',
  },
  '.DraftEditor-root': {
    paddingLeft: '1rem',
    fontSize: '0.875rem',
    lineHeight: 'normal',
  },
});
