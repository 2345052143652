import { DraftBlockType, DraftInlineStyleType, EditorState, RichUtils } from 'draft-js';
import { OrderedSet } from 'immutable';

import { EditorStateTransform } from './types';
import { preserveVolatileState } from './utils';

type TransformName =
  | 'toggleBold'
  | 'toggleItalics'
  | 'toggleUnderline'
  | 'toggleStrikethrough'
  | 'toggleCode'
  | 'toggleParagraph'
  | 'toggleHeaderOne'
  | 'toggleHeaderTwo'
  | 'toggleOrderedList'
  | 'toggleUnorderedList'
  | 'toggleBlockquote'
  | 'toggleCodeBlock';

export const transforms: Record<TransformName, EditorStateTransform> = {
  // Those transforms apply inline styles
  toggleBold: getInlineToggler('BOLD'),
  toggleItalics: getInlineToggler('ITALIC'),
  toggleUnderline: getInlineToggler('UNDERLINE'),
  toggleStrikethrough: getInlineToggler('STRIKETHROUGH'),
  toggleCode: preserveVolatileState(RichUtils.toggleCode),
  // Those transforms apply block styles
  toggleParagraph: getBlockToggler('paragraph'),
  toggleHeaderOne: getBlockToggler('header-one'),
  toggleHeaderTwo: getBlockToggler('header-two'),
  toggleBlockquote: getBlockToggler('blockquote'),
  toggleCodeBlock: getBlockToggler('code-block'),
  // Those transforms apply to each selected block separately
  toggleOrderedList: getBlockToggler('ordered-list-item'),
  toggleUnorderedList: getBlockToggler('unordered-list-item'),
};

function getInlineToggler(inlineStyle: DraftInlineStyleType): EditorStateTransform {
  return preserveVolatileState(
    (state) => RichUtils.toggleInlineStyle(state, inlineStyle),
    (state) => {
      const currentInlineStyle = state.getCurrentInlineStyle() ?? OrderedSet();
      return EditorState.setInlineStyleOverride(
        state,
        currentInlineStyle.has(inlineStyle)
          ? currentInlineStyle.delete(inlineStyle)
          : currentInlineStyle.add(inlineStyle),
      );
    },
  );
}

function getBlockToggler(blockType: DraftBlockType): EditorStateTransform {
  return preserveVolatileState((state) => RichUtils.toggleBlockType(state, blockType));
}
