import styled from '@emotion/styled';
import RequirementsListElement from 'components/app/controllers/views/RequirementsListElement';
import { sprintsBacklogWrapperStyles } from 'components/app/layouts/SprintsBacklog/styles';
import Avatar from 'components/core/controllers/views/Avatar';
import Text from 'components/core/controllers/views/Text';
import React from 'react';
import { strings } from 'resources';

import { BlankRequirement } from './BlankRequirement';
import { Task } from './Task';
import { PreviewProps } from './types';

const variantToChildren = {
  blank: {
    Requirements(): JSX.Element {
      return (
        <>
          <BlankRequirement color='#43ad86' />
          <BlankRequirement color='#f87949' />
          <BlankRequirement color='#fcc81f' />
          <BlankRequirement color='#4470e6' />
        </>
      );
    },
    Tasks(): null {
      return null;
    },
  },
  oneRequirement: {
    Requirements(): JSX.Element {
      return (
        <RequirementsListElement
          icon={(): JSX.Element => <Avatar requirement={{ title: 'Requirement 1' }} size='medium' />}
          label='Requirement 1'
          rightIcon={<></>}
        />
      );
    },
    Tasks({ taskTitles }: Pick<PreviewProps, 'taskTitles'>): JSX.Element {
      return (
        <>
          <Task placeholder={strings.onboarding.tasksForm.taskPlaceholders[0]} value={taskTitles?.[0]} />
          <Task placeholder={strings.onboarding.tasksForm.taskPlaceholders[1]} value={taskTitles?.[1]} />
          <Task placeholder={strings.onboarding.tasksForm.taskPlaceholders[2]} value={taskTitles?.[2]} />
        </>
      );
    },
  },
};

interface Props extends Pick<PreviewProps, 'taskTitles'> {
  className?: string;
  tasksWrapperClassName?: string;
  variant: keyof typeof variantToChildren;
}

export const Backlog: React.FC<Props> = ({ className, tasksWrapperClassName, taskTitles, variant }) => {
  const { Requirements, Tasks } = variantToChildren[variant];
  return (
    <Wrapper>
      <Header>{strings.sprints.backlogTitle}</Header>
      <div className={className}>
        <Requirements />
      </div>
      <TasksWrapper className={tasksWrapperClassName}>
        <Tasks taskTitles={taskTitles} />
      </TasksWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${sprintsBacklogWrapperStyles};
  z-index: 1;
  flex-shrink: 0;
  width: 17rem;
  background-color: transparent;
`;

const Header = styled((props) => <Text {...props} />)`
  padding: 1.5rem 0 1.25rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #303f4b;
  box-shadow: 0 0.0625rem 0 0 #eaeef5;
`;

const TasksWrapper = styled.div`
  padding: 0.5rem;
`;
