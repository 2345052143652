import { Data } from '@taraai/types';
import { UIRequirement } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { Selector } from 'react-redux';
import { OrderByOptions, ReduxFirestoreQuerySetting, WhereOptions } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import { getUsers } from 'reduxStore/users';
import {
  createIndividualSelector,
  createStandardSelector,
  generateAlias,
  IndividualQuery,
  inertIndividualQuery,
  inertQuery,
  PopulateTuples,
  Query,
} from 'reduxStore/utils/selectors';

/**
 * Private Query Builder
 */

type OrderBy = keyof Pick<UIRequirement, 'title' | 'updatedAt' | 'createdAt'>;

function queryBuilder(orgID: string, where: WhereOptions[], ordered?: OrderBy): Query<UIRequirement> {
  if (!isNonEmptyString(orgID)) {
    return inertQuery();
  }

  let orderBy: OrderByOptions | undefined;
  switch (ordered) {
    case 'createdAt':
      orderBy = ['createdAt', 'desc'];
      break;
    case 'title':
      orderBy = ['title', 'asc'];
      break;
    case 'updatedAt':
      orderBy = ['updatedAt', 'desc'];
      break;
  }

  const queryUser: ReduxFirestoreQuerySetting = getUsers(orgID).query[0];
  const queryRequirement: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/requirements`,
    where,
  };

  if (orderBy) queryRequirement.orderBy = orderBy;
  queryRequirement.storeAs = generateAlias(queryRequirement);

  const query = [queryRequirement];
  const documentTuples: PopulateTuples = [['author', queryUser]];

  return {
    query,
    selector: createStandardSelector(queryRequirement, documentTuples),
  };
}

export type RequirementListSelector = Selector<RootState, UIRequirement[] | undefined>;

export type RequirementSelector = Selector<RootState, UIRequirement | undefined>;

/**
 * Public functions
 */
/**
 * Gets requirements from global/team scope
 * @param orgID
 * @param teamID - if not provided it will get all requirements
 * @param options
 */
export function getRequirements(
  orgID?: string,
  teamID?: Data.Id.TeamId | null,
  options?: { orderBy?: OrderBy },
): Query<UIRequirement> {
  if (!orgID) {
    return inertQuery();
  }
  const where: WhereOptions[] = [
    ['archived', '==', false],
    ['deleted', '==', false],
  ];

  if (teamID) {
    where.push(['assignedTeamIds', 'array-contains', teamID]);
  }

  return queryBuilder(orgID, where, options?.orderBy ?? 'createdAt');
}

export function getRequirement(orgID: string, requirementID: string): IndividualQuery<UIRequirement> {
  if (!isNonEmptyString(requirementID)) {
    return inertIndividualQuery();
  }
  const queryRequirement: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/requirements`,
    where: [['__name__', '==', requirementID]],
  };

  const queryUser: ReduxFirestoreQuerySetting = getUsers(orgID).query[0];
  const documentTuples: PopulateTuples = [['author', queryUser]];

  return {
    query: [queryRequirement],
    selector: createIndividualSelector(requirementID, queryRequirement, documentTuples),
  };
}

export function getArchivedRequirements(orgID: string, options?: { orderBy: OrderBy }): Query<UIRequirement> {
  const where: WhereOptions[] = [
    ['archived', '==', true],
    ['deleted', '==', false],
  ];
  return queryBuilder(orgID, where, options?.orderBy ?? 'createdAt');
}
