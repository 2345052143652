import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export type FinishRegistrationData = {
  organizationName?: string;
  id?: string;
  invitationToken?: string;
};

export const finishRegistration = createAsyncThunk(
  'FinishRegistration',
  async (data: FinishRegistrationData, { extra }) => {
    const { getFirebase } = extra as ExtraAPI;

    let payload: Functions.FinishRegistration.Payload;
    if (data.invitationToken) {
      payload = {
        type: 'JoinExistingOrganization',
        invitationToken: data.invitationToken,
      };
    } else if (data.organizationName && data.id) {
      payload = {
        type: 'CreateNewOrganization',
        name: data.organizationName,
        id: data.id,
      };
    } else {
      throw Error('FinishRegistration error: insufficient data');
    }

    const response = await getFirebase().functions().httpsCallable('finishRegistration')(payload);

    return Functions.unwrap(decode<Functions.FinishRegistration.Response>(response.data, 'FinishRegistrationResponse'));
  },
);
