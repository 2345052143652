/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */

import { notUndefined } from '@taraai/utility/dist/functions';
import { CompositeDecorator, ContentState, EditorState } from 'draft-js';
import React, {
  createContext,
  createRef,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebounced } from 'tools/utils/hooks/useDebounced';

import { composePlugins, createDefaultPlugin, useHandlers } from './plugins';
import { Awaitable, RichEditorHandlers, RichEditorPlugin } from './types';

const errorNoProvider = (): never => {
  throw new Error('Tried to use RichEditorContext without RichEditorProvider');
};

/** Amount of time to wait after user stops typing before autosaving */
const autoSaveWait = 1000;

type State = 'mounted' | 'initialized' | 'modified' | 'saved';

export const RichEditorContext = createContext<{
  editorState: EditorState;
  handlers: RichEditorHandlers;
  onChange: (editorState: EditorState) => void;
  readOnly: boolean;
  setDisableHandlers: Dispatch<SetStateAction<boolean>>;
  setEditorState: Dispatch<SetStateAction<EditorState>>;
  singleLine: boolean;
  state: State;
  toolbarRef: MutableRefObject<HTMLElement | null>;
}>({
  editorState: EditorState.createEmpty(),
  handlers: {},
  onChange: errorNoProvider,
  readOnly: false,
  setDisableHandlers: errorNoProvider,
  setEditorState: errorNoProvider,
  singleLine: false,
  state: 'mounted',
  toolbarRef: createRef(),
});

interface Props<Value> {
  children: ReactNode;
  debug?: boolean;
  initialValue?: Value;
  onHasTextChange?: (hasContent: boolean) => void;
  onSave?: (value: Value, content: ContentState) => Awaitable<void>;
  onTextLengthChange?: (length: number) => void;
  plugin?: RichEditorPlugin<((source: Value) => Awaitable<ContentState>) | undefined>;
  readOnly?: boolean;
  saveOnReturn?: boolean;
  singleLine?: boolean;
}

/**
 * The main goal of `RichEditorProvider` component is to
 * provide a single source of truth for the internal editor state
 * and to share it between `RichEditor` and `Toolbar` components.
 */
export function RichEditorProvider<Value>({
  children,
  debug,
  initialValue,
  onHasTextChange,
  onSave,
  onTextLengthChange,
  plugin,
  readOnly: forceReadOnly,
  saveOnReturn = false,
  singleLine = false,
}: Props<Value>): JSX.Element {
  const [state, setState] = useState<State>('mounted');
  const toolbarRef = useRef(null);
  const [disableHandlers, setDisableHandlers] = useState(false);
  const pluginWithDefaults = useMemo(
    () =>
      composePlugins(...[plugin, createDefaultPlugin({ saveOnReturn })].filter(notUndefined)) as Required<
        RichEditorPlugin<(source: Value) => Awaitable<ContentState>>
      >,
    [plugin, saveOnReturn],
  );

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(new CompositeDecorator(pluginWithDefaults.decorator)),
  );

  const hasText = editorState.getCurrentContent().hasText();
  useLayoutEffect(() => {
    onHasTextChange?.(hasText);
  }, [hasText, onHasTextChange]);

  const textLength = editorState.getCurrentContent().getPlainText().length;
  useLayoutEffect(() => {
    onTextLengthChange?.(textLength);
  }, [textLength, onTextLengthChange]);

  const save = useSavePipeline<Value>({
    save: pluginWithDefaults.pipeline.save,
    onSave,
    setSaved: useCallback(() => {
      setState('saved');
    }, []),
  });

  const handlers = useHandlers(pluginWithDefaults, { save, setEditorState });
  const debouncedSave = useDebounced(save, autoSaveWait);

  const prevStateRef = useRef(state);
  const prevEditorStateRef = useRef(editorState);
  useEffect(() => {
    const prevState = prevStateRef.current;
    const prevEditorState = prevEditorStateRef.current;
    prevStateRef.current = state;
    prevEditorStateRef.current = editorState;

    /**
     * For the first change in the editor we're relying on the fact that focusing causes `onChange` to fire.
     * Before we first change state to 'modified' we won't be registering any changes.
     */
    if (prevState === 'mounted' || prevState === 'initialized') {
      return;
    }

    const prevContent = prevEditorState.getCurrentContent();
    const nextContent = editorState.getCurrentContent();
    if (nextContent !== prevContent && !saveOnReturn) {
      debouncedSave(nextContent);
    }
  }, [debouncedSave, editorState, state, saveOnReturn]);

  const onChange = useCallback((nextState: EditorState) => {
    setState('modified');
    setEditorState(nextState);
  }, []);

  useReadPipeline<Value>({
    read: pluginWithDefaults.pipeline.read,
    initialValue,
    setInitialized: useCallback((content) => {
      setState('initialized');
      if (content) {
        setEditorState((currentEditorState) =>
          EditorState.createWithContent(content, currentEditorState.getDecorator()),
        );
      }
    }, []),
  });

  const readOnly =
    forceReadOnly ||
    // Draft.js source code says that if readOnly is on, all handlers are disabled
    disableHandlers ||
    // Disable when the editor is not initialized yet
    state === 'mounted';

  if (debug) {
    // We're assuming that `debug` won't change while the component is mounted
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      console.log(`[RichEditorProvider] State change: ${state}`);
    }, [state]);
  }

  return (
    <RichEditorContext.Provider
      value={useMemo(
        () => ({
          editorState,
          handlers,
          onChange,
          readOnly,
          state,
          setDisableHandlers,
          setEditorState,
          toolbarRef,
          singleLine,
        }),
        [editorState, handlers, onChange, readOnly, singleLine, state],
      )}
    >
      {children}
    </RichEditorContext.Provider>
  );
}

function useReadPipeline<Value>({
  initialValue,
  read,
  setInitialized,
}: {
  initialValue: Value | undefined;
  read: (source: Value) => Awaitable<ContentState>;
  setInitialized: (content?: ContentState) => void;
}): void {
  // Put the dependencies in a ref because the effect should fire only once
  // TODO: fix it in the pipeline, make read/write functions unchangeable and pass the context separately
  const depsRef = useRef({ initialValue, read, setInitialized });
  useEffect(() => {
    const { initialValue, read, setInitialized } = depsRef.current;
    let stopped = false;
    (async () => {
      try {
        const content = initialValue && (await read(initialValue));
        if (!stopped) {
          setInitialized(content);
        }
      } catch (error) {
        console.error('Read pipeline error:', error);
      }
    })();
    return () => {
      stopped = true;
    };
  }, []);
}

function useSavePipeline<Value>({
  onSave,
  save,
  setSaved,
}: {
  save: (content: ContentState) => Value | Promise<Value>;
  onSave?: (value: Value, content: ContentState) => Awaitable<void>;
  setSaved: () => void;
}): (content: ContentState) => void {
  const lastSaveIdRef = useRef(0);
  return useCallback(
    async (content: ContentState) => {
      lastSaveIdRef.current += 1;
      const currentSaveId = lastSaveIdRef.current;
      try {
        const value = await save(content);
        await onSave?.(value, content);
        if (currentSaveId === lastSaveIdRef.current) {
          setSaved();
        }
      } catch (error) {
        console.error('Save pipeline error:', error);
      }
    },
    [onSave, save, setSaved],
  );
}
