/* eslint-disable import/no-mutable-exports */
import { noop } from '@taraai/utility';
import StackdriverErrorReporter from 'stackdriver-errors-js';

/**
 * Initializes Stackdriver error reporting so we have all our reports
 * in Google Cloud.
 *
 * Usage:
 * ```
 * import errorHandler from 'tools/libraries/stackdriver';
 *
 * errorHandler.report(new Error('something broke!'));
 * ```
 *
 * See https://github.com/GoogleCloudPlatform/stackdriver-errors-js
 */
let errorHandler: StackdriverErrorReporter;

export const config = {
  key: process.env.REACT_APP_STACKDRIVER_KEY,
  projectId: process.env.REACT_APP_GCP_PROJECT_ID,
  service: 'default', // (optional)
  version: process.env.REACT_APP_VERSION,
  // reportUncaughtExceptions: false          // (optional) Set to false to stop reporting unhandled exceptions.
  // reportUnhandledPromiseRejections: false  // (optional) Set to false to stop reporting unhandled promise rejections.
  disabled: process.env.NODE_ENV !== 'production', // (optional) Set to true to not report errors when calling report(), this can be used when developing locally.
  // context: {user: 'user1'}                 // (optional) You can set the user later using errorHandler.setUser()
};

if (process.env.NODE_ENV === 'production') {
  errorHandler = new StackdriverErrorReporter();
  errorHandler.start(config);
} else {
  errorHandler = ({
    setUser: noop,
    report: noop,
  } as unknown) as StackdriverErrorReporter;
}

export default errorHandler;
