/* eslint-disable @typescript-eslint/ban-types */
/// <reference types="@types/segment-analytics" />

import { Data } from '@taraai/types';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}
function page(): void {
  window?.analytics?.page?.();
}

function load(): void {
  // eslint-disable-next-line no-secrets/no-secrets
  window?.analytics?.load?.('VLrX6QgliAdDyD5YLX2H70CA6aEagqtP');
  page();
}

function logout(): void {
  if (window?.analytics?.user) window?.analytics?.user?.().logout?.();
  window?.analytics?.reset?.();
}

function identify(userId: string, traits?: Object): void {
  return window?.analytics?.identify?.(userId, traits);
}

function group(groupId: string, traits?: Object): void {
  return window?.analytics?.group?.(groupId, traits);
}

function multiGroup(
  groups: {
    id: Data.Id.OrganizationId | Data.Id.TeamId;
    name: string;
    orgID?: Data.Id.OrganizationId;
  }[],
): void {
  groups.forEach(({ id, ...properties }) => window?.analytics?.group?.(id, properties));
}

interface Event {
  orgID: Data.Id.OrganizationId;
}

interface TaskEvent extends Event {
  slug: Data.Id.TaskId;
}

interface TaskCreatedEvent extends TaskEvent {
  location: 'TaskModalSubtask' | 'Requirement' | 'Backlog' | 'RequirementBacklog' | 'Onboarding' | 'SprintsColumn';
}

interface RequirementEvent extends Event {
  requirementID: Data.Id.RequirementId;
}

interface RequirementCreatedEvent extends RequirementEvent {
  location: 'RequirementsBacklog' | 'RequirementsPage';
}

interface SprintEvent extends Event {
  sprintID: Data.Id.SprintId;
}

interface SprintCreatedEvent extends SprintEvent {
  location: 'SprintComplete' | 'EmptySprint' | 'SprintsPlanning';
}

interface SprintCompletedEvent extends SprintEvent {
  nextSprintID: Data.Id.SprintId | null;
}

interface IntegrationEvent extends Event {
  service: 'github' | 'slack' | 'gitlab' | 'trello' | 'asana';
}

interface IntegrationConnectedEvent extends IntegrationEvent {
  location: 'GitlabModal' | 'SetupIntegration' | 'IntegrationFormButton';
}

interface Events {
  TaskCreated: TaskCreatedEvent;
  RequirementCreated: RequirementCreatedEvent;
  SprintCreated: SprintCreatedEvent;
  SprintStarted: SprintEvent;
  SprintCompleted: SprintCompletedEvent;
  IntegrationConnected: IntegrationConnectedEvent;
}

type EventName = keyof Events;

function track<EN extends EventName>(name: EN, options: Events[EN]): void {
  return window?.analytics?.track?.(name, options);
}

export const segment = {
  group,
  identify,
  load,
  logout,
  multiGroup,
  page,
  track,
};
