import styled from '@emotion/styled';
import { FormProps } from 'components/app/Onboarding/FlowWrapper';
import { Header, NextButton, SkipButton, Wrapper } from 'components/app/Onboarding/Form';
import React from 'react';
import { strings } from 'resources';
import { workspaceSetupTestIDs } from 'resources/cypress/testAttributesValues';

import { ChildProps } from './types';

const TASKS = ['first', 'second', 'third'];

/**
 * Form
 * The form on the left panel on step 2 of onboarding.
 */
export const Form = ({
  onSkip,
  onNext,
  handleOnChange,
  hasActiveOrgs,
  isSkipping,
}: FormProps<ChildProps>): JSX.Element => (
  <Wrapper>
    <CustomHeader>{strings.onboarding.tasksForm.header}</CustomHeader>
    <FormContainer>
      {TASKS.map(
        (task: string, index: number): JSX.Element => (
          <InputField
            key={task}
            autoFocus={index === 0}
            data-cy={
              strings.formatString(workspaceSetupTestIDs.SECOND_STEP_INPUT, {
                taskNumber: task,
              }) as string
            }
            name={task}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => handleOnChange(event.target.value, index)}
            placeholder={strings.onboarding.tasksForm.taskPlaceholders[index]}
          />
        ),
      )}

      <Tip>
        <span>{strings.onboarding.tasksForm.lightbulb.emoji}</span>
        <span>
          <p>{strings.onboarding.tasksForm.tip.firstLine}</p>
          <p>{strings.onboarding.tasksForm.tip.secondLine}</p>
        </span>
      </Tip>
      <ButtonsContainer>
        <NextButton disabled={isSkipping} onClick={onNext}>
          {strings.onboarding.tasksForm.next}
        </NextButton>
        {hasActiveOrgs && <SkipButton onClick={onSkip}>{strings.onboarding.tasksForm.skip}</SkipButton>}
      </ButtonsContainer>
    </FormContainer>
  </Wrapper>
);

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const CustomHeader = styled(Header)`
  margin-bottom: 1.375rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  > * + * {
    margin-left: 1.5rem;
  }
`;

const InputField = styled((props) => <input {...props} />)`
  height: 2.8125rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #c8d0df;
  background-color: #ffffff;
  padding: 1rem;
  margin: 0.25rem 0;
  ::placeholder {
    color: #c2c8cc;
  }
`;

const Tip = styled.div`
  border-radius: 0.1875rem;
  color: #6c757c;
  background-color: #fef8e6;
  padding: 1rem 0.75rem;
  display: grid;
  place-items: baseline;
  grid-template-columns: 1.25rem auto;
  margin: 0.5rem 0 1.5rem 0;
`;
