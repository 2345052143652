import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import AddCommentInputView, { AddCommentInputStatus } from 'components/app/controllers/views/AddCommentInputView';
import DraftMention from 'components/core/controllers/DraftMention';
import DraftMentionInserter from 'components/core/controllers/DraftMentionInserter';
import { useEditor } from 'components/core/controllers/Editor';
import { mentionInserterStrategy, mentionStrategy } from 'components/core/controllers/Editor/decorators';
import { DraftEditorWrapper } from 'components/core/controllers/Editor/DraftEditorWrapper';
import { convertEditorStateToRawMarkdown, getEntitiesOfType } from 'components/core/controllers/Editor/helpers';
import { downgradeToPlainText } from 'components/core/controllers/Editor/mention';
import { CompositeDecorator, DraftHandleValue, EditorState, RichUtils } from 'draft-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createTaskComment, reduxStore } from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';

interface AddCommentInputProps {
  onCommentAdded: () => void;
  taskID: Data.Id.TaskId;
}

export default function AddCommentInput({ onCommentAdded, taskID }: AddCommentInputProps): JSX.Element {
  const { editorState, setEditorState, getEditorState, editorRef, setOnChangeFn, setStyle, focusEditor } = useEditor();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editorDecorator = new CompositeDecorator([
    {
      strategy: mentionStrategy,
      component: DraftMention,
      props: {
        getEditorState,
        setEditorState,
      },
    },
    {
      strategy: mentionInserterStrategy,
      component: DraftMentionInserter,
      props: {
        getEditorState,
        setEditorState,
      },
    },
  ]);

  const onChangeFn = useCallback(
    (currentState: EditorState, callback?: (newEditorState: EditorState) => void) => {
      setEditorState(currentState, callback);
    },
    [setEditorState],
  );

  useEffect(() => {
    setOnChangeFn(onChangeFn);
  }, [onChangeFn, setOnChangeFn]);

  const status: AddCommentInputStatus = useMemo(() => {
    if (isLoading) {
      return 'loading';
    }
    if (editorState.getCurrentContent().hasText()) {
      return 'ready';
    }
    return 'disabled';
  }, [editorState, isLoading]);

  const handleSend = useCallback(async (): Promise<void> => {
    if (status === 'ready') {
      // convert EditorState to markdown equivalent
      const downgradedMentions = downgradeToPlainText(editorState.getCurrentContent());
      const markdownCommentContent = convertEditorStateToRawMarkdown(downgradedMentions);
      const mentionEntities = getEntitiesOfType<{ id: string }>('mention', editorState.getCurrentContent());
      const mentionedUserIds = mentionEntities.map(({ id }) => id);
      setIsLoading(true);
      await reduxStore
        .dispatch(
          createTaskComment({
            description: markdownCommentContent,
            taskID,
            mentionedUserIds,
          }),
        )
        .then(unwrapResult)
        .then(() => setEditorState(EditorState.createEmpty(editorDecorator)))
        .then(() => onCommentAdded())
        .catch(() => {
          addToast({
            type: 'error',
            message: strings.comments.couldntAdd,
            timeoutMs: 3000,
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [addToast, setEditorState, editorDecorator, editorState, onCommentAdded, setIsLoading, status, taskID]);

  const handleSendButonClick = useCallback(() => {
    handleSend();
  }, [handleSend]);

  return (
    <AddCommentInputView
      inputSlot={({ inputRootStyle }): JSX.Element => (
        <DraftEditorWrapper
          className={inputRootStyle}
          editorRef={editorRef}
          editorState={editorState}
          focusEditor={focusEditor}
          onChange={setEditorState}
          onReturn={(): DraftHandleValue => {
            // send comment on return key
            handleSend();
            return 'handled';
          }}
          onShiftReturn={(_event, currentState): DraftHandleValue => {
            // place newline on shitf+return
            const currentStateWithNewline = RichUtils.insertSoftNewline(currentState);
            setEditorState(currentStateWithNewline);
            return 'handled';
          }}
          placeholder={strings.comments.leaveComment}
          setEditorState={setEditorState}
          setStyle={setStyle}
        />
      )}
      onSendButtonClick={handleSendButonClick}
      status={status}
    />
  );
}
