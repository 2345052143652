import { Data, Functions, UI } from '@taraai/types';
import { AsanaImportBacklogIndicatorController } from 'components/app/controllers/AsanaImportBacklogIndicatorController';
import { GithubImportBacklogIndicatorController } from 'components/app/controllers/GithubImportBacklogIndicatorController';
import TaskCount from 'components/app/controllers/TaskCount';
import { TrelloImportBacklogIndicatorController } from 'components/app/controllers/TrelloImportBacklogIndicatorController';
import RequirementsListElement from 'components/app/controllers/views/RequirementsListElement';
import Avatar from 'components/core/controllers/views/Avatar';
import Icon from 'components/core/controllers/views/Icon';
import ListView from 'components/core/layouts/ListView';
import { linkTo } from 'components/Router/paths';
import { css, cx } from 'emotion';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { atomic } from 'resources';
import { Document } from 'resources/assets';
import { strings } from 'resources/i18n';
import { useAfterMountEffect } from 'tools';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title' | 'backlogTasksCount' | 'archived'>;

export interface RequirementsListControllerProps {
  requirements: RequirementFragment[];
  listItem: UI.UITask[];
  getRequirementTasks: (requirement: RequirementFragment) => void;
  getBacklogTasks: () => void;
  showImportedTasks: (
    service: Functions.ImportCSVFile.CSVImportableService | Data.ExternalIssue.Any['service'],
  ) => void;
  backlogTaskCount: number;
}

export default function RequirementsListController({
  requirements = [],
  listItem,
  getRequirementTasks,
  getBacklogTasks,
  backlogTaskCount,
  showImportedTasks,
}: RequirementsListControllerProps): JSX.Element {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();
  const history = useHistory();
  const [taskUpdate, setTaskUpdate] = useState(false);

  const triggerAnimation = useCallback(() => {
    setTaskUpdate(true);
    setTimeout(() => {
      setTaskUpdate(false);
    }, 1000);
  }, []);

  useAfterMountEffect(() => {
    triggerAnimation();
  }, [backlogTaskCount, triggerAnimation]);

  function handleRequirementClick(requirement: RequirementFragment): void {
    getRequirementTasks(requirement);
  }

  function handleBacklogClick(): void {
    getBacklogTasks();
  }

  function handleCreateRequirement(event: React.SyntheticEvent): void {
    event.preventDefault();
    history.push(linkTo('createRequirement', { orgID, teamID }));
  }

  const elements: JSX.Element[] = [];

  if (listItem) {
    elements.push(
      <RequirementsListElement
        key='tasks-header'
        description={strings.formatString(strings.sprints.backlogTaskCount, {
          number: backlogTaskCount,
        })}
        glow={taskUpdate}
        icon={({ glowClass }): JSX.Element => (
          <Icon
            className={cx(
              css`
                background-color: #f1f5fd;
                color: #4550ce;
                border-radius: 0.1875rem;
              `,
              glowClass,
            )}
            name='backlog'
          />
        )}
        label={strings.sprints.backlogTasks}
        onClick={(): void => handleBacklogClick()}
      />,
    );
  }

  elements.push(
    <TrelloImportBacklogIndicatorController
      key='trello-import-indicator'
      showImportedTasks={(): void => showImportedTasks('trello')}
    />,
  );

  elements.push(
    <AsanaImportBacklogIndicatorController
      key='asana-import-indicator'
      showImportedTasks={(): void => showImportedTasks('asana')}
    />,
  );
  elements.push(
    <GithubImportBacklogIndicatorController
      key='github-import-indicator'
      showImportedTasks={(): void => showImportedTasks('github')}
    />,
  );

  if (requirements.length > 0 || requirements.length === 0) {
    elements.push(
      <div
        key='requirements-header'
        className={css`
          font-size: 0.75rem;
          font-weight: 500;
          font-style: normal;
          color: #949caf;
          border-bottom: solid 0.0625rem #eaeef5;
          padding: 1.5625rem 1rem 0.5625rem 1rem;
          text-transform: uppercase;
          cursor: auto;
        `}
      >
        {strings.requirements.title}
      </div>,
    );
  }
  elements.push(
    <div
      key='no-requirements'
      className={css`
        font-size: 0.875rem;
        color: #949caf;
        border-bottom: solid 0.0625rem #eaeef5;
        padding: 0.9375rem 0.5rem 0.9375rem 1rem;
        cursor: pointer;
        line-height: 1.07;
        background-repeat: no-repeat;
        background-position: top 0% left 105%;
        background-image: url('${Document}');
        :focus {
          outline: 0;
        }
        :hover {
          background-color: #fbfbfd;
        }
      `}
      onClick={(event: React.SyntheticEvent): void => {
        handleCreateRequirement(event);
      }}
      onKeyDown={(event: React.SyntheticEvent): void => handleCreateRequirement(event)}
      role='button'
      tabIndex={0}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          className={css`
            display: flex;
            margin-right: 0.5rem;
          `}
        >
          <Icon
            className={css`
              padding: 0;
              color: #4550ce;
              width: 2rem;
              height: 2rem;
              border-radius: 0.1875rem;
              object-fit: contain;
            `}
            name='addSquare'
          />
        </div>
        <div>
          <div
            className={css`
              font-size: 1rem;
              font-weight: 500;
              ${atomic.color(atomic.colors.secondary)};
              :hover {
                text-decoration: underline;
              }
              :focus {
                outline: 0;
              }
            `}
          >
            {strings.requirements.createRequirement}
          </div>
          <div
            className={css`
              padding-top: 0.25rem;
            `}
          >
            {strings.requirements.emptyStateRequirementDetails}
          </div>
        </div>
      </div>
    </div>,
  );

  requirements
    .filter(
      (requirement) =>
        requirement.backlogTasksCount > 0 || (!requirement.archived && requirement.backlogTasksCount === 0),
    )
    .forEach((requirement: RequirementFragment) =>
      elements.push(
        <RequirementsListElement
          key={requirement.id}
          description={
            <TaskCount
              className={css`
                padding-top: 0;
              `}
              requirement={requirement}
            />
          }
          icon={(): JSX.Element => <Avatar key={requirement.id} requirement={requirement} size='medium' />}
          iconStyle={css`
            align-items: flex-start;
          `}
          label={requirement.title}
          labelStyle={css`
            padding-top: 0;
          `}
          onClick={(): void => handleRequirementClick(requirement)}
        />,
      ),
    );

  return (
    <ListView
      childrenClassName={css`
        cursor: pointer;
      `}
      className={css`
        list-style-type: none;
        overflow: auto;
        position: relative;
        max-height: 100%;
        max-width: 100%;
      `}
    >
      {elements}
    </ListView>
  );
}
