import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ExtraAPI } from 'reduxStore/utils/types';

interface DeleteTaskComment {
  commentID: Data.Id.CommentId;
  taskID: Data.Id.TaskId;
}

export const deleteTaskComment = createAsyncThunk(
  'DeleteTaskComment',
  async ({ commentID, taskID }: DeleteTaskComment, { extra }) => {
    const { getOrgID, getFirestore } = extra as ExtraAPI;
    const orgID = getOrgID();
    const firestore = getFirestore();

    if (!isNonEmptyString(taskID)) {
      throw new Error('Missing task id');
    }
    if (!isNonEmptyString(commentID)) {
      throw new Error('Missing comment id');
    }

    await getFirestore().update<Data.Comment>(`orgs/${orgID}/tasks/${taskID}/comments/${commentID}`, {
      deletedAt: firestore.Timestamp.now(),
    });

    return { id: commentID };
  },
);
