"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.systemUiMap = void 0;
exports.systemUiMap = {
    background: 'backgrounds',
    backgroundColor: 'colors',
    borderBottomColor: 'colors',
    borderBottomWidth: 'borderWidths',
    borderColor: 'colors',
    borderLeftColor: 'colors',
    borderLeftWidth: 'borderWidths',
    borderRadius: 'radii',
    borderRightColor: 'colors',
    borderRightWidth: 'borderWidths',
    borderTopColor: 'colors',
    borderTopWidth: 'borderWidths',
    borderWidth: 'borderWidths',
    boxShadow: 'shadows',
    color: 'colors',
    fill: 'colors',
    fontFamily: 'fonts',
    fontSize: 'fontSizes',
    fontWeight: 'fontWeights',
    gridColumnGap: 'space',
    gridGap: 'space',
    gridRowGap: 'space',
    height: 'sizes',
    lineHeight: 'lineHeights',
    margin: 'space',
    marginBottom: 'space',
    marginLeft: 'space',
    marginRight: 'space',
    marginTop: 'space',
    maxHeight: 'sizes',
    maxWidth: 'sizes',
    minHeight: 'sizes',
    minWidth: 'sizes',
    outlineColor: 'colors',
    padding: 'space',
    paddingBottom: 'space',
    paddingLeft: 'space',
    paddingRight: 'space',
    paddingTop: 'space',
    stroke: 'colors',
    textShadow: 'shadows',
    width: 'sizes',
};
