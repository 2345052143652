import { Data } from '@taraai/types';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { atomic } from 'resources/theme';

import ResendButton from './ResendButton';

interface Props {
  invitation: Pick<Data.OneOffInvitation, 'email' | 'message'>;
  onResendButtonClick: (email: string, message: string) => void;
  isResendingInProgress?: boolean;
}

const InvitedUsersEntry = ({ invitation, onResendButtonClick, isResendingInProgress = false }: Props): JSX.Element => {
  const boundOnResendButtonClick = useCallback(() => onResendButtonClick(invitation.email, invitation.message), [
    invitation,
    onResendButtonClick,
  ]);

  return (
    <li
      key={invitation.email}
      className={css`
        padding: 0rem 1.3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child) {
          border-bottom: solid 0.0625rem;
          border-color: ${atomic.get(atomic.colors.grey4)};
        }
      `}
    >
      <Text
        className={css`
          font-size: 0.85rem;
          color: ${atomic.get(atomic.colors.dark)};
        `}
      >
        {invitation.email}
      </Text>
      {isResendingInProgress ? <StandardSpinner size='small' /> : <ResendButton onClick={boundOnResendButtonClick} />}
    </li>
  );
};

export default InvitedUsersEntry;
