import { compose, createSelector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import SprintDetailsLayout from 'components/app/layouts/SprintDetailsLayout';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import {
  getCompletedSprints,
  getRequirements,
  getSprint,
  getSprintTasks,
  getUpcomingSprints,
  selectTeam,
} from 'reduxStore';
import { findInIterator, getAdjacentItemsIterator } from 'tools/helpers/iterators';
import { sort } from 'tools/libraries/helpers/sort';
import { markPage, Marks } from 'tools/utils/perfmarks';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title' | 'assignedTeamIds'>;
const mapToRequirementFragment = (data: UI.UIRequirement[] = []): RequirementFragment[] =>
  data.map(({ id, title, assignedTeamIds }) => ({ id, title, assignedTeamIds }));
const filterTeamRequirements = (teamId: Data.Id.TeamId) => (
  requirements: RequirementFragment[],
): RequirementFragment[] => requirements.filter(({ assignedTeamIds }) => assignedTeamIds.includes(teamId));

/** Pass through only active sprint and sprints that are before it. */
const isSprintBeforeOrActive = (activeSprint: UI.UISprint | undefined) => (sprint: UI.UISprint): boolean => {
  if (!activeSprint) {
    // when there is no active sprint, show only completed ones
    return sprint.isComplete;
  }
  const isCurrentlyActiveSprint = activeSprint.id === sprint.id;
  const sprintStartsBeforeActive = activeSprint?.sprintNumber > sprint.sprintNumber;
  return sprintStartsBeforeActive || isCurrentlyActiveSprint;
};

/**
 * SprintDetailsController does…
 * Accesses higher level data that will be passed to multiple child components in SprintDetailsLayout
 */
export default function SprintDetailsController(): JSX.Element | null {
  const { orgID, teamID, sprintID: sprintIdParam } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
    sprintID: Data.Id.SprintId;
  }>();

  const currentSprintId = useSelector(compose((data) => data?.currentSprintId, selectTeam(orgID, teamID)));
  const sprintId = sprintIdParam === 'current' ? currentSprintId : sprintIdParam;

  const tasksSlice = getSprintTasks(orgID, sprintId ?? '');
  const selectedSlice = getSprint(orgID, sprintId ?? '');
  const activeSlice = getSprint(orgID, currentSprintId ?? '');
  const upcomingSlice = getUpcomingSprints(orgID, teamID);
  const completedSlice = getCompletedSprints(orgID, teamID, {
    orderBy: 'sprintNumber',
  });
  const requirementsSlice = getRequirements(orgID, null, {
    orderBy: 'title',
  });

  useFirestoreConnect([
    ...tasksSlice.query,
    ...selectedSlice.query,
    ...activeSlice.query,
    ...upcomingSlice.query,
    ...completedSlice.query,
    ...requirementsSlice.query,
  ]);

  const sprintTasksSelector = tasksSlice.selector;

  const sortedTasksSelector = createSelector(sprintTasksSelector, (allTasks) =>
    sort(allTasks || [], 'taskAssigneeNameStatus'),
  );

  const completedSprints = useSelector(completedSlice.selector) ?? [];
  const upcomingSprint = useSelector(upcomingSlice.selector) ?? [];
  const selectedSprint = useSelector(selectedSlice.selector);
  const sprintTasks = useSelector(sprintTasksSelector);
  const activeSprint = useSelector(activeSlice.selector);
  const requirements = useSelector(
    compose(filterTeamRequirements(teamID), mapToRequirementFragment, requirementsSlice.selector),
  );

  const sprintsToSort = activeSprint ? [...completedSprints, ...upcomingSprint] : completedSprints;

  const allSprintIdsSorted = sort(Array.from(sprintsToSort), 'sprintNumber')
    .filter(isSprintBeforeOrActive(activeSprint))
    .map((sprint) => sprint.id);

  const adjacentSprints = findInIterator(
    getAdjacentItemsIterator(allSprintIdsSorted),
    ({ current }) => current === selectedSprint?.id,
  );

  const lastCompletedFromInactiveSprint =
    !selectedSprint?.id && allSprintIdsSorted.slice(allSprintIdsSorted.length - 1)[0];

  // Consider page loaded when there is no sprint id
  const loaded = !sprintId || (isLoaded(selectedSprint) && isLoaded(sprintTasks));

  if (!isLoaded(currentSprintId)) {
    return null;
  }

  if (loaded) {
    markPage(Marks.PageSprintDetails, true);
  }

  return (
    <SprintDetailsLayout
      currentSprintId={currentSprintId}
      isLoaded={loaded}
      nextSprintID={adjacentSprints?.next || null}
      organizationID={orgID}
      previousSprintID={adjacentSprints?.prev || lastCompletedFromInactiveSprint || null}
      requirements={requirements}
      selectedSprint={selectedSprint}
      tasksSelector={sortedTasksSelector}
    />
  );
}
