import { Selector } from '@reduxjs/toolkit';
import { UISprint } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import {
  createIndividualSelector,
  generateAlias,
  IndividualQuery,
  inertIndividualQuery,
} from 'reduxStore/utils/selectors';

export type SprintSelector = Selector<RootState, UISprint | undefined>;
export type SprintListSelector = Selector<RootState, UISprint[] | undefined>;

export function getSprint(orgID: string, sprintID: string): IndividualQuery<UISprint> {
  if (!isNonEmptyString(orgID) || !isNonEmptyString(sprintID)) {
    return inertIndividualQuery();
  }
  const querySprint: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/sprints`,
    where: [['__name__', '==', sprintID]],
  };
  querySprint.storeAs = generateAlias(querySprint);

  return {
    query: [querySprint],
    selector: createIndividualSelector(sprintID, querySprint),
  };
}
