import { Fluid, HStack, Spacer, styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { RequirementSelector } from 'components/app/controllers/Selectors/RequirementSelector';
import { UserSelector } from 'components/app/controllers/Selectors/UserSelector';
import { SectionType } from 'components/core/controllers/Selector';
import React, { useEffect, useState } from 'react';
import { strings } from 'resources';

import { SelectButton } from './SelectButton';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;
type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

type Props = {
  assignees: UserFragment[];
  requirements: RequirementFragment[];
  handleFiltering: (selectedAssignees: UserFragment[], selectedRequirements: RequirementFragment[]) => void;
};

export const Filters = ({ assignees, requirements, handleFiltering }: Props): JSX.Element => {
  const [selectedAssignees, setSelectedAssignees] = useState<UserFragment[]>([]);
  const [selectedRequirements, setSelectedRequirements] = useState<RequirementFragment[]>([]);

  useEffect(() => {
    handleFiltering(selectedAssignees, selectedRequirements);
  }, [selectedAssignees, selectedRequirements, handleFiltering]);

  const requirementsSection: SectionType<RequirementFragment> = {
    id: 'requirements',
    options: requirements,
  };
  const assigneesSection: SectionType<UserFragment> = {
    id: 'assignees',
    options: assignees,
  };

  return (
    <Fluid>
      <HStack align='right'>
        <RequirementSelector
          onDeselectOption={() => setSelectedRequirements([])}
          onSelectOption={(requirement): void => setSelectedRequirements([requirement])}
          renderSelectButton={(props) => (
            <SelectButton {...props} description={strings.sprints.sprintTimeline.filterByRequirement} />
          )}
          searchPlaceholder={strings.sprints.sprintTimeline.searchRequirements}
          sections={[requirementsSection]}
          selection={selectedRequirements}
          Wrapper={SelectorWrapper}
        />
        <Spacer space='$16px' />
        <UserSelector
          deselectUser={() => setSelectedAssignees([])}
          renderSelectButton={(props) => (
            <SelectButton {...props} description={strings.sprints.sprintTimeline.filterByAssignee} />
          )}
          searchPlaceholder={strings.sprints.sprintTimeline.searchUsers}
          sections={[assigneesSection]}
          selection={selectedAssignees}
          selectUser={(assignee): void => setSelectedAssignees([assignee])}
          Wrapper={SelectorWrapper}
        />
      </HStack>
    </Fluid>
  );
};

const SelectorWrapper = styled('div', { flexGrow: 1, maxWidth: '12.5rem' });
