import { styled } from '@taraai/design-system';
import React from 'react';

import { IntegrationsPanel } from './IntegrationsPanel/IntegrationsPanel';
import { Navigation } from './Navigation';
import { ProfileSection } from './ProfileSection/ProfileSection';
import { TeamSwitcher } from './TeamSwitcher/TeamSwitcher';
import Users from './Users';
import { WorkspaceSwitcher } from './WorkspaceSwitcher/WorkspaceSwitcher';

export function GlobalNav(): JSX.Element {
  return (
    <Bar>
      <Panel>
        <WorkspaceSwitcher />
        <Navigation />
      </Panel>
      <IntegrationsPanel />
      <Panel>
        <TeamSwitcher />
        <Users />
        <ProfileSection />
      </Panel>
    </Bar>
  );
}

const Panel = styled('div', {
  display: 'flex',
});

const Bar = styled('div', {
  position: 'relative',
  justifyContent: 'space-between',
  display: 'flex',
  width: '100vw',
  height: '3rem',
  boxShadow: '$navbar',
  backgroundColor: '#ffffff',
  zIndex: 6,
});
