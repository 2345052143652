import { notNullOrUndefined } from '@taraai/utility';
import { getImageMetadata, toggleStyle } from 'components/core/controllers/Editor/helpers';
import { Style, STYLES } from 'components/core/controllers/Editor/styles';
import { ContentBlock, ContentState, Editor, EditorState, genKey, SelectionState } from 'draft-js';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { strings } from 'resources/i18n';
import { useStateCallback, useToast } from 'tools';

import EditorContext, { OnChangeFunc, UploadAttachmentFnType } from './EditorContext';

type DraftDecoratorType = Parameters<typeof EditorState.createEmpty>[0];

export type EditorProviderProps = {
  children?: JSX.Element | JSX.Element[] | string;
  decorator?: DraftDecoratorType;
};

export const EditorProvider = ({ children, decorator }: EditorProviderProps): JSX.Element => {
  const { addToast } = useToast();
  const editorRef: RefObject<Editor> = useRef(null);

  const [editorState, setEditorState] = useStateCallback<EditorState>(EditorState.createEmpty(decorator));

  const editorStateRef = useRef<EditorState>(editorState);
  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);
  const getEditorState = useCallback((): EditorState => {
    return editorStateRef.current;
  }, []);

  const focusEditor = useCallback(() => {
    editorRef.current?.focus();
  }, []);

  const setStyle = useCallback(
    (style: Style) => {
      setEditorState(toggleStyle(style), focusEditor);
    },
    [focusEditor, setEditorState],
  );

  const [uploadAttachmentFn, setUploadAttachmentFn] = useState<UploadAttachmentFnType | null>(null);

  const onChangeFn = useRef<OnChangeFunc | null>(null);
  const setOnChangeFn = useCallback((newOnChangeFn: OnChangeFunc) => (onChangeFn.current = newOnChangeFn), []);

  const setDecorator = useCallback(
    (newDecorator: DraftDecoratorType) => {
      const stateWithNewDecorator = EditorState.set(editorState, {
        decorator: newDecorator,
      });
      setEditorState(stateWithNewDecorator);
    },
    [editorState, setEditorState],
  );

  const uploadAndInjectFiles = useCallback(
    async (exactSelectionState: SelectionState | null, files: Blob[]): Promise<void> => {
      async function handleFile(currentEditorState: EditorState, fileURL: string): Promise<EditorState> {
        // Whether or not the media is an image
        const { validUrl: isImageURL } = await getImageMetadata(fileURL);
        // If the URL is an image...
        if (isImageURL) {
          const contentState = currentEditorState.getCurrentContent();
          const selectionState = exactSelectionState ?? currentEditorState.getSelection();
          // The currently selected block of the selection state
          const currentBlock = contentState.getBlockForKey(selectionState.getEndKey());
          // All the blocks in the content state of the editor
          const blockMap = contentState.getBlockMap();
          // The blocks before the current block
          const blocksBefore = blockMap.toSeq().takeUntil((block) => block === currentBlock);
          // The blocks after the current block
          const blocksAfter = blockMap
            .toSeq()
            .skipUntil((block) => block === currentBlock)
            .rest();
          const block1Key = genKey();
          const block2Key = genKey();
          const block3Key = genKey();
          // This creates three blocks, 2 empty spaces above and below the
          // image, and an image block in the middle. This prevents a few bugs:
          // 1. Doesn't override any existing content in the block images will
          // be inserted into
          // 2. Draft has a bug where if there are no blocks with cursors above
          // or below an un-editable block (like images), the user can get stuck
          // and not be able to insert any new content, above or below the image
          // 3. If the user only inserts an image, the entire editor becomes
          // un-editable and un-usuable, unless these two empty blocks are
          // above and below the image.
          const newBlocks = [
            [currentBlock.getKey(), currentBlock],
            [
              block1Key,
              new ContentBlock({
                key: block1Key,
                type: STYLES.BLOCK.UNSTYLED.value,
                text: '',
                data: {},
              }),
            ],
            [
              block2Key,
              new ContentBlock({
                key: block2Key,
                type: STYLES.BLOCK.ATOMIC.value,
                text: `![image](${fileURL})`,
                data: {
                  alt: 'image',
                  src: fileURL,
                },
              }),
            ],
            [
              block3Key,
              new ContentBlock({
                key: block3Key,
                type: STYLES.BLOCK.UNSTYLED.value,
                text: '',
                data: {},
              }),
            ],
          ];
          // The new final block map with all the old and new blocks.
          const newBlockMap = blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();
          const newContentState = contentState.merge({
            blockMap: newBlockMap,
            selectionBefore: selectionState,
            selectionAfter: selectionState,
          }) as ContentState;
          // Return the new editor state with the new blocks and old selection
          return EditorState.forceSelection(
            EditorState.push(currentEditorState, newContentState, 'insert-characters'),
            selectionState,
          );
        }
        // ...else return the existing editor state
        return currentEditorState;
      }

      const URLs: string[] = (
        await Promise.all(
          files.map(
            async (file: Blob): Promise<string | undefined> => {
              // Attached media firebase URL
              return uploadAttachmentFn?.(file as File).catch(() => undefined);
            },
          ),
        )
      ).filter(notNullOrUndefined);

      return URLs.reduce(async (previousPromise, nextURL) => {
        const nextEditorState = await previousPromise;
        return handleFile(nextEditorState, nextURL);
      }, Promise.resolve(editorStateRef.current))
        .then(onChangeFn?.current ?? setEditorState)
        .catch(() => {
          addToast({
            message: strings.editor.filesNotUploaded,
            timeoutMs: 5500,
            type: 'error',
          });
        });
    },
    [setEditorState, uploadAttachmentFn, addToast],
  );

  const uploadAndInjectPastedFiles = useCallback((files: Blob[]) => uploadAndInjectFiles(null, files), [
    uploadAndInjectFiles,
  ]);

  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [addLinkPopperOpen, setAddLinkPopperOpen] = useState(false);

  return (
    <EditorContext.Provider
      value={{
        onChangeFn,
        editorRef,
        editorState,
        focusEditor,
        setEditorState,
        setStyle,
        uploadAndInjectFiles,
        uploadAndInjectPastedFiles,
        setUploadAttachmentFn,
        setOnChangeFn,
        getEditorState,
        readOnlyMode,
        setReadOnlyMode,
        setDecorator,
        addLinkPopperOpen,
        setAddLinkPopperOpen,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};
