import Tara, { Data } from '@taraai/types';
import ProgressRow from 'components/app/controllers/views/ProgressRow';
import moment from 'moment';
import React from 'react';
import { daysFromEpoch, toDate } from 'tools/libraries/helpers/dates';
import { sort } from 'tools/libraries/helpers/sort';

export interface ProgressControllerProps {
  status: number;
  history: Tara.StatusHistory[];
  sprint: Data.Sprint;
  dates: string[];
  gitStatus: string | null;
}

/**
 * GanttChartProgressController does…
 *
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function ProgressController({
  history = [],
  sprint,
  status,
  gitStatus,
  dates,
}: ProgressControllerProps): JSX.Element | null {
  const startDate = sprint.initialStartDate;

  // The mapping in the end of the function is not possible
  // if the sprint hasn't started yet.
  if (!startDate) {
    return null;
  }

  if (history.length > 0) {
    const dayStatus = {} as Record<number | string, Tara.StatusHistory>;
    history.forEach((entry) => {
      const historyDay = daysFromEpoch(entry.updatedAt);
      if (entry.status === 1) {
        dayStatus[`${historyDay} doing`] = entry;
      } else {
        dayStatus[historyDay] = entry;
      }
    });

    const newHistory = sort(Object.values(dayStatus), 'updatedAt');
    /**
     * loop over history and create key value hash with each key being timestamp/60/60/24 and each value being status history object value
     * [{status: 0, updatedAt: 242511, updatedBy: vijay}, {status: 0, updatedAt: 242511, updatedBy: bob}, {status: 0, updatedAt: 242511, updatedBy: john}]
     */

    // FIXME: there are many algebraic operations on `number` and `undefined`.
    // All of them produce `NaN`.
    return (
      <>
        {newHistory.map((item: Tara.StatusHistory, index: number) => {
          if (item.status === 1) {
            const currentHistoryStatus = item;
            const nextHistoryStatus = newHistory[index + 1] ?? {};
            let succeedingCurrentHistoryStatus: Tara.StatusHistory | undefined;
            let nextDoingStart;

            newHistory.forEach((entry) => {
              if (entry.status === 1) {
                succeedingCurrentHistoryStatus = entry;
              }
            });

            if (currentHistoryStatus.status === nextHistoryStatus.status) {
              return null;
            }

            const currentDate = moment(Date.now());
            const doingStart = moment(toDate(currentHistoryStatus?.updatedAt));

            const initialStart = moment(toDate(startDate));

            if (succeedingCurrentHistoryStatus) {
              nextDoingStart = moment(toDate(succeedingCurrentHistoryStatus.updatedAt));
            }

            const completeDate = nextHistoryStatus.status === 2 ? moment(toDate(nextHistoryStatus.updatedAt)) : null;
            const stopDate = nextHistoryStatus.status === 0 ? moment(toDate(nextHistoryStatus.updatedAt)) : null;

            if (completeDate && completeDate < initialStart) {
              return null;
            }

            if (stopDate && stopDate < initialStart) {
              return null;
            }

            let startDiff = doingStart.endOf('day').diff(initialStart, 'days');

            let nextDoingDiff = nextDoingStart && nextDoingStart.endOf('day').diff(initialStart, 'days');

            if (startDiff < 0) {
              startDiff = 0;
            }

            if (nextDoingDiff && nextDoingDiff < 0) {
              nextDoingDiff = 0;
            }

            // Set to TODO status by default
            // @TODO Rewrite to function without any lets
            let currentStatusColor = '#eef1f7';
            let statusContent = 'To Do';
            let color = '#000000';

            if (status === 1) {
              currentStatusColor = '#ddf0ff';
              statusContent = 'Doing';
              color = '#1d98ff';
            }

            if (status === 2) {
              currentStatusColor = '#ddf4d9';
              statusContent = 'Done';
              color = '#389e0d';
            }

            const currentDiff = currentDate.endOf('day').diff(initialStart, 'days');
            const completeDiff = completeDate && completeDate.endOf('day').diff(initialStart, 'days');

            const stopDiff = stopDate && stopDate.endOf('day').diff(initialStart, 'days');

            const startMarker = startDiff + 2;
            const nextDoingMarker = typeof nextDoingDiff == 'number' && nextDoingDiff + 2;
            const currentMarker = currentDiff + 3;
            let completeMarker = typeof completeDiff == 'number' && completeDiff + 3;
            let stopMarker = typeof stopDiff == 'number' && stopDiff + 3;

            if (typeof stopMarker == 'number' && stopMarker - 1 === nextDoingMarker) {
              stopMarker -= 1;
            }

            if (typeof completeMarker == 'number' && completeMarker - 1 === nextDoingMarker) {
              completeMarker -= 1;
            }

            if (startMarker >= dates.length + 2) {
              return null;
            }

            if (typeof completeMarker == 'number' && completeMarker < startMarker) {
              return null;
            }

            if (typeof stopMarker == 'number' && stopMarker <= startMarker) {
              return null;
            }

            if (typeof completeMarker == 'number') {
              return (
                <ProgressRow
                  color={color}
                  end={completeMarker}
                  gitStatus={gitStatus}
                  start={startMarker}
                  statusColor={currentStatusColor}
                  statusContent={statusContent}
                />
              );
            }

            if (typeof stopMarker == 'number') {
              return (
                <ProgressRow
                  color={color}
                  end={stopMarker}
                  gitStatus={gitStatus}
                  start={startMarker}
                  statusColor={currentStatusColor}
                  statusContent={statusContent}
                />
              );
            }

            if (currentMarker > startMarker) {
              return (
                <ProgressRow
                  color={color}
                  end={currentMarker}
                  gitStatus={gitStatus}
                  start={startMarker}
                  statusColor={currentStatusColor}
                  statusContent={statusContent}
                />
              );
            }
            return null;
          }
          return null;
        })}
      </>
    );
  }
  return null;
}
