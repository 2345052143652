import { UI } from '@taraai/types';
import { strings } from 'resources/i18n';
import { formatFromNow } from 'tools';

export const formatUpdateStatus = (requirement: UI.UIRequirement): string => {
  return strings.formatString(strings.builder.header.updateStatus, {
    author: requirement.authorDocument.name,
    date: formatFromNow(requirement.updatedAt),
  }) as string;
};
