/* eslint-disable sonarjs/cognitive-complexity */
import { Data, UI } from '@taraai/types';
import AvatarListController from 'components/app/controllers/AvatarListController';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CompleteSprintData } from 'reduxStore/sprints/queries/complete-sprint-data';
import { sprintsTabTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { formatDMMM, toDate } from 'tools';

import { SprintDetailsNavigation } from './SprintDetailsNavigation';

export interface SprintDetailsHeaderProps {
  selectedSprint?: UI.UISprint;
  organizationID: Data.Id.OrganizationId;
  nextSprintID: Data.Id.SprintId | null;
  previousSprintID: Data.Id.SprintId | null;
  completeSprintData?: CompleteSprintData;
}

function getTasksDoneStats(totalTasksNumber: number | null, totalTasksCompletedNumber: number): string | string[] {
  if (totalTasksNumber !== null) {
    return strings.formatString(strings.sprints.completedHeader.taskValues, {
      total: totalTasksNumber.toString(),
      done: totalTasksCompletedNumber.toString(),
    });
  }

  if (totalTasksCompletedNumber === 1) {
    return strings.sprints.completedHeader.onlyCompletedTaskValuesSingular;
  }
  return strings.formatString(strings.sprints.completedHeader.onlyCompletedTaskValuesPlural, {
    done: totalTasksCompletedNumber.toString(),
  });
}

/**
 * Render the sprint number, complete status, and date filter which will update
 * the top level state and render the filtered data for SprintDetailsTimeline
 */
export default function SprintDetailsHeader({
  selectedSprint,
  organizationID,
  nextSprintID,
  previousSprintID,
  completeSprintData,
}: SprintDetailsHeaderProps): JSX.Element {
  const { teamID } = useParams<{ teamID: Data.Id.TeamId }>();
  const history = useHistory();

  function navigateToSprint(event: React.SyntheticEvent): void {
    event.preventDefault();
    if (!selectedSprint?.id) {
      return;
    }
    history.push(
      linkTo('sprintDetailsEdit', {
        orgID: organizationID,
        teamID,
        sprintID: selectedSprint.id,
      }),
    );
  }

  function completeSprint(event: React.SyntheticEvent): void {
    event.preventDefault();
    if (!selectedSprint?.id) {
      return;
    }
    history.push(
      linkTo('sprintDetailsComplete', {
        orgID: organizationID,
        teamID,
        sprintID: selectedSprint.id,
      }),
    );
  }

  const {
    allPullRequestsCount = 0,
    closedPullRequestsCount = 0,
    totalEffortCompleted = 0,
    effortCompletedPercent = 0,
    sprintTasks,
    totalCommits = 0,
    totalEffortEstimated = 0,
    totalTasksCompleted = 0,
    totalTasks = 0,
  } = completeSprintData ?? {};

  const completeSprintButton = !selectedSprint?.initialEndDate
    ? false
    : toDate(selectedSprint?.initialEndDate).getDate() <= new Date(Date.now()).getDate();

  return (
    <div>
      <div
        className={css`
          height: 3.6875rem;
          display: grid;
          align-items: center;
          grid-template-columns: 1fr repeat(1, auto) 1fr;
          div:first-child {
            grid-column-start: 2;
          }
          div:last-child {
            margin-left: auto;
          }
          border-bottom: ${atomic.get(atomic.colors.grey3)} solid 0.0625rem;
        `}
      >
        <div>
          <SprintDetailsNavigation
            nextSprintID={nextSprintID}
            organizationID={organizationID}
            previousSprintID={previousSprintID}
            selectedSprint={selectedSprint}
          >
            {selectedSprint ? selectedSprint?.sprintName : strings.sprints.noActiveSprints.header}
          </SprintDetailsNavigation>
        </div>
        {selectedSprint && (
          <div
            className={css`
              display: flex;
              align-items: center;
            `}
          >
            <Text
              className={css`
                ${atomic.color(atomic.colors.grey6)}
              `}
              h4
            >
              {selectedSprint ? formatDMMM(selectedSprint.initialStartDate) : ''}
              {strings.sprints.sprintColumn.sprintDateSpace}
              {selectedSprint ? formatDMMM(selectedSprint.initialEndDate) : ''}
            </Text>
            {selectedSprint.isComplete ? (
              <a
                className={css`
                  font-weight: 500;
                  margin-left: 1rem;
                  align-self: center;
                  font-size: 0.875rem;
                  text-decoration: none;
                  ${atomic.color(atomic.theme.color.success)};
                  :hover {
                    ${atomic.color(atomic.theme.color.success)};
                  }
                `}
                href={['/', organizationID].join('')}
                onClick={navigateToSprint}
              >
                <Icon
                  className={css`
                    width: 0.875rem;
                    height: 0.75rem;
                    padding: 0rem;
                    margin-right: 0.3125rem;
                    margin-bottom: -0.1406rem;
                  `}
                  color='white'
                  name='tick'
                />
                {strings.sprints.completed}
              </a>
            ) : (
              <Button
                className={css`
                  @keyframes pulse {
                    0% {
                      transform: scale(0.95);
                    }
                    70% {
                      transform: scale(1);
                      box-shadow: 0 0 0 5px rgba(71, 167, 0, 0.4);
                    }
                    100% {
                      transform: scale(0.95);
                      box-shadow: 0 0 0 5px rgba(71, 167, 0, 0.4);
                    }
                  }
                  padding: 0rem;
                  background-color: ${completeSprintButton
                    ? atomic.get(atomic.colors.success)
                    : atomic.get(atomic.colors.grey2)};
                  margin: 0rem;
                  margin-left: 1rem;
                  max-height: 1.6875rem;
                  min-height: 1.6875rem;
                  min-width: 0rem;
                  outline: 0;
                  width: 5.8125rem;
                  font-size: 0.875rem;
                  font-weight: 500;
                  color: ${completeSprintButton ? '#ffffff' : '#67728b'};
                  -webkit-animation: ${completeSprintButton ? 'pulse 1.5s infinite' : 'none'};
                  transition: all 0.3s;
                  box-shadow: 0 0 0 0 rgba(#47a700, 0.5);
                  :hover {
                    background-color: ${completeSprintButton
                      ? atomic.get(atomic.colors.success)
                      : atomic.get(atomic.colors.grey2)};
                    -webkit-animation: none;
                  }
                `}
                data-cy={sprintsTabTestIDs.COMPLETE_SPRINT}
                onClick={completeSprint}
              >
                {strings.sprints.sprintDetails.completeSprint}
              </Button>
            )}
          </div>
        )}
      </div>
      {selectedSprint?.isComplete && (
        <div
          className={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          <div
            className={css`
              flex-grow: 1;
              margin: 2.25rem 0;
            `}
          >
            <br />
            <span aria-label={strings.sprints.completedHeader.rocket.label} role='img'>
              <Text h1>{strings.sprints.completedHeader.rocket.emoji}</Text>
            </span>
            <Text
              className={css`
                margin-top: 0.5rem;
                margin-bottom: 1rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                ${atomic.color(atomic.colors.greySlate)};
              `}
              h3
            >
              {strings.sprints.completedHeader.header}
            </Text>
            <AvatarListController userIDs={sprintTasks?.map((task) => task.assignee)} />
          </div>
          <div
            className={css`
              flex-grow: 1;
              display: flex;
              flex-wrap: wrap;
              max-width: 30.5rem;
              justify-content: space-evenly;
              margin-top: 2.5rem;
            `}
          >
            <div
              className={css`
                flex-grow: 1;
                display: flex;
                min-width: 13.75rem;
                max-width: 13.75rem;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 0.1875rem;
                box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.08);
                background-color: var(--white);
                padding: 1.5rem 0rem;
                margin-left: 1rem;
              `}
            >
              <div
                className={css`
                  width: 3.125rem;
                  background-color: ${atomic.get(atomic.colors.success)};
                  height: 3.125rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 100%;
                  margin-bottom: 1rem;
                `}
              >
                <Text
                  className={css`
                    font-size: 2rem;
                    padding-top: 0.25rem;
                    color: white;
                    font-weight: 300;
                  `}
                >
                  <Icon
                    className={css`
                      width: 1.5625rem;
                      height: 1.2312rem;
                      padding: 0rem;
                      color: #ffffff;
                    `}
                    name='tick'
                  />
                </Text>
              </div>
              <Text
                className={css`
                  font-size: 0.75rem;
                  font-weight: 500;
                  ${atomic.color(atomic.colors.greySlate)};
                `}
              >
                {strings.sprints.completedHeader.effortCompleted}
              </Text>
              <Text
                className={css`
                  margin-top: 0.5rem;
                  margin-bottom: 0.375rem;
                  font-size: 1.125rem;
                  font-weight: 600;
                  ${atomic.color(atomic.colors.dark)};
                `}
              >
                {strings.formatString(strings.sprints.completedHeader.values, {
                  total: totalEffortEstimated,
                  completed: totalEffortCompleted,
                  percent: +effortCompletedPercent || 0,
                })}
              </Text>
              <Text
                className={css`
                  font-size: 0.75rem;
                  font-weight: normal;
                  ${atomic.color(atomic.colors.grey6)};
                `}
              >
                {getTasksDoneStats(totalTasks, totalTasksCompleted)}
              </Text>
            </div>
            <div
              className={css`
                flex-grow: 1;
                display: flex;
                min-width: 13.75rem;
                max-width: 13.75rem;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 0.1875rem;
                box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.08);
                background-color: var(--white);
                padding: 1.5rem 0rem;
                margin-left: 1rem;
              `}
            >
              <div
                className={css`
                  width: 3.125rem;
                  height: 3.125rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 1rem;
                `}
              >
                <Icon
                  className={css`
                    width: 3.125rem;
                    height: 3.125rem;
                  `}
                  color='#6F42C1'
                  name='merge'
                />
              </div>
              <Text
                className={css`
                  font-size: 0.75rem;
                  font-weight: 500;
                  ${atomic.color(atomic.colors.greySlate)};
                `}
              >
                {strings.sprints.completedHeader.prsClosed}
              </Text>
              <Text
                className={css`
                  font-size: 1.125rem;
                  font-weight: 600;
                  margin-top: 0.5rem;
                  margin-bottom: 0.375rem;
                  ${atomic.color(atomic.colors.dark)};
                `}
              >
                {strings.formatString(strings.sprints.completedHeader.values, {
                  percent: +Math.round((closedPullRequestsCount / allPullRequestsCount) * 100) || 0,
                  total: allPullRequestsCount,
                  completed: closedPullRequestsCount,
                })}
              </Text>
              <Text
                className={css`
                  font-size: 0.75rem;
                  font-weight: normal;
                  ${atomic.color(atomic.colors.grey6)};
                `}
              >
                {strings.formatString(strings.sprints.completedHeader.prCommits, {
                  number: totalCommits,
                })}
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
