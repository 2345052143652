import { createSelector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { Selector } from 'react-redux';
import { getRequirement } from 'reduxStore/requirements';
import { RootState, RootStateWithProfile } from 'reduxStore/store';
import { selectProfile } from 'reduxStore/users';

import { getOrgTeams } from './queries/getOrgTeams';

/**
 * Returns the preferred team ID or falls back to the first available team that user belongs to in current organization
 */

export type TeamSelector = Selector<RootState, UI.UITeam | undefined>;

export function selectHasPreferredTeam(
  orgId: Data.Id.OrganizationId | undefined,
): Selector<RootStateWithProfile, boolean> {
  return createSelector(selectProfile, (profile) => typeof (orgId && profile.preferredTeamIds?.[orgId]) === 'string');
}

export function selectPreferredTeamId(orgId: Data.Id.OrganizationId): Selector<RootStateWithProfile, Data.Id.TeamId>;
export function selectPreferredTeamId(
  orgId?: Data.Id.OrganizationId,
): Selector<RootStateWithProfile, Data.Id.TeamId | undefined>;
export function selectPreferredTeamId(
  orgId: Data.Id.OrganizationId | undefined,
): Selector<RootStateWithProfile, Data.Id.TeamId | undefined> {
  return createSelector(
    selectProfile,
    (profile) => orgId && (profile?.preferredTeamIds?.[orgId] || profile?.teamIds?.[orgId]?.[0]),
  );
}
/**
 * Team document selector.
 *
 */

export const selectTeam = (
  orgId: Data.Id.OrganizationId,
  teamId: Data.Id.TeamId,
): Selector<RootState, UI.UITeam | undefined> =>
  createSelector(getOrgTeams(orgId).selector, (teams: UI.UITeam[] = []) => teams.find((team) => teamId === team.id));

export const selectPreferredTeam = (
  orgID: Data.Id.OrganizationId,
): Selector<RootStateWithProfile, UI.UITeam | undefined> =>
  createSelector(getOrgTeams(orgID).selector, selectProfile, (teams: UI.UITeam[] = [], profile) => {
    const preferred = teams.find((team) => profile.preferredTeamIds[orgID] === team.id);
    const preferredTeamId = profile.preferredTeamIds[orgID];
    if (!preferred) {
      const message = `team with preferredTeamId: ${preferredTeamId} doesn't exist`;
      // this situation should never happened
      throw new Error(message);
    }

    return preferred;
  });

export const selectUserTeams = (orgID: Data.Id.OrganizationId): Selector<RootStateWithProfile, UI.UITeam[]> =>
  createSelector(getOrgTeams(orgID).selector, selectProfile, (teams: UI.UITeam[] = [], profile) =>
    teams.filter((team) => profile.teamIds[orgID].indexOf(team.id) >= 0),
  );
export const selectAllTeams = (orgID: Data.Id.OrganizationId): Selector<RootStateWithProfile, UI.UITeam[]> =>
  createSelector(getOrgTeams(orgID).selector, selectProfile, (teams: UI.UITeam[] = [], profile) =>
    teams.filter((team) => profile.teamIds[orgID]),
  );
export const selectOtherTeams = (orgID: Data.Id.OrganizationId): Selector<RootStateWithProfile, UI.UITeam[]> =>
  createSelector(getOrgTeams(orgID).selector, selectProfile, (teams: UI.UITeam[] = [], profile) =>
    teams.filter((team) => profile.teamIds[orgID].indexOf(team.id) < 0),
  );
export const selectCurrentTeam = (
  orgID: Data.Id.OrganizationId,
  currentTeamID: Data.Id.TeamId,
): Selector<RootState, UI.UITeam | undefined> =>
  createSelector(getOrgTeams(orgID).selector, (teams: UI.UITeam[] = []) =>
    teams.find((team) => currentTeamID === team.id),
  );

export const selectRequirementAssignedTeams = (
  orgId: Data.Id.OrganizationId,
  requirementId: Data.Id.RequirementId,
): Selector<RootState, UI.UITeam[]> =>
  createSelector(
    getOrgTeams(orgId).selector,
    getRequirement(orgId, requirementId).selector,
    (teams = [], requirement) => teams.filter((team) => requirement?.assignedTeamIds.includes(team.id)),
  );
