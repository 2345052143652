import { Popper, PopperProps } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources';
import { strings } from 'resources/i18n';

import DraftLinkAnchor from './DraftLinkAnchor';
import DraftLinkPopperButton from './DraftLinkPopperButton';

type DraftLinkPopperProps = {
  anchorEl?: PopperProps['anchorEl'];
  editable: boolean;
  onCopy(): void;
  onEditLink(): void;
  onRemove(): void;
  open: boolean;
  url: string;
};

export default function DraftLinkPopper({
  anchorEl,
  onCopy,
  onEditLink,
  onRemove,
  open,
  editable,
  url,
}: DraftLinkPopperProps): JSX.Element {
  return (
    <Popper
      anchorEl={anchorEl}
      className={css`
        background-color: ${atomic.get(atomic.colors.white)};
        border: ${atomic.get(atomic.colors.black)};
        margin-top: 10px;
        padding: 0.7rem 1rem;
        box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 3px 6px -4px rgba(0, 0, 0, 0.18);
        z-index: 1000;
      `}
      open={open}
      placement='bottom'
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <DraftLinkAnchor
          className={css`
            color: ${atomic.get(atomic.colors.secondary)};
            font-size: 0.88rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            margin-right: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 10rem;

            :hover {
              cursor: pointer;
              opacity: 0.7;
              text-decoration: underline;
            }
          `}
          href={url}
          title={url}
        >
          {url}
        </DraftLinkAnchor>
        <DraftLinkPopperButton
          className={css`
            color: ${atomic.get(atomic.colors.grey6)};
            width: 0.9rem;
            height: 0.9rem;
          `}
          iconName='linkcopy'
          onClick={onCopy}
          title={strings.editor.links.copy}
        />
        {editable && (
          <DraftLinkPopperButton
            className={css`
              color: ${atomic.get(atomic.colors.grey6)};
              width: 0.75rem;
              height: 0.75rem;
              margin-left: 1rem;
              margin-right: 1rem;
            `}
            iconName='pencil'
            onClick={onEditLink}
            title={strings.editor.links.edit}
          />
        )}
        {editable && (
          <DraftLinkPopperButton
            className={css`
              color: ${atomic.get(atomic.colors.grey6)};
            `}
            iconName='linkremove'
            onClick={onRemove}
            title={strings.editor.links.remove}
          />
        )}
      </div>
    </Popper>
  );
}
