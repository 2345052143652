"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
exports.default = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    if (typeof value !== 'string') {
        return ts_data_json_1.err(dateError(value));
    }
    var unixTimestamp = Date.parse(value);
    if (isNaN(unixTimestamp)) {
        return ts_data_json_1.err(dateError(value));
    }
    return ts_data_json_1.ok(new Date(unixTimestamp));
});
function dateError(value) {
    return "Failed to decode " + value + " as a Date";
}
