import { FormProps } from 'components/app/Onboarding/FlowWrapper';
import { Description, Header, NextButton, Wrapper } from 'components/app/Onboarding/Form';
import React from 'react';
import { strings } from 'resources';
import { workspaceSetupTestIDs } from 'resources/cypress/testAttributesValues';

export const Form = ({ onNext }: FormProps): JSX.Element => (
  <Wrapper>
    <Description>
      <Header>{strings.onboarding.moveTasksForm.header}</Header>
    </Description>
    <NextButton data-cy={workspaceSetupTestIDs.THIRD_STEP_NEXT_BUTTON} onClick={onNext}>
      {strings.onboarding.moveTasksForm.submit}
    </NextButton>
  </Wrapper>
);
