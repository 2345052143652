import 'react-datepicker/dist/react-datepicker.css';

import { compose, unwrapResult } from '@reduxjs/toolkit';
import { Data, Timestamp } from '@taraai/types';
import { noop } from '@taraai/utility';
import UpdateSprintModal from 'components/app/controllers/views/UpdateSprintModal';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  estimatedEffortSelector,
  getCompletedSprints,
  getSprint,
  getSprintTasks,
  reduxStore,
  selectTeam,
  sprintTasksUnassigned,
  totalEffortSelector,
  updateSprint,
} from 'reduxStore';
import { startSprint } from 'reduxStore/sprints/actions/start';
import strings from 'resources/i18n';
import { funcLog, toTimestamp, useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

const renderNewSprintPreview = (sprintName?: string): string => {
  if (!sprintName) return '';
  const splits = sprintName.split(/(\d+)$/);
  const onlySprintName = splits[0];
  const onlySprintNumber = parseInt(splits[1], 10) || 1;
  return strings.formatString(strings.sprintsSettings.newSprintNumber, {
    sprintNum1: `${onlySprintName} ${onlySprintNumber + 1}`,
    sprintNum2: `${onlySprintName} ${onlySprintNumber + 2}`,
    sprintNum3: `${onlySprintName} ${onlySprintNumber + 3}`,
  }) as string;
};

/**
 * UpdateSprintController
 * sprint controller that gets all data for start and edit sprint modals
 *
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function UpdateSprintController(): JSX.Element | null {
  /**
   * @TODO: It would be much easier to work with 2 separate controllers:
   *  - EditSprintController
   *  - StartSprintController
   **/
  const location = useLocation();
  const isStartSprintPage = location.pathname.includes('start');

  const { orgID, teamID, sprintID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
    sprintID: Data.Id.SprintId;
  }>();

  const history = useHistory();
  const { addToast } = useToast();

  const sprintSlice = getSprint(orgID, sprintID ?? '');
  const taskSlice = getSprintTasks(orgID, sprintID ?? '');
  const unassignedTaskSlice = sprintTasksUnassigned(orgID, sprintID ?? '');
  const completedSlice = getCompletedSprints(orgID, teamID);

  useFirestoreConnect([
    ...sprintSlice.query,
    ...taskSlice.query,
    ...unassignedTaskSlice.query,
    ...completedSlice.query,
  ]);

  const sprintDurationWeeks = useSelector(
    compose((data) => data?.sprintSettings.durationWeeks, selectTeam(orgID, teamID)),
  );
  const allSprintTasks = useSelector(taskSlice.selector);
  const unassignedSprintTasks = useSelector(unassignedTaskSlice.selector);
  const estimatedEffort = useSelector(estimatedEffortSelector(completedSlice.selector, sprintSlice.selector));
  const currentSprint = useSelector(sprintSlice.selector);
  const totalEffort = useSelector(totalEffortSelector(sprintSlice.selector, taskSlice.selector));

  const [startDateTimestamp, setStartDateTimestamp] = useState<Timestamp>();
  const [endDateTimestamp, setEndDateTimestamp] = useState<Timestamp>();
  const [sprintName, setSprintName] = useState('');
  const [inputIsInvalid, setInputIsInvalid] = useState(false);

  // validation checker till we refactor sprint actions to use ajv validation
  useEffect(() => {
    if (sprintName !== undefined && startDateTimestamp !== undefined) {
      if (sprintName.length < 2 || !startDateTimestamp) setInputIsInvalid(true);
      else {
        setInputIsInvalid(false);
      }
    }
  }, [sprintName, startDateTimestamp]);

  const setStartAndEndTimestamps = useCallback((startDate: Timestamp, endDate: Timestamp): void => {
    setStartDateTimestamp(startDate);
    setEndDateTimestamp(endDate);
  }, []);

  const handleSaveDate = useCallback((): void => {
    reduxStore
      .dispatch(
        updateSprint({
          id: sprintID,
          initialStartDate: startDateTimestamp,
          initialEndDate: endDateTimestamp,
          sprintName,
        }),
      )
      .then(unwrapResult)
      .then(() => {
        return history.goBack();
      })
      .catch((error: Error) => {
        const message = strings
          .formatString(strings.sprints.error.updateFailure, {
            errorMassage: error.message,
          })
          .toString();
        addToast({
          message,
          type: 'error',
        });
      });
  }, [sprintID, startDateTimestamp, endDateTimestamp, sprintName, history, addToast]);

  const handleStartSprint = useCallback(() => {
    if (!startDateTimestamp || !endDateTimestamp) {
      funcLog('Both startDate and endDate have to be defined to start a sprint.');
      return;
    }
    reduxStore
      .dispatch(
        startSprint({
          id: sprintID,
          initialStartDate: toTimestamp(startDateTimestamp),
          initialEndDate: toTimestamp(endDateTimestamp),
          sprintName,
        }),
      )
      .then(() => {
        segment.track('SprintStarted', {
          orgID,
          sprintID,
        });
      })
      .catch(noop);
    history.goBack();
  }, [startDateTimestamp, endDateTimestamp, sprintID, sprintName, history, orgID]);

  if (!isLoaded(currentSprint) || !isLoaded(sprintDurationWeeks)) {
    return null;
  }

  return (
    <UpdateSprintModal
      allSprintTasks={allSprintTasks}
      currentDurationWeeks={sprintDurationWeeks ?? 0}
      currentSprint={currentSprint}
      estimatedEffort={estimatedEffort}
      inputIsInvalid={inputIsInvalid}
      isStartSprintPage={isStartSprintPage}
      onSubmit={isStartSprintPage ? handleStartSprint : handleSaveDate}
      renderNewSprintPreview={renderNewSprintPreview}
      setSprintName={setSprintName}
      setStartAndEndTimestamps={setStartAndEndTimestamps}
      totalEffort={totalEffort}
      unassignedSprintTasks={unassignedSprintTasks}
    />
  );
}
