import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';

export const getTaskBranchSummaries = (
  orgID: Data.Id.OrganizationId,
  taskSlug: Data.Id.TaskSlug,
): Query<UI.UIBranchSummary> => {
  if (![orgID, taskSlug].every(isNonEmptyString)) {
    return inertQuery();
  }

  const taskBranchSummaryQuery: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/tasks-branches-summaries`,
    where: [['taskSlug', '==', taskSlug]],
    storeAs: `org-${orgID}-task-${taskSlug}-branches-summaries`,
  };

  return {
    query: [taskBranchSummaryQuery],
    selector: createStandardSelector(taskBranchSummaryQuery),
  };
};
