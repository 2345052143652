import { Box, HStack, Spacer, styled } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { MergeableState } from '@taraai/types/src/data/pull-request';
import LegacyFeatureCard from 'components/app/controllers/LegacyFeatureCard';
import ProgressController from 'components/app/controllers/ProgressController';
import SprintEffort from 'components/app/controllers/SprintEffort';
import GanttChartHeader from 'components/app/controllers/views/GanttChartHeader';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

import { Filters } from './Filters';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;
type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

interface Props {
  sprint: UI.UISprint;
  dates: string[];
  tasks: UI.UITask[];
  today: string;
  orgID: Data.Id.OrganizationId;
  assignees: UserFragment[];
  requirements: RequirementFragment[];
  handleFiltering: (selectedAssignees: UserFragment[], selectedRequirements: RequirementFragment[]) => void;
  toggleModal: (task: UI.UITask, tasks: UI.UITask[]) => void;
}

export function mapStatusesToPRStateString(statuses: Data.PullRequestMergeableState[]): string | null {
  const mergeableState = getMergeableState(statuses);

  switch (mergeableState) {
    case null:
      return null;
    case 'blocked':
      return strings.sprints.sprintDetails.gitStatus.blocked;
    case 'merged':
      return strings.sprints.sprintDetails.gitStatus.merged;
    default:
      return strings.sprints.sprintDetails.gitStatus.pending;
  }
}

function getMergeableState(statuses: Data.PullRequestMergeableState[]): MergeableState | null {
  if (statuses.some((status) => status === 'blocked')) {
    return 'blocked';
  }
  if (statuses.every((status) => status === 'merged')) {
    return 'merged';
  }
  return 'pending';
}

export const getCommitCountString = (commits?: number): string | null => {
  const commitCount = commits ?? null;
  const commitCountString =
    commitCount === 1
      ? strings.sprints.sprintDetails.gitStatus.commitCount
      : strings.sprints.sprintDetails.gitStatus.commitsCount;

  if (typeof commitCount !== 'number') return null;

  return strings.formatString(commitCountString, { commitCount }) as string;
};

export const getGitStatus = (task: UI.UITask): string | null => {
  // Do not show git status for closed pull requests
  const statuses = Object.values(task.gitStatus?.pullRequests ?? {}).filter((status) => status !== 'closed');

  return statuses.length ? mapStatusesToPRStateString(statuses) : getCommitCountString(task.gitStatus?.commits);
};

/**
 * GanttChart displays the sprint timeline
 *
 */
export default function GanttChart({
  sprint,
  tasks = [],
  dates,
  orgID,
  today,
  assignees,
  requirements,
  handleFiltering,
  toggleModal,
  ...props
}: Props): JSX.Element {
  const gridTemplateColumns = { gridTemplateColumns: `40% repeat(${dates.length}, 1fr)` };

  return (
    <Container>
      <GanttChartHeader />
      <GanttChartTable {...props}>
        <GanttChartTableHeader style={gridTemplateColumns}>
          <TasksDetails space={['$8px', '$8px', '$8px', '$12px']}>
            <HStack alignY='center'>
              <TasksAmount>
                {strings.formatString(strings.sprints.sprintTimeline.tasks, {
                  count: tasks.length,
                })}
              </TasksAmount>
              <Spacer space='$16px' />
              <SprintEffort currentSprint={sprint} isTextHidden orgID={orgID} tasks={tasks} />
              <Spacer space='$16px' />
              <Filters assignees={assignees} handleFiltering={handleFiltering} requirements={requirements} />
            </HStack>
          </TasksDetails>
          {dates.map((date) => (
            <DateWrapper key={date} today={date === today}>
              <Day>{date.split(' ')[2]}</Day>
              <br />
              <Date>{date.slice(0, -4)}</Date>
            </DateWrapper>
          ))}
        </GanttChartTableHeader>
        <VerticalTableLines style={gridTemplateColumns}>
          <span />
          {dates.map((date) => (
            <VerticalLinePerDate key={date} today={date === today} />
          ))}
        </VerticalTableLines>
        {tasks.map((task) => (
          <TaskRow key={task.slug} style={gridTemplateColumns}>
            <LegacyFeatureCard
              key={task.slug}
              background='transparent'
              onClick={(): void => toggleModal(task, tasks)}
              onMoveToBacklog={(): void => undefined}
              onMoveToSprint={(): void => undefined}
              selectSprint={false}
              sprintProgressView
              sprintView={false}
              task={task}
              title={css`
                outline: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                z-index: 1;
              `}
              withBorder={false}
            />
            <ProgressController
              dates={dates}
              gitStatus={getGitStatus(task)}
              history={task.statusHistory}
              sprint={sprint}
              status={task.status}
            />
          </TaskRow>
        ))}
      </GanttChartTable>
    </Container>
  );
}

const Container = styled('div', {
  minWidth: '46.875rem',
  margin: '$20px 0',
  boxShadow: '$ganttChart',
});

const GanttChartTable = styled('div', {
  position: 'relative',
  minHeight: '18.2rem',
});

const GanttChartTableHeader = styled('div', { display: 'grid' });

const TasksDetails = styled(Box, { lineHeight: 'inherit' });

const TasksAmount = styled('span', {
  fontSize: '$14px',
  color: '$dark',
});

const focusBorder = 'borderWidths.$1px solid colors.$focus';

const DateWrapper = styled(
  'span',
  {
    height: '3rem',
    fontSize: '0.75rem',
    fontWeight: 500,
    textAlign: 'center',
    borderLeft: 'borderWidths.$1px solid colors.$grey3',
  },
  {
    today: {
      true: {
        'backgroundColor': 'rgba(29, 152, 255, 0.04)',
        'color': '$focus',
        'borderLeft': focusBorder,
        'borderRight': focusBorder,
        '> span': {
          color: '$focus',
        },
      },
    },
  },
);

const Day = styled('span', {
  color: '$grey7',
  position: 'relative',
  top: '0.625rem',
});

const Date = styled('span', {
  color: '$grey5',
  position: 'relative',
  top: '0.9375rem',
});

const VerticalTableLines = styled('div', {
  display: 'grid',
  position: 'absolute',
  height: 'calc(100% - 3rem)',
  width: '$full',
  backgroundColor: 'transparent',
});

const VerticalLinePerDate = styled(
  'span',
  { borderLeft: 'borderWidths.$1px solid colors.$grey3' },
  {
    today: {
      true: {
        backgroundColor: 'rgba(29, 152, 255, 0.04)',
        borderLeft: focusBorder,
        borderRight: focusBorder,
      },
    },
  },
);

const TaskRow = styled('div', {
  'display': 'grid',
  'cursor': 'pointer',

  ':nth-of-type(odd)': {
    backgroundColor: '$grey1',
  },

  '> span:not(:last-of-type)': {
    display: 'none',
  },
});
