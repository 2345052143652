/* eslint-disable @typescript-eslint/no-explicit-any */
import { Selector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import TaskModalContext from 'components/app/controllers/TaskModalContext';
import TaskModalController from 'components/app/controllers/TaskModalController';
import { linkTo } from 'components/Router/paths';
import constant from 'lodash.constant';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export interface TaskModalProviderProps extends React.HTMLProps<HTMLElement> {
  children?: JSX.Element | JSX.Element[] | string;
}

/**
 * TaskModalProvider
 * provider for task modal
 *
 */
export default function TaskModalProvider({ children }: TaskModalProviderProps): JSX.Element {
  const [initialModalTaskID, setInitialModalTaskID] = useState<string | null>(null);
  const tasksSelector = useRef<Selector<any, UI.UITask[] | undefined>>(constant([]));
  const [navigationCategory, setNavigationCategory] = useState<string | null>(null);

  const [initialPath, setInitialPath] = useState('');
  const { orgID, teamID } = useParams<{
    orgID?: Data.Id.OrganizationId;
    teamID?: Data.Id.TeamId;
  }>();
  const location = useLocation();
  const history = useHistory();

  const initialTaskList = useSelector(tasksSelector.current, deepEquals);

  const [initialTaskListOrder, setInitialTaskListOrder] = useState<string[]>([]);

  // If task list order isn't ready yet, default it to empty array
  // If it is empty array, get the new task list order
  // If it isn't empty don't let new data override it

  useEffect(() => {
    if (initialTaskListOrder.length === 0 && initialTaskList && initialTaskList.length > 0) {
      setInitialTaskListOrder(initialTaskList.map((task) => task.id));
    }
  }, [initialTaskList, initialTaskListOrder.length]);

  const openModal = useCallback(
    (task: UI.UITask, newTasksSelector: Selector<any, UI.UITask[] | undefined>, category: string) => {
      if (orgID && teamID) {
        setInitialPath(location.pathname);
        setInitialModalTaskID(task.id);
        // To enable a user refresh and return to the task page,
        // we need to change the URL without navigating to that page.
        // Right now the "react-router-dom history", can't be used for this.
        window.history.pushState('', '', linkTo('task', { orgID, teamID, taskSlug: task.slug }));
        setInitialTaskListOrder([]);
        tasksSelector.current = newTasksSelector;
        setNavigationCategory(category);
      }
    },
    [location.pathname, orgID, teamID],
  );

  const closeModal = useCallback(() => {
    setInitialModalTaskID(null);
    tasksSelector.current = constant([]);
    setNavigationCategory(null);
    setInitialTaskListOrder([]);
    history.replace(initialPath);
  }, [history, initialPath]);

  return (
    <TaskModalContext.Provider value={{ openModal }}>
      {initialModalTaskID &&
        tasksSelector.current &&
        createPortal(
          <TaskModalController
            key={initialModalTaskID}
            closeModal={closeModal}
            initialTaskID={initialModalTaskID}
            initialTaskListOrder={initialTaskListOrder}
            navigationCategory={navigationCategory}
            tasksSelector={tasksSelector.current}
          />,
          document.body,
        )}
      {children}
    </TaskModalContext.Provider>
  );
}
