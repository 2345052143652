import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { EffortToggler, useEffortToggler } from 'components/core/controllers/views/EffortToggler';
import { usePathParams } from 'components/Router/paths';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reduxStore, selectOrganization, updateOrganization } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

/**
 * WorkspaceEffortTogglerController
 * - responsible for showing and changing Workspace effort unit
 */
export default function WorkspaceEffortTogglerController(): JSX.Element {
  const { addToast } = useToast();
  const { orgID } = usePathParams('workspace');
  const { effortUnit: initialEffortUnit } = useSelector(selectOrganization(orgID));

  const updateOrgEffortUnit = useCallback(
    (unit: Data.EffortUnit) =>
      reduxStore
        .dispatch(updateOrganization({ id: orgID, effortUnit: unit }))
        .then(unwrapResult)
        .then(() =>
          addToast({
            type: 'success',
            timeoutMs: 2500,
            message: strings.formatString(strings.workspace.effortToggler.success, {
              effortUnit: strings.workspace.effortToggler.units[unit],
            }) as string,
          }),
        )
        .catch((error: Error) => {
          addToast({
            type: 'error',
            timeoutMs: 2500,
            message: strings.formatString(strings.workspace.effortToggler.error, {
              errorMessage: error.message,
            }) as string,
          });
        }),
    [addToast, orgID],
  );

  const togglerProps = useEffortToggler({
    initialEffortUnit,
    onChange: updateOrgEffortUnit,
  });
  return (
    <EffortToggler
      description={strings.workspace.effortToggler.description}
      label={strings.workspace.effortToggler.label}
      {...togglerProps}
    />
  );
}
