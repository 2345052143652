import { Data } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { RootState } from 'reduxStore/store';
import { getOrgID as getOrgIDFromPath, getTeamID as getTeamIDFromPath } from 'route-utils';

export function getUserID(state: RootState): Data.Id.UserId {
  const userID = state.firebase.auth.uid;

  if (!isNonEmptyString(userID)) {
    throw new Error('getUserID called for non authenticated user');
  }

  return userID;
}

export function getOrgID(): Data.Id.OrganizationId {
  const orgID = getOrgIDFromPath();

  if (!isNonEmptyString(orgID)) {
    throw new Error('getOrgID called from non organizational page');
  }

  return orgID;
}

export function getTeamID(): Data.Id.OrganizationId {
  const teamID = getTeamIDFromPath();

  if (!isNonEmptyString(teamID)) {
    throw new Error('getTeamID called from non team page');
  }

  return teamID;
}
