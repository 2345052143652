import styled from '@emotion/styled';
import { AsanaEmptyBacklogCardController } from 'components/app/controllers/AsanaEmptyBacklogCardController';
import TeamBacklogPopupController from 'components/app/controllers/TeamBacklogPopupController';
import { TrelloEmptyBacklogCardController } from 'components/app/controllers/TrelloEmptyBacklogCardController';
import { css } from 'emotion';
import React from 'react';
import { sprintBacklogButtons } from 'resources/cypress/testAttributesValues';
import { atomic } from 'resources/theme';
import { onEnterDown } from 'tools/helpers';

export interface EmptyBacklogImportCardProps {
  icon: JSX.Element;
  label: string;
  onClick: () => void;
  dataCy?: string;
}
export const EmptyBacklogImportCard = ({ icon, label, onClick, dataCy }: EmptyBacklogImportCardProps): JSX.Element => (
  <div
    className={css`
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      border: solid 1px ${atomic.get(atomic.colors.grey3)};
      background: ${atomic.get(atomic.colors.white)};

      &:hover {
        cursor: pointer;
        background: ${atomic.get(atomic.colors.grey1)};
      }
    `}
    data-cy={dataCy}
    onClick={onClick}
    onKeyDown={onEnterDown(onClick)}
    role='button'
    tabIndex={0}
  >
    <div
      className={css`
        margin: 0 12px;
      `}
      data-cy={dataCy}
    >
      {icon}
    </div>
    <div
      className={css`
        font-family: Sofia;
        font-size: 14px;
        font-weight: 500;
        color: #575f65;
      `}
      data-cy={dataCy}
    >
      {label}
    </div>
  </div>
);

const Separator = styled('div')`
  margin-top: 0.5rem;
`;

const EmptyBacklogView = (): JSX.Element => (
  <div
    className={css`
      padding: 0 1rem;
    `}
  >
    <div
      className={css`
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
      `}
    />
    <TrelloEmptyBacklogCardController data-cy={sprintBacklogButtons.TRELLO} />
    <Separator />
    <AsanaEmptyBacklogCardController data-cy={sprintBacklogButtons.ASANA} />
    <TeamBacklogPopupController />
  </div>
);

export default EmptyBacklogView;
