import { Data, UI } from '@taraai/types';
import DropdownBody from 'components/app/controllers/views/DropdownBody';
import DropdownHeader from 'components/app/controllers/views/DropdownHeader';
import Dropdown from 'components/core/controllers/views/Dropdown';
import Fuse from 'fuse.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'tools';
import { sort } from 'tools/libraries/helpers/sort';

export interface DropdownControllerProps extends React.HTMLProps<HTMLDivElement> {
  header?: boolean;
  footer?: boolean;
  options: Array<UI.UIRequirement | UI.UIUser>;
  addEvent: (id: string) => void;
  removeEvent: (id: string) => void;
  closeEvent: (event: MouseEvent | React.BaseSyntheticEvent) => void;
  selectedOptions: Array<UI.UIRequirement | UI.UIUser>;
  headerTitle?: string | string[];
  headerPlaceholder?: string;
  positionCSS?: string;
  widthCSS?: string;
  searchQuery?: string;
  exactTypeaheadInputRef?: React.RefObject<HTMLInputElement>;
  dataCy?: string;
}

/**
 * DropdownController
 * logic for dropdown interactions
 *
 */
export default function DropdownController({
  className,
  header,
  footer,
  options,
  addEvent,
  removeEvent,
  closeEvent,
  selectedOptions,
  headerTitle,
  headerPlaceholder,
  positionCSS,
  widthCSS,
  exactTypeaheadInputRef,
  searchQuery: passedInQuery,
  dataCy,
  ...props
}: DropdownControllerProps): JSX.Element {
  const KEYS = {
    ESCAPE: 27,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    ENTER: 13,
  };

  const selectedIds = selectedOptions.map((selectedOption): Data.Id.UserId => selectedOption.id);

  const customSort = [
    {
      desc: (option: { id: string }): number => selectedIds.indexOf(option.id),
    },
  ];

  const sortedOptions = sort(options, customSort);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const typeaheadInputRef = exactTypeaheadInputRef ?? useRef<HTMLInputElement>(null);

  const [typeaheadInput, setTypeaheadInput] = useState('');
  const [typeaheadResults, setTypeaheadResults] = useState(sortedOptions);
  const [activeResult, setActiveResult] = useState(0);

  useEffect(() => {
    typeaheadInputRef.current && typeaheadInputRef.current.focus();
  }, [typeaheadInputRef]);

  useClickOutside(wrapperRef, (event) => closeEvent(event));

  const fuseOptions = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name', 'title'],
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fuse = new Fuse(options, fuseOptions);

  const onSearchChange = useCallback(
    (event) => {
      const searchQuery = event.target.value;
      const results = searchQuery.length === 0 ? sortedOptions : fuse.search(searchQuery).map(({ item }) => item);
      setActiveResult(0);
      setTypeaheadInput(searchQuery);
      setTypeaheadResults(results);
    },
    [fuse, sortedOptions],
  );

  useEffect(() => {
    if ((!header || !headerTitle || !headerPlaceholder) && (passedInQuery?.length ?? 0) > 0) {
      onSearchChange({ target: { value: passedInQuery } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedInQuery]);

  const addOrRemove = useCallback(
    (list, item) => {
      if (list.includes(item) && removeEvent) {
        removeEvent(item);
      } else {
        addEvent(item);
      }
    },
    [addEvent, removeEvent],
  );

  const onOptionClick = useCallback(
    (event: React.BaseSyntheticEvent) => {
      event.stopPropagation();
      addOrRemove(selectedIds, event.currentTarget.dataset.choice);
    },
    [addOrRemove, selectedIds],
  );

  const handleInputOnKeyDown = useCallback(
    (event) => {
      switch (event.keyCode) {
        case KEYS.ESCAPE:
          event.preventDefault();
          closeEvent(event);
          return;
        case KEYS.ARROW_UP:
          event.preventDefault();
          setActiveResult((prevActive) => (prevActive === 0 ? 0 : prevActive - 1));
          return;
        case KEYS.ARROW_DOWN:
          event.preventDefault();
          setActiveResult((prevActive) => (prevActive === typeaheadResults.length - 1 ? prevActive : prevActive + 1));
          return;
        case KEYS.ENTER:
          event.preventDefault();
          if (typeaheadResults[activeResult]) addOrRemove(selectedIds, typeaheadResults[activeResult].id);
          // eslint-disable-next-line no-useless-return
          return;
        default:
          break;
      }
    },
    [
      KEYS.ARROW_DOWN,
      KEYS.ARROW_UP,
      KEYS.ENTER,
      KEYS.ESCAPE,
      activeResult,
      addOrRemove,
      closeEvent,
      selectedIds,
      typeaheadResults,
    ],
  );

  return (
    <Dropdown
      Ref={wrapperRef}
      data-cy={dataCy}
      positionCSS={positionCSS}
      widthCSS={widthCSS}
      {...props}
      header={
        header &&
        headerTitle &&
        headerPlaceholder && (
          <DropdownHeader
            closeEvent={closeEvent}
            handleInputOnKeyDown={handleInputOnKeyDown}
            headerPlaceholder={headerPlaceholder}
            headerTitle={headerTitle}
            inputRef={typeaheadInputRef}
            onSearchChange={onSearchChange}
            typeaheadInput={typeaheadInput}
          />
        )
      }
    >
      <DropdownBody
        activeResult={activeResult}
        handleInputOnKeyDown={handleInputOnKeyDown}
        onOptionClick={onOptionClick}
        options={options}
        selectedIds={selectedIds}
        typeaheadResults={typeaheadResults}
      />
    </Dropdown>
  );
}
