import { Box } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { composePlugins, createLabelsPlugin, getWhitespacePlugin, markdownPlugin } from 'components/editor/plugins';
import { RichEditor } from 'components/editor/RichEditor';
import { RichEditorProvider } from 'components/editor/RichEditorProvider';
import React, { MutableRefObject, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { createLabel, defaultLabels, getCustomLabels, reduxStore, selectDefaultLabel } from 'reduxStore';
import { taskModalTestIDs } from 'resources/cypress/testAttributesValues';

type TaskNameInputProps = {
  value: string;
  trySave: (title: string) => Promise<void>;
  placeholder?: string;
};

export const TaskNameInput: React.FC<TaskNameInputProps> = ({
  value,
  trySave,
  placeholder = '',
}: TaskNameInputProps) => {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const customLabels = useSelector(getCustomLabels(orgID).selector);

  // Don't recreate the plugin every time labels change
  const labels = useRef() as MutableRefObject<UI.UILabel[]>;
  labels.current = [
    ...(Object.keys(defaultLabels) as Data.DefaultLabelId[]).map(selectDefaultLabel),
    ...(customLabels || []),
  ];

  const plugin = useMemo(
    () =>
      composePlugins(
        getWhitespacePlugin({ trim: true, collapse: true }),
        markdownPlugin,
        createLabelsPlugin({
          createLabel: (title) => reduxStore.dispatch(createLabel(title)),
          getLabels: () => labels.current,
        }),
      ),
    [],
  );

  return (
    <RichEditorProvider initialValue={value} onSave={trySave} plugin={plugin}>
      <Box space={['$16px', '$24px']}>
        <RichEditor data-cy={taskModalTestIDs.TASK_NAME} isTitle placeholder={placeholder} />
      </Box>
    </RichEditorProvider>
  );
};
