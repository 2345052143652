import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { getContrastingColor } from 'tools/libraries/helpers/colors';

export interface HomeBannerProps {
  username?: string;
  backgroundColor: string | null;
}

export default function HomeBanner({ username = '', backgroundColor }: HomeBannerProps): JSX.Element {
  const background = backgroundColor
    ? `background-color: ${backgroundColor};`
    : 'background-image: linear-gradient(302deg, #3985c5, #4550ce);';
  const foregroundColor = backgroundColor ? getContrastingColor(backgroundColor) : '#ffffff';
  return (
    <div
      className={css`
        padding: 1.875rem;
        margin-bottom: 1.875rem;
        height: 9.0625rem;
        border-radius: 0.1875rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        ${background}
      `}
    >
      <Text
        className={css`
          color: ${foregroundColor};
        `}
        h1
      >
        {strings.formatString(strings.dashboard.welcomeBack, {
          name: username.split(' ')[0],
        })}
      </Text>
    </div>
  );
}
