import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources';

import DraftLinkAnchor from './DraftLinkAnchor';

interface DraftLinkViewProps {
  children?: React.ReactNode;
  href: string;
  onClick: () => void;
}

export default function DraftLinkView({ children, href, onClick }: DraftLinkViewProps): JSX.Element {
  return (
    <DraftLinkAnchor
      className={css`
        &,
        &:visited {
          ${atomic.color(atomic.colors.focus)}
          text-decoration: none;
        }

        &:hover {
          ${atomic.color(atomic.colors.focus)}
          opacity: 0.6;
          cursor: pointer;
        }

        &:focus {
          ${atomic.color(atomic.colors.focus)}
          outline: 0;
        }

        &:active {
          ${atomic.color(atomic.colors.focus)}
        }
      `}
      href={href}
      onClick={onClick}
    >
      {children}
    </DraftLinkAnchor>
  );
}
