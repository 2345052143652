import { styled } from '@taraai/design-system';
import { noop } from '@taraai/utility';
import { getEntityData } from 'components/editor/entities';
import { RichEditorContext } from 'components/editor/RichEditorProvider';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'tools';

import { Popup } from './Popup';
import { getImageMetadata, ImageMetadata } from './utils';

export function Image({ blockKey, contentState, end, entityKey, start }: DraftDecoratorComponentProps): JSX.Element {
  const { setEditorState } = useContext(RichEditorContext);
  const [isOpen, setIsOpen] = useState(false);
  const image = useRef<HTMLImageElement | null>(null);
  const popup = useRef<HTMLDivElement | null>(null);
  const [imageData, setImageData] = useState<ImageMetadata | null>(null);
  const imageUrl = getEntityData('IMAGE', contentState, entityKey).src;

  const handleToggle = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen((current) => !current);
  }, []);

  const handleCopy = useCallback((): void => {
    navigator.clipboard.writeText(imageUrl);
    setIsOpen(false);
  }, [imageUrl]);

  const handleRemove = useCallback((): void => {
    const selection = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: start,
      focusOffset: end,
    }) as SelectionState;
    setEditorState(
      (editorState: EditorState): EditorState =>
        EditorState.push(
          editorState,
          Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward').set(
            'selectionAfter',
            SelectionState.createEmpty(blockKey).merge({
              anchorOffset: start,
              focusOffset: start + 1,
              hasFocus: true,
            }) as SelectionState,
          ) as ContentState,
          'remove-range',
        ),
    );
    setIsOpen(false);
  }, [blockKey, end, setEditorState, start]);

  useEffect(() => {
    getImageMetadata(imageUrl).then(setImageData).catch(noop);
  }, [imageUrl]);

  useClickOutside([image, popup], (): void => setIsOpen(false));

  const isLandscape = !!imageData && imageData.height < imageData.width;

  return (
    <>
      <ImageContent
        ref={image}
        alt=''
        isLandscape={isLandscape}
        onClick={handleToggle}
        role='button'
        src={imageUrl}
        tabIndex={0}
      />
      <Popup
        ref={popup}
        anchorEl={image.current}
        onCopy={handleCopy}
        onRemove={handleRemove}
        open={isOpen}
        url={imageUrl}
      />
    </>
  );
}

const ImageContent = styled(
  'img',
  {
    border: 'none',
    outline: 'none',
    background: 'none',
    margin: 'auto',
  },
  {
    isLandscape: {
      true: {
        maxWidth: '100%',
      },
      false: {
        maxWidth: '50%',
      },
    },
  },
);
