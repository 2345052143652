import { Tooltip } from '@material-ui/core';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import { css, cx } from 'emotion';
import React, { useCallback } from 'react';
import { gitlabIntegrationTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme/atomic';

export interface GitlabIntegrationProps extends React.HTMLProps<HTMLDivElement> {
  id?: string;
  isAdmin: boolean;
  isInstalled: boolean;
  openModal: () => void;
}

export default function GitlabIntegration({
  className,
  isAdmin,
  isInstalled,
  openModal,
}: GitlabIntegrationProps): JSX.Element {
  const withPermissionsTooltip = useCallback(
    (element: JSX.Element): JSX.Element => {
      return isAdmin ? (
        element
      ) : (
        <Tooltip
          arrow
          placement='bottom'
          title={
            <span
              className={css`
                font-size: 0.8rem;
              `}
            >
              {strings.integrations.gitlab.disabled}
            </span>
          }
        >
          <span>{element}</span>
        </Tooltip>
      );
    },
    [isAdmin],
  );

  const connectButton = isInstalled ? (
    <Button
      className={css`
        padding: 0.25rem 1rem;
        margin-right: 0.5rem;
        outline: none;
        pointer-events: ${isAdmin ? 'auto' : 'none'};
      `}
      color='ghost'
      data-cy={gitlabIntegrationTestIDs.MANAGE_BUTTON}
      disabled={!isAdmin}
      onClick={openModal}
    >
      {strings.integrations.gitlab.manage}
    </Button>
  ) : (
    <Button
      className={css`
        padding: 0.25rem 0.5rem;
        outline: none;
        pointer-events: ${isAdmin ? 'auto' : 'none'};
      `}
      color='green'
      data-cy={gitlabIntegrationTestIDs.CONNECT_BUTTON}
      disabled={!isAdmin}
      id='installIntegrationFormChild'
      onClick={openModal}
    >
      {strings.integrations.connect}
    </Button>
  );
  return (
    <div
      className={cx(
        css`
          border-radius: 0.1875rem;
          box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.1);
          background-color: ${atomic.get(atomic.colors.white)};
          z-index: 1;
          display: flex;
          flex-direction: column;
        `,
        className,
      )}
    >
      <div
        className={css`
          padding: 1.5rem 1rem;
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <Icon
          className={css`
            width: 2rem;
            height: 2rem;
          `}
          name='gitlab'
        />
        <div
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 0 0.5rem;
            flex-grow: 1;
          `}
        >
          <p
            className={css`
              font-size: 1rem;
              font-weight: 500;
              color: ${atomic.get(atomic.colors.dark)};
            `}
          >
            {strings.integrations.gitlab.title}
          </p>
          <p
            className={css`
              font-size: 0.875rem;
              line-height: 1.57;
              color: #575f65;
            `}
          >
            {strings.integrations.gitlab.description}
          </p>
        </div>
        {withPermissionsTooltip(connectButton)}
      </div>
    </div>
  );
}
