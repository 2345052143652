import { TrelloIcon } from 'components/app/controllers/TrelloImportBacklogIndicatorController';
import { EmptyBacklogImportCard } from 'components/app/controllers/views/EmptyBacklogView';
import React, { useCallback } from 'react';
import { reduxStore } from 'reduxStore';
import { openImportModal } from 'reduxStore/imports/actions';
import { sprintBacklogButtons } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export function TrelloEmptyBacklogCardController(): JSX.Element {
  const onClick = useCallback(() => {
    reduxStore.dispatch(openImportModal('trello'));
  }, []);

  return (
    <EmptyBacklogImportCard
      dataCy={sprintBacklogButtons.TRELLO}
      icon={<TrelloIcon size='1.5rem' />}
      label={strings.requirements.importCardLabels.trello}
      onClick={onClick}
    />
  );
}
