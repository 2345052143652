import { css, keyframes } from 'emotion';

const slideFromTop = keyframes`
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
`;

export const animateFromTop = css`
  transform-origin: top center;
  animation: ${slideFromTop} 0.2s ease-in-out;
`;
