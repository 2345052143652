import { unwrapResult } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import SubtaskView from 'components/app/controllers/views/SubtaskView';
import React, { ChangeEventHandler, useCallback } from 'react';
import { reduxStore } from 'reduxStore';
import { deleteTask } from 'reduxStore/tasks/actions';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources';
import { useToast } from 'tools';

export type TaskFragment = Pick<UI.UITask, 'id' | 'title' | 'status' | 'slug'>;
export interface SubtaskControllerProps {
  task: TaskFragment;
  dragRef: React.MutableRefObject<HTMLDivElement | null>;
}

const STATUS_IN_PROGRESS = 1;
const STATUS_DONE = 2;

/**
 * SubtaskController
 * controller
 */
export default function SubtaskController({ task, dragRef }: SubtaskControllerProps): JSX.Element {
  const { addToast } = useToast();

  const handleUpdate = (title: string): void => {
    reduxStore
      .dispatch(updateTask({ id: task.id, title }))
      .then(unwrapResult)
      .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
  };

  const completeProps = {
    checked: task.status === STATUS_DONE,
    onChange: useCallback<ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        reduxStore
          .dispatch(updateTask({ id: task.id, status: event.target.checked ? STATUS_DONE : STATUS_IN_PROGRESS }))
          .then(unwrapResult)
          .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
      },
      [addToast, task.id],
    ),
  };

  const deleteCurrentTask = useCallback(() => {
    reduxStore
      .dispatch(deleteTask({ id: task?.id }))
      .then(unwrapResult)
      .catch(() => addToast({ type: 'error', message: strings.task.failedToDeleteTask }));
  }, [addToast, task]);

  return (
    <SubtaskView
      completeProps={completeProps}
      deleteCurrentTask={deleteCurrentTask}
      dragRef={dragRef}
      handleUpdate={handleUpdate}
      subtaskTitle={task.title}
    />
  );
}
