import { LabelChip } from 'components/app/controllers/views/LabelChip';
import { getEntityData } from 'components/editor/entities';
import { DraftDecoratorComponentProps } from 'components/editor/types';
import React from 'react';

export function Label({ children, contentState, entityKey }: DraftDecoratorComponentProps): JSX.Element {
  const { color, description } = getEntityData('label', contentState, entityKey);
  return (
    <LabelChip backgroundColor={color} description={description}>
      {children}
    </LabelChip>
  );
}
