"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
exports.default = ts_data_json_1.JsonDecoder.object({
    backlog: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    home: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    products: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    autoSwitchTaskStatus: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    newSprintsPage: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    autoSprints: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
}, 'FeatureFlags');
