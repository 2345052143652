import { Data } from '@taraai/types';
import { TeamDetailsModal } from 'components/app/controllers/views/TeamDetailsModal';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { compose } from 'redux';
import { getCompletedSprints, selectAuth, selectProfile, selectTeam, selectUserTeams } from 'reduxStore';

export interface TeamDetailsControllerProps {
  noModal?: boolean;
  closeModal?: () => void;
}

/**
 * TeamDetailsController
 * Team Details Controller for the page view
 *
 */
export default function TeamDetailsController({
  noModal = false,
  closeModal,
}: TeamDetailsControllerProps): JSX.Element | null {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();
  const profileId = useSelector(selectAuth).uid;
  const team = useSelector(selectTeam(orgID, teamID));
  const hasAlternativeTeams = useSelector(compose((teams) => teams.length > 1, selectUserTeams(orgID)));
  const profile = useSelector(selectProfile);
  const completedSlice = getCompletedSprints(orgID, teamID, {
    orderBy: 'sprintNumber',
  });
  useFirestoreConnect(completedSlice.query);
  const hasCompleteSprint = useSelector(compose((data) => data?.map((sprint) => sprint.id), completedSlice.selector));

  if (!isLoaded(team) || !isLoaded(hasCompleteSprint)) {
    return null;
  }
  const isMember = (profile.teamIds[orgID] || []).includes(team.id);
  const canLeave = isMember && profile.teamIds[orgID].length > 1;
  const isPreferred = profile.preferredTeamIds[orgID] === team.id;
  const isSprintCompleteOrActive = hasCompleteSprint.length > 0 || team.currentSprintId !== null;
  return (
    <TeamDetailsModal
      canLeave={canLeave}
      closeModal={closeModal}
      hasAlternativeTeams={hasAlternativeTeams}
      isMember={isMember}
      isPreferred={isPreferred}
      isSprintCompleteOrActive={isSprintCompleteOrActive}
      noModal={noModal}
      orgId={orgID}
      profileId={profileId}
      teamId={teamID}
      teamName={team.name}
      username={profile.name}
    />
  );
}
