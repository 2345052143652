import { css } from 'emotion';
import React from 'react';
import { EmptyColumn } from 'resources/assets';
import { strings } from 'resources/i18n';

export default function EmptySprintColumn(): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <img
        alt={strings.sprints.dropZone.emptyColumnAlt}
        className={css`
          margin: 56px auto 26px auto;
          width: 108px;
          height: 85px;
          object-fit: contain;
        `}
        src={EmptyColumn}
      />
      <span
        className={css`
          margin-left: auto;
          margin-right: auto;
          width: 249px;
          height: 40px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: center;
          color: #949caf;
        `}
      >
        {strings.sprints.dropZone.emptyColumn}
      </span>
    </div>
  );
}
