import { AddToastFn } from 'components/app/controllers/Toast/ToastContext';
import { PluginFunctions, RichEditorPlugin } from 'components/editor/types';
import { DraftHandleValue, SelectionState } from 'draft-js';

import { imageDecorator } from './imageDecorator';
import { createInsertFileFn } from './insertFileFn';

export const createAttachmentPlugin = (
  addToast: AddToastFn,
  uploadAttachment?: (file: File) => Promise<string>,
): RichEditorPlugin => {
  const insertFiles = (
    selection: SelectionState | null,
    files: File[],
    { setEditorState }: PluginFunctions,
  ): DraftHandleValue => {
    if (!uploadAttachment) {
      return 'not-handled';
    }

    const insertFilesFn = createInsertFileFn(setEditorState, addToast, uploadAttachment);

    insertFilesFn(selection, files);

    return 'handled';
  };

  return {
    decorator: [imageDecorator],
    handleDroppedFiles: insertFiles,
    handlePastedFiles: (files: Array<File>, pluginFunctions: PluginFunctions) =>
      insertFiles(null, files, pluginFunctions),
  };
};
