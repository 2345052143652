import { compose, unwrapResult } from '@reduxjs/toolkit';
import Tara, { Data, UI } from '@taraai/types';
import { InviteUsers } from 'components/app/controllers/views/InviteUsers';
import React, { ChangeEvent, useCallback, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import {
  deactivateInviteLink as deactivateInviteLinkAction,
  getReusableInvitation,
  inviteUsers,
  makeInviteLink,
  reduxStore,
  selectAuth,
  selectPreferredTeamId,
} from 'reduxStore';
import { selectOrganization } from 'reduxStore/organization/selectors';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { emailValidator } from 'tools/helpers/validators/inviteUserValidators';

type Props = {
  onClose: () => void;
};

export type MaybeReusableLink = {
  id: Data.Id.InvitationId;
  invitationLink: string;
  expiredAt: Tara.Timestamp;
};

const getInitialMessage = (orgName: string): string =>
  strings.formatString(strings.inviteUserPopup.defaultInviteMessageContent, {
    orgName,
  }) as string;

export default function InviteUsersController({ onClose }: Props): JSX.Element {
  const { whenError } = useToast();
  const { orgID, teamID } = useParams<{ orgID: Data.Id.OrganizationId; teamID: Data.Id.TeamId }>();
  const { uid: userID } = useSelector(selectAuth);

  const currentOrg = useSelector(selectOrganization(orgID));
  const preferredTeamId = useSelector(selectPreferredTeamId(orgID));

  const organizationName = currentOrg.name ?? 'my organization';

  const [emailList, setEmailList] = useState<string[]>([]);
  const [emailInputValue, setEmailInputValue] = useState<string>('');
  const [selectedTeam, setSelectedTeam] = useState<Data.Id.TeamId | null>(preferredTeamId ?? null);
  const [messageValue, setMessageValue] = useState(getInitialMessage(organizationName));
  const [errorsPresent, setErrorsPresent] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingInvitationLink, setLoadingInvitationLink] = useState(false);

  const validators = [emailValidator];

  const hideAllErrors = (): void => {
    setErrorsPresent(false);
    setInviteSent(false);
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setMessageValue(event.target.value);
    hideAllErrors();
  };

  const handleTagInputChange = (newTags: string[]): void => {
    setEmailList(newTags);
    hideAllErrors();
  };

  const handleTeamSelectionChange = (teamId: Data.Id.TeamId): void => {
    setSelectedTeam(teamId);
    hideAllErrors();
  };

  const sendInvites = async (emails: string[], message: string): Promise<void> => {
    const anyEmailInvalid = emails.some((email) => validators.some((validator) => !validator(email).valid));
    if (anyEmailInvalid) {
      setErrorsPresent(true);
      return;
    }

    setLoadingSubmit(true);

    const payload: UI.Invitation = {
      emails,
      message,
      // user is not able to click `sent` if team is not selected
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      teamId: selectedTeam!,
    };
    try {
      await reduxStore.dispatch(inviteUsers(payload));
    } catch (err) {
      // FIXME: temporary error handling
      // since we cannot check if a mail is already added to an org on FE
      // that validation must be moved to BE
      if (err.code === 'invalid-argument') {
        setErrorsPresent(true);
        return;
      }
      // eslint-disable-next-line no-console
      console.error(err);
      return;
    } finally {
      setLoadingSubmit(false);
    }

    setInviteSent(true);
    setEmailList([]);
    setEmailInputValue('');
    setSelectedTeam(null);
    setMessageValue(getInitialMessage(organizationName));
  };

  const onSubmit = (): void => {
    const currentInput = emailInputValue.trim();

    // treat non-empty input value as another tag
    const emails = [...emailList, ...(currentInput.length > 0 ? [currentInput] : [])];
    setEmailList(emails);
    setEmailInputValue('');

    // setEmailList is async, so we cant just pass emailList here
    sendInvites(emails, messageValue);
  };

  const emailInputEmpty = emailInputValue.length === 0 && emailList.length === 0;
  const emailInputValid = emailList.some((email) => validators.some((validator) => !validator(email).valid));

  const makeReusableLinkFromToken = (token: string): string => `${window.location.origin}/invite/${token}`;

  const invitationSlice = getReusableInvitation(orgID, userID);
  const currentTime = Date.now();

  useFirestoreConnect(invitationSlice.query);

  const maybeReusableLink = useSelector(
    compose(
      (invitations: MaybeReusableLink[]) =>
        invitations.filter((invitation) => invitation.expiredAt.toMillis() > currentTime),
      (invitations?: UI.UIInvitation[]) =>
        invitations?.map(({ id, expiredAt, token }) => ({
          id,
          expiredAt,
          invitationLink: makeReusableLinkFromToken(token),
        })) || [],
      invitationSlice.selector,
    ),
    deepEquals,
  )[0];

  const createInviteLink = useCallback(() => {
    setLoadingInvitationLink(true);

    return reduxStore
      .dispatch(makeInviteLink({ teamId: teamID }))
      .then(unwrapResult)
      .finally(() => setLoadingInvitationLink(false))
      .catch(whenError((err) => err.message));
  }, [teamID, whenError]);

  const deactivateInviteLink = useCallback(() => {
    setLoadingInvitationLink(true);

    return reduxStore
      .dispatch(
        deactivateInviteLinkAction({
          invitationId: maybeReusableLink.id,
        }),
      )
      .then(unwrapResult)
      .finally(() => setLoadingInvitationLink(false))
      .catch(whenError((err) => err.message));
  }, [maybeReusableLink, whenError]);

  const disableSubmit = emailInputEmpty || loadingSubmit || !selectedTeam || emailInputValid;

  return (
    <InviteUsers
      createInviteLink={createInviteLink}
      deactivateInviteLink={deactivateInviteLink}
      disableSubmit={disableSubmit}
      emailInputValue={emailInputValue}
      emailList={emailList}
      errorsPresent={errorsPresent}
      handleTagInputChange={handleTagInputChange}
      handleTeamSelectionChange={handleTeamSelectionChange}
      handleTextareaChange={handleTextareaChange}
      inviteSent={inviteSent}
      loadingInvitationLink={loadingInvitationLink}
      loadingSubmit={loadingSubmit}
      maybeReusableLink={maybeReusableLink}
      messageValue={messageValue}
      onClose={onClose}
      onSubmit={onSubmit}
      organizationName={organizationName}
      selectedTeam={selectedTeam}
      setEmailInputValue={setEmailInputValue}
      validators={validators}
    />
  );
}
