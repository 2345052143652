import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IconName } from 'resources';

import { TabsView, TabsViewVariantProps } from './TabsView';

export interface Tab {
  label: string;
  route: string;
  disabled?: boolean;
  icon?: IconName;
  dataCy?: string;
}

export interface TabBarProps extends TabsViewVariantProps {
  tabs: Tab[];
  selectedTab?: Tab;
  lineMarginX?: string;
  handleTabChange: (tab: Tab) => void;
}

/**
 * Tabs component renders buttons for switching views.
 */
const Tabs: React.FC<TabBarProps> = ({ children, tabs, lineMarginX = '', ...rest }) => {
  const [tabLeftPosition, setTabLeftPosition] = useState(0);
  const [tabWidth, setTabWidth] = useState(0);
  const selectedTabRef = useRef<HTMLButtonElement>(null);
  const [enableAnimation, setEnableAnimation] = useState(false);

  // set up an animation frame loop for checking if the bounding rect has changed
  // doing it this way prevents inconsistencies that may occur when the fonts are not loaded fast enough
  useLayoutEffect(() => {
    let handle: number;

    function listen(): void {
      handle = requestAnimationFrame(listen);
      const selectedTabElement = selectedTabRef.current;
      if (!selectedTabElement) {
        return;
      }
      if (tabLeftPosition !== selectedTabElement.offsetLeft) {
        setTabLeftPosition(selectedTabElement.offsetLeft);
      }
      if (tabWidth !== selectedTabElement.offsetWidth) {
        setTabWidth(selectedTabElement.offsetWidth);
      }
    }
    listen();

    return () => cancelAnimationFrame(handle);
  }, [tabLeftPosition, tabWidth]);

  // only enable animation after we have the initial tab width estabilished
  useEffect(() => {
    if (tabWidth && !enableAnimation) {
      setEnableAnimation(true);
    }
  }, [enableAnimation, tabWidth]);

  return (
    <TabsView
      {...rest}
      enableAnimation={enableAnimation}
      lineMarginX={lineMarginX}
      selectedTabRef={selectedTabRef}
      tabbedContent={tabs}
      tabLeftPosition={tabLeftPosition}
      tabWidth={tabWidth}
    >
      {children}
    </TabsView>
  );
};

export default Tabs;
