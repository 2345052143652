import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { parseLabelsFromPlainText } from '@taraai/utility';
import { LabelChip } from 'components/app/controllers/views/LabelChip';
import React, { useMemo, useState } from 'react';
import { isDefaultLabel, selectLabel } from 'reduxStore';
import { strings } from 'resources/i18n';

const maxShownLabels = 3;

interface Props {
  description: string;
  showAll?: boolean;
  title: string;
}

/**
 * A view component that displays a list of labels
 */
export function LabelList({ description, showAll = false, title }: Props): JSX.Element | null {
  const [showAllLabels, setShowAllLabels] = useState(showAll);

  const labels: UI.UILabel[] = useMemo(() => {
    const descriptionLabels = new Set(parseLabelsFromPlainText(description));
    parseLabelsFromPlainText(title).forEach((titleLabelId) => {
      descriptionLabels.delete(titleLabelId);
    });
    return Array.from(descriptionLabels, selectLabel);
  }, [description, title]);

  if (labels.length === 0) {
    return null;
  }

  const showSurplusLabels = labels.length > maxShownLabels && !showAllLabels;
  return (
    <Wrapper>
      {labels.slice(0, showAllLabels ? labels.length : maxShownLabels).map((label) => (
        <LabelChipWrapper key={label.id}>
          <LabelChip
            backgroundColor={isDefaultLabel(label) ? label.color : undefined}
            description={isDefaultLabel(label) ? label.description : undefined}
          >
            #{label.title}
          </LabelChip>
        </LabelChipWrapper>
      ))}
      {showSurplusLabels && (
        <SurplusNumber
          onClick={(event) => {
            event.stopPropagation();
            setShowAllLabels(true);
          }}
          role='button'
          title={strings.labelList.showMore}
        >
          {strings.formatString(strings.avatarPicker.plus, {
            count: labels.length - maxShownLabels,
          })}
        </SurplusNumber>
      )}
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  'gridColumn': '2 / span 3',
  'marginLeft': '-0.5rem',
  'marginTop': '-0.5rem',
  'padding': '0.5rem 0.5rem 0.8rem 0',

  '> *': {
    marginLeft: '0.5rem',
    marginTop: '0.5rem',
  },
});

const LabelChipWrapper = styled('div', {
  display: 'inline-block',
  wordBreak: 'break-all',
});

const SurplusNumber = styled('div', {
  color: '#949caf',
  display: 'inline-block',
  cursor: 'pointer',
  fontSize: '0.875rem',
});
