import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';

export const getTaskCommitsSummaries = (
  orgId: Data.Id.OrganizationId,
  taskSlug: Data.Id.TaskSlug,
  options?: { limit?: number },
): Query<UI.UICommitSummary> => {
  if (![orgId, taskSlug].every(isNonEmptyString)) {
    return inertQuery();
  }

  const taskCommitsSummaryQuery: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgId}/tasks-commits-summaries`,
    where: [['taskSlug', '==', taskSlug]],
    storeAs: `org-${orgId}-task-${taskSlug}-commits-tasks`,
    orderBy: ['createdAt', 'desc'],
  };

  if (typeof options?.limit === 'number') {
    taskCommitsSummaryQuery.limit = options.limit;
  }

  return {
    query: [taskCommitsSummaryQuery],
    selector: createStandardSelector(taskCommitsSummaryQuery),
  };
};
