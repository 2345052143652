import { NotificationBar } from 'components/core/controllers/views/NotificationBar';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources';

export const NoTeamsAssignedNotification: React.FC = () => (
  <NotificationBar bgColor='#fff2e4' className={notificationClassName} fgColor='#d99b59' iconName='warning'>
    {strings.requirements.noTeamsAssigned}
  </NotificationBar>
);

const notificationClassName = css`
  justify-content: flex-start;
  max-width: 62.5rem;
  margin-bottom: 0.25rem;
`;
