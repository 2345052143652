import { Data } from '@taraai/types';
import HomeLayout from 'components/app/layouts/HomeLayout';
import { usePathParams } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { selectPreferredTeamId, selectProfile, selectTeam, selectUserTeams } from 'reduxStore';
import { selectOrganization } from 'reduxStore/organization/selectors';
import { useConnectGitHubAccount } from 'tools/utils/hooks/useConnectExternalAccount';
import { useInstallGitHub } from 'tools/utils/hooks/useInstallIntegration';

/**
 * HomeController contains high level data for that is used throughout the home page
 *
 */
export default function HomeController(): JSX.Element | null {
  const { orgID } = usePathParams('home');
  const currentOrg = useSelector(selectOrganization(orgID));
  const preferredTeamId = useSelector(selectPreferredTeamId(orgID));
  const preferredTeam = useSelector(selectTeam(orgID, preferredTeamId));

  const userTeams = useSelector(selectUserTeams(orgID));
  const { name } = useSelector(selectProfile);

  const gitHubInstallation = useInstallGitHub();
  const gitHubConnectAccount = useConnectGitHubAccount();

  if (!userTeams) {
    return null;
  }

  if (!isLoaded(preferredTeam)) {
    return null;
  }

  const allTeamsWithActiveSprints = userTeams.filter((team) => team.currentSprintId !== null);

  const otherTeamsWithActiveSprints = allTeamsWithActiveSprints.filter((team) => team.id !== preferredTeamId);

  const allTeamsWithActiveSprintsOrdered = [preferredTeam, ...otherTeamsWithActiveSprints];

  const activeSprintIds = allTeamsWithActiveSprints.map((team) => team.currentSprintId) as Data.Id.SprintId[];

  return (
    <HomeLayout
      activeSprintIds={activeSprintIds}
      allTeamsWithActiveSprintsOrdered={allTeamsWithActiveSprintsOrdered}
      connectGitHubAccountData={gitHubConnectAccount}
      installGitHubData={gitHubInstallation}
      name={name}
      org={{ id: orgID, ...currentOrg }}
    />
  );
}
