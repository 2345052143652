/* eslint-disable sonarjs/no-duplicate-string */

import { EditorCommand } from 'components/editor/editorCommand';
import { takeWhile } from 'components/editor/plugins/utils';
import { RichEditorPlugin } from 'components/editor/types';
import { DraftHandleValue, EditorChangeType, EditorState, SelectionState } from 'draft-js';

import { linkDecorator } from './linkDecorator';
import { replaceAndReconcile } from './replaceAndReconcile';

/**
 * For the purpose of distinguishing between different links, the following definitions are used:
 * - a "standard" link is a link entity that has a URL that matches the output from linkify-it
 * - a "custom" link is a link entity that has text different from its URL
 *
 * Editing a standard link by typing can either change it and its URL or turn it into a plain text.
 * That means a standard link can be surrounded by whitespace, punctuation, or other entities, but
 * it *should not* be surrounded not plain text (but it's possible to tamper with the text).
 *
 * It's possible to turn a standard link into a custom link only by editing its URL via the popup.
 *
 * When editing a custom link's text it's possible to turn it into a standard link if conditions
 * for a standard link are fulfilled (that means being a "custom" link is not a flag, but it is a
 * dynamically computed characteristic).
 */
export const linkPlugin: RichEditorPlugin = {
  decorator: [linkDecorator],
  handleKeyCommand: (command: EditorCommand, editorState: EditorState, eventTimeStamp: number, { setEditorState }) => {
    if (command === 'backspace-to-start-of-line') {
      return 'handled';
    }
    if (command !== 'backspace' && command !== 'backspace-word' && command !== 'delete' && command !== 'delete-word') {
      return 'not-handled';
    }
    const updater = getUpdater('', editorState, setEditorState);
    if (!editorState.getSelection().isCollapsed()) {
      return updater('remove-range');
    }
    if (canAvoidHandlingRemove(command, editorState)) {
      return 'not-handled';
    }
    if (command === 'backspace') {
      return updater('backspace-character', selectCharBefore(editorState));
    }
    if (command === 'backspace-word') {
      return updater('remove-range', selectWordBefore(editorState));
    }
    if (command === 'delete') {
      return updater('delete-character', selectCharAfter(editorState));
    }
    if (command === 'delete-word') {
      return updater('remove-range', selectWordAfter(editorState));
    }
    return 'not-handled';
  },
  handleBeforeInput: (chars, editorState, eventTimeStamp, { setEditorState }) =>
    getUpdater(chars, editorState, setEditorState)('insert-characters'),
  handlePastedText: (text, html, editorState, { setEditorState }) =>
    html ? 'not-handled' : getUpdater(text, editorState, setEditorState)('insert-characters'),
};

function getUpdater(chars: string, editorState: EditorState, setEditorState: (editorState: EditorState) => void) {
  return (editorChangeType: EditorChangeType, selection = editorState.getSelection()): DraftHandleValue => {
    const content = editorState.getCurrentContent();
    const nextContent = replaceAndReconcile(chars, editorState, selection);
    if (nextContent === content) {
      return 'not-handled';
    }
    setEditorState(EditorState.push(editorState, nextContent, editorChangeType));
    return 'handled';
  };
}

function canAvoidHandlingRemove(command: EditorCommand, editorState: EditorState): boolean {
  const selection = editorState.getSelection();
  const backspacingAtStart =
    selection.getStartOffset() === 0 && (command === 'backspace' || command === 'backspace-word');
  const deletingAtEnd =
    selection.getEndOffset() ===
      editorState.getCurrentContent().getBlockForKey(selection.getEndKey()).getText().length &&
    (command === 'delete' || command === 'delete-word');
  return backspacingAtStart || deletingAtEnd;
}

function selectCharBefore(editorState: EditorState): SelectionState {
  const selection = editorState.getSelection();
  return selection.set('anchorOffset', selection.getAnchorOffset() - 1) as SelectionState;
}

function selectWordBefore(editorState: EditorState): SelectionState {
  const selection = editorState.getSelection();
  const block = editorState.getCurrentContent().getBlockForKey(selection.getAnchorKey());
  const { offset: textOffset } = takeWhile(block, selection.getAnchorOffset(), true, { bailOnText: true });
  const { offset } = takeWhile(block, textOffset, true, {
    bailOnWhitespace: true,
    bailOnPunctuation: true,
  });
  return selection.set('anchorOffset', offset) as SelectionState;
}

function selectCharAfter(editorState: EditorState): SelectionState {
  const selection = editorState.getSelection();
  return selection.set('focusOffset', selection.getFocusOffset() + 1) as SelectionState;
}

function selectWordAfter(editorState: EditorState): SelectionState {
  const selection = editorState.getSelection();
  const block = editorState.getCurrentContent().getBlockForKey(selection.getFocusKey());
  const { offset: textOffset } = takeWhile(block, selection.getFocusOffset(), false, { bailOnText: true });
  const { offset } = takeWhile(block, textOffset, false, {
    bailOnWhitespace: true,
    bailOnPunctuation: true,
  });
  return selection.set('focusOffset', offset) as SelectionState;
}
