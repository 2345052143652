import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { noop, parseLabelsFromPlainText, unique } from '@taraai/utility';
import TaskModalContext from 'components/app/controllers/TaskModalContext';
import RequirementTasksListEditorView from 'components/app/controllers/views/RequirementTasksListEditorView';
import { RichEditorHandle } from 'components/editor/RichEditor';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
  createTask,
  CreateTaskActionPayload,
  getCustomLabels,
  getUsers,
  reduxStore,
  requirementTasks,
  updateTask,
} from 'reduxStore';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';
import { sort } from 'tools/libraries/helpers/sort';
import {
  filterTaraTasks,
  getResults,
  getTaskStatusOptions,
  searchTaraTasks,
} from 'tools/libraries/helpers/tableFilter';

export interface RequirementTasksEditorControllerProps {
  orgID: Data.Id.OrganizationId;
  requirement: UI.UIRequirement;
}

export default function RequirementTasksEditorController({
  orgID,
  requirement,
}: RequirementTasksEditorControllerProps): JSX.Element {
  const teamUsers = useSelector(getUsers(orgID).selector) ?? [];

  const requirementTasksSlice = requirementTasks(orgID, requirement.id);
  useFirestoreConnect(requirementTasksSlice.query);
  const sortedAllTasks = createSelector(requirementTasksSlice.selector, (allTasksData) =>
    sort(allTasksData ?? [], 'createdAt', true),
  );
  const tasksData = useSelector(sortedAllTasks);
  const customLabels = useSelector(getCustomLabels(orgID).selector);
  const statusFilterOptions = getTaskStatusOptions();

  // Track the state of whether or not a person is searching and also the search results
  const [searchResults, setSearchResults] = useState<UI.UITask[]>([]);
  const [filterResults, setFilterResults] = useState<UI.UITask[]>([]);
  const [intersectedTasks, setIntersectedTasks] = useState<UI.UITask[]>([]);
  useEffect(() => {
    const combinedResults = getResults(searchResults, filterResults);
    setIntersectedTasks(combinedResults);
  }, [filterResults, searchResults]);
  const finalTasks = createSelector(sortedAllTasks, (sortedAllTasksData) =>
    !intersectedTasks.length ? sortedAllTasksData : intersectedTasks,
  );

  const { openModal } = useContext(TaskModalContext);
  const toggleModal = useCallback((task: UI.UITask) => openModal(task, finalTasks, requirement.title), [
    finalTasks,
    openModal,
    requirement.title,
  ]);

  const { addToast, maybeToast } = useToast();

  const taskEditorRef = useRef<RichEditorHandle | null>(null);
  const onTaskSave = (title: string): void => {
    const labels = unique(parseLabelsFromPlainText(title));

    const onValid = (data: unknown): void => {
      /**
       * `TaskPartial` validation will remove `requirement` property
       * because it is not a part of `TaskPartial` type. That's why
       * we need to manually add it to `createTask` action payload.
       */
      reduxStore
        .dispatch(createTask({ ...(data as CreateTaskActionPayload), requirement: requirement.id }))
        .then(unwrapResult)
        .then((slug) => {
          taskEditorRef.current?.clear();
          segment.track('TaskCreated', { orgID, slug, location: 'Requirement' });
        })
        .catch(noop);
    };
    maybeToast({ title, labels }, 'TaskPartial', onValid);
  };

  const addAssignee = useCallback(
    (taskID: string) => (assigneeID: string): void => {
      reduxStore
        .dispatch(updateTask({ id: taskID, assignee: assigneeID }))
        .then(unwrapResult)
        .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
    },
    [addToast],
  );

  const removeAssignee = useCallback(
    (taskID: string) => (): void => {
      reduxStore
        .dispatch(updateTask({ id: taskID, assignee: null }))
        .then(unwrapResult)
        .catch((error: Error) => addToast({ type: 'error', message: error.message }));
    },
    [addToast],
  );

  /**
   * handleSearch accepts a string that then uses fusejs to search the collection
   */
  const handleSearch = useCallback(
    (query: string) => setSearchResults(query.length ? searchTaraTasks(tasksData, query) : []),
    [tasksData],
  );

  const handleFilter = useCallback(
    (collection: keyof UI.UITask, items: (string | number)[]) =>
      setFilterResults(items.length ? filterTaraTasks(tasksData, collection, items) : []),
    [tasksData],
  );

  return (
    <RequirementTasksListEditorView
      addAssignee={addAssignee}
      addFeatureRef={taskEditorRef}
      customLabels={customLabels}
      handleFilter={handleFilter}
      handleSearch={handleSearch}
      onTaskSave={onTaskSave}
      orgID={orgID}
      removeAssignee={removeAssignee}
      statusFilterOptions={statusFilterOptions}
      tasksData={intersectedTasks.length > 0 ? intersectedTasks : tasksData}
      team={teamUsers}
      toggleModal={toggleModal}
    />
  );
}
