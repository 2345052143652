import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import { MenuOption } from 'components/core/controllers/views/Menu/menuTypes';
import { css } from 'emotion';
import React from 'react';

export interface SortMenuProps {
  isFiltering?: boolean;
  options: MenuOption[];
}

/**
 * SortMenu renders a clickable icon along with a menu with a list of options
 * @param isFiltering - Whether or not a filter is currently active
 * @param options - An array of options
 */
export default function SortMenu({ options, isFiltering = false }: SortMenuProps): JSX.Element {
  return (
    <Menu options={options} position='bottom' showSelection sortFilterMenu>
      <Icon
        className={css`
          fill: #949caf;
          width: 0.875rem;
          height: 1.375rem;
          padding: 0.3125rem 0.125rem 0rem 0.5rem;
          cursor: pointer;
          outline: 0;
        `}
        name={isFiltering ? 'sortActive' : 'sortInactive'}
      />
    </Menu>
  );
}
