import { Tooltip } from '@material-ui/core';
import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { close as TaraIconClose } from 'resources/assets/icons/tara';
import { atomic } from 'resources/theme/atomic';

type TagProps = {
  label: string;
  invalid: boolean;
  invalidMessage: string;
  onRemove: () => void;
};

export const Tag: React.FC<TagProps> = ({ label, invalid, invalidMessage, onRemove }: TagProps) => {
  const fontColor = atomic.getValue(invalid ? atomic.colors.white : atomic.colors.dark);

  return (
    <Tooltip
      arrow
      disableFocusListener={!invalid}
      disableHoverListener={!invalid}
      disableTouchListener={!invalid}
      enterDelay={0}
      interactive
      placement='bottom'
      title={invalidMessage}
      TransitionProps={{ timeout: 600 }}
    >
      <TagContainer invalidColorStyle={invalid}>
        {!invalid && <EmailIcon name='email' />}
        <span>{label}</span>
        <CloseIcon fill={fontColor} onClick={onRemove} />
      </TagContainer>
    </Tooltip>
  );
};

const EmailIcon = styled(Icon, { padding: '0rem', paddingRight: '0.3125rem' });

const CloseIcon = styled(TaraIconClose, {
  width: '0.5rem',
  height: '0.5rem',
  marginLeft: '0.375rem',
  cursor: 'pointer',
});

const TagContainer = styled(
  'div',
  {
    padding: '0.25rem 0.375rem',
    backgroundColor: '$grey2',
    color: '$dark',
    borderRadius: '0.1875rem',
    fontSize: '0.875rem',
    lineHeight: '1',
    marginRight: '0.5rem',
    marginBottom: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'default',
  },
  {
    invalidColorStyle: { true: { color: 'white', backgroundColor: '$failure' } },
  },
);
