import LegacyFeatureCard from 'components/app/controllers/LegacyFeatureCard';
import React, { ComponentProps, useEffect } from 'react';
import { useDragFeatureCard } from 'tools/utils/hooks/dragAndDrop';

interface Props extends ComponentProps<typeof LegacyFeatureCard> {
  onDragStart: () => void;
  onDragStop: () => void;
}

const DraggableFeatureCard: React.FC<Props> = ({
  task,
  onMoveToBacklog,
  onMoveToSprint,
  onDragStart,
  onDragStop,
  selectSprint,
  sprintColumn,
  ...props
}) => {
  const [{ isDragging }, dragRef] = useDragFeatureCard(task);

  // This is used instead of `begin` and `end` callbacks in `useDrag` hook,
  // to prevent lag from breaking drag&drop (monitor.isDragging() returning
  // true one render after `begin()` runs, preventing drag action)
  useEffect(() => {
    if (isDragging) {
      onDragStart();
    } else {
      onDragStop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const showCard = !isDragging;

  return (
    <div style={{ cursor: 'pointer', position: 'relative' }}>
      <div
        style={{
          marginBottom: showCard ? '0.5rem' : undefined,
        }}
      >
        <div
          ref={dragRef}
          style={{
            height: showCard ? undefined : 0,
          }}
        >
          <LegacyFeatureCard
            onMoveToBacklog={onMoveToBacklog}
            onMoveToSprint={onMoveToSprint}
            selectSprint={selectSprint}
            sprintColumn={sprintColumn}
            task={task}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

export default DraggableFeatureCard;
