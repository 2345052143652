import { unwrapResult } from '@reduxjs/toolkit';
import WorkspaceLogoView, { LogoChangeStatus } from 'components/app/controllers/views/WorkspaceLogoView';
import { usePathParams } from 'components/Router/paths';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { isProfileAdmin, reduxStore, removeOrganizationLogo, setOrganizationLogo } from 'reduxStore';
import { selectOrganization } from 'reduxStore/organization/selectors';
import { strings } from 'resources';
import { useToast } from 'tools';

export default function WorkspaceLogoController(): JSX.Element {
  const { orgID } = usePathParams('workspace');
  const org = useSelector(selectOrganization(orgID));
  const orgStanza = {
    id: orgID,
    ...org,
  };

  const isAdmin = useSelector(isProfileAdmin(orgID));

  const { addToast } = useToast();

  const [changeStatus, setChangeStatus] = useState<LogoChangeStatus>('none');

  const handleError = useCallback(
    (error: Error): void => {
      const message = strings
        .formatString(strings.workspaceLogo.changeError, {
          errorMessage: error.message,
        })
        .toString();
      addToast({
        message,
        timeoutMs: 5500,
        type: 'error',
      });
    },
    [addToast],
  );

  const handleUploadWorkspaceLogo = useCallback(
    (file: File): void => {
      setChangeStatus('uploading');
      reduxStore
        .dispatch(setOrganizationLogo({ file }))
        .then(unwrapResult)
        .then(() => {
          return addToast({
            message: strings.workspaceLogo.successfullyUploaded,
            timeoutMs: 3000,
            type: 'success',
          });
        })
        .finally(() => setChangeStatus('none'))
        .catch(handleError);
    },
    [addToast, handleError, setChangeStatus],
  );

  const handleRemoveWorkspaceLogo = useCallback((): void => {
    setChangeStatus('removing');
    reduxStore
      .dispatch(removeOrganizationLogo())
      .then(unwrapResult)
      .then(() => {
        return addToast({
          message: strings.workspaceLogo.successfullyRemoved,
          timeoutMs: 3000,
          type: 'success',
        });
      })
      .finally(() => setChangeStatus('none'))
      .catch(handleError);
  }, [addToast, handleError, setChangeStatus]);

  return (
    <WorkspaceLogoView
      canChange={isAdmin}
      changeStatus={changeStatus}
      handleRemoveWorkspaceLogo={handleRemoveWorkspaceLogo}
      handleUploadWorkspaceLogo={handleUploadWorkspaceLogo}
      org={orgStanza}
    />
  );
}
