import React, { useCallback, useRef, useState } from 'react';
import { useVisibility } from 'tools';

import StickyContainer from './StickyContainer';
import TopSpace from './TopSpace';

export type StickyPageHeaderProps = {
  children: React.ReactNode;
  prefixComponent?: React.ReactNode;
};

export default function StickyPageHeader({ children, prefixComponent }: StickyPageHeaderProps): JSX.Element {
  const [topSpaceVisible, setTopSpaceVisible] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const handleStyleChangeOnScroll = useCallback((entry: IntersectionObserverEntry) => {
    setTopSpaceVisible(entry.intersectionRatio === 1.0);
  }, []);
  useVisibility(ref, handleStyleChangeOnScroll, null, '0px', 1.0);

  return (
    <>
      <TopSpace ref={ref} />
      {prefixComponent}
      <StickyContainer shadowVisible={topSpaceVisible}>{children}</StickyContainer>
    </>
  );
}
