import TaskController from 'components/app/controllers/TaskController';
import React from 'react';
import { useParams } from 'react-router-dom';

/**
 * TaskPage
 * page for deep link task
 *
 */
export default function TaskPage(): JSX.Element {
  const { taskSlug } = useParams<{ orgID: string; taskSlug: string }>();

  return <TaskController key={taskSlug} slugID={taskSlug} />;
}
