import { HStack, styled } from '@taraai/design-system';
import { Identifiable } from '@taraai/types/src';
import React, { useEffect, useMemo, useRef } from 'react';

type Props<Item extends Identifiable> = {
  anchorLeft: number;
  items: Item[];
  renderItem: (item: Item, ref: React.RefObject<HTMLDivElement>, offsetFromSelected: number) => JSX.Element;
  selectedId: string;
};

export function ScrollPane<Item extends Identifiable>({
  anchorLeft,
  items,
  renderItem,
  selectedId,
}: Props<Item>): JSX.Element {
  const paneRef = useRef<HTMLDivElement>(null);
  const oldSelectedId = useRef(selectedId);
  const itemsWithRefs = useMemo(() => {
    return items.map((item) => ({ item, ref: React.createRef<HTMLDivElement>() }));
  }, [items]);
  const selectedItemRef = itemsWithRefs.find(({ item }) => item.id === selectedId);

  const firstRender = useRef(true);
  useEffect(() => {
    if (selectedItemRef && paneRef.current) {
      const itemOffset = selectedItemRef.ref.current?.offsetLeft ?? 0;
      const scrollPosition = itemOffset - anchorLeft;
      // scroll without animation on initial render
      paneRef.current.scrollTo({ top: 0, left: scrollPosition, behavior: !firstRender.current ? 'smooth' : undefined });
      // We only want the Pane to load first time in the correct sprint,
      // without previous animation ( smooth scroll )
      // Otherwise, we want it to scroll smoothly
      if (firstRender.current) {
        firstRender.current = false;
      }
    }
    oldSelectedId.current = selectedId;
  }, [selectedId, anchorLeft, selectedItemRef]);

  return (
    <Wrapper ref={paneRef}>
      <HStack style={{ paddingLeft: anchorLeft }}>
        {itemsWithRefs.map(({ item, ref }, idx) => {
          const selectedItemIdx = itemsWithRefs.findIndex((itemWithRef) => itemWithRef.item.id === selectedId);
          const offsetFromSelected = idx - selectedItemIdx;
          return renderItem(item, ref, offsetFromSelected);
        })}
        <Filler />
      </HStack>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  width: '100%',
  height: '100%',
  overflowX: 'hidden',
});

// FIXME: this should not be needed
const Filler = styled('div', {
  width: '100%',
});
