import { Data, UI } from '@taraai/types';
import SprintColumnsLayout from 'components/app/layouts/SprintColumnsLayout';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import getSprintsWindow from 'reduxStore/sprints/queries/getSprintsWindow';
import { usePagination } from 'tools/utils/hooks/usePagination';

// these values determine trigger points at which additional page will be loaded
const prevLoadOverscan = 1;
const nextLoadOverscan = 3;
const pageSize = 6; // this should be even

type SprintFragment = Pick<
  UI.UISprint,
  'id' | 'sprintName' | 'initialStartDate' | 'initialEndDate' | 'isComplete' | 'computedOnCompletion'
>;

const mapSprints = (arr: UI.UISprint[] | undefined, currentSprintId: string): SprintFragment[] | undefined =>
  arr?.map((sprint: UI.UISprint) => {
    return {
      id: sprint.id,
      sprintName: sprint.sprintName,
      initialStartDate: sprint.initialStartDate,
      initialEndDate: sprint.initialEndDate,
      isComplete: sprint.isComplete,
      computedOnCompletion: sprint.computedOnCompletion,
    };
  });

type Props = {
  currentSprint: UI.UISprint;
};

/**
 * SprintColumnsController is responsible for the sprints moving window columns page data management
 * Accesses higher level data that will be passed to multiple child components in SprintColumnsLayout
 */
export default function SprintColumnsController({ currentSprint }: Props): JSX.Element | null {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();

  const [selectedSprintId, setSelectedSprintId] = useState(currentSprint.id);

  const { items: sprints, loadNextPage, loadPrevPage } = usePagination(
    pageSize,
    // initialEndDate will always be there
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    currentSprint.initialEndDate!,
    (limitBefore, limitAfter, pivotEndDate) =>
      getSprintsWindow(orgID, teamID, pivotEndDate, {
        limitBefore,
        limitAfter,
      }),
  );

  const sprintFragments = useMemo(() => mapSprints(sprints, currentSprint.id), [sprints, currentSprint.id]);
  const selectedSprintIdx = sprintFragments?.findIndex((sprint) => sprint.id === selectedSprintId);

  useEffect(() => {
    if (sprintFragments) {
      const selectedSpIdx = sprintFragments.findIndex((sprint) => sprint.id === selectedSprintId);
      if (selectedSpIdx <= prevLoadOverscan) {
        loadPrevPage();
      } else if (selectedSpIdx >= sprintFragments.length - 1 - nextLoadOverscan) {
        loadNextPage();
      }
    }
  }, [selectedSprintId, sprintFragments, loadNextPage, loadPrevPage]);

  const selectNextSprint = (): void => {
    if (sprintFragments && selectedSprintIdx !== undefined) {
      const nextSprint = sprintFragments[selectedSprintIdx + 1];
      if (nextSprint) {
        setSelectedSprintId(nextSprint.id);
      }
    }
  };

  const selectPrevSprint = (): void => {
    if (sprintFragments && selectedSprintIdx !== undefined) {
      const prevSprint = sprintFragments[selectedSprintIdx - 1];
      if (prevSprint) {
        setSelectedSprintId(prevSprint.id);
      }
    }
  };

  return (
    <SprintColumnsLayout
      currentSprintId={currentSprint.id}
      onSelectCurrentSprint={() => setSelectedSprintId(currentSprint.id)}
      onSelectNextSprint={selectNextSprint}
      onSelectPrevSprint={selectPrevSprint}
      selectedSprintId={selectedSprintId}
      sprints={sprintFragments}
    />
  );
}
