import styled from '@emotion/styled';

export const BacklogTasksOutline = styled.div`
  border: solid 0.125rem #1d98ff;
  border-radius: 0.275rem;
  height: 10.6875rem;
  left: 4.6875rem;
  opacity: 0;
  position: absolute;
  top: 13.6875rem;
  width: 18.1875rem;
  z-index: 2;
`;
