import { styled } from '@taraai/design-system';

/**
 * A short description for Card.
 *
 */
export const Card = styled(
  'div',
  {
    backgroundColor: '$white',
    borderRadius: '0.1875rem',
    color: '$dark',
  },
  {
    background: {
      transparent: { backgroundColor: 'transparent' },
      todo: { backgroundColor: '#fcf9f2' },
      doing: { backgroundColor: '#f3faff' },
      done: { backgroundColor: '#f5faf3' },
    },
    disabled: {
      true: {
        filter: 'grayscale(100%) opacity(50%)',
        pointerEvents: 'none',
      },
    },
    withBorder: {
      true: { border: 'borderWidths.$1px solid colors.$grey3' },
      false: { borderRadius: 0 },
    },
  },
);

Card.defaultProps = {
  role: 'button',
  tabIndex: 0,
  withBorder: true,
};
