import { createAsyncThunk } from '@reduxjs/toolkit';
import { UISprintPartial } from '@taraai/types/dist/ui';
import { UISprintChangeset } from '@taraai/types/src/ui';
import { isNonEmptyString } from '@taraai/utility';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI, Firestore } from 'reduxStore/utils/types';
import { strings } from 'resources';

type UpdateSprintPayload = UISprintChangeset & {
  meta?: {
    transaction?: Firestore['Transaction'];
  };
};

export const updateSprint = createAsyncThunk(
  'UpdateSprint',
  async ({ id: sprintID, meta, ...propsToSet }: UpdateSprintPayload, { extra }) => {
    const { getOrgID, getFirestore } = extra as ExtraAPI;
    const orgID = getOrgID();
    if (!isNonEmptyString(sprintID)) throw strings.sprints.error.missingSprintId;
    const firestore = getFirestore();
    const path = `orgs/${orgID}/sprints/${sprintID}`;

    const { id, ...validChanges } = decode<UISprintPartial>(
      {
        id: sprintID,
        ...propsToSet,
        updatedAt: firestore.Timestamp.now(),
      },
      'UISprintPartial',
    );
    if (meta?.transaction) {
      meta.transaction.update(firestore.doc(path), validChanges);
    } else {
      await firestore.update(path, validChanges);
    }
    return { id, ...validChanges };
  },
);
