/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { UI } from '@taraai/types';
import { isNonEmptyString, noop } from '@taraai/utility';
import Avatar from 'components/core/controllers/views/Avatar';
import { css, cx } from 'emotion';
import Fuse from 'fuse.js';
import React, { useEffect, useRef, useState } from 'react';
import { atomic } from 'resources';
import { fuseMatchToSubstrings } from 'tools/utils/fuse-utils';

const UserDropdownElement = ({
  children,
  user,
  selected,
  onClick,
}: {
  children: React.ReactNode;
  user: UI.UIUser;
  selected: boolean;
  onClick: (event: React.MouseEvent) => void;
}): JSX.Element => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selected && elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  });

  return (
    <div
      ref={elementRef}
      className={cx(
        css`
          padding: 8px 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          &:hover,
          &.selected {
            background-color: ${atomic.get(atomic.colors.primary)};
            color: white;
          }

          & > * + * {
            margin-left: 8px;
          }
        `,
        { selected },
      )}
      // this has to be onMouseDown to prevent
      // DraftEditor from stealing focus
      onMouseDown={onClick}
    >
      <Avatar size='24px' user={user} />
      <div
        className={css`
          flex: 1;
        `}
      >
        {children}
      </div>
    </div>
  );
};

const fuseOptions = {
  shouldSort: true,
  includeMatches: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  ignoreLocation: true,
  maxPatternLength: 32,
  minMatchCharLength: 0,
  keys: ['name'],
};

type UserDropdownProps = {
  users: UI.UIUser[];
  searchQuery: string;
  onUserSelect: (user: UI.UIUser) => void;
};

type DropDirection = 'up' | 'down';

const pickDropDirectionForRef = (ref: React.RefObject<HTMLDivElement>): DropDirection => {
  if (!ref.current) {
    return 'down';
  }

  const currentElementYPosition = ref.current.getBoundingClientRect().top;
  const browserViewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  return currentElementYPosition > browserViewportHeight / 2 ? 'up' : 'down';
};

export const UserDropdown = ({ users, searchQuery, onUserSelect }: UserDropdownProps): JSX.Element => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [dropDirection, setDropDirection] = useState<DropDirection>('down');

  useEffect(() => {
    setDropDirection(pickDropDirectionForRef(rootRef));
  }, [rootRef]);

  const fuse = new Fuse(users, fuseOptions);
  const filteredUsers = isNonEmptyString(searchQuery)
    ? fuse.search(searchQuery)
    : users.map<Fuse.FuseResult<UI.UIUser>>((user, idx) => ({
        item: user,
        refIndex: idx,
      }));

  // if selection is ever in a invalid state
  // reset it to the valid one
  useEffect(() => {
    if (selectedIdx < 0 || selectedIdx >= filteredUsers.length) {
      setSelectedIdx((current) => (filteredUsers.length + current) % filteredUsers.length);
    }
  }, [selectedIdx, filteredUsers.length]);

  // this ref is used in order to "update"
  // handleSelect function with new selectedUser
  // without the need to remove/add new listener for keydown
  const handleSelectRef = useRef(noop);
  useEffect(() => {
    handleSelectRef.current = (): void => {
      const selectedUser = filteredUsers[selectedIdx];
      if (selectedUser) {
        onUserSelect(selectedUser.item);
      }
    };
  }, [selectedIdx, onUserSelect, filteredUsers]);

  const moveSelection = (diff: 1 | -1): void => {
    setSelectedIdx((current) => current + diff);
  };

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        if (event.key === 'ArrowUp') {
          moveSelection(-1);
        } else if (event.key === 'ArrowDown') {
          moveSelection(1);
        } else if (event.key === 'Enter' || event.key === 'Tab') {
          handleSelectRef.current();
        }
      }
    };

    document.addEventListener('keydown', handler, true);
    return (): void => {
      document.removeEventListener('keydown', handler, true);
    };
  }, []);

  return (
    <div
      ref={rootRef}
      className={css`
        position: absolute;
        background: white;
        width: 300px;
        max-height: 200px;
        border-radius: 3px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
        border: solid 1px ${atomic.get(atomic.colors.grey5)};
        ${dropDirection === 'up' ? 'bottom: 1.2em;' : 'top: 0;'}
        overflow-y: auto;
        padding: 8px 0;
      `}
      contentEditable={false}
    >
      {filteredUsers.map(({ item: user, matches }, idx) => {
        const matchedText = matches?.map(fuseMatchToSubstrings)[0];

        return (
          <UserDropdownElement
            key={user.id}
            onClick={(event): void => {
              event.stopPropagation();
              onUserSelect(user);
            }}
            selected={selectedIdx === idx}
            user={user}
          >
            {matchedText
              ? matchedText.map(({ matched, value, start, end }) =>
                  matched ? (
                    <strong
                      key={`match-${start}:${end}`}
                      className={css`
                        text-decoration: underline;
                      `}
                    >
                      {value}
                    </strong>
                  ) : (
                    <span key={`${start}:${end}`}>{value}</span>
                  ),
                )
              : user.name}
          </UserDropdownElement>
        );
      })}
    </div>
  );
};
