import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, Functions } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

type MakeInviteLink = {
  teamId: Data.Id.TeamId;
};

export const makeInviteLink = createAsyncThunk(
  'MakeInviteLink',
  async ({ teamId }: MakeInviteLink, { extra, getState }) => {
    const { getFirebase, getOrgID, getUserID } = extra as ExtraAPI;

    const state = getState() as RootState;
    const userId = getUserID(state);

    const organizationId = getOrgID();

    const payloadDraft: Functions.MakeInviteLink.Payload = {
      organizationId,
      userId,
      teamId,
    };

    const payload = decode<Functions.MakeInviteLink.Payload>(payloadDraft, 'MakeInviteLinkPayload');

    const response = await getFirebase().functions().httpsCallable('makeInviteLink')(payload);

    if (response.data.status === 'error') {
      throw new Error(response.data.errors[0]?.error);
    }
  },
);
