import { css, cx } from 'emotion';
import React from 'react';

export interface DropdownProps extends React.HTMLProps<HTMLDivElement> {
  children: JSX.Element | JSX.Element[] | string;
  header?: JSX.Element | boolean | string;
  footer?: JSX.Element | boolean;
  Ref: React.RefObject<HTMLDivElement>;
  positionCSS?: string;
  widthCSS?: string;
}

/**
 * Dropdown
 * dropdown that supports search and buttons
 *
 */
export default function Dropdown({
  className,
  children,
  header,
  footer,
  Ref,
  positionCSS,
  widthCSS,
  ...props
}: DropdownProps): JSX.Element {
  return (
    <div
      ref={Ref}
      {...props}
      className={cx(
        css`
          background-color: #ffffff;
          width: 18.75rem;
          position: absolute;
          z-index: 2500;
          border-radius: 0.1875rem;
          border: solid 0.0625rem #c8d0df;
          box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.14);
          right: ${!positionCSS && '0px'};
        `,
        positionCSS,
        widthCSS,
      )}
    >
      <div>{header}</div>
      <div
        className={cx(
          css`
            max-height: 15.625rem;
            overflow: auto;
          `,
          className,
        )}
        {...props}
      >
        {children}
      </div>
      <div>{footer}</div>
    </div>
  );
}
