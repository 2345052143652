import { Global } from '@emotion/core';
import { css } from 'emotion';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

type PositionedPortalProps = {
  elementID: string;
  zIndex?: number;
};

/**
 * Behaves like `ReactDOM.createPortal()` but puts its children
 * into a wrapper that tries to render them at the same position
 * on the screen that originaly they would be placed.
 */
export const PositionedPortal: React.FC<PositionedPortalProps> = ({ children, elementID, zIndex = 1000 }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      setBoundingRect(wrapperRef.current.getBoundingClientRect());
    }
  }, [wrapperRef]);

  const targetNode = document.getElementById(elementID);
  if (!targetNode) {
    throw new Error(`Tried to use PositionedPortal with target node #${elementID}, but no such node found`);
  }

  const targetStyles = css`
    #${elementID} {
      z-index: ${zIndex};
      position: fixed;
      top: 0;
      left: 0;
    }
  `;

  return (
    <div ref={wrapperRef}>
      <Global styles={targetStyles} />
      {ReactDOM.createPortal(
        // don't render anything before the position was established
        boundingRect ? (
          <PositionWrapper left={boundingRect.left} top={boundingRect.top} zIndex={zIndex}>
            {children}
          </PositionWrapper>
        ) : null,
        targetNode,
      )}
    </div>
  );
};

type PositionWrapperProps = {
  top: number;
  left: number;
  zIndex: number;
};

const PositionWrapper: React.FC<PositionWrapperProps> = ({ top, left, children, zIndex }) => {
  return (
    <div
      className={css`
        z-index: ${zIndex};
        position: absolute;
        left: ${left}px;
        top: ${top}px;
      `}
    >
      {children}
    </div>
  );
};
