import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { updateOrganization } from 'reduxStore/organization';
import { ExtraAPI } from 'reduxStore/utils';
import { decode } from 'reduxStore/utils/decoders';

type SkipOnboardingPayload = {
  orgId: Data.Id.OrganizationId;
};

export const skipOnboarding = createAsyncThunk(
  'SkipOnboarding',
  async ({ orgId }: SkipOnboardingPayload, { dispatch, extra }) => {
    const { getFirestore } = extra as ExtraAPI;
    const firestore = getFirestore();

    return firestore.runTransaction(async (transaction) => {
      const orgChangeset = decode<UI.UIOrganizationPartial>(
        {
          id: orgId,
          onboardingStatus: 'finished',
        },
        'UIOrganizationPartial',
        {
          removeAdditional: 'all',
        },
      );

      await dispatch(updateOrganization({ ...orgChangeset, meta: { transaction } }));
    });
  },
);
