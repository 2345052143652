import { Tooltip } from '@material-ui/core';
import { styled } from '@taraai/design-system';
import Tara from '@taraai/types';
import { MaybeReusableLink } from 'components/app/controllers/InviteUsersController';
import Icon from 'components/core/controllers/views/Icon';
import moment from 'moment';
import React, { useState } from 'react';
import { atomic } from 'resources';
import { strings } from 'resources/i18n';
import { toDate } from 'tools/libraries/helpers/dates';

/**
 * A presentational component to render invitation link in invite user popup
 */
export default function InviteLinkSnippet({
  maybeReusableLink,
}: {
  maybeReusableLink: MaybeReusableLink | undefined;
}): JSX.Element {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyInvite = (): void => {
    if (maybeReusableLink?.invitationLink) {
      navigator.clipboard.writeText(maybeReusableLink.invitationLink);
      setIsLinkCopied(true);
    }
  };

  const formatDate = (date: Tara.Timestamp): string =>
    `${strings.inviteUserPopup.validTill}
     ${moment(toDate(date)).format('LL')}
     ${moment(toDate(date)).format('LT')}`;

  return (
    <>
      <Wrapper>
        <LinkWrapper>{maybeReusableLink?.invitationLink}</LinkWrapper>
        <Tooltip
          arrow
          enterDelay={0}
          interactive
          placement='top'
          title={
            <TooltipText>{isLinkCopied ? strings.inviteUserPopup.copied : strings.inviteUserPopup.copy}</TooltipText>
          }
          TransitionProps={{ timeout: 600 }}
        >
          <StyledIcon
            color={atomic.colors.focus}
            name='copyInvite'
            onClick={copyInvite}
            onMouseEnter={(): void => setIsLinkCopied(false)}
          />
        </Tooltip>
      </Wrapper>
      {maybeReusableLink?.expiredAt && <ExpirationDate>{formatDate(maybeReusableLink.expiredAt)}</ExpirationDate>}
    </>
  );
}

const Wrapper = styled('div', {
  backgroundColor: '$white',
  border: 'borderWidths.$1px solid colors.$grey4',
  borderRadius: '0.1875rem',
  padding: '0.5rem',
  marginLeft: '1rem',
  marginRight: '1rem',
  marginBottom: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '2.37rem',
});

const LinkWrapper = styled('div', {
  color: '$grey7',
  fontSize: '0.75rem',
  fontWeight: '500',
  fontFamily: 'SourceCodePro',
  overflow: 'hidden',
});

const TooltipText = styled('div', {
  color: '$white',
  fontSize: '0.875rem',
  fontWeight: 'normal',
  padding: '0.1875rem',
  lineHeight: '1rem',
  maxWidth: '15.625rem',
});

const StyledIcon = styled(Icon, {
  padding: '0rem',
  display: 'flex',
  cursor: 'pointer',
});

const ExpirationDate = styled('div', {
  color: '$grey6',
  fontSize: '.75rem',
  lineHeight: '1rem',
  marginLeft: '1rem',
  marginBottom: '1rem',
});
