import { UI } from '@taraai/types';
import DraggableFeatureCard from 'components/app/controllers/views/DraggableFeatureCard';
import { DnDContext, DragSource } from 'components/app/controllers/views/DraggableFeatureCard/DragAndDropContext';
import { css, cx } from 'emotion';
import React, { useContext } from 'react';

/**
 * sprintColumn prop is used to resize width of dropdown
 */

type Props = {
  tasks: UI.UITask[];
  onMoveToSprint: (taskRef: string, sprintId: string) => void;
  onMoveToBacklog: (taskRef: string) => void;
  dragSource: DragSource;
  sprintView?: boolean;
  selectSprint?: boolean;
  className?: string;
  toggleModal: (task: UI.UITask) => void;
  sprintColumn?: boolean;
};

export const DraggableFeatureCardList: React.FC<Props> = ({
  tasks = [],
  onMoveToSprint,
  onMoveToBacklog,
  sprintView = false,
  selectSprint = false,
  dragSource,
  toggleModal,
  sprintColumn,
  className,
}: Props) => {
  const { notifyDragStop, notifyDragStart } = useContext(DnDContext);

  return (
    <div
      className={cx(
        css`
          overflow: auto;
          padding: 0.5rem 0.5rem;
          padding-bottom: 0rem;
          height: 100%;
        `,
        className,
      )}
    >
      {tasks.map((task: UI.UITask) => (
        <DraggableFeatureCard
          key={task.slug}
          onClick={(): void => toggleModal(task)}
          onDragStart={(): void => notifyDragStart(dragSource, task)}
          onDragStop={notifyDragStop}
          onMoveToBacklog={onMoveToBacklog}
          onMoveToSprint={onMoveToSprint}
          selectSprint={selectSprint}
          sprintColumn={sprintColumn}
          sprintView={sprintView}
          task={task}
        />
      ))}
    </div>
  );
};
