import { Data } from '@taraai/types';
import { intercomUpdateCompany } from 'intercom';
import React, { useEffect } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Redirect, useLocation, useParams } from 'react-router';
import { compose } from 'redux';
import {
  getCustomLabels,
  getOrg,
  getOrgTeams,
  getUsers,
  listOrganizationUsers,
  reduxStore,
  selectActiveOrgIds,
  selectPreferredTeamId,
  selectUserTeams,
} from 'reduxStore';
import { getTeamID } from 'route-utils';
import { segment } from 'tools/libraries/analytics';

import { getRouteName, linkTo } from './paths';

/**
 * Shows spinner until organization document is loaded from firestore
 */
export const OrgAndTeamIsLoaded: React.FC = ({ children }): JSX.Element | null => {
  const location = useLocation();
  const { orgID, ...params } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const orgSlice = getOrg(orgID);
  const userSlice = getUsers(orgID);
  const teamSlice = getOrgTeams(orgID);
  const labelsSlice = getCustomLabels(orgID);

  const orgName = useSelector(compose((data) => data?.name, orgSlice.selector));
  const uids = useSelector(
    compose((data) => data?.map(({ id }) => id), userSlice.selector),
    deepEquals,
  );
  const teamNames = useSelector(
    compose((data) => data?.map(({ name }) => name), teamSlice.selector),
    deepEquals,
  );
  const profileHasAccessToOrg = useSelector(
    compose((organizationIds) => organizationIds.includes(orgID), selectActiveOrgIds),
  );

  useFirestoreConnect([...orgSlice.query, ...userSlice.query, ...teamSlice.query, ...labelsSlice.query]);

  const userTeams = useSelector(selectUserTeams(orgID), deepEquals);

  useEffect(() => {
    segment.multiGroup(userTeams.map((team) => ({ ...team, orgID })));
  }, [orgID, userTeams]);

  // When the org, user custom-claims or users in org changes get the new access levels
  useEffect(() => {
    // theoretically user without custom claims should never get here
    // to be 100% sure that user has rights to request organization data
    // there should be another layer of abstraction checking that
    // this is not part of a hotfix so ticket for that: https://app.tara.ai/tara-ai/tasks/2273
    if (profileHasAccessToOrg) {
      reduxStore.dispatch(listOrganizationUsers());
    }
  }, [profileHasAccessToOrg]);

  // Each time user switches workspace, re-initialize Intercom.
  // TODO: Consider re-initializing Fullstory and Firebase Analytics.
  useEffect(() => {
    intercomUpdateCompany(orgID);
  }, [orgID]);

  const preferredTeamID = useSelector(selectPreferredTeamId(orgID));
  const routeTeamID = getTeamID();
  const orgTeams = useSelector(getOrgTeams(orgID).selector);

  const teamID = routeTeamID && orgTeams?.some(({ id }) => id === routeTeamID) ? routeTeamID : undefined;

  if (!isLoaded(orgName) || !isLoaded(uids) || !isLoaded(teamNames)) {
    return null;
  }

  /**
   * We need support for legacy links without team ID in the URL
   * for tasks and requirements.
   */
  const isLegacyRoute = getRouteName().startsWith('LEGACY_');
  if (isLegacyRoute && !teamID) {
    const routeWithPreferredTeam = location.pathname.replace(orgID, `${orgID}/${preferredTeamID}`);

    return <Redirect to={routeWithPreferredTeam} />;
  }

  if (!teamID) {
    return (
      <Redirect
        to={linkTo(getRouteName(), {
          ...params,
          orgID,
          teamID: preferredTeamID,
          // TODO: linkTo requires static params but in order to have global
          //       navigation the route must be a variable
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any)}
      />
    );
  }

  return <>{children}</>;
};
