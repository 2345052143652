import { styled } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import Text from 'components/core/controllers/views/Text';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { strings } from 'resources';
import { workspaceTestIDs } from 'resources/cypress/testAttributesValues';

import WorkspaceItem from './WorkspaceItem';

type WorkspaceSwitcherPopupProps = {
  userOrgs: UI.UIOrganization[];
  selectedOrgID: Data.Id.OrganizationId;
  containerRef?: React.RefObject<HTMLDivElement>;
};

export function WorkspaceSwitcherPopup({
  userOrgs,
  selectedOrgID,
  containerRef,
}: WorkspaceSwitcherPopupProps): JSX.Element {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();
  return (
    <Container ref={containerRef}>
      <WorkspaceList>
        <WorkspaceListHeadline>{strings.workspaceSwitcher.switchWorkspace}</WorkspaceListHeadline>
        {userOrgs.map((org) => (
          <WorkspaceItem key={org.id} org={org} selectedOrgID={selectedOrgID} />
        ))}
      </WorkspaceList>
      <OptionsContainer>
        <OptionButton data-cy={workspaceTestIDs.OPEN_SETTINGS} to={linkTo('workspace', { orgID, teamID })}>
          {strings.workspaceSwitcher.workspaceSettings}
        </OptionButton>
        <OptionButton
          data-cy={workspaceTestIDs.CREATE_NEW_BUTTON}
          primary
          to={linkTo('onboarding', { orgID: undefined })}
        >
          {strings.workspaceSwitcher.createNewWorkspace}
        </OptionButton>
      </OptionsContainer>
    </Container>
  );
}

export const Container = styled('div', {
  position: 'absolute',
  left: '1rem',
  top: '2.5rem',
  boxShadow: '$popup',
  border: 'borderWidths.$1px solid colors.$grey5',
  borderRadius: '0.1875rem',
  width: '17.875rem',
  backgroundColor: '$white',
  cursor: 'default',
  pointerEvents: 'auto',
  outline: 'none',
});

export const WorkspaceList = styled('div', {
  maxHeight: '15.25rem',
  overflowY: 'auto',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
});

export const WorkspaceListHeadline = styled(Text, {
  display: 'flex',
  fontSize: '0.75rem',
  fontWeight: '$regular',
  color: '$grey6',
  padding: '$8px $16px',
});

const OptionsContainer = styled('div', {
  border: 'borderWidths.$1px solid colors.$grey5',
  borderWidth: '0.0625rem 0 0 0',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
});

const OptionButton = styled(
  Link,
  {
    'background': 'none',
    'border': 'none',
    'outline': 'inherit',
    'width': '$full',
    'height': '1.75rem',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-start',
    'paddingLeft': '1rem',
    'fontSize': '0.8125rem',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '$grey1',
    },
  },
  {
    primary: {
      true: {
        color: '$focus',
      },
    },
  },
);
