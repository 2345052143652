import { UISprint } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { OrderByOptions, ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createStandardSelector, generateAlias, inertQuery, Query } from 'reduxStore/utils/selectors';

type OrderBy = keyof Pick<UISprint, 'createdAt' | 'sprintNumber'>;

export function getCompletedSprints(
  orgId: string,
  teamId: string,
  options: { orderBy: OrderBy } | undefined = {
    orderBy: 'createdAt',
  },
): Query<UISprint> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(teamId)) {
    return inertQuery();
  }

  const sort: OrderByOptions = options.orderBy === 'sprintNumber' ? ['sprintNumber', 'desc'] : ['createdAt', 'asc'];

  const query: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgId}/sprints`,
    where: [
      ['teamId', '==', teamId],
      ['isComplete', '==', true],
      ['archived', '==', false],
      ['deleted', '==', false],
    ],
    orderBy: sort,
  };
  query.storeAs = generateAlias(query);

  return {
    query: [query],
    selector: createStandardSelector(query),
  };
}
