/* eslint-disable sonarjs/cognitive-complexity */
import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { LabelChip } from 'components/app/controllers/views/LabelChip';
import Fuse from 'fuse.js';
import React, { MouseEventHandler, ReactNode, useEffect, useRef, useState } from 'react';
import { isDefaultLabel } from 'reduxStore';
import { fuseMatchToSubstrings } from 'tools/utils/fuse-utils';

const fuseOptions = {
  shouldSort: true,
  includeMatches: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  ignoreLocation: true,
  maxPatternLength: 32,
  minMatchCharLength: 0,
  keys: ['title'],
};

type DropDirection = 'up' | 'down';

export function LabelSelectorPopup({
  getOptions,
  onCreate,
  onSelect,
  readOnly,
  searchQuery,
}: {
  getOptions: () => UI.UILabel[];
  onCreate: (text: string) => void;
  onSelect: (label: UI.UILabel) => void;
  readOnly?: boolean;
  searchQuery: string;
}): JSX.Element | null {
  const [options] = useState(getOptions);
  const rootRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dropDirection, setDropDirection] = useState<DropDirection>('down');

  useEffect(() => {
    setDropDirection(pickDropDirection(rootRef.current));
  }, []);

  const fuse = new Fuse(options, fuseOptions);
  const filteredOptions = isNonEmptyString(searchQuery)
    ? fuse.search(searchQuery)
    : options.map<Fuse.FuseResult<UI.UILabel>>((label, index) => ({
        item: label,
        refIndex: index,
      }));
  const hasCreateLabelOption =
    !readOnly &&
    !filteredOptions.some(({ item }) => item.title.toLocaleLowerCase() === searchQuery.toLocaleLowerCase());
  const optionCount = filteredOptions.length + (hasCreateLabelOption ? 1 : 0);

  useEffect(() => {
    function moveSelection(diff: 1 | -1): void {
      setSelectedIndex((current) => (current + diff + optionCount) % optionCount);
    }

    function select(): void {
      if (optionCount === 0) {
        return;
      }
      if (selectedIndex === optionCount) {
        onCreate(searchQuery);
      } else {
        onSelect(filteredOptions[selectedIndex].item);
      }
    }

    function handler(event: KeyboardEvent): void {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        if (event.key === 'ArrowUp') {
          moveSelection(-1);
        } else if (event.key === 'ArrowDown') {
          moveSelection(1);
        } else if (event.key === 'Enter' || event.key === 'Tab') {
          select();
        }
      }
    }

    document.addEventListener('keydown', handler, true);
    return (): void => {
      document.removeEventListener('keydown', handler, true);
    };
  }, [filteredOptions, onCreate, onSelect, optionCount, searchQuery, selectedIndex]);

  if (optionCount === 0) {
    return null;
  }

  return (
    <ListWrapper ref={rootRef} contentEditable={false} dropDirection={dropDirection}>
      {filteredOptions.map(({ item, matches }, index) => {
        const matchedText = matches?.map(fuseMatchToSubstrings)[0];
        return (
          <Option
            key={item.id}
            onMouseDown={(event): void => {
              event.stopPropagation();
              onSelect(item);
            }}
            selected={selectedIndex === index}
          >
            <LabelChip
              backgroundColor={isDefaultLabel(item) ? item.color : undefined}
              description={isDefaultLabel(item) ? item.description : undefined}
            >
              #
              {matchedText
                ? matchedText.map(({ matched, value, start, end }) =>
                    matched ? <MatchHighlight key={`match-${start}:${end}`}>{value}</MatchHighlight> : value,
                  )
                : item.title}
            </LabelChip>
          </Option>
        );
      })}
      {hasCreateLabelOption && (
        <Option
          create
          onMouseDown={(event): void => {
            event.stopPropagation();
            onCreate(searchQuery);
          }}
          selected={selectedIndex === optionCount - 1}
        >
          Create a new label &quot;#{searchQuery}&quot;
        </Option>
      )}
    </ListWrapper>
  );
}

const ListWrapper = styled(
  'div',
  {
    backgroundColor: '$white',
    border: 'solid 1px colors.$grey5',
    borderRadius: '3px',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.14)',
    maxHeight: '200px',
    minWidth: '300px',
    overflowY: 'auto',
    padding: '8px 0',
    position: 'absolute',
  },
  {
    dropDirection: {
      // eslint-disable-next-line id-length
      up: { bottom: '1.5rem' },
      down: { top: 0 },
    },
  },
);

const MatchHighlight = styled('strong', {
  textDecoration: 'underline',
});

function pickDropDirection(element: HTMLDivElement | null): DropDirection {
  if (!element) {
    return 'down';
  }
  return element.getBoundingClientRect().top > window.innerHeight / 2 ? 'up' : 'down';
}

function Option({
  children,
  create,
  onMouseDown,
  selected,
}: {
  children: ReactNode;
  create?: boolean;
  onMouseDown: MouseEventHandler;
  selected: boolean;
}): JSX.Element {
  const root = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selected) {
      root.current?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  }, [selected]);

  return (
    <OptionWrapper ref={root} create={create} onMouseDown={onMouseDown} selected={selected}>
      {children}
    </OptionWrapper>
  );
}

const OptionWrapper = styled(
  'div',
  {
    'cursor': 'pointer',
    'padding': '8px 16px',
    'whiteSpace': 'nowrap',
    '&:hover': { backgroundColor: '#4550ce', color: '$white' },
  },
  {
    create: {
      true: { color: '$focus' },
    },
    selected: {
      true: { backgroundColor: '#4550ce', color: '$white' },
    },
  },
);
