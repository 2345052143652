import { Data } from '@taraai/types';
import { linkTo } from 'components/Router/paths';
import { css, cx } from 'emotion';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { TaskRemoved } from 'resources/assets';
import { strings } from 'resources/i18n';
import { onEnterDown } from 'tools/helpers';

/**
 * FIXME: <HTMLDivElement> should be replaced with the top level DOM element or Component this component uses
 */
export interface RemovedTaskViewProps extends React.HTMLProps<HTMLDivElement> {
  /** An example property, replace with your props */
  currentOrg: string;
}

/**
 * RemovedTaskView
 * &#x3D;removed task view for deleted tasks
 *
 */
export default function RemovedTaskView({ className, currentOrg, ...props }: RemovedTaskViewProps): JSX.Element {
  const history = useHistory();
  const { teamID } = useParams<{
    teamID: Data.Id.TeamId;
  }>();

  const redirectToHome = (): void => {
    history.push(linkTo('home', { orgID: currentOrg, teamID }));
  };

  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 336px;
          min-width: 250px;
          width: 100%;
          height: 100%;
        `,
        className,
      )}
      {...props}
    >
      <img
        alt={strings.emptyRequirement}
        className={css`
          margin-bottom: 32px;
          width: 131px;
          height: 100px;
        `}
        src={TaskRemoved}
      />
      <div
        className={css`
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 24px;
          color: #575f65;
        `}
      >
        {strings.task.notHereAnymore}
      </div>
      <div
        className={css`
          border: solid 1px #c8d0df;
          border-radius: 3px;
          padding: 12px;
          color: #67728b;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          width: 120px;
          margin-left: 12px;
          cursor: pointer;
          :hover {
            opacity: 0.7;
          }
        `}
        onClick={redirectToHome}
        onKeyDown={onEnterDown(redirectToHome)}
        role='button'
        tabIndex={0}
      >
        {strings.task.backToHome}
      </div>
    </div>
  );
}
