import { Data, UI } from '@taraai/types';
import GitHubFallback from 'components/app/controllers/views/GitHubFallback';
import PullRequestListItem from 'components/app/controllers/views/PullRequestListItem';
import ListView from 'components/core/layouts/ListView';
import { css, cx } from 'emotion';
import React, { useState } from 'react';
import { IconName } from 'resources';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { compareDates } from 'tools';
import { sort } from 'tools/libraries/helpers/sort';
import { ConnectGitHubAccountData } from 'types/connect-account';
import { InstallGitHubData } from 'types/install-integration';

export interface PullRequestsHomepageProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Array of abstract Tara Pull Request objects (supporting all platforms)
   */
  pullRequests?: UI.UIPullRequest[];
  orgID: Data.Id.OrganizationId;
  installGitHubData: InstallGitHubData;
  connectGitHubAccountData?: ConnectGitHubAccountData;
  icon: IconName;
  title: string;
  details?: string;
  showDetailsButton?: boolean;
  showReviewPRButton?: boolean;
  showSorting?: boolean;
  showBranch?: boolean;
  showStatus?: boolean;
  showCommentsCount?: boolean;
  headingStyle?: string;
  pullRequestTableStyle?: string;
  linkYourProfileButton?: string;
  connectYourRepo?: string;
}

const arrowUp = css`
  position: relative;
  width: 0;
  height: 0;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-bottom: 0.25rem solid #67728b;
  bottom: 0.5rem;
  margin-left: 0.5rem;
`;

const arrowDown = css`
  position: relative;
  width: 0;
  height: 0;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-top: 0.25rem solid #67728b;
  top: 0.5rem;
  margin-left: 0.5rem;
`;

/**
 * A view of the open Pull Requests
 *
 *  - [Designs](https://zpl.io/adYY8Xe)
 */
function PullRequestsHomepage({
  className,
  pullRequests,
  installGitHubData,
  connectGitHubAccountData,
  orgID,
  icon,
  title,
  details,
  showDetailsButton = false,
  showReviewPRButton = false,
  showSorting = false,
  showBranch = false,
  showStatus = false,
  showCommentsCount = false,
  headingStyle,
  pullRequestTableStyle,
  linkYourProfileButton,
  connectYourRepo,
  ...props
}: PullRequestsHomepageProps): JSX.Element {
  const [sortOrder, setSortOrder] = useState(1);

  const customSort = [{ desc: (): boolean => !!sortOrder }, { asc: 'createdAt', comparer: compareDates }];
  const sortedPullRequests: UI.UIPullRequest[] = sort(pullRequests || [], customSort);

  const shouldShowPullRequests = installGitHubData.id && sortedPullRequests.length > 0;

  return (
    <div
      className={cx(
        css`
          flex-grow: 1;
          flex-shrink: 1;
        `,
        className,
      )}
    >
      <div
        className={cx(
          css`
            height: 100%;
            position: relative;
            min-height: 15.625rem;
            max-height: 28.125rem;
            border-radius: 0.1875rem;
            box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.08);
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            box-shadow: none;
          `,
          pullRequestTableStyle,
        )}
        {...props}
      >
        {shouldShowPullRequests && (
          <section
            className={css`
              padding: 1.5rem 1rem 0.75rem;
              background-color: #fbfbfd;
              :after {
                content: '';
                display: table;
                clear: both;
              }
            `}
          >
            <h2
              className={cx(
                css`
                  font-size: 1rem;
                  float: left;
                  width: 50%;
                `,
                headingStyle,
              )}
            >
              {strings.formatString(strings.pullRequests.openPRs, {
                openPRs: shouldShowPullRequests ? sortedPullRequests.length : 0,
              })}
            </h2>
            <h2
              className={css`
                font-size: 1rem;
                color: #374856;
                float: left;
                margin-left: 20%;
                width: 20%;
                text-align: center;
              `}
            >
              {strings.pullRequests.branch}
            </h2>
          </section>
        )}
        {shouldShowPullRequests ? (
          <>
            {showSorting && (
              <div
                className={css`
                  position: absolute;
                  font-size: 0.75rem;
                  right: 1.5rem;
                  top: 1.75rem;
                  color: #67728b;
                  outline: none;
                  cursor: pointer;
                  :hover {
                    opacity: 0.7;
                    transition: 0.3s all 0s ease;
                  }
                  display: ${sortedPullRequests.length ? 'block' : 'none'};
                `}
                onClick={(): void => setSortOrder(sortOrder * -1)}
                onKeyDown={(): void => setSortOrder(sortOrder * -1)}
                role='button'
                tabIndex={0}
              >
                {sortOrder > 0 ? strings.pullRequests.oldest : strings.pullRequests.newest}
                <span className={sortOrder > 0 ? arrowDown : arrowUp} />
              </div>
            )}
            <div
              className={css`
                border-bottom: 0.0625rem solid ${atomic.get(atomic.colors.grey3)};
                width: 100%;
              `}
            />
            <ListView
              childrenClassName={css`
                padding: 0.25rem 0;
              `}
              className={css`
                overflow: auto;
              `}
            >
              {sortedPullRequests.map((pullRequest: UI.UIPullRequest) => (
                <PullRequestListItem
                  key={pullRequest.id}
                  pullRequest={pullRequest}
                  showBranch
                  showCommentsCount
                  showCreatedAt
                  showDetailsButton={showDetailsButton}
                  showLinesAdded={false}
                  showLinesRemoved={false}
                  showReviewPRButton={showReviewPRButton}
                  showStatus={showStatus}
                />
              ))}
            </ListView>
          </>
        ) : (
          <GitHubFallback
            connectGitHubAccountData={connectGitHubAccountData}
            details={details}
            icon={icon}
            iconClassName={css`
              color: black;
              width: 2rem;
              height: 2.5rem;
            `}
            installGitHubData={installGitHubData}
            organizationID={orgID}
            title={title}
          />
        )}
      </div>
    </div>
  );
}

export default PullRequestsHomepage;
