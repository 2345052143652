import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'reduxStore/store';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources/i18n';
import { THREE_MINUTES, TWO_MEGABYTES } from 'tools/libraries/helpers/constants';
import { v4 as uuidv4 } from 'uuid';

type SetProfilePictureProps = {
  file?: File;
  removeCurrent?: boolean;
};

export const setProfilePicture = createAsyncThunk(
  'SetProfilePicture',
  async ({ file, removeCurrent }: SetProfilePictureProps, { extra, getState }) => {
    const { getUserID, getFirestore, getFirebase } = extra as ExtraAPI;
    const state = getState() as RootState;
    const userID = getUserID(state);

    // remove profile photo avatarURL form user if removeCurrent prop is true
    if (removeCurrent === true) {
      return getFirestore()
        .set(`users/${userID}`, { avatarURL: '' }, { merge: true })
        .catch((error: Error) => {
          throw error.message;
        });
    }
    if (!file) throw strings.profileImage.fileIssue;
    if (file.size > TWO_MEGABYTES) throw strings.profileImage.imageTooLarge;
    // generate unique filename for photo
    const uniqueFileName = uuidv4();
    // make upload ref
    const fileRef = getFirebase().storage().ref(`users/${userID}/avatars/${uniqueFileName}`);
    const uploadTask = fileRef.put(file);
    return new Promise<string>((resolve, reject) => {
      // timeout upload after three minutes
      const uploadTimeout = setTimeout(() => {
        uploadTask.cancel();
        return reject(new Error(strings.profileImage.uploadTimeout));
      }, THREE_MINUTES);
      uploadTask.on(
        'state_changed',
        // use snapshot for upload progress
        (snapshot) => {
          return snapshot;
        },
        (err: Error) => {
          clearTimeout(uploadTimeout);
          return reject(err.message);
        },
        async () => {
          // get url on successful upload
          const avatarURL = await fileRef.getDownloadURL();
          // on successful upload set photoURL to user object
          await getFirestore()
            .set(`users/${userID}`, { avatarURL }, { merge: true })
            .catch((error: Error) => {
              reject(error);
            });
          clearTimeout(uploadTimeout);
          return resolve(avatarURL);
        },
      );
    });
  },
);
