import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { ExtraAPI } from 'reduxStore/utils/types';

export const deleteOrganization = createAsyncThunk('deleteOrganization', (_actionPayload: undefined, { extra }) => {
  const { getFirebase, getOrgID } = extra as ExtraAPI;
  const organizationId = getOrgID();

  const payload: Functions.RemoveOrganization.Payload = {
    organizationId,
  };

  return getFirebase().functions().httpsCallable('removeOrganization')(payload);
});
