"use strict";
/* eslint-disable xss/no-mixed-html */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Popup = void 0;
var react_1 = __importStar(require("react"));
var Box_1 = require("./Box");
var RelativePositioning_1 = require("./common/RelativePositioning");
var useClickOutside_1 = require("./common/useClickOutside");
function Popup(_a) {
    var children = _a.children, content = _a.content, onShowChange = _a.onShowChange, placement = _a.placement, show = _a.show;
    var childRef = react_1.useRef(null);
    var contentRef = react_1.useRef(null);
    var handleClose = react_1.useCallback(function () { return onShowChange(false); }, [onShowChange]);
    useClickOutside_1.useClickOutside(show, handleClose, childRef, contentRef);
    react_1.useImperativeHandle(children.ref, function () { return childRef.current; }, []);
    return (react_1.default.createElement(RelativePositioning_1.RelativePositioning, { ref: contentRef, content: content, offset: 8, placement: placement, show: show }, react_1.cloneElement(children, { ref: childRef })));
}
exports.Popup = Popup;
Popup.Content = function PopupContent(_a) {
    var children = _a.children;
    return (react_1.default.createElement(Box_1.Box, { background: '$white', border: '$grey5', borderRadius: '$4px', minWidth: '$minPopupWidth', shadow: '$popup', space: ['$12px', '$16px', '$16px'] }, children));
};
