import { createAsyncThunk } from '@reduxjs/toolkit';
import Tara, { Data } from '@taraai/types';
import { RootState } from 'reduxStore/store';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

const ORGANIZATIONS_COLLECTION = 'orgs';
const TASKS_COLLECTION = 'tasks';

export interface CreateTaskActionPayload {
  title: string;
  parent?: Data.Id.TaskId | null;
  requirement?: Data.Id.RequirementId | null;
  sprint?: Data.Id.SprintId | null;
  orgId?: Data.Id.OrganizationId;
  labels?: string[];
}

export const createTask = createAsyncThunk(
  'CreateTask',
  async (
    {
      title,
      parent = null,
      requirement = null,
      sprint = null,
      labels = [],
      orgId: forceOrgId,
    }: CreateTaskActionPayload,
    { extra, getState },
  ) => {
    const { getOrgID, getUserID, getFirestore } = extra as ExtraAPI;
    const state = getState() as RootState;
    const userID = getUserID(state);
    const orgID = forceOrgId ?? getOrgID();
    const firestore = getFirestore();

    // decode relationships and set default
    const _relationships = decode<Tara.Data.TaskRelationships>(
      {
        parent,
        requirement,
      },
      'Relationships',
      CREATION_DEFAULTS,
    );

    const statusHistory = [
      {
        lastUpdatedBy: userID,
        updatedAt: firestore.Timestamp.now(),
      },
    ];

    // decode history and set default
    statusHistory.map((status) => decode<Tara.StatusHistory>(status, 'StatusHistory', CREATION_DEFAULTS));

    return firestore.runTransaction(async (transaction) => {
      const organizationRef = firestore.collection(ORGANIZATIONS_COLLECTION).doc(orgID);

      const organizationDoc = await transaction.get(organizationRef);

      const slugNumber = organizationDoc.get('nextTaskSlug');
      const effortUnit = organizationDoc.get('effortUnit');

      if (typeof slugNumber !== 'number') throw new Error('Failed to decode next task slug number');

      const slug = String(slugNumber);

      const changeset = decode<Tara.Task>(
        {
          slug,
          author: userID,
          updatedAt: firestore.Timestamp.now(),
          createdAt: firestore.Timestamp.now(),
          lastUpdateVia: 'tara',
          title,
          sprint,
          updatedBy: userID,
          _relationships,
          statusHistory,
          effortUnit,
          gitStatus: null,
          labels,
        },
        'Task',
        CREATION_DEFAULTS,
      );

      // Add new task to tasks collection
      transaction.set(organizationRef.collection(TASKS_COLLECTION).doc(slug), {
        ...changeset,
        slug,
      });

      // Update next task slug number on organization level
      transaction.update(organizationRef, {
        nextTaskSlug: slugNumber + 1,
      });

      return slug;
    });
  },
);
