import { Data } from '@taraai/types';
import { ImportBacklogIndicatorController } from 'components/app/controllers/ImportBacklogIndicatorController';
import RequirementsListElement from 'components/app/controllers/views/RequirementsListElement';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React from 'react';
import { Github } from 'resources/assets';
import { strings } from 'resources/i18n';

export function GithubIcon({ size = '2rem', padding = '0rem' }: { size?: string; padding?: string }): JSX.Element {
  return (
    <img
      alt=''
      className={css`
        width: ${size};
        height: ${size};
        padding: ${padding};
        box-sizing: content-box;
      `}
      src={Github}
    />
  );
}

function GithubImportedTasksIndicator({
  taskCount,
  onClick,
}: {
  taskCount: number;
  onClick: () => void;
}): JSX.Element | null {
  if (taskCount === 0) {
    return null;
  }

  return (
    <RequirementsListElement
      description={strings.formatString(strings.importIndicatorInBacklog.taskCount, { taskCount })}
      icon={(): JSX.Element => <GithubIcon />}
      label={strings.importIndicatorInBacklog.importedFromGithub}
      onClick={onClick}
    />
  );
}

function GithubImportInProgressIndicator({
  importDoc,
  onClick,
}: {
  importDoc: Data.Import;
  onClick: () => void;
}): JSX.Element {
  const tasksProgress = `${importDoc.processedItemsCount}/${importDoc.totalItemsCount}`;
  const description = strings.formatString(strings.importIndicatorInBacklog.importingTasks, { tasksProgress });
  return (
    <RequirementsListElement
      description={description}
      icon={(): JSX.Element => <GithubIcon />}
      label={strings.importIndicatorInBacklog.importedFromGithub}
      onClick={onClick}
      rightIcon={<FastSmallSpinner />}
    />
  );
}

export function GithubImportBacklogIndicatorController({
  showImportedTasks,
}: {
  showImportedTasks: () => void;
}): JSX.Element {
  return (
    <ImportBacklogIndicatorController
      importInProgressIndicator={GithubImportInProgressIndicator}
      service='github'
      showImportedTasks={showImportedTasks}
      tasksImportedIndicator={GithubImportedTasksIndicator}
    />
  );
}
