import { unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { TeamPartial } from '@taraai/types/src/data';
import { MemberListView } from 'components/app/controllers/views/MemberListView';
import Button from 'components/core/controllers/views/Button';
import Input from 'components/core/controllers/views/Input';
import Logo from 'components/core/controllers/views/Logo';
import Modal from 'components/core/controllers/views/Modal';
import SpinnerButton from 'components/core/controllers/views/SpinnerButton';
import { css } from 'emotion';
import React, { useCallback, useState } from 'react';
import { createTeam, reduxStore } from 'reduxStore';
import { decode } from 'reduxStore/utils/decoders';
import { atomic } from 'resources';
import { createTeamModalTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { onEnterDown, useToast } from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';

type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

interface Props {
  orgID: Data.Id.OrganizationId;
  closeModal: () => void;
  profileId: string;
}

/**
 * View for team creation modal
 */
export function TeamCreateModal({ orgID, profileId, closeModal }: Props): JSX.Element {
  const [teamName, setTeamName] = useState('');
  const [inputError, setInputError] = useState('');
  const [memberIds, setMemberIds] = useState<Data.Id.UserId[]>([profileId]);
  const { addToast } = useToast();

  const createNewTeam = useCallback(async (): Promise<void> => {
    try {
      decode<TeamPartial>({ name: teamName }, 'TeamPartial');
    } catch (error) {
      setInputError(strings.createTeamModal.createTooShort);
      return Promise.resolve();
    }
    return reduxStore
      .dispatch(createTeam({ name: teamName, userIds: memberIds }))
      .then(unwrapResult)
      .then(() => {
        addToast({
          message: formatI18n(strings.createTeamModal.createSuccess)({
            team: teamName,
          }),
          type: 'success',
          timeoutMs: 3000,
        });
        return closeModal();
      })
      .catch(() => {
        setInputError(strings.createTeamModal.validationFailure);
        addToast({
          message: strings.createTeamModal.createFailure,
          type: 'error',
        });
      });
  }, [addToast, closeModal, memberIds, teamName]);

  const joinTeam = (user: UserFragment): void => setMemberIds((current) => [...current, user.id]);

  const leaveTeam = (user: UserFragment): void =>
    setMemberIds((current) => current.filter((userId) => user.id !== userId));

  const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTeamName(event.target.value);
    setInputError('');
  };

  return (
    <Modal
      className={css`
        width: 60rem;
        border: solid 0.0625rem #dee3ec;
      `}
      closeModal={closeModal}
      footer={
        <div
          className={css`
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          `}
        >
          <Button
            className={css`
              margin-right: 0.8125rem;
              min-width: 6.25rem;
              min-height: 2.375rem;
              padding: 0;
            `}
            color='ghost'
            data-cy={createTeamModalTestIDs.TEAM_CREATION_CANCEL_BUTTON}
            onClick={(): void => {
              closeModal();
            }}
          >
            {strings.createTeamModal.cancel}
          </Button>
          <SpinnerButton
            className={css`
              padding: 0;
              min-width: 6.25rem;
              min-height: 2.375rem;
            `}
            data-cy={createTeamModalTestIDs.TEAM_CREATION_CREATE_BUTTON}
            hideSpinnerAutomatically
            name={strings.createTeamModal.create}
            onClick={createNewTeam}
          >
            {strings.createTeamModal.create}
          </SpinnerButton>
        </div>
      }
      header={<div>{strings.createTeamModal.createTeam}</div>}
      headerStyle={css`
        padding: 0.75rem 0.9375rem 0.75rem 1.5rem;
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
      `}
    >
      <div
        className={css`
          border-bottom: solid 1px #eaeef5;
          margin-bottom: 24px;
          display: flex;
        `}
      >
        <Logo
          className={css`
            margin-top: 8px;
            margin-bottom: 40px;
          `}
          color={teamName.length === 0 ? '#eaeef5' : undefined}
          name={teamName}
          shape='circle'
          size='80px'
        />
        <div
          className={css`
            margin-left: 17px;
            margin-top: 14px;
          `}
        >
          <Input
            bottomLabel={inputError}
            data-cy={createTeamModalTestIDs.TEAM_NAME_INPUT}
            label={strings.createTeamModal.teamName}
            onChange={handleTeamNameChange}
            onKeyDown={onEnterDown(createNewTeam)}
            placeholder={strings.createTeamModal.yourTeamName}
            style={{
              main: css`
                color: #303f4b;
                font-size: 1rem;
                background-color: #ffffff;
                border-radius: 6px;
              `,
              container: css`
                height: 2.875rem;
                margin: 0.5rem 0rem;
                width: 300px;
              `,
              labelStyle: css`
                font-size: 1rem;
                color: ${atomic.get(atomic.colors.grey7)};
              `,
              bottomLabelStyle: css`
                color: #e86262;
                font-size: 14px;
                font-weight: normal;
              `,
            }}
            value={teamName}
          />
        </div>
      </div>
      <MemberListView
        canProfileLeave={false}
        joinTeam={joinTeam}
        leaveTeam={leaveTeam}
        memberIds={memberIds}
        orgId={orgID}
        profileId={profileId}
        teamName={teamName}
      />
    </Modal>
  );
}
