import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React, { RefObject } from 'react';
import { atomic } from 'resources/theme/atomic';

import { Tab } from './Tabs';

export type TabsViewVariantProps = {
  tabButtonMargin?: 'none';
  tabsSeparatorBottomMargin?: 'none' | 'small';
  tabsSeparatorVariant?: 'transparent';
  tabsUnderlineVerticalStackPosition?: 'negative';
  tabsWrapperVariant?: 'settings';
  isGlobalNav?: boolean;
};

export type TabsViewProps = {
  enableAnimation: boolean;
  handleTabChange: (tab: Tab) => void;
  lineMarginX?: string;
  selectedTab?: Tab;
  selectedTabRef: RefObject<HTMLButtonElement>;
  tabbedContent: Tab[];
  tabLeftPosition: number;
  tabWidth: number;
} & TabsViewVariantProps;

export const TabsView: React.FC<TabsViewProps> = ({
  children,
  enableAnimation,
  handleTabChange,
  lineMarginX,
  selectedTab,
  selectedTabRef,
  tabbedContent,
  tabLeftPosition,
  tabWidth,
  tabButtonMargin,
  tabsSeparatorBottomMargin,
  tabsSeparatorVariant,
  tabsUnderlineVerticalStackPosition,
  tabsWrapperVariant,
  isGlobalNav,
}) => (
  <>
    <TabsWrapper variant={tabsWrapperVariant}>
      {tabbedContent.map((tab) => (
        <TabButton
          key={tab.label}
          ref={selectedTab && tab.label === selectedTab.label ? selectedTabRef : null}
          data-cy={tab.dataCy}
          disabled={tab.disabled}
          id={tab.label}
          isGlobalNav={isGlobalNav}
          margin={tabButtonMargin}
          onClick={(): void => handleTabChange(tab)}
          onKeyDown={(event: React.KeyboardEvent<HTMLElement>): void => {
            if (event.key === 'Enter') {
              handleTabChange(tab);
            }
          }}
          selected={selectedTab && tab.label === selectedTab.label}
          tabIndex={0}
          type='button'
        >
          {tab.icon ? (
            <Icon
              // TODO: provide variants in Icon component
              className={css`
                height: 0.85rem;
                width: 0.85rem;
                padding: 0.81rem;
              `}
              color={atomic.colors.inherit}
              name={tab.icon}
            />
          ) : (
            <Text
              // TODO: provide variants in Text component
              className={css`
                font-size: 0.875rem;
              `}
              fontWeight='500'
            >
              {tab.label}
            </Text>
          )}
        </TabButton>
      ))}
      <SpaceBox />
      {children}
    </TabsWrapper>
    <TabsSeparator
      bottomMargin={tabsSeparatorBottomMargin}
      isGlobalNav={isGlobalNav}
      style={{
        marginLeft: lineMarginX || '0rem',
        marginRight: lineMarginX || '0rem',
      }}
      variant={tabsSeparatorVariant}
    >
      {selectedTab && (
        <Underline
          enableAnimation={enableAnimation}
          style={{
            left: `calc(${tabLeftPosition}px - ${lineMarginX || '0rem'})`,
            width: tabWidth,
          }}
          verticalStackPosition={tabsUnderlineVerticalStackPosition}
        />
      )}
    </TabsSeparator>
  </>
);

const PRIMARY_BLUE = '#4550ce';

const TabsWrapper = styled(
  'div',
  {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '$full',
    position: 'relative',
  },
  {
    variant: {
      settings: { backgroundColor: '$grey1', padding: '$none 3rem' },
    },
  },
);

const TabButton = styled(
  'button',
  {
    'display': 'flex',
    'whiteSpace': 'nowrap',
    'listStyle': 'none',
    'padding': '$16px',
    'margin': '$none $16px',
    'maxHeight': '$full',
    'background': 'none',
    'border': 'none',
    'color': '$grey6',
    'cursor': 'pointer',
    ':focus-within': { outline: 'none', color: PRIMARY_BLUE },
  },
  {
    isGlobalNav: {
      true: {
        color: '$dark',
        margin: '$none $12px',
        padding: '$16px $1px',
      },
    },
    selected: {
      true: {
        color: PRIMARY_BLUE,
        g: {
          fill: PRIMARY_BLUE,
        },
      },
    },
    disabled: {
      true: { cursor: 'not-allowed', color: '$dark' },
    },
    margin: { none: { margin: 0 } },
  },
);

const SpaceBox = styled('div', { flexGrow: 1 });

const TabsSeparator = styled(
  'div',
  {
    height: '0.1rem',
    minHeight: '0.1rem',
    backgroundColor: '$grey4',
    marginBottom: '$16px',
    position: 'relative',
  },
  {
    isGlobalNav: {
      true: {
        top: '-0.2rem',
        height: '0.1875rem',
        minHeight: '0.1875rem',
      },
    },
    bottomMargin: {
      none: { marginBottom: '$none' },
      small: { marginBottom: '$8px' },
    },
    variant: {
      transparent: {
        backgroundColor: 'transparent',
      },
    },
  },
);

const Underline = styled(
  'div',
  {
    position: 'absolute',
    height: 'inherit',
    backgroundColor: PRIMARY_BLUE,
  },
  {
    enableAnimation: {
      true: { transition: 'all 0.25s ease-in-out' },
    },
    verticalStackPosition: {
      negative: {
        zIndex: -1,
      },
    },
  },
);
