/* eslint-disable @typescript-eslint/no-explicit-any */
import { Selector } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { noop } from '@taraai/utility';
import React from 'react';

export interface TaskModalContextType {
  openModal: (task: UI.UITask, newTasksSelector: Selector<any, UI.UITask[] | undefined>, category: string) => void;
}

const TaskModalContext = React.createContext<TaskModalContextType>({
  openModal: noop,
});

export default TaskModalContext;
