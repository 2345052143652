import { styled, VStack } from '@taraai/design-system';
import { UI } from '@taraai/types/src';
import { SprintOverloadedCard } from 'components/app/controllers/views/SprintOverloadedCard';
import { TaskCard } from 'components/app/TaskCard';
import React from 'react';

export type TaskFragment = Pick<
  UI.UITask,
  'id' | 'title' | 'description' | 'assignee' | 'effortLevel' | 'status' | 'slug' | 'labels' | '_relationships'
>;

type SprintColumnTasksViewProps = {
  tasks: TaskFragment[];
  overloadPoints: number;
};

export function SprintColumnTasksView({ tasks, overloadPoints }: SprintColumnTasksViewProps): JSX.Element {
  return (
    <Wrapper>
      <VStack space='$1px'>
        <VStack space='$1px'>
          {tasks.map((task) => (
            <TaskCard
              key={task.id}
              effortLevel={task.effortLevel}
              slug={task.slug}
              status={task.status}
              title={task.title}
            />
          ))}
        </VStack>
        {overloadPoints > 0 && <SprintOverloadedCard overloadPoints={overloadPoints} />}
      </VStack>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  overflow: 'auto',
  backgroundColor: '$grey2',
  borderRadius: '0 0 0.1875rem 0.1875rem',
});
