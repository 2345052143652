"use strict";
/* eslint-disable xss/no-mixed-html */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelativePositioning = exports.placements = void 0;
var react_1 = __importStar(require("react"));
var react_dom_1 = require("react-dom");
var react_popper_1 = require("react-popper");
var core_1 = require("@popperjs/core");
Object.defineProperty(exports, "placements", { enumerable: true, get: function () { return core_1.placements; } });
/**
 * A common component for positioning content relatively to the child.
 * Uses Popper.js and accepts all placements that Popper.js allows.
 *
 * **Important note:**
 *
 * To avoid wrapping children in another HTML element, the child component has to accept a ref. If the child is
 * a simple function component, then React will warn about it in the console and relative positioning won't work.
 * For an example of how to forward refs check out the Box component in the design-system package.
 *
 * Avoid the temptation to pass an arrow function to `forwardRef` as that will make the component anonymous in React
 * dev tools.
 */
exports.RelativePositioning = react_1.forwardRef(function RelativePositioning(_a, ref) {
    var children = _a.children, content = _a.content, _b = _a.offset, offset = _b === void 0 ? 0 : _b, placement = _a.placement, _c = _a.show, show = _c === void 0 ? true : _c;
    var _d = react_1.useState(null), portal = _d[0], setPortal = _d[1];
    var _e = react_1.useState(null), reference = _e[0], setReference = _e[1];
    var _f = react_1.useState(null), popper = _f[0], setPopper = _f[1];
    var _g = react_popper_1.usePopper(reference, popper, {
        placement: placement,
        modifiers: [{ name: 'offset', options: { offset: [0, offset] } }],
    }), styles = _g.styles, attributes = _g.attributes;
    react_1.useImperativeHandle(ref, function () { return portal; }, [portal]);
    react_1.useImperativeHandle(children.ref, function () { return reference; }, [reference]);
    react_1.useLayoutEffect(function () {
        if (!show) {
            return;
        }
        var portalElement = window.document.createElement('div');
        window.document.body.appendChild(portalElement);
        setPortal(portalElement);
        return function () {
            /**
             * A possible perf improvement would be to create the element on first show and then keep it until the component
             * is unmounted; but for now this should suffice.
             */
            setPortal(null);
            window.document.body.removeChild(portalElement);
        };
    }, [show]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.cloneElement(children, { ref: setReference }),
        portal &&
            react_dom_1.createPortal(
            // TODO: replace `z`Index: 999` with an upcoming LevelUp component
            react_1.default.createElement("div", __assign({ ref: setPopper, style: __assign(__assign({}, styles.popper), { zIndex: 999 }) }, attributes.popper), content), portal)));
});
