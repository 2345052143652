"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
exports.default = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    if (typeof value !== 'string') {
        return ts_data_json_1.err(errorMessage(value));
    }
    var number = parseFloat(value);
    if (Number.isFinite(number)) {
        return ts_data_json_1.ok(number);
    }
    return ts_data_json_1.err(errorMessage(value));
});
function errorMessage(value) {
    return "Failed to decode " + value + " as a number";
}
