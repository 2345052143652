import { createAsyncThunk } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export type UpdateRequirementTeamAction = keyof Pick<UI.UIRequirementChangeset, 'appendTeamIds' | 'removeTeamIds'>;

export const updateRequirement = createAsyncThunk(
  'UpdateRequirement',
  async ({ id: requirementID, ...propsToSet }: Omit<UI.UIRequirementChangeset, 'updatedAt'>, { extra }) => {
    const { getFirestore, getOrgID } = extra as ExtraAPI;
    const orgID = getOrgID();
    const firestore = getFirestore();
    const batch = firestore.batch();
    const requirementRef = firestore.collection('orgs').doc(orgID).collection('requirements').doc(requirementID);

    const decodedChangesWithID = decode<UI.UIRequirementChangeset>(
      {
        id: requirementID,
        ...propsToSet,
        updatedAt: firestore.Timestamp.now(),
      },
      'UIRequirementChangeset',
    );

    const { id, appendTeamIds, removeTeamIds, ...valid } = decodedChangesWithID;

    if (appendTeamIds) {
      batch.update(requirementRef, {
        assignedTeamIds: firestore.FieldValue.arrayUnion(...appendTeamIds),
      });
    }
    if (removeTeamIds) {
      batch.update(requirementRef, {
        assignedTeamIds: firestore.FieldValue.arrayRemove(...removeTeamIds),
      });
    }

    batch.update(requirementRef, valid);
    await batch.commit();
    return decodedChangesWithID as UI.UIRequirementChangeset;
  },
);
