/* eslint-disable sonarjs/no-duplicate-string */

import { styled } from '@taraai/design-system';
import React, { ReactNode } from 'react';

interface Props {
  backgroundColor?: string;
  children: ReactNode;
  description?: string;
}

// A temporary solution until we get rid of colors in labels
const $grey3 = '#eaeef5';
const $grey7 = '#67728b';
const $white = '#ffffff';

/**
 * A label chip that is used for categorizing items on the Tara app.
 */
export function LabelChip({ backgroundColor = $grey3, children, description }: Props): JSX.Element {
  const color = backgroundColor === $grey3 ? $grey7 : $white;
  return (
    <Wrapper style={{ backgroundColor, color }} title={description}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled('span', {
  'borderRadius': '0.1875rem',
  'fontSize': '0.875em',
  'fontStyle': 'normal',
  'fontWeight': '$regular',
  'lineHeight': 1.5,
  'padding': '0.125rem 0.25rem',
  'textDecoration': 'none',
  // Override inline styles coming from the editor
  '& *': {
    color: 'inherit !important',
    fontSize: 'inherit !important',
    fontStyle: 'inherit !important',
    fontWeight: 'inherit !important',
    textDecoration: 'inherit !important',
  },
});
