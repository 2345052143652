import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { getNumberAtEndAsString } from '@taraai/utility';
import { reduxStore, RootStateWithProfile } from 'reduxStore/store';
import { CREATION_DEFAULTS, decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';
import { strings } from 'resources/i18n';
import { plusMilliseconds, WEEK_IN_SECONDS } from 'tools';

export const createSprint = createAsyncThunk('CreateSprint', async (___, { extra }) => {
  const { getOrgID, getFirestore, getTeamID } = extra as ExtraAPI;
  const state = reduxStore.getState() as RootStateWithProfile;
  const firestore = getFirestore();
  const teamID = getTeamID(state);
  const orgID = getOrgID();

  return firestore.runTransaction(async (transaction) => {
    const orgRef = firestore.collection('orgs').doc(orgID);
    const teamRef = orgRef.collection('teams').doc(teamID);
    const newSprintRef = orgRef.collection('sprints').doc();

    const teamData = (await transaction.get(teamRef)).data() as Data.Team;
    if (!teamData) throw new Error('Team does not exist');

    const { totalSprintsCount, sprintSettings } = teamData;
    const newSprintNumber = totalSprintsCount + 1;

    const lastCreatedSprintSnapshot = await orgRef
      .collection('sprints')
      .where('teamId', '==', teamID)
      .where('archived', '==', false)
      .where('deleted', '==', false)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get();

    const lastCreatedSprintData = (lastCreatedSprintSnapshot.docs.pop()?.data() as Data.Sprint) || undefined;

    const initialStartDate = lastCreatedSprintData?.initialEndDate ?? firestore.Timestamp.now();

    const initialEndDate = plusMilliseconds(initialStartDate, sprintSettings.durationWeeks * WEEK_IN_SECONDS * 1000);

    const newSprintData = decode<Data.Sprint>(
      {
        teamId: teamID,
        initialStartDate,
        initialEndDate,
        createdAt: firestore.Timestamp.now(),
        updatedAt: firestore.Timestamp.now(),
        sprintName: getNewSprintName(lastCreatedSprintData, newSprintNumber),
        sprintNumber: newSprintNumber,
      },
      'Sprint',
      CREATION_DEFAULTS,
    );

    transaction.set(newSprintRef, newSprintData);

    return { id: newSprintRef.id, ...newSprintData } as UI.UISprint;
  });
});

function getNewSprintName(lastSprintData: Data.Sprint | undefined, newSprintNumber: number): string {
  if (lastSprintData) {
    const titleNumberString = getNumberAtEndAsString(lastSprintData.sprintName);

    // If number found in the title, increment it by 1
    if (titleNumberString) {
      const nextNumber = parseInt(titleNumberString, 10) + 1;
      return lastSprintData.sprintName.replace(titleNumberString, nextNumber.toString());
    }

    // If no number found in the title, append ' 2' for the next sprint title
    return lastSprintData.sprintName.concat(' 2');
  }

  // If this is the first sprint or any other edge case occurs,
  // use the default title using the org sprintNumber value
  return strings.formatString(strings.sprints.sprintNumber, {
    number: newSprintNumber.toString(),
  }) as string;
}
