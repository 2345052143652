import Icon from 'components/core/controllers/views/Icon';
import { css, cx } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

export interface ReviewPRButtonProps extends React.HTMLProps<HTMLDivElement> {
  url?: string;
}

/**
 * ReviewPRButton is a button on the PR table on the homepage to navigate to the PR
 *
 *
 */
export default function ReviewPRButton({ url, className }: ReviewPRButtonProps): JSX.Element {
  return (
    <a
      className={cx(
        css`
          min-height: 0rem;
          min-width: 0rem;
          padding: 0.5rem;
          border-radius: 0.1875rem;
          border: solid 1px #eaeef5;
          background-color: #fbfbfd;
          font-size: 14px;
          font-weight: 500;
          color: #303f4b;
          outline: 0;
          color: #303f4b;
          text-decoration: none;
          :hover {
            background-color: #fbfbfd;
            opacity: 0.5;
          }
        `,
        className,
      )}
      href={url}
      rel='noopener noreferrer'
      target='_blank'
    >
      <Icon
        className={css`
          padding: 0;
          color: black;
          padding-right: 0.5rem;
          vertical-align: sub;
        `}
        name='github'
      />
      {strings.dashboard.pullRequest.reviewPR}
    </a>
  );
}
