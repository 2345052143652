import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { ExtraAPI } from 'reduxStore/utils';
import { decode } from 'reduxStore/utils/decoders';

export const checkOrganizationExists = createAsyncThunk(
  'CheckOrganizationExists',
  async ({ orgID }: { orgID: Data.Id.OrganizationId }, { extra }): Promise<boolean> => {
    const { getFirestore } = extra as ExtraAPI;
    const firestore = getFirestore();

    const validOrgID = decode<Data.Id.OrganizationId>(orgID, 'OrganizationId');

    return firestore
      .collection('/orgs')
      .doc(validOrgID)
      .get()
      .then((doc) => doc.exists);
  },
);
