import { ClickAwayListener } from '@material-ui/core';
import { isNonEmptyString } from '@taraai/utility';
import { useEditor } from 'components/core/controllers/Editor/Context/useEditor';
import { getEntitySelection } from 'components/core/controllers/Editor/util';
import { EditorState, Modifier } from 'draft-js';
import React, { useCallback, useRef, useState } from 'react';

import DraftLinkPopper from './DraftLinkPopper';
import DraftLinkView from './DraftLinkView';

export interface DraftLinkProps {
  children?: React.ReactNode;
  entityKey?: string;
  href: string;
}

/**
 * DraftLink
 * A component to render the Link Entity in Draft
 *
 */
export default function DraftLink({ children, entityKey, href }: DraftLinkProps): JSX.Element {
  const { setAddLinkPopperOpen, setEditorState } = useEditor();

  const popperAnchor = useRef<HTMLSpanElement | null>(null);
  const [popperOpen, setPopperOpen] = useState(false);

  const handleCopy = useCallback((): void => {
    if (isNonEmptyString(href)) {
      navigator.clipboard.writeText(href);
    }
    setPopperOpen(false);
  }, [setPopperOpen, href]);

  const handleEditLink = useCallback(() => {
    if (!entityKey) {
      throw new Error('No entity key provided');
    }

    setEditorState(
      (editorState: EditorState): EditorState => {
        const entitySelection = getEntitySelection(editorState, entityKey);
        if (!entitySelection) {
          throw new Error("Couldn't find entity");
        }
        return EditorState.forceSelection(editorState, entitySelection);
      },
    );

    setPopperOpen(false);
    setAddLinkPopperOpen(true);
  }, [entityKey, setEditorState, setAddLinkPopperOpen]);

  const handleRemove = useCallback(() => {
    if (entityKey) {
      setEditorState(
        (intialEditorState: EditorState): EditorState => {
          const selection = getEntitySelection(intialEditorState, entityKey);
          if (!selection) {
            throw new Error("Couldn't find selection.");
          }
          const newContentState = Modifier.applyEntity(intialEditorState.getCurrentContent(), selection, null);
          return EditorState.push(intialEditorState, newContentState, 'apply-entity');
        },
      );
    }
    setPopperOpen(false);
  }, [entityKey, setEditorState, setPopperOpen]);

  return (
    <ClickAwayListener onClickAway={(): void => setPopperOpen(false)}>
      <span ref={popperAnchor}>
        <DraftLinkView href={href} onClick={(): void => setPopperOpen(!popperOpen)}>
          {children}
        </DraftLinkView>
        <DraftLinkPopper
          anchorEl={popperAnchor.current}
          // Allow editing only for links with names (e.g. [Tara](http://tara.ai) vs http://tara.ai).
          // The latter ones come with no entity key provided.
          editable={!!entityKey}
          onCopy={handleCopy}
          onEditLink={handleEditLink}
          onRemove={handleRemove}
          open={popperOpen}
          url={href}
        />
      </span>
    </ClickAwayListener>
  );
}
