import { UI } from '@taraai/types';
import { getFilterByNameFn } from 'components/app/controllers/Selectors/common/filterFn';
import { Option } from 'components/app/controllers/Selectors/common/Option';
import SearchHeader from 'components/app/controllers/Selectors/common/SearchHeader';
import {
  OptionRenderProps,
  SectionType,
  SelectButtonRenderProps,
  Selector,
} from 'components/core/controllers/Selector';
import React, { FunctionComponent } from 'react';

type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;
type Section = SectionType<UserFragment>;

type Props = {
  sections: Section[];
  selection: UserFragment[];
  selectUser: (user: UserFragment) => void;
  deselectUser: (user: UserFragment) => void;
  renderSelectButton: (props: SelectButtonRenderProps) => JSX.Element;
  headerTitle?: string;
  searchPlaceholder: string;
  Wrapper?: FunctionComponent<{ 'data-cy'?: string }>;
};

const filterFn = getFilterByNameFn<Section, UserFragment>();

/**
 * UserSelector is an UI component build on top of the Selector to handle selecting users.
 * It supports:
 * - multiple and single selection of user by mouse clicking and keyboard navigation
 * - searching users by name
 * - displaying user names and avatars in dropdown
 *
 * This component should be wrapped by parent component to handle specific use case
 * (e.g. TeamMemberSelector to select members of a team)
 */
export function UserSelector({
  sections,
  selection,
  selectUser,
  deselectUser,
  renderSelectButton,
  headerTitle,
  searchPlaceholder,
  Wrapper,
}: Props): JSX.Element {
  return (
    <Selector
      filterFn={filterFn}
      onDeselectOption={deselectUser}
      onSelectOption={selectUser}
      renderHeader={(props): JSX.Element => (
        <SearchHeader searchPlaceholder={searchPlaceholder} showSearch title={headerTitle} {...props} />
      )}
      renderOption={UserOption}
      renderSelectButton={renderSelectButton}
      sections={sections}
      selection={selection}
      Wrapper={Wrapper}
    />
  );
}

function UserOption({ option, ...props }: OptionRenderProps<UserFragment>): JSX.Element {
  return <Option avatarURL={option.avatarURL} mode='avatar' title={option.name} {...props} />;
}
