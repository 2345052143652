import { RichEditorPlugin } from 'components/editor/types';
import { ContentState } from 'draft-js';

import { downgradeMentionsTransform, upgradeMentionsTransform } from './mention';
import { mentionDropdownDecorator } from './mentionDropdown';
import { mentionTagDecorator } from './mentionTag';

export function createMentionPlugin(
  getUserTagForId: (id: string) => string | undefined,
): RichEditorPlugin<(source: ContentState) => ContentState> {
  return {
    decorator: [mentionTagDecorator, mentionDropdownDecorator],
    pipeline: {
      read: upgradeMentionsTransform(getUserTagForId),
      save: downgradeMentionsTransform,
    },
  };
}
