import { Avatar, Box, Fluid, HStack, Text, VStack } from '@taraai/design-system';
import { SmartText } from 'components/app/controllers/views/SmartText';
import React from 'react';
import { strings } from 'resources/i18n';

interface Props {
  effortLevel: number;
  slug: string;
  status?: Status;
  title: string;
}

type Status = 0 | 1 | 2;

export function TaskCard({ effortLevel, slug, status, title }: Props): JSX.Element {
  return (
    <Box background='$grey1' borderRadius='$2px' space={['$8px', '$8px', '$12px']}>
      <HStack space='$4px'>
        <Fluid>
          <VStack space='$4px'>
            <Text color='$grey6' size='$10px' weight='medium'>
              {strings.formatString(strings.task.taskSlug, { slug })}
            </Text>
            <Text italic size='$12px'>
              <SmartText text={title} />
            </Text>
          </VStack>
        </Fluid>
        <StatusAndEffortIndicator effortLevel={effortLevel} status={status} />
        <Avatar />
      </HStack>
    </Box>
  );
}

function StatusAndEffortIndicator({ effortLevel, status }: { effortLevel: number; status?: Status }): JSX.Element {
  return (
    <Box
      background={statusToBackground[status ?? 'default']}
      borderRadius='$circle'
      center
      height='$avatar'
      width='$avatar'
    >
      <Text color={typeof status === 'undefined' ? '$dark' : '$white'} size='$10px'>
        {effortLevel}
      </Text>
    </Box>
  );
}

const statusToBackground = {
  0: '$todo', // todo
  1: '$focus', // doing
  2: '$success', // done
  default: '$grey4',
} as const;
