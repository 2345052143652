import styled from '@emotion/styled';
import React from 'react';
import { atomic } from 'resources';

import * as TEST_IDS from './test-ids';

export type StepIndicatorProps = {
  /**
   * current step index
   */
  step: number;
  /**
   * all steps count
   */
  stepsCount: number;
};

/**
 * StepIndicator
 *
 * - displays each step as a dot
 * - indicates current step by accent color
 */
export function StepIndicator({ step: currentStep, stepsCount }: StepIndicatorProps): JSX.Element {
  if (stepsCount <= currentStep) {
    return <div data-testid={TEST_IDS.EMPTY_DIV} />;
  }

  const steps = Array.from(Array(stepsCount).keys());

  return (
    <Container data-testid={TEST_IDS.STEP_INDICATOR}>
      {steps.map((step) => (
        <Dot key={step} current={step === currentStep} data-testid={TEST_IDS.STEP_INDICATOR_DOT + step} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 0.5rem 0;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    padding: 1rem 0;
  }
`;

type DotProps = {
  current: boolean;
};

const Dot = styled.div`
  margin: 0 0.25rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  background-color: ${({ current }: DotProps): string => (current ? '#4d5bff' : '#c8d0df')};

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    margin: 0 0.4375rem;
    width: 0.625rem;
    height: 0.625rem;
  }
`;
