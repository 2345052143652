import { notUndefined } from '@taraai/utility';
import { createEntity, getEntityData, getEntityRanges } from 'components/editor/entities';
import { ContentBlock, ContentState, Modifier, SelectionState } from 'draft-js';

// TODO: move this to components/editor/mentions*

export const mentionRegex = /@tara-user-([\da-z]+)/gi;

/**
 * Upgrades editor state with mentions in `@tara-user-<ID>` format
 * into `@<USER>` entity with ID stored in entity data object.
 *
 * It does so by searching through all ContentBlocks using
 * `@tara-user-<ID>` regex and then replacing matches with
 * text fetched based on parsed `<ID>` using `idToText()` function.
 * Each mention replacement is additionally annotated with `mention`
 * entity data containing parsed user id.
 *
 * If the parsed `<ID>` does not correspond to any valid user,
 * `idToText` function is expected to return `undefined`.
 */
export const upgradeToEntity = (content: ContentState, idToText: (id: string) => string | undefined): ContentState => {
  const mentionSpans = content
    .getBlocksAsArray()
    .map((block) => getMentionsFromBlock(block, mentionRegex))
    // reverse span order and flatten
    // it's important to reverse the order, so that mentions
    // are replaced from back to front
    // (in order to prevent replacement from screwing up next span in line)
    .reduce((acc, mentions) => [...acc, ...Array.from(mentions).reverse()], []);

  // go through all mentions and replace them one by one
  return mentionSpans.reduce((previousContent, metadata) => {
    const replacedText = idToText(metadata.userId);
    // if there is no user found, do not upgrade this mention to entity
    // TODO: It might be a good idea to remove such mentions
    if (!replacedText) {
      return previousContent;
    }

    const selection = getSelectionFromMentionSpan(metadata.span);

    // annotate the mention with user id
    const { contentWithEntity, key } = createEntity('mention', 'IMMUTABLE', { id: metadata.userId }, previousContent);
    return Modifier.replaceText(contentWithEntity, selection, replacedText, undefined, key);
  }, content);
};

export const upgradeMentionsTransform = (idToText: (id: string) => string | undefined) => (
  content: ContentState,
): ContentState => upgradeToEntity(content, idToText);

type MentionMetadata = {
  userId: string;
  span: {
    start: { key: string; offset: number };
    end: { key: string; offset: number };
  };
};

export const getSelectionFromMentionSpan = ({ start, end }: MentionMetadata['span']): SelectionState =>
  SelectionState.createEmpty(start.key).merge({
    anchorKey: start.key,
    anchorOffset: start.offset,
    focusKey: start.key,
    focusOffset: end.offset,
    isBackward: false,
  }) as SelectionState;

type RegExpMatch = {
  text: string;
  index: number;
  length: number;
  groups: string[];
};

// Wrap imperative stateful regex.exec API in something more manageble
const matchAll = (str: string, regex: RegExp): RegExpMatch[] => {
  const allMatches: RegExpMatch[] = [];
  let match: RegExpExecArray | null = null;
  // eslint-disable-next-line no-loops/no-loops
  while ((match = regex.exec(str)) !== null) {
    const [text, ...groups] = match;
    allMatches.push({
      index: match.index,
      length: text.length,
      text,
      groups,
    });
  }
  return allMatches;
};

/**
 * This function takes a `ContentBlock` finds all mentions based on
 * provided regex and returns a list of all mention metadata
 * (its span and parsed user id).
 */
export const getMentionsFromBlock = (block: ContentBlock, regex: RegExp): MentionMetadata[] => {
  const text = block.getText();
  const key = block.getKey();
  const allMatches = matchAll(text, regex);
  return allMatches.map(({ index, length, groups }) => ({
    span: {
      start: { key, offset: index },
      end: { key, offset: index + length },
    },
    userId: groups[0],
  }));
};

const getMentionMetadataFromEntities = (block: ContentBlock, content: ContentState): MentionMetadata[] => {
  const blockKey = block.getKey();
  return getEntityRanges('mention', content, block)
    .map(([start, end]) => {
      const entityKey = block.getEntityAt(start);
      const { id } = getEntityData('mention', content, entityKey);
      return {
        span: {
          start: { key: blockKey, offset: start },
          end: { key: blockKey, offset: end },
        },
        userId: id,
      };
    })
    .filter(notUndefined);
};

/**
 * Downgrades editor state with mentions as `mention` entities
 * with `@<USER>` text, into `@tara-user-<ID>` plain text tags.
 *
 * It does so by searching through all ContentBlocks entities with type
 * 'mention' and then replacing matches with plain text tags.
 */
export const downgradeToPlainText = (content: ContentState): ContentState => {
  const mentionMetadata = content
    .getBlocksAsArray()
    .map((block) => getMentionMetadataFromEntities(block, content))
    .reduce((acc, mentions) => [...acc, ...Array.from(mentions).reverse()], []);

  return mentionMetadata.reduce((previousContent, metadata) => {
    const selection = getSelectionFromMentionSpan(metadata.span);

    const plainTextMention = `@tara-user-${metadata.userId}`;
    // replace text with plain text mention tag and remove
    // annotated entity (entityKey === undefined).
    return Modifier.replaceText(previousContent, selection, plainTextMention);
  }, content);
};

export const downgradeMentionsTransform = (content: ContentState): ContentState => downgradeToPlainText(content);
