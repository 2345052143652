import { css, cx } from 'emotion';
import React from 'react';
import { requirementDetailsTestIDs } from 'resources/cypress/testAttributesValues';

export type BadgeProps = React.HTMLProps<HTMLSpanElement>;

/**
 * Badge
 * A non-clickable badge used to display information about something.
 */
export default function Badge({ children }: BadgeProps): JSX.Element {
  return (
    <span
      className={cx(
        css`
          display: block;
          text-align: center;
          width: fit-content;
          font-size: 0.875rem;
          font-weight: 500;
          padding: 0.375rem 0.3125rem 0.3125rem 0.3125rem;
          background-color: #eaeef5;
          border-radius: 0.125rem;
          color: #949caf;
          text-transform: uppercase;
          white-space: nowrap;
        `,
      )}
      data-cy={requirementDetailsTestIDs.INFORMATION_BADGE}
    >
      {children}
    </span>
  );
}
