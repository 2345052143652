import GitLabSnippet from 'components/app/controllers/views/GitLabSnippet';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import Modal from 'components/core/controllers/views/Modal';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React from 'react';
import { atomic } from 'resources';
import { gitlabIntegrationTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export interface GitlabModalProps extends React.HTMLProps<HTMLDivElement> {
  dataCy?: string;
  generateToken: () => void;
  secretToken: string | undefined;
  instructionImgSrc: string | undefined;
  isGeneratingToken: boolean;
  closeModal?: () => void;
}
export default function GitlabModal({
  dataCy,
  generateToken,
  secretToken,
  isGeneratingToken,
  closeModal,
  instructionImgSrc,
}: GitlabModalProps): JSX.Element {
  return (
    <Modal
      bodyStyle={css`
        padding: 1rem;
        line-height: 2rem;
      `}
      className={css`
        width: 60rem;
        border: solid 0.0625rem #dee3ec;
      `}
      closeModal={closeModal}
      data-cy={dataCy}
      footer={
        <Button data-cy={gitlabIntegrationTestIDs.DONE_BUTTON} onClick={closeModal}>
          {strings.integrations.gitlab.modal.done}
        </Button>
      }
      footerStyle={css`
        text-align: end;
      `}
      header={
        <div
          className={css`
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            letter-spacing: normal;
            color: var(--dark);
          `}
        >
          {strings.integrations.gitlab.modal.title}
        </div>
      }
    >
      <div>
        <Icon
          className={css`
            padding: 0rem;
            min-width: 7rem;
            min-height: 2.375rem;
          `}
          name='gitlabLong'
        />
      </div>
      <div>
        <div>{strings.integrations.gitlab.modal.heading}</div>
        <div>
          {strings.integrations.gitlab.modal.step1}
          <span
            className={css`
              font-size: 16px;
              font-weight: 600;
              line-height: 1;
              letter-spacing: normal;
              color: var(--dark);
            `}
          >
            {strings.integrations.gitlab.modal.settings}
          </span>
        </div>
        <div>
          {strings.integrations.gitlab.modal.step2}
          <GitLabSnippet />
        </div>
        <div>
          {strings.integrations.gitlab.modal.step3}
          <div>
            {secretToken ? (
              <GitLabSnippet secretToken={secretToken} token />
            ) : (
              <Button
                className={css`
                  min-height: 0rem;
                  max-width: 11.75rem;
                  min-width: 11.75rem;
                  max-height: 2.1875rem;
                  padding: 0.4375rem 1.1875rem 0.4375rem 1.1875rem;
                `}
                color='green'
                data-cy={gitlabIntegrationTestIDs.GENERATE_BUTTON}
                disabled={isGeneratingToken}
                onClick={generateToken}
              >
                {isGeneratingToken ? (
                  <span>
                    {strings.integrations.gitlab.modal.generatingToken}
                    <FastSmallSpinner
                      color={atomic.get(atomic.colors.white)}
                      size={0.8}
                      spinnerStyles={css`
                        padding: 0rem;
                        margin-left: 0.5rem;
                        margin-top: 0.043rem;
                      `}
                    />
                  </span>
                ) : (
                  strings.integrations.gitlab.modal.generateToken
                )}
              </Button>
            )}
          </div>
        </div>
        <div>{strings.integrations.gitlab.modal.step4}</div>
        <div>{strings.integrations.gitlab.modal.step5}</div>
        <div>
          <img
            alt={strings.integrations.gitlab.modal.alt}
            className={css`
              max-width: 55rem;
            `}
            src={instructionImgSrc}
          />
        </div>
      </div>
    </Modal>
  );
}
