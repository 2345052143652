import { notUndefined } from '@taraai/utility';
import { RichEditorPlugin } from 'components/editor/types';
import identity from 'lodash.identity';

export const getWhitespacePlugin = ({ trim = false, collapse = false } = {}): RichEditorPlugin<
  (source: string) => string
> => {
  const mods = [trim ? trimMod : undefined, collapse ? collapseMod : undefined].filter(notUndefined);
  return {
    pipeline: {
      read: identity,
      save: (source) => mods.reduce((result, mod) => mod(result), source),
    },
  };
};

function trimMod(text: string): string {
  return text.trim();
}

function collapseMod(text: string): string {
  return text.replaceAll(/\s+/g, ' ');
}
