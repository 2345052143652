import 'firebase/auth';
import 'firebaseui/dist/firebaseui.css';

import { noop } from '@taraai/utility';
import * as Emotion from 'emotion';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import React from 'react';
import { githubSrc } from 'resources/assets/icons/brand';
import { mailSrc } from 'resources/assets/icons/flat';
import { atomic } from 'resources/theme/atomic';
import { auth } from 'tools';
import { FirebaseCallback, useFirebaseAuth, useFirebaseUI } from 'tools/utils/hooks/firebase';

export const FIREBASE_UI_INTERFACE = {
  PROVIDER: {
    ANON: {
      provider: firebaseui.auth.AnonymousAuthProvider,
      providerName: 'Guest',
    },
  },
  KEY: {
    TOS_URL: 'tosUrl',
    CALLBACKS: 'callbacks',
    SIGN_IN_FLOW: 'signInFlow',
    SIGN_IN_OPTIONS: 'signInOptions',
    CREDENTIAL_HELPER: 'credentialHelper',
    PRIVACY_POLICY_URL: 'privacyPolicyUrl',
    SIGN_IN_SUCCESS_URL: 'signInSuccessUrl',
  },
  CALLBACK: {
    SHOWN: 'shown',
    MOUNTED: 'mounted',
    UNMOUNTED: 'unmounted',
    LOGGED_IN: 'loggedIn',
    LOGGED_OUT: 'loggedOut',
    SIGN_IN_SUCCESS: 'signInSuccess',
    SIGN_IN_FAILURE: 'signInFailure',
    ANON_MERGE_CONFLICT: 'autoAnonUpgradeMergeConflict',
  },
  FLOW: {
    POPUP: 'popup',
    REDIRECT: 'redirect',
  },
  CREDENTIAL_HELPER: {
    NONE: firebaseui.auth.CredentialHelper.NONE,
    ONE_TAP_SIGN_UP: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    ACCOUNT_CHOOSER: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM,
  },
  AUTO_UPGRADE_ANON: {
    // eslint-disable-next-line id-length
    NO: false,
    YES: true,
  },
  ERROR_CODE: {
    ANON_HAD_CREDENTIAL: 'auth/credential-already-in-use',
    ANON_MERGE_CONFLICT: 'firebaseui/anonymous-upgrade-merge-conflict',
  },
};

type FirebaseUICallbacks = firebaseui.auth.Config['callbacks'];

type FirebaseAuthUIProps = {
  uiID: string;
  config?: firebaseui.auth.Config;
  callbacks?: {
    [k: string]: (data: unknown, publicPage: boolean) => unknown;
  };
  defaultCallbacks: {
    [k: string]: (data: unknown, publicPage: boolean) => unknown;
  };
  successCB?: (...args: unknown[]) => boolean;
  className?: string;
  defaultUIConfig: firebaseui.auth.Config;
  style?: { main?: Emotion.ClassNamesArg };
  showTPOS?: boolean;
  redirect?: boolean;
  publicPage: boolean;
} & { [key: string]: unknown };

/**
 * Merges provided config with default callbacks
 */
const makeFirebaseUIConfig = (
  config: firebaseui.auth.Config,
  options: {
    redirect: boolean;
    showTPOS: boolean;
  },
  callbacks: {
    onShow: () => void;
    onAnonMergeConflict: (data: unknown) => void;
    onSignInFailure: (data: unknown) => void;
    onSignInSuccess: (data: unknown) => unknown;
    onSuccess: (data: unknown) => boolean;
  },
): firebaseui.auth.Config => {
  const firebaseCallbacks: FirebaseUICallbacks = {
    uiShown(): void {
      return callbacks.onShow();
    },
    signInFailure(error: { code: string }): Promise<void> {
      if (error.code === FIREBASE_UI_INTERFACE.ERROR_CODE.ANON_MERGE_CONFLICT) {
        return Promise.resolve(callbacks.onAnonMergeConflict({ error }));
      }

      return Promise.resolve(callbacks.onSignInFailure({ error }));
    },
    signInSuccessWithAuthResult(
      authResult: {
        additionalUserInfo?: firebase.auth.AdditionalUserInfo | null;
        user: firebase.User | null;
      },
      redirectUrl: string,
    ): boolean {
      return (
        callbacks.onSuccess(
          callbacks.onSignInSuccess({
            authResult,
            redirectUrl,
          }),
        ) || options.redirect
      );
    },
  };

  const tposConfig = options.showTPOS
    ? { tosUrl: config.tosUrl, privacyPolicyUrl: config.privacyPolicyUrl }
    : { tosUrl: undefined, privacyPolicyUrl: undefined };

  return {
    ...config,
    callbacks: firebaseCallbacks,
    ...tposConfig,
  };
};

export default function FirebaseAuthUI({
  uiID,
  showTPOS = false,
  redirect = false,
  callbacks,
  publicPage,
  className = '',
  defaultUIConfig,
  config: uiConfig,
  successCB = (): boolean => false,
  style: { main = {} } = {},
  defaultCallbacks: initialCallbacks,
  ...props
}: FirebaseAuthUIProps): JSX.Element | null {
  const getCallback = (key: string): FirebaseCallback => {
    const callbackMap: {
      [k: string]: (data: unknown, publicPage: boolean) => unknown;
    } = {
      ...initialCallbacks,
      ...callbacks,
    };

    return (data: unknown): unknown => (callbackMap[key] || noop)(data, publicPage);
  };

  const providedConfig = {
    ...defaultUIConfig,
    ...uiConfig,
  };

  const onShow = getCallback(FIREBASE_UI_INTERFACE.CALLBACK.SHOWN);
  const onAnonMergeConflict = getCallback(FIREBASE_UI_INTERFACE.CALLBACK.ANON_MERGE_CONFLICT);
  const onSignInFailure = getCallback(FIREBASE_UI_INTERFACE.CALLBACK.SIGN_IN_FAILURE);
  const onSignInSuccess = getCallback(FIREBASE_UI_INTERFACE.CALLBACK.SIGN_IN_SUCCESS);

  const firebaseUIConfig = makeFirebaseUIConfig(
    providedConfig,
    {
      redirect,
      showTPOS,
    },
    {
      onShow,
      onAnonMergeConflict,
      onSignInFailure,
      onSignInSuccess,
      onSuccess: successCB,
    },
  );

  const uiWidgetInstance = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth());

  useFirebaseUI(uiWidgetInstance, uiID, firebaseUIConfig, {
    onMounted: getCallback(FIREBASE_UI_INTERFACE.CALLBACK.MOUNTED),
    onUnmounted: getCallback(FIREBASE_UI_INTERFACE.CALLBACK.UNMOUNTED),
  });
  useFirebaseAuth(auth(), uiWidgetInstance, {
    onLoggedIn: getCallback(FIREBASE_UI_INTERFACE.CALLBACK.LOGGED_IN),
    onLoggedOut: getCallback(FIREBASE_UI_INTERFACE.CALLBACK.LOGGED_OUT),
  });

  return (
    <div
      {...props}
      className={Emotion.cx(
        Emotion.css`
    .firebaseui-container {
      margin: 0;
      width: 21.875rem;
    }

    .firebaseui-card-content {
      padding: 0;
    }

    * {
      letter-spacing: 0.0313rem;
      font-family: ${atomic.get(atomic.fonts.family)};
      ${atomic.transition({ duration: '.25s' })};
    }

    button.firebaseui-idp-button.mdl-button.mdl-js-button.mdl-button--raised.firebaseui-id-idp-button {
      height: 2.875rem;
      max-width: none;
      background-color: white !important;
      border: solid 0.0625rem ${atomic.get(atomic.colors.grey4)};
      border-radius: 0.1875rem;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    button.firebaseui-idp-github > .firebaseui-idp-icon-wrapper > img {
      content: url(${githubSrc});
    }

    button.firebaseui-idp-password > .firebaseui-idp-icon-wrapper > img {
      content: url(${mailSrc});
    }

    span.firebaseui-idp-text.firebaseui-idp-text-long {
      font-weight: 500;
      color: #191919;
      margin-top: 0.25rem;
    }

    .mdl-card {
      box-shadow: none !important;
    }

    .firebaseui-id-page-sign-in .firebaseui-card-header,
    .firebaseui-id-page-federated-linking .firebaseui-card-header {
      display: none;
    }

    .firebaseui-card-header {
      padding-left: 0;
    }

    input.firebaseui-input,
    input.firebaseui-id-email,
    input.mdl-textfield__input {
      border: solid 0.0625rem ${atomic.get(atomic.colors.grey5)} !important;
      padding: 1rem;
      border-radius: 0.1875rem;
    }

    input.firebaseui-input-invalid {
      border: solid 0.0625rem ${atomic.get(atomic.colors.error)} !important;
    }
    .firebaseui-textfield,
    .firebaseui-textfield.is-focused {
      margin-top: 1rem;
      label {
        top: -0.375rem !important;
        font-size: 0.875rem !important;
        font-weight: normal !important;
        line-height: 1.57 !important;
        letter-spacing: normal !important;
        color: ${atomic.get(atomic.colors.dark)} !important;
        &:after {
    display: none !important;
              }
            }
    }

    .firebaseui-card-actions {
      padding: 0;
      padding-top: 1rem;
    }

    .firebaseui-input-floating-button.firebaseui-id-password-toggle {
      top: 3.25rem;
      right: 1rem;
    }

    .firebaseui-button.mdl-button.mdl-js-button {
      text-transform: none;
      font-size: 0.875rem;
      padding: 0.5rem 2.1875rem;
      line-height: 0.875rem;
      border-radius: 0.1875rem;
      box-shadow: none;
      height: auto;

      &.mdl-button--colored {
        background-color: ${atomic.get(atomic.colors.primary)};
            }

      &:not(.mdl-button--colored) {
        border: solid 0.0625rem ${atomic.get(atomic.colors.grey5)} !important;
        color: ${atomic.get(atomic.colors.dark)} !important;
            }
    }

    .firebaseui-form-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      & > * {
        flex: 1;
        margin-left: 0;
            }

      & > * + * {
        margin-left: 0.5rem;
            }
    }
  `,
        className,
        main,
      )}
      id={uiID}
    />
  );
}
