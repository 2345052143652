import UpdateSprintController from 'components/app/controllers/UpdateSprintController';
import React from 'react';

/**
 * StartSprint route for start sprint modal
 *
 */
export default function StartSprint(): JSX.Element {
  return <UpdateSprintController />;
}
