import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data, Functions } from '@taraai/types';
import { setUserAccessLevel } from 'reduxStore/usersWithAccessLevels';
import { ExtraAPI } from 'reduxStore/utils/types';

interface GrantPermissionActionPayload {
  userId: Data.Id.UserId;
  accessLevel: Data.AccessLevel;
}

export const grantPermission = createAsyncThunk(
  'GrantPermission',
  async ({ accessLevel, userId }: GrantPermissionActionPayload, { dispatch, extra }) => {
    const { getFirebase, getOrgID } = extra as ExtraAPI;
    const organizationId = getOrgID();

    const payload: Functions.GrantPermission.Payload = {
      accessLevel,
      organizationId,
      userId,
    };

    await getFirebase().functions().httpsCallable('grantPermission')(payload);

    await dispatch(setUserAccessLevel(payload));
  },
);
