import { UI } from '@taraai/types';
import { noop } from '@taraai/utility';
import React, { createContext, useState } from 'react';

/**
 * Context for managing `globalDragStatus` state.
 */

type TaskFragment = Pick<UI.UITask, 'id' | 'title' | 'assignee' | 'effortLevel' | 'status' | 'slug' | '_relationships'>;
export const DnDContext = createContext<{
  globalDragStatus: DragStatus;
  notifyDragStart: (dragSource: DragSource, task: TaskFragment) => void;
  notifyDragStop: () => void;
}>({
  globalDragStatus: { isDragging: false },
  notifyDragStart: noop,
  notifyDragStop: noop,
});

export type DragSource = { type: 'backlog' } | { type: 'sprint'; sprintId: string };

export type DragStatus =
  | {
      isDragging: true;
      dragSource: DragSource;
      taskRequirement: string | null;
    }
  | { isDragging: false };

/**
 * Provides `globalDragStatus` state, which is used
 * to determine if any item is being dragged and where is
 * it being dragged from.
 */
export const DnDStatusProvider: React.FC = ({ children }) => {
  const [globalDragStatus, setGlobalDragStatus] = useState<DragStatus>({
    isDragging: false,
  });
  return (
    <DnDContext.Provider
      value={{
        globalDragStatus,
        notifyDragStart: (dragSource, task): void => {
          setGlobalDragStatus({
            isDragging: true,
            dragSource,
            taskRequirement: task._relationships.requirement,
          });
        },
        notifyDragStop: (): void => {
          setGlobalDragStatus({ isDragging: false });
        },
      }}
    >
      {children}
    </DnDContext.Provider>
  );
};
