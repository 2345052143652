import { Data } from '@taraai/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { getOrgList, selectActiveOrgIds } from 'reduxStore';

import { InstallableServiceWithRedirects } from './interfaces';
import { SetupIntegration } from './SetupIntegration';
import { WorkspaceSelectLayout } from './WorkspaceSelectLayout';

type IntegrationWorkspaceSelectControllerProps = {
  service: InstallableServiceWithRedirects;
  urlPayload: Record<string, string>;
};

/**
 * Displays a list of user's workspaces to install integration on.
 * When user chooses one, it renders SetupIntegration
 */
export function WorkspaceSelect({ service, urlPayload }: IntegrationWorkspaceSelectControllerProps): JSX.Element {
  const activeOrgIds = useSelector(selectActiveOrgIds);

  const orgListSlice = getOrgList(activeOrgIds);

  useFirestoreConnect(orgListSlice.query);

  const activeUserOrgs = useSelector(orgListSlice.selectors.all());

  const [selectedWorkspace, setSelectedWorkspace] = useState<Data.Id.OrganizationId | null>(null);

  const { teamID } = useParams<{
    teamID: Data.Id.TeamId;
  }>();

  const selectWorkspace = useCallback(
    (orgID: Data.Id.OrganizationId) => (): void => {
      setSelectedWorkspace(orgID);
    },
    [],
  );

  // When user belongs only to a single workspace, select it automatically
  useEffect(() => {
    if (activeOrgIds.length === 1) {
      setSelectedWorkspace(activeOrgIds[0]);
    }
  }, [activeOrgIds]);

  if (selectedWorkspace) {
    return <SetupIntegration orgID={selectedWorkspace} service={service} teamID={teamID} urlPayload={urlPayload} />;
  }

  return <WorkspaceSelectLayout onWorkspaceClick={selectWorkspace} service={service} userOrgs={activeUserOrgs} />;
}
