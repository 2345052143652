import { compose, unwrapResult } from '@reduxjs/toolkit';
import { Data, RequirementPartial, UI } from '@taraai/types';
import { NewRequirement as NewRequirementLayout } from 'components/app/controllers/views/NewRequirement';
import { linkTo } from 'components/Router/paths';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { createRequirement, reduxStore, selectTeam } from 'reduxStore';
import { decode } from 'reduxStore/utils/decoders';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

export interface NewRequirementProps extends React.HTMLProps<HTMLDialogElement> {
  createRequirementFromSprintsPage?: boolean;
}

const teamFragment = (team?: UI.UITeam): Pick<UI.UITeam, 'id' | 'name'> | undefined =>
  team && {
    id: team.id,
    name: team.name,
  };

export default function NewRequirement({
  createRequirementFromSprintsPage = false,
}: NewRequirementProps): JSX.Element | null {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();
  const history = useHistory();

  // eslint-disable-next-line xss/no-mixed-html
  const createRequirementInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { addToast } = useToast();
  const [inputError, setInputError] = useState('');

  const currentTeam = useSelector(compose(teamFragment, selectTeam(orgID, teamID)));

  const goBack = useCallback(() => {
    history.go(-1);
  }, [history]);

  const createNewRequirement = useCallback(() => {
    const showError = (errorMessage: string): void =>
      addToast({
        type: 'error',
        message: strings
          .formatString(strings.requirements.createFailure, {
            errorMessage,
          })
          .toString(),
      });

    if (!currentTeam) return showError(strings.requirements.createMissingPreferredTeam);

    // validate input before calling action
    const inputValue = createRequirementInputRef.current.value;
    try {
      decode<RequirementPartial>({ title: inputValue }, 'RequirementPartial');
    } catch (error) {
      return setInputError(strings.requirements.createTooShort);
    }
    return reduxStore
      .dispatch(
        createRequirement({
          // newly created requirements should have assign user's preferred team
          assignedTeamIds: [currentTeam.id],
          title: inputValue,
        }),
      )
      .then(unwrapResult)
      .then((success) => {
        const { id: requirementID } = success;
        segment.track('RequirementCreated', {
          orgID,
          requirementID,
          location: createRequirementFromSprintsPage ? 'RequirementsPage' : 'RequirementsBacklog',
        });
        // if sprints page
        if (createRequirementFromSprintsPage) {
          const message = strings
            .formatString(strings.requirements.createSuccess, { teamName: currentTeam.name })
            .toString();
          addToast({ message, type: 'success', timeoutMs: 3000 });

          return goBack();
        }
        return history.push(linkTo('requirement', { orgID, teamID, requirementID }));
      })
      .catch((error: Error) => showError(error.message));
  }, [currentTeam, orgID, createRequirementFromSprintsPage, history, teamID, addToast, goBack]);

  const createButtonText = createRequirementFromSprintsPage
    ? strings.requirements.create
    : strings.requirements.createAndDefine;

  const titleText = createRequirementFromSprintsPage
    ? strings.requirements.createRequirement
    : strings.requirements.createAndDefine;

  return (
    <NewRequirementLayout
      createButtonText={createButtonText}
      inputError={inputError}
      inputRef={createRequirementInputRef}
      onCancel={goBack}
      onCreate={createNewRequirement}
      titleText={titleText}
    />
  );
}
