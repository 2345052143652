/* eslint-disable sonarjs/cognitive-complexity */
import { compose, unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import { Data, Functions, UI } from '@taraai/types';
import BacklogTasks from 'components/app/controllers/BacklogTasks';
import { ImportedTasksController } from 'components/app/controllers/ImportedTasksController';
import RequirementsListController from 'components/app/controllers/RequirementsListController';
import RequirementTasksController from 'components/app/controllers/RequirementTasksController';
import TaskModalContext from 'components/app/controllers/TaskModalContext';
import TeamBacklogPopupController from 'components/app/controllers/TeamBacklogPopupController';
import { AreaDropZone } from 'components/app/controllers/views/AreaDropZone';
import CreateTaskInput from 'components/app/controllers/views/CreateTaskInput';
import { DnDContext } from 'components/app/controllers/views/DraggableFeatureCard/DragAndDropContext';
import EmptyBacklogView from 'components/app/controllers/views/EmptyBacklogView';
import SprintsBacklogHeader from 'components/app/controllers/views/SprintsBacklogHeader';
import { css, cx } from 'emotion';
import React, { useCallback, useContext, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { getRequirements, incompleteBacklogTasks, reduxStore } from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { strings } from 'resources';
import { sprintsTabTestIDs } from 'resources/cypress/testAttributesValues';
import { useToast } from 'tools';
import { sort } from 'tools/libraries/helpers/sort';

import { sprintsBacklogWrapperStyles } from './styles';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title' | 'backlogTasksCount' | 'archived'>;

/**
 * Sprints backlog component. Will be replaced with work drawer in Tara 3.0.
 * @deprecated
 */
export default function SprintsBacklog({ className, ...props }: React.HTMLProps<HTMLDivElement>): JSX.Element {
  const { orgID, teamID } = useParams<{ orgID: Data.Id.OrganizationId; teamID: Data.Id.TeamId }>();

  const { openModal } = useContext(TaskModalContext);

  const taskSlice = incompleteBacklogTasks(orgID);
  const requirementsSlice = getRequirements(orgID, teamID, {
    orderBy: 'updatedAt',
  });

  const tasks = useSelector(taskSlice.selector) ?? [];

  const taraTasksSelector = compose((allTasks) => {
    const filteredTasks = (allTasks ?? [])
      .filter((task) => !task.externalIssue || task.externalIssue.service === 'slack')
      .filter((task) => task._relationships.requirement === null);
    return sort(filteredTasks, 'createdAt', true);
  }, taskSlice.selector);

  const taraTasks = useSelector(taraTasksSelector, deepEquals);

  const requirements = (useSelector(
    compose(
      (data: UI.UIRequirement[]) =>
        data?.map((requirement) => ({
          id: requirement.id,
          title: requirement.title,
          backlogTasksCount: requirement.backlogTasksCount,
          archived: requirement.archived,
        })),
      requirementsSlice.selector,
    ),
  ) ?? []) as RequirementFragment[];

  useFirestoreConnect([...taskSlice.query, ...requirementsSlice.query]);

  const { globalDragStatus } = useContext(DnDContext);

  const [selectedRequirement, setSelectedRequirement] = useState<RequirementFragment | undefined>();
  const [displayBacklogTasks, setDisplayBacklogTasks] = useState(false);
  const [displayTasksImportedFromService, setDisplayImportTasks] = useState(
    null as Functions.ImportCSVFile.CSVImportableService | Data.ExternalIssue.Any['service'] | null,
  );

  const { addToast } = useToast();

  const moveTaskToBacklog = useCallback(
    (taskID: string): void => {
      reduxStore
        .dispatch(updateTask({ id: taskID, sprint: null }))
        .then(unwrapResult)
        .catch((error: Error) => addToast({ type: 'error', message: error.message }));
    },
    [addToast],
  );

  const moveTaskToSprint = useCallback(
    (task, sprintID: string): void => {
      reduxStore
        .dispatch(
          updateTask({
            id: task?.id || task?.firebaseMetadata.id,
            sprint: sprintID,
          }),
        )
        .then(unwrapResult)
        .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
    },
    [addToast],
  );

  const getRequirementTasks = useCallback((requirement) => {
    setSelectedRequirement(requirement);
  }, []);

  const getBacklogTasks = useCallback(() => {
    setDisplayBacklogTasks(true);
  }, []);

  const selectNoRequirement = useCallback(() => {
    setSelectedRequirement(undefined);
  }, []);

  const getGroups = useCallback(() => {
    setDisplayBacklogTasks(false);
  }, []);

  const hideImportedTasks = useCallback(() => {
    setDisplayImportTasks(null);
  }, []);

  const toggleModal = useCallback(
    (task) => {
      openModal(task, taraTasksSelector, strings.tasks.tasks);
    },
    [openModal, taraTasksSelector],
  );

  const shouldDisplayProductList =
    !selectedRequirement && !displayBacklogTasks && displayTasksImportedFromService === null;
  const displayEmptyBacklog = requirements?.length === 0 && tasks?.length === 0;

  const disableDropZone = globalDragStatus.isDragging && globalDragStatus.dragSource.type === 'backlog';

  const draggedRequirement = globalDragStatus.isDragging
    ? requirements?.find((req) => req.id === globalDragStatus.taskRequirement)
    : null;

  const dropZoneStrings = strings.sprints.dropZone;
  const label = draggedRequirement
    ? (strings.formatString(dropZoneStrings.backlogWithRequirement, {
        requirement: draggedRequirement.title,
      }) as string)
    : dropZoneStrings.backlog;

  return (
    <AreaDropZone
      className={cx(
        css`
          ${sprintsBacklogWrapperStyles};
        `,
        className,
      )}
      disable={disableDropZone}
      label={label}
      onItemDrop={(item): void => {
        moveTaskToBacklog(item.id);
      }}
      {...props}
    >
      <SprintBacklogHeaderContainer>
        <SprintsBacklogHeader />
      </SprintBacklogHeaderContainer>
      {!displayTasksImportedFromService && (
        <CreateTaskInput dataCy={sprintsTabTestIDs.NEW_TASK_INPUT_SPRINT_BACKLOG} requirement={selectedRequirement} />
      )}
      {selectedRequirement && (
        <RequirementTasksController getRequirements={selectNoRequirement} requirement={selectedRequirement} />
      )}
      {displayBacklogTasks && (
        <BacklogTasks
          getGroups={getGroups}
          onMoveToBacklog={moveTaskToBacklog}
          onMoveToSprint={moveTaskToSprint}
          tasks={taraTasks}
          toggleModal={toggleModal}
        />
      )}
      {displayTasksImportedFromService === null ? null : (
        <ImportedTasksController
          onBackClick={hideImportedTasks}
          onMoveToBacklog={moveTaskToBacklog}
          onMoveToSprint={moveTaskToSprint}
          service={displayTasksImportedFromService}
        />
      )}
      {shouldDisplayProductList && (
        <RequirementsListController
          backlogTaskCount={taraTasks.length}
          getBacklogTasks={getBacklogTasks}
          getRequirementTasks={getRequirementTasks}
          listItem={taraTasks}
          requirements={requirements ?? []}
          showImportedTasks={setDisplayImportTasks}
        />
      )}
      <TeamBacklogPopupController />
      {displayEmptyBacklog && <EmptyBacklogView />}
    </AreaDropZone>
  );
}
const SprintBacklogHeaderContainer = styled('div', {
  padding: '1.125rem 1rem',
});
