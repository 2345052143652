import { css, cx } from 'emotion';
import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { segment } from 'tools/libraries/analytics';
import { setPendingIntegrationOrg } from 'tools/utils/pendingIntegrationOrg';
import { ConnectAccountGenericData } from 'types/connect-account';
import { InstallIntegrationGenericData, Parameter } from 'types/install-integration';

export interface InstallIntegrationFormProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Text to be displayed as a prompt when a user hovers the component.
   */
  hoverPromptText?: string;
  installationData: InstallIntegrationGenericData | ConnectAccountGenericData;
  isLoading?: boolean;
  onClick?: () => void;
  method: 'get' | 'post';
  dataCy?: string;
}

/**
 * An HoC component that wraps its child into form.
 * The form submits action with specified url firebase function.
 *
 * This component doesn't have a visible UI. It takes the shape of its child.
 */
export default function InstallIntegrationForm({
  className,
  children,
  hoverPromptText,
  installationData,
  dataCy,
  isLoading = false,
  disabled,
  onClick,
  method,
  ...props
}: InstallIntegrationFormProps): JSX.Element {
  const isInstalled = (installationData as InstallIntegrationGenericData).id ?? false;
  const userHasPermission = (installationData as InstallIntegrationGenericData).userHasPermission ?? true;
  const disableForm = disabled || !userHasPermission || !installationData.isFeatureEnabled || isInstalled;

  const { orgID } = useParams<{
    orgID: string;
  }>();

  const onSubmit = useCallback(() => {
    if (installationData.saveOrgForSetupIntegration) {
      setPendingIntegrationOrg(orgID);
    }

    segment.track('IntegrationConnected', {
      orgID,
      service: installationData.service,
      location: 'IntegrationFormButton',
    });

    if (onClick) {
      onClick();
    }
  }, [orgID, onClick, installationData.service, installationData.saveOrgForSetupIntegration]);

  return (
    <div
      className={cx(
        css`
          position: relative;
          &:hover #installationPrompt {
            opacity: ${hoverPromptText ? 1 : 0};
          }
          &:hover #installIntegrationFormChild {
            opacity: ${disableForm ? '0.5' : '0.8'};
          }
        `,
        className,
      )}
      {...props}
    >
      {children}
      <form action={installationData.actionURL} method={method} onSubmit={onSubmit}>
        {installationData.parameters.map((parameter: Parameter) => {
          return <input key={parameter.name} name={parameter.name} type='hidden' value={parameter.value} />;
        })}
        <input
          className={css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            cursor: ${disableForm || isLoading ? 'not-allowed' : 'pointer'};
            pointer-events: ${disableForm ? 'none' : 'auto'};
          `}
          data-cy={dataCy}
          name='submit'
          type='submit'
          value=''
        />
      </form>
      <div
        className={css`
          position: absolute;
          padding: 0.5rem;
          bottom: 0.1875rem;
          left: 50%;
          transform: translate(-50%, 100%);
          margin: 0 auto;
          text-align: center;
          background-color: #595959;
          color: white;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-radius: 0.1875rem;
          opacity: 0;
          transition: opacity 0.4s;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 0.5rem;
            height: 0.5rem;
            background-color: #595959;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        `}
        id='installationPrompt'
      >
        {hoverPromptText}
      </div>
    </div>
  );
}
