import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { strings } from 'resources/i18n';

interface SprintOverloadedCardProps {
  overloadPoints: number;
}

export function SprintOverloadedCard({ overloadPoints }: SprintOverloadedCardProps): JSX.Element {
  return (
    <Container>
      <FlexBoxWrapper>
        <AlertIcon name='redAlert' />
        <SprintOverloadText>
          {strings.formatString(strings.sprints.sprintColumn.sprintOverloadPoints, { overloadPoints })}
        </SprintOverloadText>
      </FlexBoxWrapper>
      <FlexBoxWrapper>
        <SmallInfoIcon name='infoCircle' />
      </FlexBoxWrapper>
    </Container>
  );
}

const Container = styled('div', {
  alignItems: 'center',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.5rem',
});

const AlertIcon = styled(Icon, { padding: '0rem', width: '0.625rem', height: '0.625rem' });

const SprintOverloadText = styled('div', {
  fontWeight: '600',
  fontSize: '0.625rem',
  color: '#E86262',
  padding: '0 0 0.125rem 0.1875rem',
});

const FlexBoxWrapper = styled('div', { display: 'flex', alignItems: 'center' });

const SmallInfoIcon = styled(Icon, { padding: '0rem', width: '0.625rem', height: '0.625rem' });
