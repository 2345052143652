import Icon from 'components/core/controllers/views/Icon';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css, cx } from 'emotion';
import React from 'react';
import { IconName } from 'resources';
import { atomic } from 'resources/theme';

export interface IntegrationBoxViewProps extends React.HTMLProps<HTMLDivElement> {
  icon: JSX.Element | IconName;
  iconColor?: string;
  title: string;
  description: string | JSX.Element;
  actionButton: JSX.Element;
  children?: JSX.Element | null;
  isLoading: boolean;
  className?: string;
}

const IntegrationBoxView = ({
  icon,
  iconColor = '',
  title,
  description,
  actionButton,
  children,
  isLoading,
  className,
}: IntegrationBoxViewProps): JSX.Element => (
  <div
    className={cx(
      css`
        border-radius: 0.1875rem;
        box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.1);
        background-color: ${atomic.get(atomic.colors.white)};
        z-index: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      `,
      className,
    )}
  >
    <div
      className={css`
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
    >
      {typeof icon === 'string' ? (
        <Icon
          className={css`
            width: 2rem;
            height: 2rem;
          `}
          color={iconColor}
          name={icon}
        />
      ) : (
        icon
      )}
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 0 0.5rem;
          flex-grow: 1;
        `}
      >
        <p
          className={css`
            font-size: 1rem;
            font-weight: 500;
            color: ${atomic.get(atomic.colors.dark)};
          `}
        >
          {title}
        </p>
        <p
          className={css`
            font-size: 0.875rem;
            line-height: 1.57;
            color: #575f65;
          `}
        >
          {description}
        </p>
      </div>
      {isLoading ? (
        <StandardSpinner
          className={css`
            margin-right: 2.75em;
          `}
          size='small'
        />
      ) : (
        actionButton
      )}
    </div>
    {children}
  </div>
);

export default IntegrationBoxView;
