import { Data, UI } from '@taraai/types';
import TaskModalContext from 'components/app/controllers/TaskModalContext';
import GanttChart from 'components/core/controllers/views/GanttChart';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Selector, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { compose } from 'redux';
import { getUsers } from 'reduxStore';
import { RootState } from 'reduxStore/store';
import { toDate } from 'tools/libraries/helpers/dates';
import { filterTaraTasks, getResults } from 'tools/libraries/helpers/tableFilter';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;
type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

export interface SprintDetailsLayoutProps extends React.HTMLProps<HTMLDivElement> {
  organizationID: Data.Id.OrganizationId;
  selectedSprint: UI.UISprint;
  requirements: RequirementFragment[];
  currentDate?: Date;
  tasksSelector: Selector<RootState, UI.UITask[] | undefined>;
}

// FIXME: this should be locale and user specified
const DATE_FORMAT = 'D MMM ddd';

function enumerateDaysBetweenDates(start: Date, end: Date): moment.Moment[] {
  const dates: moment.Moment[] = [];
  const currDate = moment(start).subtract(1, 'days').startOf('day');
  const lastDate = moment(end).add(1, 'days').startOf('day');
  // eslint-disable-next-line no-loops/no-loops
  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone());
  }
  return dates;
}

/**
 * Displays the Gantt Chart and handles all data that will be passed to the chart
 */
export default function SprintDetailsTimeline({
  selectedSprint,
  organizationID,
  currentDate = new Date(),
  requirements,
  tasksSelector,
  ...props
}: SprintDetailsLayoutProps): JSX.Element | null {
  const { teamID } = useParams<{ teamID: Data.Id.TeamId }>();
  const [filteredByAssignee, setFilteredByAssignee] = useState<UI.UITask[]>([]);
  const [filteredByRequirement, setFilteredByRequirement] = useState<UI.UITask[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<UI.UITask[]>([]);

  const { openModal } = useContext(TaskModalContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tasks = useSelector(tasksSelector) ?? [];

  const assignees = useSelector(
    compose(
      (users = []) => users.filter(({ teamIds }) => teamIds[organizationID].includes(teamID)),
      getUsers(organizationID).selector,
    ),
  );

  const handleFiltering = useCallback(
    (selectedAssignees: UserFragment[], selectedRequirements: RequirementFragment[]) => {
      const assigneeIds = selectedAssignees.map(({ id }) => id);
      const requirementsIds = selectedRequirements.map(({ id }) => id);
      setFilteredByAssignee(filterTaraTasks(tasks, 'assignee', assigneeIds));
      setFilteredByRequirement(filterTaraTasks(tasks, 'requirementDocument', requirementsIds));
    },
    [tasks],
  );

  useEffect(() => {
    const combinedResults = getResults(filteredByAssignee, filteredByRequirement);
    setFilteredTasks(combinedResults);
  }, [filteredByAssignee, filteredByRequirement]);

  const toggleModal = useCallback(
    (task) => {
      openModal(task, tasksSelector, selectedSprint.sprintName);
    },
    [openModal, selectedSprint.sprintName, tasksSelector],
  );

  const startDate = selectedSprint?.initialStartDate;
  const endDate = selectedSprint?.initialEndDate;

  if (!startDate || !endDate) {
    return null;
  }

  const enumeratedDates = enumerateDaysBetweenDates(toDate(startDate), toDate(endDate));
  const formattedDates = enumeratedDates.map((date: moment.Moment) => date.format(DATE_FORMAT));
  const today = moment(currentDate).format(DATE_FORMAT);

  return (
    <div {...props}>
      <GanttChart
        assignees={assignees}
        dates={formattedDates}
        handleFiltering={handleFiltering}
        orgID={organizationID}
        requirements={requirements}
        sprint={selectedSprint}
        tasks={filteredTasks || tasks}
        today={today}
        toggleModal={toggleModal}
      />
    </div>
  );
}
