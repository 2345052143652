import styled from '@emotion/styled';
import { Attachment, UI } from '@taraai/types';
import RequirementBuilderHeader from 'components/app/controllers/views/RequirementBuilderHeader';
import RequirementBuilderSubHeader from 'components/app/controllers/views/RequirementBuilderSubHeader';
import RequirementDescription from 'components/app/controllers/views/RequirementDescription';
import StickyPageHeader from 'components/core/controllers/views/StickyPageHeader';
import React from 'react';
import { UpdateRequirementTeamAction } from 'reduxStore';
import { FirebaseInput } from 'tools';

import { NoTeamsAssignedNotification } from './NoTeamsAssignedNotification';

type TeamFragment = Pick<UI.UITeam, 'id' | 'name'>;

interface Props {
  archiveRequirement: () => void;
  assignedTeams: TeamFragment[];
  forceSave: () => Promise<void>;
  isEditorConflict: boolean;
  manageRequirementTeam: (action: UpdateRequirementTeamAction) => (team: TeamFragment) => void;
  onAttachmentRemove: (attachment: Attachment) => void;
  onAttachmentUpload: (file: File) => Promise<string>;
  requirement: UI.UIRequirement;
  restoreArchivedRequirement: () => void;
  titleProps: FirebaseInput<string>;
}

/**
 * Contains main editor parts like requirement header (with the toolbar) and description
 */
export default function EditorSection({
  archiveRequirement,
  assignedTeams,
  forceSave,
  isEditorConflict,
  manageRequirementTeam,
  onAttachmentRemove,
  onAttachmentUpload,
  requirement,
  restoreArchivedRequirement,
  titleProps,
}: Props): JSX.Element {
  return (
    <Container>
      <StickyPageHeader prefixComponent={assignedTeams.length < 0 && <NoTeamsAssignedNotification />}>
        <RequirementBuilderHeader
          archiveRequirement={archiveRequirement}
          assignedTeams={assignedTeams}
          manageRequirementTeam={manageRequirementTeam}
          requirement={requirement}
          restoreArchivedRequirement={restoreArchivedRequirement}
          titleProps={titleProps}
        />
        <RequirementBuilderSubHeader
          forceSave={forceSave}
          isEditorConflict={isEditorConflict}
          onAttachmentUpload={onAttachmentUpload}
          requirement={requirement}
        />
      </StickyPageHeader>
      <RequirementDescriptionWrapper>
        <Separator />
        <RequirementDescription
          onAttachmentRemove={onAttachmentRemove}
          onAttachmentUpload={onAttachmentUpload}
          requirement={requirement}
        />
      </RequirementDescriptionWrapper>
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const RequirementDescriptionWrapper = styled((props) => (
  <div onKeyDown={(event): void | false => event.keyCode === 13 && event.stopPropagation()} role='none' {...props} />
))`
  width: 100%;
  max-width: 65.5rem;
  padding: 0 1.5rem;
  margin: 1rem 0 3rem;
`;

const Separator = styled.hr`
  border: solid 1px #dee3ec;
  border-top-width: 0px;
`;
