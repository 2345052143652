import { Markdown } from '@taraai/types';
import { isNonEmptyString, noop } from '@taraai/utility';
import DraftLink from 'components/core/controllers/views/DraftLink';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftHandleValue,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import { OrderedSet } from 'immutable';
import React from 'react';
import { DispatchWithCallback } from 'tools';

import { Style, STYLES } from './styles';

export const convertMarkdownToContentState = (rawMarkdown: Markdown): ContentState => {
  return stateFromMarkdown(rawMarkdown, {
    parserOptions: { atomicImages: true, breaks: true },
  });
};

export const blockBreakoutPlugin = createBlockBreakoutPlugin();

export type DraftKeyHandler = (event: React.KeyboardEvent, editorState: EditorState) => DraftHandleValue;

// source draft-js/lib/keyCommandInsertNewline.js.flow
function keyCommandInsertNewline(editorState: EditorState): EditorState {
  const contentState = Modifier.splitBlock(editorState.getCurrentContent(), editorState.getSelection());
  return EditorState.push(editorState, contentState, 'split-block');
}

function isEndOfLine(editorState: EditorState): boolean {
  const selection = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const block = currentContent.getBlockForKey(selection.getEndKey());

  return block.getLength() === selection.getEndOffset();
}

function clearInlineStyle(editorState: EditorState): EditorState {
  return EditorState.setInlineStyleOverride(editorState, OrderedSet<string>());
}

function clearInlineStyleIfEndOfLine(editorState: EditorState): EditorState {
  return isEndOfLine(editorState) ? clearInlineStyle(editorState) : editorState;
}

export const createReturnHandler = ({
  setEditorState,
  onShiftReturn,
  onReturn,
}: {
  setEditorState: (value: EditorState) => void;
  onShiftReturn?: DraftKeyHandler;
  onReturn?: DraftKeyHandler;
}) => (event: React.KeyboardEvent, editorState: EditorState): DraftHandleValue => {
  const isShiftReturn = event.nativeEvent.shiftKey;

  if (isShiftReturn && onShiftReturn) {
    const handledStatus = onShiftReturn(event, editorState);
    if (handledStatus === 'handled') return 'handled';
  }

  if (onReturn) {
    const handledStatus = onReturn(event, editorState);
    if (handledStatus === 'handled') return 'handled';
  }

  if (
    blockBreakoutPlugin.handleReturn(event, editorState, {
      setEditorState: (newEditorState: EditorState) => setEditorState(clearInlineStyleIfEndOfLine(newEditorState)),
    }) === 'handled'
  ) {
    return 'handled';
  }

  setEditorState(clearInlineStyleIfEndOfLine(keyCommandInsertNewline(editorState)));

  return 'handled';
};

export const createKeyCommandHandler = ({
  getEditorState,
  setEditorState,
}: {
  getEditorState: () => EditorState | null;
  setEditorState: (editorState: EditorState) => void;
}) => (command: string): DraftHandleValue => {
  if (command === 'backspace' || command === 'backspace-word' || command === 'backspace-to-start-of-line') {
    const editorState = getEditorState();
    if (editorState) {
      const newEditorState = RichUtils.onBackspace(editorState);
      if (newEditorState) {
        setEditorState(newEditorState);
        return 'handled';
      }
    }
  }
  return 'not-handled';
};

const linkifyPlugin = createLinkifyPlugin({
  target: '_blank',
  component: DraftLink,
});

/**
 * Utility function to remove block with a block key, great for removing images
 *
 * @param editorState: EditorState
 * @param blockKey: string
 */
export function removeBlockEntity(editorState: EditorState, blockKey: string): EditorState {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const block = blockMap.get(blockKey);
  const newBlock = block.merge({
    type: STYLES.BLOCK.UNSTYLED.value,
    text: '',
    characterList: block.getCharacterList().slice(0, 0),
    data: {},
  }) as ContentBlock;
  const newSelection = new SelectionState({
    anchorKey: blockKey,
    focusKey: blockKey,
    anchorOffset: 0,
    focusOffset: 0,
  });
  const newContent = content.merge({
    blockMap: blockMap.set(blockKey, newBlock),
  }) as ContentState;
  return EditorState.forceSelection(EditorState.push(editorState, newContent, 'change-block-type'), newSelection);
}

export function checkImageURL(url: string): boolean {
  return !!url.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
}

type ImageMetadata = { validUrl: false } | { validUrl: true; width: number; height: number };

export async function getImageMetadata(url?: string): Promise<ImageMetadata> {
  if (!isNonEmptyString(url)) return { validUrl: false };
  return new Promise<ImageMetadata>((resolve) => {
    const img = new Image();
    img.onerror = (): void => {
      resolve({ validUrl: false });
    };
    img.onabort = (): void => {
      resolve({ validUrl: false });
    };
    img.onload = (): void => {
      resolve({
        validUrl: true,
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
    img.src = url;
  });
}

export const customStyleMap = {
  [STYLES.INLINE.CODE.value]: STYLES.INLINE.CODE.styles,
  [STYLES.INLINE.STRIKETHROUGH.value]: STYLES.INLINE.STRIKETHROUGH.styles,
};

export function blockStyleFn(contentBlock: ContentBlock): string {
  const type = contentBlock.getType();
  if (type === STYLES.BLOCK.CODE.value) return STYLES.BLOCK.CODE.className;
  if (type === STYLES.BLOCK.BLOCKQUOTE.value) return STYLES.BLOCK.BLOCKQUOTE.className;
  return type;
}

export function getBlockRendererFn(allProps: Record<string, unknown>) {
  return (
    contentBlock: ContentBlock,
  ):
    | undefined
    | {
        props: Record<string, unknown>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component: (...args: any[]) => JSX.Element;
        editable: boolean;
      } => {
    const type = contentBlock.getType();
    if (type === STYLES.BLOCK.ATOMIC.value) {
      return STYLES.BLOCK.ATOMIC.renderer(allProps);
    }
    return undefined;
  };
}

const buildDecorators = (
  getEditorState: () => EditorState | null = (): null => null,
  setEditorState: DispatchWithCallback<EditorState> = noop,
): CompositeDecorator =>
  new CompositeDecorator([
    ...linkifyPlugin.decorators,
    {
      strategy: STYLES.ENTITY.LINK.decoratorStrategy,
      component: STYLES.ENTITY.LINK.decoratorComponent,
      props: {
        getEditorState,
        setEditorState,
      },
    },
    {
      strategy: STYLES.ENTITY.MENTION.decoratorStrategy,
      component: STYLES.ENTITY.MENTION.decoratorComponent,
      props: {
        getEditorState,
        setEditorState,
      },
    },
    {
      strategy: STYLES.ENTITY.MENTIONINSERTER.decoratorStrategy,
      component: STYLES.ENTITY.MENTIONINSERTER.decoratorComponent,
      props: {
        getEditorState,
        setEditorState,
      },
    },
  ]);

/**
 * Converts raw markdown string into editor state.
 *
 * - If conversion failed, returns empty editor state.
 * - If provided with current `editorState` it will preserve selection.
 */
export function convertRawMarkdownToEditorState(
  rawMarkdown?: Markdown,
  editorState: EditorState | null = null,
  getEditorState: () => EditorState | null = (): null => null,
  setEditorState: DispatchWithCallback<EditorState> = noop,
): EditorState {
  const decorator = buildDecorators(getEditorState, setEditorState);
  if (!rawMarkdown) return EditorState.createEmpty(decorator);
  try {
    const newEditorState = EditorState.createWithContent(convertMarkdownToContentState(rawMarkdown), decorator);
    if (editorState == null) return newEditorState;
    // try to preserve selection from old editor state
    const currentSelection = editorState.getSelection();
    return EditorState.forceSelection(newEditorState, currentSelection);
  } catch (error) {
    return EditorState.createEmpty(decorator);
  }
}

export function convertEditorStateToRawMarkdown(contentState: ContentState): Markdown {
  return stateToMarkdown(contentState);
}

export const toggleStyle = (style: Style) => (currentEditorState: EditorState): EditorState => {
  let nextEditorState;
  try {
    switch (style) {
      case STYLES.BLOCK.HEADERONE.value:
      case STYLES.BLOCK.HEADERTWO.value:
      case STYLES.BLOCK.HEADERTHREE.value:
      case STYLES.BLOCK.HEADERFOUR.value:
      case STYLES.BLOCK.HEADERFIVE.value:
      case STYLES.BLOCK.HEADERSIX.value:
      case STYLES.BLOCK.BLOCKQUOTE.value:
      case STYLES.BLOCK.UNORDERED.value:
      case STYLES.BLOCK.ORDERED.value:
      case STYLES.BLOCK.CODE.value:
        nextEditorState = RichUtils.toggleBlockType(currentEditorState, style);
        break;
      case STYLES.INLINE.BOLD.value:
      case STYLES.INLINE.ITALIC.value:
      case STYLES.INLINE.UNDERLINE.value:
      case STYLES.INLINE.STRIKETHROUGH.value:
      case STYLES.INLINE.CODE.value:
        nextEditorState = RichUtils.toggleInlineStyle(currentEditorState, style);
        break;
      default:
        nextEditorState = currentEditorState;
        break;
    }
  } catch (error) {
    nextEditorState = currentEditorState;
  }
  return nextEditorState;
};

export const isStyleActive = (style: Style, editorState: EditorState | undefined): boolean => {
  try {
    if (editorState === undefined) return false;
    switch (style) {
      case STYLES.BLOCK.HEADERONE.value:
      case STYLES.BLOCK.HEADERTWO.value:
      case STYLES.BLOCK.HEADERTHREE.value:
      case STYLES.BLOCK.HEADERFOUR.value:
      case STYLES.BLOCK.HEADERFIVE.value:
      case STYLES.BLOCK.HEADERSIX.value:
      case STYLES.BLOCK.BLOCKQUOTE.value:
      case STYLES.BLOCK.UNORDERED.value:
      case STYLES.BLOCK.ORDERED.value:
      case STYLES.BLOCK.CODE.value:
        return (
          style === editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey())?.getType()
        );
      case STYLES.INLINE.BOLD.value:
      case STYLES.INLINE.ITALIC.value:
      case STYLES.INLINE.UNDERLINE.value:
      case STYLES.INLINE.STRIKETHROUGH.value:
      case STYLES.INLINE.CODE.value:
        return editorState.getCurrentInlineStyle()?.has(style) ?? false;
      default:
        return false;
    }
  } catch (error) {
    return false;
  }
};

export const styleForKeyboardEvent = (event: React.KeyboardEvent): Style | null => {
  let style: string | null = null;
  if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === 'x')
    style = STYLES.INLINE.STRIKETHROUGH.value;
  if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === 'c') style = STYLES.INLINE.CODE.value;
  if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === '7') style = 'ordered-list';
  if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === '8') style = 'unordered-list';
  if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === '9') style = 'blockquote';
  if (style === null) style = getDefaultKeyBinding(event);
  if (
    style !== null &&
    Object.values(STYLES.INLINE)
      .map((inlineStyle) => inlineStyle.value.toLowerCase())
      .includes(style.toLowerCase())
  ) {
    style = style.toUpperCase();
  }

  return style;
};

export function hasUserInput(editorState: EditorState): boolean {
  const contentState = editorState.getCurrentContent();
  return contentState.hasText() || contentState.getBlockMap().first().getType() !== STYLES.BLOCK.UNSTYLED.value;
}

export const getEntitiesOfType = <T extends unknown>(type: string, contentState: ContentState): T[] => {
  const entities: T[] = [];
  contentState.getBlockMap().forEach((block) => {
    if (block) {
      block.findEntityRanges((character) => {
        const charEntity = character.getEntity();
        if (charEntity) {
          const contentEntity = contentState.getEntity(charEntity);
          if (contentEntity.getType() === type) {
            entities.push(contentEntity.getData());
          }
        }
        return false;
      }, noop);
    }
  });
  return entities;
};
