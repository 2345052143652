import { createSelector } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectHasPreferredTeam, selectIsProfileActiveIn } from 'reduxStore';

/**
 * Returns orgID from the URL only if org has finished loading properly,
 * otherwise it's undefined.
 */
export const useLoadedOrgID = (): Data.Id.OrganizationId | undefined => {
  const { orgID } = useParams<{ orgID?: Data.Id.OrganizationId }>();
  return useSelector(
    createSelector(
      [selectIsProfileActiveIn(orgID), selectHasPreferredTeam(orgID)],
      (isProfileActive, hasPreferredTeamId) => (isProfileActive && hasPreferredTeamId ? orgID : undefined),
    ),
  );
};
