/* eslint-disable sonarjs/cognitive-complexity */
import { Data } from '@taraai/types';
import { useEditor } from 'components/core/controllers/Editor';
import { PositionedPortal } from 'components/core/controllers/PositionedPortal/PositionedPortal';
import { useUserTagForId } from 'components/editor/plugins/mention/useUserTagForId';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { css } from 'emotion';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getUsers } from 'reduxStore';
import { useAfterUnMountEffect } from 'tools';

import { UserDropdown } from './UserDropdown';

export interface DraftMentionInserterLegacyProps {
  blockKey?: string;
  children?: React.ReactNode;
  end?: number;
  start?: number;
  decoratedText?: string;
}

/**
 * DraftMentionInserterLegacy
 * Draft Mention Inserter for parsing mentions from markdown and letting the
 * user insert mentions.
 *
 */
export default function DraftMentionInserterLegacy({
  blockKey,
  children,
  decoratedText,
  end,
  start,
}: DraftMentionInserterLegacyProps): JSX.Element {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const contentText = decoratedText?.trim();
  const { editorState, getEditorState, setReadOnlyMode, onChangeFn } = useEditor();
  const allUsers = useSelector(getUsers(orgID).selector);

  const allActiveUsers = allUsers?.filter(
    (user) => user.accessLevels?.[orgID] && user.accessLevels[orgID] !== 'deactivated',
  );

  /**
   * Gets user mention tag `"@Bob Ross"` for user using allUsers list.
   *
   * __NOTE__: allUsers list will always be populated here because of check
   * in `OrgAndTeamIsLoaded.tsx`.
   */
  const getUserMentionTag = useUserTagForId(orgID);
  const mentionSelection: SelectionState | undefined =
    blockKey !== undefined
      ? (SelectionState.createEmpty(blockKey).merge({
          anchorKey: blockKey,
          anchorOffset: (end ?? 0) - (contentText?.length ?? 0),
          focusKey: blockKey,
          focusOffset: end,
          isBackward: false,
        }) as SelectionState)
      : undefined;
  const currentSelection = editorState?.getSelection();
  const insertMention = useCallback(
    (mentionText: string | undefined, userId: string, focusAfter = true) => {
      if (!mentionText) {
        return;
      }
      const currentEditorState: EditorState | undefined = getEditorState?.();
      if (currentEditorState !== undefined && mentionSelection !== undefined) {
        const currentStateContent = currentEditorState.getCurrentContent();
        const contentStateWithEntity = currentStateContent.createEntity('mention', 'IMMUTABLE', { id: userId });
        const cursorSelection = SelectionState.createEmpty(mentionSelection.getStartKey()).merge({
          anchorKey: mentionSelection.getStartKey(),
          anchorOffset: mentionSelection.getStartOffset() + mentionText.length,
          focusKey: mentionSelection.getStartKey(),
          focusOffset: mentionSelection.getStartOffset() + mentionText.length,
          isBackward: false,
        }) as SelectionState;
        const entityKeyToAdd = contentStateWithEntity.getLastCreatedEntityKey();
        const finalEditorState = EditorState.push(
          currentEditorState,
          Modifier.replaceText(contentStateWithEntity, mentionSelection, mentionText, undefined, entityKeyToAdd),
          'insert-characters',
        );
        if (onChangeFn && onChangeFn.current) {
          onChangeFn.current(
            focusAfter ? EditorState.forceSelection(finalEditorState, cursorSelection) : finalEditorState,
          );
        }
      }
    },
    [getEditorState, mentionSelection, onChangeFn],
  );
  const selectionStartKey = currentSelection?.getStartKey();
  const selectionEndKey = currentSelection?.getEndKey();
  const selectionStartOffset = currentSelection?.getStartOffset();
  const selectionEndOffset = currentSelection?.getEndOffset();
  const entityHasSelection =
    blockKey === selectionStartKey &&
    blockKey === selectionEndKey &&
    start !== undefined &&
    selectionStartOffset !== undefined &&
    start <= selectionStartOffset &&
    end !== undefined &&
    selectionEndOffset !== undefined &&
    end >= selectionEndOffset;
  const exactTypeaheadInputRef = useRef<HTMLInputElement>(null);
  const matchedMention = decoratedText?.match(/(?:^|\s)@(\S*)/);
  const userSearch = matchedMention ? matchedMention[1] : '';
  useEffect(() => {
    if (entityHasSelection && exactTypeaheadInputRef.current) {
      exactTypeaheadInputRef.current.focus();
      setReadOnlyMode(entityHasSelection);
    }
  }, [setReadOnlyMode, entityHasSelection]);
  useAfterUnMountEffect((): void => setReadOnlyMode(false));

  return (
    <span
      className={css`
        position: relative;
      `}
    >
      {children}
      {entityHasSelection && (
        <div tabIndex={-1}>
          <PositionedPortal elementID='pp-dropdown'>
            <UserDropdown
              onUserSelect={(user): void => {
                const userTag = getUserMentionTag(user.id);
                insertMention(userTag, user.id);
              }}
              searchQuery={userSearch}
              users={allActiveUsers ?? []}
            />
          </PositionedPortal>
        </div>
      )}
    </span>
  );
}
