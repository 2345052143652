"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokens = void 0;
exports.tokens = {
    backgrounds: {
        $primaryGradient: 'linear-gradient(135.19deg, #58a6e7, #775dfa)',
    },
    borderWidths: {
        $1px: '0.0625rem',
        $2px: '0.125rem',
    },
    colors: {
        $background: '#f4f4fa',
        $beige: '#fef8e6',
        $black65: '#000000a6',
        $dark: '#303f4b',
        $failure: '#e86262',
        $focus: '#1d98ff',
        $grey1: '#fbfbfd',
        $grey2: '#eef1f7',
        $grey3: '#eaeef5',
        $grey4: '#dee3ec',
        $grey5: '#c8d0df',
        $grey6: '#949caf',
        $grey7: '#67728b',
        $indigo: '#4d5bff',
        $primaryBlue: '#4550ce',
        $success: '#389e0d',
        $successLight: '#ddf4d9',
        $todo: '#f19135',
        $white: '#ffffff',
        // Colors used only in stories, not for use in the app
        $debug: '#ff69b4',
        $debugLight: '#ffd0e7',
    },
    fonts: {
        $basic: 'Sofia',
        $code: 'SourceCodePro',
    },
    fontSizes: {
        $10px: '0.625rem',
        $12px: '0.75rem',
        $14px: '0.875rem',
        $16px: '1rem',
        $18px: '1.125rem',
        $24px: '1.5rem',
    },
    fontWeights: {
        // Basic
        $regular: '400',
        $medium: '600',
        // Code
        $codeMedium: '500',
    },
    lineHeights: {
        $10px: '135%',
        $12px: '135%',
        $14px: '1.375rem',
        $16px: '1.5rem',
        $18px: '1.75rem',
        $24px: '2rem',
    },
    radii: {
        $2px: '0.125rem',
        $4px: '0.25rem',
        $8px: '0.5rem',
        $12px: '0.75rem',
        // A big border radius is just what we want, 50% would result in an ellipse
        $circle: '10000px',
    },
    shadows: {
        $dividerBottom: '0 0.0625rem 0 0 #eaeef5',
        $dividerRight: '0.0625rem 0 0 0 #eaeef5',
        $dividerLeft: '-0.0625rem 0 0 0 #eaeef5',
        $overlay: '0 0 0 200vmax #303f4b50',
        $soft: '0 0 1.875rem 0 #67728b30',
        $navbar: '0 0.0625rem 0 0 #eaeef5, 0 0.125rem 0.5rem 0 rgba(87, 95, 101, 0.06)',
        $popup: '0 0 0.625rem 0 #00000020',
        $globalNavPopup: '0 0 0.625rem 0 #00000020',
        $ganttChart: '0 0 1.25rem 0 rgba(0, 0, 0, 0.08)',
    },
    sizes: {
        $avatar: '1rem',
        $backlogWidth: '17.5rem',
        $checkbox: '0.625rem',
        $controlHeight: '2.5rem',
        $divider: '0.0625rem',
        $effortInput: '1rem',
        $fitContent: 'fit-content',
        $form: '31.25rem',
        $full: '100%',
        $fullScreenHeight: '100vh',
        $minPopupWidth: '15.625rem',
        $navLogo: '2.5rem',
        $sprintsWidth: '18.5rem',
        $switchHeight: '0.75rem',
        $switchWidth: '1.25rem',
    },
    space: {
        $none: '0rem',
        $1px: '0.0625rem',
        $2px: '0.125rem',
        $4px: '0.25rem',
        $8px: '0.5rem',
        $12px: '0.75rem',
        $16px: '1rem',
        $20px: '1.25rem',
        $24px: '1.5rem',
    },
};
