import { unwrapResult } from '@reduxjs/toolkit';
import ActiveUsersLayout from 'components/app/controllers/views/ActiveUsersLayout';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { usePathParams } from 'components/Router/paths';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { isProfileAdmin, listOrganizationUsers, reduxStore, selectActiveUsers, selectAuth } from 'reduxStore';
import { listOrgUserEmails, selectActiveUsersWithEmails } from 'reduxStore/usersWithEmails';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

/**
 * ActiveUsersController loads data for list of organization users:
 *  - fetch all users of current organization from Firestore
 *  - fetch extra information about access levels via `listOrganizationUsers` cloud function
 */
export default function ActiveUsersController(): JSX.Element {
  const { orgID } = usePathParams('workspace');
  const { uid: profileId } = useSelector(selectAuth);
  const { addToast } = useToast();

  const isAdmin = useSelector(isProfileAdmin(orgID));
  const selectUsers = isAdmin ? selectActiveUsersWithEmails(orgID) : selectActiveUsers(orgID);

  // show only active users, or all users if there's no access level info available
  const users = useSelector(selectUsers);

  useEffect(() => {
    if (isAdmin) {
      reduxStore.dispatch(listOrgUserEmails());
    }
    async function dispatchListOrganizationUsers(): Promise<void> {
      try {
        await reduxStore.dispatch(listOrganizationUsers()).then(unwrapResult);
      } catch {
        addToast({
          message: strings.users.activeUsers.toasts.error,
          timeoutMs: 3500,
          type: 'error',
        });
      }
    }
    dispatchListOrganizationUsers();
  }, [addToast, orgID, profileId, isAdmin]);

  if (!isLoaded(users)) {
    return <StandardSpinner fillSpace size='medium' />;
  }

  return <ActiveUsersLayout isAdmin={isAdmin} orgID={orgID} users={users} />;
}
