import { styled } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { matchRoute } from 'components/Router/paths';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { selectAllTeams, selectAuth, selectCurrentTeam, selectPreferredTeam, selectUserTeams } from 'reduxStore';
import { strings } from 'resources';
import { useClickOutside } from 'tools';

import { TeamSwitcherPopup } from './Popup';
import { TeamSwitcherButton } from './SwitcherButton';
import { TeamsSection } from './types';

function teamPreview(team: UI.UITeam): Pick<UI.UITeam, 'id' | 'name'> {
  return {
    id: team.id,
    name: team.name,
  };
}

/**
 * TeamSwitcher allows to switch current team
 */
export function TeamSwitcher(): JSX.Element | null {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();

  const preferredTeam = useSelector(compose((team) => team && teamPreview(team), selectPreferredTeam(orgID)));
  const currentTeam = useSelector(compose((team) => team && teamPreview(team), selectCurrentTeam(orgID, teamID)));
  const { uid: profileId } = useSelector(selectAuth);
  const userTeams = useSelector(
    compose((teams) => teams?.map((team) => teamPreview(team)), selectUserTeams(orgID)),
    deepEquals,
  );
  const allTeams = useSelector(
    compose((teams) => teams?.map((team) => teamPreview(team)), selectAllTeams(orgID)),
    deepEquals,
  );

  const selectedTeam = currentTeam ?? userTeams[0];
  const popupRef = useRef<HTMLDivElement>(null);
  const isDisabledRoute = matchRoute(['requirements', 'requirement']);
  const sections: TeamsSection[] = [{ id: '1', label: strings.teamSwitcher.selectTeam, options: allTeams }];

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);

  const history = useHistory();

  useEffect(() => closePopup(), [history.location.pathname, closePopup]);

  useClickOutside(popupRef, closePopup);

  if (!isLoaded(preferredTeam)) return null;

  return (
    <Wrapper>
      <TeamSwitcherButton interactive={!showPopup} onClick={openPopup} selectedTeamName={selectedTeam.name}>
        {showPopup && (
          <TeamSwitcherPopup
            containerRef={popupRef}
            disableOptions={isDisabledRoute}
            preferredTeamID={preferredTeam.id}
            profileID={profileId}
            sections={sections}
            selectedTeamID={selectedTeam.id}
          />
        )}
      </TeamSwitcherButton>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  position: 'relative',
});
