"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_data_json_1 = require("ts.data.json");
exports.default = new ts_data_json_1.JsonDecoder.Decoder(function (value) {
    if (Buffer.isBuffer(value)) {
        return ts_data_json_1.ok(value);
    }
    return ts_data_json_1.err(errorMessage(value));
});
function errorMessage(value) {
    return "Failed to decode " + value + " as a Buffer";
}
