import { makeStyles, Theme, Tooltip } from '@material-ui/core';
import Tara, { Data } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Asana, Github, Slack, Trello } from 'resources/assets';
import { taskModalTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export interface TaskModalHeaderProps extends React.HTMLProps<HTMLDivElement> {
  taskSlug?: string;
  deleteCurrentTask: () => void;
  copyTaskID: () => void;
  requirementTitle?: string;
  currentOrg: Data.Id.OrganizationId;
  requirementID?: string;
  taskService?: Tara.Service;
  taskCopiedText: boolean;
  closeModal?: () => void;
  setTaskCopiedText: (value: boolean) => void;
}

/**
 * TaskModalHeader
 * view for header in task modal
 *
 */
export default function TaskModalHeader({
  className,
  taskSlug,
  deleteCurrentTask,
  copyTaskID,
  requirementTitle,
  currentOrg,
  requirementID,
  taskService,
  taskCopiedText,
  setTaskCopiedText,
  closeModal,
  ...props
}: TaskModalHeaderProps): JSX.Element {
  const { teamID } = useParams<{
    teamID: Data.Id.TeamId;
  }>();
  const history = useHistory();

  const useStylesTooltip = makeStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.65)',
      padding: '9px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.65)',
    },
  }));

  const tooltipClasses = useStylesTooltip();

  const getIconSrcForTaskService = (service: Tara.Service): string | null => {
    switch (service) {
      case 'trello':
        return Trello;
      case 'github':
        return Github;
      case 'asana':
        return Asana;
      case 'slack':
        return Slack;
      default:
        return null;
    }
  };

  const taskServiceSrc = taskService && getIconSrcForTaskService(taskService);

  const taskIDAndRequirement = (
    <div
      className={css`
        font-size: 0.875rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-top: 1px;
      `}
    >
      <span
        className={css`
          color: #949caf;
          text-transform: uppercase;
          margin-right: 0.25em;
        `}
        data-cy={taskModalTestIDs.TASK_ID}
      >
        {taskSlug && strings.formatString(strings.task.taskSlug, { slug: taskSlug })}
      </span>
      {requirementTitle && requirementID && (
        <span
          className={css`
            color: #949caf;
            margin-right: 0.25em;
          `}
        >
          {strings.task.in}
        </span>
      )}
      {requirementTitle && requirementID && (
        <a
          className={css`
            color: #1d98ff;
          `}
          data-cy={taskModalTestIDs.REQUIREMENT}
          href={['/', currentOrg].join('')}
          onClick={(event: React.SyntheticEvent): void => {
            event.preventDefault();
            closeModal?.();
            history.push(
              linkTo('requirement', {
                orgID: currentOrg,
                teamID,
                requirementID,
              }),
            );
          }}
        >
          {requirementTitle}
        </a>
      )}
    </div>
  );

  return (
    <div
      {...props}
      className={css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div
        className={css`
          display: flex;
        `}
      >
        {taskServiceSrc && taskService && (
          <Tooltip
            arrow
            classes={tooltipClasses}
            enterDelay={0}
            placement='left'
            title={
              <div
                className={css`
                  color: #ffffff;
                  font-size: 14px;
                  font-weight: normal;
                `}
              >
                {strings.task.importedFrom}
                <span
                  className={css`
                    text-transform: capitalize;
                  `}
                >
                  {taskService}
                </span>
              </div>
            }
            TransitionProps={{ timeout: 600 }}
          >
            <img
              alt=''
              className={css`
                width: 16px;
                height: 16px;
                margin-right: 8px;
              `}
              src={taskServiceSrc}
            />
          </Tooltip>
        )}
        {taskIDAndRequirement}
      </div>
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        <div onMouseEnter={(): void => setTaskCopiedText(false)}>
          <Tooltip
            arrow
            enterDelay={0}
            interactive
            title={
              <div
                className={css`
                  color: #ffffff;
                  font-size: 0.875rem;
                  font-weight: normal;
                  padding: 0.1875rem;
                  line-height: 1.125rem;
                  max-width: 15.625rem;
                `}
              >
                {taskCopiedText ? strings.task.copied : strings.task.copyTask}
              </div>
            }
            TransitionProps={{ timeout: 600 }}
          >
            <div
              className={css`
                display: flex;
              `}
              data-cy={taskModalTestIDs.COPY_URL}
            >
              <Icon
                className={css`
                  background-color: #eaeef5;
                  padding: 6px;
                  height: 12px;
                  width: 12px;
                  border-radius: 2px;
                  :hover {
                    cursor: pointer;
                    opacity: 0.6;
                  }
                `}
                data-cy={taskModalTestIDs.COPY_URL}
                name='copy'
                onClick={copyTaskID}
              />
            </div>
          </Tooltip>
        </div>
        <div
          className={css`
            padding-top: 0.1875rem;
            position: relative;
            fill: #949caf;
            width: 1.5625rem;
            margin-left: 14px;
          `}
          data-cy={taskModalTestIDs.THREE_DOT_MENU}
        >
          <Menu
            options={[
              {
                title: strings.tasks.delete,
                onSelect: deleteCurrentTask,
                dataCy: taskModalTestIDs.DELETE_TASK,
              },
            ]}
          >
            <Icon
              className={css`
                fill: #949caf;
                width: 0.875rem;
                padding: 0rem;
                padding-top: 0.125rem;
                cursor: pointer;
              `}
              data-cy={taskModalTestIDs.THREE_DOT_MENU}
              name='meatballs'
            />
          </Menu>
        </div>
      </div>
    </div>
  );
}
