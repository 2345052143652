import { noop } from '@taraai/utility';
import { css } from 'emotion';
import React, { useRef } from 'react';
import AutosizeInput from 'react-input-autosize';
import { sendInvitePopupTestIDs } from 'resources/cypress/testAttributesValues';
import { atomic } from 'resources/theme/atomic';
import { Validator } from 'tools/helpers/validators/inviteUserValidators';

import { TagList } from './TagList';

export type TagInputProps = {
  tags?: string[];
  innerInputValue?: string;
  onTagsChange?: (newTags: string[]) => void;
  onInnerInputChange?: (newValue: string) => void;
  validators?: Validator[];
  placeholder?: string;
  errorsPresent?: boolean;
  /** Transform function to apply on each tag from input before it's processed. */
  transform?: (tag: string) => string;
};

const TagInput: React.FC<TagInputProps> = ({
  tags = [],
  innerInputValue = '',
  onTagsChange = noop,
  onInnerInputChange = noop,
  validators = [],
  placeholder = '',
  errorsPresent = false,
  transform,
}: TagInputProps) => {
  const innerInputRef = useRef<AutosizeInput & HTMLInputElement>(null);

  const addTag = (content: string): void => {
    if (content === '') return; // don't accept empty tags
    // split by ',', transform, and filter out duplicates
    const subTags = content
      .split(',')
      .map((tag) => (transform ? transform(tag) : tag))
      .filter((tag) => !tags.includes(tag));
    onTagsChange([...tags, ...subTags]);
    onInnerInputChange('');
  };

  const removeTag = (content: string): void => {
    const filteredTags = tags.filter((tag) => tag !== content);
    onTagsChange(filteredTags);
  };

  const removeLastTag = (): void => {
    if (tags.length === 0) {
      return;
    }
    const lastTag = tags[tags.length - 1];
    removeTag(lastTag);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    switch (event.key) {
      case 'Enter':
      case ',':
      case 'Tab':
      case ' ':
      case 'Spacebar':
        addTag(innerInputValue);
        event.preventDefault();
        break;
      case 'Backspace':
        if (innerInputValue.length === 0) removeLastTag();
        break;
      default:
        break;
    }
  };

  const borderColor = errorsPresent ? atomic.get(atomic.colors.error1) : atomic.theme.color.input.border.default;

  return (
    // It is safe to disable these rules, since outer div is used only
    // to capture click event to focus on already focus-able input field
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <div
      className={css`
        padding: 0.5rem;
        border-radius: 3px;
        cursor: text;
        border: solid 1px ${atomic.get(atomic.colors.grey4)};
        ${atomic.border({
          style: 'solid',
          width: atomic.theme.space.input.border.width.regular,
          color: borderColor,
          radius: atomic.theme.space.input.border.radius.regular,
        })}
        &:focus-within {
          ${atomic.border({
            style: 'solid',
            width: atomic.theme.space.input.border.width.regular,
            color: atomic.theme.color.input.border.focus,
            radius: atomic.theme.space.input.border.radius.regular,
          })}
          ${atomic.transition()}
        }
      `}
      onClick={(): void => innerInputRef?.current?.focus()}
    >
      <div
        className={css`
          margin-bottom: -0.5rem;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        `}
      >
        <TagList onRemove={removeTag} tags={tags} validators={validators} />
        <AutosizeInput
          ref={innerInputRef}
          className={css`
            input {
              border: none;
              outline: none;
              font-size: 14px;
              height: 1.375rem;

              &::placeholder {
                font-size: 0.75rem;
              }
            }
            margin-bottom: 0.5rem;
          `}
          data-cy={sendInvitePopupTestIDs.EMAIL_ADDRESSES}
          onChange={(event): void => {
            onInnerInputChange(event.target.value);
          }}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          style={{ fontSize: 14 }}
          value={innerInputValue}
        />
      </div>
    </div>
  );
};

export default TagInput;
