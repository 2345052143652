import { unwrapResult } from '@reduxjs/toolkit';
import { Color, ColorPicker, useColorPicker } from 'components/core/controllers/views/ColorPicker';
import { usePathParams } from 'components/Router/paths';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reduxStore, selectOrganization, updateOrganization } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { formatColor, stringToColor } from 'tools/libraries/helpers/colors';

/**
 * WorkspaceColorPickerController
 * - responsible for showing and changing Workspace color
 *
 */
export default function WorkspaceColorPickerController(): JSX.Element {
  const { addToast } = useToast();
  const { orgID } = usePathParams('workspace');
  const { name, color } = useSelector(selectOrganization(orgID));

  const updateOrgColor = useCallback(
    (newColor: Color) =>
      reduxStore
        .dispatch(updateOrganization({ id: orgID, color: newColor }))
        .then(unwrapResult)
        .then(() =>
          addToast({
            type: 'success',
            timeoutMs: 2500,
            message: strings.workspace.colorPicker.success,
          }),
        )
        .catch((error) => {
          addToast({
            type: 'error',
            timeoutMs: 2500,
            message: strings.formatString(strings.workspace.colorPicker.error, {
              errorMessage: error.message,
            }) as string,
          });
        }),
    [addToast, orgID],
  );

  // generate color from name if organization doesn't have one
  const colorHex: string = color ?? formatColor(stringToColor(name), 'hex');
  const pickerProps = useColorPicker({
    initialColor: colorHex,
    onChange: updateOrgColor,
  });
  return <ColorPicker label={strings.workspace.colorPicker.label} {...pickerProps} />;
}
