import { Data } from '@taraai/types';
import { NotificationBar } from 'components/core/controllers/views/NotificationBar';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getSprint, getSprintCompletingNotification } from 'reduxStore/sprints';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export function SprintCompletedNotificationBarController(): JSX.Element | null {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const notification = useSelector(getSprintCompletingNotification);
  const sprintSlice = getSprint(orgID, notification.status === 'open' ? notification.sprintID : '');
  const sprintName = useSelector(sprintSlice.selector)?.sprintName || '';

  if (notification.status !== 'open') {
    return null;
  }

  return (
    <NotificationBar bgColor={atomic.get(atomic.colors.grey3)} fgColor={atomic.get(atomic.colors.grey7)} isLoading>
      <div
        className={css`
          font-weight: 500;
        `}
      >
        {strings.formatString(strings.sprints.complete.notificationLabel, {
          sprintName,
        })}
      </div>
    </NotificationBar>
  );
}
