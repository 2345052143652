import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { atomic } from 'resources';

type Props = {
  className?: string;
  outsideWindowContentChildren?: React.ReactNode;
};

export const Window: React.FC<Props> = ({ children, className, outsideWindowContentChildren }) => (
  <Wrapper className={className}>
    <ScaleWrapper>
      <Bar>
        <BarNavigation />
      </Bar>
      <Content>{children}</Content>
      {outsideWindowContentChildren}
    </ScaleWrapper>
  </Wrapper>
);

const ScaleWrapper = styled.div`
  height: 100%;
  width: 200%;
  transform: scale(0.5) translateX(-50%) translateY(-50%);

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    overflow: hidden;
    width: 100%;
    transform: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 55.25rem;
  max-height: 45.5rem;
  border-radius: 0.5rem 0 0 0;
  box-shadow: 0 0 1.875rem 0 rgba(103, 114, 139, 0.2);
  background-color: #ffffff;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    height: 100%;
    overflow: visible;
  }
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  height: 200%;

  @media (min-width: ${atomic.responsive.breakpoints[0].small}px) {
    height: 100%;
  }
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  height: 2.375rem;
  width: 100%;
  background-color: #f4f4f6;
  border-radius: 0.5rem 0 0 0;
`;

const dot = css`
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #c8d0df;
  border-radius: 50%;
`;

const BarNavigation = styled.div`
  ${dot};
  position: relative;

  &::before {
    ${dot};
    content: '';
    left: -1rem;
  }

  &::after {
    ${dot};
    content: '';
    right: -1rem;
  }
`;
