import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';

export const getImports = (
  orgId: Data.Id.OrganizationId,
  service: Data.ImportableService,
  author?: Data.Id.UserId,
): Query<UI.UIImport> => {
  if (!isNonEmptyString(orgId) || !author) {
    return inertQuery();
  }

  const importsQuery: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgId}/imports`,
    where: [
      ['author', '==', author],
      ['service', '==', service],
    ],
    storeAs: `org-${orgId}-service-${service}-imports`,
  };

  return {
    query: [importsQuery],
    selector: createStandardSelector(importsQuery),
  };
};

export const getOrgGithubProducts = (orgId: Data.Id.OrganizationId): Query<UI.UIProduct> => {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }

  const orgGithubProductsQuery: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgId}/products`,
    where: [
      ['externalService.service', '==', 'github'],
      ['archived', '==', false],
      ['deleted', '==', false],
    ],
    storeAs: `all-${orgId}-github-products`,
  };

  return {
    query: [orgGithubProductsQuery],
    selector: createStandardSelector(orgGithubProductsQuery),
  };
};
