import { Data } from '@taraai/types';
import UserAccountSetupProgress from 'components/app/controllers/views/UserAccountSetupProgress';
import { linkTo } from 'components/Router/paths';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { selectAuth, useJoinOrganization } from 'reduxStore';
import { strings } from 'resources/i18n';
import { getInvitationTokenFromCookie, removeInvitationTokenCookie } from 'tools/utils/invitationToken';

import JoinWorkspaceController from './JoinWorkspaceController';

/**
 * Handles automatic workspace joining when invitationToken cookie is set,
 * if not display JoinWorkspaceController for manual joining
 */
export default function AutoJoinWorkspaceController(): JSX.Element {
  const [{ error, data }, joinOrganization] = useJoinOrganization();
  const tokenFromCookie = getInvitationTokenFromCookie();
  const auth = useSelector(selectAuth);
  const history = useHistory();
  const userIsLogged = !auth.isEmpty;
  const { teamID } = useParams<{
    teamID: Data.Id.TeamId;
  }>();

  const shouldJoinWorkspaceAutomatically = userIsLogged && tokenFromCookie;

  useEffect(() => {
    if (shouldJoinWorkspaceAutomatically) {
      // tokenFromCookie is always here (shouldJoinWorkspaceAutomatically)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      joinOrganization(tokenFromCookie!);
    }
  }, [shouldJoinWorkspaceAutomatically, joinOrganization, tokenFromCookie]);

  // if we should not join automatically, or there was an error
  // fallback on manual joining
  if (!shouldJoinWorkspaceAutomatically || error) {
    if (tokenFromCookie) {
      // make sure that token is stored in URL
      history.replace(linkTo('invite', { token: tokenFromCookie }));
      // if there was a token from cookie, remove it before proceeding
      removeInvitationTokenCookie();
    }
    // display manual joining
    return <JoinWorkspaceController />;
  }

  if (data) {
    // auto joining succeed and user can be redirected to joined workspace home page
    removeInvitationTokenCookie();
    const link = {
      pathname: linkTo('home', { orgID: data.organizationId, teamID }),
    };
    return <Redirect to={link} />;
  }

  const message = auth.emailVerified
    ? strings.joinWorkspace.progress.joiningWorkspace
    : strings.joinWorkspace.progress.userAccountSetup;

  return <UserAccountSetupProgress message={message} />;
}
