import { UI } from '@taraai/types';
import GithubInfoPopup from 'components/app/controllers/views/GithubInfoPopup';
import NoGitData from 'components/app/controllers/views/NoGitData';
import Icon from 'components/core/controllers/views/Icon';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React, { useState } from 'react';
import { isLoaded } from 'react-redux-firebase';
import { TaskGitData } from 'reduxStore/git-task-lifecycle/queries/task-git-data';
import { strings } from 'resources';
import { formatFromNow, formatIdWithRepositoryName, useInstallGitHub } from 'tools';
import { getCommitKey } from 'tools/libraries/helpers/commit';

type TaskFragment = Pick<UI.UITask, 'slug'>;

type GitDataRow = {
  label: string;
  jsx: JSX.Element;
};

type GitDataRows = {
  branches: UI.UIBranchSummary[];
  pullRequests: UI.UIPullRequestSummary[];
  commits: UI.UICommitSummary[];
  isGithubInstalled: boolean;
};

type GitTaskDataRowProps = {
  name: string;
  values?: string[] | GitDataRow[];
  emptyValue?: string;
};

function GitTaskDataRow({ name, values, emptyValue }: GitTaskDataRowProps): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        padding-top: 1.875rem;
      `}
    >
      <div
        className={css`
          text-transform: uppercase;
          color: #949caf;
          font-size: 0.75rem;
          font-weight: 600;
          width: 7rem;
        `}
      >
        {name}
      </div>
      <div
        className={css`
          width: calc(100% - 7rem);
        `}
      >
        {values ? (
          Object.values(values).map((value) => (
            <div
              key={value.label}
              className={css`
                &:not(:last-child) {
                  margin-bottom: 0.5rem;
                }
              `}
            >
              {value.jsx}
            </div>
          ))
        ) : (
          <div
            className={css`
              color: #949caf;
              font-size: 0.875rem;
              font-weight: normal;
            `}
          >
            {emptyValue}
          </div>
        )}
      </div>
    </div>
  );
}

function Spinner(): JSX.Element {
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3.75rem;
      `}
    >
      <FastSmallSpinner />
    </div>
  );
}

function GitTaskDataRows({ branches, pullRequests, commits, isGithubInstalled }: GitDataRows): JSX.Element {
  return (
    <>
      {branches && branches.length > 0 ? (
        <GitTaskDataRow name={strings.gitTaskLifecycle.branches} values={branches.map(getBranchElements)} />
      ) : (
        <GitTaskDataRow emptyValue={strings.gitTaskLifecycle.noBranches} name={strings.gitTaskLifecycle.branches} />
      )}
      {pullRequests && pullRequests.length > 0 ? (
        <GitTaskDataRow name={strings.gitTaskLifecycle.pullRequests} values={pullRequests.map(getPrElements)} />
      ) : (
        <GitTaskDataRow
          emptyValue={strings.gitTaskLifecycle.noPullRequests}
          name={strings.gitTaskLifecycle.pullRequests}
        />
      )}
      {commits && commits.length > 0 ? (
        <GitTaskDataRow name={strings.gitTaskLifecycle.lastCommit} values={commits.map(getCommitElements)} />
      ) : (
        <GitTaskDataRow emptyValue={strings.gitTaskLifecycle.noCommits} name={strings.gitTaskLifecycle.lastCommit} />
      )}
    </>
  );
}

const getCommitElements = (commit: UI.UICommitSummary): GitDataRow => ({
  label: commit.message,
  jsx: (
    <div>
      <div
        className={css`
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: normal;
        `}
      >
        <a
          className={css`
            padding-right: 0.1875rem;
            color: #1d98ff;
            cursor: pointer;
          `}
          href={commit.url}
          rel='noopener noreferrer'
          target='_blank'
        >
          {getCommitKey(commit)}
        </a>
        <div
          className={css`
            color: #303f4b;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 26.5rem;
          `}
        >
          {commit.message}
        </div>
      </div>
      <span
        className={css`
          color: #949caf;
          font-size: 0.75rem;
          font-weight: normal;
          font-style: italic;
        `}
      >
        {strings.formatString(strings.gitTaskLifecycle.updatedCommit, {
          date: formatFromNow(commit.createdAt),
        })}
      </span>
    </div>
  ),
});

const getPrElements = ({ data: prSummary }: UI.UIPullRequestSummary): GitDataRow => ({
  label: prSummary.title,
  jsx: (
    <div
      className={css`
        display: flex;
        align-items: center;
      `}
    >
      <span
        className={css`
          background-color: ${prSummary.state === 'open' ? '#eef1f7' : '#ddf4d9'};
          border-radius: 0.1875rem;
          color: ${prSummary.state === 'open' ? '#949caf' : '#389e0d'};
          font-size: 0.6875rem;
          font-weight: 600;
          padding: 0.3125rem;
          margin-right: 0.5rem;
          text-transform: uppercase;
        `}
      >
        {prSummary.state}
      </span>
      <div
        className={css`
          color: #67728b;
          font-size: 0.875rem;
          font-weight: normal;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 26.5rem;
        `}
      >
        <span
          className={css`
            padding-right: 5px;
          `}
        >
          [
          <a
            className={css`
              color: #1d98ff;
              cursor: pointer;
            `}
            href={prSummary.url}
            rel='noopener noreferrer'
            target='_blank'
          >
            {formatIdWithRepositoryName(prSummary)}
          </a>
          ]
        </span>
        {prSummary.title}
      </div>
    </div>
  ),
});

const getBranchElements = (branch: UI.UIBranchSummary): GitDataRow => ({
  label: branch.name,
  jsx: (
    <div
      className={css`
        width: 29.5rem;
        display: flex;
      `}
    >
      <div
        className={css`
          color: #575f65;
          font-size: 0.875rem;
          font-weight: normal;
          border-radius: 0.1875rem;
          background-color: #eef1f7;
          padding: 0.125rem 0.25rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        {branch.name}
      </div>
    </div>
  ),
});

type GitTaskDataProps = {
  data: TaskGitData | undefined;
  task: TaskFragment;
};

export function GitTaskData({ data, task }: GitTaskDataProps): JSX.Element {
  const [showGithubInfo, setShowGithubInfo] = useState(false);
  const gitHubInstallation = useInstallGitHub();

  if (!isLoaded(data)) {
    return <Spinner />;
  }

  const { branches, pullRequests, commits } = data;

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        padding: 1.5625rem;
        border-top: solid 0.0625rem #dee3ec;
        position: relative;
      `}
    >
      {showGithubInfo && (
        <div
          className={css`
            position: absolute;
            left: 2rem;
            top: 3rem;
          `}
        >
          <GithubInfoPopup
            isGithubInstalled={gitHubInstallation.id !== undefined}
            onClose={(): void => setShowGithubInfo(false)}
            taskSlug={task?.slug}
          />
        </div>
      )}
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        <span
          className={css`
            color: #67728b;
            font-size: 0.75rem;
            font-weight: 600;
            text-transform: uppercase;
          `}
        >
          {strings.gitTaskLifecycle.git}
        </span>
        <Icon
          className={css`
            padding: 0rem;
            padding-left: 0.5rem;
            padding-top: 0.125rem;
            cursor: pointer;
            :hover {
              opacity: 0.7;
            }
          `}
          name='infoCircle'
          onClick={(): void => setShowGithubInfo(true)}
        />
      </div>
      {branches?.length === 0 && pullRequests?.length === 0 && commits?.length === 0 ? (
        <NoGitData isGithubInstalled={gitHubInstallation.id !== undefined} taskSlug={task?.slug} />
      ) : (
        <GitTaskDataRows
          branches={branches}
          commits={commits}
          isGithubInstalled={gitHubInstallation.id !== undefined}
          pullRequests={pullRequests}
        />
      )}
    </div>
  );
}
