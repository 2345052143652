import { Data, Functions } from '@taraai/types';
import { AsanaIcon } from 'components/app/controllers/AsanaImportBacklogIndicatorController';
import { GithubIcon } from 'components/app/controllers/GithubImportBacklogIndicatorController';
import TaskModalContext from 'components/app/controllers/TaskModalContext';
import { TrelloIcon } from 'components/app/controllers/TrelloImportBacklogIndicatorController';
import ImportedTasksView from 'components/app/controllers/views/ImportedTasksView';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { getImportedTasks } from 'reduxStore/imports/queries/imported-tasks';
import { strings } from 'resources/i18n';

interface Props {
  service: Functions.ImportCSVFile.CSVImportableService | Data.ExternalIssue.Any['service'];
  onBackClick: () => void;
  onMoveToSprint: (taskRef: string, sprintId: string) => void;
  onMoveToBacklog: (taskRef: string) => void;
}

export const ImportedTasksController = ({
  service,
  onBackClick,
  onMoveToSprint,
  onMoveToBacklog,
}: Props): JSX.Element | null => {
  const { openModal } = useContext(TaskModalContext);

  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();

  const importedTasksSlice = getImportedTasks(orgID, service);

  useFirestoreConnect(importedTasksSlice.query);
  const importedTasks = useSelector(importedTasksSlice.selector) || [];

  const taskCount = importedTasks.length;

  const toggleModal = useCallback(
    (task) => {
      openModal(task, importedTasksSlice.selector, service);
    },
    [importedTasksSlice.selector, openModal, service],
  );
  switch (service) {
    case 'github':
      return (
        <ImportedTasksView
          description={strings.formatString(strings.importIndicatorInBacklog.taskCount, { taskCount })}
          icon={<GithubIcon />}
          label={strings.importIndicatorInBacklog.importedFromGithub}
          onBackClick={onBackClick}
          onMoveToBacklog={onMoveToBacklog}
          onMoveToSprint={onMoveToSprint}
          tasks={importedTasks}
          toggleModal={toggleModal}
        />
      );
    case 'trello':
      return (
        <ImportedTasksView
          description={strings.formatString(strings.importIndicatorInBacklog.taskCount, { taskCount })}
          icon={<TrelloIcon />}
          label={strings.importIndicatorInBacklog.importedFromTrello}
          onBackClick={onBackClick}
          onMoveToBacklog={onMoveToBacklog}
          onMoveToSprint={onMoveToSprint}
          tasks={importedTasks}
          toggleModal={toggleModal}
        />
      );
    case 'asana':
      return (
        <ImportedTasksView
          description={strings.formatString(strings.importIndicatorInBacklog.taskCount, { taskCount })}
          icon={<AsanaIcon />}
          label={strings.importIndicatorInBacklog.importedFromAsana}
          onBackClick={onBackClick}
          onMoveToBacklog={onMoveToBacklog}
          onMoveToSprint={onMoveToSprint}
          tasks={importedTasks}
          toggleModal={toggleModal}
        />
      );
    default:
      return null;
  }
};
