import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export default function IntegrationsHeader(): JSX.Element {
  return (
    <p
      className={css`
        font-size: 0.875rem;
        color: ${atomic.get(atomic.colors.grey6)};
        text-transform: uppercase;
        padding-bottom: 1rem;
      `}
    >
      {strings.integrations.title}
    </p>
  );
}
