import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import Button from 'components/core/controllers/views/Button';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css, cx } from 'emotion';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { reduxStore } from 'reduxStore';
import { createSprint } from 'reduxStore/sprints/actions/create';
import { atomic } from 'resources';
import { sprintsTabTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';

/**
 * A short description for NewSprint.
 *
 */
export default function NewSprint({ className }: React.HTMLProps<HTMLDivElement>): JSX.Element {
  const { orgID } = useParams<{
    orgID: Data.Id.OrganizationId;
  }>();
  const [isCreatingSprint, setIsCreatingSprint] = useState(false);
  const { addToast } = useToast();

  const handleCreateSprint = useCallback(() => {
    setIsCreatingSprint(true);
    reduxStore
      .dispatch(createSprint())
      .then(unwrapResult)
      .then((success) => {
        segment.track('SprintCreated', {
          orgID,
          sprintID: success.id,
          location: 'SprintsPlanning',
        });
        const message = strings
          .formatString(strings.sprints.createSprintSuccess, {
            sprintName: success.sprintName,
          })
          .toString();
        setIsCreatingSprint(false);
        return addToast({
          message,
          timeoutMs: 5500,
          type: 'success',
        });
      })
      .catch((error: Error) => {
        const message = strings
          .formatString(strings.sprints.createSprintFailure, {
            errorMassage: error.message,
          })
          .toString();
        setIsCreatingSprint(false);
        addToast({
          message,
          type: 'error',
        });
      });
  }, [addToast, orgID]);

  return (
    <Button
      className={cx(
        css`
          padding: 0.5rem 1rem;
          margin: 0.5rem;
        `,
        className,
      )}
      color='primary'
      data-cy={sprintsTabTestIDs.CREATE_SPRINT_RIGHT_CORNER}
      disabled={isCreatingSprint}
      name='newSprintButton'
      onClick={handleCreateSprint}
      size='small'
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {isCreatingSprint && (
          <FastSmallSpinner
            color={atomic.get(atomic.colors.white)}
            spinnerStyles={css`
              margin-right: 0.5rem;
              padding: 0;
            `}
          />
        )}
        {isCreatingSprint ? (
          <span
            className={css`
              margin-bottom: -0.1rem;
            `}
          >
            {strings.sprints.creating}
          </span>
        ) : (
          strings.sprints.create
        )}
      </div>
    </Button>
  );
}
