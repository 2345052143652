"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokens = exports.styled = exports.mapTokens = exports.mapBreakpoints = exports.GlobalStyles = void 0;
var breakpoints_1 = require("./breakpoints");
var createStyled_1 = require("./createStyled");
var tokens_1 = require("./tokens");
exports.GlobalStyles = (_a = createStyled_1.createStyled({
    tokens: tokens_1.tokens,
    breakpoints: breakpoints_1.breakpoints,
}), _a.GlobalStyles), exports.mapBreakpoints = _a.mapBreakpoints, exports.mapTokens = _a.mapTokens, exports.styled = _a.styled, exports.tokens = _a.tokens;
