import { Data } from '@taraai/types';
import Button from 'components/core/controllers/views/Button';
import Modal from 'components/core/controllers/views/Modal';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { workspaceTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export interface LeaveWorkspaceModalProps {
  isLoading: boolean;
  onSubmit: () => void;
  organizationName: Data.Organization['name'];
}

export default function LeaveWorkspaceModal({
  isLoading,
  onSubmit,
  organizationName,
}: LeaveWorkspaceModalProps): JSX.Element {
  const history = useHistory();

  return (
    <Modal
      bodyStyle={css`
        min-width: 30rem;
        max-width: 30rem;
      `}
      footer={
        <div
          className={css`
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          `}
        >
          <Button
            className={css`
              margin-right: 0.8125rem;
            `}
            color='ghost'
            data-cy={workspaceTestIDs.LEAVE_MODAL_CANCEL_BUTTON}
            name='cancel'
            onClick={(): void => {
              history.go(-1);
            }}
          >
            {strings.workspace.leaveWorkspace.cancel}
          </Button>
          <Button
            color='red'
            data-cy={workspaceTestIDs.LEAVE_MODAL_CONFIRM_BUTTON}
            disabled={isLoading}
            name='leave'
            onClick={onSubmit}
          >
            {isLoading ? (
              <FastSmallSpinner
                color={atomic.get(atomic.colors.white)}
                spinnerStyles={css`
                  padding: 0;
                `}
              />
            ) : (
              strings.workspace.leaveWorkspace.yes
            )}
          </Button>
        </div>
      }
      header={
        <Text
          className={css`
            color: #303f4b;
            font-size: 1rem;
            font-weight: 500;
          `}
        >
          {strings.workspace.leaveWorkspace.title}
        </Text>
      }
    >
      <div
        className={css`
          width: 100%;
        `}
      >
        {strings
          .formatString(strings.workspace.leaveWorkspace.areYouSure, {
            organizationName,
          })
          .toString()}
      </div>
    </Modal>
  );
}
