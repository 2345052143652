import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';

export function replaceSelectionWithLink(editorState: EditorState, text: string, url: string): EditorState {
  const contentState = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();
  const blocksForCurrentSelectionExist =
    !!contentState.getBlockForKey(currentSelection.getStartKey()) &&
    !!contentState.getBlockForKey(currentSelection.getEndKey());
  const selectionToReplace = blocksForCurrentSelectionExist
    ? currentSelection
    : SelectionState.createEmpty(contentState.getFirstBlock().getKey());

  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const contentStateWithLink = Modifier.replaceText(
    contentStateWithEntity,
    selectionToReplace,
    text,
    undefined,
    entityKey,
  );

  return EditorState.push(editorState, contentStateWithLink, 'insert-characters');
}

export function getEntitySelection(editorState: EditorState, entityKey: string): SelectionState | null {
  const allBlocks = editorState.getCurrentContent().getBlockMap().valueSeq().toArray();

  // eslint-disable-next-line no-loops/no-loops
  for (const block of allBlocks) {
    let entitySelection = null;

    block.findEntityRanges(
      (charMeta) => charMeta.getEntity() === entityKey,
      (start, end) => {
        entitySelection = SelectionState.createEmpty(block.getKey()).merge({
          anchorOffset: start,
          focusOffset: end,
        }) as SelectionState;
      },
    );

    if (entitySelection) {
      return entitySelection;
    }
  }

  return null;
}

export function getLinkInfoUnderSelection(editorState: EditorState): { url?: string; text?: string } {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const blockKey = selectionState.getStartKey();
  const block = contentState.getBlockForKey(blockKey);

  let url;
  if (block) {
    block.findEntityRanges(
      (charMeta) => {
        const entityKey = charMeta.getEntity();
        if (entityKey) {
          const entity = contentState.getEntity(entityKey);
          const entityData = entity.getData();
          if (typeof entityData.url === 'string') {
            url = entityData.url;
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        if (
          selectionState.getEndKey() !== blockKey ||
          selectionState.getStartOffset() !== start ||
          selectionState.getEndOffset() !== end
        ) {
          url = undefined;
        }
      },
    );
  }

  const text = getTextUnderSelection(contentState, selectionState, '');

  return {
    text,
    url,
  };
}

// source https://github.com/facebook/draft-js/issues/442
function getTextUnderSelection(contentState: ContentState, selection: SelectionState, blockDelimiter = '\n'): string {
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const blocks = contentState.getBlockMap();

  let lastWasEnd = false;
  const selectedBlock = blocks
    .skipUntil((block) => block?.getKey() === startKey)
    .takeUntil((block) => {
      const result = lastWasEnd;

      if (block?.getKey() === endKey) {
        lastWasEnd = true;
      }

      return result;
    });

  return selectedBlock
    .map((block) => {
      if (!block) {
        return '';
      }

      const key = block.getKey();
      let text = block.getText();

      let start = 0;
      let end = text.length;

      if (key === startKey) {
        start = selection.getStartOffset();
      }
      if (key === endKey) {
        end = selection.getEndOffset();
      }

      text = text.slice(start, end);
      return text;
    })
    .join(blockDelimiter);
}
