import { createAsyncThunk } from '@reduxjs/toolkit';
import { Functions } from '@taraai/types';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export const connectToOrganization = createAsyncThunk(
  'ConnectToOrganization',
  async (changeset: Functions.ConnectToOrganization.Payload, { extra }) => {
    const { getFirebase } = extra as ExtraAPI;

    const payload = decode<Functions.ConnectToOrganization.ConnectToOrganizationPayload>(
      changeset,
      'ConnectToOrganizationPayload',
    );

    await getFirebase().functions().httpsCallable('connectToOrganization')(payload);
  },
);
