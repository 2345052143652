import { createSelector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { SprintColumnType, SprintColumnView } from 'components/app/controllers/views/SprintColumnView/SprintColumnView';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { computeTotalEffort, estimatedEffortSelector, getCompletedSprints, getSprintTasks } from 'reduxStore';
import { CustomSorts, sort } from 'tools/libraries/helpers/sort';

type SprintFragment = Pick<
  UI.UISprint,
  'id' | 'sprintName' | 'initialStartDate' | 'initialEndDate' | 'isComplete' | 'computedOnCompletion'
>;

type SprintColumnControllerProps = {
  sprint: SprintFragment;
  currentSprintId: string;
};

export default function SprintColumnController({
  sprint,
  currentSprintId,
}: SprintColumnControllerProps): JSX.Element | null {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();
  const [sortFilter] = useState<keyof CustomSorts | null>(null); // @TODO

  const sprintTasksSlice = getSprintTasks(orgID, sprint?.id);
  const completedSlice = getCompletedSprints(orgID, teamID);

  useFirestoreConnect([...sprintTasksSlice.query, ...completedSlice.query]);

  const allTasksSelector = createSelector(
    [(): keyof CustomSorts | null => sortFilter, sprintTasksSlice.selector],
    (filter, allTasks) => allTasks && sort(allTasks, filter ?? 'taskAssigneeNameStatus'),
  );

  const tasks: UI.UITask[] = useSelector(allTasksSelector) ?? [];

  const completedEffort = tasks
    ?.filter(({ status }) => status === 2)
    ?.reduce((tasksDone, task) => tasksDone + Number(task.effortLevel), 0);

  const estimatedEffort = useSelector(estimatedEffortSelector(completedSlice.selector, () => sprint as UI.UISprint));
  const totalEffort = computeTotalEffort(sprint, tasks);

  let sprintType: SprintColumnType = 'upcoming';
  if (sprint.isComplete) {
    sprintType = 'complete';
  } else if (sprint.id === currentSprintId) {
    sprintType = 'active';
  }

  return (
    <SprintColumnView
      assigneeFilter={undefined}
      completedEffort={completedEffort}
      overloadPoints={totalEffort - estimatedEffort}
      sprint={sprint}
      statusFilter={undefined}
      tasks={tasks}
      totalEffort={totalEffort}
      type={sprintType}
    />
  );
}
