/**
 * Promise based setTimeout() wrapper so you can `await wait()`
 */
export const wait = (milliseconds: number): Promise<void> =>
  new Promise((resolve) => window.setTimeout(resolve, milliseconds));

/**
 * A simple debounce so the pass function is only executed once given
 * a bunch of calls during the same interval.
 *
 * use:
 * ```
 * const myFuncDebounced = debounce(myFunc, 100);
 * myFuncDebounced()
 * myFuncDebounced()
 * // wait 100ms
 * // myFunc() only called once
 * ```
 */
export function debounce<Args extends unknown[]>(
  callback: (...args: Args) => void,
  milliseconds = 200,
): (...args: Args) => void {
  let timeout: number;
  return (...args: Args): void => {
    window.clearTimeout(timeout); // clear previous timeout and try again
    timeout = window.setTimeout(callback, milliseconds, ...args);
  };
}
