import { css } from '@emotion/core';

export const sprintsBacklogWrapperStyles = css`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0.0625rem 0 0 0 #eaeef5;
  flex-grow: 1;
  overflow: hidden;
`;
