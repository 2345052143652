"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileExtension = exports.matchAll = exports.getNumberAtEndAsString = exports.getInitials = exports.isNonEmptyString = exports.addPrefix = exports.capitalizeWords = exports.uncapitalize = void 0;
/**
 * Returns true if the argument is a String.
 *
 */
function isString(obj) {
    return typeof obj === 'string' || obj instanceof String;
}
/**
 * Uncapitalizes the first letter of the string and
 * returns a new string.
 */
function uncapitalize(str) {
    return str[0].toLocaleLowerCase() + str.slice(1);
}
exports.uncapitalize = uncapitalize;
/**
 * Capitalizes the first letter of each word in a
 * given string.
 */
function capitalizeWords(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map(function (word) { return word.charAt(0).toUpperCase() + word.substring(1); })
        .join(' ');
}
exports.capitalizeWords = capitalizeWords;
/**
 * Adds the specified prefix to the string
 * only if it doesn't already have the prefix
 * and returns a new string.
 */
function addPrefix(str, prefix) {
    return (str.startsWith(prefix) ? '' : prefix) + str;
}
exports.addPrefix = addPrefix;
/**
 * Returns true if the argument is a String and isn't just empty space.
 */
function isNonEmptyString(obj) {
    return isString(obj) && obj.toString().trim().length !== 0;
}
exports.isNonEmptyString = isNonEmptyString;
/**
 * Get the initials for the full name
 *
 * @example getInitials('Syed Ahmed') -> SA
 * @example getInitials('Madonna') -> M
 * @example getInitials('Kier Ivan Borromeo') -> KB
 *
 */
function getInitials(name) {
    if (name === void 0) { name = ''; }
    var nameParts = name.replace(/[^ a-z]/gi, '').split(' ');
    var initials = name.charAt(0);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (nameParts.length > 1)
        initials += nameParts.pop().charAt(0);
    return initials.toUpperCase();
}
exports.getInitials = getInitials;
/**
 * Get a number that appears at the end of a string or return null
 * @example getNumberAtEndAsString('Sprint 1') -> '1'
 * @example getNumberAtEndAsString('Tara Sprint 100') -> '100'
 * @example getNumberAtEndAsString('No Numbers Here') -> null
 */
function getNumberAtEndAsString(str) {
    var match = str.match(/\d+$/);
    if (match)
        return match[0];
    return null;
}
exports.getNumberAtEndAsString = getNumberAtEndAsString;
/**
 * Wrap imperative stateful regex.exec API in something more manageble.
 *
 * TODO: consider migration to matchAll from ES2020.
 */
function matchAll(str, regex) {
    var allMatches = [];
    var match = null;
    // eslint-disable-next-line no-loops/no-loops
    while ((match = regex.exec(str)) !== null) {
        var text = match[0], groups = match.slice(1);
        allMatches.push({
            index: match.index,
            length: text.length,
            text: text,
            groups: groups,
        });
    }
    return allMatches;
}
exports.matchAll = matchAll;
/**
 * Returns extension of provided filename.
 */
function getFileExtension(filename) {
    var _a, _b;
    return (_b = (_a = filename.match(/.\.([^.]+)$/)) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : '';
}
exports.getFileExtension = getFileExtension;
