import { RichEditorPlugin } from 'components/editor/types';
import { ContentState, EditorState, Modifier } from 'draft-js';

// This also removes formatting from pasted text, similar to what <input type="text"> is doing
// TODO: Remove flag when the whole pipeline changes + plugin hook is created
export const createSingleLinePlugin = (
  options: { returnHandled: boolean } = { returnHandled: false },
): RichEditorPlugin<() => ContentState> => ({
  handleReturn: () => (!options.returnHandled ? 'handled' : 'not-handled'),
  handlePastedText: (text, html, editorState, { setEditorState }) => {
    setEditorState(
      EditorState.push(
        editorState,
        Modifier.replaceText(editorState.getCurrentContent(), editorState.getSelection(), text.replaceAll(/\n/g, ' ')),
        'insert-fragment',
      ),
    );
    return 'handled';
  },
});
