import { Box } from '@taraai/design-system';
import { Attachment, UI } from '@taraai/types';
import { EditorAttachments, useAddAttachment } from 'components/core/controllers/Editor';
import { RichEditor } from 'components/editor/RichEditor';
import React from 'react';
import { strings } from 'resources';

interface Props {
  onAttachmentRemove: (attachment: Attachment) => void;
  onAttachmentUpload: (file: File) => Promise<string>;
  requirement: UI.UIRequirement;
}

export default function RequirementDescription({
  onAttachmentRemove,
  onAttachmentUpload,
  requirement,
}: Props): JSX.Element {
  const requirementAttachments: Attachment[] = requirement?.attachments ?? [];

  useAddAttachment(onAttachmentUpload);

  return (
    <>
      <Box space={['$20px', '$none']}>
        <RichEditor placeholder={strings.editor.requirementDescriptionPlaceholder} />
      </Box>
      <EditorAttachments attachments={requirementAttachments} onRemove={onAttachmentRemove} />
    </>
  );
}
