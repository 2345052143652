import { UI } from '@taraai/types';
import { DragElementWrapper, DragPreviewOptions, DragSourceOptions, useDrag, useDrop } from 'react-dnd';

export type FeatureCardDragItem = {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  type: 'feature-card';
  id: string;
  requirement: string | null;
};

type TaskFragment = Pick<UI.UITask, 'id' | 'title' | 'assignee' | 'effortLevel' | 'status' | 'slug' | '_relationships'>;

/**
 * FeatureCard wrapper on top of react-dnd's useDrag.
 */
export const useDragFeatureCard = (
  task: TaskFragment,
): [
  {
    isDragging: boolean;
    // used for testing
    handlerId: string | symbol | null;
  },
  DragElementWrapper<DragSourceOptions>,
  DragElementWrapper<DragPreviewOptions>,
] =>
  useDrag<FeatureCardDragItem, unknown, { isDragging: boolean; handlerId: string | symbol | null }>({
    item: {
      type: 'feature-card',
      id: task.id,
      requirement: task._relationships.requirement,
    },
    collect: (monitor) => ({
      // hook interface for react-dnd does not provide way to access
      // handlerId (used to simulate dnd when testing) other than
      // using monitor object in collect handler
      handlerId: monitor.getHandlerId(),
      isDragging: monitor.isDragging(),
    }),
  });
export type CardDropCallback = (featureCard: FeatureCardDragItem) => void;

/**
 * FeatureCard wrapper on top of react-dnd's useDrop.
 */
export const useDropFeatureCard = (
  dropCallback: CardDropCallback,
  options: { shallowHover?: boolean; disable?: boolean },
): [
  {
    isOver: boolean;
    handlerId: string | symbol | null;
  },
  DragElementWrapper<unknown>,
] => {
  return useDrop<FeatureCardDragItem, void, { isOver: boolean; handlerId: string | symbol | null }>({
    accept: 'feature-card' as FeatureCardDragItem['type'],
    drop: (item) => {
      if (!options.disable) {
        dropCallback(item);
      }
    },
    collect: (monitor) => ({
      // see handlerId above in useDragFeatureCard
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver({ shallow: !!options.shallowHover }) && !options.disable,
    }),
  });
};
