import TeamBacklogPopup from 'components/app/controllers/views/TeamBacklogPopup';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { reduxStore, selectAuth, selectProfile, updateUser } from 'reduxStore';

/**
 * TeamBacklogPopupController
 * controller for show backlog popup
 *
 */
export default function TeamBacklogPopupController(): JSX.Element | null {
  const { uid: profileId } = useSelector(selectAuth);

  const isTeamBacklogTutorialComplete = useSelector(
    compose((profile) => profile?.tutorialCards?.teamBacklog ?? false, selectProfile),
  );

  const closePopup = useCallback(() => {
    reduxStore.dispatch(updateUser({ id: profileId, tutorialCards: { teamBacklog: true } }));
  }, [profileId]);

  return !isTeamBacklogTutorialComplete ? <TeamBacklogPopup closePopup={closePopup} /> : null;
}
