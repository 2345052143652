import { UI } from '@taraai/types';

import { compareDates } from './dates';

export function compareByCreatedAtAsc(taskA: UI.UITask, taskB: UI.UITask): number {
  return compareDates(taskA.createdAt, taskB.createdAt);
}

export function compareByCreatedAtDesc(taskA: UI.UITask, taskB: UI.UITask): number {
  return -compareByCreatedAtAsc(taskA, taskB);
}
