/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/cognitive-complexity */
import { keyframes } from '@emotion/core';
import { Selector, unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { notUndefined } from '@taraai/utility';
import TaskModal from 'components/app/controllers/views/TaskModal';
import Modal from 'components/core/controllers/views/Modal';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { deleteTask, getTask, getTaskComments, getTaskRevision, reduxStore, taskSubTasks } from 'reduxStore';
import { getTaskGitData } from 'reduxStore/git-task-lifecycle/queries/task-git-data';
import { strings } from 'resources/i18n';
import { useToast } from 'tools/utils/hooks/useToast';

export interface TaskModalControllerProps extends React.HTMLProps<HTMLElement> {
  initialTaskID: string;
  initialTaskListOrder: string[];
  tasksSelector: Selector<any, UI.UITask[] | undefined>;
  navigationCategory: string | null;
  closeModal: () => void;
}

/**
 * TaskModalController
 * controller specific logic for task modal
 *
 */
export default function TaskModalController({
  initialTaskID,
  initialTaskListOrder,
  tasksSelector,
  closeModal,
  navigationCategory,
}: TaskModalControllerProps): JSX.Element {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const [currentTaskID, setCurrentTaskID] = useState(initialTaskID);
  const allCurrentTasksData: Record<string, UI.UITask> = (useSelector(tasksSelector) ?? []).reduce((acc, task) => {
    return { ...acc, [task.id]: task };
  }, {});

  const taskSlice = getTask(orgID, currentTaskID);
  const taskCommentsSlice = getTaskComments(orgID, currentTaskID);

  useFirestoreConnect([...taskSlice.query, ...taskCommentsSlice.query]);

  const queryTask = useSelector(taskSlice.selector);
  const taskComments = useSelector(taskCommentsSlice.selector)?.filter(({ deletedAt }) => deletedAt === null);

  /**
   * allCurrentTasks is mapping over initialTaskListOrder to keep original data
   * order from the selector that is called in the TaskModalProvider.
   * It's still all current tasks data, but the new data is being mapped
   * over the original task list order when the modal was initially opened
   * This prevents any ordering changes from happening in the navigation when tasks update
   */

  const allCurrentTasks = initialTaskListOrder
    .map((id) => allCurrentTasksData[id] ?? (id === currentTaskID ? queryTask : undefined))
    .filter(notUndefined);

  /**
   * This query is to help prevent the task from closing when data updates occur
   */

  const currentTask = allCurrentTasksData[currentTaskID] ?? queryTask;

  /**
   * This use effect is effectively intercepting any react router history and forcing the modal to close
   * instead of going back on the history stack.
   */

  useEffect((): (() => void) => {
    window.onpopstate = (): void => {
      closeModal();
    };
    return (): null => (window.onpopstate = null);
  }, [closeModal]);

  const [taskCopiedText, setTaskCopiedText] = useState(false);

  const taskSubTasksSlice = taskSubTasks(orgID, currentTask?.id);
  const taskGitDataSlice = getTaskGitData(orgID, currentTask?.slug);
  const taskRevisionsSlice = getTaskRevision(orgID, currentTask?.id);

  useFirestoreConnect([...taskSubTasksSlice.query, ...taskGitDataSlice.query, ...taskRevisionsSlice.query]);

  const revisions = useSelector(taskRevisionsSlice.selectors.all()) || [];

  const gitData = useSelector(taskGitDataSlice.selectors.all());
  const subtasks = useSelector(taskSubTasksSlice.selector) || [];

  const currentIndex = allCurrentTasks.findIndex((taskItem) => taskItem.slug === currentTask?.slug);

  const currentTaskNumber = currentIndex + 1;

  const prevTask = allCurrentTasks[currentIndex - 1];
  const nextTask = allCurrentTasks[currentIndex + 1];

  const navigateLeft = useCallback(() => {
    window.history.pushState('', '', `/${orgID}/tasks/${prevTask.slug}`);
    setCurrentTaskID(prevTask.id);
  }, [orgID, prevTask]);

  const navigateRight = useCallback(() => {
    window.history.pushState('', '', `/${orgID}/tasks/${nextTask.slug}`);
    setCurrentTaskID(nextTask.id);
  }, [nextTask, orgID]);

  useHotkeys(
    'left',
    (event) => {
      event.preventDefault();
      if (currentIndex > 0) {
        navigateLeft();
      }
    },
    {},
    [currentIndex],
  );
  useHotkeys(
    'right',
    (event) => {
      event.preventDefault();
      if (currentIndex < allCurrentTasks.length - 1) {
        navigateRight();
      }
    },
    {},
    [currentIndex],
  );

  useHotkeys('esc', () => closeModal(), {}, []);

  const { addToast } = useToast();

  const deleteCurrentTask = (taskToBeDeleted: UI.UITask, allTasks: UI.UITask[]): (() => void) => (): void => {
    if (subtasks.length) {
      subtasks.map((subtask) => {
        return reduxStore
          .dispatch(deleteTask({ id: subtask.id } as Pick<UI.UITask, 'id'>))
          .then(unwrapResult)
          .catch(() => {
            addToast({
              type: 'error',
              message: strings.task.failedToDeleteTask,
            });
          });
      });
    }
    reduxStore
      .dispatch(deleteTask({ id: taskToBeDeleted.id } as Pick<UI.UITask, 'id'>))
      .then(unwrapResult)
      .then(() =>
        addToast({
          message: `${strings.formatString(strings.task.deleted, {
            slug: taskToBeDeleted.slug,
          })}`,
          timeoutMs: 3500,
          type: 'success',
        }),
      )
      .catch(() => {
        addToast({ type: 'error', message: strings.task.failedToDeleteTask });
      });

    if (prevTask && allCurrentTasks.length > 1) {
      navigateLeft();
      /**
       * Else if navigation mode and more than one sibling task exists navigate to the right task on deletion
       */
    } else if (nextTask && allCurrentTasks.length > 1) {
      navigateRight();
      /**
       * Else if there is only one remaining task then close modal
       */
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (!currentTask) {
      if (prevTask && allCurrentTasks.length >= 1) {
        navigateLeft();
      } else if (nextTask && allCurrentTasks.length >= 1) {
        navigateRight();
      } else if (allCurrentTasks.length === 0) {
        closeModal();
      }
    }
  }, [allCurrentTasks.length, closeModal, currentTask, navigateLeft, navigateRight, nextTask, prevTask]);

  const copyTaskID = (): void => {
    navigator.clipboard.writeText(`${window.location.origin}/${orgID}/*/tasks/${currentTask?.slug}`);
    setTaskCopiedText(true);
  };
  const isNotLoaded =
    !isLoaded(allCurrentTasksData) ||
    !isLoaded(currentIndex) ||
    !isLoaded(allCurrentTasks) ||
    !isLoaded(currentTask) ||
    !isLoaded(taskComments) ||
    !isLoaded(revisions) ||
    !isLoaded(gitData);

  if (isNotLoaded) {
    return (
      <Modal
        className={css`
          animation: ${fadeIn} 2s ease-in-out;
        `}
      >
        <StandardSpinner fillSpace size='medium' />
      </Modal>
    );
  }

  // eslint-disable-next-line xss/no-mixed-html
  return (
    <DndProvider backend={HTML5Backend}>
      <TaskModal
        key={currentTask?.id + allCurrentTasks.length}
        author={currentTask?.authorDocument}
        closeModal={closeModal}
        copyTaskID={copyTaskID}
        currentOrg={orgID}
        currentTaskNumber={currentTaskNumber}
        deleteCurrentTask={deleteCurrentTask(currentTask, allCurrentTasks)}
        gitData={gitData}
        navigateLeft={prevTask ? navigateLeft : undefined}
        navigateRight={nextTask ? navigateRight : undefined}
        navigationCategory={navigationCategory}
        revisions={revisions}
        setTaskCopiedText={setTaskCopiedText}
        task={currentTask}
        taskComments={taskComments}
        taskCopiedText={taskCopiedText}
        taskCount={allCurrentTasks.length}
      />
    </DndProvider>
  );
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
