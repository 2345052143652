import { Popper } from '@material-ui/core';
import { styled } from '@taraai/design-system';

export const PopperWrapper = styled(Popper, {
  backgroundColor: '$white',
  boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.18)',
  padding: '0.7rem 1rem',
  zIndex: 1000,
});

export const Content = styled('div', {
  'alignItems': 'center',
  'display': 'flex',
  'flexDirection': 'row',

  '> * + *': {
    marginLeft: '1rem',
  },
});
