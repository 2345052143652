import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import Menu from 'components/core/controllers/views/Menu';
import { linkTo } from 'components/Router/paths';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { strings } from 'resources/i18n';

interface Props {
  isMember: boolean;
  hasAlternativeTeams?: boolean;
  isSprintCompleteOrActive?: boolean;
  name: string;
  orgId: string;
  teamId: string;
}

/**
 * TeamDetailsHeader
 * Team Details Header
 *
 */

export function TeamDetailsHeader({
  isMember,
  hasAlternativeTeams,
  isSprintCompleteOrActive,
  name,
  orgId,
  teamId,
}: Props): JSX.Element {
  const history = useHistory();
  const navigateToDelete = useCallback((): void => {
    history.push(linkTo('deleteTeam', { orgID: orgId, teamID: teamId }));
  }, [history, orgId, teamId]);

  return (
    <Container>
      <HeaderContainer>
        {name}
        {isMember && <IsMemberText>{strings.teamDetails.isTeamMember}</IsMemberText>}
      </HeaderContainer>
      {isSprintCompleteOrActive || !hasAlternativeTeams ? (
        <Menu
          isDisabled
          options={[
            {
              title: strings.teamDetails.deleteTeam,
              onSelect: (event) => event.stopPropagation(),
            },
          ]}
          showTooltip
          styledType='menu'
          title={
            isSprintCompleteOrActive
              ? strings.teamDetails.deleteError.activeOrCompletedSprints
              : strings.teamDetails.deleteError.noAlternativeTeams
          }
        >
          <MeatballsIcon name='meatballs' />
        </Menu>
      ) : (
        <Menu
          options={[
            {
              title: strings.teamDetails.deleteTeam,
              onSelect: navigateToDelete,
            },
          ]}
          styledType='menu'
        >
          <MeatballsIcon name='meatballs' />
        </Menu>
      )}
    </Container>
  );
}

const Container = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const HeaderContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const IsMemberText = styled('span', {
  marginLeft: '0.5rem',
  fontSize: '0.875rem',
  color: '#949caf',
});

const MeatballsIcon = styled(Icon, {
  color: '#949caf',
  width: '1rem',
  paddingTop: '1.25rem',
  alignContent: 'center',
  justifyContent: 'center',
});
