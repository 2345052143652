import Tabs, { Tab } from 'components/core/controllers/views/Tabs';
import React from 'react';

type Props = {
  handleTabChange: (tab: Tab) => void;
  selectedTab?: Tab;
  tabs: Tab[];
};

export const NavigationLayout = ({ handleTabChange, selectedTab, tabs }: Props): JSX.Element => {
  return (
    <div>
      <Tabs
        handleTabChange={handleTabChange}
        isGlobalNav
        selectedTab={selectedTab}
        tabs={tabs}
        tabsSeparatorVariant='transparent'
        tabsUnderlineVerticalStackPosition='negative'
      />
    </div>
  );
};
