import { RichEditorPlugin } from 'components/editor/types';
import { ContentState } from 'draft-js';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';

export const markdownPlugin: RichEditorPlugin<(source: string) => ContentState> = {
  pipeline: {
    read: stateFromMarkdown,
    save: stateToMarkdown,
  },
};
