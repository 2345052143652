import { Data, UI } from '@taraai/types/src';
import TeamStats from 'components/app/controllers/views/TeamStats';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { getSprint, getSprintTasks } from 'reduxStore';
import {
  getCompletedEffortFromTasks,
  getCompletedPercentage,
  getTotalEffortFromTasks,
} from 'tools/utils/calculateTaskEffort';

export interface TeamStatsControllerProps {
  team: UI.UITeam;
  orgID: Data.Id.OrganizationId;
  isPreferredTeam: boolean;
}

/**
 * TeamStatsController
 * controller logic to determine individual team stats ui
 * can be of type 'team' or type 'preferred team'
 *
 */
export default function TeamStatsController({
  team,
  orgID,
  isPreferredTeam,
}: TeamStatsControllerProps): JSX.Element | null {
  const sprintSlice = getSprint(orgID, team.currentSprintId ?? '');
  const tasksSlice = getSprintTasks(orgID, team.currentSprintId || '');

  useFirestoreConnect([...sprintSlice.query, ...tasksSlice.query]);

  const sprint = useSelector(sprintSlice.selector);
  const tasks = useSelector(tasksSlice.selector) || [];

  const totalEffort = getTotalEffortFromTasks(tasks);
  const teamTasksCompletedEffort = getCompletedEffortFromTasks(tasks);

  const teamDonePercentage = getCompletedPercentage(teamTasksCompletedEffort, totalEffort);

  if (!sprint) {
    return null;
  }

  return (
    <>
      {!isPreferredTeam && (
        <div
          className={css`
            border-top: solid 1px #eaeef5;
            margin: 0rem 1rem;
          `}
        />
      )}
      <TeamStats
        done={teamDonePercentage}
        effort={totalEffort}
        effortCompleted={teamTasksCompletedEffort}
        sprint={sprint}
        teamName={team.name}
      />
    </>
  );
}
