/* eslint-disable xss/no-mixed-html */

import { Tooltip } from '@material-ui/core';
import { styled } from '@taraai/design-system';
import { noop } from '@taraai/utility';
import Icon from 'components/core/controllers/views/Icon';
import { EditorState } from 'draft-js';
import React, { createElement, MutableRefObject, useContext, useMemo, useRef } from 'react';
import { atomic, IconName } from 'resources';

import { RichEditorContext } from './RichEditorProvider';
import { EditorStateTransform, transforms } from './transforms';
import { isBlockTypeSelected, isInlineStyleSelected } from './utils';

type EditorToolName =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'code'
  | 'attachment'
  | 'headerOne'
  | 'headerTwo'
  | 'unorderedList'
  | 'orderedList'
  | 'blockquote'
  | 'codeBlock';

interface Props {
  enabled?: EditorToolName[];
  onAttachmentUpload?: (file: File) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function RichEditorToolbar({
  enabled: initiallyEnabled = defaultEnabled,
  onAttachmentUpload,
  onClick,
}: Props): JSX.Element {
  const { toolbarRef } = useContext(RichEditorContext);
  const toolbarButtons = useMemo(() => createToolbarButtons(onAttachmentUpload ?? noop), [onAttachmentUpload]);
  const enabled = initiallyEnabled;
  if (onAttachmentUpload && !initiallyEnabled.includes('attachment')) {
    enabled.push('attachment');
  }
  return (
    <ToolbarRow ref={toolbarRef as MutableRefObject<HTMLDivElement>} onClick={onClick}>
      {enabled.map((toolName) => createElement(toolbarButtons[toolName], { key: toolName }))}
    </ToolbarRow>
  );
}

const defaultEnabled: EditorToolName[] = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'code',
  'headerOne',
  'headerTwo',
  'unorderedList',
  'orderedList',
  'blockquote',
  'codeBlock',
];

const createToolbarButtons = (onAttachmentUpload: (file: File) => void): Record<EditorToolName, () => JSX.Element> => ({
  bold: getToolbarButton('Bold', isInlineStyleSelected('BOLD'), 'formatBold', transforms.toggleBold),
  italic: getToolbarButton('Italic', isInlineStyleSelected('ITALIC'), 'formatItalic', transforms.toggleItalics),
  underline: getToolbarButton(
    'Underline',
    isInlineStyleSelected('UNDERLINE'),
    'formatUnderlined',
    transforms.toggleUnderline,
  ),
  strikethrough: getToolbarButton(
    'Strikethrough',
    isInlineStyleSelected('STRIKETHROUGH'),
    'strikethrough',
    transforms.toggleStrikethrough,
  ),
  code: getToolbarButton('Code', isInlineStyleSelected('CODE'), 'formatCode', transforms.toggleCode),
  attachment: getAttachmentButton(onAttachmentUpload),
  headerOne: getToolbarButton('Large Title', isBlockTypeSelected('header-one'), 'looksOne', transforms.toggleHeaderOne),
  headerTwo: getToolbarButton('Small Title', isBlockTypeSelected('header-two'), 'looksTwo', transforms.toggleHeaderTwo),
  unorderedList: getToolbarButton(
    'Bulleted List',
    isBlockTypeSelected('unordered-list-item'),
    'formatListBulleted',
    transforms.toggleUnorderedList,
  ),
  orderedList: getToolbarButton(
    'Ordered List',
    isBlockTypeSelected('ordered-list-item'),
    'formatListNumbered',
    transforms.toggleOrderedList,
  ),
  blockquote: getToolbarButton(
    'Blockquote',
    isBlockTypeSelected('blockquote'),
    'blockquote',
    transforms.toggleBlockquote,
  ),
  codeBlock: getToolbarButton(
    'Code Block',
    isBlockTypeSelected('code-block'),
    'formatCodeBlock', // todo: new icon should be provided
    transforms.toggleCodeBlock,
  ),
});

function getToolbarButton(
  title: string,
  activeSelector: (state: EditorState) => boolean,
  icon: IconName,
  onTransformState: EditorStateTransform,
) {
  return function ToolbarButton(): JSX.Element {
    const { editorState, setEditorState } = useContext(RichEditorContext);
    const isSelected = activeSelector(editorState);
    return useMemo(
      () => (
        <Tooltip title={<TooltipTitle>{title}</TooltipTitle>} TransitionProps={{ timeout: 600 }}>
          <Icon
            color={isSelected ? atomic.colors.dark : atomic.colors.grey5}
            name={icon}
            onMouseDown={(event) => {
              // Prevent the loss of focus in the editor
              event.preventDefault();
              setEditorState(onTransformState);
            }}
          />
        </Tooltip>
      ),
      [isSelected, setEditorState],
    );
  };
}

const ToolbarRow = styled('div', {
  'display': 'flex',
  'flexDirection': 'row',
  'paddingLeft': '0.5rem',
  'paddingRight': '0.5rem',
  '& > *': {
    padding: '0.5rem',
    margin: '0 0.5rem',
  },
});

const TooltipTitle = styled('div', {
  color: '#ffffff',
  fontSize: '0.875rem',
  fontWeight: 'normal',
  lineHeight: '1.125rem',
});

function getAttachmentButton(onAttachmentUpload: (file: File) => void) {
  return function AttachmentButton(): JSX.Element {
    const fileInput = useRef<HTMLInputElement>(null);
    return useMemo(
      () => (
        <>
          <UploadFilesInput
            ref={fileInput}
            multiple
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const files = Array.from(event.target.files ?? []);
              files.forEach(onAttachmentUpload);
            }}
            type='file'
          />
          <Tooltip title={<TooltipTitle>Insert file</TooltipTitle>} TransitionProps={{ timeout: 600 }}>
            <Icon
              color={atomic.colors.grey5}
              name='attachment'
              onClick={(): void => {
                fileInput.current?.click();
              }}
            />
          </Tooltip>
        </>
      ),
      [],
    );
  };
}
const UploadFilesInput = styled('input', {
  opacity: 0,
  position: 'absolute',
  transform: 'scale(0, 0)',
});
