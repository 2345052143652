import { unwrapResult } from '@reduxjs/toolkit';
import DeactivatedUsersLayout from 'components/app/controllers/views/DeactivatedUsersLayout';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { usePathParams } from 'components/Router/paths';
import { css } from 'emotion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUsers, grantPermission, listOrganizationUsers, reduxStore, selectAuth } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

export default function DeactivatedUsersController(): JSX.Element {
  const { orgID } = usePathParams('workspace');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { uid: profileId } = useSelector(selectAuth);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deactivatedUsers =
    useSelector(getUsers(orgID).selector)?.filter((user) => user.accessLevels?.[orgID] === 'deactivated') || [];
  const { addToast } = useToast();

  // used to detect if component unmounted
  const isListOrgUsersCancelled = useRef(false);

  useEffect(() => {
    async function dispatchListOrganizationUsers(): Promise<void> {
      setIsLoading(true);
      try {
        await reduxStore.dispatch(listOrganizationUsers()).then(unwrapResult);
      } catch {
        addToast({
          message: strings.users.deactivatedUsers.toasts.error,
          timeoutMs: 3500,
          type: 'error',
        });
      } finally {
        if (!isListOrgUsersCancelled.current) setIsLoading(false);
      }
    }
    dispatchListOrganizationUsers();

    return (): void => {
      isListOrgUsersCancelled.current = true;
    };
  }, [addToast, profileId]);

  const [reactivationInProgress, setReactivationInProgress] = useState<{
    [userId: string]: boolean;
  }>({});

  const onReactivateUser = useCallback(
    async (userId: string) => {
      try {
        const reactivatedUser = deactivatedUsers.find((user) => user.id === userId);
        if (!reactivatedUser) {
          throw new Error("Couldn't find user to reactivate");
        }

        setReactivationInProgress((prevState) => ({
          ...prevState,
          [userId]: true,
        }));

        await reduxStore
          .dispatch(
            grantPermission({
              accessLevel: 'user',
              userId,
            }),
          )
          .then(unwrapResult);

        addToast({
          message: strings.formatString(strings.users.deactivatedUsers.toasts.reactivate.success, {
            name: reactivatedUser.name,
          }) as string,
          timeoutMs: 3500,
          type: 'success',
        });
      } catch {
        addToast({
          message: strings.users.deactivatedUsers.toasts.reactivate.error,
          timeoutMs: 3500,
          type: 'error',
        });
      }
    },
    [addToast, setReactivationInProgress, deactivatedUsers],
  );

  if (isLoading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-top: 8rem;
          width: 100%;
        `}
      >
        <StandardSpinner size='medium' />
      </div>
    );
  }
  return (
    <DeactivatedUsersLayout
      deactivatedUsers={deactivatedUsers}
      onReactivateUser={onReactivateUser}
      reactivationInProgress={reactivationInProgress}
    />
  );
}
