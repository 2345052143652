import { styled } from '@taraai/design-system';
import { AsanaNotificationBarController } from 'components/app/controllers/AsanaNotificationBarController';
import { GithubImportNotificationBarController } from 'components/app/controllers/GithubImportNotificationBarController';
import ImportModalController from 'components/app/controllers/ImportModalController';
import { SlackConnectAccountNotificationBar } from 'components/app/controllers/SlackConnectAccountNotificationBar/SlackConnectAccountNotificationBar';
import { SprintCompletedNotificationBarController } from 'components/app/controllers/SprintCompletedNotificationBarController';
import TaskModalProvider from 'components/app/controllers/TaskModalProvider';
import { TrelloNotificationBarController } from 'components/app/controllers/TrelloNotificationBarController';
import { GlobalNav } from 'components/app/GlobalNav';
import React from 'react';

const Scaffold: React.FC = ({ children }) => (
  <TaskModalProvider>
    <SlackConnectAccountNotificationBar />
    <TrelloNotificationBarController />
    <AsanaNotificationBarController />
    <GithubImportNotificationBarController />
    <SprintCompletedNotificationBarController />
    <Container>
      <GlobalNav />
      <ImportModalController />
      <Main>{children}</Main>
    </Container>
  </TaskModalProvider>
);

export default Scaffold;

const Main = styled('main', {
  backgroundColor: '#ffffff',
  height: 'calc(100% - 3rem)',
  width: '100%',
});

const Container = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
});
