import styled from '@emotion/styled';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { atomic, IconName } from 'resources';

type NavigationIconProps = {
  name: IconName;
  dataCy?: string;
};

export const NavigationIcon: React.FC<NavigationIconProps> = ({ name, dataCy }): JSX.Element => (
  <CustomIcon color={atomic.colors.inherit} dataCy={dataCy} name={name} />
);

const CustomIcon = styled((props) => <Icon {...props} />)`
  position: relative;
  top: 0.5em;
  left: 0.6em;
  height: 1.25rem;
  width: 1.25rem;
`;
