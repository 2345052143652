import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { notUndefined } from '@taraai/utility';
import TaskModalContext from 'components/app/controllers/TaskModalContext';
import TaskStatus from 'components/app/controllers/TaskStatus';
import EmptyTableState from 'components/app/controllers/views/EmptyTableState';
import { SmartText } from 'components/app/controllers/views/SmartText';
import TaskList from 'components/app/controllers/views/TaskList';
import TasksAssignedHeader from 'components/app/controllers/views/TasksAssignedHeader';
import { TableDataEntry } from 'components/core/controllers/Table';
import AvatarPicker from 'components/core/controllers/views/AvatarPicker';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
  getAssignedTasksFromUserSprints,
  getCollaborativeTasksFromUserSprints,
  getUsers,
  reduxStore,
  selectAuth,
} from 'reduxStore';
import { updateTask } from 'reduxStore/tasks/actions/update';
import { taskListAttributeTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { sort } from 'tools/libraries/helpers/sort';

// Sorting on the frontend to fix an issue with duplicate tasks showing up on staging due to TaskStatus not having the updated action for tasks
export function convertTasksToTableData(
  tasks: UI.UITask[] | undefined,
  onClick: (event: React.SyntheticEvent) => void,
  onAddUser: (taskId: Data.Id.TaskId) => (userId: Data.Id.UserId) => void,
  onRemoveUser: (taskId: Data.Id.TaskId) => (userId: Data.Id.UserId) => void,
  team: UI.UIUser[],
  orgID: Data.Id.OrganizationId,
): TableDataEntry[] {
  return sort(tasks || [], 'status').map((task: UI.UITask) => ({
    status: (
      <div
        data-testid='task-status'
        onClick={onClick}
        onKeyDown={(event): void | false => event.keyCode === 13 && event.stopPropagation()}
        role='button'
        tabIndex={0}
      >
        <TaskStatus key={task.id} dataCy={taskListAttributeTestIDs.STATUS_DROPDOWN} task={task} />
      </div>
    ),
    title: (
      <TaskTitleText h5>
        <SmartText text={task.title} />
      </TaskTitleText>
    ),
    assignee: (
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <AvatarPicker
          key={task.id}
          className={css`
            padding: 0.25rem 0rem;
          `}
          dataCy={taskListAttributeTestIDs.ASSIGNEE_DROPDOWN}
          maxAllowed={1}
          onAddUser={onAddUser(task.id)}
          onRemoveUser={onRemoveUser(task.id)}
          position='bottom-left'
          size='small'
          // show only active users plus assignee, or all users if there's no access level info available
          suggestions={team.filter(
            (user) => user.accessLevels?.[orgID] !== 'deactivated' || user.id === task.assigneeDocument?.id,
          )}
          users={[task.assigneeDocument].filter(notUndefined)}
        />
      </div>
    ),
    id: task.id,
    task,
  }));
}

export interface DashboardTasksProps {
  // ID of currently viewed organization (aka workspace)
  orgID: Data.Id.OrganizationId;
  activeSprintIds: Data.Id.SprintId[];
}

/**
 * DashboardTasks holds logic to render the correct task list tables
 * for the current user on the home page
 *
 */
export default function DashboardTasks({ orgID, activeSprintIds: sprintIDs }: DashboardTasksProps): JSX.Element {
  const team = useSelector(getUsers(orgID).selector);
  const { uid: userID } = useSelector(selectAuth);
  const { openModal } = useContext(TaskModalContext);

  const currentSprintCollaboratingTasks = getCollaborativeTasksFromUserSprints(orgID, userID, sprintIDs, {
    orderBy: 'title',
  });

  const currentSprintAssignedTasks = getAssignedTasksFromUserSprints(orgID, userID, sprintIDs, {
    orderBy: 'title',
  });

  useFirestoreConnect(currentSprintCollaboratingTasks.query);
  useFirestoreConnect(currentSprintAssignedTasks.query);

  const sprintCollabTasks = useSelector(currentSprintCollaboratingTasks.selector) || [];
  const sprintAssignedTasks = useSelector(currentSprintAssignedTasks.selector) || [];

  const dashboardSelector = createSelector(
    currentSprintCollaboratingTasks.selector,
    currentSprintAssignedTasks.selector,
    (sprintCollabTasksData, sprintAssignedTasksData) => [
      ...sort(sprintAssignedTasksData || [], 'status'),
      ...sort(sprintCollabTasksData || [], 'status'),
    ],
  );

  const toggleModal = useCallback(
    (task) => {
      openModal(task, dashboardSelector, strings.dashboard.home);
    },
    [dashboardSelector, openModal],
  );

  const assignedTasksData = sprintAssignedTasks;
  const collabTasksData = sprintCollabTasks;

  const { addToast } = useToast();

  const addAssignee = useCallback(
    (taskID) => (assignee: string): void => {
      reduxStore
        .dispatch(updateTask({ id: taskID, assignee }))
        .then(unwrapResult)
        .catch(() => {
          addToast({ type: 'error', message: strings.task.failedToUpdateTask });
        });
    },
    [addToast],
  );

  const removeAssignee = useCallback(
    (taskID) => (): void => {
      reduxStore
        .dispatch(updateTask({ id: taskID, assignee: null }))
        .then(unwrapResult)
        .catch(() => {
          addToast({ type: 'error', message: strings.task.failedToUpdateTask });
        });
    },
    [addToast],
  );

  const colWidth: Record<string, string> = {};

  colWidth[strings.dashboard.status] = '7%';
  colWidth[strings.dashboard.title] = '90%';
  colWidth[strings.dashboard.assignee] = '3%';

  const colCount = Object.keys(colWidth).length;

  const handleClick = (event: React.SyntheticEvent): void => {
    event.stopPropagation();
  };

  const assignedTasks = convertTasksToTableData(
    assignedTasksData,
    handleClick,
    addAssignee,
    removeAssignee,
    team ?? [],
    orgID,
  );

  const collabTasks = convertTasksToTableData(
    collabTasksData,
    handleClick,
    addAssignee,
    removeAssignee,
    team ?? [],
    orgID,
  );

  const noAssignedTasks =
    assignedTasks.length === 0 ? <EmptyTableState colCount={colCount} noAssignedTasks orgID={orgID} /> : undefined;

  const noActiveSprint =
    sprintIDs.length > 0 ? undefined : <EmptyTableState colCount={colCount} noActiveSprint orgID={orgID} />;

  const columns = {
    status: { name: strings.task.status, width: '15%' },
    title: { name: strings.task.name, width: '80%' },
    assignee: { name: '', width: '5%' },
  };
  return (
    <div
      className={css`
        flex: 1;
        background-color: #fbfbfd;
      `}
    >
      <TaskList
        bottomRow={noActiveSprint || noAssignedTasks}
        columns={columns}
        colWidth={colWidth}
        dashboardTaskRow
        tasks={assignedTasks}
        toggleModal={toggleModal}
        topRow={
          sprintIDs.length > 0 ? (
            <TasksAssignedHeader colCount={colCount} header taskCount={assignedTasks.length} />
          ) : undefined
        }
      />
      {collabTasks?.length > 0 && (
        <TaskList
          columns={columns}
          colWidth={colWidth}
          dashboardTaskRow
          tasks={collabTasks}
          toggleModal={toggleModal}
          topRow={<TasksAssignedHeader colCount={colCount} taskCount={collabTasks.length} />}
        />
      )}
    </div>
  );
}

const TaskTitleText = styled(Text, {
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  wordBreak: 'break-word',
});
