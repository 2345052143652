import { createAsyncThunk } from '@reduxjs/toolkit';
import { UISprint, UISprintChangeset } from '@taraai/types/dist/ui';
import { updateTeam } from 'reduxStore/teams';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';
import { funcLog } from 'tools';
import errorHandler from 'tools/libraries/stackdriver';

import { updateSprint } from './update';

export type StartSprintPayload = Pick<UISprint, 'id' | 'initialStartDate' | 'initialEndDate' | 'sprintName'>;

export const startSprint = createAsyncThunk(
  'StartSprint',
  async (payload: StartSprintPayload, { dispatch, extra }): Promise<void> => {
    try {
      const { getOrgID, getFirestore } = extra as ExtraAPI;
      const orgID = getOrgID();
      const firestore = getFirestore();

      const { id: sprintID } = payload;

      const validatedChangeset = decode<UISprintChangeset>(payload, 'UISprintChangeset');

      await firestore.runTransaction(async (transaction) => {
        const orgRef = firestore.doc(`orgs/${orgID}`);

        // Validate that the selected sprint exists
        const currentSprintDoc = await orgRef.collection('sprints').doc(sprintID).get();

        if (!currentSprintDoc.exists) {
          throw new Error(`Sprint document ${sprintID} or it's data does not exist`);
        }

        const { teamId: teamID } = currentSprintDoc.data() || {};

        if (!teamID) {
          throw new Error('teamID can not be empty');
        }

        // Validate that team exists
        const teamDoc = await orgRef.collection('teams').doc(teamID).get();

        if (!teamDoc.exists) {
          throw new Error(`Team ${teamID} does not exist`);
        }

        // Set start/end timestamps on the selected sprint
        await dispatch(
          updateSprint({
            ...validatedChangeset,
            meta: { transaction },
          }),
        );

        // Update team settings to specify current sprint
        await dispatch(
          updateTeam({
            id: teamID,
            currentSprintId: sprintID,
            meta: { transaction },
          }),
        );
      });
    } catch (error) {
      funcLog('Failed to start sprint', {
        error,
        ...error,
      });
      errorHandler.report(error);
      throw error;
    }
  },
);
