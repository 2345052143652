import { UI } from '@taraai/types';
import DraggableFeatureCardList from 'components/app/controllers/DraggableFeatureCardList';
import BacklogTaskHeader from 'components/app/controllers/views/BacklogTaskHeader';
import { DragSource } from 'components/app/controllers/views/DraggableFeatureCard/DragAndDropContext';
import { css } from 'emotion';
import React, { useMemo } from 'react';

export interface BacklogTasksProps extends React.HTMLProps<HTMLDivElement> {
  tasks: UI.UITask[];
  toggleModal: (task: UI.UITask) => void;
  getGroups: () => void;
  onMoveToBacklog: (taskRef: string) => void;
  onMoveToSprint: (taskRef: string, sprintId: string) => void;
}

/**
 * BacklogTasks does…
 *
 */
function BacklogTasks({
  className,
  tasks = [],
  onMoveToBacklog,
  onMoveToSprint,
  getGroups,
  toggleModal,
  ...props
}: BacklogTasksProps): JSX.Element {
  const dragSource = useMemo((): DragSource => ({ type: 'backlog' }), []);
  return (
    <>
      <div
        className={css`
          padding: 1rem 0.5rem 1.125rem 0.5rem;
          background-color: #ffffff;
          border-bottom: 0.0625rem solid #eaeef5;
        `}
      >
        <BacklogTaskHeader backlogTaskCount={tasks.length} getGroups={getGroups} />
      </div>
      {tasks.length > 0 && (
        <div
          className={css`
            height: 90%;
            overflow: auto;
          `}
        >
          <DraggableFeatureCardList
            dragSource={dragSource}
            onMoveToBacklog={onMoveToBacklog}
            onMoveToSprint={onMoveToSprint}
            selectSprint
            tasks={tasks}
            toggleModal={toggleModal}
            {...props}
          />
        </div>
      )}
    </>
  );
}

export default BacklogTasks;
