import { Data, UI } from '@taraai/types';
import SprintDetailsHeader from 'components/app/controllers/views/SprintDetailsHeader';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { getCompleteSprintData } from 'reduxStore/sprints/queries/complete-sprint-data';

export interface SprintDetailsHeaderControllerProps {
  selectedSprint: UI.UISprint | undefined;
  organizationID: Data.Id.OrganizationId;
  nextSprintID: Data.Id.SprintId | null;
  previousSprintID: Data.Id.SprintId | null;
}

/**
 * SprintDetailsHeaderController
 */
export default function SprintDetailsHeaderController({
  selectedSprint,
  organizationID,
  nextSprintID,
  previousSprintID,
}: SprintDetailsHeaderControllerProps): JSX.Element {
  // Lazy load data while rendering UI
  const sprintData = getCompleteSprintData(
    organizationID,
    selectedSprint?.id ?? '',
    selectedSprint?.initialStartDate ?? null,
    selectedSprint?.initialEndDate ?? null,
  );

  useFirestoreConnect(sprintData.query);
  const completeSprintData = useSelector(sprintData.selectors.all());

  return (
    <SprintDetailsHeader
      completeSprintData={completeSprintData}
      nextSprintID={nextSprintID}
      organizationID={organizationID}
      previousSprintID={previousSprintID}
      selectedSprint={selectedSprint}
    />
  );
}
