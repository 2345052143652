import { styled } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import Icon from 'components/core/controllers/views/Icon';
import Logo from 'components/core/controllers/views/Logo';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectPreferredTeamId } from 'reduxStore';
import { workspaceTestIDs } from 'resources/cypress/testAttributesValues';

export interface WorkspaceItemProps extends React.HTMLProps<HTMLDivElement> {
  org: UI.UIOrganization;
  selectedOrgID: Data.Id.OrganizationId;
}

export default function WorkspaceItem({ org, selectedOrgID }: WorkspaceItemProps): JSX.Element | null {
  const isSelected = selectedOrgID === org.id;
  const preferredTeamId = useSelector(selectPreferredTeamId(org.id));

  return (
    <Workspace
      data-cy={workspaceTestIDs.SINGLE_WORKSPACE_ON_LIST}
      to={linkTo('sprints', {
        orgID: org.id,
        teamID: preferredTeamId,
      })}
    >
      <Logo color={org.color} name={org.name} shape='square' size='xsmall' url={org.logoURL} />
      <WorkspaceName>{org.name}</WorkspaceName>
      {isSelected && <IndicatorStyled name='tick' />}
    </Workspace>
  );
}

const WorkspaceName = styled('div', {
  fontSize: '0.875rem',
  color: '$dark',
  textAlign: 'left',
  marginLeft: '0.5rem',
});

const IndicatorStyled = styled(Icon, {
  position: 'absolute',
  top: '50%',
  right: '0.5rem',
  transform: 'translateY(-50%)',
  color: '$grey6',
  width: '0.875rem',
  height: '0.625rem',
});

const Workspace = styled(Link, {
  'position': 'relative',
  'padding': '1rem',
  'height': '2.25rem',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'flex-start',
  '&:hover': {
    backgroundColor: '$grey1',
  },
});
