import { useEffect } from 'react';

export const useVisibility = (
  ref: React.RefObject<Element>,
  handler: (entry: IntersectionObserverEntry) => void,
  root: Element | null | undefined,
  rootMargin?: string,
  threshold?: number | number[],
): void => {
  useEffect(() => {
    const { current } = ref;
    const observer = new IntersectionObserver(
      ([entry]) => {
        handler(entry);
      },
      { root, rootMargin, threshold },
    );

    if (current) {
      observer.observe(current);
    }

    return (): void => {
      if (current) {
        observer.unobserve(current);
      }
    };
  });
};
