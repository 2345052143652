import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import React, { useCallback } from 'react';
import { reduxStore, updateTask } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';
import { useForceSave } from 'tools/reconciliation/useForceSave';

import { TaskNameInput } from './TaskNameInput';

type TaskNameControllerProps = {
  getAllTaskLabels: (overrides: { title: string }) => string[];
  taskId: Data.Id.TaskId;
  value: string;
};

export const TaskNameController: React.FC<TaskNameControllerProps> = ({
  taskId,
  value,

  getAllTaskLabels,
}: TaskNameControllerProps): JSX.Element => {
  const { whenSuccess, whenError } = useToast();

  const updateName = useCallback(
    async (name: string) => {
      await reduxStore
        .dispatch(
          updateTask({
            id: taskId,
            title: name,
            labels: getAllTaskLabels({ title: name }),
          }),
        )
        .then(unwrapResult)
        .then(whenSuccess(formatI18n(strings.task.titleUpdated)))
        .catch(whenError(formatI18n(strings.task.taskTitleError)));
    },
    [getAllTaskLabels, taskId, whenError, whenSuccess],
  );

  const { trySave } = useForceSave(value, updateName, getKeyForReconciliation);

  return <TaskNameInput placeholder={placeholder} trySave={trySave} value={value} />;
};

const getKeyForReconciliation = (newTitle: string): string => newTitle;

const placeholder = strings.formatString(strings.placeholder.text, {
  field: strings.placeholder.fields.title,
}) as string;
