import { Data } from '@taraai/types';
import { ImportBacklogIndicatorController } from 'components/app/controllers/ImportBacklogIndicatorController';
import RequirementsListElement from 'components/app/controllers/views/RequirementsListElement';
import Icon from 'components/core/controllers/views/Icon';
import { FastSmallSpinner } from 'components/core/controllers/views/Spinners';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

export function AsanaIcon(): JSX.Element {
  return (
    <Icon
      className={css`
        width: 2rem;
        height: 2rem;
        padding: 0;
      `}
      name='asana'
    />
  );
}

function AsanaImportedTasksIndicator({
  taskCount,
  onClick,
}: {
  taskCount: number;
  onClick: () => void;
}): JSX.Element | null {
  if (taskCount === 0) {
    return null;
  }

  return (
    <RequirementsListElement
      description={strings.formatString(strings.importIndicatorInBacklog.taskCount, { taskCount })}
      icon={AsanaIcon}
      label={strings.importIndicatorInBacklog.importedFromAsana}
      onClick={onClick}
    />
  );
}

function AsanaImportInProgressIndicator({
  importDoc,
  onClick,
}: {
  importDoc: Data.Import;
  onClick: () => void;
}): JSX.Element {
  const tasksProgress = `${importDoc.processedItemsCount}/${importDoc.totalItemsCount}`;
  const description = strings.formatString(strings.importIndicatorInBacklog.importingTasks, { tasksProgress });
  return (
    <RequirementsListElement
      description={description}
      icon={AsanaIcon}
      label={strings.importIndicatorInBacklog.importedFromAsana}
      onClick={onClick}
      rightIcon={<FastSmallSpinner />}
    />
  );
}

export function AsanaImportBacklogIndicatorController({
  showImportedTasks,
}: {
  showImportedTasks: () => void;
}): JSX.Element {
  return (
    <ImportBacklogIndicatorController
      importInProgressIndicator={AsanaImportInProgressIndicator}
      service='asana'
      showImportedTasks={showImportedTasks}
      tasksImportedIndicator={AsanaImportedTasksIndicator}
    />
  );
}
