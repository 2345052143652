import { isNonEmptyString } from '@taraai/utility';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'tools/libraries';

export interface InputProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  bottomLabel: string;
  error: boolean;
}

interface Validation {
  isValid: boolean;
  message: string;
}

interface UseFormInputOptions {
  interval?: number;
  initialValid?: boolean;
  validator?: (value: string) => Promise<Validation>;
  debounceValidation?: boolean;
}
export function useFormInput(
  initialData: InputProps['value'] = '',
  {
    interval = 400,
    initialValid = true,
    validator: initialValidator = async (value: string): Promise<Validation> => ({
      isValid: !!value,
      message: '',
    }),
    debounceValidation: shouldDebounce = false,
  }: UseFormInputOptions = {},
): [string, InputProps, boolean, (val: string) => void] {
  // FIXME: use Formik or something estabilished for forms
  const [data, setData] = useState(initialData);
  const [valid, setValid] = useState(initialValid);
  const [bottomLabel, setBottomLabel] = useState('');
  const timeout = useRef<NodeJS.Timeout>();
  const validator = useCallback(
    async (val) => {
      const { isValid, message } = await initialValidator(val);
      setValid(isValid);
      if (isNonEmptyString(message)) setBottomLabel(message);
      else setBottomLabel('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // NEVER ALLOW data INSIDE HERE
  );
  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setValid(initialValid);
      if (timeout.current) clearTimeout(timeout.current);
      const newData = event.target.value;
      setData(newData);
      timeout.current = setTimeout(validator, shouldDebounce ? interval : 0, newData);
    },
    [initialValid, interval, shouldDebounce, validator],
  );
  const inputProps = useMemo(
    () => ({
      value: data,
      onChange: handleChange,
      bottomLabel,
      error: !valid,
    }),
    [bottomLabel, data, handleChange, valid],
  );
  const setNewData = useCallback(
    async (val) => {
      await validator(val);
      setData(val);
    },
    [validator],
  );
  return [data, inputProps, valid, setNewData];
}

interface FirebaseInputOptions {
  interval?: number;
  transformer?: <T>(value: T) => T;
  onChangeHandler?: (event: React.SyntheticEvent<HTMLInputElement>, ...args: unknown[]) => void;
}

export type FirebaseInput<T> = {
  Ref: React.RefObject<HTMLInputElement>;
  defaultValue: T;
  onChange: (event: React.SyntheticEvent) => void;
};

export const useFirebaseInput = <T>(
  data: T,
  latestSetter: unknown,
  /**
   * Optional arguments in one object argument..
   */
  {
    interval = 1000,
    transformer = <Value>(value: Value): Value => value,
    onChangeHandler = (event: React.SyntheticEvent): React.SyntheticEvent => event,
  }: FirebaseInputOptions = {},
): [FirebaseInput<T>, ...unknown[]] => {
  const customOnChange = useCallback(
    (event, ...args) => onChangeHandler(event, ...args),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setter = useRef<unknown>();
  useEffect(() => {
    setter.current = latestSetter;
  }, [latestSetter]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [dirty, setDirty] = useState(false);
  const handleChange = useCallback(
    (event) => {
      setDirty(true);
      customOnChange(event);
    },
    [customOnChange],
  );
  const setNewData = useCallback((val) => {
    if (inputRef.current !== null) {
      if (inputRef.current.type === 'checkbox') inputRef.current.checked = val;
      inputRef.current.value = val;
    }
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDirty = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    debounce<any>(
      (
        currentSetter: (value: string | boolean) => void,
        currentInputRef: { type: string; checked: boolean; value: string },
      ): void => {
        currentSetter(currentInputRef.type === 'checkbox' ? currentInputRef.checked : currentInputRef.value);
        setDirty(false);
      },
      interval,
    ),
    [],
  );
  useEffect(() => {
    if (!dirty) setNewData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setNewData]); // NEVER ALLOW dirty INSIDE HERE because it will mess with the effect cycle
  useEffect(() => {
    if (dirty) handleDirty(setter.current, inputRef.current);
  }, [dirty, handleDirty]);
  return [
    {
      Ref: inputRef,
      defaultValue: data,
      onChange: handleChange,
    },
    transformer(data),
    setNewData,
  ];
};
