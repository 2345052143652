import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { isNonEmptyString, notUndefined } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { RootState } from 'reduxStore/store';
import { createIndividualSelector, IndividualQuery, inertIndividualQuery } from 'reduxStore/utils/selectors';

export const getOrg = (orgID: Data.Id.OrganizationId | undefined): IndividualQuery<UI.UIOrganization> => {
  if (!isNonEmptyString(orgID) || !orgID) {
    return inertIndividualQuery();
  }

  const orgQuery: ReduxFirestoreQuerySetting = {
    collection: 'orgs',
    where: ['__name__', '==', orgID],
    storeAs: `org/${orgID}`,
  };

  return {
    query: [orgQuery],
    selector: createIndividualSelector(orgID, orgQuery),
  };
};

type GetOrgListRes = {
  query: ReduxFirestoreQuerySetting[];
  selectors: {
    all: () => Selector<RootState, UI.UIOrganization[]>;
  };
};

export const getOrgList = (orgIDs: Data.Id.OrganizationId[]): GetOrgListRes => {
  const orgQueries = orgIDs.map(getOrg);

  const combinedQuery = orgQueries.map(({ query }) => query[0]).filter(notUndefined);

  const allSelectors = orgQueries.map((slice) => slice.selector);
  const combinedSelector = createSelector(allSelectors, (...orgDocs) => orgDocs.filter(notUndefined));

  return {
    query: combinedQuery,
    selectors: {
      all: (): Selector<RootState, UI.UIOrganization[]> => combinedSelector,
    },
  };
};
