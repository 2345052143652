import { Data, UI } from '@taraai/types';
import TeamStatsController from 'components/app/controllers/TeamStatsController';
import YourStats from 'components/app/controllers/views/YourStats';
import { linkTo } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { getAssignedTasksFromUserSprints, selectAuth, selectPreferredTeamId } from 'reduxStore';
import { strings } from 'resources/i18n';
import {
  getCompletedEffortFromTasks,
  getCompletedPercentage,
  getTotalEffortFromTasks,
} from 'tools/utils/calculateTaskEffort';

export interface DashboardStatisticsProps {
  orgID: Data.Id.OrganizationId;
  allTeamsWithActiveSprintsOrdered: UI.UITeam[];
  activeSprintIds: Data.Id.SprintId[];
}

/**
 * DashboardStatistics container for current sprint user and team statistics
 *
 */
export default function DashboardStatistics({
  orgID,
  allTeamsWithActiveSprintsOrdered,
  activeSprintIds: sprintIDs,
}: DashboardStatisticsProps): JSX.Element {
  const { uid: userID } = useSelector(selectAuth);
  const teamID = useSelector(selectPreferredTeamId(orgID));
  const preferredTeam = allTeamsWithActiveSprintsOrdered[0];

  const assignedTasksFromUserSprints = getAssignedTasksFromUserSprints(orgID, userID, sprintIDs);

  useFirestoreConnect(assignedTasksFromUserSprints.query);

  const sprintAssignedTasks = useSelector(assignedTasksFromUserSprints.selector) || [];

  const totalEffortAssignedToUser = getTotalEffortFromTasks(sprintAssignedTasks);

  const userTasksCompletedEffort = getCompletedEffortFromTasks(sprintAssignedTasks);

  const userDonePercentage = getCompletedPercentage(userTasksCompletedEffort, totalEffortAssignedToUser);

  const currentSprintPath = linkTo('sprintDetails', {
    orgID,
    teamID,
    sprintID: preferredTeam.currentSprintId ?? '',
  });

  return (
    <div
      className={css`
        border-right: 0.0625rem solid #dee3ec;
        padding: 0rem 0.1563rem 0rem 0.1563rem;
      `}
    >
      <div
        className={css`
          padding: 0.75rem 0.75rem 0.5rem 0.75rem;
        `}
      >
        <Link
          className={css`
            font-size: 1rem;
            font-weight: 600;
            color: #303f4b;
            :hover {
              text-decoration: underline;
            }
          `}
          to={currentSprintPath}
        >
          {strings.dashboard.currentSprint}
        </Link>
      </div>
      <YourStats
        done={userDonePercentage}
        effort={totalEffortAssignedToUser}
        effortCompleted={userTasksCompletedEffort}
      />
      {allTeamsWithActiveSprintsOrdered.map((team, index) => (
        <TeamStatsController key={team.id} isPreferredTeam={index === 0} orgID={orgID} team={team} />
      ))}
    </div>
  );
}
