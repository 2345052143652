import styled from '@emotion/styled';
import { isNonEmptyString } from '@taraai/utility';
import Text from 'components/core/controllers/views/Text';
import React from 'react';

import { Task } from './Task';
import { PreviewProps } from './types';

type SprintProps = {
  className?: string;
};

const SprintTasks = ({ className, taskTitles }: { className?: string; taskTitles?: string[] }): JSX.Element => (
  <>
    {taskTitles?.map((title, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Task key={index} className={className} value={title} />
    ))}
  </>
);

const variantToChildren = {
  blank: {
    Sprint1({ className }: SprintProps): JSX.Element {
      return (
        <>
          <Task className={className} />
          <Task className={className} />
          <Task className={className} />
          <Task className={className} />
        </>
      );
    },
    Sprint2({ className }: SprintProps): JSX.Element {
      return (
        <>
          <Task className={className} />
          <Task className={className} />
        </>
      );
    },
  },
  namedTasks: {
    Sprint1({ taskTitles, ...props }: Pick<PreviewProps, 'taskTitles'> & SprintProps): JSX.Element {
      return <SprintTasks taskTitles={taskTitles?.slice(0, 2)} {...props} />;
    },
    Sprint2({ taskTitles, ...props }: Pick<PreviewProps, 'taskTitles'> & SprintProps): JSX.Element {
      return <SprintTasks taskTitles={taskTitles?.slice(2)} {...props} />;
    },
  },
};

interface Props extends Pick<PreviewProps, 'taskTitles'> {
  className?: string;
  sprintClassName?: string;
  variant: keyof typeof variantToChildren;
}

export const SprintsRow: React.FC<Props> = ({ className, sprintClassName, taskTitles, variant }) => {
  const { Sprint1, Sprint2 } = variantToChildren[variant];
  const nonEmptyTaskTitles = taskTitles?.filter(isNonEmptyString);
  return (
    <Wrapper className={className}>
      <Sprint>
        <SprintName>Sprint 1</SprintName>
        <TasksOutline>
          <Sprint1 className={sprintClassName} taskTitles={nonEmptyTaskTitles} />
        </TasksOutline>
      </Sprint>
      <Sprint>
        <SprintName>Sprint 2</SprintName>
        <TasksOutline>
          <Sprint2 className={sprintClassName} taskTitles={nonEmptyTaskTitles} />
        </TasksOutline>
      </Sprint>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  padding: 1rem 0 0 0.5rem;
  background-color: #fbfbfd;
`;

const Sprint = styled.div`
  flex-shrink: 0;
  width: 18.5rem;
  height: 110%;
  margin: 0 0.5rem;
  padding: 1.25rem 0.75rem 0;
  border-radius: 0.25rem;
  border: solid 0.0625rem #eaeef5;
  background-color: #ffffff;
`;

const TasksOutline = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 0.875rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: solid 1px #eaeef5;
  background-color: #f4f4fa;
`;

const SprintName = styled((props) => <Text {...props} />)`
  margin-left: 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  color: #303f4b;
`;
