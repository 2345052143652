import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { strings } from 'resources/i18n';

export type FilterByStatus = 0 | 1 | 2;

interface SprintFilterCardProps {
  assignee?: string;
  statusFilter?: FilterByStatus;
}

export function SprintFilterCard({ assignee, statusFilter }: SprintFilterCardProps): JSX.Element {
  const isOnlyAssigneeFilter = !!assignee && !statusFilter;
  const multiFilterText = statusFilter && assignee && strings.sprints.sprintColumn.twoFiltersApplied;
  const singleFilterText =
    (statusFilter && strings.sprints.sprintColumn.simpleLabels[statusFilter]) ??
    (assignee && strings.formatString(strings.sprints.sprintColumn.filteredByAssignee, { assignee }));

  return (
    <Container assignee={isOnlyAssigneeFilter} status={statusFilter}>
      <FlexBoxWrapper>
        <FilterIcon name='filterBars' />
        <FilterLabel>{multiFilterText ?? singleFilterText}</FilterLabel>
      </FlexBoxWrapper>
      <ResetButtonContainer>
        <ResetButtonText assignee={isOnlyAssigneeFilter} status={statusFilter}>
          {strings.menuFilter.reset}
        </ResetButtonText>
      </ResetButtonContainer>
    </Container>
  );
}

const Container = styled(
  'div',
  {
    display: 'flex',
    maxWidth: '17.5rem',
    border: '0.0625rem solid #DEE3EC',
    borderRadius: '0.25rem',
    padding: '0.5rem 0.75rem',
    justifyContent: 'space-between',
    margin: '0.3125rem',
  },
  {
    status: {
      0: {
        backgroundColor: '#C18700',
      },
      1: {
        backgroundColor: '#1D98FF',
      },
      2: {
        backgroundColor: '#389E0D',
      },
    },
    assignee: {
      true: {
        backgroundColor: '#67728B',
      },
    },
  },
);

const FlexBoxWrapper = styled('div', { display: 'flex', alignItems: 'center' });

const ResetButtonContainer = styled('div', {
  padding: '0.1875rem 0.3125rem 0.25rem 0.3125rem',
  backgroundColor: '#FFFFFF',
  borderRadius: '0.125rem',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

const ResetButtonText = styled(
  'div',
  {
    fontWeight: '600',
    fontSize: '10px',
  },
  {
    status: {
      0: {
        color: '#C18700',
      },
      1: {
        color: '#1D98FF',
      },
      2: {
        color: '#389E0D',
      },
    },
    assignee: {
      true: {
        color: '#67728B',
      },
    },
  },
);

const FilterLabel = styled('div', {
  color: '#FFFFFF',
  fontWeight: '600',
  fontSize: '0.625rem',
  paddingLeft: '0.3125rem',
});
const FilterIcon = styled(Icon, { padding: '0rem', width: '0.625rem', height: '0.625rem' });
