import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import { UserSelector } from 'components/app/controllers/Selectors/UserSelector';
import { AddTeamMemberCell } from 'components/app/controllers/views/AddTeamMemberCell';
import { SectionType, SelectButtonRenderProps } from 'components/core/controllers/Selector';
import TableCell from 'components/core/controllers/views/TableCell';
import TableRow from 'components/core/controllers/views/TableRow';
import React, { FunctionComponent, useCallback } from 'react';
import { strings } from 'resources/i18n';

type UserFragment = Pick<UI.UIUser, 'id' | 'name'>;

interface Props {
  joinTeam: (userId: UserFragment) => void;
  leaveTeam: (userId: UserFragment) => void;
  options: UserFragment[];
  selection: UserFragment[];
  teamName: string;
}

/**
 * AddTeamMemberRow
 * ui component for top row of table that will be used for adding users
 *
 */
export function AddTeamMemberRow({ joinTeam, leaveTeam, options, selection, teamName }: Props): JSX.Element {
  const section: SectionType<UserFragment> = { id: 'users', options };
  const renderSelectButton = useCallback(
    ({ openPopup }: SelectButtonRenderProps) => <AddTeamMemberCell onClick={openPopup} onKeyDown={openPopup} />,
    [],
  );
  const Wrapper = useCallback<FunctionComponent<{ 'data-cy'?: string | undefined }>>(
    ({ children, 'data-cy': dataCy }) => (
      <TableRow data-cy={dataCy}>
        <AddMemberTableCell columnSpan={2}>{children}</AddMemberTableCell>
      </TableRow>
    ),
    [],
  );
  return (
    <UserSelector
      deselectUser={leaveTeam}
      headerTitle={
        strings.formatString(strings.dropdown.header.addMembersToTeam, {
          teamName,
        }) as string
      }
      renderSelectButton={renderSelectButton}
      searchPlaceholder={strings.dropdown.placeholder.searchUsers}
      sections={[section]}
      selection={selection}
      selectUser={joinTeam}
      Wrapper={Wrapper}
    />
  );
}

const AddMemberTableCell = styled(TableCell, {
  padding: 0,
});
