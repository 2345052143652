import { Schema, TypescriptDecoder } from '@taraai/types';
import Ajv from 'ajv';

import Schemas from './schemas';

type DecoderFn = <T>(data: unknown, taraType: keyof Schema.TaraValidation, options?: Ajv.Options) => T;

type DecoderChainFn = <T>(data: unknown, taraType: keyof Schema.TaraValidation, options?: Ajv.Options) => Promise<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
TypescriptDecoder.setSchemas(Schemas as any);

export const {
  decode,
  decodeChain,
  CREATION_DEFAULTS,
}: {
  decode: DecoderFn;
  decodeChain: DecoderChainFn;
  CREATION_DEFAULTS: Ajv.Options;
} = TypescriptDecoder;

export default TypescriptDecoder.default;
