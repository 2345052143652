import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import SprintColumnController from 'components/app/controllers/SprintColumnController/SprintColumnController';
import { ScrollPane } from 'components/app/layouts/ScrollPane/ScrollPane';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

type SprintFragment = Pick<
  UI.UISprint,
  'sprintName' | 'initialStartDate' | 'initialEndDate' | 'isComplete' | 'id' | 'computedOnCompletion'
>;

type SprintColumnsProps = {
  sprints: SprintFragment[] | undefined;
  currentSprintId: string;
  selectedSprintId: string;
  onSelectNextSprint: () => void;
  onSelectPrevSprint: () => void;
  onSelectCurrentSprint: () => void;
};

const getOpacityFromOffset = (offset: number): number => {
  switch (offset) {
    case 0:
      return 1;
    case 1:
      return 0.65;
    default:
      return 0.2;
  }
};

export default function SprintColumnsLayout({
  sprints,
  selectedSprintId,
  currentSprintId,
  onSelectNextSprint,
  onSelectPrevSprint,
  onSelectCurrentSprint,
}: SprintColumnsProps): JSX.Element {
  const selectedSprintIdx = sprints?.findIndex((sprint) => sprint.id === selectedSprintId);
  const isFirstSelected = selectedSprintIdx === 0;
  const isLastSelected = sprints ? selectedSprintIdx === sprints.length - 1 : true;

  return (
    <Wrapper>
      <ControlsRow>
        <SprintNavigation>
          <ButtonArrow disabled={isFirstSelected} name='previousButton' onClick={onSelectPrevSprint}>
            <Icon
              className={css`
                height: 9px;
                width: 8px;
                padding: 0px;
              `}
              name='leftTriangle'
            />
          </ButtonArrow>
          <ButtonCurrent
            disabled={selectedSprintId === currentSprintId}
            name='currentButton'
            onClick={onSelectCurrentSprint}
          >
            {strings.sprints.sprintColumn.current}
          </ButtonCurrent>
          <ButtonArrow disabled={isLastSelected} name='nextButton' onClick={onSelectNextSprint}>
            <Icon
              className={css`
                height: 9px;
                width: 8px;
                padding: 0px;
              `}
              name='rightTriangle'
            />
          </ButtonArrow>
        </SprintNavigation>
      </ControlsRow>
      <ScrollPane
        anchorLeft={144}
        items={sprints ?? []}
        renderItem={(sprint, ref, offsetFromSelected) => {
          const opacity = getOpacityFromOffset(offsetFromSelected);
          return (
            <SprintColumnWrapper
              key={sprint.id}
              ref={ref}
              selected={sprint.id === selectedSprintId}
              style={{ opacity }}
            >
              <SprintColumnController currentSprintId={currentSprintId} sprint={sprint} />
            </SprintColumnWrapper>
          );
        }}
        selectedId={selectedSprintId}
      />
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  background: '#f4f4fa',
  height: '100%',
});

const ButtonCurrent = styled('button', {
  'background': '#ffffff',
  'borderRadius': '2px',
  'padding': '2px 6px 0px 6px',
  'border': '0px',
  'margin': '0px 4px',
  'height': '16px',
  'fontSize': '10px',
  'cursor': 'pointer',
  'color': '#67728b',
  'outline': 'none',

  '&:disabled': {
    color: '#c8d0df',
  },
});

const ButtonArrow = styled('button', {
  background: '#ffffff',
  borderRadius: '2px',
  border: '0px',
  width: '16px',
  height: '16px',
  cursor: 'pointer',
  outline: 'none',
});

const SprintNavigation = styled('div', {
  position: 'absolute',
  left: '144px',
  width: '288px',
  display: 'flex',
  justifyContent: 'center',
});

const ControlsRow = styled('div', {
  zIndex: '999',
  position: 'absolute',
  left: '0',
  right: '0',
  height: '32px',
  padding: '8px',
  fontSize: '10px',
  lineHeight: '135%',
});

const SprintColumnWrapper = styled(
  'div',
  {
    paddingTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: '1',
    transition: 'background 0.5s linear, opacity 0.5s linear',
  },
  {
    selected: {
      true: { background: 'linear-gradient(180deg, rgba(78, 98, 146, 0.05) 52.28%, rgba(78, 98, 146, 0) 100%)' },
      false: { background: 'rgba(78, 98, 146, 0)' },
    },
  },
);
