import { useCallback, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';

/**
 * "Force save" content reconciliation scheme.
 *
 * Basic premise of this scheme is to do 3 things:
 *
 * 1) Detect that other user is simultaneously editing the same content,
 * 2) Prevent the user from saving (because that would overwrite changes that are on the server),
 * 3) Allow the user to force the save and overwrite the changes on the server.
 *
 * It is accomplished by creating a proxy function `trySave` that wraps original `save` function.
 *
 * It tracks two different versions of the content: _local_ and _remote_.
 *
 * It stores two sync points: _the latest remote version_ and _the last remote version that was the same
 * as local one_.
 *
 * Each time we try to save we compare these two sync points and if they differ we block the save
 * and indicate that by setting `isConflict` to true.
 * We also store parameters to that `trySave` call, so that we can repeat that call if the user decides to
 * force save their changes.
 *
 * @param currentRemote Current value for remote key
 * @param save Save handler
 * @param getKey A getter that selects from arguments to `save` function a key
 * that will be used later in reconciliation process.
 */
export function useForceSave<T, Args extends unknown[]>(
  currentRemote: T,
  save: (...args: Args) => Promise<void>,
  getKey: (...args: Args) => T,
): {
  trySave: (...args: Args) => Promise<void>;
  isConflict: boolean;
  forceSave: () => Promise<void>;
} {
  const [isConflict, setIsConflict] = useState(false);

  // used to store last trySave call args, so that we can
  // repeat that call if user decides they want to force save
  const deferredSaveArgs = useRef<Args | null>(null);

  // current state of the remote
  const latestRemoteKey = useRef(currentRemote);
  latestRemoteKey.current = currentRemote;

  // last state when local and remote were synced
  const lastSyncedKey = useRef(currentRemote);

  // saves and synchronizes local with remote
  const doSave = useCallback(
    async (...args: Args): Promise<void> => {
      const key = getKey(...args);

      // sync local and remote
      lastSyncedKey.current = key;
      latestRemoteKey.current = key;

      setIsConflict(false);
      await save(...args);
    },
    [getKey, save],
  );

  return {
    trySave: useCallback(
      async (...args) => {
        // check if remote is in sync with local
        const remoteInSync = deepEquals(lastSyncedKey.current, latestRemoteKey.current);
        if (remoteInSync) {
          return doSave(...args);
        }
        // defer args for possible force-save
        deferredSaveArgs.current = args;
        setIsConflict(true);
        throw new Error('Cannot save, conflict detected');
      },
      [doSave],
    ),
    forceSave: useCallback(async () => {
      if (deferredSaveArgs.current) {
        doSave(...deferredSaveArgs.current);
      }
    }, [doSave]),
    isConflict,
  };
}
