/* eslint-disable id-length */
import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';

export const defaultLabels: Record<Data.DefaultLabelId, Data.DefaultLabel> = {
  P1: { title: 'P1', color: '#e86262', description: 'High priority', isDefault: true },
  P2: { title: 'P2', color: '#dba41e', description: 'Medium priority', isDefault: true },
  P3: { title: 'P3', color: '#67728b', description: 'Low priority', isDefault: true },
  bug: { title: 'bug', color: '#e86262', isDefault: true },
  enhancement: { title: 'enhancement', color: '#67728b', isDefault: true },
  feature: { title: 'feature', color: '#67728b', isDefault: true },
  sev1: { title: 'sev1', color: '#e86262', description: 'High severity', isDefault: true },
  sev2: { title: 'sev2', color: '#dba41e', description: 'Medium severity', isDefault: true },
  sev3: { title: 'sev3', color: '#67728b', description: 'Low severity', isDefault: true },
};

/**
 * For now, we are hardcoding the default labels.
 * We are placing this data in a selector just
 * in case we want to move it to the backend
 * in the future. That way, we would only
 * have to change the implmentation of
 * this selector.
 *
 * @param id The id of the default label that
 * you want to select.
 */
export const selectDefaultLabel = (id: Data.DefaultLabelId): UI.UIDefaultLabel => ({ id, ...defaultLabels[id] });

export const selectLabel = (id: string): UI.UILabel =>
  id in defaultLabels ? selectDefaultLabel(id as Data.DefaultLabelId) : ({ id, title: id } as UI.UICustomLabel);

export function getCustomLabels(orgID: Data.Id.OrganizationId): Query<UI.UICustomLabel> {
  if (!isNonEmptyString(orgID)) {
    return inertQuery();
  }

  const query: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/labels`,
  };

  return {
    query: [query],
    selector: createStandardSelector(query),
  };
}

export function isDefaultLabel(label: UI.UILabel): label is UI.UIDefaultLabel {
  return ((label as unknown) as { isDefault?: unknown }).isDefault === true;
}
