import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { IconName } from 'resources';

import { NavigationIcon } from './NavigationIcon';
import { NavigationItem } from './NavigationItem';

type NavigationLinkProps = {
  /** Title displayed in tooltip next to the link */
  title: string;
  icon: IconName;
  /** Path to navigate to upon click */
  to: string;
  target?: string;
  external?: boolean;
  /** Path that route should match on (same as `to` by default) */
  matchOn?: string;
  dataCy?: string;
};

export const NavigationLink: React.FC<NavigationLinkProps> = ({
  title,
  icon,
  external = false,
  to,
  target,
  dataCy,
  matchOn = to,
}: NavigationLinkProps): JSX.Element => {
  const matched = useRouteMatch(matchOn);
  const active = (matched && matched.isExact) ?? false;

  // if link is external use anchor, otherwise use react-router NavLink
  return (
    <NavigationItem active={active} data-cy={dataCy} title={title}>
      {external ? (
        <a data-cy={dataCy} href={to} target={target}>
          <NavigationIcon data-cy={dataCy} name={icon} />
        </a>
      ) : (
        <Link data-cy={dataCy} target={target} to={to}>
          <NavigationIcon data-cy={dataCy} name={icon} />
        </Link>
      )}
    </NavigationItem>
  );
};
