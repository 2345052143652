import { createAsyncThunk } from '@reduxjs/toolkit';
import Tara, { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { FirebaseThunkAPI } from 'reduxStore/utils/types';
import { strings } from 'resources';

export const deleteRequirement = createAsyncThunk<
  Pick<UI.UIRequirement, 'id'>,
  Pick<UI.UIRequirement, 'id'>,
  FirebaseThunkAPI
>('DeleteRequirement', async ({ id: requirementID }, { extra: { getFirestore, getOrgID } }) => {
  const orgID = getOrgID();

  if (!isNonEmptyString(requirementID)) throw strings.requirements.missingCredentials;

  await getFirestore().update<Tara.Requirement>(`orgs/${orgID}/requirements/${requirementID}`, {
    deleted: true,
  });
  return { id: requirementID };
});
