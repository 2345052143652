import { compose } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { Selector } from 'react-redux';
import { RootState, RootStateWithProfile } from 'reduxStore/store';
import { Profile } from 'reduxStore/utils';
import { sort } from 'tools/libraries/helpers/sort';

import { getUsers } from './queries';

export const isUserActiveIn = (user: Profile) => (orgId: string): boolean => {
  const access = userAccessLevel(user, orgId);
  return typeof access !== 'undefined' && access !== 'deactivated';
};

export const selectIsProfileActiveIn = (orgId?: string) => (state: RootStateWithProfile): boolean =>
  typeof orgId !== 'undefined' && isUserActiveIn(selectProfile(state))(orgId);

export const isProfileAdmin = (orgId: string) => (state: RootStateWithProfile): boolean =>
  userAccessLevel(selectProfile(state), orgId) === 'admin';

export const isProfileDeactivated = (orgId: string): Selector<RootStateWithProfile, boolean> => (
  state: RootStateWithProfile,
): boolean => userAccessLevel(selectProfile(state), orgId) === 'deactivated';

const userAccessLevel = (user: Profile, orgId: string): Data.AccessLevel | undefined =>
  user.token?.claims?.orgs?.[orgId]?.accessLevel;

const activeOrgIds = (user: Profile): string[] =>
  Object.keys(user.token?.claims?.orgs ?? {}).filter(isUserActiveIn(user));

export const selectProfile: Selector<RootStateWithProfile, Profile> = (state: RootStateWithProfile): Profile =>
  state.firebase.profile;

export const selectActiveOrgIds = compose(activeOrgIds, selectProfile);

const filterTeamMembers = (orgId: Data.Id.OrganizationId, teamId: Data.Id.TeamId, users: UI.UIUser[]): UI.UIUser[] =>
  users.filter((user) => user.teamIds[orgId]?.includes(teamId));

const filterActiveUsers = (orgId: Data.Id.OrganizationId, users: UI.UIUser[]): UI.UIUser[] =>
  users.filter((user) => user.accessLevels?.[orgId] && user.accessLevels[orgId] !== 'deactivated');

/**
 * Select non-deactivated team members sorted by name
 */
export const selectTeamMembers = (
  orgId: Data.Id.OrganizationId,
  teamId: Data.Id.TeamId,
): Selector<RootState, UI.UIUser[]> =>
  compose(
    (users: UI.UIUser[]) => sort(users, 'name'),
    (users: UI.UIUser[]) => filterTeamMembers(orgId, teamId, users),
    (users) => filterActiveUsers(orgId, users ?? []),
    getUsers(orgId).selector,
  );

/**
 * Select non-deactivated users
 */
export const selectActiveUsers = (orgId: Data.Id.OrganizationId): Selector<RootState, UI.UIUser[]> =>
  compose((users) => filterActiveUsers(orgId, users ?? []), getUsers(orgId).selector);
