import { styled } from '@taraai/design-system';
import { MaybeReusableLink } from 'components/app/controllers/InviteUsersController';
import Button from 'components/core/controllers/views/Button';
import StandardSpinner from 'components/core/controllers/views/Spinners/StandardSpinner';
import React, { useRef } from 'react';
import { alert as TaraIconInfo, tick as TaraIconTick } from 'resources/assets/icons/tara';
import { sendInvitePopupTestIDs } from 'resources/cypress/testAttributesValues';
import strings from 'resources/i18n';
import { atomic } from 'resources/theme/atomic';
import { useClickOutside } from 'tools';

import { InviteLinkSection } from './InviteLinkSection';

export type Props = {
  emailInput: JSX.Element;
  windowTitle: string;
  inviteSent: boolean;
  disableSubmit: boolean;
  loadingSubmit: boolean;
  loadingInvitationLink: boolean;
  createInviteLink: () => void;
  deactivateInviteLink: () => void;
  onSubmit: () => void;
  onClose: () => void;
  errorsPresent: boolean;
  maybeReusableLink?: MaybeReusableLink;
};

export const InviteUsersPopup: React.FC<Props> = ({
  emailInput,
  windowTitle,
  inviteSent,
  disableSubmit,
  loadingSubmit,
  maybeReusableLink,
  errorsPresent,
  loadingInvitationLink,
  createInviteLink,
  deactivateInviteLink,
  onSubmit,
  onClose,
}: Props) => {
  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, onClose);

  return (
    <Popup ref={popupRef}>
      <WindowContainer>
        <WindowTitle>{windowTitle}</WindowTitle>
      </WindowContainer>
      <Label>
        <SubLabel>{strings.inviteUserPopup.link.header}</SubLabel>
        {maybeReusableLink ? (
          <SubLabelButton
            disabled={loadingInvitationLink}
            loading={loadingInvitationLink}
            onClick={deactivateInviteLink}
          >
            {strings.inviteUserPopup.link.deactivate}
          </SubLabelButton>
        ) : (
          <SubLabel variant='grey'>{strings.inviteUserPopup.link.expirationNote}</SubLabel>
        )}
      </Label>
      <InviteLinkSection
        createInviteLink={createInviteLink}
        loadingInvitationLink={loadingInvitationLink}
        maybeReusableLink={maybeReusableLink}
      />
      <Label>{strings.inviteUserPopup.email.header}</Label>
      <EmailInput>{emailInput}</EmailInput>
      {!inviteSent && !errorsPresent && (
        <SubmitButton
          buttonDisabled={disableSubmit}
          data-cy={sendInvitePopupTestIDs.SEND_INVITE_BUTTON}
          disabled={disableSubmit}
          name={
            errorsPresent ? strings.inviteUserPopup.submit.errorMessage : strings.inviteUserPopup.submit.buttonLabel
          }
          onClick={(): void => {
            if (!disableSubmit) {
              onSubmit();
            }
          }}
          size='small'
        >
          {loadingSubmit ? <StandardSpinner size='small' /> : strings.inviteUserPopup.submit.buttonLabel}
        </SubmitButton>
      )}
      {inviteSent && (
        <SuccessContainer>
          <TickIcon fill={atomic.getValue(atomic.colors.success)} />
          {strings.inviteUserPopup.submit.successMessage}
        </SuccessContainer>
      )}
      {errorsPresent && (
        <ErrorContainer>
          <InfoIcon />
          {strings.inviteUserPopup.submit.errorMessage}
          <ContactSupport>{strings.inviteUserPopup.submit.contactSupport}</ContactSupport>
        </ErrorContainer>
      )}
    </Popup>
  );
};

const Popup = styled('div', {
  width: '21rem',
  borderRadius: '0.1875rem',
  boxShadow: '$popup',
  border: 'borderWidths.$1px solid colors.$grey5',
  backgroundColor: '$grey1',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  zIndex: '100',

  textarea: {
    backgroundColor: 'white',
  },
});

const EmailInput = styled('div', { margin: '1rem', marginTop: '0rem' });

const WindowContainer = styled('div', {
  borderRadius: '0.1875rem 0.1875rem 0rem 0rem',
  backgroundColor: 'white',
});

const WindowTitle = styled('h1', {
  margin: '0',
  fontSize: '0.875rem',
  fontWeight: '600',
  color: '$dark',
  padding: '1rem',
});

const Label = styled('span', {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '0.875rem',
  lineHeight: '1',
  letterSpacing: 'normal',
  color: '$dark',
  padding: '1rem',
  marginTop: '0rem',
  borderTop: 'solid 0.0625rem colors.$grey3',
});

const SubLabel = styled('span', {}, { variant: { grey: { color: '$grey6' } } });

const SubLabelButton = styled(
  'button',
  {
    fontSize: '14px',
    lineHeight: '1',
    padding: '0',

    background: 'none',
    border: 'none',
    color: '$focus',
    outlineColor: '$focus',
    cursor: 'pointer',
  },
  {
    loading: {
      true: {
        cursor: 'progress',
        opacity: 0.65,
      },
      false: {
        ':hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
);

const SubmitButton = styled(
  Button,
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '1rem',
    marginTop: '0rem',
    padding: '0.6rem',
  },
  {
    buttonDisabled: {
      true: {
        'backgroundColor': '$grey5',
        'color': 'white',
        ':hover': { backgroundColor: '$grey5' },
      },
      false: {
        'backgroundColor': '#389E0D',
        'color': 'white',
        ':hover': { backgroundColor: '#389E0D' },
      },
    },
  },
);

const SuccessContainer = styled('div', {
  fontSize: '0.75rem',
  color: '$success',
  backgroundColor: '$successLight',
  margin: '1rem',
  marginTop: '0rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '4.25rem',
  minHeight: '2rem',
});

const ErrorContainer = styled('div', {
  fontSize: '0.75rem',
  color: '$failure',
  backgroundColor: '$beige',
  margin: '1rem',
  marginTop: '0rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '4.25rem',
  minHeight: '2rem',
});

const TickIcon = styled(TaraIconTick, {
  width: '1rem',
  height: '1rem',
  marginRight: '0.5rem',
});

const InfoIcon = styled(TaraIconInfo, {
  width: '1rem',
  height: '1rem',
  marginRight: '0.5rem',
});

const ContactSupport = styled('span', {
  fontSize: '0.75rem',
  fontWeight: '600',
  color: '$failure',
  paddingBottom: '0.0625rem',
  paddingLeft: '0.0625rem',
});
