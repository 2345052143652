import { PluginFunctions, RichEditorPlugin } from 'components/editor/types';
import { EditorState } from 'draft-js';
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import { KeyboardEvent } from 'react';

import { handleKeyCommand } from './handleKeyCommand';
import { keyBindingFn } from './keyBindingFn';

const { handleReturn } = createBlockBreakoutPlugin();

export const createDefaultPlugin = (options: { saveOnReturn?: boolean }): RichEditorPlugin => ({
  handleReturn: options.saveOnReturn ? handleSaveOnReturn : handleReturn,
  handleKeyCommand,
  keyBindingFn,
});

const handleSaveOnReturn: RichEditorPlugin['handleReturn'] = (
  event: KeyboardEvent,
  editorState: EditorState,
  { save }: PluginFunctions,
) => {
  if (event.key !== 'Enter') return 'not-handled';
  save(editorState.getCurrentContent());
  return 'handled';
};
