import { Data, UI } from '@taraai/types';
import DropdownOption from 'components/app/controllers/views/DropdownOption';
import { css, cx } from 'emotion';
import React from 'react';

export interface DropdownBodyProps extends React.HTMLProps<HTMLDivElement> {
  options: Array<UI.UIRequirement | UI.UIUser>;
  onOptionClick: (element: React.BaseSyntheticEvent) => void;
  selectedIds: Data.Id.UserId[] | Data.Id.RequirementId[];
  typeaheadResults: Array<UI.UIRequirement | UI.UIUser>;
  activeResult: number;
  handleInputOnKeyDown: (event: React.SyntheticEvent) => void;
}

/**
 * DropdownBody
 * selectable list for dropdown
 *
 */
export default function DropdownBody({
  className,
  options,
  onOptionClick,
  selectedIds,
  typeaheadResults,
  handleInputOnKeyDown,
  activeResult,
  ...props
}: DropdownBodyProps): JSX.Element {
  return (
    <div
      className={cx(
        css`
          margin: 0.5rem 0rem;
        `,
        className,
      )}
      {...props}
    >
      {options.length > 0 &&
        typeaheadResults.map((option) => {
          const exists = selectedIds.includes(option.id);
          const highlighted = typeaheadResults[activeResult]?.id === option.id;

          return (
            <DropdownOption
              key={option.id}
              handleInputOnKeyDown={handleInputOnKeyDown}
              highlighted={highlighted}
              isSelected={exists}
              onOptionClick={onOptionClick}
              option={option}
            />
          );
        })}
    </div>
  );
}
