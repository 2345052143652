import { EditorState } from 'draft-js';

import { EditorStateTransform } from './types';

/**
 * Enhances an editor state trasformer with ability to preserve
 * inline style override and selection state after transform.
 */
export const preserveVolatileState = (
  transformer: EditorStateTransform,
  afterSelectionTransformer?: EditorStateTransform,
): EditorStateTransform => {
  return (state: EditorState): EditorState => {
    const currentInlineStyleOverride = state.getInlineStyleOverride();
    const currentSelection = state.getSelection();
    // RichUtils transforms result in a loss of focus, so we have to restore it
    const stateWithTransform = transformer(state);
    // EditorState.forceSelection resets inlineStyleOverride, so we have to restore it
    const stateWithSelection = EditorState.forceSelection(stateWithTransform, currentSelection);
    const stateWithInlineStyleOverride = EditorState.setInlineStyleOverride(
      stateWithSelection,
      currentInlineStyleOverride,
    );
    return afterSelectionTransformer?.(stateWithInlineStyleOverride) ?? stateWithInlineStyleOverride;
  };
};
