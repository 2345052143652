import { createSelector } from '@reduxjs/toolkit';
import { Identifiable, Timestamp } from '@taraai/types';
import { notUndefined } from '@taraai/utility';
import uniqBy from 'lodash.uniqby';
import { useCallback, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Query } from 'reduxStore';
import { sort } from 'tools/libraries/helpers/sort';

type UsePaginationHookReturn<T extends Identifiable> = {
  items: T[] | undefined;
  loadNextPage: () => void;
  loadPrevPage: () => void;
};

const getPageSliceKey = (config: {
  pageSize: number;
  beforeLimit: number;
  afterLimit: number;
  pivotEndDate: Timestamp;
}): string => {
  return JSON.stringify(config);
};

/**
 * This hook enables us to paginate data using page slices
 * @param pageSize
 * @param initialPivotEndDate
 * @param getPageSlice
 */
export const usePagination = <T extends Identifiable & { initialEndDate: Timestamp | null }>(
  pageSize: number,
  initialPivotEndDate: Timestamp,
  getPageSlice: (before: number, after: number, pivotEndDate: Timestamp) => Query<T>,
): UsePaginationHookReturn<T> => {
  // TODO: add initial values as a config for the hook (instead of assuming selected in the middle)
  const [beforeLimit, setBeforeLimit] = useState(pageSize / 2);
  const [afterLimit, setAfterLimit] = useState(pageSize / 2 + 1);
  const [pivotEndDate, setPivotEndDate] = useState(initialPivotEndDate);

  // store all slices to be able to display all of the loaded pages
  const sliceMap = useRef<Map<string, Query<T>>>(new Map());

  const pageSlice = getPageSlice(beforeLimit, afterLimit, pivotEndDate);

  const pageSliceKey = getPageSliceKey({ pageSize, beforeLimit, afterLimit, pivotEndDate });
  sliceMap.current.set(pageSliceKey, pageSlice);

  // retrieve all loaded page slices
  const allPageSlices = Array.from(sliceMap.current.values());

  const pageQueries = allPageSlices.flatMap((slice) => slice.query);
  useFirestoreConnect(pageQueries);

  const itemsSelector = createSelector(
    allPageSlices.map((slice) => slice.selector),
    (...allPageSlice) => {
      const loadedItems = allPageSlice.filter(notUndefined).flat();
      // FIXME: this might be improved by ensuring that pages don't overlap
      return uniqBy(sort(loadedItems, 'initialEndDate'), 'id');
    },
  );
  const items = useSelector(itemsSelector, deepEquals);

  const loadPrevPage = useCallback((): void => {
    if (isLoaded(items) && items.length > 0) {
      const firstLoadedItem = items[0];
      // if firstLoadedItem initialEndDate is the same as pivotEndDate
      // it means that there are no items before
      const isFirst = firstLoadedItem.initialEndDate === pivotEndDate;
      if (!isFirst) {
        setBeforeLimit(pageSize);
        setAfterLimit(0);
        // With Auto-Sprints this will always be there
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setPivotEndDate(firstLoadedItem.initialEndDate!);
      }
    }
  }, [items, pivotEndDate, pageSize]);

  const loadNextPage = useCallback((): void => {
    if (isLoaded(items) && items.length > 0) {
      const lastLoadedItem = items[items.length - 1];
      // if lastLoadedItem initialEndDate is the same as pivotEndDate
      // it means that there are no more items
      const isLast = lastLoadedItem.initialEndDate === pivotEndDate;
      if (!isLast) {
        setBeforeLimit(0);
        setAfterLimit(pageSize);
        // With Auto-Sprints this will always be there
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setPivotEndDate(lastLoadedItem.initialEndDate!);
      }
    }
  }, [items, pivotEndDate, pageSize]);

  return {
    items,
    loadPrevPage,
    loadNextPage,
  };
};
