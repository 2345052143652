import { Data } from '@taraai/types';
import Text from 'components/core/controllers/views/Text';
import { linkTo, usePathParams } from 'components/Router/paths';
import { css } from 'emotion';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SprintDetailsTimeline } from 'resources/assets';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export default function SprintDetailsNoActiveSprints(): JSX.Element {
  const { orgID } = usePathParams('sprintDetails');
  const { teamID } = useParams<{ teamID: Data.Id.TeamId }>();

  const history = useHistory();

  return (
    <div
      className={css`
        margin-top: 1.25rem;
        width: 100%;
        position: relative;
      `}
    >
      <img
        alt={strings.sprints.noActiveSprints.message}
        className={css`
          width: 100%;
        `}
        src={SprintDetailsTimeline}
      />
      <div
        className={css`
          color: ${atomic.get(atomic.colors.greySlate)};
          left: 50%;
          line-height: 1.8rem;
          position: absolute;
          text-align: center;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        `}
      >
        <Text
          className={css`
            margin-top: 1rem;
            font-size: 1.3rem;
            font-weight: 500;
          `}
        >
          {strings.sprints.noActiveSprints.message}
        </Text>
        <br />
        <Text
          className={css`
            font-size: 0.9rem;
            font-weight: 500;
          `}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={css`
              color: #1d98ff;
              &:hover {
                cursor: pointer;
              }
            `}
            href='#'
            onClick={(): void => history.push(linkTo('sprints', { orgID, teamID }))}
          >
            {strings.sprints.noActiveSprints.startASprint}
          </a>
          {strings.sprints.noActiveSprints.toViewInsights}
        </Text>
      </div>
    </div>
  );
}
