import { UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createStandardSelector, inertQuery, Query } from 'reduxStore/utils/selectors';

export function getOpenPullRequests(orgId: string): Query<UI.UIPullRequest> {
  if (!isNonEmptyString(orgId)) {
    return inertQuery();
  }
  const query: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgId}/pull-requests`,
    where: [['state', '==', 'open']],
    orderBy: [['createdAt', 'asc']],
    storeAs: `open-${orgId}-pull-requests`,
  };

  return {
    query: [query],
    selector: createStandardSelector(query),
  };
}
