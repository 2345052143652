import { createSlice } from '@reduxjs/toolkit';
import { UI } from '@taraai/types';
import { Async, pending } from 'reduxStore/utils';

import { getOrgFromInvitation } from './actions/getOrgFromInvitation';

const slice = createSlice({
  name: 'organizationInvitation',
  initialState: {} as {
    [invitationToken: string]: Async<UI.OrganizationInvitation>;
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getOrgFromInvitation.fulfilled,
      (
        state,
        {
          payload: { organizationId, organizationName, organizationLogoURL, inviterName, color },
          meta: {
            arg: { token },
          },
        },
      ) => ({
        ...state,
        [token]: {
          organization: {
            id: organizationId,
            name: organizationName,
            logoURL: organizationLogoURL,
            color,
          },
          inviterName,
          fulfilled: true,
        },
      }),
    );
    builder.addCase(getOrgFromInvitation.pending, (state, { meta: { arg: { token } } }) => ({
      ...state,
      [token]: pending,
    }));
    builder.addCase(getOrgFromInvitation.rejected, (state, { meta: { arg: { token } }, error }) => ({
      ...state,
      [token]: {
        ...error,
        error: true,
      },
    }));
  },
});

export const organizationInvitationReducer = slice.reducer;
