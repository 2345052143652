import { animateFromTop } from 'components/core/controllers/Selector/animations';
import { css, cx } from 'emotion';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { atomic } from 'resources';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export interface PopupHandle {
  openPopup: () => void;
  closePopup: () => void;
}

/**
 * Popup has to be class component, bc we want to be able to force open and close from parent
 */
export const Popup = forwardRef<PopupHandle, Props>(function Popup({ children, className }, ref) {
  const rootRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      openPopup: () => setShow(true),
      closePopup: () => setShow(false),
    }),
    [],
  );

  useEffect(() => {
    function handleMouseDownOutside(event: MouseEvent): void {
      if (!rootRef.current?.contains(event.target as Node)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleMouseDownOutside);
    return () => {
      document.removeEventListener('mousedown', handleMouseDownOutside);
    };
  }, [rootRef]);

  if (!show) {
    return null;
  }

  return (
    <div
      ref={rootRef}
      className={cx(
        css`
          position: absolute;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
          border: solid 1px ${atomic.get(atomic.colors.grey5)};
          border-radius: 3px;
          width: 21.875rem;
          z-index: 10;
          background: ${atomic.get(atomic.colors.white)};
          cursor: default;
          margin-bottom: 1rem;
        `,
        animateFromTop,
        className,
      )}
    >
      {children}
    </div>
  );
});
