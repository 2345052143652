import { createSelector, Selector } from '@reduxjs/toolkit';
import { Data, Timestamp, UI } from '@taraai/types';
import { isLoaded, ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { getSprintCommits } from 'reduxStore/commits';
import { getSprintPullRequests } from 'reduxStore/pull-requests';
import { RootState } from 'reduxStore/store';
import { getSprintTasks as sprintTasksQuery } from 'reduxStore/tasks';

import { getSprint } from './getSprint';
import { getEffortPercent, getSprintCalculations } from './getSprintCalculations';

const TASK_STATUS = { todo: 0, doing: 1, done: 2 };

export type CompleteSprintData = {
  sprintTasks: UI.UITask[] | undefined;
  incompleteSprintTasks: UI.UITask[] | undefined;
  completeSprintTasks: UI.UITask[] | undefined;

  closedPullRequestsCount: number;
  allPullRequestsCount: number;

  totalEffortCompleted: number;
  totalEffortEstimated: number;
  effortCompletedPercent: number;

  totalTasks: number;
  totalTasksCompleted: number;

  totalCommits: number;
};

type GetCompleteSprintDataRes = {
  query: ReduxFirestoreQuerySetting[];
  selectors: {
    all: () => Selector<RootState, CompleteSprintData | undefined>;
  };
};

export const getCompleteSprintData = (
  orgId: Data.Id.OrganizationId,
  sprintId: Data.Id.SprintId,
  initialStartDate: Timestamp | null,
  initialEndDate: Timestamp | null,
): GetCompleteSprintDataRes => {
  const data = {
    sprint: getSprint(orgId, sprintId),
    sprintTasks: sprintTasksQuery(orgId, sprintId, { orderBy: 'status' }),
    sprintPullRequests: getSprintPullRequests(orgId, sprintId, initialStartDate, initialEndDate),
    sprintCommits: getSprintCommits(orgId, sprintId, initialStartDate, initialEndDate),
  };

  const query = [
    ...data.sprint.query,
    ...data.sprintTasks.query,
    ...data.sprintPullRequests.query,
    ...data.sprintCommits.query,
  ];

  const sprintDataSelector = createSelector(
    [data.sprint.selector, data.sprintTasks.selector, data.sprintPullRequests.selector, data.sprintCommits.selector],
    (sprint, sprintTasks, sprintPullRequests, sprintCommits): CompleteSprintData | undefined => {
      if (!isLoaded(sprint) || !isLoaded(sprintTasks) || !isLoaded(sprintPullRequests) || !isLoaded(sprintCommits)) {
        return undefined;
      }

      const incompleteSprintTasks = sprintTasks?.filter(
        (task: UI.UITask): boolean => task.status === TASK_STATUS.todo || task.status === TASK_STATUS.doing,
      );

      const completeSprintTasks = sprintTasks?.filter((task: UI.UITask): boolean => task.status === TASK_STATUS.done);

      const closedPullRequestsCount =
        sprintPullRequests?.filter((pullRequest) => pullRequest.state === 'closed').length || 0;

      const { totalEffortCompleted, totalEffortEstimated, totalTasksCompleted, totalTasks } = getSprintCalculations(
        sprint,
        sprintTasks,
        completeSprintTasks,
      );

      const effortCompletedPercent = getEffortPercent(totalEffortCompleted, totalEffortEstimated);

      return {
        sprintTasks,
        incompleteSprintTasks,
        completeSprintTasks,
        closedPullRequestsCount,
        totalEffortCompleted,
        totalEffortEstimated,
        effortCompletedPercent,
        totalTasksCompleted,
        totalTasks,
        allPullRequestsCount: sprintPullRequests?.length || 0,
        totalCommits: sprintCommits?.length || 0,
      };
    },
  );

  return {
    query,
    selectors: {
      all: (): Selector<RootState, CompleteSprintData | undefined> => sprintDataSelector,
    },
  };
};
