"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userRoleDecoder = exports.externalAccountsDecoder = exports.tutorialCardsDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
exports.tutorialCardsDecoder = ts_data_json_1.JsonDecoder.object({
    tutorial: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    taskTutorial: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    taskTutorialLearn: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    sprintTutorial: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    sprintTutorialLearn: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    specTutorial: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    specTutorialLearn: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    importSuggestion: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    teamBacklog: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    slackConnectAccount: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
}, 'User.TutorialCards');
exports.externalAccountsDecoder = ts_data_json_1.JsonDecoder.object({
    github: ts_data_json_1.JsonDecoder.array(ts_data_json_1.JsonDecoder.object({
        id: ts_data_json_1.JsonDecoder.number,
        username: ts_data_json_1.JsonDecoder.string,
    }, 'ExternalGithubAccountData'), 'ExternalGithubAccountData[]'),
    slack: ts_data_json_1.JsonDecoder.array(ts_data_json_1.JsonDecoder.object({
        id: ts_data_json_1.JsonDecoder.string,
        name: ts_data_json_1.JsonDecoder.string,
        team: ts_data_json_1.JsonDecoder.string,
        user: ts_data_json_1.JsonDecoder.string,
    }, 'ExternalSlackAccount'), 'ExternalSlackAccount[]'),
}, 'User.ExternalAccounts');
exports.userRoleDecoder = ts_data_json_1.JsonDecoder.object({
    roleId: ts_data_json_1.JsonDecoder.string,
    description: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.string),
}, 'UserRole');
exports.default = ts_data_json_1.JsonDecoder.object({
    name: ts_data_json_1.JsonDecoder.string,
    username: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.string),
    avatarURL: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.nullable(ts_data_json_1.JsonDecoder.string)),
    organizationIds: ts_data_json_1.JsonDecoder.array(ts_data_json_1.JsonDecoder.string, 'OrganizationId[]'),
    teamIds: ts_data_json_1.JsonDecoder.dictionary(ts_data_json_1.JsonDecoder.array(ts_data_json_1.JsonDecoder.string, 'TeamId[]'), '{ [orgId: string]: TeamId[] }'),
    preferredTeamIds: ts_data_json_1.JsonDecoder.dictionary(ts_data_json_1.JsonDecoder.string, '{ [orgId: string]: TeamId }'),
    roles: ts_data_json_1.JsonDecoder.array(exports.userRoleDecoder, 'UserRole[]'),
    externalAccounts: ts_data_json_1.JsonDecoder.optional(exports.externalAccountsDecoder),
    dummy: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.boolean),
    tutorialCards: ts_data_json_1.JsonDecoder.optional(exports.tutorialCardsDecoder),
    refreshToken: ts_data_json_1.JsonDecoder.optional(ts_data_json_1.JsonDecoder.string),
}, 'User');
