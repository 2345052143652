import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { css, cx } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

export interface BacklogTaskHeaderProps extends React.HTMLProps<HTMLDivElement> {
  getGroups: () => void;
  backlogTaskCount: number;
}

function BacklogTaskHeader({ className, backlogTaskCount, getGroups, ...props }: BacklogTaskHeaderProps): JSX.Element {
  const handleClick = (): void => {
    getGroups();
  };

  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
        `,
        className,
      )}
      {...props}
    >
      <Icon
        className={css`
          cursor: pointer;
          color: #949caf;
        `}
        name='sprintleft'
        onClick={handleClick}
      />
      <Icon
        className={css`
          background-color: #f1f5fd;
          color: #4550ce;
          border-radius: 0.1875rem;
        `}
        name='backlog'
      />
      <Text
        className={css`
          padding: 0rem 0.5rem;
          font-size: 1rem;
          font-weight: 500;
          color: #303f4b;
        `}
      >
        {strings.sprints.backlogTasks}
        <div
          className={cx(
            css`
              font-size: 0.875rem;
              font-weight: normal;
              color: #949caf;
              padding-top: 0.25rem;
            `,
            className,
          )}
          {...props}
        >
          {!isNaN(backlogTaskCount) &&
            strings.formatString(strings.sprints.backlogTaskCount, {
              number: backlogTaskCount,
            })}
        </div>
      </Text>
    </div>
  );
}

export default BacklogTaskHeader;
