import { UI } from '@taraai/types';
import { getFilterByTitleFn } from 'components/app/controllers/Selectors/common/filterFn';
import { Option } from 'components/app/controllers/Selectors/common/Option';
import SearchHeader from 'components/app/controllers/Selectors/common/SearchHeader';
import {
  OptionRenderProps,
  SectionType,
  SelectButtonRenderProps,
  Selector,
} from 'components/core/controllers/Selector';
import React, { FunctionComponent } from 'react';

type RequirementFragment = Pick<UI.UIRequirement, 'id' | 'title'>;
type Section = SectionType<RequirementFragment>;

type Props = {
  sections: Section[];
  selection: RequirementFragment[];
  headerTitle?: string;
  searchPlaceholder: string;
  Wrapper?: FunctionComponent<{ 'data-cy'?: string }>;
  onSelectOption: (requirement: RequirementFragment) => void;
  onDeselectOption: (requirement: RequirementFragment) => void;
  renderSelectButton: (props: SelectButtonRenderProps) => JSX.Element;
};

const filterFn = getFilterByTitleFn<Section, RequirementFragment>();

/**
 * RequirementSelector is an UI component build on top of the Selector to handle selecting requirements.
 *
 * It supports:
 * - multiple and single selection of requirement by mouse clicking and keyboard navigation
 * - searching requirements by name
 * - displaying requirement names in dropdown
 */
export function RequirementSelector({
  sections,
  selection,
  headerTitle,
  searchPlaceholder,
  Wrapper,
  onSelectOption,
  onDeselectOption,
  renderSelectButton,
}: Props): JSX.Element {
  return (
    <Selector
      filterFn={filterFn}
      onDeselectOption={onDeselectOption}
      onSelectOption={onSelectOption}
      renderHeader={(props): JSX.Element => (
        <SearchHeader searchPlaceholder={searchPlaceholder} showSearch title={headerTitle} {...props} />
      )}
      renderOption={RequirementOption}
      renderSelectButton={renderSelectButton}
      sections={sections}
      selection={selection}
      Wrapper={Wrapper}
    />
  );
}

function RequirementOption({ option, ...props }: OptionRenderProps<RequirementFragment>): JSX.Element {
  return <Option title={option.title} {...props} />;
}
