import { Tooltip } from '@material-ui/core';
import { styled } from '@taraai/design-system';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { atomic, IconName } from 'resources';

interface Props {
  iconName: IconName;
  onClick: () => void;
  title: string;
}

export function PopupButton({ iconName, onClick, title }: Props): JSX.Element {
  return (
    <Tooltip title={<Title>{title}</Title>} TransitionProps={{ timeout: 600 }}>
      <IconWrapper>
        <PopupIcon color={atomic.colors.white} name={iconName} onClick={onClick} />
      </IconWrapper>
    </Tooltip>
  );
}

const Title = styled('div', {
  color: '$white',
  fontSize: '0.875rem',
  fontWeight: 'normal',
  lineHeight: '1.125rem',
});

// Tooltip doesn't work with SVGs, so we have to wrap it in a `div`
const IconWrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const PopupIcon = styled(Icon, {
  'color': '$grey6',
  'cursor': 'pointer',
  'height': 'auto',
  'padding': '0',
  'verticalAlign': 'top',
  'width': 'auto',

  ':hover': {
    opacity: 0.7,
  },
});
