import { Data, Functions } from '@taraai/types';
import {
  ImportNotificationBarView,
  ImportNotificationBarViewProps,
} from 'components/app/controllers/views/ImportNotificationBarView';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { reduxStore, RootState } from 'reduxStore';
import { closeImportNotification } from 'reduxStore/imports/actions';
import { getImport } from 'reduxStore/imports/queries/imports';

import { hasImportFinished } from './guards';

type ImportNotificationBarProps = Pick<
  ImportNotificationBarViewProps,
  'bgColor' | 'iconName' | 'importInProgressCopy'
> & {
  importId: Data.Id.ImportId;
  service: Functions.ImportCSVFile.CSVImportableService;
};

function ImportNotificationBar({ importId, service, ...viewProps }: ImportNotificationBarProps): JSX.Element | null {
  const { orgID } = useParams<{
    orgID: Data.Id.OrganizationId;
  }>();

  const importSlice = getImport(orgID, importId);

  useFirestoreConnect(importSlice.query);
  const importDoc = useSelector(importSlice.selector);

  const onCloseRequest = useCallback(() => {
    reduxStore.dispatch(closeImportNotification(service));
  }, [service]);

  const onViewImportedTasksRequest = useCallback(() => {
    reduxStore.dispatch(closeImportNotification(service));
  }, [service]);

  if (!importDoc) {
    return null;
  }

  return (
    <ImportNotificationBarView
      hasImportFinished={hasImportFinished(importDoc)}
      onCloseRequest={onCloseRequest}
      onViewImportedTasksRequest={onViewImportedTasksRequest}
      {...viewProps}
    />
  );
}

type ImportNotificationBarControllerProps = Omit<ImportNotificationBarProps, 'importId'>;

export function ImportNotificationBarController({
  service,
  ...barProps
}: ImportNotificationBarControllerProps): JSX.Element | null {
  const importId = useSelector((state: RootState) => state.imports.notifications[service]);

  if (!importId) {
    return null;
  }

  return <ImportNotificationBar importId={importId} service={service} {...barProps} />;
}
