import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import Logo, { TLogoSize } from 'components/core/controllers/views/Logo';
import { keyframes } from 'emotion';
import React from 'react';

export type IconElementProps = {
  tooltip: string;
  name: string;
  size?: TLogoSize;
  url?: string | null;
  onClick?: (event: React.SyntheticEvent) => void;
};

export function IconElement({ tooltip, name, url, size = 'medium', onClick }: IconElementProps): JSX.Element {
  return (
    <Tooltip
      key={name}
      arrow
      enterDelay={0}
      interactive
      placement='bottom'
      title={tooltip}
      TransitionProps={{ timeout: 600 }}
    >
      <LogoContainer onClick={onClick} tabIndex={0}>
        <Logo name={name} shape='circle' size={size} url={url} />
      </LogoContainer>
    </Tooltip>
  );
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const LogoContainer = styled.div`
  border: solid 0.125rem #ffffff;
  border-radius: 50%;
  position: relative;
  margin-left: -0.6rem;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  animation: ${fadeIn} 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
    z-index: 2;
    cursor: pointer;
  }

  &:focus {
    transform: scale(1.1);
    z-index: 2;
    cursor: pointer;
  }
`;
