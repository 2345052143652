import { Data } from '@taraai/types';
import { linkTo } from 'components/Router/paths';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useHistory, useParams } from 'react-router';
import { compose } from 'redux';
import { getTasks, selectPreferredTeamId } from 'reduxStore';

import { MobileNote } from './MobileNote';

export const OnboardingMobileNoteController: React.FC = () => {
  const history = useHistory();
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const tasksSlice = getTasks(orgID);

  useFirestoreConnect(tasksSlice.query);

  const preferredTeamID = useSelector(selectPreferredTeamId(orgID));
  const taskTitles = useSelector(
    compose((data) => data?.map(({ title }) => title), tasksSlice.selector),
    deepEquals,
  );

  const onClick = (): void => {
    history.push(linkTo('sprints', { orgID, teamID: preferredTeamID }));
  };

  return <MobileNote onClick={onClick} taskTitles={taskTitles} />;
};
