import { Functions } from '@taraai/types';
import { createCloudFunctionAction } from 'reduxStore/utils/createCloudFunctionAction';

export const joinOrganization = createCloudFunctionAction<
  Functions.JoinOrganization.Payload,
  Functions.JoinOrganization.Success
>({
  typePrefix: 'JoinOrganization',
  cloudFunctionName: 'joinOrganization',
  responseDecoder: 'JoinOrganizationResponse',
});
