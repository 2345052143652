import { PopperProps } from '@material-ui/core';
import { styled } from '@taraai/design-system';
import Text from 'components/core/controllers/views/Text';
import * as PopupComponents from 'components/editor/plugins/shared/Popup.styles';
import { PopupButton } from 'components/editor/plugins/shared/PopupButton';
import React, { forwardRef } from 'react';
import { strings } from 'resources/i18n';

interface Props {
  anchorEl?: PopperProps['anchorEl'];
  url: string;
  onCopy: () => void;
  onRemove: () => void;
  open: boolean;
}

export const Popup = forwardRef<HTMLDivElement, Props>(
  ({ anchorEl, url, onCopy, onRemove, open }, ref): JSX.Element => {
    return (
      <PopupComponents.PopperWrapper ref={ref} anchorEl={anchorEl} open={open} placement='bottom'>
        <PopupComponents.Content>
          <StyledText>
            <a href={url}>{url}</a>
          </StyledText>

          <PopupButton iconName='linkcopy' onClick={onCopy} title={strings.editor.attachments.copy} />
          <PopupButton iconName='modalexit' onClick={onRemove} title={strings.editor.attachments.remove} />
        </PopupComponents.Content>
      </PopupComponents.PopperWrapper>
    );
  },
);
Popup.displayName = 'Popup';

export const StyledText = styled(Text, {
  'overflow': 'hidden',
  'textOverflow': 'ellipsis',
  'whiteSpace': 'nowrap',
  'maxWidth': '250px',

  ':hover': {
    opacity: 0.7,
  },
});
