import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting, WhereOptions } from 'react-redux-firebase';
import { getUsers } from 'reduxStore/users';
import { createStandardSelector, inertQuery, PopulateTuples, Query } from 'reduxStore/utils/selectors';

function queryBuilder(orgID: Data.Id.OrganizationId, where: WhereOptions[]): Query<UI.UITask> {
  if (!isNonEmptyString(orgID)) {
    return inertQuery();
  }

  const queryTask: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgID}/tasks`,
    where,
    orderBy: ['status', 'desc'],
  };

  const queryUser: ReduxFirestoreQuerySetting = getUsers(orgID).query[0];

  const documentTuples: PopulateTuples = [
    ['author', queryUser],
    ['assignee', queryUser],
  ];

  return {
    query: [queryTask],
    selector: createStandardSelector(queryTask, documentTuples),
  };
}

export function getImportedTasks(
  orgID: Data.Id.OrganizationId,
  service: Data.ExternalIssue.Any['service'],
): Query<UI.UITask> {
  if (![orgID, service].every(isNonEmptyString)) {
    return inertQuery();
  }

  const where: WhereOptions[] = [
    ['externalIssue.service', '==', service],
    ['_relationships.parent', '==', null],
    ['sprint', '==', null],
    ['archived', '==', false],
    ['deleted', '==', false],
    ['status', '<', 2],
  ];

  return queryBuilder(orgID, where);
}
