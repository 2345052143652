import { noop } from '@taraai/utility';
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { wait } from 'tools/libraries';

export type Callback<T> = (value: T) => void;
export type DispatchWithCallback<T> = (setStateAction: SetStateAction<T>, callback?: Callback<T>) => void;

/**
 * useStateCallback is the same as useState, except it allows a callback to be
 * passed in alongside a set state, something to run after the setState is
 * done, just like the `this.setState` from react without hooks
 *
 * @param initialState
 */
export function useStateCallback<T>(initialState: T | (() => T)): [T, DispatchWithCallback<T>] {
  const [state, _setState] = useState(initialState);

  const callbackRef = useRef<Callback<T>>();
  const isFirstCallbackCall = useRef<boolean>(true);

  const setState: DispatchWithCallback<T> = useCallback(
    (setStateAction: SetStateAction<T>, callback?: Callback<T>): void => {
      callbackRef.current = callback;
      _setState(setStateAction);
    },
    [],
  );

  useEffect(() => {
    if (!isFirstCallbackCall.current && callbackRef.current) {
      callbackRef.current(state);
    } else {
      isFirstCallbackCall.current = false;
    }
  }, [state]);

  return [state, setState];
}

export function useWindowWidth(): number {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (): void => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
}

export function useWindowReload(callback: () => void, dependencies: unknown[] = []): void {
  const handleCallback = useCallback(() => {
    callback();
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
  useEffect(() => {
    window.addEventListener('beforeunload', handleCallback);
    return (): void => {
      window.removeEventListener('beforeunload', handleCallback);
    };
  }, [handleCallback]);
}

export function useAfterMountEffect(callback: () => void, dependencies: unknown[], remountAfterUnmount = false): void {
  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) callback();
    else didMount.current = true;
    return (): void => {
      if (remountAfterUnmount) didMount.current = false;
    };
    // ESlint disable required here in order to use 2 dependencies with this hook. Only being used for keyframes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export function useAfterUnMountEffect(callback: () => void, dependencies: unknown[] = []): void {
  useEffect(() => {
    return callback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export interface CancellablePromise {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promise: Promise<any>;
  cancel: () => void;
}

export function makeCancelable<Type>(promise: Promise<Type>): CancellablePromise {
  let isCanceled = false;
  const cancellablePromise = new Promise<Type>((resolve, reject) => {
    promise
      .then((val: Type) => (isCanceled ? reject() : resolve(val)))
      .catch((error: Error) => (isCanceled ? reject() : reject(error)));
  });
  return {
    promise: cancellablePromise,
    cancel(): void {
      isCanceled = true;
    },
  };
}

export function useAfterUnMountWithoutRerenderEffect(
  callback: () => void,
  dependencies: unknown[] = [],
  timeout = 1000,
): void {
  const unmountPromiseRef = useRef(null as null | CancellablePromise);
  (unmountPromiseRef.current as CancellablePromise)?.cancel();
  useAfterUnMountEffect(() => {
    unmountPromiseRef.current = makeCancelable(wait(timeout));
    // eslint-disable-next-line promise/no-callback-in-promise
    unmountPromiseRef.current.promise.then(callback).catch(noop);
  }, dependencies);
}
