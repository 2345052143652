"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyboardSelection = void 0;
var react_1 = require("react");
function useKeyboardSelection(_a) {
    var handleClose = _a.handleClose, items = _a.items, show = _a.show;
    var _b = react_1.useState(), selectedIndex = _b[0], setSelectedIndex = _b[1];
    // Prevent unnecessary event handler re-attachments
    var selectedItemOnClickRef = react_1.useRef();
    selectedItemOnClickRef.current =
        typeof selectedIndex !== 'undefined' ? items[selectedIndex].props.onClick : undefined;
    var handleKeyDown = react_1.useCallback(function (event) {
        var _a;
        switch (event.keyCode) {
            case 9: // event.code = "Tab"
                // Just close on tab since we're not handling normal focus
                handleClose();
                return;
            case 13: // event.code = "Enter"
                event.preventDefault();
                (_a = selectedItemOnClickRef.current) === null || _a === void 0 ? void 0 : _a.call(selectedItemOnClickRef);
                handleClose();
                return;
            case 27: // event.code = "Escape"
                event.preventDefault();
                handleClose();
                return;
            case 38: // event.code = "ArrowUp"
                event.preventDefault();
                setSelectedIndex(function (prevSelectedIndex) {
                    return ((typeof prevSelectedIndex === 'undefined' ? 0 : prevSelectedIndex) + items.length - 1) % items.length;
                });
                return;
            case 40: // event.code = "ArrowDown"
                event.preventDefault();
                setSelectedIndex(function (prevSelectedIndex) {
                    return ((typeof prevSelectedIndex === 'undefined' ? -1 : prevSelectedIndex) + 1) % items.length;
                });
        }
    }, [handleClose, items.length]);
    react_1.useEffect(function () {
        if (!show) {
            return;
        }
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            setSelectedIndex(undefined);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown, show]);
    return selectedIndex;
}
exports.useKeyboardSelection = useKeyboardSelection;
