import 'react-datepicker/dist/react-datepicker.css';

import { Data, Timestamp, UI } from '@taraai/types';
import SprintPlanning from 'components/app/controllers/views/SprintPlanning';
import Icon from 'components/core/controllers/views/Icon';
import Input from 'components/core/controllers/views/Input';
import Text from 'components/core/controllers/views/Text';
import { usePathParams } from 'components/Router/paths';
import { css, cx } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { selectTeam } from 'reduxStore';
import { sprintsTabTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { firestore } from 'tools/libraries/firebaseValues';
import {
  formatDMMMYYYY,
  getEpochSecondsFromDate,
  getMillisecondsFromSeconds,
  plusSeconds,
  toDate,
  toTimestamp,
  WEEK_IN_SECONDS,
} from 'tools/libraries/helpers/dates';

export interface EditSprintBodyProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * The duration of the sprint in weeks
   */
  currentDurationWeeks: 0 | Data.SprintDurationWeeks;
  currentSprint: Data.Sprint | undefined;
  setStartAndEndTimestamps: (startDate: Timestamp, endDate: Timestamp) => void;
  setSprintName: (name: string) => void;
  estimatedEffort: number;
  totalEffort: number;
  allSprintTasks: UI.UITask[] | undefined;
  unassignedSprintTasks: UI.UITask[] | undefined;
  renderNewSprintPreview: (sprintName?: string) => string;
}

/**
 * Body for the Sprint Start/Edit Modal component. The body takes in a start date and spits out an end date based
 * on the sprint duration setting
 */
export default function EditSprintBody({
  currentDurationWeeks,
  currentSprint,
  setStartAndEndTimestamps,
  setSprintName,
  estimatedEffort,
  totalEffort,
  allSprintTasks,
  unassignedSprintTasks,
  renderNewSprintPreview,
  className,
  ...props
}: EditSprintBodyProps): JSX.Element {
  const { orgID, teamID } = usePathParams('sprints');
  const team = useSelector(selectTeam(orgID, teamID));

  const [startDate, setStartDate] = useState(currentSprint?.initialStartDate ?? firestore.Timestamp.now());

  const [bodySprintName, setBodySprintName] = useState(currentSprint?.sprintName || '');

  const [endDate, setEndDate] = useState(
    formatDMMMYYYY(plusSeconds(startDate, currentDurationWeeks * WEEK_IN_SECONDS)),
  );

  const calculateEndDate = useCallback(
    (date: Date) => {
      const totalEpochSeconds = getEpochSecondsFromDate(date) + currentDurationWeeks * WEEK_IN_SECONDS;
      const endDateFormatted = formatDMMMYYYY(
        firestore.Timestamp.fromMillis(getMillisecondsFromSeconds(totalEpochSeconds)),
      );
      setEndDate(endDateFormatted);
    },
    [currentDurationWeeks],
  );

  const handleDateChange = useCallback(
    (date: Date) => {
      setStartDate(toTimestamp(date));
      calculateEndDate(date);
    },
    [calculateEndDate],
  );

  const handleNameChange = (event: React.BaseSyntheticEvent): void => {
    setBodySprintName(event.target.value);
  };

  useEffect(() => {
    setStartAndEndTimestamps(startDate, plusSeconds(startDate, currentDurationWeeks * WEEK_IN_SECONDS));
    setSprintName(bodySprintName);
  }, [currentDurationWeeks, endDate, setSprintName, setStartAndEndTimestamps, bodySprintName, startDate]);

  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          padding-bottom: 1.5rem;
        `,
        className,
      )}
      {...props}
    >
      {estimatedEffort > 0 && totalEffort > estimatedEffort && (
        <div
          className={css`
            display: flex;
            padding-bottom: 1.25rem;
          `}
        >
          <Icon
            className={css`
              padding: 0rem;
              padding-top: 0.0625rem;
              padding-right: 0.25rem;
              display: flex;
              align-self: auto;
              height: 0.875rem;
              width: 0.875rem;
            `}
            name='overload'
          />
          <div>
            <span
              className={css`
                color: #d99b59;
                font-size: 0.875rem;
              `}
            >
              {strings.sprints.edit.youMightHave}
            </span>
            <span
              className={css`
                color: #d99b59;
                font-size: 0.875rem;
                font-weight: 600;
              `}
            >
              {strings.sprints.edit.overloadedYourSprint}
            </span>
            <span
              className={css`
                color: #d99b59;
                font-size: 0.875rem;
              `}
            >
              {strings.sprints.edit.takeALook}
            </span>
          </div>
        </div>
      )}
      <SprintPlanning
        allSprintTasks={allSprintTasks}
        currentSprint={currentSprint}
        estimatedEffort={estimatedEffort}
        totalEffort={totalEffort}
        unassignedSprintTasks={unassignedSprintTasks}
      />
      <Text
        className={css`
          color: #303f4b;
          font-size: 0.875rem;
          font-weight: normal;
          padding-top: 1.25rem;
          padding-bottom: 0.5rem;
        `}
      >
        {strings.sprints.edit.sprintName}
      </Text>
      <Input
        className={css`
          color: #303f4b;
          font-size: 0.875rem;
          width: 10.5rem;
        `}
        data-cy={sprintsTabTestIDs.SPRINT_EDIT_MODAL.EDIT_NAME_FIELD}
        onChange={handleNameChange}
        style={{
          main: css`
            ::placeholder {
              opacity: 0.7;
            }
            padding-left: 0.75rem;
          `,
        }}
        value={bodySprintName}
      />
      <Text
        className={css`
          color: #67728b;
          font-size: 0.75rem;
          font-weight: normal;
          margin-top: 0.5rem;
        `}
      >
        {renderNewSprintPreview(bodySprintName)}
      </Text>
      <Text
        className={css`
          margin-top: 1rem;
          color: #303f4b;
          font-size: 0.875rem;
          font-weight: normal;
        `}
      >
        {strings.sprints.edit.duration}
      </Text>
      <Text
        className={css`
          margin-top: 1rem;
          color: #67728b;
          font-size: 0.875rem;
          font-weight: normal;
        `}
      >
        {strings.formatString(strings.sprints.edit.weeks, {
          number: currentDurationWeeks,
        })}
      </Text>
      <div
        className={css`
          margin-top: 1rem;
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Text
            className={css`
              font-size: 0.875rem;
              color: #303f4b;
              font-weight: normal;
              margin-bottom: 0.25rem;
            `}
          >
            {strings.sprints.edit.startDate}
          </Text>

          {team?.sprintSettings.autoSprints ? (
            <div
              className={css`
                padding: 0.8125rem 0rem;
              `}
            >
              <Text
                className={css`
                  font-size: 0.875rem;
                  font-weight: normal;
                  align-self: center;
                `}
              >
                {formatDMMMYYYY(startDate)}
              </Text>
            </div>
          ) : (
            <div
              className={css`
                border: solid 0.0625rem #c8d0df;
                border-radius: 0.1875rem;
                padding: 0.4375rem 0.75rem;
              `}
            >
              <DatePicker
                className={css`
                  border: none;
                  border-radius: 0.1875rem;
                  font-size: 0.875rem;
                  color: #303f4b;
                  font-weight: normal;
                `}
                dateFormat='d MMM yyyy'
                onChange={(date: Date): void => handleDateChange(date)}
                selected={toDate(startDate)}
              />
              <Icon
                className={css`
                  vertical-align: middle;
                  width: 1rem;
                  height: 1rem;
                `}
                name='calendar'
              />
            </div>
          )}
        </div>
        <div
          className={css`
            margin: 0rem 0.5rem;
            margin-top: 1.125rem;
            background-color: #c8d0df;
            width: 1rem;
            height: 0.0625rem;
            align-self: center;
          `}
        />
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-self: center;
          `}
        >
          <Text
            className={css`
              font-size: 0.875rem;
              color: #303f4b;
              font-weight: normal;
              margin-bottom: 0.25rem;
            `}
          >
            {strings.sprints.edit.endDate}
          </Text>
          <div
            className={css`
              padding: 0.8125rem 0rem;
            `}
          >
            <Text
              className={css`
                font-size: 0.875rem;
                font-weight: normal;
                align-self: center;
              `}
            >
              {endDate}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
