import { noop } from '@taraai/utility';
import Box from 'components/core/controllers/views/Box';
import Icon from 'components/core/controllers/views/Icon';
import Text from 'components/core/controllers/views/Text';
import { css, cx } from 'emotion';
import React, { useMemo } from 'react';
import { atomic } from 'resources';
import { v1 as uuid } from 'uuid';
/**
 * Wrapper around HTML <input>
 *
 * @see https://zpl.io/a7e3qGv
 */

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function Input({
  color = atomic.theme.color.input.border.default,
  icon = false,
  value = undefined,
  Ref = undefined,
  /**
   * Autosize property is being used only when input
   * width needs to resize dynamically based on input length
   */
  autosize = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_event) => noop(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onKeyDown = (_event) => noop(),
  disabled = undefined,
  required = undefined,
  placeholder = undefined,
  readyOnly = undefined,
  type = undefined,
  onClick = () => false,
  label = '',
  bottomLabel = '',
  error = false,
  semibold = false,
  min = undefined,
  max = undefined,
  naked = false,
  left = null,
  right = null,
  className = '',
  style: { main = {}, container = {}, labelStyle = {}, bottomLabelStyle = {} } = {},
  checked = undefined,
  readOnly = undefined,
  name = undefined,
  autoFocus = undefined,
  onKeyPress = undefined,
  ...props
} = {}) {
  // HACK: used to link <label> to <input> for screen readers
  const id = uuid();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const iconComponent = icon !== false && (
    <Icon
      className={cx(css`
        margin: 0.125rem;
        ${atomic.transition()}
      `)}
      color={error === true ? atomic.theme.color.input.icon.error : color}
      id='input-icon' // FIXME: this ID will be duplicated in the DOM leading to bug
      name={icon}
      onClick={onClick}
      // padding={atomic.theme.space.input.icon.padding}
    />
  );
  return useMemo(
    () => (
      <>
        {label.trim().length !== 0 && (
          <Text
            className={cx(
              css`
                margin-bottom: 0.5rem;
                ${atomic.color(atomic.colors.dark)}
                ${semibold === true ? atomic.fontWeight('600') : atomic.fontWeight('400')}
              `,
              labelStyle,
            )}
            error={error}
            htmlFor={id}
          >
            {label}
          </Text>
        )}
        <Box
          className={cx(
            css`
              ${atomic.height('2.5rem')};
              ${atomic.border({
                style: naked === true ? 'none' : 'solid',
                width: atomic.theme.space.input.border.width.regular,
                color: error === true ? atomic.theme.color.input.border.error : atomic.theme.color.input.border.default,
                radius: atomic.theme.space.input.border.radius.regular,
              })}
              ${atomic.transition({
                duration: '.2s',
              })} :focus {
                outline: 0;
              }
              &:focus-within {
                ${atomic.border({
                  style: naked === true ? 'none' : 'solid',
                  width: atomic.theme.space.input.border.width.regular,
                  color: error === true ? atomic.theme.color.input.border.error : atomic.theme.color.input.border.focus,
                  radius: atomic.theme.space.input.border.radius.regular,
                })}
                ${atomic.transition()}
              }
              &:focus-within > #input-icon {
                ${error === true
                  ? atomic.color(atomic.theme.color.input.border.error)
                  : atomic.color(atomic.theme.color.input.border.focus)};
              }
              ${autosize && `width: calc(${autosize} * 0.4375rem + 2.5rem);`}
            `,
            className,
            /**
             * Autosize needs more work, currently a
             * quick way to make it expand based on length
             */
            container,
          )}
          left={left && iconComponent}
          onClick={onClick}
          right={right && iconComponent}
          row
        >
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            checked={checked}
            name={name}
            onKeyPress={onKeyPress}
            readOnly={readOnly}
            {...props}
            ref={Ref}
            className={cx(
              css`
                font-size: 0.875rem;
                ${error === true
                  ? atomic.color(atomic.theme.color.input.error)
                  : atomic.color(atomic.theme.color.input.default)} :focus {
                  outline: 0;
                }
                ${atomic.border({
                  style: 'none',
                  radius: atomic.theme.space.input.border.radius.regular,
                })}
                ${atomic.width(
                  icon === false
                    ? '100%'
                    : `calc(100% - 2 * ${atomic.get(atomic.theme.space.input.padding)}
                        )`,
                )} ::placeholder {
                  color: ${error === true
                    ? atomic.theme.color.input.placeholder.error
                    : atomic.theme.color.input.placeholder.default};
                }
                padding-left: 1rem;
              `,
              main,
            )}
            disabled={disabled || readyOnly}
            id={id}
            max={max}
            min={min}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            required={required}
            type={type || 'text'}
            value={value}
          />
        </Box>
        {bottomLabel.trim().length !== 0 && (
          <Text
            className={cx(
              css`
                ${semibold === true ? atomic.fontWeight('600') : atomic.fontWeight('400')}
              `,
              bottomLabelStyle,
            )}
            error={error}
          >
            {bottomLabel}
          </Text>
        )}
      </>
    ),
    [
      label,
      semibold,
      labelStyle,
      error,
      id,
      naked,
      autosize,
      className,
      container,
      left,
      iconComponent,
      onClick,
      right,
      props,
      Ref,
      icon,
      main,
      disabled,
      readyOnly,
      max,
      min,
      onChange,
      onKeyDown,
      placeholder,
      required,
      type,
      value,
      bottomLabel,
      bottomLabelStyle,
      checked,
      name,
      readOnly,
      autoFocus,
      onKeyPress,
    ],
  );
}
