import { Data } from '@taraai/types';
import { TeamCreateModal } from 'components/app/controllers/views/TeamCreateModal';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { selectAuth } from 'reduxStore';

type TeamCreateControllerProps = {
  closeModal?: () => void;
};

/**
 * Prepares data for team creation modal
 */
export function TeamCreateController({ closeModal }: TeamCreateControllerProps): JSX.Element | null {
  const history = useHistory();
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const { uid: profileId } = useSelector(selectAuth);

  const goBack = (): void => history.go(-1);

  return <TeamCreateModal closeModal={closeModal || goBack} orgID={orgID} profileId={profileId} />;
}
