import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import { Data, TaskPartial, UI } from '@taraai/types';
import { notUndefined } from '@taraai/utility';
import Subtasks from 'components/app/controllers/views/Subtasks';
import { RichEditorHandle } from 'components/editor/RichEditor';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { createTask, reduxStore, taskSubTasks, updateTask } from 'reduxStore';
import { decode } from 'reduxStore/utils/decoders';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';
import { compareByCreatedAtAsc } from 'tools/libraries/helpers/tasks';

type TaskFragment = Pick<UI.UITask, 'id' | 'orderedSubtaskIds'>;
export interface SubtasksControllerProps extends React.HTMLProps<HTMLDivElement> {
  task: TaskFragment;
  showSubtaskInput: boolean;
  taskRef: React.MutableRefObject<RichEditorHandle | null>;
}

/**
 * SubtaskController
 * controller
 */
export default function SubtasksController({ task, showSubtaskInput, taskRef }: SubtasksControllerProps): JSX.Element {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const { addToast } = useToast();

  const subTasksSlice = taskSubTasks(orgID, task.id, { orderBy: 'createdAt' });
  useFirestoreConnect(subTasksSlice.query);

  const subtasksSelector = createSelector(
    [subTasksSlice.selector, (): string[] => task.orderedSubtaskIds ?? []],
    (allSubtasks, orderedIds) => {
      if (allSubtasks === undefined) return undefined;
      // use orderedSubtaskIds as a base for subtask order
      // ids present in order list but not on subtask list are removed
      const subtasksOnOrderList = orderedIds
        .map((subtaskId) => allSubtasks.find((subtask) => subtask.id === subtaskId))
        .filter(notUndefined);

      // all subtasks that are not on the order list are sorted by createdAt
      // and put at the end.
      const subtasksNotOnOrderList = allSubtasks
        .filter((subtask) => !subtasksOnOrderList.includes(subtask))
        .sort(compareByCreatedAtAsc);

      return [...subtasksOnOrderList, ...subtasksNotOnOrderList];
    },
  );

  const sortedSubtasks = useSelector(subtasksSelector) ?? [];

  const handleEnter = (featureName: string): void => {
    try {
      decode<TaskPartial>({ title: featureName }, 'TaskPartial');
    } catch {
      addToast({ type: 'error', message: strings.task.titleTooShort });
      return;
    }

    reduxStore
      .dispatch(createTask({ title: featureName, parent: task.id }))
      .then(unwrapResult)
      .then((slug) => {
        taskRef.current?.clear();
        segment.track('TaskCreated', { orgID, slug, location: 'TaskModalSubtask' });
      })
      .catch(() => {
        taskRef.current?.clear();
        addToast({ type: 'error', message: strings.task.failedToCreateTask });
      });
  };

  const reorderSubtasks = (newList: TaskFragment[]): void => {
    reduxStore
      .dispatch(updateTask({ id: task.id, orderedSubtaskIds: newList.map(({ id }) => id) }))
      .then(unwrapResult)
      .catch(() => addToast({ type: 'error', message: strings.task.failedToUpdateTask }));
  };

  return (
    <Subtasks
      key={task.id}
      addTaskRef={taskRef}
      handleEnter={handleEnter}
      onSubtaskListReorder={reorderSubtasks}
      orgID={orgID}
      showSubtaskInput={showSubtaskInput}
      subtasks={sortedSubtasks}
    />
  );
}
