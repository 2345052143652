import { Data } from '@taraai/types';
import { getPath, linkTo, routes } from 'components/Router/paths';
import { History, Location } from 'history';
import React, { useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
  generatePath,
  match,
  matchPath,
  Redirect,
  RouteComponentProps,
  RouteProps,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  reduxStore,
  RootStateWithProfile,
  selectActiveOrgIds,
  selectPreferredTeamId,
  selectUserTeams,
} from 'reduxStore';
import { firestore } from 'tools/libraries/firebaseValues';
import { getOriginalRouteCookie, removeOriginalRouteCookie } from 'tools/utils/originalRoute';

import AuthenticatedRoute from './AuthenticatedRoute';
import { getIsOnboardedSlice } from './getIsOnboarded';

/**
 * Render route if user is authenticated and onboarded,
 * otherwise show Onboarding.
 */
export const OnboardedRoute: React.FC<RouteProps> = ({ children, render, ...props }) => {
  const childrenProvided = React.Children.count(children) > 0;

  if (childrenProvided && render) {
    // TODO: better wording here
    // eslint-disable-next-line no-console
    console.warn('both children and render provided, will use render()');
  }

  return (
    <AuthenticatedRoute
      {...props}
      render={(renderProps): React.ReactElement => (
        <RouteRenderer render={render} renderProps={renderProps}>
          {children}
        </RouteRenderer>
      )}
    />
  );
};

type Params = {
  orgID?: Data.Id.OrganizationId;
  teamID?: Data.Id.TeamId;
  requirementID?: Data.Id.RequirementId;
  sprintID?: Data.Id.SprintId;
  taskSlug?: Data.Id.TaskSlug;
};

type ParamsKey = keyof Params;

const paramOrder: ParamsKey[] = ['orgID', 'teamID', 'requirementID', 'sprintID', 'taskSlug'];

async function getBestOrgIdReplacement(params: Readonly<Params>): Promise<string> {
  const state = reduxStore.getState() as RootStateWithProfile;
  const preferredOrgID = selectActiveOrgIds(state)[0];
  const { orgID: paramOrgID = preferredOrgID } = params;
  return paramOrgID === '*' ? preferredOrgID : paramOrgID;
}

async function getBestTeamIdReplacement(params: Readonly<Params>): Promise<string> {
  const state = reduxStore.getState() as RootStateWithProfile;
  const orgID = params.orgID as string;
  const paramTeamID = params.teamID;
  const preferredTeamId = selectPreferredTeamId(orgID)(state);
  const teamIDs = selectUserTeams(orgID)(state).map((team) => team.id) || [];
  return paramTeamID && teamIDs.includes(paramTeamID) ? paramTeamID : preferredTeamId;
}

async function getBestRequirementIdReplacement(params: Readonly<Params>): Promise<string> {
  const orgID = params.orgID as string;
  const { teamID, requirementID: paramRequirementID } = params;
  const requirementsCollection = firestore().collection('orgs').doc(orgID).collection('requirements');
  if (paramRequirementID && paramRequirementID !== '*') {
    const paramRequirementDoc = await requirementsCollection.doc(paramRequirementID).get();
    if (
      paramRequirementDoc.exists &&
      (teamID ? (paramRequirementDoc.data()?.assignedTeamIds ?? []).includes(teamID) : true)
    ) {
      return paramRequirementID;
    }
  }
  let requirementsQuery = requirementsCollection.where('archived', '==', false).where('deleted', '==', false);
  if (teamID) requirementsQuery = requirementsQuery.where('assignedTeamIds', 'array-contains', teamID);
  const requirements = await requirementsQuery.orderBy('createdAt', 'desc').limit(1).get();
  const requirementIDs = requirements.docs.map((doc) => doc.id);
  return requirementIDs[0];
}

async function getBestSprintIdReplacement(params: Readonly<Params>): Promise<string> {
  const orgID = params.orgID as string;
  const { teamID, sprintID: paramSprintID } = params;
  const sprintsCollection = firestore().collection('orgs').doc(orgID).collection('sprints');
  if (paramSprintID && paramSprintID !== '*') {
    const paramSprintDoc = await sprintsCollection.doc(paramSprintID).get();
    if (paramSprintDoc.exists && (teamID ? paramSprintDoc.data()?.teamId === teamID : true)) {
      return paramSprintID;
    }
  }
  let sprintsQuery = sprintsCollection.where('deleted', '==', false);
  if (teamID) sprintsQuery = sprintsQuery.where('teamId', '==', teamID);
  const sprints = await sprintsQuery.orderBy('isComplete', 'desc').limit(1).get();
  const sprintIDs = sprints.docs.map((doc) => doc.id);
  return sprintIDs[0];
}

async function getBestTaskIdReplacement(params: Readonly<Params>): Promise<string> {
  const orgID = params.orgID as string;
  const { requirementID, sprintID, taskSlug: paramTaskSlug } = params;
  const tasksCollection = firestore().collection('orgs').doc(orgID).collection('tasks');
  if (paramTaskSlug && paramTaskSlug !== '*') {
    const paramTaskDocs = await tasksCollection.where('slug', '==', paramTaskSlug).limit(1).get();
    const paramTaskDoc = paramTaskDocs.empty ? null : paramTaskDocs.docs[0];
    if (
      paramTaskDoc &&
      paramTaskDoc.exists &&
      (requirementID ? paramTaskDoc.data()?._relationships.requirement === requirementID : true) &&
      (sprintID ? paramTaskDoc.data()?.sprint === sprintID : true)
    ) {
      return paramTaskSlug;
    }
  }
  let tasksQuery = tasksCollection.where('deleted', '==', false);
  if (requirementID) tasksQuery = tasksQuery.where('_relationships.requirement', '==', requirementID);
  if (sprintID) tasksQuery = tasksQuery.where('sprint', '==', sprintID);
  const tasks = await tasksQuery.orderBy('createdAt', 'desc').limit(1).get();
  const tasksIDs = tasks.docs.map((doc) => doc.data().slug);
  return tasksIDs[0];
}

async function getBestReplacement(params: Readonly<Params>, paramKey: ParamsKey): Promise<string | null> {
  try {
    switch (paramKey) {
      case 'orgID':
        return getBestOrgIdReplacement(params);
      case 'teamID':
        return getBestTeamIdReplacement(params);
      case 'requirementID':
        return getBestRequirementIdReplacement(params);
      case 'sprintID':
        return getBestSprintIdReplacement(params);
      case 'taskSlug':
        return getBestTaskIdReplacement(params);
    }
  } catch {
    return null;
  }
}

function getCurrentRouteMatch(pathname: string): match<Params> | undefined {
  return keys(routes)
    .map((routeKey) =>
      matchPath(pathname, {
        path: getPath(routeKey),
        exact: true,
        strict: true,
      }),
    )
    .find(isDynamic);
}

async function dynamicReroute(
  routeMatch: match<Params>,
  history: History,
  location: Location<History.PoorMansUnknown>,
): Promise<void> {
  const { path, params } = routeMatch;
  try {
    const newParams = await paramOrder.reduce(async (previousPromise: Promise<Params>, paramKey: ParamsKey) => {
      const currentParams = await previousPromise;
      const fallback = currentParams[paramKey];
      currentParams[paramKey] = (await getBestReplacement(currentParams, paramKey)) ?? fallback;
      return currentParams;
    }, Promise.resolve(params));
    const finalPath = generatePath(path, newParams);
    const pathMatchesLocation = matchPath(location.pathname, { path })?.isExact ?? false;
    const pathMatchesFinal = matchPath(finalPath, { path })?.isExact ?? false;
    if (pathMatchesLocation && pathMatchesFinal) {
      history.replace({ ...location, pathname: finalPath });
    }
  } catch {
    history.replace('/');
  }
}

const RouteRenderer: React.FC<{
  render: RouteProps['render'];
  renderProps: RouteComponentProps;
}> = ({ children, render, renderProps }): React.ReactElement | null => {
  const history = useHistory();
  const { orgID } = useParams<{
    orgID?: Data.Id.OrganizationId;
  }>();
  const location = useLocation();
  const routeMatch = useMemo(() => getCurrentRouteMatch(location.pathname), [location.pathname]);
  useLayoutEffect(() => {
    if (routeMatch) {
      dynamicReroute(routeMatch, history, location);
    }
  }, [history, location, routeMatch]);
  const isOnboardedSlice = getIsOnboardedSlice(orgID);
  useFirestoreConnect(isOnboardedSlice.query);
  const isOnboarded = useSelector(isOnboardedSlice.selector);
  if (isOnboarded === undefined) {
    return null;
  }
  if (!isOnboarded) {
    return <Redirect to={linkTo('onboarding', { orgID })} />;
  }
  // It may happen that before entering the app the user wanted to enter a different page
  // They got redirected to login and now we want to redirect them back to the original route
  const originalRoute = getOriginalRouteCookie();
  if (originalRoute) {
    removeOriginalRouteCookie();
    return <Redirect to={originalRoute} />;
  }
  return <>{render ? render(renderProps) : children}</>;
};

function keys<Key extends string>(object: Record<Key, unknown>): Key[] {
  return Object.keys(object) as Key[];
}

function isDynamic(matchedPath: match | null | undefined): matchedPath is match {
  if (!matchedPath) {
    return false;
  }
  return Object.values(matchedPath.params).some((param) => param === '*');
}
