import { createSelector } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import PullRequestsHomepage from 'components/app/controllers/views/PullRequestsHomepage';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { getOpenPullRequests, selectAuth } from 'reduxStore';
import { strings } from 'resources';
import { useConnectGitHubAccount, useInstallGitHub } from 'tools';

/**
 * gets active sprints from store and passes them to the SprintsRow
 */
export default function CreatedPRs(): JSX.Element {
  const { orgID } = useParams<{
    orgID: Data.Id.OrganizationId;
  }>();
  const openPullRequestsSlice = getOpenPullRequests(orgID);
  const { uid: profileId } = useSelector(selectAuth);
  const prsCreatedByUser = createSelector(openPullRequestsSlice.selector, (pullRequestsData) =>
    pullRequestsData?.filter(
      (pullRequest: Data.PullRequest<Data.ExternalRepo.Any>) => pullRequest.user.id === profileId,
    ),
  );
  const pullRequests = useSelector(prsCreatedByUser) || [];

  useFirestoreConnect(openPullRequestsSlice.query);

  const gitHubInstallation = useInstallGitHub();
  const gitHubConnectAccount = useConnectGitHubAccount();

  return (
    <PullRequestsHomepage
      className={css`
        padding: 0rem;
        border-radius: 0.1875rem;
      `}
      connectGitHubAccountData={gitHubConnectAccount}
      details={
        gitHubConnectAccount.accounts.length === 0 || !gitHubInstallation.id
          ? strings.dashboard.pullRequest.empty.enableFeature
          : strings.dashboard.pullRequest.empty.noPullRequestsDescription
      }
      icon={gitHubConnectAccount.accounts.length === 0 || !gitHubInstallation.id ? 'github' : 'pullRequest'}
      installGitHubData={gitHubInstallation}
      orgID={orgID}
      pullRequests={pullRequests}
      pullRequestTableStyle={css`
        box-shadow: none;
      `}
      showBranch
      showCommentsCount
      showDetailsButton={false}
      showReviewPRButton={false}
      showSorting={false}
      title={
        !gitHubInstallation.id || gitHubConnectAccount.accounts.length === 0
          ? strings.dashboard.pullRequest.empty.githubProfileNotLinked
          : strings.dashboard.pullRequest.empty.noCreatedByYouPRs
      }
    />
  );
}
