import { EmptyBacklogImportCard } from 'components/app/controllers/views/EmptyBacklogView';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { reduxStore } from 'reduxStore';
import { openImportModal } from 'reduxStore/imports/actions';
import { sprintBacklogButtons } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';

export function AsanaEmptyBacklogCardController(): JSX.Element {
  const onClick = useCallback(() => {
    reduxStore.dispatch(openImportModal('asana'));
  }, []);

  return (
    <EmptyBacklogImportCard
      dataCy={sprintBacklogButtons.ASANA}
      icon={
        <Icon
          className={css`
            width: 1.5rem;
            height: 1.5rem;
            padding: 0;
          `}
          dataCy={sprintBacklogButtons.ASANA}
          name='asana'
        />
      }
      label={strings.requirements.importCardLabels.asana}
      onClick={onClick}
    />
  );
}
