import { Tooltip } from '@material-ui/core';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React from 'react';
import { strings } from 'resources/i18n';

export interface SprintInsightInfoProps extends React.HTMLProps<HTMLDivElement> {
  estimatedEffort: number;
}

/**
 * SprintInsightInfo tooltip indicator for sprint
 *
 */
export default function SprintInsightInfo({ estimatedEffort, ...props }: SprintInsightInfoProps): JSX.Element {
  return (
    <div
      {...props}
      className={css`
        color: #389e0d;
        font-size: 1.125rem;
        font-weight: 600;
        display: flex;
        align-items: center;
      `}
    >
      {estimatedEffort || (
        <span
          className={css`
            font-size: 1.125rem;
            color: #c8d0df;
          `}
        >
          {strings.sprints.insights.empty}
        </span>
      )}
      <Tooltip
        arrow
        enterDelay={0}
        interactive
        title={
          <div
            className={css`
              color: #ffffff;
              font-size: 0.875rem;
              font-weight: normal;
              padding: 0.1875rem;
              line-height: 1.125rem;
              max-width: 15.625rem;
            `}
          >
            {estimatedEffort
              ? strings.sprints.insights.estimatedEffortInfo
              : strings.sprints.insights.emptyEstimatedEffortInfo}
          </div>
        }
        TransitionProps={{ timeout: 600 }}
      >
        <div>
          <Icon
            className={css`
              padding: 0rem;
              padding-top: 0.1875rem;
              padding-left: 0.25rem;
              height: 0.75rem;
              width: 0.75rem;
            `}
            name='info'
          />
        </div>
      </Tooltip>
    </div>
  );
}
