import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import Fuse from 'fuse.js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { reduxStore, RootStateWithProfile } from 'reduxStore/store';

const fuseOptions = {
  isCaseSensitive: false,
  includeScore: false,
  includeMatches: false,
  shouldSort: false,
  findAllMatches: true,
  keys: ['title'],
  threshold: 0.3,
};

type TaskFragment = Pick<UI.UITask, 'id' | 'title' | 'assignee' | 'labels'>;

export interface SearchQuery {
  text: string;
  labels: string[];
  mentions: Data.Id.UserId[];
}

export interface SearchState {
  query: SearchQuery | undefined;
  matchedIds: string[];
}

const initialState: SearchState = {
  query: undefined,
  matchedIds: [],
};

const selectSearchState = (state: RootStateWithProfile): SearchState => state.search;

const selectSearchQuery = createSelector(selectSearchState, ({ query }) => query);

const getTaskHasMatch = (task: TaskFragment, query?: SearchQuery): boolean => {
  if (!query) return true;

  const fuse = new Fuse([task], fuseOptions);

  if (isNonEmptyString(query.text) && fuse.search(query.text).length === 0) return false;

  const hasAssignee = query.mentions.length === 0 || (task.assignee !== null && query.mentions.includes(task.assignee));
  const hasAllLabels = query.labels.every((label) => task.labels.includes(label));

  return hasAssignee && hasAllLabels;
};

export const selectSearchCount = createSelector(selectSearchState, ({ matchedIds, query }) =>
  query ? matchedIds.length : null,
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    search: (state, action: PayloadAction<SearchQuery | undefined>) => {
      state.query = action.payload;
    },
    addIdMatch: (state, action: PayloadAction<string>) => {
      if (!state.matchedIds.includes(action.payload)) {
        state.matchedIds.push(action.payload);
      }
    },
    removeIdMatch: (state, action: PayloadAction<string>) => {
      state.matchedIds = state.matchedIds.filter((id) => id !== action.payload);
    },
  },
});

export const searchActions = searchSlice.actions;
export const searchReducer = searchSlice.reducer;

export function useTaskHasMatch(task: TaskFragment): boolean {
  const query = useSelector(selectSearchQuery);
  const hasMatch = getTaskHasMatch(task, query);

  useEffect(() => {
    if (!hasMatch) return;

    reduxStore.dispatch(searchActions.addIdMatch(task.id));
    return () => {
      reduxStore.dispatch(searchActions.removeIdMatch(task.id));
    };
  }, [hasMatch, task.id]);

  return hasMatch;
}
