import { css } from 'emotion';
import React, { forwardRef, Ref } from 'react';

// eslint-disable-next-line react/display-name
const TopSpace = forwardRef((_props: unknown, ref: Ref<HTMLDivElement>) => (
  <div
    ref={ref}
    className={css`
      padding: 4rem 0 0;
      box-sizing: border-box;
    `}
  />
));

export default TopSpace;
