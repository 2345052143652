import { styled } from '@taraai/design-system';
import { MaybeReusableLink } from 'components/app/controllers/InviteUsersController';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';
import { strings } from 'resources/i18n';

import InviteLinkSnippet from './InviteLinkSnippet';

type Props = {
  maybeReusableLink?: MaybeReusableLink;
  loadingInvitationLink: boolean;
  createInviteLink: () => void;
};

export const InviteLinkSection = ({
  maybeReusableLink,
  loadingInvitationLink,
  createInviteLink,
}: Props): JSX.Element => {
  if (!maybeReusableLink?.invitationLink) {
    return (
      <LinkButton
        disabled={loadingInvitationLink}
        loading={loadingInvitationLink}
        onClick={createInviteLink}
        size='small'
      >
        <CopyIcon name='copy' />
        {strings.inviteUserPopup.link.submit}
      </LinkButton>
    );
  }
  return <InviteLinkSnippet maybeReusableLink={maybeReusableLink} />;
};

const LinkButton = styled(
  Button,
  {
    'backgroundColor': '$focus',
    'margin': '1rem',
    'marginTop': '0rem',
    'padding': '0.6rem',
    ':hover': { backgroundColor: '#18a0fb' },
  },
  {
    loading: {
      true: {
        cursor: 'progress',
      },
    },
  },
);

const CopyIcon = styled(Icon, {
  color: 'white',
  padding: '0rem',
  verticalAlign: 'sub',
  paddingRight: '0.3125rem',
});
