import styled from '@emotion/styled';
import { TrelloIcon } from 'components/app/controllers/TrelloImportBacklogIndicatorController';
import { ExportInstructionUrl } from 'components/app/controllers/views/ImportModalView';
import IntegrationBoxView from 'components/app/controllers/views/IntegrationBoxView';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { reduxStore } from 'reduxStore';
import { openImportModal } from 'reduxStore/imports/actions';
import { integrationTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { ASANA_EXPORT_INSTRUCTION_URL, TRELLO_EXPORT_INSTRUCTION_URL } from 'tools';

function ImportButton({ onClick, dataCy }: { onClick: () => void; dataCy?: string }): JSX.Element {
  return (
    <Button
      className={css`
        padding: 0.25rem 0.5rem;
        outline: none;
      `}
      color='ghost'
      data-cy={dataCy}
      onClick={onClick}
    >
      {strings.integrations.importBtnLabel}
    </Button>
  );
}

const BoxesSeparator = styled('div')`
  margin-top: 1rem;
`;

/**
 * Responsible for displaying importable services list on the Integrations page.
 */
function ImportServicesListController(): JSX.Element {
  const onTrelloActionBtnClick = useCallback(() => {
    reduxStore.dispatch(openImportModal('trello'));
  }, []);

  const trelloDescription = (
    <>
      {strings.integrations.trello.description}
      <ExportInstructionUrl
        className={css`
          margin-left: 0.3rem;
        `}
        href={TRELLO_EXPORT_INSTRUCTION_URL}
        label={strings.integrations.trello.exportInstructionUrlLabel}
      />
    </>
  );

  const onAsanaActionBtnClick = useCallback(() => {
    reduxStore.dispatch(openImportModal('asana'));
  }, []);

  const asanaDescription = (
    <>
      {strings.integrations.asana.description}
      <ExportInstructionUrl
        className={css`
          margin-left: 0.3rem;
        `}
        href={ASANA_EXPORT_INSTRUCTION_URL}
        label={strings.integrations.asana.exportInstructionUrlLabel}
      />
    </>
  );

  return (
    <>
      <p
        className={css`
          margin-top: 1.5rem;
          font-size: 0.875rem;
          color: ${atomic.get(atomic.colors.grey6)};
          text-transform: uppercase;
          padding-bottom: 1rem;
        `}
      >
        {strings.integrations.importSectionHeader}
      </p>
      <IntegrationBoxView
        actionButton={<ImportButton dataCy={integrationTestIDs.TRELLO_IMPORT} onClick={onTrelloActionBtnClick} />}
        description={trelloDescription}
        icon={<TrelloIcon padding='0.5rem' />}
        isLoading={false}
        title={strings.integrations.trello.title}
      />
      <BoxesSeparator />
      <IntegrationBoxView
        actionButton={<ImportButton dataCy={integrationTestIDs.ASANA_IMPORT} onClick={onAsanaActionBtnClick} />}
        description={asanaDescription}
        icon={
          <Icon
            className={css`
              width: 2rem;
              height: 2rem;
              padding: 0.5rem;
            `}
            name='asana'
          />
        }
        isLoading={false}
        title={strings.integrations.asana.title}
      />
    </>
  );
}

export default ImportServicesListController;
