import Icon from 'components/core/controllers/views/Icon';
import { css, cx, keyframes } from 'emotion';
import React from 'react';
import { atomic } from 'resources/theme';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

interface FastSmallSpinnerProps {
  color?: string;
  className?: string;
  spinnerStyles?: string;
  /** optional size in rem */
  size?: number;
}

const FastSmallSpinner = ({
  color = atomic.get(atomic.colors.grey6),
  className,
  spinnerStyles,
  size = 0.875,
}: FastSmallSpinnerProps): JSX.Element => (
  <Icon
    className={cx(
      css`
        animation: ${spin} 1s linear infinite;
        width: ${size}rem;
        height: ${size}rem;
      `,
      className,
      spinnerStyles,
    )}
    color={color}
    name='loading'
  />
);

export default FastSmallSpinner;
