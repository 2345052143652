import { Data, UI } from '@taraai/types';
import { isNonEmptyString } from '@taraai/utility';
import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';
import { createIndividualSelector, IndividualQuery, inertIndividualQuery } from 'reduxStore/utils/selectors';

export function getImport(orgId: string, importId: Data.Id.ImportId): IndividualQuery<UI.UIImport> {
  if (!isNonEmptyString(orgId) || !isNonEmptyString(importId)) {
    return inertIndividualQuery();
  }

  const queryImport: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgId}/imports`,
    where: [['__name__', '==', importId]],
    storeAs: `org/${orgId}/import/${importId}`,
  };
  return {
    query: [queryImport],
    selector: createIndividualSelector(importId, queryImport),
  };
}
