import { makeStyles, Tooltip } from '@material-ui/core';
import { EditorState } from 'draft-js';
import { css, cx } from 'emotion';
import React, { useRef } from 'react';
import { atomic } from 'resources';

import { MentionTooltipContent } from './MentionTooltipContent';

const useStylesTooltip = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow:
      '0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.18)',
  },
  arrow: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

export interface DraftMentionProps {
  className?: string;
  children?: React.ReactNode;
  getEditorState?: () => EditorState;
  entityKey?: string;
  tooltipClassName?: string;
}

/**
 * This component decorates Draft.js `mention` entity
 * with Tooltip component that display basic user info.
 */
export default function DraftMentionLegacy({
  className,
  entityKey,
  children,
  getEditorState,
  tooltipClassName,
}: DraftMentionProps): JSX.Element {
  const ref = useRef<HTMLSpanElement>(null);
  const tooltipClasses = useStylesTooltip();
  if (!getEditorState || !entityKey) {
    return <span>{children}</span>;
  }
  const mentionEntity = getEditorState().getCurrentContent().getEntity(entityKey);
  const userId = mentionEntity.getData().id;
  if (!userId) {
    return (
      <span
        className={css`
          ${atomic.color(atomic.colors.error1)};
          border-bottom: 1px dotted;
        `}
      >
        {children}
      </span>
    );
  }
  return (
    <Tooltip
      arrow
      classes={tooltipClasses}
      className={tooltipClassName}
      interactive
      placement='bottom'
      title={<MentionTooltipContent userId={userId} />}
    >
      <span
        ref={ref}
        className={cx(
          css`
            ${atomic.color(atomic.colors.focus)};
          `,
          className,
        )}
      >
        {children}
      </span>
    </Tooltip>
  );
}
