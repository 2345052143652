/* eslint-disable react/no-unused-prop-types */
import Box from 'components/core/controllers/views/Box';
import Icon from 'components/core/controllers/views/Icon';
import ModalContainer from 'components/core/controllers/views/ModalContainer';
import { css, cx } from 'emotion';
import React from 'react';

/**
 * Short description of ModalProps.
 */
export interface ModalProps extends React.HTMLProps<HTMLDialogElement> {
  header?: JSX.Element | JSX.Element[] | string;
  footer?: JSX.Element | JSX.Element[] | string;
  headerStyle?: string;
  footerStyle?: string;
  bodyStyle?: string;
  noExit?: boolean;
  superHeader?: JSX.Element | JSX.Element[] | string;
  navigateRight?: () => void;
  navigateLeft?: () => void;
  closeModal?: () => void;
  noModal?: boolean;
  modalContainerHeader?: JSX.Element;

  /**
   * This is going to be added to the default z-index value.
   *
   * It can be used when showing multiple modals at the same time.
   */
  zIndexPlus?: number;
}

/**
 * Modal does…
 *
 */
export default function Modal({
  className,
  children,
  header,
  footer,
  headerStyle,
  footerStyle,
  bodyStyle,
  noExit,
  superHeader,
  navigateLeft,
  navigateRight,
  closeModal,
  noModal,
  modalContainerHeader,
  zIndexPlus,
}: ModalProps): JSX.Element {
  return (
    <ModalContainer
      closeModal={closeModal}
      modalContainerHeader={modalContainerHeader}
      navigateLeft={navigateLeft}
      navigateRight={navigateRight}
      noModal={noModal}
      routeBack
      zIndexPlus={zIndexPlus}
    >
      {({ Ref, toggleModal }: { Ref: string; toggleModal: () => void }): JSX.Element => (
        <div
          className={css`
            margin: auto;
          `}
        >
          {superHeader}
          <Box
            Ref={Ref}
            className={cx(
              css`
                height: auto;
                border-radius: 0.375rem;
              `,
              className,
            )}
            white
          >
            {header && (
              <div
                className={cx(
                  css`
                    display: flex;
                    justify-content: space-between;
                    border-bottom: #eaeef5 solid 0.0625rem;
                    align-items: center;
                    padding: 0.75rem 1.5rem;
                  `,
                  headerStyle,
                )}
              >
                {header}
                {!noExit && (
                  <div
                    className={css`
                      height: 1.75rem;
                    `}
                    onClick={toggleModal}
                    onKeyDown={toggleModal}
                    role='button'
                    tabIndex={0}
                  >
                    <Icon
                      className={css`
                        width: 0.75rem;
                        height: 0.75rem;
                      `}
                      name='modalexit'
                      onClick={toggleModal}
                    />
                  </div>
                )}
              </div>
            )}
            {children && (
              <div
                className={cx(
                  css`
                    padding: 1rem 1.5rem;
                  `,
                  bodyStyle,
                )}
              >
                {children}
              </div>
            )}
            {footer && (
              <div
                className={cx(
                  css`
                    border-top: #eaeef5 solid 0.0625rem;
                    padding: 0.75rem 1.5rem;
                  `,
                  footerStyle,
                )}
              >
                {footer}
              </div>
            )}
            <Box
              className={css`
                height: auto;
                border-radius: 0.1875rem 0.1875rem 0rem 0rem;
              `}
            />
          </Box>
        </div>
      )}
    </ModalContainer>
  );
}
