"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.responseDecoder = exports.commandDecoder = exports.commandTypeDecoder = void 0;
var ts_data_json_1 = require("ts.data.json");
var registration_1 = require("../decoders/registration");
var result_1 = require("./result");
/**
 * Decoder for command type
 */
exports.commandTypeDecoder = ts_data_json_1.JsonDecoder.oneOf([
    ts_data_json_1.JsonDecoder.isExactly('SetupInvitationToken'),
    ts_data_json_1.JsonDecoder.isExactly('SetupOrganization'),
    ts_data_json_1.JsonDecoder.isExactly('SetupProfile'),
    ts_data_json_1.JsonDecoder.isExactly('InviteTeamMembers'),
], 'SetupRegistration.CommandType');
/**
 * Decode incoming command as one of the possible options.
 */
exports.commandDecoder = ts_data_json_1.JsonDecoder.object({ type: exports.commandTypeDecoder }, 'WithType').then(function (_a) {
    var type = _a.type;
    switch (type) {
        case 'SetupInvitationToken':
            return ts_data_json_1.JsonDecoder.object({
                type: ts_data_json_1.JsonDecoder.isExactly('SetupInvitationToken'),
                payload: registration_1.SetupInvitationTokenStepDataDecoder,
            }, 'SetupRegistration.SetupInvitationToken');
        case 'SetupOrganization':
            return ts_data_json_1.JsonDecoder.object({
                type: ts_data_json_1.JsonDecoder.isExactly('SetupOrganization'),
                payload: registration_1.SetupOrganizationStepDataDecoder,
            }, 'SetupRegistration.SetupOrganization');
        case 'SetupProfile':
            return ts_data_json_1.JsonDecoder.object({
                type: ts_data_json_1.JsonDecoder.isExactly('SetupProfile'),
                payload: registration_1.SetupProfileStepDataDecoder,
            }, 'SetupRegistration.SetupProfile');
        case 'InviteTeamMembers':
            return ts_data_json_1.JsonDecoder.object({
                type: ts_data_json_1.JsonDecoder.isExactly('InviteTeamMembers'),
                payload: registration_1.InviteTeamMembersStepDataDecoder,
            }, 'SetupRegistration.InviteTeamMembers');
        default:
            return ts_data_json_1.JsonDecoder.fail("Failed to decode " + type + " command");
    }
});
exports.responseDecoder = result_1.getDecoder(ts_data_json_1.JsonDecoder.isExactly(null));
