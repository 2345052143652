import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch, ReducerAction, useReducer } from 'react';

export type State = {
  isOnboarded: boolean;
  orgName: string;
  taskTitles: string[];
};

export type Action = ReducerAction<typeof slice['reducer']>;

export const initialState: State = {
  isOnboarded: false,
  orgName: '',
  taskTitles: [],
};

const slice = createSlice({
  name: 'flowWrapper',
  initialState,
  reducers: {
    finishOnboarding: (state): void => {
      state.isOnboarded = true;
    },
    setOrgName: (state, action: PayloadAction<string>): void => {
      state.orgName = action.payload;
    },
    setTaskTitles: (state, action: PayloadAction<string[]>): void => {
      state.taskTitles = action.payload;
    },
  },
});

export const { actions } = slice;

export const useFlowWrapperReducer = (state?: State): [State, Dispatch<Action>] =>
  useReducer(slice.reducer, state ?? initialState);
