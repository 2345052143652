import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Logo from 'components/core/controllers/views/Logo';
import React from 'react';

type Props = {
  color: string;
};

export const BlankRequirement: React.FC<Props> = ({ color }) => (
  <Wrapper>
    <Logo color={color} name='' shape='square' size='medium' />
    <Requirement>
      <NamePlaceholder />
      <TasksCountPlaceholder />
    </Requirement>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 4.0625rem;
  padding: 0 1rem;
  box-shadow: 0 0.0625rem 0 0 #eaeef5;
`;

const Requirement = styled.div`
  display: flex;
  flex-direction: column;
`;

const placeholderStyles = css`
  margin: 0.25rem 0.75rem;
  height: 0.5rem;
  border-radius: 0.0625rem;
  background-color: #eaeef5;
`;

const NamePlaceholder = styled.span`
  ${placeholderStyles};
  width: 7.75rem;
`;

const TasksCountPlaceholder = styled.span`
  ${placeholderStyles};
  width: 3.25rem;
`;
