import React, { useCallback, useState } from 'react';
import { strings } from 'resources/i18n';
import { auth } from 'tools/libraries/firebaseValues';
import { useToast } from 'tools/utils/hooks/useToast';

import EmailVerificationLayout from './EmailVerificationLayout';

export default function EmailVerificationController(): JSX.Element {
  const [resendClicked, setResendClicked] = useState<boolean>(false);
  const { addToast } = useToast();

  const onResendEmailClick = useCallback(async (): Promise<void> => {
    try {
      await auth().currentUser?.sendEmailVerification();
      setResendClicked(true);
    } catch {
      addToast({
        message: strings.onboarding.emailVerification.error,
        timeoutMs: 3500,
        type: 'error',
      });
    }
  }, [addToast]);

  return <EmailVerificationLayout onResendEmailClick={onResendEmailClick} resendClicked={resendClicked} />;
}
