import Tara, { Data, UI } from '@taraai/types';
import SprintDropdown, { SprintDropdownProps } from 'components/app/controllers/SprintDropdown';
import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import Modal from 'components/core/controllers/views/Modal';
import Text from 'components/core/controllers/views/Text';
import { css } from 'emotion';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { strings } from 'resources/i18n';

export type CompleteSprintModalProps = React.HTMLProps<HTMLDialogElement> &
  SprintDropdownProps & {
    orgID: Data.Id.OrganizationId;
    allSprintTasks: Tara.Task[] | undefined;
    completedEffort: number;
    completedSprintTasks: Tara.Task[] | undefined;
    currentSprint: UI.UISprint | undefined;
    effortCompletedPercent: number;
    handleCompleteSprint: () => void;
    tasksRemaining: number;
    totalCommits: number;
    totalEffort: number;
    allPullRequestsCount: number;
    closedPullRequestsCount: number;
    upcomingSprints: UI.UISprint[] | undefined;
    // @FIXME even though CompleteSprintModal does not use the props below, I had to edit that bc the hook providing completed sprint data is used in a different place
    totalTasksNumber: number | null;
    totalTasksCompletedNumber: number;
  };

/**
 * CompleteSprintModal
 * view for the complete sprint modal, takes in all data from complete sprint controller
 *
 */
export default function CompleteSprintModal({
  orgID,
  allSprintTasks,
  completedEffort,
  completedSprintTasks,
  currentSprint,
  effortCompletedPercent,
  setSelectedSprint,
  handleCompleteSprint,
  tasksRemaining,
  totalCommits,
  totalEffort,
  allPullRequestsCount,
  closedPullRequestsCount,
  selectedSprint,
  upcomingSprints,
}: CompleteSprintModalProps): JSX.Element {
  const history = useHistory();

  return useMemo(
    () => (
      <Modal
        className={css`
          width: 500px;
        `}
        footer={
          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            `}
          >
            <Button
              className={css`
                margin-right: 0.8125rem;
                min-height: 2.375rem;
                min-width: 6.25rem;
                padding: 0rem;
              `}
              color='ghost'
              onClick={(): void => history.goBack()}
            >
              {strings.sprints.complete.cancel}
            </Button>
            <Button
              className={css`
                min-height: 2.375rem;
                min-width: 6.25rem;
                padding: 0rem;
              `}
              disabled={!selectedSprint && tasksRemaining > 0}
              onClick={handleCompleteSprint}
            >
              {strings.sprints.complete.complete}
            </Button>
          </div>
        }
        header={
          <Text
            className={css`
              color: #303f4b;
              font-size: 16px;
              font-weight: 500;
            `}
          >
            {currentSprint &&
              strings.formatString(strings.sprints.complete.completeSprint, {
                name: currentSprint.sprintName,
              })}
          </Text>
        }
      >
        <div
          className={css`
            text-align: center;
            padding: 10px 35px;
          `}
        >
          <div
            className={css`
              color: #303f4b;
              font-size: 16px;
              font-weight: 500;
            `}
          >
            {currentSprint &&
              strings.formatString(strings.sprints.complete.sprintSummaryName, {
                name: currentSprint.sprintName,
              })}
          </div>
          <div
            className={css`
              color: #575f65;
              font-size: 14px;
              font-weight: normal;
              margin-top: 10px;
            `}
          >
            {strings.sprints.complete.sprintSummaryDetails}
          </div>
          <div
            className={css`
              border: solid 1px #dee3ec;
              border-radius: 3px;
              background-color: #fbfbfd;
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              justify-content: space-between;
              padding: 10px 22px;
              margin-top: 30px;
              margin-bottom: 10px;
            `}
          >
            <div
              className={css`
                align-items: center;
                display: flex;
                flex-direction: column;
              `}
            >
              <Icon
                className={css`
                  width: 24px;
                  height: 24px;
                `}
                name='greentick'
              />
              <div
                className={css`
                  color: #575f65;
                  font-size: 12px;
                  font-weight: 500;
                `}
              >
                {strings.sprints.complete.effortCompleted}
              </div>
              <div
                className={css`
                  display: flex;
                  padding-top: 8px;
                `}
              >
                <div
                  className={css`
                    font-size: 18px;
                    font-weight: 600;
                    padding-right: 4px;
                  `}
                >
                  {strings.formatString(strings.sprints.complete.effortCompletedAmount, {
                    completedEffort,
                    totalEffort,
                  })}
                </div>
                <div
                  className={css`
                    color: #949caf;
                    font-size: 18px;
                    font-weight: 600;
                  `}
                >
                  {strings.formatString(strings.sprints.complete.effortCompletedPercent, {
                    effortCompletedPercent,
                  })}
                </div>
              </div>
              <div
                className={css`
                  color: #949caf;
                  font-size: 12px;
                  font-weight: normal;
                  padding-top: 5px;
                `}
              >
                {strings.formatString(strings.sprints.complete.tasksDone, {
                  completedTasks: completedSprintTasks?.length ?? 0,
                  totalTasks: allSprintTasks?.length ?? 0,
                })}
              </div>
            </div>
            <span
              aria-label={
                completedSprintTasks?.length === 0 ? 'Congratulations!' : 'Oh Poo.' // FIXME: this should be in string/en.ts
              }
              className={css`
                font-size: 40px;
                line-height: 1;
                align-self: center;
                padding-top: 25px;
              `}
              role='img'
            >
              {completedSprintTasks?.length === 0 ? strings.emoji.poop : strings.emoji.confetti}
            </span>
            <div
              className={css`
                align-items: center;
                display: flex;
                flex-direction: column;
              `}
            >
              <Icon
                className={css`
                  width: 24px;
                  height: 24px;
                `}
                name='pullrequests'
              />
              <div
                className={css`
                  color: #575f65;
                  font-size: 12px;
                  font-weight: 500;
                `}
              >
                {strings.sprints.complete.pullRequestsClosed}
              </div>
              <div
                className={css`
                  font-size: 18px;
                  font-weight: 600;
                  padding-top: 8px;
                `}
              >
                {strings.formatString(strings.sprints.complete.pullRequestsClosedOverTotal, {
                  closedPullRequestsCount,
                  allPullRequestsCount,
                })}
              </div>
              <div
                className={css`
                  color: #949caf;
                  font-size: 12px;
                  font-weight: normal;
                  padding-top: 5px;
                `}
              >
                {strings.formatString(strings.sprints.complete.commits, {
                  number: totalCommits,
                })}
              </div>
            </div>
          </div>
          {tasksRemaining > 0 && (
            <div>
              <div
                className={css`
                  color: #303f4b;
                  font-size: 14px;
                  font-weight: 500;
                  margin-top: 30px;
                  margin-bottom: 16px;
                `}
              >
                {strings.formatString(strings.sprints.complete.moveRemainingTasksTo, {
                  number: tasksRemaining,
                })}
              </div>
              <div
                className={css`
                  text-align: left;
                `}
              >
                <SprintDropdown
                  currentSprint={currentSprint}
                  orgID={orgID}
                  selectedSprint={selectedSprint}
                  setSelectedSprint={setSelectedSprint}
                  upcomingSprints={upcomingSprints}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    ),
    [
      selectedSprint,
      tasksRemaining,
      handleCompleteSprint,
      currentSprint,
      completedEffort,
      totalEffort,
      effortCompletedPercent,
      completedSprintTasks,
      allSprintTasks,
      closedPullRequestsCount,
      allPullRequestsCount,
      totalCommits,
      orgID,
      setSelectedSprint,
      history,
      upcomingSprints,
    ],
  );
}
