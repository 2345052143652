/* eslint-disable no-loops/no-loops */

import { ContentBlock, ContentState, EditorState, SelectionState } from 'draft-js';

export const isInlineStyleSelected = (styleName: string) => (state: EditorState) =>
  state.getCurrentInlineStyle().includes(styleName);

export const isBlockTypeSelected = (blockType: string) => (state: EditorState) => {
  const selectionStart = state.getSelection().getStartKey();
  const selectionEnd = state.getSelection().getEndKey();
  return getSelectedBlocks(state.getCurrentContent(), selectionStart, selectionEnd).some(
    (block) => block.getType() === blockType,
  );
};

type SelectionDebugInfo = {
  [K in 'anchor' | 'focus']: {
    blockKey: string;
    blockText: string;
    offset: number;
    character: string;
  };
};

export function debugSelection(state: EditorState): SelectionDebugInfo {
  const selection = state.getSelection();

  const anchorKey = selection.getAnchorKey();
  const anchorOffset = selection.getAnchorOffset();
  const anchorBlock = state.getCurrentContent().getBlockForKey(anchorKey);
  const anchorCharacter = anchorBlock.getText().substring(anchorOffset, anchorOffset + 1);

  const focusKey = selection.getFocusKey();
  const focusOffset = selection.getFocusOffset();
  const focusBlock = state.getCurrentContent().getBlockForKey(focusKey);
  const focusCharacter = focusBlock.getText().substring(focusOffset, focusOffset + 1);

  return {
    anchor: {
      blockKey: anchorKey,
      blockText: anchorBlock.getText(),
      offset: anchorOffset,
      character: anchorCharacter,
    },
    focus: {
      blockKey: focusKey,
      blockText: focusBlock.getText(),
      offset: focusOffset,
      character: focusCharacter,
    },
  };
}

function getSelectedBlocks(content: ContentState, startKey: string, endKey: string): ContentBlock[] {
  const isSameBlock = startKey === endKey;
  const startingBlock = content.getBlockForKey(startKey);
  const selectedBlocks = [startingBlock];

  if (!isSameBlock) {
    let blockKey = startKey;

    while (blockKey !== endKey) {
      const nextBlock = content.getBlockAfter(blockKey);
      selectedBlocks.push(nextBlock);
      blockKey = nextBlock.getKey();
    }
  }

  return selectedBlocks;
}

export type Selection = {
  anchorKey: string;
  anchorOffset: number;
  hasFocus?: boolean;
} & (
  | {
      focusKey?: undefined;
      focusOffset?: undefined;
    }
  | {
      focusKey: string;
      focusOffset: number;
    }
);

export function withSelection(
  editorState: EditorState,
  { anchorKey, anchorOffset, focusKey = anchorKey, focusOffset = anchorOffset, hasFocus = false }: Selection,
): EditorState {
  return EditorState.forceSelection(
    editorState,
    SelectionState.createEmpty(anchorKey).merge({
      anchorKey,
      anchorOffset,
      focusKey,
      focusOffset,
      hasFocus,
    }) as SelectionState,
  );
}
