import { Tooltip } from '@material-ui/core';
import Icon from 'components/core/controllers/views/Icon';
import { css, cx } from 'emotion';
import React from 'react';
import { atomic, IconName } from 'resources';

interface DraftLinkPopperButtonProps {
  className?: string;
  iconName: IconName;
  onClick: () => void;
  title: string;
}

export default function DraftLinkPopperButton({
  className,
  iconName,
  onClick,
  title,
}: DraftLinkPopperButtonProps): JSX.Element {
  return (
    <Tooltip
      title={
        <div
          className={css`
            color: #ffffff;
            font-size: 0.875rem;
            font-weight: normal;
            line-height: 1.125rem;
          `}
        >
          {title}
        </div>
      }
      TransitionProps={{ timeout: 600 }}
    >
      <div>
        <Icon
          className={cx(
            css`
              color: ${atomic.get(atomic.colors.dark)};
              padding: 0;
              width: 1rem;
              height: 1rem;
              :hover {
                cursor: pointer;
                opacity: 0.7;
              }
            `,
            className,
          )}
          color={atomic.colors.white}
          name={iconName}
          onClick={onClick}
        />
      </div>
    </Tooltip>
  );
}
