import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Data, Functions } from '@taraai/types';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI } from 'reduxStore/utils/types';

export const setUserAccessLevel = createAction<{
  accessLevel: Data.AccessLevel;
  organizationId: Data.Id.ImportId;
  userId: Data.Id.UserId;
}>('setUserAccessLevel');

export const listOrganizationUsers = createAsyncThunk(
  'ListOrganizationUsers',
  async (actionPayload: void, { extra }) => {
    const { getFirebase, getOrgID } = extra as ExtraAPI;
    const organizationId = getOrgID();

    const payload: Functions.ListOrganizationUsers.Payload = {
      organizationId,
    };

    const result = await getFirebase().functions().httpsCallable('listOrganizationUsers')(payload);

    return decode<Functions.ListOrganizationUsers.Response>(result?.data, 'ListOrganizationUsersResponse');
  },
);
