import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { UITeamChangeset, UITeamPartial } from '@taraai/types/dist/ui';
import { decode } from 'reduxStore/utils/decoders';
import { ExtraAPI, Firestore } from 'reduxStore/utils/types';

type UpdateTeamPayload = UITeamChangeset & {
  meta?: {
    transaction: Firestore['Transaction'];
  };
};

export const updateTeam = createAsyncThunk(
  'UpdateTeam',
  async ({ id: teamID, meta, ...changes }: UpdateTeamPayload, { extra }) => {
    const { getFirestore, getOrgID } = extra as ExtraAPI;
    const orgId = getOrgID();
    const firestore = getFirestore();
    const path = `orgs/${orgId}/teams/${teamID}`;

    const { id, ...valid } = decode<UITeamPartial>(
      { id: teamID, ...changes, updatedAt: firestore.Timestamp.now() },
      'UITeamPartial',
    );

    // We need transaction for changeset with name
    if (changes.name) {
      const { name } = changes;

      // Use transaction from meta argument
      if (meta?.transaction) {
        await hasTeamWithSameNameFunc(firestore, meta.transaction, orgId, name);

        return meta.transaction.update(firestore.doc(path), valid);
      }

      // Or create a new transaction
      return firestore.runTransaction(async (transaction) => {
        await hasTeamWithSameNameFunc(firestore, transaction, orgId, name);

        transaction.update(firestore.doc(path), valid);
      });
    }

    if (meta?.transaction) {
      meta.transaction.update(firestore.doc(path), valid);
    } else {
      await firestore.update(path, valid);
    }
  },
);

/**
 * Verify if the name already exist in the firestore
 */
const hasTeamWithSameNameFunc = async (
  firestore: Firestore,
  transaction: Firestore['Transaction'],
  orgId: Data.Id.OrganizationId,
  newName?: string,
): Promise<void> => {
  if (!newName) return;

  const teamsCollectionRef = firestore.collection('orgs').doc(orgId).collection('teams');

  const allTeams = await firestore.get<Data.Team>(`orgs/${orgId}/teams`);
  const allTeamsIds = allTeams.docs.map(({ id }) => id);

  const lockedTeamDocs = await Promise.all(allTeamsIds.map((id) => transaction.get(teamsCollectionRef.doc(id))));

  const allTeamsNames = lockedTeamDocs.map((doc) => doc.data()?.name);

  const hasTeamWithSameName = allTeamsNames.map((name) => name.toUpperCase()).includes(newName.toUpperCase());

  if (hasTeamWithSameName) {
    throw new Error(`Team ${newName} already exist`);
  }
};
