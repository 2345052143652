import { compose, unwrapResult } from '@reduxjs/toolkit';
import { Data, UI } from '@taraai/types';
import IconGroup from 'components/app/controllers/Selectors/common/IconGroup';
import { UserSelector } from 'components/app/controllers/Selectors/UserSelector';
import { SectionType } from 'components/core/controllers/Selector';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { reduxStore, selectActiveUsers, selectTeam, selectTeamMembers, updateTeamMembership } from 'reduxStore';
import { strings } from 'resources/i18n';
import { useToast } from 'tools';

type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL' | 'username'>;

type Props = {
  orgID: Data.Id.OrganizationId;
  teamID: Data.Id.TeamId;
};

/**
 * TeamMemberSelector allows to select members of given team
 */
export function TeamMemberSelector({ orgID, teamID }: Props): JSX.Element | null {
  const { whenSuccess, whenError } = useToast();

  const team = useSelector(selectTeam(orgID, teamID));

  const orgUsers = useSelector(
    compose(
      (data) => data.map(({ id, name, avatarURL, username }) => ({ id, name, avatarURL, username })),
      selectActiveUsers(orgID),
    ),
  );

  const teamMembers = useSelector(selectTeamMembers(orgID, teamID));

  if (!isLoaded(teamMembers) || !isLoaded(team)) {
    return null;
  }

  const userElements = teamMembers.map((user) => ({
    id: user.id,
    name: user.name,
    tooltip: user.name,
    url: user.avatarURL,
  }));

  const section: SectionType<UserFragment> = {
    id: teamID,
    options: orgUsers,
  };

  const addUserToTeam = (user: UserFragment): void => {
    reduxStore
      .dispatch(
        updateTeamMembership({
          action: 'add',
          teamId: teamID,
          userIds: [user.id],
        }),
      )
      .then(unwrapResult)
      .then(
        whenSuccess(
          strings.formatString(strings.memberManager.addMember.success, {
            username: user.username ?? '',
            teamName: team.name,
          }) as string,
        ),
      )
      .catch(whenError(({ message }) => message));
  };

  const removeUserFromTeam = (user: UserFragment): void => {
    reduxStore
      .dispatch(
        updateTeamMembership({
          action: 'remove',
          teamId: teamID,
          userIds: [user.id],
        }),
      )
      .then(unwrapResult)
      .then(() =>
        whenSuccess(
          strings.formatString(strings.memberManager.removeMember.success, {
            username: user.username ?? '',
            teamName: team.name,
          }) as string,
        ),
      )
      .catch(whenError(({ message }) => message));
  };

  return (
    <UserSelector
      deselectUser={removeUserFromTeam}
      headerTitle={
        strings.formatString(strings.teamMemberSelector.addMembers, {
          teamName: team.name,
        }) as string
      }
      renderSelectButton={(props): JSX.Element => <IconGroup elements={userElements} {...props} />}
      searchPlaceholder={strings.teamMemberSelector.searchUser}
      sections={[section]}
      selection={teamMembers}
      selectUser={addUserToTeam}
    />
  );
}
