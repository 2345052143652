import ImportServicesListController from 'components/app/controllers/ImportServicesListController';
import IntegrationsController from 'components/app/controllers/IntegrationsController';
import IntegrationsHeader from 'components/app/controllers/views/IntegrationsHeader';
import React from 'react';

const IntegrationsLayout = (): JSX.Element => (
  <>
    <IntegrationsHeader />
    <IntegrationsController />
    <ImportServicesListController />
  </>
);

export default IntegrationsLayout;
