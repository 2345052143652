import { css, cx } from 'emotion';
import React from 'react';
import { atomic } from 'resources';

export default function Box({
  row = false,
  left = null,
  right = null,
  border = false,
  className = {},
  children = null,
  title = null,
  initialHeight = false,
  style: { main = {} } = {},
  height: exactHeight = '2.5rem',
  // Box colors
  grey = false,
  white = false,
  black = false,
  error = false,
  accept = false,
  primary = false,
  transparent = false,
  table = false,
  tableHeader = false,
  tableRow = false,
  tableCell = false,
  align = 'center',
  color: exactColor = atomic.colors.black,
  Ref = null,
  ...props
}) {
  const displayArr = [table, tableHeader, tableRow, tableCell];
  const displayProp = [
    displayArr.includes(true)
      ? ['table', 'table-header-group', 'table-row', 'table-cell'][displayArr.indexOf(true)]
      : 'flex',
  ];
  const widthProp = [displayArr.includes(true) ? ['100%', '', '', 'unset'][displayArr.indexOf(true)] : '100%'];
  const alignProp = [displayArr.includes(true) ? ['', '', '', align][displayArr.indexOf(true)] : ''];
  const colorArr = [primary, accept, error, grey, black, white, transparent];
  const colors = [
    colorArr.includes(true)
      ? [
          atomic.theme.color.primary,
          atomic.theme.color.success,
          atomic.theme.color.error,
          atomic.theme.color.grey,
          atomic.theme.color.black,
          atomic.theme.color.white,
          atomic.theme.color.transparent,
        ][colorArr.indexOf(true)]
      : null,
    colorArr.includes(true)
      ? [
          atomic.theme.color.primary.text,
          atomic.theme.color.success.text,
          atomic.theme.color.error.text,
          atomic.theme.color.grey.text,
          atomic.theme.color.black.text,
          atomic.theme.color.white.text,
          atomic.theme.color.white.text,
        ][colorArr.indexOf(true)]
      : exactColor,
  ];
  const [boxColor, textColor] = colors;
  return (
    <div
      {...props}
      ref={Ref}
      className={cx(
        css`
          ${atomic.color(atomic.get(textColor))};
          ${atomic.backgroundColor(atomic.get(boxColor))};
          display: ${displayProp};
          flex-direction: ${row || tableRow ? 'row' : 'column'};
          ${atomic.height(initialHeight === false ? '100%;' : exactHeight)};
          ${atomic.width(widthProp)};
          ${atomic.border({
            style: border === false ? 'none' : 'solid',
            color: atomic.theme.color.input.border.default,
            radius: border === false ? '0rem' : atomic.theme.space.input.border.radius.regular,
          })};
          text-align: ${alignProp};
        `,
        className,
        main,
      )}
      title={title}
    >
      {left}
      {children}
      {right}
    </div>
  );
}
