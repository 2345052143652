import FilterMenuView from 'components/core/controllers/views/FilterMenuView';
import React, { useCallback, useMemo, useState } from 'react';
import { Option } from 'tools/libraries/helpers/tableFilter';

export interface FilterMenuControllerProps<T> extends React.HTMLProps<HTMLDivElement> {
  collectionName: keyof T;
  options: Option[];
  handleFilter?: (collectionToFilter: keyof T, itemsToFilter: (string | number)[]) => void;
}

export function resetOptions(options: Option[]): Option[] {
  return options.map(
    (opt): Option => {
      opt.isActive = false;
      return opt;
    },
  );
}

export function getActiveOptions(options: Option[]): (string | number)[] {
  return options.filter((opt) => opt.isActive === true).map((opt) => opt.value);
}

/**
 * FilterMenuController handles the business logic of the filter menu and passes it to FilterMenuView
 *
 * @param collectionName - The collection we are filtering over (such as status or assignee)
 * @param options - An array of options
 * @param handleFilter - The callback function for executing a filter
 */
export default function FilterMenuController<T>({
  collectionName,
  options,
  handleFilter,
}: FilterMenuControllerProps<T>): JSX.Element {
  const [currentOptions, setCurrentOptions] = useState<Option[]>(options);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const onChange = useCallback(
    (value: string | number, isActive: boolean): void => {
      const opts = currentOptions.map(
        (opt): Option => {
          if (opt.value === value) {
            opt.isActive = !isActive;
          }
          return opt;
        },
      );
      setIsFiltering(currentOptions.filter((opt) => opt.isActive === true).length > 0);
      setCurrentOptions(opts);
    },
    [currentOptions],
  );

  const onReset = useCallback((): void => {
    const opts = resetOptions(currentOptions);
    setCurrentOptions(opts);
    const activeOptions = getActiveOptions(currentOptions);
    setIsFiltering(false);
    if (typeof handleFilter === 'function') handleFilter(collectionName, activeOptions);
  }, [collectionName, currentOptions, handleFilter]);

  const onSubmit = useCallback((): void => {
    const activeOptions = getActiveOptions(currentOptions);
    if (typeof handleFilter === 'function') handleFilter(collectionName, activeOptions);
  }, [currentOptions, handleFilter, collectionName]);

  return useMemo(
    () => (
      <FilterMenuView
        isFiltering={isFiltering}
        onChange={onChange}
        onReset={onReset}
        onSubmit={onSubmit}
        options={currentOptions}
      />
    ),
    [isFiltering, onChange, onReset, onSubmit, currentOptions],
  );
}
