import Button from 'components/core/controllers/views/Button';
import Icon from 'components/core/controllers/views/Icon';
import { StandardSpinner } from 'components/core/controllers/views/Spinners';
import { css, cx } from 'emotion';
import React, { useCallback, useRef, useState } from 'react';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';
import { getFileSizeInKb } from 'tools/libraries';

function SelectFileBtn({ onUploadBtnClick }: { onUploadBtnClick: () => void }): JSX.Element {
  return (
    <Button
      className={css`
        margin-top: 1rem;
        width: 12.875rem;
        height: 2.375rem;
        border-radius: 3px;
        border: solid 1px ${atomic.get(atomic.colors.grey4)};
        line-height: 1rem;
        font-family: Sofia;
        font-size: 0.875rem;
        font-weight: 500;
      `}
      color='ghost'
      onClick={onUploadBtnClick}
      type='button'
    >
      {strings.importModal.uploadFileBtnLabel}
    </Button>
  );
}

export function ExportInstructionUrl({
  href,
  label,
  className,
}: {
  href: string;
  label: string;
  className?: string;
}): JSX.Element {
  return (
    <a
      className={cx(
        css`
          font-size: 0.875rem;
          font-weight: 500;
          text-decoration: none;
          ${atomic.color(atomic.colors.secondary)}
          &:hover {
            text-decoration: none;
            color: ${atomic.color(atomic.colors.focus)};
          }
        `,
        className,
      )}
      href={href}
      rel='noopener noreferrer'
      target='_blank'
    >
      {label}
    </a>
  );
}

function ImportTasksSection({
  selectedFile,
  removeSelectedFile,
  handleImportBtnClick,
}: {
  selectedFile: File;
  removeSelectedFile: () => void;
  handleImportBtnClick: () => void;
}): JSX.Element {
  return (
    <>
      <div
        className={css`
          margin-top: 1.5rem;
          width: 18.75rem;
          min-height: 4.5rem;
          height: auto;
          padding: 0.5rem 0;
          border-radius: 3px;
          border: solid 1px ${atomic.get(atomic.colors.grey4)};
          background-color: ${atomic.get(atomic.colors.white)};
          display: flex;
          align-items: center;
          position: relative;
        `}
      >
        <Icon
          className={css`
            width: 2.5rem;
            height: 2.5rem;
            object-fit: contain;
          `}
          name='csv'
        />
        <div
          className={css`
            min-height: 2.5rem;
            height: auto;
            width: calc(100% - 6rem);
            word-wrap: break-word;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          `}
        >
          <div
            className={css`
              font-family: Sofia;
              font-size: 0.875rem;
              font-weight: 500;
              color: #191919;
            `}
          >
            {selectedFile.name}
          </div>
          <div
            className={css`
              font-family: Sofia;
              font-size: 0.875rem;
              color: ${atomic.get(atomic.colors.grey7)};
            `}
          >
            {getFileSizeInKb(selectedFile.size)}
          </div>
          <Icon
            className={css`
              position: absolute;
              top: 0.5rem;
              right: 0.5rem;
              width: 0.75rem;
              height: 0.75rem;
            `}
            color={atomic.get(atomic.colors.grey5)}
            name='close'
            onClick={removeSelectedFile}
          />
        </div>
      </div>
      <Button
        className={css`
          margin-top: 1rem;
          width: 12.875rem;
          height: 2.375rem;
          border-radius: 3px;
          border: solid 1px ${atomic.get(atomic.colors.grey4)};
          line-height: 1rem;
          font-family: Sofia;
          font-size: 0.875rem;
          font-weight: 500;
        `}
        color='green'
        onClick={handleImportBtnClick}
        type='button'
      >
        {strings.importModal.importTasksBtnLabel}
      </Button>
    </>
  );
}

export interface ImportModalViewProps {
  onCloseRequest: () => void;
  onImportRequest: (file: File) => void;
  title: string;
  description: string;
  serviceIcon: JSX.Element;
  exportInstructionUrlHref: string;
  exportInstructionUrlLabel: string;
  isUploading: boolean;
}

function ImportModalView({
  onCloseRequest,
  onImportRequest,
  title,
  description,
  serviceIcon,
  exportInstructionUrlHref,
  exportInstructionUrlLabel,
  isUploading,
}: ImportModalViewProps): JSX.Element | null {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState(null as File | null);

  const onUploadBtnClick = useCallback(() => {
    fileInputRef?.current?.click();
  }, [fileInputRef]);

  const onFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setSelectedFile(event.target.files[0] || null);
      }
    },
    [setSelectedFile],
  );

  const removeSelectedFile = useCallback(() => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [setSelectedFile, fileInputRef]);

  const handleImportBtnClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => onImportRequest(selectedFile!),
    [onImportRequest, selectedFile],
  );

  const bottomSection =
    // eslint-disable-next-line no-nested-ternary
    selectedFile === null ? (
      <SelectFileBtn onUploadBtnClick={onUploadBtnClick} />
    ) : isUploading ? (
      <StandardSpinner
        className={css`
          margin-top: 2rem;
        `}
        size='medium'
      />
    ) : (
      <ImportTasksSection
        handleImportBtnClick={handleImportBtnClick}
        removeSelectedFile={removeSelectedFile}
        selectedFile={selectedFile}
      />
    );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={css`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.65);
        z-index: 2;
      `}
      onClick={onCloseRequest}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions*/}
      <div
        className={css`
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 6.25rem;
          width: 31.25rem;
          background: ${atomic.get(atomic.colors.white)};
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-bottom: 2.5rem;
          z-index: 2;
        `}
        onClick={(evt: React.MouseEvent<HTMLElement>): void => {
          evt.stopPropagation();
        }}
      >
        <div
          className={css`
            margin-top: 3rem;
          `}
        >
          {serviceIcon}
        </div>
        <h2
          className={css`
            margin-top: 0.5rem;
            font-family: Sofia;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.38;
            color: ${atomic.get(atomic.colors.dark)};
          `}
        >
          {title}
        </h2>
        <div
          className={css`
            width: 80%;
            text-align: center;
            font-family: Sofia;
            font-size: 0.875rem;
            line-height: 1.57;
            color: #575f65;
            margin-top: 0.625rem;
          `}
        >
          {description}
        </div>
        <ExportInstructionUrl
          className={css`
            margin-top: 1.5rem;
          `}
          href={exportInstructionUrlHref}
          label={exportInstructionUrlLabel}
        />
        <input
          ref={fileInputRef}
          className={css`
            display: none;
          `}
          id='input'
          onChange={onFileInputChange}
          type='file'
        />
        {bottomSection}
        <Icon
          className={css`
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
          `}
          color={atomic.get(atomic.colors.grey5)}
          name='close'
          onClick={onCloseRequest}
        />
      </div>
    </div>
  );
}

export default ImportModalView;
