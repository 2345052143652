/* eslint-disable react/display-name */
import { unwrapResult } from '@reduxjs/toolkit';
import { Data, Service, UI } from '@taraai/types';
import GitlabModalController from 'components/app/controllers/GitlabModalController';
import ConnectExternalAccount from 'components/app/controllers/views/ConnectExternalAccount';
import GitlabIntegration from 'components/app/controllers/views/GitlabIntegration';
import IntegrationBoxView from 'components/app/controllers/views/IntegrationBoxView';
import IntegrationsApp, {
  IntegrationElement,
  IntegrationsAppProps,
} from 'components/app/controllers/views/IntegrationsApp';
import { ManageRepositoriesBtn } from 'components/app/controllers/views/IntegrationsApp/manageRepositoriesBtn';
import IntegrationsAppsList, { AppListEntry } from 'components/app/controllers/views/IntegrationsAppsList';
import { css } from 'emotion';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { isProfileAdmin, reduxStore } from 'reduxStore';
import { getOrgGithubProducts } from 'reduxStore/github-import/queries';
import { disconnectProfile } from 'reduxStore/integrations/actions/disconnectProfile';
import { uninstallIntegration } from 'reduxStore/integrations/actions/uninstallIntegration';
import { integrationTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import {
  useConnectGitHubAccount,
  useConnectSlackAccount,
  useInstallGitHub,
  useInstallGitlab,
  useInstallSlack,
  useToast,
} from 'tools';
import { formatI18n } from 'tools/libraries/helpers/formatI18n';

type IsDisconnectPendingMap = {
  [serviceName in Service]: boolean;
};

function IntegrationsController(): JSX.Element {
  const { orgID } = useParams<{ orgID: Data.Id.OrganizationId }>();
  const { whenSuccess, whenError } = useToast();

  const isAdmin = useSelector(isProfileAdmin(orgID));

  const githubReposSlice = getOrgGithubProducts(orgID);
  useFirestoreConnect(githubReposSlice.query);
  const githubRepositories = useSelector(
    compose(
      (arr?: UI.UIProduct[]): IntegrationElement[] | undefined =>
        arr?.map(
          (data): IntegrationElement => ({
            text: data.externalService?.repository ?? data.title,
            url: `https://github.com/${data.externalService?.repository}`,
          }),
        ),
      githubReposSlice.selector,
    ),
  );

  const gitHubInstallation = useInstallGitHub();
  const slackInstallation = useInstallSlack();
  const gitlabInstallation = useInstallGitlab(orgID);

  const githubConnectProfileData = useConnectGitHubAccount();
  const slackConnectData = useConnectSlackAccount();

  const [isDisconnectPendingMap, setIsDisconnectPendingMap] = useState({} as IsDisconnectPendingMap);

  const onDisconnectProfile = useCallback(
    async (service: 'github' | 'slack'): Promise<void> =>
      reduxStore
        .dispatch(disconnectProfile({ service }))
        .then(unwrapResult)
        .then(
          whenSuccess(
            formatI18n(strings.integrations.disconnectedProfileSuccess)({
              service,
            }),
          ),
        )
        .catch(
          whenError(
            formatI18n(strings.integrations.disconnectedProfileError)({
              service,
            }),
          ),
        ),
    [whenSuccess, whenError],
  );

  const withPendingState = useCallback(
    (
      service: Service,
      disconnectHandler: () => Promise<void>,
    ): {
      onDisconnect: IntegrationsAppProps['onDisconnect'];
      isLoading: IntegrationsAppProps['isLoading'];
    } => ({
      onDisconnect: async (): Promise<void> => {
        setIsDisconnectPendingMap({
          ...isDisconnectPendingMap,
          [service]: true,
        });

        await disconnectHandler();

        setIsDisconnectPendingMap({
          ...isDisconnectPendingMap,
          [service]: false,
        });
      },
      isLoading: !!isDisconnectPendingMap[service],
    }),
    [isDisconnectPendingMap, setIsDisconnectPendingMap],
  );

  const apps = useMemo(
    (): IntegrationsAppProps[] => [
      {
        connectBtnDataCy: integrationTestIDs.GITHUB_CONNECT,
        description: strings.integrations.github.description,
        installationData: gitHubInstallation,
        icon: 'github',
        iconColor: 'black',
        title: strings.integrations.github.title,
        elements: githubRepositories,
        elementsTitle: strings.integrations.github.repositoriesConnected,
        withProfileConnect: {
          service: 'github',
          title: strings.profile.connectWithGitHubTitle,
          description: strings.profile.connectWithGitHubDescription,
          label: strings.profile.connectProfile,
          connectedLabel: strings.profile.connectedProfile,
          icon: 'github',
          connectData: githubConnectProfileData,
        },
        manageIntegrationBtn: <ManageRepositoriesBtn installationData={gitHubInstallation} orgID={orgID} />,
        ...withPendingState(
          'github',
          async (): Promise<void> => {
            if (gitHubInstallation.id) {
              await reduxStore.dispatch(uninstallIntegration(gitHubInstallation.id));
            }
          },
        ),
      },
      {
        connectBtnDataCy: integrationTestIDs.SLACK_CONNECT,
        description: strings.integrations.slack.description,
        installationData: slackInstallation,
        icon: 'slack',
        title: strings.integrations.slack.title,
        withProfileConnect: {
          service: 'slack',
          title: strings.profile.connectWithSlackTitle,
          description: strings.profile.connectWithSlackDescription,
          label: strings.profile.connectProfile,
          connectedLabel: strings.profile.connectedProfile,
          icon: 'slack',
          connectData: slackConnectData,
        },
        ...withPendingState(
          'slack',
          async (): Promise<void> => {
            if (slackInstallation.id) {
              await reduxStore.dispatch(uninstallIntegration(slackInstallation.id));
            }
          },
        ),
      },
    ],
    [
      gitHubInstallation,
      githubRepositories,
      githubConnectProfileData,
      orgID,
      withPendingState,
      slackInstallation,
      slackConnectData,
    ],
  );

  const entriesFromApps = apps.map(
    (app): AppListEntry => ({
      key: app.title,
      installed: !!app.installationData.id,
      component: (): JSX.Element => <IntegrationsApp {...app} />,
      profileComponent: (): JSX.Element | null =>
        app.withProfileConnect ? (
          <IntegrationBoxView
            actionButton={
              <ConnectExternalAccount
                key={app.withProfileConnect.label}
                className={css`
                  margin: 0.5rem 0;
                `}
                onProfileDisconnect={onDisconnectProfile}
                {...app.withProfileConnect}
              />
            }
            description={app.withProfileConnect.description}
            icon={app.icon}
            isLoading={false}
            title={app.withProfileConnect.title}
          />
        ) : null,
    }),
  );

  const gitlabEntry: AppListEntry = {
    key: 'gitlab',
    installed: gitlabInstallation.isInstalled,
    component: (): JSX.Element => (
      <GitlabIntegration
        isAdmin={isAdmin}
        isInstalled={gitlabInstallation.isInstalled}
        openModal={gitlabInstallation.openModal}
      />
    ),
  };

  const entries: AppListEntry[] = [...entriesFromApps, gitlabEntry];

  return (
    <IntegrationsAppsList entries={entries}>
      {gitlabInstallation.isModalOpen && (
        <GitlabModalController
          closeModal={gitlabInstallation.closeModal}
          instructionImgSrc={gitlabInstallation.instructionImgUrl}
          secretToken={gitlabInstallation.secretToken}
        />
      )}
    </IntegrationsAppsList>
  );
}

export default IntegrationsController;
