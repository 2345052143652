import { unwrapResult } from '@reduxjs/toolkit';
import { Data } from '@taraai/types';
import { linkTo } from 'components/Router/paths';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { reduxStore } from 'reduxStore';
import { setupIntegration } from 'reduxStore/setupIntegration/setupIntegration';
import { strings } from 'resources';
import { useToast } from 'tools';
import { segment } from 'tools/libraries/analytics';
import { clearPendingIntegrationOrg } from 'tools/utils/pendingIntegrationOrg';

import { InstallableServiceWithRedirects } from './interfaces';
import { SetupIntegrationLayout } from './SetupIntegrationLayout';

type SetupIntegrationProps = {
  orgID?: Data.Id.OrganizationId;
  service: InstallableServiceWithRedirects;
  urlPayload: Record<string, string>;
  teamID: Data.Id.TeamId;
};

/**
 * Calls setupIntegration cloud function and handles errors
 * Then it redirects the user to integrations page
 */
export function SetupIntegration({ orgID, service, teamID, urlPayload }: SetupIntegrationProps): JSX.Element {
  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    (async (): Promise<void> => {
      clearPendingIntegrationOrg();
      if (!orgID) {
        return;
      }

      try {
        const response = await reduxStore
          .dispatch(
            setupIntegration({
              service,
              organizationId: orgID,
              payload: urlPayload,
            }),
          )
          .then(unwrapResult);
        segment.track('IntegrationConnected', {
          orgID,
          service: response.service,
          location: 'SetupIntegration',
        });
        switch (response.service) {
          case 'github':
          case 'slack':
            return history.replace(
              linkTo('workspace', { orgID: response.organizationId, teamID }, '?tab=Integrations'),
            );
          case 'gitlab':
            throw new Error('Server responded with payload for incorrect service');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
        addToast({
          message: strings.setupIntegration.error,
          type: 'error',
          timeoutMs: 3500,
        });

        history.replace(linkTo('integrations', { orgID, teamID }));
      }
    })();
  }, [orgID, service, history, addToast, urlPayload, teamID]);

  return <SetupIntegrationLayout service={service} />;
}
