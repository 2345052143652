import Input from 'components/core/controllers/views/Input';
import { css } from 'emotion';
import React from 'react';
import { RootState } from 'reduxStore';
import { userProfileTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { atomic } from 'resources/theme';

export interface EditUserDataProps {
  authData: RootState['firebase']['auth'];
  nameProps: Record<string, unknown>;
}

export function EditUserData({ authData, nameProps }: EditUserDataProps): JSX.Element {
  return (
    <>
      <Input
        {...nameProps}
        data-cy={userProfileTestIDs.NAME_INPUT}
        label={strings.profile.label.name}
        naked
        style={{
          main: css`
            color: ${atomic.get(atomic.colors.dark)};
            padding: 1rem 1rem 0.75rem 1rem;
            max-width: 31.25rem;
            font-size: 1rem;
            background-color: ${atomic.get(atomic.colors.white)};
            border: 0.0625rem solid ${atomic.get(atomic.colors.grey5)};
            border-radius: 0.375rem;
            &:focus-within {
              border-radius: 0.1875rem;
              border: solid 0.125rem ${atomic.get(atomic.colors.secondary)};
            }
          `,
          container: css`
            margin-bottom: 1.5rem;
          `,
          labelStyle: css`
            font-size: 1rem;
            color: ${atomic.get(atomic.colors.grey7)};
          `,
        }}
      />
      <Input
        data-cy={userProfileTestIDs.EMAIL_INPUT}
        disabled
        label={strings.profile.label.emailAddress}
        naked
        style={{
          main: css`
            border-radius: 0.375rem;
            max-width: 31.25rem;
            padding: 1rem 1rem 0.75rem 1rem;
            border: 0.0625rem solid ${atomic.get(atomic.colors.grey5)};
            background-color: ${atomic.get(atomic.colors.grey2)};
            color: ${atomic.get(atomic.colors.grey6)};
            font-size: 1rem;
            &:focus-within {
              border-radius: 0.1875rem;
              border: solid 0.125rem ${atomic.get(atomic.colors.secondary)};
            }
          `,
          container: css`
            margin-bottom: 0.5rem;
          `,
          labelStyle: css`
            font-size: 1rem;
            color: ${atomic.get(atomic.colors.grey7)};
          `,
        }}
        value={authData.email}
      />
    </>
  );
}
