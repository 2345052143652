import styled from '@emotion/styled';
import { NavigationListElements } from 'components/app/controllers/Navbar/NavigationListElements';
import { NavbarWrapper, NavigationList } from 'components/app/controllers/Navbar/styles';
import { LogoContainer } from 'components/app/controllers/WorkspaceSwitcher/NavButton';
import Logo from 'components/core/controllers/views/Logo';
import React from 'react';
import { TaraLogoWithShadow } from 'resources';
import { strings } from 'resources/i18n';

export const Navbar: React.FC<{ orgName: string }> = ({ orgName }) => (
  <Wrapper>
    <NavigationList>
      <TaraLogo alt={strings.logo.tara} src={TaraLogoWithShadow} />
      <LogoContainer>
        <Logo name={orgName} shape='square' size='medium' />
      </LogoContainer>
      <NavigationListElements />
    </NavigationList>
  </Wrapper>
);

const TaraLogo = styled.img`
  margin-bottom: 1.1875rem;
  cursor: pointer;
`;

const Wrapper = styled((props) => <NavbarWrapper {...props} />)`
  z-index: auto;
`;
