import { ImportNotificationBarController } from 'components/app/controllers/ImportNotificationBarController';
import React from 'react';
import { strings } from 'resources/i18n';

export function AsanaNotificationBarController(): JSX.Element {
  return (
    <ImportNotificationBarController
      bgColor='#e65e71'
      iconName='asanaWhite'
      importInProgressCopy={strings.importNotificationBar.asana.importInProgressCopy}
      service='asana'
    />
  );
}
