/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { firebaseReducer, getFirebase } from 'react-redux-firebase';
import { firestoreReducer, getFirestore, reduxFirestore } from 'redux-firestore';
import thunk from 'redux-thunk';
import { firebaseInit } from 'tools/libraries/firebase';

import { importsReducer } from './imports/actions';
import { getOrgID, getTeamID, getUserID, timing } from './middlewares';
import { dataIdMiddleware } from './middlewares/dataId';
import { organizationInvitationReducer } from './onboarding/reducers';
import { searchReducer } from './search';
import { sprintsReducer } from './sprints/reducers';
import { usersWithAccessLevelsReducer } from './usersWithAccessLevels/reducers';
import { usersWithEmailsReducer } from './usersWithEmails/reducers';
import { Profile } from './utils';

// Initialize other services on firebase instance
firebaseInit();

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  imports: importsReducer,
  usersWithAccessLevels: usersWithAccessLevelsReducer,
  userEmails: usersWithEmailsReducer,
  organizationInvitation: organizationInvitationReducer,
  sprints: sprintsReducer,
  search: searchReducer,
});

// Defining the Root State Type https://react-redux.js.org/using-react-redux/static-typing
export type RootState = ReturnType<typeof rootReducer>;

export type RootStateWithProfile = Omit<RootState, 'firebase'> & {
  firebase: Omit<RootState['firebase'], 'profile'> & { profile: Profile };
};

export const middleware = [
  timing,
  dataIdMiddleware,
  thunk.withExtraArgument({
    getFirestore,
    getFirebase,
    getUserID,
    getOrgID,
    getTeamID,
  }),
  ...getDefaultMiddleware<RootState, { serializableCheck: boolean; immutableCheck: boolean }>({
    serializableCheck: false,
    immutableCheck: false,
  }),
] as const;

const enhancers = [reduxFirestore(firebase as any)];

// Create store with reducers
export const reduxStore = configureStore({
  reducer: rootReducer,
  middleware,
  enhancers,
});

export type AppDispatch = typeof reduxStore.dispatch;
