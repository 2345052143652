import React from 'react';

type DraftLinkAnchorProps = React.HTMLProps<HTMLAnchorElement>;

export default function DraftLinkAnchor({ children, ...props }: DraftLinkAnchorProps): JSX.Element {
  return (
    <a {...props} rel='noreferrer noopener' target='_blank'>
      {children}
    </a>
  );
}
