/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Tooltip } from '@material-ui/core';
import { isNonEmptyString, noop } from '@taraai/utility';
import Icon from 'components/core/controllers/views/Icon';
import { ContentBlock, ContentState, EditorState } from 'draft-js';
import { css, cx } from 'emotion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { atomic, strings } from 'resources';
import { useClickOutside } from 'tools';

import { useEditor } from './Context/useEditor';
import { getImageMetadata, removeBlockEntity } from './helpers';

export interface DraftImageProps extends React.HTMLProps<HTMLDivElement> {
  alt?: string;
  block?: ContentBlock;
  contentState?: ContentState;
  imageClassName?: string;
  src?: string;
  tooltipClassName?: string;
  tooltipTitleClassName?: string;
}

/**
 * DraftImage
 * Draft Editor Image
 *
 */
export default function DraftImage({
  alt,
  block,
  className,
  contentState,
  imageClassName,
  src,
  tooltipClassName,
  tooltipTitleClassName,
  ...props
}: DraftImageProps): JSX.Element {
  const { onChangeFn } = useEditor();
  const { src: blockSrc, alt: blockAlt } = block?.getEntityAt(0)
    ? contentState?.getEntity(block?.getEntityAt(0))?.getData()
    : { src: null, alt: null };
  const { src: dataSrc, alt: dataAlt } = ((block?.getData() as unknown) as {
    src?: string;
    alt?: string;
  }) ?? {
    src: null,
    alt: null,
  };
  const imageSrc = src ?? dataSrc ?? blockSrc ?? '';
  const imageAlt = alt ?? dataAlt ?? blockAlt ?? imageSrc;

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const openTooltip = useCallback(() => setTooltipIsOpen(true), []);
  const closeTooltip = useCallback(() => setTooltipIsOpen(false), []);
  const tooltipRef = useRef<HTMLElement | null>(null);
  useClickOutside(tooltipRef, () => setTimeout(closeTooltip, 200));
  const toggleTooltip = useCallback(() => setTooltipIsOpen((open) => !open), []);

  const [imageMetadata, setImageMetadata] = useState<{ exists: false } | { exists: true; landscape: boolean }>({
    exists: false,
  });
  useEffect(() => {
    getImageMetadata(imageSrc)
      .then((imageURLExists) => {
        if (imageURLExists.validUrl === true) {
          return setImageMetadata({
            exists: true,
            landscape: (imageURLExists.width ?? 0) > (imageURLExists.height ?? 0),
          });
        }
        return setImageMetadata({ exists: false });
      })
      .catch(noop);
  }, [imageSrc]);

  const [copiedURL, setCopiedURL] = useState(false);
  const copyURL = useCallback((): void => {
    if (isNonEmptyString(imageSrc)) {
      navigator.clipboard.writeText(imageSrc);
      setCopiedURL(true);
      setTimeout(closeTooltip, 200);
    }
  }, [closeTooltip, imageSrc]);

  const removeImage = useCallback(() => {
    const {
      blockProps: { getEditorState },
    } = props as {
      blockProps: {
        getEditorState: () => EditorState;
      };
    };
    const editorState = getEditorState();
    const blockKey = block?.getKey();
    if (isNonEmptyString(blockKey)) onChangeFn?.current?.(removeBlockEntity(editorState, blockKey ?? ''));
  }, [block, onChangeFn, props]);

  if (!imageMetadata.exists) {
    return <div />;
  }
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
        `,
        className,
      )}
    >
      <Tooltip
        ref={tooltipRef}
        arrow
        className={cx(css``, tooltipClassName)}
        disableHoverListener
        interactive
        onClose={closeTooltip}
        onOpen={openTooltip}
        open={tooltipIsOpen}
        placement='right'
        title={
          <div
            className={cx(
              css`
                display: flex;
                flex-direction: row;
                align-items: center;
                max-width: 18.75rem;
                padding: 8px;
              `,
              tooltipTitleClassName,
            )}
          >
            <div onMouseEnter={(): void => setCopiedURL(false)}>
              <Tooltip
                enterDelay={0}
                interactive
                title={
                  <div
                    className={css`
                      color: #ffffff;
                      font-size: 0.875rem;
                      font-weight: normal;
                      line-height: 1.125rem;
                      max-width: 15.625rem;
                    `}
                  >
                    {copiedURL ? strings.task.copied : strings.task.copyURL}
                  </div>
                }
                TransitionProps={{ timeout: 600 }}
              >
                <div
                  className={css`
                    display: flex;
                  `}
                >
                  <Icon
                    className={css`
                      height: 0.75rem;
                      width: 0.75rem;
                      border-radius: 0.1875rem;
                      border: solid 0.0625rem ${atomic.get(atomic.colors.white)};
                      :hover {
                        cursor: pointer;
                        opacity: 0.7;
                      }
                    `}
                    color={atomic.colors.white}
                    name='copy'
                    onClick={copyURL}
                  />
                </div>
              </Tooltip>
            </div>
            <a
              className={cx(
                css`
                  font-size: 0.875rem;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.57;
                  letter-spacing: normal;
                  margin-right: 1rem;
                  margin-left: 1rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  :hover {
                    cursor: pointer;
                    opacity: 0.7;
                    text-decoration: underline;
                  }
                `,
              )}
              href={imageSrc}
              rel='noreferrer noopener'
              target='_blank'
              title={imageSrc}
            >
              {imageSrc}
            </a>
            <Tooltip
              enterDelay={0}
              interactive
              title={
                <div
                  className={css`
                    color: #ffffff;
                    font-size: 0.875rem;
                    font-weight: normal;
                    line-height: 1.125rem;
                    max-width: 15.625rem;
                  `}
                >
                  Remove Image
                </div>
              }
              TransitionProps={{ timeout: 600 }}
            >
              <div
                className={css`
                  display: flex;
                `}
              >
                <Icon
                  className={css`
                    height: 0.75rem;
                    width: 0.75rem;
                    border-radius: 0.1875rem;
                    border: solid 0.0625rem ${atomic.get(atomic.colors.white)};
                    :hover {
                      cursor: pointer;
                      opacity: 0.7;
                    }
                  `}
                  color={atomic.colors.white}
                  name='close'
                  onClick={removeImage}
                />
              </div>
            </Tooltip>
          </div>
        }
      >
        <img
          alt={imageAlt}
          className={cx(
            css`
              max-width: ${imageMetadata.exists && imageMetadata.landscape ? '100' : '50'}%;
              margin: auto;
            `,
            imageClassName,
          )}
          onClick={toggleTooltip}
          src={imageSrc}
        />
      </Tooltip>
    </div>
  );
}
