import { styled } from '@taraai/design-system';
import { UI } from '@taraai/types';
import React from 'react';
import { strings } from 'resources/i18n';
import { formatDMMM } from 'tools/libraries/helpers/dates';

type SprintType = 'active' | 'complete' | 'upcoming';
type SprintFragment = Pick<UI.UISprint, 'sprintName' | 'initialStartDate' | 'initialEndDate'>;

type SprintColumnHeaderViewProps = {
  type: SprintType;
  sprint: SprintFragment;
  completedEffort: number;
  totalEffort: number;
};

export const SprintColumnHeaderView: React.FC<SprintColumnHeaderViewProps> = ({
  type,
  sprint,
  completedEffort,
  totalEffort,
}) => (
  <SprintHeader type={type === 'active'}>
    <HeaderItemWrapper>
      <Title type={type}>{sprint.sprintName}</Title>
    </HeaderItemWrapper>
    <HeaderItemWrapper>
      <DateRange type={type}>
        {formatDMMM(sprint?.initialStartDate)}
        {strings.sprints.sprintColumn.sprintDateSpace}
        {formatDMMM(sprint?.initialEndDate)}
      </DateRange>
    </HeaderItemWrapper>
    <HeaderItemWrapper>
      <TaskCompletionRate type={type}>
        {strings.formatString(strings.sprints.sprintColumn.totalEffortCompleted, {
          completedEffort,
          totalEffort,
        })}
      </TaskCompletionRate>
    </HeaderItemWrapper>
  </SprintHeader>
);

const SprintHeader = styled(
  'div',
  {
    display: 'flex',
    order: '0',
    flexGrow: '0',
    borderRadius: '0.25rem 0.25rem 0rem 0rem',
    backgroundColor: '#FFFFFF',
    padding: '0.25rem 0.5rem',
  },
  {
    type: {
      true: {
        backgroundColor: '#4D5BFF',
      },
    },
  },
);

const HeaderItemWrapper = styled('div', {
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Title = styled(
  'span',
  {
    fontWeight: '600',
    fontSize: '0.75rem',
    marginRight: 'auto',
  },
  {
    type: {
      active: { color: '#FFFFFF' },
      complete: { color: '#389E0D' },
      upcoming: { color: '#67728B' },
    },
  },
);

const DateRange = styled(
  'span',
  {
    alignSelf: 'flex-end',
    fontWeight: '400',
    fontSize: '0.625rem',
  },
  {
    type: {
      active: { color: '#FFFFFF' },
      complete: { color: '#389E0D' },
      upcoming: { color: '#949CAF' },
    },
  },
);

const TaskCompletionRate = styled(
  'span',
  {
    fontWeight: '600',
    fontSize: '0.625rem',
    marginLeft: 'auto',
  },
  {
    type: {
      active: { color: '#FFFFFF' },
      complete: { color: '#389E0D' },
      upcoming: { color: '#949CAF' },
    },
  },
);
