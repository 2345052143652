import Tara, { UI } from '@taraai/types';
import Fuse from 'fuse.js';
import { strings } from 'resources/i18n';

/**
 * Interface for one Option in a filter menu
 *
 * @param key Used distinuish what has been added, changed, or removed from the array
 * @param label What is displayed to the user on the filter menu
 * @param value The data that is associated with the label
 * @param isActive Whether or not the option selected or not
 */
export interface Option {
  key: string | number;
  label: string;
  value: string | number;
  isActive: boolean;
}

export function getTaskStatusOptions(): Option[] {
  return [
    {
      value: 0,
      key: 0,
      label: strings.taskStatus.statusLabels[0],
      isActive: false,
    },
    {
      value: 1,
      key: 1,
      label: strings.taskStatus.statusLabels[1],
      isActive: false,
    },
    {
      value: 2,
      key: 2,
      label: strings.taskStatus.statusLabels[2],
      isActive: false,
    },
  ];
}

type TeamFragment = Pick<UI.UITeam, 'id' | 'name'>;
export const getRequirementAssignedTeamsOptions = (teams: TeamFragment[] = []): Option[] =>
  teams.map((team) => ({
    value: team.id,
    key: team.id,
    label: team.name,
    isActive: false,
  }));

/**
 * Given an objectKey, generate a list of Options and return it
 *
 * @param taskData A collection of Tara Tasks
 * @param objectKey The object key on which to extract options from
 */
export function getOptionsFromTaskData(taskData: UI.UITask[], objectKey: string): Option[] {
  // Filter out any undefined values
  const items = Array.from(
    new Set(
      taskData.map((item) => {
        return item[objectKey as keyof UI.UITask];
      }),
    ),
  ).filter((item) => item !== undefined);

  if (objectKey === 'status') {
    return (
      items
        .map(
          (item): Option => ({
            value: item,
            key: item,
            label: strings.taskStatus.statusLabels[item],
            isActive: false,
          }),
        )
        // Sort by todo, doing, done
        .sort((statusA, statusB) => (statusA.value < statusB.value ? -1 : 0))
    );
  }

  if (objectKey === 'assignee') {
    return (
      (items as Tara.User[])
        .map(
          (item): Option => ({
            value: item.id || item.name,
            key: item.username || item.name,
            label: item.name,
            isActive: false,
          }),
        )
        // Sort assignee names alphabetically
        .sort((assigneeA, assigneeB) => (assigneeA.value < assigneeB.value ? -1 : 0))
    );
  }

  return items.map(
    (item: Tara.User): Option => ({
      value: item.name,
      key: item.name,
      label: item.name,
      isActive: false,
    }),
  );
}

/**
 * Filter function for a Tara Task
 *
 * @param tasks Array of tasks to filter
 * @param objectKey What key to filter
 * @param itemsToFilter Which items in that key to filter
 */
export function filterTaraTasks(
  tasks: UI.UITask[],
  objectKey: keyof UI.UITask,
  itemsToFilter: (string | number)[],
): UI.UITask[] {
  if (itemsToFilter.length === 0) return tasks;
  return tasks.filter((item) => {
    if (objectKey === 'requirementDocument') {
      return itemsToFilter.includes(item[objectKey as keyof UI.UITask]?.id);
    }
    return itemsToFilter.includes(item[objectKey as keyof UI.UITask]);
  });
}

type RequirementFragment = Pick<UI.UIRequirement, 'assignedTeamIds'>;

export function filterTaraRequirements(
  requirements: UI.UIRequirement[],
  objectKey: keyof RequirementFragment,
  itemsToFilter: (string | number)[],
): UI.UIRequirement[] {
  if (itemsToFilter.length === 0) return requirements;
  return requirements.filter((requirement) => {
    const assignedTeamIds = requirement[objectKey];
    return itemsToFilter.every((filter) => assignedTeamIds.includes(String(filter)));
  });
}

export const searchTaraTasks = (collectionToSearch: UI.UITask[], query: string): UI.UITask[] => {
  if (query === '') return collectionToSearch;
  /**
   * The options for fusejs.
   * @see https://fusejs.io/
   */
  const fuseOptions = {
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    includeScore: false,
    keys: ['title', 'assigneeDocument.name', 'slug'],
  };
  const fuse = new Fuse(collectionToSearch as UI.UITask[], fuseOptions);
  return (fuse.search(query).map(({ item }) => item) as unknown) as UI.UITask[];
};

/**
 * Combines the results of two Tara Task arrays
 *
 * @param resultsA Search / filter results
 * @param resultsB Search / filter results
 */
export function combineResults<T>(resultsA: T[], resultsB: T[]): T[] {
  return resultsA.filter((result) => resultsB.includes(result));
}

export function getResults<T>(resultsA: T[], resultsB: T[]): T[] {
  if (resultsA.length === 0 && resultsB.length > 0) return resultsB;
  if (resultsA.length > 0 && resultsB.length === 0) return resultsA;
  return combineResults(resultsA, resultsB);
}
