import styled from '@emotion/styled';
import Icon from 'components/core/controllers/views/Icon';
import React from 'react';

import { SprintSelectorOption } from './types';

type SelectButtonProps = {
  selectedOption: SprintSelectorOption;
  onClick: () => void;
  dataCy?: string;
};

export default function SelectButton({ selectedOption, dataCy, onClick }: SelectButtonProps): JSX.Element {
  return (
    <Container data-cy={dataCy} onClick={onClick}>
      <Title data-cy={dataCy}>{selectedOption.sprintName}</Title>
      <DropdownIndicator data-cy={dataCy} />
    </Container>
  );
}

const Container = styled.div`
  max-width: 12.5rem;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  flex-basis: content;
`;

const Title = styled.div`
  font-size: 0.875rem;
  color: #303f4b;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.5rem;
`;

const DropdownIndicator = styled((props) => <Icon name='dropdownindicator' {...props} />)`
  width: 0.4375rem;
  padding: 0;
  cursor: pointer;
`;
