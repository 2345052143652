import { Schema } from '@taraai/types';
import Ajv from 'ajv';
import ToastContext from 'components/app/controllers/Toast/ToastContext';
import ToastErrorMessages from 'components/app/controllers/Toast/ToastErrorMessages';
import { Toast, TOAST_ANIMATION_DURATION_MS } from 'components/core/controllers/views/Toast';
import { css } from 'emotion';
import React, { ComponentProps, ContextType, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { decode } from 'reduxStore/utils/decoders';
import { strings } from 'resources';
import { toastTestIDs } from 'resources/cypress/testAttributesValues';

let nextToastId = 1;

type ToastType = ComponentProps<typeof Toast>['color'];

export interface ToastData {
  id: string;
  message?: string | JSX.Element;
  open: boolean;
  type: ToastType;
  showSpinner: boolean;
}

export interface ToastProviderProps extends React.HTMLProps<HTMLElement> {
  children?: JSX.Element | JSX.Element[] | string;
}

const ToastProvider = ({ children }: ToastProviderProps): JSX.Element => {
  const [toasts, setToasts] = useState<ToastData[]>([]);

  const value = useMemo<ContextType<typeof ToastContext>>(() => getValue(setToasts), []);

  return (
    <ToastContext.Provider value={value}>
      {createPortal(
        <div
          className={css`
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 2.5rem;
            position: fixed;
            visibility: hidden;
            z-index: 1000;
          `}
          data-cy={toastTestIDs.TOAST_CONTAINER}
        >
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              color={toast.type}
              icon={toast.type !== 'upgrade' ? toast.type : undefined}
              id={toast.id}
              open={toast.open}
              showSpinner={toast.showSpinner}
            >
              {toast.message}
            </Toast>
          ))}
        </div>,
        document.body,
      )}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

function getValue(setToasts: Dispatch<SetStateAction<ToastData[]>>): ContextType<typeof ToastContext> {
  function removeToast(id: string): void {
    setToasts((prevToasts) =>
      prevToasts.map((toast) =>
        id === toast.id
          ? {
              ...toast,
              open: false,
            }
          : toast,
      ),
    );
    setTimeout(
      () => setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id)),
      TOAST_ANIMATION_DURATION_MS + 100, // animation time plus jiffy
    );
  }

  function addToast(options: {
    addOnTop?: boolean;
    id?: string;
    message?: string | JSX.Element;
    timeoutMs?: number;
    type?: ToastType;
    errorMessage?: Ajv.ErrorObject[];
    inputType?: string;
  }): void {
    setToasts((prevToasts) => {
      const id = options.id || (nextToastId++).toString();
      const existingToast = prevToasts.find((toast) => toast.id === id);
      const newToast = {
        id,
        message: options.message,
        type: options.type || 'success',
        open: true,
        showSpinner: options.type === 'loading',
      };

      let newToasts;
      if (existingToast) {
        newToasts = prevToasts.map((toast) => (id === toast.id ? newToast : toast));
      } else if (options.addOnTop) {
        newToasts = [newToast, ...prevToasts];
      } else {
        newToasts = [...prevToasts, newToast];
      }

      if (options.timeoutMs) {
        setTimeout(() => removeToast(id), options.timeoutMs);
      }

      return newToasts;
    });
  }

  function maybeToast(
    data: unknown,
    type: keyof Schema.TaraValidation,
    onValid?: <T extends unknown>(data: T) => void,
  ): void {
    try {
      onValid?.(decode(data, type));
    } catch (err) {
      const errorObject = err.errors as Ajv.ErrorObject[];
      const firstError: Ajv.ErrorObject | string =
        errorObject.find(({ dataPath, keyword }) => ToastErrorMessages[`${type}${dataPath}.${keyword}`]) ??
        strings.authLayout.unknownError;
      addToast({
        type: 'error',
        timeoutMs: 3500,
        message:
          typeof firstError !== 'string'
            ? ToastErrorMessages[`${type}${firstError.dataPath}.${firstError.keyword}`]
            : firstError,
      });
    }
  }

  function whenError(str: ((error: Error) => string) | string): (error: Error) => void {
    const format = typeof str === 'string' ? () => str : str;
    return (error) => {
      addToast({
        type: 'error',
        addOnTop: true,
        timeoutMs: 4000,
        message: format(error),
      });
    };
  }

  function whenSuccess<Data>(str: ((data: Data) => string) | string): (data: Data) => Data {
    const format = typeof str === 'string' ? () => str : str;
    return (data) => {
      addToast({
        type: 'success',
        timeoutMs: 5500,
        addOnTop: true,
        message: format(data),
      });
      return data;
    };
  }

  return {
    removeToast,
    addToast,
    maybeToast,
    whenError,
    whenSuccess,
  };
}
