import { Data, UI } from '@taraai/types';
import { noop, notUndefined } from '@taraai/utility';
import { SprintSelector } from 'components/app/controllers/Selectors/SprintSelector';
import TaskStatus from 'components/app/controllers/TaskStatus';
import { SmartText } from 'components/app/controllers/views/SmartText';
import TaskList from 'components/app/controllers/views/TaskList';
import FilterMenuController from 'components/core/controllers/FilterMenuController';
import { TableDataEntry } from 'components/core/controllers/Table';
import AvatarPicker from 'components/core/controllers/views/AvatarPicker';
import { RichEditorHandle } from 'components/editor/RichEditor';
import { css, cx } from 'emotion';
import React from 'react';
import { taskListAttributeTestIDs, taskListTestIDs } from 'resources/cypress/testAttributesValues';
import { strings } from 'resources/i18n';
import { Option } from 'tools/libraries/helpers/tableFilter';

export interface RequirementTasksListEditorViewProps {
  addFeatureRef: React.MutableRefObject<RichEditorHandle | null>;
  orgID: Data.Id.OrganizationId;
  tasksData: UI.UITask[];
  toggleModal?: (task: UI.UITask) => void;
  team: UI.UIUser[];
  addAssignee: (taskID: string) => (assigneeID: string) => void;
  removeAssignee: (taskID: string) => () => void;
  onTaskSave: (title: string) => void;
  handleSearch?: (query: string) => void;
  handleFilter?: (collectionToFilter: keyof UI.UITask, itemsToFilter: (string | number)[]) => void;
  statusFilterOptions?: Option[];
  customLabels?: UI.UICustomLabel[];
}

function RequirementTasksListEditorView({
  orgID,
  tasksData = [],
  addFeatureRef,
  onTaskSave,
  toggleModal = noop,
  addAssignee,
  removeAssignee,
  team,
  handleSearch,
  handleFilter,
  statusFilterOptions,
  customLabels,
}: RequirementTasksListEditorViewProps): JSX.Element {
  const handleClick = (event: React.SyntheticEvent): void => {
    event.stopPropagation();
  };

  const columns = {
    status: {
      name: strings.task.status,
      width: '10%',
      filterRenderer: statusFilterOptions && (
        <FilterMenuController collectionName='status' handleFilter={handleFilter} options={statusFilterOptions} />
      ),
    },
    slugId: { name: strings.task.idColumnHeader, width: '10%' },
    title: { name: strings.task.name, width: '60%' },
    sprint: {
      name: strings.task.sprintColumnHeader,
      width: '10%',
      align: 'right',
    },
    assignee: { name: strings.task.assignee, width: '10%' },
  };

  const colWidth: Record<string, string> = {};

  colWidth.status = '10%';
  colWidth.slugId = '10%';
  colWidth.title = '60%';
  colWidth.sprint = '10%';
  colWidth.assignee = '10%';

  const tasks: TableDataEntry[] = Array.from(tasksData, (task) => ({
    status: (
      <div
        data-cy={taskListTestIDs.TASK_STATUS_DROPDOWN_BUTTON}
        data-testid='task-status'
        onClick={handleClick}
        onKeyDown={(event): void | false => event.keyCode === 13 && event.stopPropagation()}
        role='button'
        tabIndex={0}
      >
        <TaskStatus key={task.id} dataCy={taskListAttributeTestIDs.STATUS_DROPDOWN} task={task} />
      </div>
    ),
    slugId: (
      <div
        className={cx(
          css`
            text-transform: uppercase;
            font-size: 0.875rem;
            text-decoration: none;
            color: #67728b;
          `,
        )}
        data-cy={taskListTestIDs.TASK_ID_TEXT}
      >
        {strings.formatString(strings.task.taskSlug, { slug: task.slug })}
      </div>
    ),
    title: <SmartText text={task.title} />,
    sprint: (
      <div
        className={cx(css`
          > div {
            width: 100% !important;
          }
          outline: none;
        `)}
        data-cy={taskListTestIDs.MANAGE_SPRINT_BUTTON}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
        role='button'
        tabIndex={0}
      >
        <SprintSelector dataCy={taskListAttributeTestIDs.SPRINT_DROPDOWN} position='right' task={task} />
      </div>
    ),
    assignee: (
      <div
        className={cx(
          css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          `,
        )}
        data-cy={taskListTestIDs.MANAGE_ASSIGNMENT_BUTTON}
      >
        <AvatarPicker
          key={task.id}
          className={css`
            padding: 0.25rem 0rem;
          `}
          dataCy={taskListAttributeTestIDs.ASSIGNEE_DROPDOWN}
          maxAllowed={1}
          onAddUser={addAssignee(task.id)}
          onRemoveUser={removeAssignee(task.id)}
          pickerStyle={css`
            width: 1.5rem;
            height: 1.5rem;
            padding: 0rem;
          `}
          position='bottom-left'
          size='small'
          // show only active users plus assignee, or all users if there's no access level info available
          suggestions={team.filter(
            (user) => user.accessLevels?.[orgID] !== 'deactivated' || user.id === task.assigneeDocument?.id,
          )}
          users={[task.assigneeDocument].filter(notUndefined)}
        />
      </div>
    ),
    id: task.slug,
    task,
  }));

  const completeTaskCount = (tasksData.filter((task) => task.status === 2) ?? []).length.toString();

  return (
    <TaskList
      addFeatureRef={addFeatureRef}
      addNewTask
      columns={columns}
      colWidth={colWidth}
      completeTaskCount={completeTaskCount}
      customLabels={customLabels}
      handleSearch={handleSearch}
      onTaskSave={onTaskSave}
      tasks={tasks}
      toggleModal={toggleModal}
      withSearch
    />
  );
}

export default RequirementTasksListEditorView;
