import { Data } from '@taraai/types';
import SprintsRow from 'components/app/controllers/SprintsRow';
import React from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { getUpcomingSprints, selectTeam } from 'reduxStore';

/**
 * gets active sprints from store and passes them to the SprintsRow
 */
export default function UpcomingSprints(): JSX.Element {
  const { orgID, teamID } = useParams<{
    orgID: Data.Id.OrganizationId;
    teamID: Data.Id.TeamId;
  }>();

  const sprintSlice = getUpcomingSprints(orgID, teamID, {
    orderBy: 'createdAt',
  });
  useFirestoreConnect(sprintSlice.query);

  const currentSprintId = useSelector(compose((data) => data?.currentSprintId, selectTeam(orgID, teamID)));

  const upcomingSprints = useSelector(
    compose(
      (arr) =>
        arr?.map((data) => ({
          id: data.id,
          sprintName: data.sprintName,
          initialStartDate: data.initialStartDate,
          initialEndDate: data.initialEndDate,
          completedAt: data.completedAt,
          computedOnCompletion: data.computedOnCompletion,
          isComplete: data.isComplete,
        })),
      sprintSlice.selector,
    ),
    deepEquals,
  );

  return <SprintsRow currentSprintId={currentSprintId ?? undefined} sprints={upcomingSprints} />;
}
