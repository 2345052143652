import { UISprint } from '@taraai/types/dist/ui';
import { isNonEmptyString } from '@taraai/utility';
import { OrderByOptions, ReduxFirestoreQuerySetting, WhereOptions } from 'react-redux-firebase';
import { createStandardSelector, generateAlias, inertQuery, Query } from 'reduxStore/utils/selectors';

type OrderBy = keyof Pick<UISprint, 'createdAt' | 'sprintNumber' | 'sprintName'> | 'sprintNumberDesc';

/**
 * Returns not completed sprints
 * @param orgId
 * @param teamId - when not provided returns all not completed sprints in organization
 * @param options
 */
export function getUpcomingSprints(
  orgId?: string,
  teamId?: string,
  options?: { orderBy?: OrderBy; limit?: number },
): Query<UISprint> {
  if (!isNonEmptyString(orgId) || (teamId !== undefined && !isNonEmptyString(teamId))) {
    return inertQuery();
  }

  let orderBy: OrderByOptions;
  switch (options?.orderBy ?? 'sprintNumber') {
    case 'sprintNumber':
      orderBy = ['sprintNumber', 'asc'];
      break;
    case 'sprintNumberDesc':
      orderBy = ['sprintNumber', 'desc'];
      break;
    case 'createdAt':
      orderBy = ['createdAt', 'asc'];
      break;
    case 'sprintName':
      orderBy = ['sprintName', 'asc'];
      break;
  }

  const teamCondition: WhereOptions[] = teamId ? [['teamId', '==', teamId]] : [];
  const query: ReduxFirestoreQuerySetting = {
    collection: `orgs/${orgId}/sprints`,
    where: [...teamCondition, ['isComplete', '==', false], ['archived', '==', false], ['deleted', '==', false]],
    orderBy,
    ...(options?.limit !== undefined ? { limit: options?.limit } : {}),
  };
  query.storeAs = generateAlias(query);

  return {
    query: [query],
    selector: createStandardSelector(query),
  };
}
